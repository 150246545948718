import { Component, Vue } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import { Table } from '@/types'
import { routerBaseUrl } from '@/router'

interface TableItem {
    id: number
    department: string
    group: string
    fxs: string
    year: string
    commission: string
    income: string
}

interface Filter {
    [index: string]: string | string[]
    department: string
    group: string
    fxs: string
    time: string
}

@Component({
    components: {
        FilterComponent,
        SelectComponent,
        CheckBoxComponent,
        InputDateRangeComponent
    }
})
export default class WaitCommissionComponent extends Vue {
    filter: Filter = {
        department: '',
        group: '',
        fxs: '',
        project: '',
        time: new Date().getFullYear().toString()
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 50
    }

    created () {
        this.getTableData()
    }

    async getTableData () {
        const { data } = await this.__apis.wallet.getUnSendBonusListGroupByUser({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            dep_id: this.filter.department,
            group_id: this.filter.group,
            employee_id: this.filter.fxs,
            time_start: `${this.filter.time}-01-01 00:00:00`,
            time_end: `${this.filter.time}-12-31 23:59:59`
        })

        const all = this.tableData.data[0]
        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.employee_id,
                department: a.user_info.DepartmentName,
                group: a.user_info.GroupName,
                fxs: a.user_info.UserName,
                year: `${this.filter.time}年`,
                commission: this.__utils.index.formatMoney(a.unsend_bonus_money),
                income: this.__utils.index.formatMoney(a.unsend_bonus_interest_money)
            }
        })

        const stat = data.statistic_res ? {
            id: -1,
            department: '汇总',
            group: '',
            fxs: '',
            year: '',
            commission: this.__utils.index.formatMoney(data.statistic_res.unsend_bonus_money),
            income: this.__utils.index.formatMoney(data.statistic_res.unsend_bonus_interest_money)
        } : all
        this.tableData.data.unshift(stat)
    }

    searchDep (text: string, cb: any) {
        this.__utils.index.searchDep(text, cb)
    }

    searchGroup (text: string, cb: any) {
        this.__utils.index.searchGroup(text, cb, { dep: this.filter.department })
    }

    searchFxs (text: string, cb: any) {
        this.__utils.index.searchEmployee(text, cb)
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        if (!this.filter.time) {
            this.filter.time = new Date().getFullYear().toString()
        } else {
            this.filter.time = this.filter.time.split('-')[0]
        }

        this.getTableData()
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }

    gotoDetail (id: number) {
        open(`${routerBaseUrl}/qm-wallet/fxs/commission-detail?id=${id}`)
    }
}
