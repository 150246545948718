import { Component, Vue } from 'vue-property-decorator'
import { mergeTreeRes, Role, saveRole, Tree } from '../utils'
import { getAllRoleRes } from '@/api/system'

@Component
export default class IndexPage extends Vue {
    role: {
        value: number
        data: getAllRoleRes[]
    } = {
        value: null as any,
        data: []
    }

    tree: {
        defaultProps: any
        pc: mergeTreeRes[],
        xcx: mergeTreeRes[]
    } = {
        pc: [],
        xcx: [],
        defaultProps: {
            label: 'name'
        }
    }

    // fix
    save (): any {}
    change (): any {}

    async created () {
        const { pc, xcx } = await Tree()
        const { role: role_data, getRole, getRoleByRoleId } = await Role()
        this.tree.pc = pc
        this.tree.xcx = xcx
        this.role.data = role_data
        this.role.value = role_data[0].id

        this.change = () => {
            const pc = this.$refs.pc as any
            const xcx = this.$refs.xcx as any
            const role = getRoleByRoleId(this.role.value)

            if (role) {
                pc.setCheckedKeys(role.permissions)
                xcx.setCheckedKeys(role.permissions)
            }
        }
        this.save = async () => {
            const pc = this.$refs.pc as any
            const xcx = this.$refs.xcx as any
            const children_keys = pc.getCheckedKeys(true).concat(xcx.getCheckedKeys(true)).filter((a: any) => Number.isInteger(a))

            await saveRole({
                role_id: this.role.value,
                permissions_ids: children_keys
            })

            this.$message.success('保存成功')

            // refresh role data
            await getRole()
        }

        // fix ref
        this.$nextTick(() => {
            this.change()
        })
    }
}
