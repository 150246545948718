import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class SetCookieComponent extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    visible!: boolean

    @Prop({
        type: Object,
        default: () => {}
    })
    data!: {
        key: string
        cookieName: string
        value: string
    }

    async cookieLogin () {
        const item = this.data
        await this.__apis.market.loginSetCookie({
            cookie_name: item.cookieName,
            type: item.key,
            code: item.value
        })
        this.$emit('save')
        this.$notify({
            title: '成功',
            message: '登录成功',
            type: 'success'
        })
    }

    async save () {
        await this.cookieLogin()
        this.close()
    }

    close () {
        this.$emit('update:visible', false)
    }
}
