import { BaseRes, ListReq, ListRes, php } from '@/api/index'
import { basicUserInfo } from '@/api/user'

export interface forumDetail {
    Id: number
    UserId: number
    Title: string
    Content: string
    IsAnonymous: number
    MId: number
    CommentCount: number
    CollectCount: number
    ForumId: number
    Pid: number
    LastCommentTime: string
    Ctime: string
    UserName: string
    Deactive: number
    UserCenterInfo: {
        basic_info: basicUserInfo
    }
}

interface getForumInitRes {
    text: string
    value: number
    can_anonymous: boolean
}

interface getForumListReq extends ListReq {
    type: number
    m_id?: number
    forum_id?: string
}
interface getForumListRes extends ListRes <forumDetail> {}

interface saveForumReq {
    title: string
    is_anonymous: number | string
    m_id: number
    content: string
}

interface getForumDetailRes extends forumDetail {
    hasCollect: boolean
}

interface saveForumCommentReq {
    forum_id: string 	// 帖子ID
    content: string
    pid: number
}

export default class ApiCommunity {
    // 初始化导航板块
    getForumInit (): BaseRes<getForumInitRes[]> {
        return php.post('', {
            action_name: 'getForumInit'
        })
    }

    // 获取数据
    getForumList (data: getForumListReq): BaseRes<getForumListRes> {
        return php.post('', {
            action_name: 'getForumList',
            ...data
        })
    }

    // 保存提交
    saveForum (data: saveForumReq) {
        return php.post('', {
            action_name: 'saveForum',
            ...data
        })
    }

    // 清除未读数据
    readNotice () {
        return php.post('', {
            action_name: 'readNotice'
        })
    }

    // 获取帖子详情
    getForumDetail (id: number): BaseRes<getForumDetailRes> {
        return php.post('', {
            action_name: 'getForumDetail',
            id
        })
    }

    // 取消收藏帖子
    cancelCollectForum (id: string) {
        return php.post('', {
            action_name: 'cancelCollectForum',
            forum_id: id
        })
    }

    // 收藏帖子
    collectForum (id: string) {
        return php.post('', {
            action_name: 'collectForum',
            forum_id: id
        })
    }

    // 评论帖子
    saveForumComment (data: saveForumCommentReq) {
        return php.post('', {
            action_name: 'saveForumComment',
            ...data
        })
    }
}
