import { MessageService, messageService } from '@/libs/message-service'
import store, { Module } from '@/store'
import { ConnectionState, StoreConnectionState, UPDATE_CONNECTION_STATE } from '@/store/modules/connection'
import { AppState, StoreAppState, UPDATE_APP_STATE } from '@/store/modules/app_state'
import { ReceivedMessage } from '@/types/received_message'
import { MessageType } from '@/types/message/msg_type'
import { MessageTimeRequest, MessageTimeResponse } from '@/types/message/msg_time'
import { MessageActions, ReceivedMessageState, StoreReceivedMessageState } from '@/store/modules/message'

const msgStore = store.module<StoreReceivedMessageState>(Module.MESSAGE)
const conStore = store.module<StoreConnectionState>(Module.CONNECTION)
const appStore = store.module<StoreAppState>(Module.APP_STATE)

const msgSrv = (): MessageService => {
    // 对时用
    let time_ping_inited = false
    let time_diff_inited = false
    const TIME_DIFF_REQUEST_INTERVAL = 10 * 1000 // 时差/网速检测 时间间隔 10s
    let TIME_CLIENT_NONCE = (Math.random() * 0x7FFFFFFF).toFixed(0)

    let timeDiffer: any = null

    const ws = messageService()

    ws.onMessage = (data: ReceivedMessage<any>) => {
        // console.log('ms: onMessage', data)
        const state = appStore.state

        // 和服务器对时结果
        if (data.type === MessageType.TIME_RESPONSE) {
            const ret = data.data as MessageTimeResponse
            // 判断是否该客户端发出(因为服务器会把消息发到该用户登录的所有客户端上)
            if (ret.client_nonce === TIME_CLIENT_NONCE) {
                const now = +new Date()
                const diff = Math.round((now + ret.client_timestamp) / 2 - ret.server_timestamp)
                const ping = Math.round((now - ret.client_timestamp) / 2)
                const odiff = state.appState.time_diff_server
                const oping = state.appState.time_ping

                // 结果无参考意义
                if (ping < 0 || ping > 1000 * 5) {
                    return
                }

                appStore.commit(UPDATE_APP_STATE, { time_diff_server: time_diff_inited ? Math.round((odiff + diff) / 2) : diff } as AppState)
                appStore.commit(UPDATE_APP_STATE, { time_ping: time_ping_inited ? Math.round((ping + oping) / 2) : ping } as AppState)
                time_ping_inited = true
                time_diff_inited = true
            }
            return
        }

        msgStore.commit(MessageActions.RECEIVE_MESSAGE, { LastMessage: data } as ReceivedMessageState)

        // if (data.type === MessageType.USER_FUNDS_CHANGED) {
        //
        // }

        // 判断是否有新邮件
        // if (data.type === MessageType.USER_MESSAGE) {
        //     const msg: MsgUserMessage = data.data
        //     const app = {...my().appData}
        //     app.user_message.last_msg_id = msg.msg_id
        //     getStore().dispatch(updateUserSession({appData: app}))
        // }
    }
    ws.onConnecting = () => {
        console.log('ms: onConnecting')
        conStore.commit(UPDATE_CONNECTION_STATE, ConnectionState.CONNECTING)

        // console.log(prompt)
        // if (+new Date() - wssStartTime > 5 * 1000 && cc.director.getScene().name !== SceneName.INIT) {
        //     connectWarning.show('网络不稳定，正在连接')
        // }
    }
    ws.onConnect = () => {
        console.log('ms: onConnect')

        // connectWarning.hide()

        conStore.commit(UPDATE_CONNECTION_STATE, ConnectionState.CONNECTED)

        TIME_CLIENT_NONCE = (Math.random() * 0x7FFFFFFF).toFixed(0) // new nonce
        if (timeDiffer) {
            clearInterval(timeDiffer)
            timeDiffer = null
        }

        // 请求服务器对时
        const timeDiffRequest = () => {
            ws.send(JSON.stringify({
                type: MessageType.TIME_REQUEST,
                data: { client_timestamp: +new Date(), client_nonce: TIME_CLIENT_NONCE } as MessageTimeRequest
            }))
        }

        timeDiffRequest()
        timeDiffer = setInterval(timeDiffRequest, TIME_DIFF_REQUEST_INTERVAL)

        // 连接建立后刷新饼干信息
        // Promise.all([
        //     store.dispatch(refreshUserCookie()),
        //     store.dispatch(refreshUserVirtualAsset())
        // ])
    }
    ws.onClose = () => {
        console.log('ms: onClose')
        if (timeDiffer) {
            clearInterval(timeDiffer)
            timeDiffer = null
        }
        conStore.commit(UPDATE_CONNECTION_STATE, ConnectionState.CLOSED)
    }

    return ws
}

export const createMessageService = msgSrv
