import { Component, Vue } from 'vue-property-decorator'
enum CurrentStatusType {
    新客户推荐 = 1,
    认领成功,
    已被他人认领,
    今日认领已达上限
}
@Component
export default class recommendPopu extends Vue {
    private dialogVisible:boolean = false
    private currentStatus:number = CurrentStatusType['新客户推荐']
    private claimTime: number = 29
    private row: any = {}
    private excessValve: boolean = true // 额外的定时器阀门
    private robIntercal:any = null // 定时器
    private customer_id:any = ''

    comClaimTime () {
        setTimeout(() => {
            if (this.claimTime > 1) {
                this.claimTime = this.claimTime - 1
                console.log(this.claimTime)
                this.comClaimTime()
            } else { this.dialogVisible = false; this.excessValve && this.createInterval() }
        }, 1000)
    }
    // 查看详情
    customerDetail () {
        this.dialogVisible = false
        console.log(this.row)
        const obj: any = { name: '/customer/detail', params: { id: this.customer_id } }
        this.$router.push(obj)
    }
    // 我知道了
    comfireClick () {
        this.dialogVisible = false
    }
    // 认领
    async robClick (id: number, type: number) {
        const loading = this.$loading({
            lock: true,
            text: '正在努力为您抢客',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.4)'
        })
        this.claimTime = 0
        this.excessValve = false
        const { data } = await this.__apis.customer.robCustomerAction(id, type)
        setTimeout(() => {
            this.claimTime = 29
            this.excessValve = true
            this.comClaimTime()
            loading.close()
            switch (data.code) {
            case 8000:
                this.currentStatus = CurrentStatusType['认领成功']
                this.dialogVisible = true
                this.customer_id = data.customer_id
                break
            case 8003:
                this.$notify({
                    title: '错误',
                    message: data.message,
                    type: 'error'
                })
                break
            case 8004:
                this.currentStatus = CurrentStatusType['今日认领已达上限']
                this.dialogVisible = true
                break
            default:
                this.currentStatus = CurrentStatusType['已被他人认领']
                this.dialogVisible = true
            }
        }, 3000)
    }
    async cancelClick () {
        this.dialogVisible = false
        this.claimTime = 0
        this.excessValve = false
        clearInterval(this.robIntercal)
        await this.__apis.customer.todayNonPop()
        console.log('今日不在弹出消息发送成功')
    }

    createInterval () {
        clearInterval(this.robIntercal)
        this.robIntercal = setInterval(async () => {
            // 得到数据-展示弹窗-开启倒计时
            const { data } = await this.__apis.customer.getRobotCallNewCustomer()
            if (!data) return
            if (data.code === 1001) { // 今日不再弹出
                clearInterval(this.robIntercal)
                this.excessValve = false
                return
            }
            if (data.rows.length > 0) {
                data.rows.forEach((a:any) => {
                    a.type = data.type
                })
                this.row = data.rows[0]
                clearInterval(this.robIntercal)
                this.currentStatus = CurrentStatusType['新客户推荐']
                this.dialogVisible = true
                this.claimTime = 29
                this.comClaimTime()
            }
        }, 15 * 1000)
    }
    onVisibilityChange () {
        var hiddenProperty:any = 'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null
        if (!(document as any)[hiddenProperty]) {
            this.excessValve = true
            this.createInterval()
        } else {
            this.excessValve = false
            this.claimTime = 0
            clearInterval(this.robIntercal)
        }
    }
    mounted () {
        this.createInterval()
        var hiddenProperty:any = 'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null
        var visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange')
        document.addEventListener(visibilityChangeEvent, this.onVisibilityChange)
    }
    beforeDestroy () {
        clearInterval(this.robIntercal)
    }
}
