import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'

export interface TableItem {
    id: number
    createUser: string
    depName: string
    groupName: string
    title: string
    type: string
    count: number
    createTime: string
}

@Component({
    components: {}
})
export default class WxFriendsCircleArticlePage extends Vue {
    tableData: Table<TableItem> = {
        loading: false,
        data: [],
        page: 1,
        pageSize: 50,
        total: 0
    }

    created () {
        this.getData()
    }

    async getData () {
        try {
            this.tableData.loading = true
            const { data } = await this.__apis.wxFriends.getWeChatMomentsArticleList({
                pagesize: this.tableData.pageSize,
                page: this.tableData.page
            })

            this.tableData.data = data.list.map(a => {
                return {
                    id: a.id,
                    createUser: a.name,
                    depName: this.__utils.index.getDepInfoById(a.dep_id).v,
                    groupName: this.__utils.index.getGroupInfoById(a.gro_id).name,
                    title: a.title,
                    type: a.type === 1 ? '自定义' : '转载',
                    count: a.count,
                    createTime: this.__utils.index.formatDate(new Date(a.ctime), 'YYYY-MM-DD')
                }
            })
            this.tableData.total = data.total
        } finally {
            this.tableData.loading = false
        }
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getData()
    }

    getUserInfoText (row: TableItem) {
        return [row.depName, row.groupName, row.createUser].filter(a => a).join('-')
    }

    toSeeUserList (row: TableItem) {
        this.$router.push(`/friendsCircle/seeUser?article_id=${row.id}`)
    }
}
