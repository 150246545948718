import { Component, Vue } from 'vue-property-decorator'
import navTab from '@/components/NavTab/index.vue'
import qmPermission from 'qm-plugin-admin-permission'
import { __role } from '@/role'

@Component({
    components: {
        navTab
    }
})
export default class SystemManage extends Vue {
    menus = [
        {
            name: '账号管理',
            route: { path: '/system/account' }
        },
        {
            name: '角色权限',
            route: { path: '/system/role' },
            authKey: __role.permissions.RoleAuthPage
        },
        {
            name: '用户权限',
            route: { path: '/system/user' },
            authKey: __role.permissions.UserAuthPage
        },
        {
            name: '权限管理',
            route: { path: '/system/role-manage' },
            authKey: __role.permissions.AuthManagePage
        },
        {
            name: '战队管理',
            route: { path: '/system/group-manage' },
            authKey: __role.permissions.TeamManagePage
        }
    ]

    created () {
        qmPermission.filter(this.menus)
    }
}
