export const peiXunStatusList = [
    { label: '请选择', value: 0 },
    { label: '已完成', value: 1 },
    { label: '未完成', value: 2 }
]

export enum OnlineSignRecordResStatus {
    New = 'NEW',
    Pass = 'PASS',
    Reject = 'REJECT'
}

export const webSignStatus = [
    {
        value: OnlineSignRecordResStatus.New,
        label: '待审核'
    },
    {
        value: OnlineSignRecordResStatus.Pass,
        label: '已通过'
    },
    {
        value: OnlineSignRecordResStatus.Reject,
        label: '已驳回'
    }
]

export const LevelStatus = [{
    text: 'A级',
    value: 'A'
}, {
    text: 'B级',
    value: 'B'
}, {
    text: 'C级',
    value: 'C'
}, {
    text: 'D级',
    value: 'D'
}, {
    text: 'E级',
    value: 'E'
}, {
    text: 'F级',
    value: 'F'
}]

export enum AnswerStatus {
    no = 1,
    yes = 2
}

export enum QuestionTypeEnum {
    'singleSelect' = 1,
    'manySelect' = 2,
    'judgeSelect' = 3
}

export const QuestionType = [
    { label: '单选题', value: QuestionTypeEnum.singleSelect },
    { label: '多选题', value: QuestionTypeEnum.manySelect },
    { label: '判断题', value: QuestionTypeEnum.judgeSelect }
]

export const SubjectType = [
    { label: '楼盘基础', value: 1 },
    { label: '房产基础知识', value: 2 },
    { label: '房产政策', value: 3 },
    { label: '工作流程', value: 4 }
]

export enum DepInfoVar {
    GUANGZHOU = 4, // 广州
    HANGZHOU = 2, // 杭州东
    HANGZHOUXI = 6, // 杭州西
    YUNNAN = 3, // 云南
    KUNMING = 1001 // 昆明
}

export enum ChongZhiWalletMoneyActionType {
    充值 = 1,
    提现 = 2,
    利息 = 3
}

export const ChongZhiWalletMoneyActionTypeList = [
    { label: '充值', value: ChongZhiWalletMoneyActionType.充值 },
    { label: '提现', value: ChongZhiWalletMoneyActionType.提现 },
    { label: '利息', value: ChongZhiWalletMoneyActionType.利息 }
]

export const ChongZhiWalletMoneyCashOutExamineStatusList = [
    { label: '待审核', value: 0 },
    { label: '已通过', value: 1 },
    { label: '已驳回', value: 2 }
]

export enum FxsUserTypeEnum {
    正式 = 1,
    试用 = 0
}

export const FxsUserType = [
    {
        label: '试用员工',
        value: FxsUserTypeEnum.试用
    },
    {
        label: '正式员工',
        value: FxsUserTypeEnum.正式
    }
]

export const Gender = [
    {
        label: '男',
        value: 1
    },
    {
        label: '女',
        value: 2
    }
]
