import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { EventBus, EventNames } from '@/util/bus'

@Component
export default class NoticeSendComponent extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    show !: boolean

    private noticeData = {}
    private currentNotice:object = {}
    page = 1
    pageSize = 20
    total = 0
    noticeTitle = ''
    noticeText = ''
    noticeClose = true
    dialogNoticeVisible = false
    NoticeDetailVisible = false
    carouselPlay = true
    createTimeOut: any

    addNotice () {
        this.dialogNoticeVisible = true
    }
    async getNoticeData () {
        const { data } = await this.__apis.home.getNoticeList({
            page: 1,
            pagesize: this.pageSize
        })
        data.list = data.list.filter(i => i.Status === '1')
        data.list = data.list.slice(0, 3)
        data.list.map(i => {
            if (!!i.Content && this.isJSON_test(i.Content)) {
                i.Title = JSON.parse(i.Content).title
                i.Content = JSON.parse(i.Content).content
            } else {
                i.Title = ''
            }
        })
        data.total_page = data.list.length
        this.noticeData = data
        this.total = +data.total
    }

    paginationChange (page: number) {
        this.carouselPlay = false
        let el = this.$refs.carousel;
        (el as any).setActiveItem(page - 1)
        if (this.createTimeOut) {
            clearTimeout(this.createTimeOut)
        }
        // 手动添加宏任务强制走马灯重新计算5秒时间
        this.createTimeOut = setTimeout(() => {
            this.carouselPlay = true
        })
    }
    carouselChange (index:number) {
        this.currentNotice = (this.noticeData as any).list[index]
        this.page = index + 1
    }
    contentClick (item:any) {
        this.currentNotice = item
        this.NoticeDetailVisible = true
    }
    cancelNotice () {
        this.dialogNoticeVisible = false
    }
    async saveNotice () {
        if (!this.noticeTitle || !this.noticeText) {
            this.$message.error(`主题或内容不能为空`)
            return
        }
        await this.__apis.home.saveNotice({
            content: JSON.stringify({
                title: this.noticeTitle,
                content: this.noticeText
            }),
            status: 0
        })

        this.$notify({
            title: '成功',
            message: '发布成功！',
            type: 'success'
        })
        this.noticeText = ''
        this.noticeTitle = ''
        this.dialogNoticeVisible = false
    }
    @Watch('NoticeDetailVisible')
    Change () {
        this.carouselPlay = !this.NoticeDetailVisible
    }
    async created () {
        EventBus.$on(EventNames.upldatenotice, async () => {
            await this.getNoticeData()
        })
        await this.getNoticeData()
    }
    isJSON_test (str: string) {
        if (typeof str === 'string') {
            try {
                var obj = JSON.parse(str)
                if (typeof obj === 'object' && obj) {
                    return true
                } else {
                    return false
                }
            } catch (e) {
                // console.log('error：' + str + '!!!' + e)
                return false
            }
        }
    }
}
