import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {
    }
})
export default class SystemTabsComponent extends Vue {
    @Prop({
        default () {
            return []
        }
    })
    dataSource!: any

    @Prop({ default: 0 })
    current!: number

    created () {
        this.active()

        this.$on('hook:destroyed', this.$router.afterEach(this.active))
    }

    active () {
        this.dataSource.some((v:any, index:number) => {
            if (v.url.includes(this.$route.path)) {
                this.$emit('update:current', index)
                return true
            }
        })
    }

    tabsClick (item: any, index:number) {
        if (index === this.current) return
        this.$router.push(item.url[0])
        this.$emit('update:current', index)
    }
}
