import { Component, Vue } from 'vue-property-decorator'
import { editItem } from './edit'
import { Table } from '@/types'
import { disableGroupReq } from '@/api/system'
import editComponent from './edit/index.vue'

export interface TableItem {
    dep_id: number
    dep: string
    name: string
    leader: string
    time: string
    id: number
    status: 0 | 1
    disabledDate: string
    has_close: boolean
}

@Component({
    components: {
        editComponent
    }
})
export default class IndexPage extends Vue {
    editComponent: {
        show: boolean
        item: editItem
    } = {
        show: false,
        item: {}
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 100,
        total: 0
    }

    created () {
        this.getData()
    }

    // 新建战队
    createGroup () {
        this.editComponent = {
            show: true,
            item: {}
        }
    }

    // 编辑
    edit (item: TableItem) {
        this.editComponent = {
            show: true,
            item: {
                id: item.id
            }
        }
    }

    async getData () {
        const { data } = await this.__apis.system.getGroupList({
            pagesize: this.tableData.pageSize,
            page: this.tableData.page
        })

        this.tableData.data = data.list.map(a => {
            const group = this.__utils.index.getGroupInfoById(a.id)

            return {
                dep: this.__utils.index.getDepInfoById(a.dep_id).v,
                name: a.name,
                has_close: group.has_close,
                leader: a.user_info.user_name,
                time: this.__utils.index.formatDate(new Date(a.start_time), 'YYYY-MM-DD'),
                id: a.id,
                status: a.status,
                dep_id: a.dep_id,
                disabledDate: ''
            }
        })

        this.__utils.index.sortGroups(this.tableData.data)
    }

    // 禁用 | 启用
    async setStatus (id: disableGroupReq['group_id'], close_date: string) {
        try {
            await this.__apis.system.disableGroup({
                group_id: id,
                close_date
            })

            // 关闭时间弹窗
            document.body.click()
            this.$message.success('操作成功')
            this.getData()
        } catch (e) {}
    }

    tableRowClassFun ({ row: item }: {row: TableItem}) {
        if (item.status === 0) {
            return 'disabled'
        }
    }
}
