import { Component, Prop, Vue } from 'vue-property-decorator'
import dataRange from '@/util/date'

@Component
export default class DateSelect extends Vue {
    @Prop({
        type: String,
        default: ''
    })
    title !: string

    @Prop({
        type: Boolean,
        default: false
    })
    edit !: boolean

    @Prop({
        type: Number,
        default: 1
    })
    start !: number

    @Prop({
        type: Object,
        default () {
            return {
                0: '全部',
                1: '今天',
                2: '昨天',
                3: '本周',
                4: '上周',
                5: '本月',
                6: '上月'
            }
        }
    })
    menu !: {
        [index: string]: string
    }

    timeSelect: {
        [index: string]: string
    } = {
        0: '全部',
        1: '今天',
        2: '昨天',
        3: '本周',
        4: '上周',
        5: '本月',
        6: '上月'
    }
    timeSelectCurrent: string | number = 1
    editDate = ''
    obj: any = {}

    mounted () {
        this.timeSelect = this.menu
        this.timeSelectCurrent = +this.start || 1
        this.returnDate(this.timeSelectCurrent)
    }

    changeDatePicker () {
        this.timeSelectCurrent = ''
        this.obj = {
            time_start: (this.editDate && this.editDate[0]) ? this.editDate[0] : '',
            time_end: (this.editDate && this.editDate[1]) ? this.editDate[1] : '',
            index: this.timeSelectCurrent
        }
        this.$emit('click-handle', this.obj)
    }

    timeSelectHandle (index: string) {
        this.timeSelectCurrent = +index
        this.editDate = ''
        this.returnDate(+index)
    }

    returnDate (index: number) {
        const obj = {
            time_start: '',
            time_end: '',
            index: 0
        }

        if (index === 0) {
            // 全部
            obj.time_start = ''
            obj.time_end = ''
        } else if (index === 1) {
            // 今天
            obj.time_start = dataRange.getCurrentDate().format('yyyy-MM-dd')
            obj.time_end = dataRange.getCurrentDate().format('yyyy-MM-dd')
        } else if (index === 2) {
            // 昨天
            obj.time_start = dataRange.getCurrentYesterday().format('yyyy-MM-dd')
            obj.time_end = dataRange.getCurrentYesterday().format('yyyy-MM-dd')
        } else if (index === 3) {
            // 本周
            obj.time_start = dataRange.getCurrentWeek()[0].format('yyyy-MM-dd')
            obj.time_end = dataRange.getCurrentWeek()[1].format('yyyy-MM-dd')
        } else if (index === 4) {
            // 上周
            obj.time_start = dataRange.getPreviousWeek()[0].format('yyyy-MM-dd')
            obj.time_end = dataRange.getPreviousWeek()[1].format('yyyy-MM-dd')
        } else if (index === 5) {
            // 本月
            obj.time_start = dataRange.getCurrentMonth()[0].format('yyyy-MM-dd')
            obj.time_end = dataRange.getCurrentMonth()[1].format('yyyy-MM-dd')
        } else if (index === 6) {
            // 上月
            obj.time_start = dataRange.getPreviousMonth()[0].format('yyyy-MM-dd')
            obj.time_end = dataRange.getPreviousMonth()[1].format('yyyy-MM-dd')
        } else if (index === 7) {
            // 前三个月
            obj.time_start = dataRange.getCurrentThreeBeforeMonth()[0]
            obj.time_end = dataRange.getCurrentThreeBeforeMonth()[1]
        } else if (index === 8) {
            // 前六个月
            obj.time_start = dataRange.getCurrentSixBeforeMonth()[0]
            obj.time_end = dataRange.getCurrentSixBeforeMonth()[1]
        } else if (index === 9) {
            // 前一年
            obj.time_start = dataRange.getCurrentYearBeforeMonth()[0]
            obj.time_end = dataRange.getCurrentYearBeforeMonth()[1]
        }

        obj.index = +this.timeSelectCurrent
        this.obj = obj
        this.$emit('click-handle', obj)
    }
}
