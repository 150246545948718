import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import inputDateRange from '@/components/filters/inputDateRange/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'
import { GetInvalidCustomerApplyPageReq } from '@/api/customer'

interface FollowItem {
    operator: string
    remark: string
    time: string
}

@Component({
    components: {
        FilterComponent,
        inputDateRange,
        FilterBusSelectComponent,
        SelectComponent
    }
})
export default class InvalidCustomerAuditTableComponent extends Vue {
    @Prop({
        type: Number,
        default: 0
    })
    status!: number

    @Prop({
        type: Boolean,
        default: false
    })
    has_dk!: boolean

    @Prop({
        type: String,
        default: ''
    })
    customer_type!: String

    dataObj: any = {
        res: []
    }
    page = 1
    pageSize = 50
    total = 0
    filter = {}
    InvalidStatus = [
        { label: '待审核', value: 0 },
        { label: '通过', value: 1 },
        { label: '驳回', value: 2 }
    ]
    rejectCustomer:{
        [key:string]:any
    } = {
        visible: false,
        remark: '',
        data: {}
    }

    loading = false

    followList: FollowItem[] = []

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    @Watch('status')
    async statusChange () {
        await this.pageChange(1)
    }

    @Watch('has_dk')
    dkChange () {
        this.pageChange(1)
    }

    @Watch('customer_type')
    customerTypeChange () {
        this.pageChange(1)
    }

    async created () {
        await this.getData()
    }

    searchHouse (text: string, cb: any) {
        this.__utils.index.searchHouse(text, cb)
    }

    async getData () {
        this.loading = true
        const params: GetInvalidCustomerApplyPageReq = {
            ...this.filter,
            status: this.status,
            page: this.page,
            pagesize: this.pageSize,
            invalid_customer_for_examine: 1,
            has_dk: +this.has_dk || undefined,
            customer_type: this.customer_type ? +this.customer_type : undefined
        }

        const ctime = params.ctime && this.__utils.index.formatTimeStartTimeEnd([params.ctime.query_start_time, params.ctime.query_end_time])
        const applyTime = params.apply_time && this.__utils.index.formatTimeStartTimeEnd([params.apply_time.query_start_time, params.apply_time.query_end_time])
        params.ctime = ctime && {
            query_start_time: ctime[0],
            query_end_time: ctime[1]
        }
        params.apply_time = applyTime && {
            query_start_time: applyTime[0],
            query_end_time: applyTime[1]
        }

        const { data } = await this.__apis.customer.getInvalidCustomerApplyPage(params)

        this.dataObj = data

        this.dataObj.list = data.list.map(a => {
            return {
                Id: a.id,
                group_name: this.__utils.index.getGroupInfoById(a.group_id).name,
                Status_str: (this.InvalidStatus.filter((b: any) => b.value === Number(a.apply_status))[0] || {}).label || '',
                house: a.house_name,
                username: a.apply_user_name,
                customerName: a.customer_name,
                RejectReason: a.reject_reason,
                customerTel: a.customer_tel,
                customerType: a.customer_type,
                ctime: this.__utils.index.formatDate(new Date(a.apply_time), 'YYYY-MM-DD HH:mm:ss'),
                Status: Number(a.apply_status),
                CustomerId: a.customer_id,
                joinTime: this.__utils.index.formatDate(new Date(a.ctime * 1000), 'YYYY-MM-DD HH:mm:ss'),
                logs: a.logs.map(v => {
                    v.time = this.__utils.index.formatDate(new Date(+v.time * 1000), 'YYYY-MM-DD HH:mm:ss')
                    return v
                })
            }
        })
        this.total = data.total
        this.loading = false

        this.$emit('refresh', this.total)
    }

    // 筛选
    filterChange (data: any) {
        if ('date' in data) {
            data['apply_time'] = {
                query_start_time: data.date[0],
                query_end_time: data.date[1]
            }
            delete data.date
        }

        if ('ctime' in data) {
            data['ctime'] = {
                query_start_time: data.ctime[0],
                query_end_time: data.ctime[1]
            }
        }

        this.filter = data

        this.pageChange(1)
    }

    async pageChange (page:number) {
        this.page = page
        await this.getData()
    }

    toDetail (obj: any, bool: boolean) {
        obj = { name: '/customer/detail', params: { id: obj.CustomerId }, query: { m: `${obj.customerTel}` } }

        if (bool) {
            let { href } = this.$router.resolve(obj)
            window.open(href, '_blank')
        } else {
            this.$router.push(obj)
        }
    }

    rejectInvalid (obj:any) {
        this.rejectCustomer.data = obj
        this.rejectCustomer.visible = true
    }

    cancelReject () {
        this.rejectCustomer.visible = false
    }

    async showMoreFollowHandle (customerId: string) {
        const { data } = await this.__apis.customer.customerDetail(customerId)
        this.followList = data.brief_remark
    }

    async saveReject () {
        const loading = this.$loading({
            lock: true,
            text: '正在提交...',
            spinner: 'el-icon-loading',
            customClass: 'm_loading'
        })
        try {
            await this.__apis.customer.examineInvalidCustomer({
                id: this.rejectCustomer.data.Id,
                status: 2,
                reject_reason: this.rejectCustomer.remark
            })

            this.rejectCustomer.remark = ''
            this.cancelReject()

            await this.__utils.index.sleep(2000)

            this.$notify({
                title: '成功',
                message: '提交成功！',
                type: 'success'
            })
            this.getData()
        } finally {
            loading.close()
        }
    }

    async resolveCustomerInvalid (obj:any, status:number) {
        const loading = this.$loading({
            lock: true,
            text: '正在提交...',
            spinner: 'el-icon-loading',
            customClass: 'm_loading'
        })
        try {
            await this.$confirm('请再次确认该客户是否为无效客户，通过后将不可修改!', '确认通过', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                customClass: 'my_confirm',
                center: true
            })

            await this.__apis.customer.examineInvalidCustomer({
                id: obj.Id,
                status: status
            })

            await this.__utils.index.sleep(2000)

            this.$message({
                type: 'success',
                message: '提交成功!'
            })
            this.getData()
        } catch (e) {
            console.log(e)
        } finally {
            loading.close()
        }
    }
}
