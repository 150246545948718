import { Vue, Component } from 'vue-property-decorator'
import EditComponent from './edit/index.vue'
import { propsOption } from '@/views/growManage/file/detail/edit'
import HeaderComponent from '../../header/index.vue'

interface item {
    id: number;
    is: boolean;
    planName: string;
    startTime: string;
    endTime: string;
    list: {
        label: string;
        value: string;
        username: string;
        actionTime: string;
    }[];
}

interface editComponent {
    show: boolean;
    item: propsOption['item'];
}

@Component({
    components: {
        EditComponent,
        HeaderComponent
    }
})
export default class IndexPage extends Vue {
    get
    title () {
        const res = this.username + '成长计划'
        document.title = res
        return res
    }

    created () {
        this.getData()
    }

    headerComponent = {
        breadcrumbs: [
            {
                label: '成长管理',
                path: '/grow-manage'
            }, {
                label: '成长档案',
                path: '/grow-manage/file'
            }
        ]
    }

    editComponent: editComponent = {
        show: false,
        item: {
            id: 0,
            name: '',
            time: [],
            need: '',
            plan: '',
            rs: '',
            leader: '',
            dep: ''
        }
    }
    edit (item: item) {
        this.editComponent.show = true
        this.editComponent.item = {
            id: item.id,
            name: item.planName,
            time: [item.startTime, item.endTime],
            need: item.list[0].value,
            plan: item.list[1].value,
            rs: item.list[2].value,
            leader: item.list[3].value,
            dep: item.list[4].value
        }
    }

    list: item[] = []
    username = ''
    async getData () {
        const { data } = await this.__apis.grow.getGrowListDetail(Number(this.$route.params.id))
        const now = Date.now()

        this.username = data.employee_name
        this.list = data.plan_list.map(a => {
            return {
                id: a.id,
                is: now > a.start_time && now < a.end_time,
                planName: a.name,
                startTime: this.__utils.index.formatDate(new Date(a.start_time), 'YYYY-MM-DD'),
                endTime: this.__utils.index.formatDate(new Date(a.end_time), 'YYYY-MM-DD'),
                list: [
                    {
                        label: '成长需求',
                        value: a.demand.content,
                        username: a.demand.action_user_name,
                        actionTime: a.demand.action_time ? this.__utils.index.formatDate(new Date(a.demand.action_time), 'YYYY-MM-DD') : ''
                    }, {
                        label: '成长计划',
                        value: a.program.content,
                        username: a.program.action_user_name,
                        actionTime: a.program.action_time ? this.__utils.index.formatDate(new Date(a.program.action_time), 'YYYY-MM-DD') : ''
                    }, {
                        label: '人事评价',
                        value: a.evaluation_hr.content,
                        username: a.evaluation_hr.action_user_name,
                        actionTime: a.evaluation_hr.action_time ? this.__utils.index.formatDate(new Date(a.evaluation_hr.action_time), 'YYYY-MM-DD') : ''
                    }, {
                        label: '上级评价',
                        value: a.evaluation_leader.content,
                        username: a.evaluation_leader.action_user_name,
                        actionTime: a.evaluation_leader.action_time ? this.__utils.index.formatDate(new Date(a.evaluation_leader.action_time), 'YYYY-MM-DD') : ''
                    }, {
                        label: '部门负责人评价',
                        value: a.evaluation_dep.content,
                        username: a.evaluation_dep.action_user_name,
                        actionTime: a.evaluation_dep.action_time ? this.__utils.index.formatDate(new Date(a.evaluation_dep.action_time), 'YYYY-MM-DD') : ''
                    }
                ]
            }
        })
    }
}
