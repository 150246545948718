import { Component, Vue, Watch } from 'vue-property-decorator'
import dateRangeSelectComponent from '@/components/wallet/date-range-select/index.vue'
import FilterComponent from '@/components/filters/filter/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'
import echarts from 'echarts'
import { __utils } from '@/util'
import { Table } from '@/types'
import { routerBaseUrl } from '@/router'
import dateRange from '@/util/date'
import EChartOption = echarts.EChartOption;

interface TableItem {
    id: number,
    department: string,
    group: string,
    fxs: string,
    month: string,
    income: string,
    expenditure: string,
    incomes: string,
    balance: string
}

interface Filter {
    [index: string]: string | string[]
    department: string
    group: string
    fxs: string
    month: string[]
}

const currentTime = __utils.index.formatTimeStartTimeEnd(dateRange.getCurrentMonth().map(a => `${__utils.index.formatDate(a, 'YYYY-MM-DD')}`))

@Component({
    components: {
        dateRangeSelectComponent,
        FilterComponent,
        SelectComponent,
        InputDateRangeComponent,
        FilterBusinessSelect
    }
})
export default class IndexPage extends Vue {
    // 时间发生变更
    @Watch('dateRangeSelectComponent.time')
    watchDateRange () {
        this.drawCharts()
    }

    filter: Filter = {
        department: '',
        group: '',
        fxs: '',
        month: currentTime
    }

    chars = {
        money: '',
        interest_money: '',
        interest_rate: 0
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 50
    }

    dateRangeSelectComponent = {
        time: __utils.index.formatTimeStartTimeEnd(['2020-03-01', __utils.index.formatDate(new Date(), 'YYYY-MM-DD')])
    }

    mounted () {
        this.drawCharts()
        this.getTableData()
    }

    exportTable () {
        this.__utils.index.downloadFile({
            action_name: 'fxsWalletMonthRecordExport',
            common_dep_id: this.filter.department,
            group_id: this.filter.group,
            employee_id: this.filter.fxs,
            time_start: this.filter.month[0],
            time_end: this.filter.month[1]
        })
    }

    async getTableData () {
        const { data } = await this.__apis.wallet.fxsWalletMonthRecord({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            common_dep_id: this.filter.department,
            group_id: this.filter.group,
            employee_id: this.filter.fxs,
            time_start: this.filter.month[0],
            time_end: this.filter.month[1]
        })

        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            let s = this.filter.month[0].split(' ')[0].slice(0, 7)
            let e = this.filter.month[1].split(' ')[0].slice(0, 7)
            let month

            if (s === e) {
                month = s
            } else {
                month = `${s} 至 ${e}`
            }

            return {
                id: a.employee_id,
                department: a.user_info.CommonDepartmentName,
                group: a.user_info.GroupName,
                fxs: a.user_info.UserName,
                month: month,
                income: this.__utils.index.formatMoney(a.income_money),
                expenditure: this.__utils.index.formatMoney(a.pay_money),
                incomes: this.__utils.index.formatMoney(a.interest_money),
                balance: this.__utils.index.formatMoney(a.wallet_money)
            }
        })
    }

    searchDep () {}

    searchFxs (text: string, cb: any) {
        this.__utils.index.searchEmployee(text, cb)
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        if (!this.filter.month) {
            this.filter.month = currentTime
        } else {
            this.filter.month = __utils.index.formatTimeStartTimeEnd(this.filter.month)
        }

        this.getTableData()
    }

    gotoDetail (id: number, name: string) {
        open(`${routerBaseUrl}/qm-wallet/fxs/detail?id=${id}&name=${encodeURIComponent(name)}`, '_blank')
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }

    async drawCharts () {
        const chart = echarts.init(document.querySelector('.echarts-instance') as HTMLDivElement)

        // 获取表格数据
        const { data } = await this.__apis.wallet.fxsWalletStatistics({
            time_start: this.dateRangeSelectComponent.time[0],
            time_end: this.dateRangeSelectComponent.time[1]
        })

        this.chars.money = this.__utils.index.formatMoney(data.money_info.money)
        this.chars.interest_money = this.__utils.index.formatMoney(data.money_info.interest_money)
        this.chars.interest_rate = data.money_info.interest_rate * 100

        let option: EChartOption = {
            xAxis: {
                type: 'category',
                data: data.char_list.save_money.map(a => this.__utils.index.formatChartDate(a.date)),
                splitLine: {
                    show: false
                },
                axisLabel: {
                    formatter (val: string) {
                        return val.replace(' ', '\n')
                    },
                    color: 'rgba(0,0,0,0.45)'
                },
                axisTick: {
                    alignWithLabel: true
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(0, 0, 0, .1)'
                    }
                }
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: 'rgba(0,0,0,0.45)',
                    verticalAlign: 'middle'
                }
            },
            tooltip: {
                trigger: 'axis'
            },
            color: ['#FA8C16', '#13C2C2'],
            // 存储金额 提现金额
            series: [{
                data: data.char_list.save_money.map(a => this.__utils.index.formatMoney(a.value)),
                lineStyle: {
                    color: '#FA8C16'
                },
                type: 'line',
                symbol: 'none'
            }, {
                data: data.char_list.cash_out_money.map(a => this.__utils.index.formatMoney(a.value)),
                lineStyle: {
                    color: '#13C2C2'
                },
                type: 'line',
                symbol: 'none'
            }],
            grid: {
                width: 752,
                height: 147,
                top: 12,
                left: 0,
                containLabel: true
            }
        }

        chart.setOption(option)
    }
}
