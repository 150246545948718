import { Component, Vue } from 'vue-property-decorator'
import buttonsComponent from '@/components/wallet/buttons/index.vue'
import CommissionDetailComponent from '@/views/qmWallet/fxs/wait-commission-detail/commission-detail/index.vue'
import IncomeDetailComponent from '@/views/qmWallet/fxs/wait-commission-detail/income-detail/index.vue'

@Component({
    components: {
        buttonsComponent,
        CommissionDetailComponent,
        IncomeDetailComponent
    }
})
export default class WaitCommissionDetailPage extends Vue {
    buttonsComponent = {
        active: 0,
        items: ['未发放提成明细', '收益明细']
    }

    tabsChange (a: number) {
        this.buttonsComponent.active = a
    }
}
