import router, { routes } from '../router'
import Vue from 'vue'
import { DirectiveFunction } from 'vue/types/options'
import { installPlugin } from '@/util/install'
import { __store } from '@/store'
import Permissions from '@/role/permissions'
import qmPermission from 'qm-plugin-admin-permission'

export type keys = 'grow_file' | 'grow_xxb'
type KeysMapBoolean = Record<keys, boolean>
type KeysMapString = Record<keys, keys>

declare module 'vue/types/vue' {
    interface Vue {
        __role: Role
    }
}

class Role {
    KEYS: KeysMapString = {
        'grow_file': 'grow_file',
        'grow_xxb': 'grow_xxb'
    }

    permissions = Permissions

    check (key: Permissions) {
        return qmPermission.check(key)
    }

    keys: KeysMapBoolean = {} as KeysMapBoolean

    ROLE = {
        FENXISHI: 3, // 分析师
        SHENGZHANG: 7, // 省长
        DUIZHANG: 10, // 队长
        CAIWUKUAIJI: 11, // 财务会计
        XIAOSHOUZONGJIAN: 12, // 销售总监
        QUDAOJINGLI: 15, // 渠道经理
        RENSHI: 16, // 人事
        KEFU: 14 // 客服
    }

    // 用到的权限组
    TYPE = {
        FXSSZ: [this.ROLE.FENXISHI, this.ROLE.SHENGZHANG], // 分析师 省长
        DZ: [this.ROLE.DUIZHANG], // 队长
        SZCWKJ: [this.ROLE.SHENGZHANG, this.ROLE.CAIWUKUAIJI], // 省长 财务会计
        SZQDJL: [this.ROLE.SHENGZHANG, this.ROLE.QUDAOJINGLI] // 省长 渠道经理
    }

    has (types: number[], all = true) {
        // 销售总监所有权限都有
        if (all && __store.state.user.userInfo.role_id === this.ROLE.XIAOSHOUZONGJIAN) return true

        return types.includes(__store.state.user.userInfo.role_id)
    }

    can (key: boolean) {
        return key
    }

    private initRouter () {
        this.filter(routes)
        qmPermission.filter(routes)

        router.addRoutes(routes)
    }

    init () {
        const devKey = require('./keys.dev').default
        const prodKey = require('./keys.prod').default
        this.keys = process.env.NODE_ENV === 'development' ? devKey : prodKey

        this.initRouter()
    }

    filter (values: any[]) {
        for (let i = 0; i < values.length; i++) {
            let a = values[i]

            for (let key in a) {
                const isObject = Object.prototype.toString.call(a[key])

                // 如果这个值是对象或者数组则递归
                if (isObject.includes('Object') || isObject.includes('Array')) {
                    this.filter(a[key])
                }
            }

            const all = a.all === undefined ? true : a.all
            const key = a.key as keys
            // key and role.TYPE 两者不可以并存
            if ((a.role && !this.has(a.role, all)) ||
                (a.key !== undefined && !this.can(this.keys[key]))) {
                values.splice(i, 1)
                i--
            }
        }
    }
}

interface roleEditValue {
    authKey: Permissions
    selector: string
    values: any[]
}

const RoleEditBind: DirectiveFunction = function (el, value) {
    const values = value.value as roleEditValue

    if (!__role.check(values.authKey)) {
        const inputs = Array.from(el.querySelectorAll(values.selector)) as HTMLDivElement[]
        inputs.forEach((a, i) => {
            a.innerText = values.values[i]
        })
    }
}

Vue.directive('role-edit', {
    bind: RoleEditBind,
    update: RoleEditBind
})

// 在 Vue 实例中注入 同时 开放实例本身
export const __role = new Role()
export default {
    install: installPlugin('__role', __role)
}
