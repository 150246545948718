// 修复浏览器禁用cookie导致的localStorage访问拒绝问题

import { MockLocalStorage } from '@/util/mockLocalStorage'

function useMockStorage () {
    Object.defineProperty(window, 'localStorage', {
        value: new MockLocalStorage()
    })
    Object.defineProperty(window, 'sessionStorage', {
        value: new MockLocalStorage()
    })
}

const fixLocalStorageAccessDenied = (() => {
    try {
        if (!window) {
            return false
        }
        if (!window.localStorage) {
            // 如果浏览器禁用了cookie
            // 使用模拟localStorage
            console.log('WARNING: localStorage Access Denied.')
            useMockStorage()
        }
    } catch (e) {
        console.log('WARNING: localStorage Access Denied.')
        useMockStorage()
    }
    return true
})()

export default fixLocalStorageAccessDenied
