import { Component, Vue, Watch } from 'vue-property-decorator'
import DateSelect from '@/components/DateSelect/index.vue'
import FilterComponent from '@/components/filters/filter/index.vue'
import inputDateRange from '@/components/filters/inputDateRange/index.vue'
import InputSelectComponent from '@/components/filters/inputSelect/index.vue'
import numberRangeComponent from '@/components/filters/numberRange/index.vue'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'
import selectComponent from '@/components/filters/select/index.vue'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'
import { storageName } from '@/localStorage'
import { getOverviewDataRes } from '@/api/channelManage'
import { routerBaseUrl } from '@/router'
import { ExitStatus, GetMoneyStatus } from '@/util'

const echarts = require('echarts')

@Component({
    components: {
        'el-dateSelect': DateSelect,
        FilterComponent,
        inputDateRange,
        InputSelectComponent,
        numberRangeComponent,
        CheckBoxComponent,
        selectComponent,
        FilterBusInputSelectComponent,
        FilterBusSelectComponent
    }
})
export default class IndexPage extends Vue {
    // 顶部概览相关
    chartEle: any[] = []
    chartList: any[] = [
        { title: '已结佣金额', class: 'jy_money', money: true },
        { title: '已结佣订单数', class: 'jy_count', money: false },
        { title: '已开票金额', class: 'kp_money', money: true },
        { title: '已开票订单数', class: 'kp_count', money: false },
        { title: '已网签订单数', class: 'wq_count', money: false },
        { title: '已退房订单数', class: 'tf_count', money: false }
    ]
    liduList = [
        { index: 1, name: '日粒度' },
        { index: 2, name: '周粒度' },
        { index: 3, name: '月粒度' }
    ]
    form = {
        lidu: 1,
        time_start: '',
        time_end: ''
    }
    // 概览列表
    overViewList: getOverviewDataRes[] = []
    // 排序对象
    sortObj = {}
    // 底部大列表
    tableMode = 'jj'
    dataObj = {
        list: [1, 2, 3, 4],
        total: 0,
        statistic_res: {}
    }
    statisticRes = {}
    page = 1
    pageSize = 20
    // 大列表筛选弹窗
    formLabelWidth = '90px'
    dialogFormVisible = {
        visible: false,
        target: 'time'
    }
    dialogForm: any = {
        cjDate: '',
        cjMoney: [],
        must_dz_money: [],
        user_name: '',
        user_id: '',
        group: '',
        group_id: '',
        dep: '',
        cjHouse: '',
        cjQuyu: '',
        cjDay: [],
        customerName: '',
        customerTel: '',
        fromQudao: '',
        cjQudao: '',
        wqStatus: '',
        yujiWqDate: [],
        wqDate: [],
        kpDate: [],
        kpMoney: [],
        kpStatus: '',
        jyDate: [],
        jyMoney: [],
        jyStatus: '',
        fyDate: [],
        fyMoney: [],
        fyStatus: '',
        isTf: '',
        tfDate: []

    }
    wqStatusList = [
        {
            label: '未网签',
            value: 0
        },
        {
            label: '已网签',
            value: 1
        }
    ]
    kpStatusList = [
        {
            label: '未开票',
            value: 0
        },
        {
            label: '部分开票',
            value: 1
        },
        {
            label: '全部开票',
            value: 2
        },
        {
            label: '无需开票',
            value: 3
        }
    ]
    jyStatusList = GetMoneyStatus
    fyStatusList = [
        {
            label: '无需返佣',
            value: 0
        },
        {
            label: '待返佣',
            value: 1
        },
        {
            label: '已返佣',
            value: 2
        }
    ]
    tfStatusList = ExitStatus
    merageArrList: any = []
    channelList = {}
    channelShowList: any[] = []

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }
    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }

    @Watch('form', {
        deep: true
    })
    watchForm () {
        this.getData()
    }
    mounted () {
        this.getOverviewData()
        this.getCjListData()
    }

    getEchartsObj () {
        let arrClass = ['jy_money', 'jy_count', 'kp_money', 'kp_count', 'wq_count', 'tf_count']
        let chartsArr: any[] = []
        arrClass.map((v, i) => {
            chartsArr[i] = echarts.init(document.getElementById(v))
            chartsArr[i].showLoading({
                text: '数据正在努力加载...',
                textStyle: {
                    fontSize: 30,
                    color: '#444'
                },
                effectOption: {
                    backgroundColor: 'rgba(0, 0, 0, 0)'
                }
            })
        })
        this.chartEle = chartsArr
    }

    // 初始化图表
    echatrtInit (obj: any, myChart: any) {
        let monthArr: any[] = []
        let legendArr: any[] = []

        let color = [
            'rgba(245, 43, 68, 1)',
            'rgba(63, 138, 240, 1)',
            'rgba(245, 166, 35, 1)',
            'rgba(69, 201, 56, 1)',
            'rgba(208, 83, 240, 1)',
            'rgba(63, 210, 240, 1)'
        ]

        // y轴数据
        obj.data && obj.data.forEach((v: any, i: number) => {
            v.type = 'line'
            v.smooth = true 		// 当为true时，就是光滑的曲线（默认为true）；
            v.color = [color[i]]
            v.name = v.dep_name.includes('杭州') ? v.dep_name.substr(0, 3) : v.dep_name.substr(0, 2)
            v.dataArr = v.data

            v.data = v.dataArr.map((vv: any) => {
                return vv.value
            })

            legendArr.push({
                name: v.name,
                icon: 'circle',
                textStyle: {
                    borderRadius: 4
                }
            })
        })

        let totalArr = obj.data || []

        // x轴数据
        obj.data && obj.data.map((v: any) => {
            monthArr = v.dataArr.map((vv: any) => {
                if (vv.date.includes('-')) {
                    vv.date = vv.date.split('-')
                    vv.date = `${vv.date[0].substr(0, 4)}.${vv.date[0].substr(4, 2)}.${vv.date[0].substr(6, 2)}-${vv.date[1].substr(0, 4)}.${vv.date[1].substr(4, 2)}.${vv.date[1].substr(6, 2)} `
                } else {
                    if (vv.date.length === 6) {
                        vv.date = `${vv.date.substr(0, 4)}.${vv.date.substr(4, 2)}`
                    } else {
                        vv.date = `${vv.date.substr(0, 4)}.${vv.date.substr(4, 2)}.${vv.date.substr(6, 2)}`
                    }
                }
                return vv.date
            })
        })

        let options = {
            title: {
                x: 'left',
                text: obj.title,
                textStyle: {
                    fontSize: 16
                }
            },
            legend: {
                x: 'right',
                data: legendArr,
                itemWidth: 8,
                itemHeight: 8
            },
            grid: {
                left: '1%',
                right: '2%',
                bottom: '2%',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',	 // 鼠标一上去显示数据
                padding: [
                    10, // 上
                    10, // 右
                    10, // 下
                    10 // 左
                ],
                backgroundColor: 'rgba(255,255,255,1)',
                extraCssText: 'box-shadow:0 1px 8px 0 rgba(184,184,184,0.5);',
                textStyle: {
                    color: '#333'
                },
                formatter (params: any[]) {
                    let result = ''
                    params.forEach((item: any, index: number) => {
                        if (index === 0) {
                            result += `<div>${item.name}</div>`
                        }
                        result += `<div
								class="display:flex;flex-direction: row;align-item:center;">
								<span style="display:inline-block;    	vertical-align: middle;margin-right:5px;border-radius:3px;
									width:6px;height:6px;
									background-color:${color[index]};">
								</span>
								<span style='margin-right:6px;'>
									${item.seriesName}
								</span>
								${item.data}
								</div>`
                    })
                    return result
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: true, // 坐标轴两边留白策略
                splitLine: { show: false },
                data: monthArr
            },
            splitLine: {
                show: true
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: obj.money ? '{value}元' : '{value}单'
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    lineStyle: {
                        type: 'dashed'
                    }
                },
                min: 'dataMin',
                max: 'dataMax'
            },
            series: totalArr
        }

        // 绘制图表
        myChart.setOption(options)

        if (totalArr.length === 0) {
            myChart.dispose()
        }

        myChart.hideLoading()
    }

    // 日期组件选择反馈
    clickDateHandle (obj: any) {
        if (obj.time_start === '' && obj.time_end === '' && obj.index * 1 === 0) {
            obj.time_start = new Date(new Date().getFullYear(), 0, 1).format('yyyy-MM-dd')
            obj.time_end = new Date(new Date().getFullYear(), 11, 31).format('yyyy-MM-dd')
            obj.lidu = 3
        }
        this.form = {
            ...this.form,
            ...obj
        }
    }

    // 获取图表数据
    async getData () {
        this.getEchartsObj()
        let data = {
            time_start: this.form.time_start,
            time_end: this.form.time_end,
            time_range: this.form.lidu
        }

        const res = await this.__apis.channelManage.cuiYongChart(data)

        let datas = res.data
        let arr = []
        for (let key in datas) {
            for (let k in datas[key]) {
                if (!datas[key].data) {
                    datas[key].data = []
                }
                datas[key].data.push(datas[key][k])
            }
            datas[key].class = key
            datas[key].money = key.includes('money')
            arr.push(datas[key])
        }
        arr.map((v) => {
            this.chartList.map((vv) => {
                if (v.class === vv.class) {
                    v.title = vv.title
                }
            })
        })

        this.chartList = arr

        arr.map((v, i) => {
            this.echatrtInit(v, this.chartEle[i])
        })
    }

    // 获取概览数据
    async getOverviewData () {
        const res = await this.__apis.channelManage.getOverviewData()

        res.data.map((v) => {
            v.not_jy_money = v.not_jy_money ? Number(v.not_jy_money.toFixed(2)) : v.not_jy_money
        })
        this.overViewList = res.data
    }

    // 获取成交数据列表
    async getCjListData () {
        let form = this.dialogForm
        let data: any = Object.assign({
            page: this.page,
            pagesize: this.pageSize,
            cj_time_start: form.cjDate && form.cjDate[0],
            cj_time_end: form.cjDate && form.cjDate[1],
            cj_money_min: form.cjMoney && form.cjMoney[0],
            cj_money_max: form.cjMoney && form.cjMoney[1],
            must_dz_money_min: form.must_dz_money && form.must_dz_money[0],
            must_dz_money_max: form.must_dz_money && form.must_dz_money[1],
            fxs_user_id: form.user_id,
            group_id: form.group_id,
            dep_id: form.dep > 0 ? form.dep : '',
            house_name: form.cjHouse,
            house_region: form.cjQuyu,
            cj_day_min: form.cjDay && form.cjDay[0],
            cj_day_max: form.cjDay && form.cjDay[1],
            customer_name: form.customerName,
            customer_phone: form.customerTel,
            customer_channel: form.fromQudao,
            sale_channel_name: form.cjQudao,
            has_wangqian: form.wqStatus,
            expect_wq_time_start: form.yujiWqDate && form.yujiWqDate[0],
            expect_wq_time_end: form.yujiWqDate && form.yujiWqDate[1],
            wq_time_start: form.wqDate && form.wqDate[0],
            wq_time_end: form.wqDate && form.wqDate[1],
            kp_time_start: form.kpDate && form.kpDate[0],
            kp_time_end: form.kpDate && form.kpDate[1],
            kp_money_min: form.kpMoney && form.kpMoney[0],
            kp_money_max: form.kpMoney && form.kpMoney[1],
            kp_status: form.kpStatus,
            jy_time_start: form.jyDate && form.jyDate[0],
            jy_time_end: form.jyDate && form.jyDate[1],
            jy_money_min: form.jyMoney && form.jyMoney[0],
            jy_money_max: form.jyMoney && form.jyMoney[1],
            jy_status: form.jyStatus,
            fy_time_start: form.fyDate && form.fyDate[0],
            fy_time_end: form.fyDate && form.fyDate[1],
            fy_money_min: form.fyMoney && form.fyMoney[0],
            fy_money_max: form.fyMoney && form.fyMoney[1],
            fy_status: form.fyStatus,
            has_tuifan: form.isTf,
            tf_time_start: form.tfDate && form.tfDate[0],
            tf_time_end: form.tfDate && form.tfDate[1]

        }, this.sortObj)

        const res = await this.__apis.channelManage.getCjListData(data)

        let datas: any = res.data
        datas.list.map((v: any, i: number) => {
            v.sale_time = v.sale_time ? new Date(v.sale_time * 1000).format('yyyy-MM-dd') : ''
            v.tui_fang_time = v.tui_fang_time ? new Date(v.tui_fang_time * 1000).format('yyyy-MM-dd') : ''
            v.wangqian_time = v.wangqian_time ? new Date(v.wangqian_time * 1000).format('yyyy-MM-dd') : ''
            v.expect_wangqian_time = v.expect_wangqian_time ? new Date(v.expect_wangqian_time * 1000).format('yyyy-MM-dd') : ''
            v.expect_next_jy_time = v.expect_next_jy_time ? new Date(v.expect_next_jy_time * 1000).format('yyyy-MM-dd') : ''

            v.kaipiao_time_arr = v.kaipiao_time_arr.map((item: any) => {
                return new Date(item * 1000).format('yyyy-MM-dd')
            })
            v.fanyong_time_arr = v.fanyong_time_arr.map((item: any) => {
                return new Date(item * 1000).format('yyyy-MM-dd')
            })
            v.jieyong_time_arr = v.jieyong_time_arr.map((item: any) => {
                return new Date(item * 1000).format('yyyy-MM-dd')
            })

            v.fxs_user_info = v.fxs_user_info ? JSON.parse(v.fxs_user_info) : []
            v.cy_note = v.cy_note ? JSON.parse(v.cy_note) : {}
            v.cy_note.Ctime = v.cy_note.Ctime && v.cy_note.Ctime.split(' ')[0]

            v.sale_sum_money = this.__utils.index.numFormat(v.sale_sum_money)
            v.sum_must_dz_money = this.__utils.index.numFormat(v.sum_must_dz_money)
            v.jieyong_money = this.__utils.index.numFormat(v.jieyong_money)
            v.kaipiao_money = this.__utils.index.numFormat(v.kaipiao_money)
            v.jieyong_money_arr = v.jieyong_money_arr.map((item: any) => {
                return this.__utils.index.numFormat(item)
            })
            v.kaipiao_money_arr = v.kaipiao_money_arr.map((item: any) => {
                return this.__utils.index.numFormat(item)
            })
            v.fanyong_money_arr = v.fanyong_money_arr.map((item: any) => {
                return this.__utils.index.numFormat(item)
            })

            this.channelShowList[i] = false

            // 合并单元格内小表格
            v.fxs_user_info.map((vv: any) => {
                vv.parentIndex = i
            })

            this.merageArrList[i] = this.merageArr(v.fxs_user_info)

            v.fxs_user_info.map((item: any) => {
                item.money = this.__utils.index.numFormat(item.money)
            })
        })

        datas.statistic_res = datas.statistic_res || this.dataObj.statistic_res
        if (datas.statistic_res) {
            for (let key in datas.statistic_res) {
                if (key.includes('money')) {
                    datas.statistic_res[key] = this.__utils.index.numFormat(datas.statistic_res[key])
                }
            }
            datas.list.unshift(Object.assign({
                sale_time: '总计',
                kaipiao_money_arr: [datas.statistic_res.kaipiao_money],
                jieyong_money_arr: [datas.statistic_res.jieyong_money],
                fanyong_money_arr: [
                    `待返佣(${datas.statistic_res.fanyong_money_1})`,
                    `已返佣(${datas.statistic_res.fanyong_money_2})`
                ]
            }, datas.statistic_res))
            this.statisticRes = datas.list[0]
        } else {
            datas.list.unshift(this.statisticRes)
        }

        this.dataObj = datas
    }

    // 精简模式和完整模式切换
    tableModeChange (type: string) {
        this.tableMode = type
    }

    // 清除所有筛选条件
    clearFilter () {
        const dialogForm = this.$refs['dialogForm'] as any
        dialogForm.resetFields()

        this.dialogForm = Object.assign(this.dialogForm, {
            cjMoney: [],
            cjDay: [],
            kpMoney: [],
            jyMoney: [],
            fyMoney: [],
            user_id: '',
            group_id: '',
            yujiWqDate: [],
            wqDate: [],
            kpDate: []
        })

        this.page = 1
        this.getCjListData()
    }

    // table列排序
    sortChange (column: any) {
        if (column.prop === 'sale_sum_money') {
            column.prop = 'sale_money'
        } else if (column.prop === 'sum_must_dz_money') {
            column.prop = 'must_dz_money'
        }
        this.sortObj = {
            sort_field: column.prop,
            sort_mode: column.order === 'ascending' ? 'asc' : 'desc'
        }
        this.getCjListData()
    }

    // 编辑记录
    toEdit (id: number) {
        open(`${routerBaseUrl}/financial/success_detail/success/${id}`, '_blank')
    }

    // 分页改变
    pageChange (page: number) {
        this.page = page
        this.getCjListData()
    }

    queryGroupSearch (queryString: string, cb: any) {
        this.__utils.index.searchGroup(queryString, cb)
    }

    groupSelect (item: any) {
        this.dialogForm.group_id = item.value
    }

    // 分析师，战队，部门
    arraySpanMethod ({ columnIndex }: any) {
        if (columnIndex === 3) {
            return [1, 3]
        } else if (columnIndex === 4) {
            return [0, 0]
        } else if (columnIndex === 5) {
            return [0, 0]
        }
    }

    // 内部表格合并
    fxsSpanArray ({ row, rowIndex, columnIndex }: any) {
        if (columnIndex === 1) {
            return this.returnTableSpan(this.merageArrList[row.parentIndex].groupArr, rowIndex)
        } else if (columnIndex === 2) {
            return this.returnTableSpan(this.merageArrList[row.parentIndex].depArr, rowIndex)
        }
    }

    // 返回合并行，列
    returnTableSpan (arr: any[], rowIndex: number) {
        const _row = arr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
            rowspan: _row,
            colspan: _col
        }
    }

    // 合并单元格函数
    merageArr (arr: any[]) {
        let groupArr: any[] = []
        let groupIndex = 0
        let depArr: any[] = []
        let depIndex = 0
        let total: any = {}

        arr.map((v, i) => {
            if (!total[v.group_name]) {
                total[v.group_name] = 0
            }
            if (!total[v.dep_name]) {
                total[v.dep_name] = 0
            }
            total[v.group_name] += v.money * 1
            total[v.dep_name] += v.money * 1
            if (i === 0) {
                groupArr.push(1)
                groupIndex = 0
                depArr.push(1)
                depIndex = 0
            } else {
                if (arr[i].group_name === arr[i - 1].group_name) {
                    groupArr[groupIndex] += 1
                    groupArr.push(0)
                } else {
                    groupArr.push(1)
                    groupIndex = i
                }
                if (arr[i].dep_name === arr[i - 1].dep_name) {
                    depArr[depIndex] += 1
                    depArr.push(0)
                } else {
                    depArr.push(1)
                    depIndex = i
                }
            }
            v.total = total
        })
        for (let key in total) {
            total[key] = this.__utils.index.numFormat(total[key])
        }
        return {
            groupArr,
            depArr,
            total
        }
    }

    // 筛选
    filterChange (data: any) {
        for (let key in this.dialogForm) {
            this.dialogForm[key] = data[key]
        }
        this.page = 1
        this.getCjListData()
    }

    async searchUser (queryString: string, cb: any) {
        // eslint-disable-next-line standard/no-callback-literal
        if (!queryString || queryString.length === 0) cb([])

        const res = await this.__apis.utils.searchUser({
            key: queryString
        })

        res.data.list.map((v: any) => {
            v.label = v.Username
            v.value = v.Id
        })
        cb(res.data.list)
    }

    async searchHouse (queryString: string, cb: any) {
        // eslint-disable-next-line standard/no-callback-literal
        if (!queryString || queryString.length === 0) cb([])

        const res = await this.__apis.utils.searchHouse({
            term: queryString
        })

        cb(res.data)
    }

    queryDepSearch (queryString: string, cb: any) {
        this.__utils.index.searchDep(queryString, cb)
    }

    showChannelDjr (obj: any) {
        this.getChannelDjr(obj.sale_channel_id)
        obj.channelDisabled = !obj.channelDisabled
    }

    async getChannelDjr (id: number) {
        if (id === 0) {
            this.channelList = []
            return
        }

        const res = await this.__apis.channelManage.getPartnerDetail(id)

        this.channelList = res.data.DuijieUser
    }

    // 导出
    exportList () {
        let data: any = Object.assign({
            action_name: 'exportCjListData',
            type: this.tableMode === 'wz' ? 2 : 1,
            cj_time_start: this.dialogForm.cjDate && this.dialogForm.cjDate[0],
            cj_time_end: this.dialogForm.cjDate && this.dialogForm.cjDate[1],
            cj_money_min: this.dialogForm.cjMoney && this.dialogForm.cjMoney[0],
            cj_money_max: this.dialogForm.cjMoney && this.dialogForm.cjMoney[1],
            must_dz_money_min: this.dialogForm.must_dz_money && this.dialogForm.must_dz_money[0],
            must_dz_money_max: this.dialogForm.must_dz_money && this.dialogForm.must_dz_money[1],
            fxs_user_id: this.dialogForm.user_id,
            group_id: this.dialogForm.group_id,
            dep_id: this.dialogForm.dep > 0 ? this.dialogForm.dep : '',
            house_name: this.dialogForm.cjHouse,
            house_region: this.dialogForm.cjQuyu,
            cj_day_min: this.dialogForm.cjDay && this.dialogForm.cjDay[0],
            cj_day_max: this.dialogForm.cjDay && this.dialogForm.cjDay[1],
            customer_name: this.dialogForm.customerName,
            customer_phone: this.dialogForm.customerTel,
            customer_channel: this.dialogForm.fromQudao,
            sale_channel_name: this.dialogForm.cjQudao,
            has_wangqian: this.dialogForm.wqStatus,
            expect_wq_time_start: this.dialogForm.yujiWqDate && this.dialogForm.yujiWqDate[0],
            expect_wq_time_end: this.dialogForm.yujiWqDate && this.dialogForm.yujiWqDate[1],
            wq_time_start: this.dialogForm.wqDate && this.dialogForm.wqDate[0],
            wq_time_end: this.dialogForm.wqDate && this.dialogForm.wqDate[1],
            kp_time_start: this.dialogForm.kpDate && this.dialogForm.kpDate[0],
            kp_time_end: this.dialogForm.kpDate && this.dialogForm.kpDate[1],
            kp_money_min: this.dialogForm.kpMoney && this.dialogForm.kpMoney[0],
            kp_money_max: this.dialogForm.kpMoney && this.dialogForm.kpMoney[1],
            kp_status: this.dialogForm.kpStatus,
            jy_time_start: this.dialogForm.jyDate && this.dialogForm.jyDate[0],
            jy_time_end: this.dialogForm.jyDate && this.dialogForm.jyDate[1],
            jy_money_min: this.dialogForm.jyMoney && this.dialogForm.jyMoney[0],
            jy_money_max: this.dialogForm.jyMoney && this.dialogForm.jyMoney[1],
            jy_status: this.dialogForm.jyStatus,
            fy_time_start: this.dialogForm.fyDate && this.dialogForm.fyDate[0],
            fy_time_end: this.dialogForm.fyDate && this.dialogForm.fyDate[1],
            fy_money_min: this.dialogForm.fyMoney && this.dialogForm.fyMoney[0],
            fy_money_max: this.dialogForm.fyMoney && this.dialogForm.fyMoney[1],
            fy_status: this.dialogForm.fyStatus,
            has_tuifan: this.dialogForm.isTf,
            tf_time_start: this.dialogForm.tfDate && this.dialogForm.tfDate[0],
            tf_time_end: this.dialogForm.tfDate && this.dialogForm.tfDate[1]
        })

        this.__utils.index.downloadFile(data)
    }
}
