import { BaseRes, ListReq, ListRes, php } from '@/api/index'
import { userInfo6 } from '@/api/customer'
import { basicUserInfo } from '@/api/user'

interface getKnowledgeLibDetailRes {
    Id: number
    Name: string
    Description: string
    Img: string
    AdminList: {
        UserId: string
        UserInfo: userInfo6
    }[]
    DirSort: string
    Ctime: string
    FirstDocId: string
    FirstDirId: number
}

interface saveKnowledgeLibDirReq {
    id?: string
    name: string
    lib_id?: string
    pid?: number
}

interface saveKnowledgeLibDirRes {
    id: string
}

interface saveKnowledgeLibReq {
    id: string
    dir_sort: string
}

interface getKnowledgeLibDirListRes {
    [index: string]: {
        Id: number
        Name: string
        Pid: number
        UserId: number
        LibId: number
        Ctime: string
    }
}

interface getKnowledgeDocListReq extends ListReq {
    dir_id: string
}

interface getKnowledgeLibDocDetail {
    Id: number
    Title: string
    Body: string
    UserId: number
    LibId: number
    DirId: number
    Ctime: string
    Mtime: string
}
interface getKnowledgeDocListRes extends ListRes<getKnowledgeLibDocDetail & {
    ModifyRecords: string
}>{}

interface getKnowledgeLibDocDetailRes extends getKnowledgeLibDocDetail {
    ModifyRecords: {
        time: string
        user_id: number
        username: string
    }[]
    UserCenterInfo: {
        basic_info: basicUserInfo & {
            DepInfo: {
                name: string
                value: number
                intro: string
                img: string
            }
        }
    }
}

interface saveKnowledgeLibDocReq {
    id: string
    title: string
    body: string
    lib_id: string
    dir_id: string
}

interface getKnowledgeLibListRes extends ListRes<{
    Id: number
    Name: string
    Description: string
    Img: string
    AdminList: string
    DirSort: string
    Ctime: string
}>{}

export default class ApiKnowLedge {
    getKnowledgeLibDetail (id: string): BaseRes<getKnowledgeLibDetailRes> {
        return php.post('', {
            action_name: 'getKnowledgeLibDetail',
            id
        })
    }

    saveKnowledgeLibDir (data: saveKnowledgeLibDirReq): BaseRes<saveKnowledgeLibDirRes> {
        return php.post('', {
            action_name: 'saveKnowledgeLibDir',
            ...data
        })
    }

    deleteKnowledgeLibDir (id: number): BaseRes<saveKnowledgeLibDirRes> {
        return php.post('', {
            action_name: 'deleteKnowledgeLibDir',
            id
        })
    }

    saveKnowledgeLib (data: saveKnowledgeLibReq): BaseRes<saveKnowledgeLibDirRes> {
        return php.post('', {
            action_name: 'saveKnowledgeLib',
            ...data
        })
    }

    getKnowledgeLibDirList (lib_id: string): BaseRes<getKnowledgeLibDirListRes> {
        return php.post('', {
            action_name: 'getKnowledgeLibDirList',
            lib_id
        })
    }

    deleteKnowledgeLib (id: string): BaseRes<saveKnowledgeLibDirRes> {
        return php.post('', {
            action_name: 'deleteKnowledgeLib',
            id
        })
    }

    deleteKnowledgeLibDoc (id: string): BaseRes<saveKnowledgeLibDirRes> {
        return php.post('', {
            action_name: 'deleteKnowledgeLibDoc',
            id
        })
    }

    getKnowledgeDocList (data: getKnowledgeDocListReq): BaseRes<getKnowledgeDocListRes> {
        return php.post('', {
            action_name: 'getKnowledgeDocList',
            ...data
        })
    }

    getKnowledgeLibDocDetail (id: string): BaseRes<getKnowledgeLibDocDetailRes> {
        return php.post('', {
            action_name: 'getKnowledgeLibDocDetail',
            id
        })
    }

    saveKnowledgeLibDoc (data: saveKnowledgeLibDocReq) {
        return php.post('', {
            action_name: 'saveKnowledgeLibDoc',
            ...data
        })
    }

    getKnowledgeLibList (): BaseRes<getKnowledgeLibListRes> {
        return php.post('', {
            action_name: 'getKnowledgeLibList'
        })
    }
}
