import { Component, Prop, Vue } from 'vue-property-decorator'
import { Notification } from 'element-ui'
import { SuccessDetailDispatchType } from '@/store/successDetail'

interface options {
    show: boolean,
    remark: string,
    money: string,
    date: string,
    id: number
}

@Component
export default class SuccessDetailBilling extends Vue {
    @Prop({
        type: Object,
        default: {
            show: false,
            remark: '',
            money: '',
            date: '',
            id: undefined
        }
    })
    options !: options

    get
    cmtBtnSaveEnabled () {
        return !!(this.options.date && this.options.money !== undefined && this.options.remark)
    }

    closeSelf () {
        this.options.show = false
    }

    async save () {
        await this.__apis.financial.saveSaleKpRecord({
            sale_id: +this.$route.params.id,
            remark: this.options.remark,
            money: this.options.money,
            id: this.options.id,
            kaipiao_time: this.options.date
        })

        // 保存成功
        Notification.success('操作')
        this.__store.dispatch(SuccessDetailDispatchType.setAllData, +this.$route.params.id)

        this.closeSelf()
    }
}
