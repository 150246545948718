import { Component, Vue } from 'vue-property-decorator'
import InputHouseSearch from '@/components/InputHouseSearch/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'
import FilterComponent from '@/components/filters/filter/index.vue'
import mixin from '../../mixin'

@Component({
    components: {
        InputHouseSearch,
        FilterComponent,
        FilterBusSelectComponent
    },
    mixins: [mixin]
})
export default class OrderCustomerPage extends Vue {
    dataObj: any = {
        res: []
    }
    page = 1
    pageSize = 50
    total = 0
    sortType = ''
    sortMode = ''
    is_not_follow = false
    formLabelWidth = '90px'
    applyInvalidStatus = [
        { label: '', value: -1 }, // 不能申请 false
        { label: '申请中', value: 0 }, // false
        { label: '通过', value: 1 }, // false
        { label: '驳回', value: 2 }, // true
        { label: '', value: 3 } // 从来没有申请，且可以申请 true
    ]
    editCustomer = {
        visible: false,
        data: {}
    }

    mounted () {
        this.getData()
    }

    followFilter () {
        this.is_not_follow = !this.is_not_follow
        this.getData()
    }

    editDialog (item:any) {
        this.editCustomer.data = item
        this.editCustomer.visible = true
    }
    private user_id?: number
    async getData () {
        const res = await this.__apis.customer.getCustomerList({
            type: 'new',
            sort_field: this.sortType,
            sort_mode: this.sortMode,
            user_id: this.user_id,
            is_not_follow: this.is_not_follow ? Number(this.is_not_follow) : undefined,
            page: this.page,
            pagesize: this.pageSize
        })

        res.data.res.customers.map((v:any) => {
            v.apply_invalid_status_str = (this.applyInvalidStatus.filter((a:any) => a.value === v.apply_invalid_status)[0] || {}).label || ''
        })

        this.dataObj = res.data
        this.total = res.data.res.total
        this.$emit('totalChange', this.total)
    }
    // 筛选
    filterChange (data: any) {
        this.user_id = data.user_id
        this.page = 1
        this.getData()
    }
    async applyInvalid (item:any) {
        try {
            await this.$confirm('请再次确认该用户是否为无效客户，申请后无法更改，申请成功后退回扣除的金额！', '申请退回客户', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                customClass: 'my_confirm',
                center: true
            })

            await this.__apis.customer.applyInvalidCustomer(item.id)
            this.$message({
                type: 'success',
                message: '申请成功!'
            })
            this.getData()
        } catch (e) {
            console.log(e)
        }
    }
}
