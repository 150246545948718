export type Config = {
    title: string
    tip: string
}

export const config: {
    [index: number]: Config
} = {
    1: {
        title: '新客户数',
        tip: '所选时间内建立绑定关系的客户数'
    },
    2: {
        title: '跟进客户数',
        tip: '所选时间内跟进的客户数（多次跟进的按客户去重）'
    },
    3: {
        title: '发布跟进数',
        tip: '所选时间内发布的跟进数'
    },
    4: {
        title: '带看客户数',
        tip: ''
    },
    5: {
        title: '成交客户数',
        tip: '所选时间内成交的客户数（多次成交的按客户去重）'
    },

    6: {
        title: '成交套数',
        tip: ''
    },
    7: {
        title: '成交金额',
        tip: '所选时间内成交佣金金额'
    },
    8: {
        title: '返佣金额',
        tip: '所选时间内已经返佣给客户的金额'
    },
    9: {
        title: '开票金额',
        tip: '所选时间内已开发票的金额'
    },
    10: {
        title: '到账金额',
        tip: '所选时间内的回佣金额'
    },
    11: {
        title: '到账利润',
        tip: '所选时间内的回佣金额减去返佣金额'
    },
    12: {
        title: '退房金额',
        tip: '所选时间内的退房佣金金额'
    },
    13: {
        title: '单客转化',
        tip: '所选时间内的单客户转化金额=成交金额÷分配客户数'
    }
}
