import { Component, Vue, Watch } from 'vue-property-decorator'
import { __role } from '@/role'
import { __store } from '@/store'
import dateRangeUtil from '@/util/date'
import echarts from 'echarts'
import { customerOrderReportQueryReq, customerOrderReportQueryRes } from '@/api/customer'
import { __utils } from '@/util'
import Format = echarts.EChartOption.Tooltip.Format;

interface Filter {
    week: number
    dateList: {
        value: number
        label: string
    }[]
    date: Date
    date1: Date
    group: string
}

interface Charts {
    title: string
    type: 'bar' | 'line'
    selector: string
    reserve?: number
    send: number
    exit?: number
    send_rate?: string
    select?: boolean
    dep_ids: number[],
    team_ids: number[],
    group_by: customerOrderReportQueryReq['group_by']
    role: number[],
    rows?: customerOrderReportQueryRes[]
}

interface GetBarDataOptions {
    dep_ids: customerOrderReportQueryReq['dep_ids']
    team_ids: customerOrderReportQueryReq['team_ids']
    group_by: customerOrderReportQueryReq['group_by']
}

interface GetLineDataOptions {
    dep_ids: customerOrderReportQueryReq['dep_ids']
    team_ids: customerOrderReportQueryReq['team_ids']
    aggregate?: boolean
}

interface ChartReqData {
    title: string
    dep_ids: number[]
    team_ids: number[]
    group_by: customerOrderReportQueryReq['group_by']
    type: Charts['type']
    role: number[]
    select?: boolean
    selector: string
}

interface LineToolTipsData {
    title: string
    data: {
        label: string
        value: number
        color: string
    }[]
    x: number
    y: number
    show: boolean
}

interface Rank {
    title: string
    dep_ids: number[]
    team_ids: number[]
    reverse: {
        percentage: number
        name: string
        text: string
    }[]
    join: Rank['reverse']
    role: number[]
}

const weekMap: {
    [index: number]: Date
} = {
    0: dateRangeUtil.getPreviousMonth()[0],
    1: dateRangeUtil.getCurrentMonth()[0],
    2: dateRangeUtil.getNextMonth()[0]
}

@Component
export default class RecordStatistics extends Vue {
    @Watch('filter.date')
    watchDate () {
        for (let w in weekMap) {
            if (new Date(weekMap[w]).format('yyyy-MM-dd') === new Date(this.filter.date).format('yyyy-MM-dd')) {
                this.filter.week = +w
                break
            }
        }

        let currentYear = this.filter.date.getFullYear(); let currentMonth = this.filter.date.getMonth()
        this.filter.date1 = new Date(currentYear, currentMonth, dateRangeUtil.getMonthDays(currentYear, currentMonth))

        this.initECharts()
        this.initRanks()
    }

    get getAllGroup () {
        let res = 0
        this.__utils.index.searchGroup('', (r: any) => {
            res = r
        })
        return res
    }

    get getFilterTitle () {
        // xszj
        if (this.__role.has([])) {
            return '预约客户总数据'
        } else if (this.__role.has([this.__role.ROLE.DUIZHANG], false)) {
            // dz
            return this.__utils.index.getGroupInfoById(this.userInfo.group).name
        } else {
            // sz
            return this.__utils.index.getDepInfoById(this.userInfo.dep).v
        }
    }

    get userInfo () {
        return this.__store.state.user.infoPos
    }

    get getBarToolTipStyle () {
        return `top:${this.barToolTipsData.y}px;left:${this.barToolTipsData.x}px;`
    }

    get getLineToolTipStyle () {
        return `top:${this.lineToolTipsData.y}px;left:${this.lineToolTipsData.x}px;`
    }

    filter: Filter = {
        week: 1,
        dateList: [
            {
                value: 0,
                label: '上月'
            },
            {
                value: 1,
                label: '本月'
            },
            {
                value: 2,
                label: '下月'
            }
        ],
        date: new Date(),
        date1: new Date(),
        group: '5'
    }

    charts: Charts[] = []

    barToolTipsData = {
        title: '',
        group_by: '',
        user_name: '',
        roi: '',
        reserve: 0,
        send: 0,
        exit: 0,
        x: 0,
        y: 0,
        send_rate: '',
        exit_rate: '',
        show: false
    }

    lineToolTipsData: LineToolTipsData = {
        data: [],
        title: '',
        x: 0,
        y: 0,
        show: false
    }

    ranks: Rank[] = []
    async getHouseRankData (data: Rank) {
        const res = await this.__apis.customer.customerOrderReportQuery({
            ...this.getStartEndData(),
            group_by: 'area',
            dep_ids: data.dep_ids,
            team_ids: data.team_ids,
            pagesize: 10
        })

        res.rows = res.rows.slice(0, 10)
        res.total = res.rows.length
        return res
    }
    async getJinKeHouseRankData (data: Rank) {
        const date = this.getStartEndData()

        const res = await this.__apis.customer.getOrderRecordHouseHotRank({
            startDate: date.start_date,
            endDate: date.end_date,
            dep_id: data.dep_ids[0],
            type: 'online'
        })

        return {
            rows: res.data.map(a => {
                return {
                    customers: a.size,
                    house_name: a.house_name,
                    percentage: a.percentage
                }
            }),
            total: res.data.length
        }
    }
    async initRanks () {
        const hzd = this.__utils.index.getDepInfoById(2)
        const hzx = this.__utils.index.getDepInfoById(6)
        const gz = this.__utils.index.getDepInfoById(4)

        this.ranks = [
            {
                title: hzd.v,
                reverse: [],
                join: [],
                dep_ids: [hzd.k],
                team_ids: [],
                role: [this.__role.ROLE.XIAOSHOUZONGJIAN]
            },
            // {
            //     title: hzx.v,
            //     reverse: [],
            //     join: [],
            //     dep_ids: [hzx.k],
            //     team_ids: [],
            //     role: [this.__role.ROLE.XIAOSHOUZONGJIAN]
            // },
            {
                title: gz.v,
                reverse: [],
                join: [],
                dep_ids: [gz.k],
                team_ids: [],
                role: [this.__role.ROLE.XIAOSHOUZONGJIAN]
            }, {
                title: this.getFilterTitle,
                reverse: [],
                join: [],
                dep_ids: [this.userInfo.dep],
                team_ids: [],
                role: __role.TYPE.SZQDJL
            }, {
                title: this.getFilterTitle,
                reverse: [],
                join: [],
                dep_ids: [this.userInfo.dep],
                team_ids: [this.userInfo.group],
                role: [this.__role.ROLE.DUIZHANG]
            }
        ]

        for (let r of this.ranks) {
            if (!this.__role.has(r.role, false)) continue

            // 预约热度
            const a = await this.getHouseRankData(r)
            // 进客热度
            const c = await this.getJinKeHouseRankData(r)

            a.rows.forEach((b, i) => {
                const percentage1 = Number(b.percentage * 100).toFixed(2)
                const e = c.rows[i]
                if (e) {
                    const percentage2 = Number(e.percentage * 100).toFixed(2)

                    r.join.push({
                        name: `${i + 1}.${e.house_name}`,
                        text: `${e.customers}个（${percentage2}%）`,
                        percentage: Math.max(25, e.customers / c.rows[0].customers * 100)
                    })
                }

                r.reverse.push({
                    name: `${i + 1}.${b.house_name}`,
                    text: `${b.order_customers}个（${percentage1}%）`,
                    percentage: Math.max(25, b.order_customers / a.rows[0].order_customers * 100)
                })
            })
        }
    }

    async mounted () {
        try {
            const chartReqData: ChartReqData[] = [
                {
                    dep_ids: [],
                    team_ids: [],
                    group_by: 'department',
                    type: 'bar',
                    role: [__role.ROLE.XIAOSHOUZONGJIAN],
                    selector: 'chart0',
                    title: '公司'
                },
                {
                    dep_ids: [2],
                    team_ids: [],
                    group_by: 'team',
                    type: 'bar',
                    role: [__role.ROLE.XIAOSHOUZONGJIAN],
                    selector: 'chart1',
                    title: '杭州销售部'
                },
                {
                    dep_ids: [4],
                    team_ids: [],
                    group_by: 'team',
                    type: 'bar',
                    role: [__role.ROLE.XIAOSHOUZONGJIAN],
                    selector: 'chart2',
                    title: '广州销售部'
                },
                {
                    dep_ids: [__store.state.user.infoPos.dep],
                    team_ids: [],
                    group_by: 'team',
                    type: 'bar',
                    role: __role.TYPE.SZQDJL,
                    selector: 'chart3',
                    title: '战队预约客户'
                },
                {
                    dep_ids: [],
                    team_ids: [__store.state.user.infoPos.group],
                    group_by: 'user',
                    type: 'bar',
                    role: [__role.ROLE.DUIZHANG],
                    selector: 'chart4',
                    title: '预约客户统计'
                },
                {
                    dep_ids: [2, 4, 6],
                    team_ids: [],
                    group_by: 'team',
                    type: 'line',
                    role: [__role.ROLE.XIAOSHOUZONGJIAN],
                    selector: 'chart5',
                    title: '部门发客数趋势'
                },
                {
                    dep_ids: [__store.state.user.infoPos.dep],
                    team_ids: [],
                    group_by: 'team',
                    type: 'line',
                    role: __role.TYPE.SZQDJL,
                    selector: 'chart7',
                    title: '部门发客数趋势'
                },
                {
                    dep_ids: [],
                    team_ids: [__store.state.user.infoPos.group],
                    group_by: 'user',
                    type: 'line',
                    role: [__role.ROLE.DUIZHANG],
                    selector: 'chart8',
                    title: '发客数趋势'
                },
                {
                    dep_ids: [],
                    team_ids: [5],
                    group_by: 'user',
                    type: 'bar',
                    role: [__role.ROLE.XIAOSHOUZONGJIAN],
                    select: true,
                    selector: 'chart9',
                    title: '个人预约客户'
                }
            ]

            // 初始化chart数据
            chartReqData.forEach(a => {
                this.charts.push({
                    ...a,
                    reserve: 0,
                    send: 0,
                    send_rate: '',
                    exit: 0
                })
            })
            // 省长看的每个队的数据
            if (this.__role.has(__role.TYPE.SZQDJL, false)) {
                const dep = this.userInfo.group_arr[this.userInfo.dep]
                dep && dep.groups.filter(a => !a.has_close).forEach(a => {
                    this.charts.push(
                        {
                            reserve: 0,
                            send: 0,
                            send_rate: '',
                            exit: 0,
                            dep_ids: [],
                            team_ids: [a.value],
                            group_by: 'user',
                            type: 'bar',
                            role: __role.TYPE.SZQDJL,
                            selector: `chart${this.charts.length + 1}`,
                            title: a.name
                        }
                    )
                })
            }

            this.selectWeek()
        } catch (e) {
            this.$message.error('获取数据失败')
            throw e
        }
    }

    chartMouseLeave () {
        this.barToolTipsData.show = false
        this.lineToolTipsData.show = false
    }

    async chartMouseMove (e: MouseEvent, type: number) {
        try {
            await this.__utils.index.jieliu(50)

            const tip = type === 0 ? this.barToolTipsData : this.lineToolTipsData
            tip.x = e.clientX
            tip.y = e.clientY
        } catch (e) {}
    }

    async groupChange () {
        const last = this.charts[this.charts.length - 1]
        last.team_ids = [+this.filter.group]

        await this.drawBarCharts(last)
    }

    // 获取数据
    getStartEndData () {
        return {
            start_date: this.filter.date.format('yyyy-MM-dd'),
            end_date: this.filter.date1.format('yyyy-MM-dd')
        }
    }
    getBarData (options: GetBarDataOptions) {
        return this.__apis.customer.customerOrderReportQuery({
            ...this.getStartEndData(),
            group_by: options.group_by,
            dep_ids: options.dep_ids,
            team_ids: options.team_ids
        })
    }
    getLineData (options: GetLineDataOptions) {
        return this.__apis.customer.customerOrderQueryByTimeInterval({
            ...this.getStartEndData(),
            dep_ids: options.dep_ids,
            team_ids: options.team_ids,
            aggregate: options.aggregate
        })
    }

    async initECharts () {
        for (let i = 0; i < this.charts.length; i++) {
            const o = this.charts[i]
            if (!this.__role.has(o.role, false)) continue

            // 获取图表数据和聚合数据
            if (o.type === 'bar') {
                await this.drawBarCharts(o)
            } else {
                this.drawLineCharts(o)
            }
        }
    }

    // 获取chart dom
    getChartInstance (selector: string) {
        return echarts.init((this.$refs[selector] as HTMLDivElement[])[0])
    }

    departmentData: any[] = []
    // 柱状图
    async drawBarCharts (data: Charts) {
        // 单个数据
        const single = await this.getBarData(data)
        data.send = single.rows.map(a => a.complete_customers).reduce((a, b) => {
            return a + b
        }, 0)
        data.exit = single.rows.map(a => a.invalid_count).reduce((a, b) => {
            return a + b
        }, 0)
        data.reserve = single.rows.map(a => a.order_customers).reduce((a, b) => {
            return a + b
        }, 0)
        data.send_rate = `${single.rows.length && (single.rows.map(a => a.house_match_ratio).reduce((a, b) => {
            return a + b
        }, 0) * 100 / single.rows.length).toFixed(2)}%`

        if (data.group_by === 'department') {
            this.departmentData = single.rows
        }
        if (data.group_by === 'team' && this.departmentData.length) {
            this.departmentData.some(a => {
                if (data.dep_ids.includes(a.dep_id)) {
                    data.send_rate = `${(a.house_match_ratio * 100).toFixed(2)}%`
                    return true
                }
            })
        }
        data.rows = this.isUserCustomerOrderRecord(data) && data.group_by === 'user' ? single.rows : []

        // 配置
        let xData: string[] = ['']
        let send: number[] = [0]
        let reserve: number[] = [0]
        let exit: number[] = [0]

        if (single.rows.length) {
            xData = []
            send = []
            reserve = []
            exit = []
        }
        single.rows.forEach(s => {
            xData.push({
                team: s.team_name,
                department: s.department_name,
                user: s.username,
                area: ''
            }[data.group_by])
            send.push(s.complete_customers)
            reserve.push(s.order_customers)
            exit.push(s.invalid_count)
        })
        let options = this.getChartsBaseOptions()
        options.series = [
            {
                name: '发放',
                type: 'bar',
                barGap: '0',
                barMaxWidth: 36,
                data: send,
                itemStyle: {
                    color: '#3F8AF0'
                }
            },
            {
                name: '退客',
                type: 'bar',
                barGap: '0',
                barMaxWidth: 36,
                itemStyle: {
                    color: '#A3B0FF'
                },
                data: exit
            },
            {
                name: '预约',
                label: {
                    show: true,
                    position: 'top',
                    distance: 8,
                    color: '#535355'
                },
                type: 'bar',
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [
                            { offset: 0, color: 'rgba(219,236,248,0.83)' },
                            { offset: 1, color: 'rgba(219,236,248,0)' }
                        ]
                    ) as any
                },
                barMaxWidth: 144,
                xAxisIndex: 1,
                z: 1,
                data: reserve
            }
        ]
        options.tooltip.formatter = (params: Format) => {
            const item = single.rows[params.dataIndex as number]
            if (!item) return
            this.barToolTipsData.title = data.title || ''
            this.barToolTipsData.group_by = data.group_by || ''
            this.barToolTipsData.user_name = item.username || ''
            this.barToolTipsData.roi = item.roi || ''
            this.barToolTipsData.send = item.complete_customers
            this.barToolTipsData.exit = item.invalid_count
            this.barToolTipsData.reserve = item.order_customers
            this.barToolTipsData.send_rate = (item.house_match_ratio * 100).toFixed(2) + '%'
            this.barToolTipsData.exit_rate = (item.invalid_ratio * 100).toFixed(2) + '%'
            this.barToolTipsData.show = true
        }
        options.xAxis[0].data = xData
        options.xAxis[0].axisLabel.interval = 0
        options.xAxis[0].axisLabel.formatter = (value: string) => {
            if (xData.length > 33) {
                return value.split('').join('\n')
            }
            return value
        }
        options.xAxis[0].name = {
            team: '战队',
            department: '部门',
            user: '分析师',
            area: ''
        }[data.group_by]
        options.grid.right = options.xAxis[0].name.length * 14 + 24

        this.getChartInstance(data.selector).setOption(options)
    }

    // 折线图
    async drawLineCharts (data: Charts) {
        let options = this.getChartsBaseOptions()

        // 聚合数据
        // const all = await this.getLineData({ ...data, aggregate: true })
        let complete_customers = 0

        // 单个数据
        const color: {
            [index: number]: string
        } = {
            2: '#B13FF0',
            4: '#3F8AF0',
            6: '#c23531'
        }

        // 多条折线
        const isTeam = data.group_by === 'team'
        const ids = isTeam ? data.dep_ids : data.team_ids
        for (let i = 0; i < ids.length; i++) {
            let id = ids[i]

            let config = data
            if (isTeam) {
                config = {
                    ...config,
                    dep_ids: [id]
                }
            } else {
                config = {
                    ...config,
                    team_ids: [id]
                }
            }
            const single = await this.getLineData(config)

            options.xAxis[0].data = single.rows.map(a => a.date)

            const tn = __utils.index.getDepInfoById(id)
            const un = __utils.index.getGroupInfoById(id)
            options.series.push(
                {
                    name: {
                        team: tn.v,
                        user: un.name,
                        area: '',
                        department: ''
                    }[data.group_by],
                    type: 'line',
                    data: single.rows.map(a => a.complete_customers),
                    itemStyle: {
                        color: color[id]
                    },
                    symbolSize: 10,
                    hoverAnimation: false
                }
            )

            complete_customers += single.rows.map(a => a.complete_customers).reduce((a, b) => a + b, 0)
        }

        // 配置
        options.tooltip = {
            show: true,
            trigger: 'axis',
            axisPointer: {
                z: 1
            },
            formatter: (params: Format[]) => {
                this.lineToolTipsData.title = params[0].name || ''

                this.lineToolTipsData.data = params.map(a => {
                    return {
                        label: String(a.seriesName),
                        value: a.data,
                        color: a.color || ''
                    }
                })

                this.lineToolTipsData.show = true
            }
        }
        // options.xAxis[0].data = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天']
        options.xAxis[0].name = '日期'

        data.send = complete_customers

        this.getChartInstance(data.selector).setOption(options)
    }

    getChartsBaseOptions (): any {
        return {
            tooltip: {
                padding: 0,
                backgroundColor: '#fff',
                confine: true
            },
            grid: {
                left: 0,
                right: 52,
                bottom: 0,
                top: 80,
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    axisTick: {
                        alignWithLabel: true,
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        margin: 16
                    },
                    nameTextStyle: {
                        fontSize: 14,
                        color: '#838385'
                    },
                    nameGap: 24,
                    boundaryGap: true
                },

                {
                    show: false,
                    type: 'category',
                    boundaryGap: true
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    name: '人数',
                    nameTextStyle: {
                        fontSize: 14,
                        color: '#838385'
                    },
                    nameGap: 24,
                    splitLine: {
                        lineStyle: {
                            // 使用深浅的间隔色
                            color: '#E7EBEF'
                        }
                    }
                }
            ],
            series: []
        }
    }

    selectWeek () {
        this.filter.date = weekMap[this.filter.week]
    }

    isUserCustomerOrderRecord (item: {title: string}) {
        return item.title === '个人预约客户'
    }
}
