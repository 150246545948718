import { Vue, Component } from 'vue-property-decorator'

import FilterComponent from '@/components/filters/filter/index.vue'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import inputSelectComponent from '@/components/filters/inputSelect/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'

@Component({
    components: {
        FilterComponent,
        FilterBusInputSelectComponent,
        FilterBusSelectComponent,
        inputSelectComponent
    }
})
export default class IndexPage extends Vue {
    dataObj: any = {
        list: [],
        total: 0
    }
    page = 1
    pageSize = 20
    filter = {}

    get
    infoPos () {
        return this.$store.state.user.infoPos
    }

    mounted () {
        this.getEmployeeRecord()
    }

    async getEmployeeRecord () {
        let { data } = await this.__apis.gzManage.getEmployeeRecord({
            ...this.filter,
            with_user: 1,
            page: this.page,
            pagesize: this.pageSize
        })

        let list: any = []
        data.list.map((v: any) => {
            v.SalaryStatus = String(v.SalaryStatus)
            v.EmployeeId = v.Id
            v.UserList = !v.UserList ? [v] : v.UserList
            v.UserList.map((vv: any) => {
                vv.Username = v.Username
                vv.DepName = v.DepName
                vv.PId = v.Id
                vv.SalaryStatus = v.SalaryStatus
                list.push(vv)
            })
        })
        data.initList = data.list
        data.list = list
        this.dataObj = data
    }

    pageChange (page: number) {
        this.page = page
        this.getEmployeeRecord()
    }

    editStatus (id: number, status: number) {
        this.saveUser({
            id: id, 			// (编辑时必传)
            salary_status: status			// 1正常  2下月不再生成记录
        })
    }

    async saveUser (data: any) {
        await this.__apis.gzManage.saveEmployeeRecord({
            ...data
        })

        this.$notify({
            title: '成功',
            message: data.id ? '保存成功！' : '修改成功！',
            type: 'success'
        })

        this.dataObj.initList.map((v: any) => {
            if (v.EmployeeId === data.id) {
                v.SalaryStatus = String(data.salary_status)
            }
        })
    }

    filterChange (obj: any) {
        this.filter = obj
        this.page = 1
        this.getEmployeeRecord()
    }

    queryGroupSearch (queryString: string, cb: any) {
        this.__utils.index.searchGroup(queryString, cb)
    }

    async switchClick (e: any, obj: any) {
        let st = obj.SalaryStatus * 1 === 1 ? 2 : 1
        await this.openTips(obj.Username, st)

        this.editStatus(obj.EmployeeId, st)
    }

    openTips (name: string, status: number) {
        let title = status === 1 ? `<span class='el-icon-warning warn_color'></span>确定要开启这条工资记录吗？` : `<span class='el-icon-error err_color'></span>确定要关闭这条工资记录吗？`

        let content = status === 1 ? `开启员工“${name}”的工资记录后将在工资记录中生成该员工的工资记录，请确认是否开启！` : `关闭员工“${name}”的工资记录后将不再生成该员工的工资记录，请确认是否关闭！`

        return this.$confirm(`<div class='confirm_tips'>${title}</div>
            <div class='confirm_con'>${content}</div>`, '', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消'
        })
    }

    // 内部表格合并
    spanArray ({ rowIndex, columnIndex }: any) {
        if (columnIndex === 0) {
            return this.returnTableSpan(this.merageArr(this.dataObj.list).arr0, rowIndex)
        } else if (columnIndex === 1) {
            return this.returnTableSpan(this.merageArr(this.dataObj.list).arr1, rowIndex)
        } else if (columnIndex === 2) {
            return this.returnTableSpan(this.merageArr(this.dataObj.list).arr2, rowIndex)
        } else if (columnIndex === 6) {
            return this.returnTableSpan(this.merageArr(this.dataObj.list).arr0, rowIndex)
        }
    }

    // 返回合并行，列
    returnTableSpan (arr: any, rowIndex: number) {
        const _row = arr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
            rowspan: _row,
            colspan: _col
        }
    }

    // 合并单元格函数
    merageArr (arr: any) {
        let arr0: any[] = []
        let arr0Index = 0
        let arr1: any[] = []
        let arr1Index = 0
        let arr2: any[] = []
        let arr2Index = 0

        arr.map((v: any, i: number) => {
            if (i === 0) {
                arr0.push(1)
                arr0Index = 0
                arr1.push(1)
                arr1Index = 0
                arr2.push(1)
                arr2Index = 0
            } else {
                if (arr[i].EmployeeId === arr[i - 1].EmployeeId) {
                    arr0[arr0Index] += 1
                    arr0.push(0)
                } else {
                    arr0.push(1)
                    arr0Index = i
                }
                if (
                    arr[i].EmployeeId === arr[i - 1].EmployeeId &&
                    arr[i].Username === arr[i - 1].Username
                ) {
                    arr1[arr1Index] += 1
                    arr1.push(0)
                } else {
                    arr1.push(1)
                    arr1Index = i
                }
                if (
                    arr[i].EmployeeId === arr[i - 1].EmployeeId &&
                    arr[i].DepName === arr[i - 1].DepName
                ) {
                    arr2[arr2Index] += 1
                    arr2.push(0)
                } else {
                    arr2.push(1)
                    arr2Index = i
                }
            }
        })

        return {
            arr0,
            arr1,
            arr2
        }
    }
}
