import { BaseRes, java, ListReq, ListRes } from '@/api/index'

interface walletListReq extends ListReq{
    employee_id: string;
}
interface walletListRes extends ListRes<{
    ctime:number, employee_common_dep:number, employee_id:number,
    employee_user_name:string, id:number, money:number, mtime:number
}>{
    current_page: number;
    statistic_res: string;
}

interface walletRecordListReq extends ListReq{
    employee_id?: string;
    type?: number;
    time_start?: number;
    time_end?: number;
}
interface walletRecordListRes extends ListRes<{
    ctime:number, employee_id:number, id:number, money:number,
    mtime:number, remark:string, type:number, wallet_id:number,
    wallet_money_after:number, wallet_money_before:number
    employee_user_name: string, employee_common_dep: number
}>{
    statistic_res: {sum_money:number};
}

interface walletTransferReq{
    employee_id: number;
    money: number;
    remark: string;
}

interface cashoutListReq extends ListReq{
    employee_id?: string;
    status?: string;
    time_start?: number;
    time_end?: number;
}
interface cashoutListRes extends ListRes<{
    common_dep_id:number, ctime:number, dep_id:number,
    employee_common_dep:number, employee_id:number,
    employee_user_name:string, group_id:number,
    id:number, money:number, mtime:number,
    reject_reason:string, remark:string, send_time:string, status:number
}>{
    current_page: number;
    statistic_res: string;
}

interface cashOutExamineReq{
    cashout_id: number;
    status: number; // 1 通过 2驳回
    reject_reason?: string; // 驳回时 必填
}

export default class ApiChongZhi {
    // 获取所有人钱包
    walletList (data: walletListReq): BaseRes<walletListRes> {
        return java.post('/admin/financialWallet/walletList', data)
    }

    // 获取钱包明细
    walletRecordList (data: walletRecordListReq): BaseRes<walletRecordListRes> {
        return java.post('/admin/financialWallet/walletRecordList', data)
    }

    // 钱包充值
    walletTransfer (data: walletTransferReq) {
        return java.post('/admin/financialWallet/walletTransfer', data)
    }

    // 获取提现记录
    cashoutList (data: cashoutListReq): BaseRes<cashoutListRes> {
        return java.post('/admin/financialWallet/cashoutList', data)
    }

    // 提现审核
    cashOutExamine (data: cashOutExamineReq) {
        return java.post('/admin/financialWallet/cashOutExamine', data)
    }
}
