import { Component, Vue, Prop } from 'vue-property-decorator'

export interface editReviewData {
    [key: string]: string
    id: string
    name: string
    dep: string
    group: string
    money: string
    tax: string
    real_money: string
    submitMoney: string
    reduceSubmitMoney: string
}

@Component
export default class EditReview extends Vue {
    @Prop({
        default: false,
        type: Boolean
    })
    show !: boolean
    @Prop({
        default () {
            return {
                id: '',
                name: '',
                dep: '',
                group: '',
                money: '',
                tax: '',
                real_money: '',
                submitMoney: '',
                reduceSubmitMoney: ''
            }
        },
        type: Object
    })
    item !: editReviewData

    form: editReviewData = {
        id: '',
        name: '',
        dep: '',
        group: '',
        money: '',
        tax: '',
        real_money: '',
        submitMoney: '',
        reduceSubmitMoney: ''
    }

    get
    cmtGetMoney () {
        return Number(this.form.money) - Number(this.form.tax)
    }

    get
    cmtBtnEnterDisabled () {
        return !this.form.tax.trim()
    }

    created () {
        this.form = this.__utils.index.copyObj(this.item)
    }

    async enter () {
        await this.__apis.wallet.saveCashOut({
            id: +this.form.id,
            tax: +this.form.tax,
            expense_deduct_money: +this.form.reduceSubmitMoney
        })

        this.$message.success('修改成功')
        this.$emit('enter')
        this.close()
    }

    close () {
        this.$emit('update:show', false)
    }
}
