import Vue from 'vue'
import * as Sentry from '@sentry/vue'

import { __localStorage, storageName } from '@/localStorage'

const __env = {
    isDev: process.env.NODE_ENV === 'development'
}

const CONFIG = {
    openSentry: true,
    dsnSecretKey: __env.isDev ? '3b9f718dedbe4f5a9d12f0b96a839f00' : '8c9b03df85eb4cc2925f76afe095ce89',
    dsnProjectIndex: __env.isDev ? 3 : 4,
    dsnSendAddr: __env.isDev ? '192.168.3.103:9000' : 'ssl.malmam.com/xiangju.sentry',
    dsnHttp: __env.isDev ? 'http' : 'https'
}

if (CONFIG.openSentry && !__env.isDev) {
    const dsn = `${CONFIG.dsnHttp}://${CONFIG.dsnSecretKey}@${CONFIG.dsnSendAddr}/${CONFIG.dsnProjectIndex}`
    Sentry.init({
        dsn,
        Vue,
        environment: process.env.NODE_ENV,
        // 报错console
        logErrors: true,
        // 配置集成以跟踪所有子组件
        tracingOptions: {
            trackComponents: true
        },
        beforeSend (event) {
            if (event.exception) {
                event.user = {
                    Token: __localStorage.getLocalStorage(storageName.TOKEN)
                }
            }

            return event
        }
    })
}
