import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class InputHouseSearch extends Vue {
    @Prop({
        type: String,
        default: ''
    })
    value !: string

    @Prop({
        type: String,
        default: ''
    })
    size !: string

    @Prop({
        type: String,
        default: ''
    })
    placeholder !: string

    house_name = ''
    houseObj = {}

    @Watch('value')
    watchValue () {
        this.house_name = this.value
    }

    @Watch('house_name')
    watchHouseName () {
        this.$emit('input', this.house_name)
    }

    mounted () {
        this.house_name = this.value
    }

    // 楼盘匹配
    async querySearchAsync (queryString: string, cb: any) {
        if (!queryString || queryString.length === 0) {
            // eslint-disable-next-line standard/no-callback-literal
            cb([])
            return
        }

        const res = await this.__apis.utils.searchHouse({
            term: queryString
        })

        cb(res.data)
    }

    handleHouseSelect (obj: any) {
        this.houseObj = obj
        this.house_name = obj.value
        this.$emit('input', this.house_name)
        this.$emit('my-select', obj)
    }
}
