import { Component, Prop, Vue } from 'vue-property-decorator'
import { RoleManage, roleType, tree_refresh } from '@/views/system/utils'
import { ElLoadingComponent } from 'element-ui/types/loading'

export interface editItem {
    id?: number
    client: number
    server: number
    module: number
    path: string
    des: string
}

@Component
export default class EditComponent extends Vue {
    get
    getLabel () {
        return this.item.id ? '编辑' : '新增'
    }

    @Prop({
        type: Boolean,
        default: false
    })
    show !: boolean

    @Prop({
        type: Object,
        default: {}
    })
    item !: editItem

    form: editItem = {} as any

    TYPES: {
        service: roleType
        client: roleType
        modules: roleType
    } = {
        service: {},
        client: {},
        modules: {}
    }

    async created () {
        const { CLIENT, SERVICE, MODULES } = await RoleManage()
        this.TYPES = {
            service: SERVICE,
            client: CLIENT,
            modules: MODULES
        }

        this.form = this.__utils.index.copyObj(this.item)
    }

    async enter () {
        let loading !: ElLoadingComponent

        try {
            await this.$confirm(`请确认是否${this.getLabel} <span style="color: #408AF0;">${this.form.des}</span> 权限`, `确认${this.getLabel}权限`, {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })

            loading = this.$loading({
                lock: true,
                text: '发放中...',
                spinner: 'el-icon-loading',
                customClass: 'm_loading'
            })

            await this.__apis.system.savePermissions({
                client_id: this.form.client,
                service: this.form.server,
                pid: this.form.module,
                name: this.form.des,
                path: this.form.path,
                id: this.form.id,
                status: 1
            })

            tree_refresh.value = true
            this.$message.success(`${this.getLabel}权限成功`)
            this.$emit('enter')
            this.close()
        } catch (e) {
            throw e
        } finally {
            loading && loading.close()
        }
    }

    close () {
        this.$emit('update:show', false)
    }
}
