import { Vue, Component } from 'vue-property-decorator'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'
import { OnlineSignRecordResStatus, webSignStatus } from '@/util/const'
import { Table } from '@/types'
import SignOperateComponent from '@/components/signOperate/index.vue'
import { OnlineSignReviewersRes } from '@/api/channelManage'
import { apiConfig } from '@/api/config'

type Filter = Partial<{
    dep: number
    group: number
    fxs: number
    status: OnlineSignRecordResStatus
    signTime: string[]
    houseName: string
    customerName: string
    customerPhone: string
    reviewer: number
    submitTime: string[]
}>

type TableItem = {
    sid: number
    id: number
    customerName: string
    customerPhone: string
    signTime: string
    operator: string
    house: string
    saleMan: string
    realMoney: number
    remark: string
    status: OnlineSignRecordResStatus
    reviewer: string
}

@Component({
    components: {
        InputSelectSearch,
        SignOperateComponent
    }
})
export default class SignReviewPage extends Vue {
    signOperateComponent = {
        show: false,
        id: 0
    }

    showSignOperate (item: TableItem) {
        this.signOperateComponent.show = true
        this.signOperateComponent.id = item.id
    }

    gotoDetail (item: TableItem) {
        const { href } = this.$router.resolve({
            path: `/financial/success_detail/success/${item.sid}`
        })

        window.open(href, '_blank')
    }

    filter: Filter = {
        dep: undefined,
        group: undefined,
        fxs: undefined,
        status: undefined,
        signTime: undefined,
        houseName: undefined,
        customerName: undefined,
        customerPhone: undefined,
        reviewer: undefined,
        submitTime: undefined
    }

    webSignStatus = webSignStatus

    table: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0
    }

    created () {
        this.getData()
        this.getReviewers()
    }

    reviewers: OnlineSignReviewersRes[] = []

    async getReviewers () {
        const { data } = await this.__apis.channelManage.onlineSignReviewers()
        this.reviewers = data
    }

    tableSizeChange (a: number) {
        this.table.pageSize = a
        this.getData()
    }

    tableCurrentChange (a: number) {
        this.table.page = a
        this.getData()
    }

    isReject (item: TableItem) {
        return item.status === OnlineSignRecordResStatus.Reject
    }

    isPass (item: TableItem) {
        return item.status === OnlineSignRecordResStatus.Pass
    }

    // 导出文件
    exportFile () {
        const data = this.getFilterParams()
        const ext = {
            'sign_date.query_start_time': data.sign_date && data.sign_date.query_start_time,
            'sign_date.query_end_time': data.sign_date && data.sign_date.query_end_time,
            'apply_date.query_start_time': data.apply_date && data.apply_date.query_start_time,
            'apply_date.query_end_time': data.apply_date && data.apply_date.query_end_time
        }

        delete data.sign_date
        delete data.apply_date

        this.__utils.index.downloadFile({
            ...data,
            ...ext
        }, apiConfig.jUrl + '/admin/onlineSign/download')
    }

    reset () {
        const filter = this.filter
        const keys = Reflect.ownKeys(filter) as Array<keyof typeof filter>

        keys.forEach(a => {
            this.filter[a] = undefined
        })

        this.search()
    }

    getStatusText (item: TableItem) {
        return this.__utils.index.getLabelByValue(webSignStatus, item.status)
    }

    getFilterParams () {
        const applyDate = this.filter.submitTime && this.__utils.index.formatTimeStartTimeEnd(this.filter.submitTime)
        const signDate = this.filter.signTime && this.__utils.index.formatTimeStartTimeEnd(this.filter.signTime)

        return {
            group_id: this.filter.group,
            dep_id: this.filter.dep,
            user_id: this.filter.fxs,
            review_state: this.filter.status,
            house_name: this.filter.houseName,
            reviewer_id: this.filter.reviewer,
            customer_name: this.filter.customerName,
            customer_tel: this.filter.customerPhone,
            sign_date: signDate && {
                query_start_time: signDate[0],
                query_end_time: signDate[1]
            },
            apply_date: applyDate && {
                query_start_time: applyDate[0],
                query_end_time: applyDate[1]
            }
        }
    }

    search () {
        this.table.page = 1

        this.getData()
    }

    async getData () {
        const { data } = await this.__apis.channelManage.onlineSignList({
            page: this.table.page,
            pagesize: this.table.pageSize,
            ...this.getFilterParams()
        })

        this.table.data = data.list.map(a => {
            return {
                sid: a.sid,
                id: a.id,
                customerName: a.customer_name,
                customerPhone: a.customer_tel,
                signTime: this.__utils.index.formatDate(new Date(a.sign_time), 'YYYY-M-D'),
                operator: a.apply_name,
                house: a.house_name,
                saleMan: a.user_info.map(b => {
                    return `${b.name}（${(b.money / a.sum_money * 100).toFixed(2)}%）`
                }).join(' '),
                realMoney: a.sum_money,
                remark: a.sign_remark,
                status: a.review_state,
                reviewer: a.last_reviewer
            }
        })

        this.table.total = data.total
    }
}
