import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import mixin from '../../mixin'
import dateRangeUtil from '@/util/date'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'
import inputSelect from '@/components/inputSelect/index.vue'
import TabsComponent from '@/components/wallet/tabs/index.vue'
import { getOrderInitInfoRes } from '@/api/customer'

@Component({
    components: {
        inputSelect,
        inputSelectSearch,
        TabsComponent
    },
    mixins: [mixin]
})
export default class OrderCustomerDialog extends Vue {
    @Prop({ default: '客户预约' })
    title!:string

    @Prop({ default: false })
    visible!:boolean

    @Prop({ default () { return {} } })
    data!:any

    @Watch('visible')
    visibleChange () {
        if (this.visible) {
            this.getMyWallet()
            this.getFxsOrderPerMoney()
            this.getOrderInitInfo()
        }
        this.$emit('update:visible', this.visible)
    }

    @Watch('tabsComponent.index')
    watchTabsComponentIndex () {
        this.order.list = [{
            house_name: '',
            house_id: 0,
            customerNumber: ''
        }]
    }

    tabsComponent: any = {
        items: [],
        index: 0
    }

    order = {
        visible: this.visible,
        area: '',
        areaRes: {},
        house_name: '',
        house_id: 0,
        houseObj: {},
        customerNumber: 0,
        list: [
            {
                house_name: '',
                house_id: 0,
                customerNumber: ''
            }
        ]
    }
    myWallet:any = {}
    fxsAreaByFxs: getOrderInitInfoRes = {
        area: [],
        next_week_remain_count: 0,
        remain_count: 0,
        max_overdraft: 0,
        max_order_count: 0,
        has_order_count: 0,
        next_month_has_order_count: 0,
        show_accept_re_push_button: false,
        price: []
    }

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    get
    getUserInfo () {
        let info = {
            dep_name: '',
            group_name: ''
        }
        if (this.userInfo.dep > 0) {
            info.dep_name = this.__utils.index.getDepInfoById(this.userInfo.dep).v
        }
        if (this.userInfo.dep > 0 && this.userInfo.group > 0) {
            info.group_name = this.__utils.index.getGroupInfoById(this.userInfo.group).name
        }
        return info
    }

    totalPriceByCount (count: number) {
        const priceData = this.fxsAreaByFxs.price
        let price = 0
        priceData
            .filter(a => count >= a.start_count)
            .forEach((a, i) => {
                const start = a.start_count - 1
                const val = (count <= a.end_count ? count - start : a.end_count - start)
                price += a.price * val
            })
        return price.toFixed(2)
    }

    get
    totalMoney (): string {
        let total = this.order.list.reduce((total, currentValue:any) => {
            return total + Number(currentValue.customerNumber)
        }, 0)
        if (!total) return '0'

        if (this.tabsComponent.index === 0) {
            // 下月预约
            return this.totalPriceByCount(total + Number(this.fxsAreaByFxs.next_month_has_order_count))
        }

        const oldOrderCount = Number(this.fxsAreaByFxs.has_order_count)
        let count = oldOrderCount + total
        return (Number(this.totalPriceByCount(count)) - Number(this.totalPriceByCount(oldOrderCount))).toFixed(2)
    }

    get
    isMoneyTips () {
        // 不检查钱包余额
        return false
        // return +this.myWallet.money < (+this.totalMoney / 2)
    }

    get
    isSaveDisabled () {
        let res = !this.isMoneyTips && this.order.list.length > 0 && this.order.list.every((v) => {
            return (
                v.house_name &&
                v.house_name !== '' &&
                v.house_id &&
                v.house_id !== 0 &&
                v.customerNumber !== '' &&
                Number(v.customerNumber) > 0
            )
        })

        const count = this.order.list.map(a => +a.customerNumber).concat(0).reduce((a, b) => a + b)
        if (this.tabsComponent.index === 1) {
            return !(count > this.fxsAreaByFxs.remain_count) && res
        }
        return res && count <= this.fxsAreaByFxs.next_week_remain_count
    }

    get
    applyDate () {
        let res = []
        const date = this.tabsComponent.index === 0 ? dateRangeUtil.getCurrenAftertWeek() : [new Date(), dateRangeUtil.getCurrentWeek()[1]]
        res[0] = dateRangeUtil.format(date[0], 'yyyy-MM-dd') || ''
        res[1] = dateRangeUtil.format(date[1], 'yyyy-MM-dd') || ''
        return res.join(' ~ ')
    }

    created () {
        this.tabsComponent.items = [
            {
                text: '下月预约',
                url: []
            }, {
                text: '本月补预约',
                url: []
            }
        ]
    }

    async getLastWeekData () {
        const { data } = await this.__apis.customer.getCustomerOrderRecord({
            page: 1,
            pagesize: 20,
            user_id: this.infoPos.fxs,
            order_time_start: this.__utils.dayjs().day(1).format('YYYY-MM-DD')
        })

        this.order.list = data.list.map(a => {
            return {
                house_name: a.PlaceInfo.house_name,
                house_id: a.PlaceInfo.house_id,
                customerNumber: ''
            }
        })
    }

    limitInput (val:string, key:string, item:any) {
        let max = 999
        // val = isNaN(+val) ? '' : val
        val = val.replace(/\D/g, '')
        if (+val <= 0) {
            item[key] = ''
        } else if (+val >= max) {
            item[key] = val.substr(0, max.toString().length)
        } else {
            item[key] = val
        }
    }

    async getFxsOrderPerMoney () {
        let { data } = await this.__apis.customer.getFxsOrderPerMoney()
        // this.CustomerPrice = data.fxs_per_money / 100
    }

    async getOrderInitInfo () {
        const { data } = await this.__apis.customer.getOrderInitInfo()
        this.fxsAreaByFxs = data
    }

    async getMyWallet () {
        const { data } = await this.__apis.user.getMyWallet()
        data.money = +(data.money / 100).toFixed(2)
        this.myWallet = data
    }

    cancelOrder () {
        this.order.list = [
            {
                house_name: '',
                house_id: 0,
                customerNumber: ''
            }
        ]
        this.order.visible = false
        this.$emit('update:visible', false)
    }

    async saveList () {
        if (this.__utils.index.limitRepeatClick()) return

        try {
            const ps = this.order.list.map(a => {
                return this.__apis.customer.saveOrderCustomer({
                    type1_count: +a.customerNumber,
                    place_info: JSON.stringify({
                        house_id: a.house_id,
                        house_name: a.house_name
                    }),
                    is_additional: this.tabsComponent.index === 1 ? '1' : ''
                })
            })

            await Promise.all(ps)

            this.$emit('save', this.order)
            this.$message({
                message: '提交成功！',
                type: 'success'
            })
            this.cancelOrder()
        } catch (e) {
            this.$message({
                message: '提交失败！',
                type: 'error'
            })
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    async searchHouse (text: string, cb: any) {
        let areaId = this.fxsAreaByFxs.area.map((v:any) => {
            return v.id
        })

        const { data } = await this.__apis.utils.searchHouse({
            term: text,
            area_collection_id_list: areaId
        })

        cb(data)
    }

    selectHouse (res:any, item:any) {
        item.house_name = res.label || ''
        item.house_id = res.id || 0
        this.__apis.customer.orderCustomerHouseCheck(res.id).catch((res) => {
            this.$message.error(res.data.errMsg)
            item.house_name = ''
            item.house_id = 0
        })
    }

    addOrderList () {
        this.order.list.push({
            house_name: '',
            house_id: 0,
            customerNumber: ''
        })
    }

    delOrderList (item:any, i:number) {
        item.splice(i, 1)
    }
}
