import { Component, Vue } from 'vue-property-decorator'
import tcQkComponent from '@/views/financial/gongziManage/layout/components/tcqk/index.vue'
import tcRecordComponent from '@/views/financial/gongziManage/layout/components/tcRecord/index.vue'
import dateUtil from '@/util/date.ts'
import { storageName } from '@/localStorage'

@Component({
    components: {
        tcQkComponent,
        tcRecordComponent
    }
})
export default class IndexPage extends Vue {
    tabIndex = 0
    tabs = [
        { label: '提成情况', value: 0 }
        // { label: '提成发放记录', value: 1 }
    ]
    datePicker = new Date(dateUtil.getPreviousMonth()[0]).format('yyyy-MM') || ''
    filter = {}

    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }

    tabClick (i: number) {
        this.tabIndex = i
    }

    getFilter (data: any) {
        this.filter = data
    }

    // 导出
    exportData () {
        let data: any = {
            action_name: 'getBonusOverViewForExport',
            ...this.filter,
            time_start: this.datePicker,
            time_end: this.datePicker
        }

        this.__utils.index.downloadFile(data)
    }
}
