import { Component, Vue } from 'vue-property-decorator'
import tabComponent from '@/components/success-detail/tabs/index.vue'

interface tabsOptions {
    data: {
        label: string
        route: string
        title: string
    }[]
}

@Component({
    components: {
        tabComponent
    }
})
export default class ChannelManageFriendDetailIndexPage extends Vue {
    tabsOptions: tabsOptions = {
        data: []
    }

    created () {
        const names = ['基本信息', '业务数据', '预付款记录', '预付款变更记录', '预付款使用记录']
        const routes = ['', '/business', '/promise', '/change', '/use']
        const titles = ['friend-detail', 'friend-detail-business', 'friend-detail-promise', 'friend-detail-change', 'friend-detail-use']

        names.forEach((a, i) => {
            this.tabsOptions.data.push({
                label: a,
                route: `/channelManage/hezuoshang${routes[i]}/${this.$route.params.id}`,
                title: titles[i]
            })
        })
    }
}
