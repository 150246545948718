import { BaseRes, ListReq, ListRes, php, PubbyListRes, java, pp } from '@/api/index'
import { userInfo6 } from '@/api/customer'
import { userInfo7 } from './system'
import { AxiosResponse } from 'axios'
import { OnlineSignRecordResStatus } from '@/util/const'
import { OnlineSignAudit, OnlineSignImages, OnlineSignResetApplyReq } from '@/api/financial'

export type duijieUser = {
    id: number
    username: string
    tel: string
    status: number
}[]

interface getSalePartnerListReq extends ListReq {
    name?: string
    full_name?: string
    status?: string
    time_start?: string
    time_end?: string
    dep?: number
    cj_money_min?: string
    cj_money_max?: string
}

export interface getSalePartnerListRes extends ListRes<getPartnerDetailRes & getPartnerBizDataRes>{}

interface getSalePartnerBizRecordListReq extends ListReq {
    partner_id: string
}

export interface getSalePartnerBizRecordListRes extends ListRes <{
    Id: number
    PartnerId: number
    UserId: number
    Content: string
    Ctime: string
    UserInfo: userInfo6
}> {}

interface saveSalePartnerBizRecordReq {
    content: string
    partner_id: string
}

export interface getPartnerDetailRes {
    Id: number
    FullName: string
    Name: string
    Dep: number
    Introduction: string
    Status: number
    DuijieUser: Array<duijieUser[0] & {
        note: string
        job: string
    }>
    StartTime: null | string
    Ctime: string
    Mtime: null | string
}

export interface saveSalePartnerReq {
    id?: string
    name?: string
    full_name?: string
    dep?: number
    introduction?: string
    status?: number | undefined
    start_time?: string
    duijie_user: string
}

interface getPartnerOrderListReq extends ListReq {
    sale_partner_id: string
    cj_time_start: string
    cj_time_end: string
    cj_money_min: string
    cj_money_max: string
    fxs_user_id: string
    customer_name: string
    customer_phone: string
    house_name: string
    jy_money_min: string
    jy_money_max: string
    kp_money_min: string
    kp_money_max: string
    has_tuifan: string
    jy_status: string
}

interface getPartnerOrderListRes extends ListRes <{
    sale_time: number
    fxs_user_info: string
    house_name: string
    customer_name: string
    customer_phone: string
    sale_sum_money: number
    jieyong_money: number
    kaipiao_money: number
    has_tui_fang: number
    jieyong_status: number
    sale_id: number
}> {
    statistic_res: {
        kaipiao_money: number
        sale_sum_money: number
        jieyong_money: number
        cj_days: number
        sum_must_dz_money: number
        fanyong_money_1: number
        fanyong_money_2: number
    }
}

interface cuiYongChartReq {
    time_start: string
    time_end: string
    time_range: number
}

interface cuiYongChartRes {
    [index: string]: {
        [index: string]: any
        dep_name: string
        data: {
            time_start: string
            time_end: string
            date: string
            value: number
        }[]
    }
    jy_money: cuiYongChartRes[0]
    jy_count: cuiYongChartRes[0]
    kp_money: cuiYongChartRes[0]
    kp_count: cuiYongChartRes[0]
    wq_count: cuiYongChartRes[0]
    tf_count: cuiYongChartRes[0]
}

export interface getOverviewDataRes {
    dep_name: string
    cj_money: number
    must_dz_money: number
    cj_count: number
    jy_money: number
    jy_count_2: number
    jy_count_1: number
    jy_count_0: number
    kp_money: number
    kp_money_3: number
    kp_count_2: number
    kp_count_1: number
    kp_count_0: number
    kp_count_3: number
    fy_money: number
    not_fy_money: number
    not_jy_money: number
    not_kp_money: number
}

interface getCjListDataReq extends ListReq {
    sort_field: string
    sort_mode: string
    cj_time_start: string
    cj_time_end: string
    cj_money_min: number
    cj_money_max: number
    must_dz_money_min: number
    must_dz_money_max: number
    fxs_user_id: number
    group_id: number
    dep_id: number
    house_name: string
    house_region: string
    cj_day_min: number
    cj_day_max: number
    customer_name: string
    customer_phone: string
    customer_channel: string
    sale_channel_name: string
    has_wangqian: number
    expect_wq_time_start: string
    expect_wq_time_end: string
    wq_time_start: string
    wq_time_end: string
    kp_time_start: string
    kp_time_end: string
    kp_money_min: number
    kp_money_max: number
    kp_status: number
    jy_time_start: string
    jy_time_end: string
    jy_money_min: number
    jy_money_max: number
    jy_status: number
    fy_time_start: string
    fy_time_end: string
    fy_money_min: number
    fy_money_max: number
    fy_status: number
    has_tuifan: symbol
    tf_time_start: string
    tf_time_end: string
}

interface getCjListDataRes extends ListRes <{
    sale_id: number
    sale_time: number
    sale_sum_money: number
    sum_must_dz_money: number
    group_ids: number[]
    fxs_user_ids: number[]
    dep_ids: number[]
    fxs_user_info: string
    house_id: number
    house_name: string
    house_region: string
    customer_channel: string
    customer_type_arr: string[]
    cj_days: number
    customer_name: string
    customer_id: number
    customer_phone: string
    sale_channel_id: number
    sale_channel_name: string
    sale_channel_username: string
    sale_channel_phone: string
    zygw_username: string
    zygw_phone: string
    has_wangqian: number
    wangqian_time: null
    house_no: string
    wangqian_no: string
    wangqian_note: string
    expect_wangqian_time: null
    kaipiao_status: number
    kaipiao_time_arr: number[]
    kaipiao_money_arr: any[]
    kaipiao_note_arr: any[]
    kaipiao_money: number
    jieyong_status: number
    jieyong_time_arr: number[]
    jieyong_money_arr: any[]
    jieyong_note_arr: any[]
    jieyong_days: number
    jieyong_money: number
    fanyong_status: number
    fanyong_time_arr: number[]
    fanyong_money_arr: any[]
    fanyong_note_arr: any[]
    fanyong_money: number
    expect_next_jy_time: number
    has_tui_fang: number
    tui_fang_time: null | string
    tui_fang_note: string
    cy_note: string
}> {
    statistic_res: {
        [index: string]: number
        kaipiao_money: number
        sale_sum_money: number
        jieyong_money: number
        cj_days: number
        sum_must_dz_money: number
        fanyong_money_1: number
        fanyong_money_2: number
    }
}

interface getPartnerBizDataRes {
    SumCjMoney: number
    JieyongMoney: number
    SumMustDzMoney: number
    RemainMoney: number
    DepositMoney: number
    UnJieyongMoney: number
    SumCjCount: number
    Jy2Count: number
    Jy1Count: number
    Jy0Count: number
    JyAvgDays: number
    HouseCount: number
    TfCount: number
    Warning: boolean
}

interface depositListReq {
    partner_id: number
}

interface depositListRes {
    begin_time: number
    deposit_money: number
    end_time: number
    house_list: string
    id: number
    partner_id: number
    remain_money: number
    remark: string
    warning: boolean
}

interface saveDepositReq {
    begin_time: string
    end_time: string
    deposit_money: number
    remain_money: number
    remark: string
    house_list: string
    partner_id: number
    id?: number
}

export type baobeiListReq = ListReq & Partial<{
    dep_id: number
    user_id: number
    group_id: number
    customer_name: string
    customer_tel: string
    house_id: number
    time_start: string
    time_end: string
    channel_id: number
    has_dk: number
    has_cj: number
    has_tf: number
    has_wq: number
    jy_status: number
    status: number
}>

export interface baobeiListRes extends ListRes<{
    operator_id: number
    customer_name: string
    customer_tel: string
    house_name: string
    ctime: string
    channel_name: string
    duijie_user: duijieUser
    has_dk: number
    has_cj: number
    has_tf: number
    has_wq: number
    jy_status: number
    status: number
    user_info: userInfo7
}>{}

type houseBindListReq = ListReq & Partial<{
    house_id: number
    channel_id: number
    time_start: string
    time_end: string
}>

export type houseBindInfo = {
    channel_id: number
    channel_name: string
    duijie_user: duijieUser
    house_id: number
    house_name: string
    id: number
    ctime: number
    operator: string
    return_time: string
    status: number
}

export interface houseBindListRes extends ListRes<houseBindInfo>{}

export type houseBindSaveReq = {
    house_id: number
    house_name: string
    return_time: string
    channel_id: number
    channel_name: string
    duijie_user: number[]
    id: number
}

interface channelChangeRecordList {
    mtime: number
    note: string
}

interface houseBindStatusSaveReq {
    id: number
    status: number
}

type specialBaobeiListRes = ListRes<{
    id: number
    dep_id: number
    group_id: number
    user_id: number
    ctime: number
    customer_id: number
    customer_name: string
    customer_tel: string
    house_name: string
    house_id: number
}>

interface baobeiSaveReq {
    operator_id?: number
    is_special?: 1
    user_id?: number
    dk_time?: string
    baobei_data: {
        tel_type: number // tel_type 1 ，2，3，4 表示四种电话显示类型
        diy_list: {
            label: string
            value: any
        }[]
    }
    channel_id: number
    channel_name: string
    customer_id: number
    customer_name: string
    duijie_user: duijieUser
    house_id: number
    house_name: string
    phone_index?: number
    special_id?: number
}

interface baobeiSpecialSaveReq {
    phone_index: number
    customer_id: number
    customer_name: string
    house_id: number
    house_name: string
}

type baobeiAnalysisReq = Partial<{
    dep_id: number
    group_id: number
    user_id: number
    time_start: string
    time_end: string
}>

interface baobeiAnalysisRes {
    bb_count: number
    cj_count: number
    dk_count: number
    jy_count: number
    tf_count: number
    wq_count: number
}

export type searchDuiJieUserByNameRes = Array<{
    dep: number
    ctime: number
    mtime: number
} & duijieUser[0]>

interface duiJieUserListReq extends ListReq {
    username: string
    tel: string
}

interface duiJieUserListRes extends ListRes<searchDuiJieUserByNameRes[0]>{}

type duiJieUserActionReq = Omit<duijieUser[0], 'id' | 'status'> & {
    status?: number
    id?: number
    dep?: number
    action_name: 'edit' | 'add' | 'disable'
}

interface invalidCustomerStatisticsReq {
    startDate: string
    endDate: string
    house_province_name: string
}

interface invalidCustomerStatisticsRes {
    customers: number
    es_term_key: '1' | '2' | '3'
}

type OnlineSignListReq = ListReq & Partial<{
    group_id: number
    dep_id: number
    user_id: number
    review_state: OnlineSignRecordResStatus
    house_name: string
    customer_name: string
    reviewer_id: number
    customer_tel: string
    sign_date: {
        query_start_time: string
        query_end_time: string
    }
    apply_date: {
        query_start_time: string
        query_end_time: string
    }
}>

interface saveProblemDealSlipRecordReq {
    'sale_id': number,
    'apply_user_id': number,
    'remarks': string,
    'house_name': string,
    'channel_name': string,
    'duijie_user_info': string
}

interface getChannelProblemDealSlipRecordReq extends ListReq{

}

interface getChannelProblemDealSlipRecordRes {
    apply_user_id: number
    channel_name: string
    ctime: number
    duijie_user_info: {
        'username':string,
        'phone':string
    }[]
    house_name: string
    id: number
    mtime: number
    remarks: string
    sale_id: number
    status: number
    user_info: {
        user_name: string,
        department_name: string
    }
}

interface updateProblemDealSlipStatusReq {
    'id': number,
    'status': number
}

interface getGroupByUserIdScoreListReq {

}

interface getGroupByUserIdScoreListRes {
    total_score: number
    user_id: number
    user_info: {
        department: number
        department_name: string
        user_name: string
        role_name: string
    }
}

interface getScoreRecordDetailReq extends ListReq{
    user_id: number
    house_id?: number
    start_date?: string
    end_date?: string
}

interface getScoreRecordDetailRes {
    ctime: number
    remarks: string
    sale_id: number
    house_name: string
    hui_yong_day: number
    score: number
    user_id: number
    user_info: {
        deactive: number
        department: number
        department_name: string
        group: number
        group_name: string
        last_login_time: number
        mobile: string
        role: number
        role_name: string
        user_name: string
    }
}

interface saveScoreRecordReq {
    'user_id': number,
    'score': number,
    'remarks': string
}

interface saveHouseBindUserReq {
    'id'?: number,
    'house_id': number,
    'channel_id': number,
    'channel_name': string,
    'duijie_user_info': string,
    'user_id': number,
    'house_name': string
}

interface updateHouseBindUserReq{
    id: number
    user_id: number
}

interface getHouseChannelBindDetailRes{
    channel_id: number
    channel_name: string
    dep_id: number
    duijie_user: {
        id: number
        status: number
        username:string
        tel: string
    }[]
    house_id: number
    house_name: string
    id: number
    operator: string
    return_time: string
    rule: string
    status: number
}

interface getHouseUserBindRecordReq extends ListReq{

}

interface getHouseUserBindRecordRes{
    bind_time: number
    channel_id: number
    channel_name: string
    ctime: number
    duijie_user_info: {
        userName:string
        tel: string
    }[]
    house_id: number
    house_name: string
    id: number
    mtime: number
    user_id: number
    user_info: {
        department_name: string
        user_name: string
    }
}

interface getChannelMemberListReq extends ListReq{
}

interface getChannelMemberListRes {
    'common_dep_id': number,
    'leave_time': string,
    'role': number,
    'last_login_time': string,
    'employee_user_name': string,
    'user_id': number,
    'employee_id': number,
    'name': string,
    'ctime': string,
    'company': string,
    'department': number,
    'deactive': number,
    'group': number
}

interface getSalePartnerDepositUseRecordPageListReq extends ListReq{
    partner_id: number
    id?: number
}

interface getSalePartnerDepositUseRecordPageListRes {
    'money': string,
    'house_name': string,
    'user_id': number,
    'user_info': {
        'role_name': string,
        'role': number,
        'last_login_time': number,
        'group_name': string,
        'user_name': string,
        'department_name': string,
        'mobile': string,
        'department': number,
        'deactive': number,
        'group': number
    },
    'ctime': number,
    'id': number
}

interface getSalePartnerDepositChangeRecordPageListReq extends ListReq{
    partner_id: number
    deposit_id?: number
}

interface getSalePartnerDepositChangeRecordPageListRes {
    'begin_time_from': number,
    'end_time_to': number,
    'mtime': number,
    'action_user_id': number,
    'house_list_from': {
        'id':number,
        'label':string,
        'value':string
    }[],
    'house_list_to': {
        'id':number,
        'label':string,
        'value':string
    }[],
    'deposit_money_from': number,
    'deposit_money_to': number,
    'user_info': {
        'role_name': string,
        'role': number,
        'last_login_time': number,
        'group_name': string,
        'user_name': string,
        'department_name': string,
        'mobile': string,
        'department': number,
        'deactive': number,
        'group': number
    },
    'ctime': number,
    'remain_money_from': number,
     remain_money_to: number
    'id': number,
    'deposit_id': number,
    'end_time_from': number,
    'begin_time_to': number
}

interface ProblemDealSlipIsAppealReq {
    sale_id: number
}

interface ProblemDealSlipIsAppealRes {
    is_appeal: boolean
}

interface getOrderCustomerInvalidApplyStatisticsReq {
    dep_id?: number
    start_date?: string
    end_date?: string
}

interface getOrderCustomerInvalidApplyStatisticsRes {
    rows: getOrderCustomerInvalidApplyStatisticsItem[]
    total: number
}

interface getOrderCustomerInvalidApplyStatisticsItem {
    count: number // 数量
    type: number // 客户类型 1搜索客户 2信息流 3电话客户 0未知 4公池筛选客户(机器人外呼)
}

type OnlineSignListResItem = {
    sid: number
    id: number
    apply_name: string
    customer_name: string
    customer_tel: string
    ext_data: string
    house_name: string
    last_reviewer: string
    sign_time: number
    sign_remark: string
    sum_money: number
    review_state: OnlineSignRecordResStatus
    user_info: {
        name: string
        money: number
    }[]
}

interface OnlineSignListRes extends ListRes<OnlineSignListResItem>{}

export interface OnlineSignReviewersRes {
    reviewer_id: number
    reviewer: string
}

interface OnlineSignDetailReq {
    value: number
}

type OnlineSignDetailRes = OnlineSignAudit & OnlineSignImages

interface OnlineSignAuditRejectReq {
    online_sign_id: number
    audit_opinion: string
}

export default class ApiChannelManage {
    // 网签审核驳回
    async onlineSignAuditReject (data: OnlineSignAuditRejectReq) {
        return java.post('/admin/onlineSign/auditReject', data)
    }

    // 网签审核编辑
    async onlineSignEdit (data: OnlineSignResetApplyReq) {
        return java.post('/admin/onlineSign/edit', data)
    }

    // 网签审核通过
    async onlineSignAuditPass (data: OnlineSignDetailReq) {
        return java.post('/admin/onlineSign/auditPass', data)
    }

    // 网签审核详情
    async onlineSignDetail (data: OnlineSignDetailReq): BaseRes<OnlineSignDetailRes> {
        const res = await java.post('/admin/onlineSign/detail', data) as AxiosResponse<OnlineSignAudit>
        const images = JSON.parse(res.data.sign_image) as OnlineSignImages
        const resData = {
            contract: images.contract,
            receipt: images.receipt,
            relationship: images.relationship,
            book: images.book,
            other: images.other
        }

        return {
            ...res,
            data: {
                ...res.data,
                ...resData
            }
        } as never
    }

    // 网签审核审核人
    onlineSignReviewers (): BaseRes<OnlineSignReviewersRes[]> {
        return java.post('/admin/onlineSign/reviewers', {})
    }

    // 获取网签审核列表
    async onlineSignList (data: OnlineSignListReq): BaseRes<OnlineSignListRes> {
        const res = await java.post('/admin/onlineSign/page', data) as AxiosResponse<OnlineSignListRes>

        res.data.list.forEach(a => {
            const users = JSON.parse(a.ext_data).user_info as OnlineSignListResItem['user_info']

            a.user_info = users ? users.map((a) => {
                return {
                    name: a.name,
                    money: a.money
                }
            }) : []
        })

        return res as never
    }

    // 判断申诉按钮是否显示
    problemDealSlipIsAppeal (data: ProblemDealSlipIsAppealReq): BaseRes<ProblemDealSlipIsAppealRes> {
        return java.post('/admin/channel/achievements/manage/problem/dealslip/record/getIsAppeal', data)
    }

    // 退客审核 退客统计
    getOrderCustomerInvalidApplyStatistics (data: getOrderCustomerInvalidApplyStatisticsReq): BaseRes<getOrderCustomerInvalidApplyStatisticsRes> {
        return java.post('/admin/customer/invalid/apply/groupby/query/getOrderCustomerInvalidApplyStatistics', data)
    }

    // 退客审核 退客统计
    invalidCustomerStatistics (data: invalidCustomerStatisticsReq): PubbyListRes<invalidCustomerStatisticsRes> {
        const params: any = {
            start_date: data.startDate,
            end_date: data.endDate,
            group_by: 'source_group',
            filters: [
                {
                    column: 'customer_tags',
                    type: 21,
                    value: ['审核无效']
                }
            ]
        }

        if (data.house_province_name) {
            params.filters.push({
                column: 'house_province_name',
                type: 20,
                value: [data.house_province_name]
            })
        }

        return pp.post('/customer/report/query?out=groupBy', params)
    }

    // 修改对接人信息
    duiJieUserAction (data: duiJieUserActionReq) {
        return java.post('/admin/channel/duijie/duiJieUserAction', data)
    }

    // 获取对接人列表
    salePartnerDuiJieUserList (data: duiJieUserListReq): BaseRes<duiJieUserListRes> {
        return java.post('/admin/channel/duijie/salePartnerDuiJieUserList', data)
    }

    // 搜索对接人
    searchDuiJieUserByName (name: string): BaseRes<searchDuiJieUserByNameRes> {
        return java.post('/admin/channel/duijie/searchDuiJieUserByName', {
            username: name
        })
    }

    // 报备分析
    baobeiAnalysis (data: baobeiAnalysisReq): BaseRes<baobeiAnalysisRes> {
        return java.post('/admin/channel/baobei/baobeiAnalysis', data)
    }

    // 特殊报备记录保存
    baobeiSpecialSave (data: baobeiSpecialSaveReq) {
        return java.post('/admin/channel/baobei/specialSave', data)
    }

    // 添加报备
    baobeiSave (data: baobeiSaveReq) {
        return java.post('/admin/channel/baobei/save', data)
    }

    // 删除特殊报备
    deleteSpecialBaobei (id: number) {
        return java.post('/admin/channel/baobei/deleteSpecialBaobei', {
            id
        })
    }

    // 特殊报备
    specialBaobeiList (data: ListReq): BaseRes<specialBaobeiListRes> {
        return java.post('/admin/channel/baobei/specialBaobeiList', data)
    }

    // 楼盘渠道绑定状态修改
    houseBindStatusSave (data: houseBindStatusSaveReq) {
        return java.post('/admin/channel/houseBind/houseBindStatusSave', data)
    }

    // 楼盘渠道变更记录
    channelChangeRecordList (id: number): BaseRes<channelChangeRecordList[]> {
        return java.post('/admin/channel/houseBind/ChannelChangeRecordList', {
            hcb_id: id
        })
    }

    // 楼盘渠道绑定
    async houseBindSave (data: houseBindSaveReq) {
        return java.post('/admin/channel/houseBind/save', data)
    }

    // 楼盘渠道绑定详情
    async houseBindDetail (id: number): BaseRes<houseBindInfo> {
        const res = await java.post('/admin/channel/houseBind/detail', {
            id
        }) as AxiosResponse<houseBindInfo>

        return res as any
    }

    // 楼盘渠道绑定列表
    async houseBindList (data: houseBindListReq): BaseRes<houseBindListRes> {
        return java.post('/admin/channel/houseBind/list', data)
    }

    // 添加编辑保证金
    saveDeposit (data: saveDepositReq) {
        return java.post('/admin/sale_partner/deposit/save', data)
    }

    // 获取保证金记录
    depositList (data: depositListReq): BaseRes<depositListRes[]> {
        return java.post('/admin/sale_partner/deposit/list', data)
    }

    // 新增编辑合作商
    saveSalePartner (data: saveSalePartnerReq) {
        return php.post('', {
            action_name: 'saveSalePartner',
            ...data
        })
    }

    // 获取合作商列表
    getSalePartnerList (data: getSalePartnerListReq): BaseRes<getSalePartnerListRes> {
        return php.post('', {
            action_name: 'getSalePartnerList',
            ...data
        })
    }

    // 获取合作详情
    getPartnerDetail (id: string | number): BaseRes<getPartnerDetailRes> {
        return php.post('', {
            action_name: 'getPartnerDetail',
            id
        })
    }

    // 获取业务往来记录
    getSalePartnerBizRecordList (data: getSalePartnerBizRecordListReq): BaseRes<getSalePartnerBizRecordListRes> {
        return php.post('', {
            action_name: 'getSalePartnerBizRecordList',
            ...data
        })
    }

    // 新增业务往来记录
    saveSalePartnerBizRecord (data: saveSalePartnerBizRecordReq) {
        return php.post('', {
            action_name: 'saveSalePartnerBizRecord',
            ...data
        })
    }

    // 获取合作商 业务数据
    getPartnerBizData (partner_id: number): BaseRes<getPartnerBizDataRes> {
        return php.post('', {
            action_name: 'getPartnerBizData',
            partner_id
        })
    }

    // 获取合作商的成交订单
    getPartnerOrderList (data: getPartnerOrderListReq): BaseRes<getPartnerOrderListRes> {
        return php.post('', {
            action_name: 'getPartnerOrderList',
            ...data
        })
    }

    // 获取图表数据
    cuiYongChart (data: cuiYongChartReq): BaseRes<cuiYongChartRes> {
        return php.post('', {
            action_name: 'cuiYongChart',
            ...data
        })
    }

    // 概览
    getOverviewData (): BaseRes<getOverviewDataRes[]> {
        return php.post('', {
            action_name: 'getOverviewData'
        })
    }

    getCjListData (data: getCjListDataReq): BaseRes<getCjListDataRes> {
        return php.post('', {
            action_name: 'getCjListData',
            ...data
        })
    }

    deleteSaleRecord (id: number) {
        return php.post('', {
            action_name: 'deleteSaleRecord',
            id
        })
    }

    // 保存争议单记录
    saveProblemDealSlipRecord (data: saveProblemDealSlipRecordReq) {
        return java.post('/admin/channel/achievements/manage/problem/dealslip/record/saveProblemDealSlipRecord', data)
    }

    // 获取争议单列表记录
    async getChannelProblemDealSlipRecord (data: getChannelProblemDealSlipRecordReq): BaseRes<ListRes<getChannelProblemDealSlipRecordRes>> {
        const res = await java.post('/admin/channel/achievements/manage/problem/dealslip/record/getChannelProblemDealSlipRecord', data)
        res.data.list.map((a:any) => {
            a.duijie_user_info = a.duijie_user_info ? JSON.parse(a.duijie_user_info) : []
        })
        return res as any
    }

    // 申诉单审核
    updateProblemDealSlipStatus (data: updateProblemDealSlipStatusReq) {
        return java.post('/admin/channel/achievements/manage/problem/dealslip/record/updateProblemDealSlipStatus', data)
    }

    // 获取渠道积分列表
    getGroupByUserIdScoreList (data: getGroupByUserIdScoreListReq): BaseRes<getGroupByUserIdScoreListRes[]> {
        return java.post('/admin/channel/achievements/manage/score/record/getGroupByUserIdScoreList', data)
    }

    // 获取渠道积分详情
    getScoreRecordDetail (data: getScoreRecordDetailReq): BaseRes<ListRes<getScoreRecordDetailRes>> {
        return java.post('/admin/channel/achievements/manage/score/record/getScoreRecordDetail', data)
    }

    // 添加渠道积分
    saveScoreRecord (data: saveScoreRecordReq) {
        return java.post('/admin/channel/achievements/manage/score/record/saveScoreRecord', data)
    }

    // 保存楼盘负责人
    saveHouseBindUser (data: saveHouseBindUserReq) {
        return java.post('/admin/channel/achievements/manage/house/user/bind/saveHouseBindUser', data)
    }

    // 解绑楼盘负责人
    deleteHouseBindUser (id: number) {
        return java.post('/admin/channel/achievements/manage/house/user/bind/deleteHouseBindUser', { id })
    }

    // 编辑楼盘负责人
    updateHouseBindUser (data: updateHouseBindUserReq) {
        return java.post('/admin/channel/achievements/manage/house/user/bind/updateHouseBindUser', data)
    }

    // 获取楼盘绑定信息
    getHouseChannelBindDetail (house_id: number): BaseRes<getHouseChannelBindDetailRes> {
        return java.post('/admin/channel/achievements/manage/house/user/bind/getHouseChannelBindDetail', { house_id })
    }

    // 获取楼盘负责人绑定列表
    async getHouseUserBindRecord (data: getHouseUserBindRecordReq): BaseRes<ListRes<getHouseUserBindRecordRes>> {
        const res = await java.post('/admin/channel/achievements/manage/house/user/bind/getHouseUserBindRecord', data)
        res.data.list.map((a:any) => {
            a.duijie_user_info = a.duijie_user_info ? JSON.parse(a.duijie_user_info) : []
        })
        return res as any
    }

    // 获取渠道部成员
    getChannelMemberList (data: getChannelMemberListReq): BaseRes<getChannelMemberListRes[]> {
        return java.post('/admin/channel/achievements/manage/channel/member/getChannelMemberList', data)
    }

    // 预付款使用记录
    async getSalePartnerDepositUseRecordPageList (data: getSalePartnerDepositUseRecordPageListReq): BaseRes<ListRes<getSalePartnerDepositUseRecordPageListRes>> {
        return java.post('/admin/channel/sale/partner/deposit/getSalePartnerDepositUseRecordPageList', data)
    }

    // 预付款变动记录
    async getSalePartnerDepositChangeRecordPageList (data: getSalePartnerDepositChangeRecordPageListReq): BaseRes<ListRes<getSalePartnerDepositChangeRecordPageListRes>> {
        const res = await java.post('/admin/channel/sale/partner/deposit/getSalePartnerDepositChangeRecordPageList', data)
        res.data.list.forEach((a:any) => {
            a.house_list_from = JSON.parse(a.house_list_from)
            a.house_list_to = JSON.parse(a.house_list_to)
        })
        return res as any
    }
}
