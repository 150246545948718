import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { FxsUserType, Gender } from '@/util/const'

interface dataSource {
    Id: number
}

@Component({
    components: {
    }
})
export default class EditUserComponent extends Vue {
    @Prop({
        default: false
    })
    visible!:boolean

    @Prop({
        default () {
            return {}
        }
    })
    data!:dataSource

    formLabelWidth = '96px'
    form: {
        dep: string | number
        group: string | number
        name: string
        mobile: string
        identity?: number
        type?: number
        sex?: number
        probationDateBegin: string
        probationDateEnd: string
    } = {
        dep: '',
        group: '',
        name: '',
        mobile: '',
        type: undefined,
        sex: undefined,
        probationDateBegin: '',
        probationDateEnd: ''
    }
    rules = {
        dep: [
            { required: true, message: '请选择部门', trigger: 'change' }
        ],
        group: [
            { required: true, message: '请选择战队', trigger: 'change' }
        ],
        name: [
            { required: true, message: '请输入姓名', trigger: 'change' }
        ],
        mobile: [
            { required: true, message: '请输入手机号', trigger: 'change' },
            { min: 11, max: 11, message: '请输入正确手机号', trigger: 'blur' }
        ]
    }
    fxsDetail = {}

    roleList:{
        label: string
        value: number
        status: number
    }[] = []

    FxsUserType = FxsUserType

    Gender = Gender

    pickerOptions: {
        disabledDate: Function
    } = {
        disabledDate: () => {}
    }

    get
    isEditStatus () {
        return this.data.Id && +this.data.Id > 0
    }

    get
    title () {
        return this.isEditStatus ? '编辑分析师' : '创建分析师'
    }

    get
    commonDepText () {
        return this.__utils.index.getComDepByDepId(+this.form.dep).name
    }

    get
    depList () {
        return this.__utils.index.getXsDep()
    }

    get
    groupList () {
        return this.__utils.index.getGroupsByDepId(+this.form.dep)
    }

    @Watch('data')
    dataSourceChange (obj:dataSource) {
        if (obj.Id) {
            this.getData(obj.Id)
        }
    }

    async created () {
        this.roleList = await this.__utils.index.getAdminRoleList()
        this.roleList = this.roleList.filter(a => [3, 5].includes(a.value))
    }

    async getData (id: number) {
        let { data } = await this.__apis.system.getFxsDetail(id)
        this.fxsDetail = data
        this.form = {
            dep: data.department || '',
            group: data.group || '',
            name: data.username,
            mobile: String(data.mobile),
            identity: data.role,
            type: data.regular,
            sex: data.sex,
            probationDateBegin: data.probation_begin ? this.__utils.dayjs(data.probation_begin).format('YYYY-MM-DD') : '',
            probationDateEnd: data.probation_end ? this.__utils.dayjs(data.probation_end).format('YYYY-MM-DD') : ''
        }

        this.pickerOptions = {
            disabledDate: (time: Date) => {
                return time.getTime() < new Date(this.form.probationDateBegin.replace(/-/g, '/')).getTime()
            }
        }
    }

    close () {
        this.$emit('update:visible', false)
        const form = this.$refs['form'] as any
        form.resetFields()
    }

    checkValid () {
        const form = this.$refs['form'] as any
        form.validate((valid: any) => {
            if (valid) {
                this.save()
            } else {
                return false
            }
        })
    }

    async save () {
        let loading:any
        const text = this.data.Id ? '编辑' : '创建'
        let groupObj = this.__utils.index.getGroupInfoById(Number(this.form.group))

        try {
            await this.$confirm(
                `<div class="f_msg">请确认是否${text} <span class="name">${this.form.name} ${groupObj.name}</span> 的分析师账号  手机号：<span class="name">${this.form.mobile}</span></div>`,
                `${text}确认`, {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    customClass: 'my_confirm my_confirm_fxs',
                    center: true
                })

            loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            })

            await this.__apis.system.createAndSaveFxs({
                id: this.data.Id,
                user_name: this.form.name,
                mobile: this.form.mobile,
                group_id: this.form.group,
                dep_id: this.form.dep,
                company: this.form.dep === 4 ? '广州' : '杭州',
                role: this.form.identity,
                common_dep_id: this.__utils.index.getComDepByDepId(+this.form.dep).value,
                regular: this.form.type,
                sex: this.form.sex,
                probation_end: this.form.probationDateEnd ? this.form.probationDateEnd + ' 23:59:59' : ''
            })

            setTimeout(() => {
                loading.close()
                this.close()
                this.$emit('save')
                this.$notify({
                    title: '成功',
                    message: '保存成功！',
                    type: 'success'
                })
            }, 400)
        } catch (e) {
            loading && loading.close()
            console.log(e)
        }
    }

    departmentChange () {
        this.form.group = ''
    }
}
