import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import FilterComponent from '@/components/filters/filter/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'
import ReasonComponent from '@/components/wallet/reason/index.vue'
import { apiConfig } from '@/api/config'
import { __localStorage, storageName } from '@/localStorage'
import EditReviewComponent from '@/components/wallet/edit-review/index.vue'
import { editReviewData } from '@/components/wallet/edit-review'
import { UserDispatchType } from '@/store/user'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'

interface TableItem {
    id: number
    department: string
    group: string
    fxs: string
    time: string
    getMoney: string
    status: string
    tax: string
    real_money: string
    submitMoney: string
    reduceSubmitMoney: string
    SalaryMoney: string
    sendTime: string | null
}

interface Filter {
    [index: string]: string | string[]
    department: string
    group: string
    fxs: string
    time: string[]
    status: string
    sendTime: string[]
}

@Component({
    components: {
        FilterComponent,
        SelectComponent,
        InputDateRangeComponent,
        CheckBoxComponent,
        ReasonComponent,
        EditReviewComponent,
        FilterBusinessSelect
    }
})
export default class IndexPage extends Vue {
    editReviewComponent: {
        show: boolean,
        data: editReviewData
    } = {
        show: false,
        data: {
            id: '',
            name: '',
            dep: '',
            group: '',
            money: '',
            tax: '',
            real_money: '',
            reduceSubmitMoney: '',
            submitMoney: ''
        }
    }

    reasonComponent = {
        show: false,
        id: 0
    }

    filter: Filter = {
        department: '',
        group: '',
        fxs: '',
        time: [],
        status: '',
        sendTime: []
    }

    status = [
        {
            value: 0,
            label: '审核中'
        }, {
            value: 1,
            label: '已通过'
        }, {
            value: 2,
            label: '已驳回'
        }
    ]

    tableData: Table <TableItem> = {
        data: [],
        page: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        total: 50
    }

    selectData: number[] = []

    uploadUrl = apiConfig.apiUrl
    token = __localStorage.getLocalStorage(storageName.TOKEN)

    created () {
        this.getTableData()
    }

    // 导入成功
    uploadSuccess (res: any) {
        if (res.errCode !== 0) {
            this.$message.error('导入失败')
            return
        }

        this.$message.success('导入成功')

        this.tableData.page = 1
        this.getTableData()
    }

    // 导出
    exportFile () {
        this.__utils.index.downloadFile({
            action_name: 'exportCashOut',
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            common_dep_id: this.filter.department,
            group_id: this.filter.group,
            employee_id: this.filter.fxs,
            status: this.filter.status,
            time_start: this.filter.time[0],
            time_end: this.filter.time[1],
            send_time_start: this.filter.sendTime[0],
            send_time_end: this.filter.sendTime[1]
        })
    }

    edit (item: TableItem) {
        this.editReviewComponent.show = true
        this.editReviewComponent.data = {
            id: item.id.toString(),
            tax: item.tax,
            name: item.fxs,
            dep: item.department,
            group: item.group,
            money: item.getMoney,
            real_money: item.real_money,
            submitMoney: item.submitMoney,
            reduceSubmitMoney: item.reduceSubmitMoney
        }
    }

    // 修改个税
    changeTax () {
        this.getTableData()
    }

    selectable (item: TableItem) {
        return item.status === '审核中'
    }

    async getTableData () {
        const { data } = await this.__apis.wallet.getWalletCashOutRecord({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            common_dep_id: this.filter.department,
            group_id: this.filter.group,
            employee_id: this.filter.fxs,
            status: this.filter.status,
            time_start: this.filter.time[0],
            time_end: this.filter.time[1],
            send_time_start: this.filter.sendTime[0],
            send_time_end: this.filter.sendTime[1]
        })

        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.Id,
                department: a.UserInfo.CommonDepartmentName,
                group: a.UserInfo.GroupName,
                fxs: a.UserInfo.UserName,
                time: a.Ctime,
                getMoney: this.__utils.index.formatMoney(a.Money),
                status: this.status.filter(s => s.value === a.Status)[0].label,
                tax: this.__utils.index.formatMoney(a.Tax),
                real_money: this.__utils.index.formatMoney(a.RealMoney),
                submitMoney: this.__utils.index.formatMoney(a.ExpenseMoney),
                reduceSubmitMoney: this.__utils.index.formatMoney(a.ExpenseDeductMoney),
                SalaryMoney: this.__utils.index.formatMoney(a.SalaryMoney),
                sendTime: a.SendTime
            }
        })

        this.tableData.data.unshift({
            id: -1,
            department: '合计',
            group: '',
            fxs: '',
            time: '',
            getMoney: this.__utils.index.formatMoney(+data.statistic.Money),
            status: '',
            tax: this.__utils.index.formatMoney(+data.statistic.Tax),
            real_money: this.__utils.index.formatMoney(+data.statistic.RealMoney),
            submitMoney: this.__utils.index.formatMoney(+data.statistic.ExpenseMoney),
            reduceSubmitMoney: this.__utils.index.formatMoney(+data.statistic.ExpenseDeductMoney),
            SalaryMoney: this.__utils.index.formatMoney(+data.statistic.SalaryMoney),
            sendTime: ''
        })
    }

    searchDep () {}

    searchFxs (text: string, cb: any) {
        this.__utils.index.searchEmployee(text, cb)
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)

        if (!this.filter.time) {
            this.filter.time = []
        }

        if (!this.filter.sendTime) {
            this.filter.sendTime = []
        }

        this.tableData.page = 1
        this.getTableData()
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }

    // 审核操作 可以多个id
    async examineCashOut (id: number | number[], status: number, reason?: string) {
        if (typeof id === 'number') {
            await this.__apis.wallet.examineCashOut({
                id,
                status,
                reject_reason: reason
            })
        } else {
            const promises = id.map(a => this.__apis.wallet.examineCashOut({
                id: a,
                status,
                reject_reason: reason
            }))

            await Promise.all(promises)
        }

        // 审核完毕 重新加载列表
        this.getTableData()

        // 刷新待审核提示
        this.__store.dispatch(UserDispatchType.setFinancialReviewCount)
    }

    // 一键通过
    async allPass () {
        try {
            await this.$confirm(`<span style="color: #F5222D;">你选择了 ${this.selectData.length} 个选项，是否一键通过所选提现申请
请再次确认申请信息准确无误</span>`, '提现申请审核', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })

            await this.examineCashOut(this.selectData, 1)

            this.$message({
                type: 'success',
                message: '已通过'
            })
        } catch (e) {
            console.log(e)
        }
    }

    // 通过
    async pass (item: TableItem) {
        try {
            await this.$confirm(`你确定通过 <span style="color: #408AF0;">${item.department} ${item.fxs}</span> 的提现申请吗`, '提现申请审核', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })

            await this.examineCashOut(item.id, 1)

            this.$message({
                type: 'success',
                message: '已通过'
            })
        } catch (e) {
            console.log(e)
        }
    }

    /* 驳回 */
    async reject (reason: string) {
        await this.examineCashOut(this.reasonComponent.id, 2, reason)

        this.$message({
            type: 'success',
            message: '已驳回'
        })
    }

    showReasonComponent (item: TableItem) {
        this.reasonComponent.show = true
        this.reasonComponent.id = item.id
    }

    selectionChange (a: any) {
        this.selectData = a.map((b: any) => b.id)
    }
}
