import { Component, Vue } from 'vue-property-decorator'
import { LabelValueArray, Table } from '@/types'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'
import dateRangeUtil from '@/util/date'
import EditMoneyComponent from './edit/index.vue'

interface TableItem {
    id: number
    depName: string
    groupName: string
    groupProfitMoney: number
    groupLeaderMoney: number
    saleMoney: number
    customerMoney: number
    userMoney: number
    fxsProfitMoney: number
    orderCustomerMoney: number
    groupLeaderFinalMoney: number
}

interface Filter {
    dep: undefined | number,
    month: string,
    fastMonth: {
        current: number,
        data: LabelValueArray<string>
    }
}

interface EditMoneyComponentType {
    visible: boolean
    item: {
        id: number
        value: string
    }
}

@Component({
    components: {
        InputSelectSearch,
        EditMoneyComponent
    }
})
export default class HomeTeamProfitPage extends Vue {
    filter: Filter = {
        dep: undefined,
        month: '',
        fastMonth: {
            current: 0,
            data: [
                {
                    label: '本月',
                    value: dateRangeUtil.getCurrentMonth()[0].format('yyyy-MM')
                },
                {
                    label: '上月',
                    value: dateRangeUtil.getPreviousMonth()[0].format('yyyy-MM')
                }
            ]
        }
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0,
        loading: false
    }

    editMoneyComponent: EditMoneyComponentType = {
        visible: false,
        item: {
            id: 0,
            value: ''
        }
    }

    get
    depList () {
        return this.__utils.index.getXsDep()
    }

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    created () {
        if (this.infoPos.dep) {
            this.filter.dep = this.infoPos.dep
        }
        this.fastMonthHandle(this.filter.fastMonth.current)
    }

    async getData () {
        this.tableData.loading = true
        const filter = this.filter
        const { data } = await this.__apis.home.getProfitRecord({
            month: filter.month + '-01',
            dep_id: filter.dep
        })
        this.tableData.data = data.map(a => {
            return {
                id: a.id,
                depName: this.__utils.index.getDepInfoById(a.dep_id).v,
                groupName: this.__utils.index.getGroupInfoById(a.group_id).name,
                groupProfitMoney: a.profit_money,
                groupLeaderMoney: a.leader_commision,
                groupLeaderName: a.leader_username,
                saleMoney: a.total_sale_money,
                customerMoney: a.customer_cost,
                userMoney: a.person_cost,
                fxsProfitMoney: a.fxs_commision_cost,
                orderCustomerMoney: a.customer_order_money,
                groupLeaderFinalMoney: a.leader_final_money
            }
        })
        this.tableData.total = data.length
        this.tableData.loading = false
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getData()
    }

    resetFilter () {
        this.filter = {
            dep: undefined,
            month: '',
            fastMonth: {
                current: 0,
                data: [
                    {
                        label: '本月',
                        value: dateRangeUtil.getCurrentMonth()[0].format('yyyy-MM')
                    },
                    {
                        label: '上月',
                        value: dateRangeUtil.getPreviousMonth()[0].format('yyyy-MM')
                    }
                ]
            }
        }
        this.fastMonthHandle(this.filter.fastMonth.current)
    }

    fastMonthHandle (index: number) {
        this.filter.fastMonth.current = index
        this.filter.month = this.filter.fastMonth.data[index].value
        this.getData()
    }

    monthChange (value: string) {
        this.filter.fastMonth.data.forEach((a, i) => {
            this.filter.fastMonth.current = a.value === value ? i : -1
        })
    }

    editUserMoney (item: TableItem) {
        this.editMoneyComponent = {
            visible: true,
            item: {
                id: item.id,
                value: String(item.userMoney)
            }
        }
    }

    async saveEditMoney (item: EditMoneyComponentType['item']) {
        if (this.__utils.index.limitRepeatClick()) return

        try {
            await this.__apis.home.modifyPersonCost({
                id: item.id,
                money: +item.value
            })
            this.$notify({
                title: '成功',
                message: '保存成功',
                type: 'success'
            })
            this.getData()
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }
}
