import { Component, Vue } from 'vue-property-decorator'
import InputHouseSearch from '@/components/InputHouseSearch/index.vue'
import mixin from '../mixin'
import orderCustomer from './orderCustomer/index.vue'
import invalidRecord from './invalidRecord/index.vue'
import orderRecord from './orderRecord/index.vue'
import orderCustomerDialog from './orderCustomerDialog/index.vue'
import areaSetDialogComponent from './areaSetDialog/index.vue'

@Component({
    components: {
        InputHouseSearch,
        orderCustomer,
        invalidRecord,
        orderRecord,
        orderCustomerDialog,
        areaSetDialogComponent
    },
    mixins: [mixin]
})
export default class CustomerNewPage extends Vue {
    tabs = {
        current: 0,
        data: [{
            id: 1,
            text: '当前预约客户'
        }, {
            id: 2,
            text: '预约记录'
        }
        // , {
        //     id: 3,
        //     text: '退客记录'
        // }
        ],
        refresh: false
    }
    total = 0
    order = {
        visible: false
    }

    areaSetDialog = {
        visible: false
    }

    mounted () {
        this.tabs.current = this.tabs.data[0].id
    }

    tabsClick (index: number) {
        this.tabs.current = index
    }

    refreshData () {
        this.tabs.refresh = true
        let orderRecord = this.$refs.orderRecord as any
        if (orderRecord) {
            orderRecord.getData()
        }
    }

    totalChange (total:number) {
        this.total = total
    }

    orderCustomerVisible () {
        this.order.visible = true
    }

    areaSetVisible () {
        this.areaSetDialog.visible = true
    }
}
