import { Component, Vue } from 'vue-property-decorator'
import { Notification } from 'element-ui'
import { getSalePartnerBizRecordListRes, searchDuiJieUserByNameRes, getPartnerDetailRes } from '@/api/channelManage'
import { FriendStatus } from '@/util'

interface history {
    data: getSalePartnerBizRecordListRes['list']
    value: string
    page: number
    pagesize: number
    total: number
    loading: boolean
}

interface people {
    rawData: people['data']
    data: Omit<getPartnerDetailRes['DuijieUser'][0], 'status'>[]
}

@Component
export default class ChannelManageFriendDetailInfoPage extends Vue {
    people: people = {
        rawData: [],
        data: []
    } // 对接人信息

    friendName = '' // 合作商全名

    simpleName = '' // 简称

    department = {
        value: 0
    } // 归属部门

    intro = '' // 合作商介绍

    startDate: string | null = '' // 开始合作时间

    status: {
        data: {
            value: number
            label: string
        }[]
        value: undefined | number
    } = {
        data: FriendStatus,
        value: undefined
    } // 合作状态

    history: history = {
        data: [],
        page: 1,
        pagesize: 20,
        total: 1,
        loading: false,
        value: ''
    } // 业务往来历史

    get
    cmtBtnSubmitDisabled () {
        return !(this.friendName.trim() && this.simpleName.trim() && this.intro.trim() && this.status.value && this.cmtPeopleData.length && this.startDate)
    }
    get
    cmtBtnAddDisabled () {
        return !(this.history.value.trim())
    }
    get
    cmtPeopleData () {
        return this.people.data.filter(a => a.id)
    }

    get
    roleEdit () {
        const status = this.status.value ? this.status.data.filter(a => a.value === this.status.value)[0].label : ''

        return {
            authKey: this.__role.permissions.SaveSalePartner,
            values: [this.friendName, this.simpleName, this.intro, this.startDate, status],
            selector: '.input'
        }
    }

    created () {
        this.loadData()
        this.loadHistory()
    }

    // 滚动加载业务记录
    scroll (e: Event) {
        let target = e.target as HTMLElement

        if (target.scrollHeight - target.clientHeight - target.scrollTop < 20) {
            this.loadHistory()
        }
    }

    // 加载业务记录
    async loadHistory () {
        if ((this.history.page > this.history.total) || this.history.loading) {
            return
        }
        try {
            this.history.loading = true

            const { data } = await this.__apis.channelManage.getSalePartnerBizRecordList({
                page: this.history.page,
                pagesize: this.history.pagesize,
                partner_id: this.$route.params.id
            })

            this.history.page += 1
            this.history.total = Math.max(1, data.total_page)

            data.list.forEach(a => {
                this.history.data.push(a)
            })
        } catch (e) {
            throw e
        } finally {
            this.history.loading = false
        }
    }

    // 新增业务记录
    async addNewHistory () {
        await this.__apis.channelManage.saveSalePartnerBizRecord({
            content: this.history.value,
            partner_id: this.$route.params.id
        })

        // 新增成功
        Notification.success('新增成功')

        this.history.value = ''
        this.history.page = 1
        this.history.data = []
        this.loadHistory()
    }

    duijieUsersBySearch: searchDuiJieUserByNameRes = []

    // 搜索对接人
    async searchDuijieUser (word: string) {
        const { data } = await this.__apis.channelManage.searchDuiJieUserByName(word)
        this.duijieUsersBySearch = data
    }

    // 删除对接人
    deletePeople (index: number) {
        this.people.data.splice(index, 1)
    }

    // 选择对接人 - 填充手机号
    selectDuijieUser (item: people['data'][0]) {
        const data = this.duijieUsersBySearch.filter(a => a.id === Number(item.username))[0]
        if (!data) return

        item.username = data.username
        item.id = data.id
        item.tel = data.tel
    }

    // 新增一条对接人数据
    addPeopleData () {
        this.people.data.push({
            id: 0,
            username: '',
            job: '',
            tel: '',
            note: ''
        })
    }

    // 加载合作商数据
    async loadData () {
        const { data } = await this.__apis.channelManage.getPartnerDetail(this.$route.params.id)

        this.friendName = data.FullName
        this.simpleName = data.Name
        this.department.value = data.Dep
        this.intro = data.Introduction
        this.people.data = data.DuijieUser.map(a => {
            return {
                id: a.id,
                job: a.job,
                tel: a.tel,
                username: a.username,
                note: a.note
            }
        })
        this.people.rawData = this.__utils.index.copyObj(data.DuijieUser || [])
        this.startDate = data.StartTime
        this.status.value = data.Status
    }

    // 保存合作商信息
    async savePartner () {
        try {
            const people = this.__utils.index.copyObj(this.cmtPeopleData)

            // 检测对接人信息有无发生变化 发生变化需要提示
            if (this.people.data.length !== this.people.rawData.length || !this.people.data.every((a, i) => {
                const raw = this.people.rawData[i]
                return a.username === raw.username && a.tel === raw.tel
            })) {
                await this.$confirm(`修改对接人时请注意<br/>对接人绑定楼盘也需要修改`, '更改提醒', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
            }

            await this.__apis.channelManage.saveSalePartner({
                id: this.$route.params.id,
                name: this.simpleName,
                full_name: this.friendName,
                dep: this.department.value,
                introduction: this.intro,
                status: this.status.value,
                start_time: this.startDate || '',
                duijie_user: JSON.stringify(people.map(a => {
                    return {
                        id: a.id,
                        job: a.job,
                        note: a.note
                    }
                }))
            })

            // 新增成功
            Notification.success('修改成功')
            this.loadData()
        } catch (e) {
            console.log(e)
        }
    }
}
