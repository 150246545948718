import Vue from 'vue'
import '@/util/fixLocalStorage'
import store from './store'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import $ from 'jquery'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueCropper from 'vue-cropper'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import __api from '@/api'
import __utils, { __utils as utils } from '@/util'
import __localStorage from '@/localStorage'
import __role from '@/role'
import { ElNotificationOptions } from 'element-ui/types/notification'
import '@/util/sentry'
import { qmPermissionInstall } from 'qm-plugin-admin-permission'

window.$ = $

Vue.use(qmPermissionInstall)
Vue.use(Vuex)
Vue.use(__api)
Vue.use(__utils)
Vue.use(__localStorage)
Vue.use(__role)
Vue.use(ElementUI)
Vue.use(VueCropper)
Vue.use(Viewer, {
    defaultOptions: {
        inline: false, // 启用 inline 模式
        button: true, // 显示右上角关闭按钮
        navbar: false, // 显示缩略图导航
        title: false, // 显示当前图片的标题
        toolbar: true, // 显示工具栏
        tooltip: true, // 显示缩放百分比
        movable: true, // 图片是否可移动
        zoomable: true, // 图片是否可缩放
        rotatable: true, // 图片是否可旋转
        scalable: false, // 图片是否可翻转
        transition: true, // 使用 CSS3 过度
        fullscreen: false, // 播放时是否全屏
        keyboard: false, // 是否支持键盘
        url: 'data-source' // 设置大图片的 url
    }
})

// 指令
Vue.directive('number', {
    bind (el:any, binging) {
        // console.log(el, binging)
        el.$type = binging.value ? binging.value.type : 'keyup'
        el.$ele = el.querySelector('input')
        if (!el.$ele) return
        el.$handle = () => {
            let val = el.$ele.value
            let reg = /[^0-9]/g
            el.$ele.value = val.replace(reg, '')
            // 同步到响应式数据中
            utils.index.trigger(el.$ele, 'input')
        }
        el.$ele.addEventListener(el.$type, el.$handle)
    },
    unbind (el:any) {
        el.$ele.removeEventListener(el.$type, el.$handle)
    }
})

Vue.directive('format', (el, binding) => {
    binding.value.type = binding.value.type ? binding.value.type : 'yyyy年MM月dd日 hh:mm'
    if (binding.value.value) {
        binding.value.value = binding.value.value.replace(/-/g, '/')
    }
    el.innerText = binding.value.value ? new Date(binding.value.value).format(binding.value.type) : ''
})

// 全局的过滤器
Vue.filter('statusText', function (value: any, arr: any) {
    if (value === undefined) return
    return arr.filter((v: any) => v.value === value)[0] ? arr.filter((v: any) => v.value === value)[0].label : ''
})
// 2019-12-21  10:07:59
Vue.filter('timeFilter', function (value: any) {
    if (!value) return ''
    const arr = value.split(' ')
    return `${arr[0]}  ${arr[1]}`
})

Vue.directive('drag', {
    // 当被绑定的元素插入到 DOM 中时……
    inserted: (el) => {
        // 获取body
        const body = el.querySelector('.el-table__body-wrapper') as HTMLElement

        // 获取按下的位置
        body.onmousedown = (e) => {
            const startX = e.clientX
            const bodyScrollLeft = body.scrollLeft
            body.onmousemove = (e) => {
                body.scrollLeft = bodyScrollLeft + startX - e.clientX
            }
        }

        body.onmouseup = () => {
            body.onmousemove = null
        }

        el.onmouseleave = body.onmouseup
    }
})

Vue.directive('move', {
    // 当被绑定的元素插入到 DOM 中时……
    inserted: (el) => {
        const style = getComputedStyle(el)

        function getTopAndLeft () {
            const iTop = style.top ? parseInt(style.top) : 0
            const iLeft = style.left ? parseInt(style.left) : 0

            return {
                top: iTop,
                left: iLeft
            }
        }

        function setTopAndLeft (top: number, left: number) {
            el.style.top = top + 'px'
            el.style.left = left + 'px'
        }

        // 拖动开始时候的x, y, left, top
        let onDragX = 0
        let onDragY = 0
        let onDragLeft = 0
        let onDragTop = 0

        el.ondragover = (e) => { e.preventDefault() }

        el.ondragstart = (e) => {
            if (e.dataTransfer) {
                e.dataTransfer.effectAllowed = 'move'
            }

            const position = getTopAndLeft()
            onDragX = e.clientX
            onDragY = e.clientY
            onDragLeft = position.left
            onDragTop = position.top
        }

        el.ondrag = (e) => {
            // fix 拖动结束时候会有一次 0 0
            if (e.clientX === 0 && e.clientY === 0) return

            const x = e.clientX - onDragX
            const y = e.clientY - onDragY

            setTopAndLeft(onDragTop + y, onDragLeft + x)
        }
    }
})

function createInputText (el: HTMLInputElement) {
    const div = document.createElement('div') as HTMLDivElement
    const style = {
        width: `${el.offsetWidth}px`,
        height: `${el.offsetHeight}px`
    }
    for (const key in style) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        div.style[key] = style[key]
    }
    div.className = 'v_edit_see'
    return div
}

Vue.directive('edit', {
    inserted (el: any, binding) {
        el.$input = el
        el.$parent = el.parentNode
        el.$div = createInputText(el.$input)

        el.$handle = function inputTextToggle (binding: any) {
            el.$div.innerText = binding.value.value || ''
            if (el.tagName === 'TEXTAREA') {
                if (el.scrollHeight > el.clientHeight) {
                    el.$div.style['overflow-y'] = 'scroll'
                }
                window.addEventListener('resize', () => {
                    el.$div.style.width = `${el.$parent.offsetWidth}px`
                })
            }

            if (binding.modifiers.bg) {
                el.$div.style['padding-left'] = '0px'
                el.$div.style.background = 'transparent'
            }
            if (binding.value.isEdit) {
                el.$input.style.display = 'block'
                el.$div.style.display = 'none'
            } else {
                el.$div.style.display = 'block'
                el.$input.style.display = 'none'
            }
        }

        el.$handle(binding)
        el.$parent.appendChild(el.$div)
    },
    update (el: any, binding) {
        el.$input = el
        el.$handle(binding)
    }
})

// Vue Devtools
Vue.config.devtools = true

// 阻止 vue 在启动时生成生产提示
Vue.config.productionTip = false

function tips (this: Vue, text: string, type: ElNotificationOptions['type'] = 'success') {
    let title = ''
    switch (type) {
    case 'success':
        title = '成功'
        break
    case 'error':
        title = '失败'
        break
    case 'warning':
        title = '警告'
        break
    default:
        break
    }

    this.$notify({
        title: title,
        message: text,
        type: type
    })
}

Vue.prototype.$tips = tips
declare module 'vue/types/vue' {
    interface Vue {
        $tips: (text: string, type?: ElNotificationOptions['type']) => void
    }
}

new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App)
})

/*
	时间格式化
	new Date().format('yyyy-MM-dd hh:mm:ss')
*/
Date.prototype.format = function (fmt) {
    let o: {
        [index: string]: number
    } = {
        'M+': this.getMonth() + 1, // 月份
        'd+': this.getDate(), // 日
        'h+': this.getHours(), // 小时
        'm+': this.getMinutes(), // 分
        's+': this.getSeconds(), // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        S: this.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
    }
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            let a = ('00' + o[k]).substr(o[k].toString().length)
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k].toString() : a)
        }
    }
    return fmt
}
