import { Component, Vue, Prop } from 'vue-property-decorator'
import buttonsComponent from '@/components/wallet/buttons/index.vue'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'
import { searchUserRes } from '@/api/utils'
import { storageName } from '@/localStorage'
import { apiConfig } from '@/api/config'

interface Form {
    [index: string]: string
    userId: string,
    allMoney: string,
    department: string,
    group: string,
    remark: string
}

@Component({
    components: {
        buttonsComponent,
        inputSelectSearch
    }
})
export default class ImportComponent extends Vue {
    @Prop({
        default: '',
        type: String
    })
    type !: string

    @Prop({
        default: false,
        type: Boolean
    })
    show !: boolean

    @Prop({
        default () {
            return {
                userId: 0,
                userName: '',
                allMoney: '',
                department: '',
                group: '',
                fxs: '',
                remark: ''
            }
        },
        type: Object
    })
    item !: Form

    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }

    get
    cmtBtnEnterDisabled () {
        // 单个导入数据没有填写完 或者 批量导入没有导入数据
        let result = true

        if (this.tabs.active === 0) {
            for (let key in this.form) {
                if (key !== 'group') {
                    result = result && !!this.form[key]
                }
            }
        } else {
            if (this.upload.list.length) {
                result = this.upload.list[0].status !== 'fail'
            } else {
                result = false
            }

            result = result && (this.isGzCardImport ? true : this.upload.obj.action_time)
        }

        return !result
    }

    get
    isGzCardImport () {
        return this.type === '5'
    }

    get
    showTabs () {
        return ['1', '5'].includes(this.type)
    }

    tabs = {
        active: 0,
        tab: ['单个导入', '批量导入']
    }

    form: Form = {
        userName: '',
        userId: '',
        allMoney: '',
        department: '',
        group: '',
        remark: ''
    }

    upload: any = {
        url: apiConfig.apiUrl,
        list: [],
        obj: {
            action_name: 'importFinanceSendRecord',
            action_time: ''
        }
    }

    created () {
        this.form = this.__utils.index.copyObj(this.item)

        if (this.isGzCardImport) {
            this.upload = {
                url: `${apiConfig.jUrl}/admin/finance/manage/upload`,
                list: [],
                obj: {}
            }
        }
    }

    uploadSuccess (res: any, file: any) {
        if (res.errCode !== 0) {
            file.status = 'fail'
        }

        if (res.data.error_message) {
            file.status = 'fail'
            this.$message({
                type: 'error',
                dangerouslyUseHTMLString: true,
                message: res.data.error_rows.map((a: any) => `<div style="line-height: 1.6;">${a.error_message}</div>`).join(''),
                showClose: true,
                duration: 1000 * 10
            })
        }

        if (this.upload.list[0].status === 'success') {
            this.success()
        }
    }

    uploadChange (a: any, b: any) {
        this.upload.list = b

        if (this.upload.list.length > 1) {
            this.upload.list.shift()
        }
    }

    tabsChange (a: number) {
        this.tabs.active = a
    }

    limitMoneyInput (e: string, key: string, hasMinus = false) {
        let minus = e[0] === '-' && hasMinus ? '-' : ''

        this.form[key] = minus + this.__utils.index.limitMoneyByInput(e, 1000000)
    }

    selectName (a: searchUserRes['list'][0]) {
        this.form.userId = a.Id.toString()
        this.form.department = a.depName
        this.form.group = a.groupName
    }

    async enter () {
        if (this.__utils.index.limitRepeatClick()) return

        try {
            if (this.tabs.active === 0) {
                await this.__apis.chongzhiWallet.walletTransfer({
                    money: +this.form.allMoney * 100,
                    employee_id: +this.form.userId,
                    remark: this.form.remark
                })
            } else {
                if (this.upload.list[0].status === 'ready') {
                    const upload: any = this.$refs.upload
                    upload.submit()
                    return
                }
            }

            this.success()
        } catch (e) {
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    success () {
        this.$emit('enter')
        this.close()
    }

    close () {
        this.$emit('update:show', false)
    }
}
