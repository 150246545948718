import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'
import importDialogComponent from '../importDialog/index.vue'
import { storageName } from '@/localStorage'
import { apiConfig } from '@/api/config'

@Component({
    components: {
        FilterComponent,
        FilterBusInputSelectComponent,
        FilterBusSelectComponent,
        importDialogComponent
    },
    inject: ['parentThis']
})
export default class IndexPage extends Vue {
    @Prop({
        type: Object,
        default () {
            return {
                page: 1,
                pageSize: 10,
                datePicker: '',
                filter: {}
            }
        }
    })
    data !: {
        page: number,
        pageSize: number,
        datePicker: string,
        filter: any
    }

    dataObj: any = { list: [], total: 0 }
    page = this.data.page
    pageSize = this.data.pageSize
    datePicker = this.data.filter.date || this.data.datePicker
    filter = this.data.filter
    copyList = []
    uploadUrl = apiConfig.apiUrl
    uploadList = []
    importObj = {
        action_name: 'importSalaryList',
        action_time: '',
        type: -1
    }
    importDialog:any = {
        visible: false,
        gloading: null
    }

    get
    isEditList () {
        let list = this.copyList
        return this.dataObj.list.some((v: any, i: number) => {
            return (
                (v.MustMoney && v.MustMoney !== list[i]['MustMoney']) ||
                (v.GjjMoney && v.GjjMoney !== list[i]['GjjMoney']) ||
                (v.ShebaoMoney && v.ShebaoMoney !== list[i]['ShebaoMoney']) ||
                (v.SalaryTax && v.SalaryTax !== list[i]['SalaryTax']) ||
                (v.RealSalaryMoney && v.RealSalaryMoney !== list[i]['RealSalaryMoney']) ||
                (v.RealWalletSalaryMoney && v.RealWalletSalaryMoney !== list[i]['RealWalletSalaryMoney']) ||
                (v.SalaryRemark && v.SalaryRemark !== list[i]['SalaryRemark']) ||
                (v.SubsidyMoney && v.SubsidyMoney !== list[i]['SubsidyMoney']) ||
                (v.SubsidyRemark && v.SubsidyRemark !== list[i]['SubsidyRemark']) ||
                (v.ExpenseMoney && v.ExpenseMoney !== list[i]['ExpenseMoney']) ||
                (v.ExpenseSendMoney && v.ExpenseSendMoney !== list[i]['ExpenseSendMoney']) ||
                (v.ExpenseNotSendMoney && v.ExpenseNotSendMoney !== list[i]['ExpenseNotSendMoney']) ||
                (v.ExpenseRemark && v.ExpenseRemark !== list[i]['ExpenseRemark']) ||
                (v.RewardMoney && v.RewardMoney !== list[i]['RewardMoney']) ||
                (v.RewardRemark && v.RewardRemark !== list[i]['RewardRemark']) ||
                (v.BonusTax && v.BonusTax !== list[i]['BonusTax']) ||
                (v.BonusSend && v.BonusSend !== list[i]['BonusSend']) ||
                (v.OtherDeduct && v.OtherDeduct.length)
            )
        })
    }
    get
    infoPos () {
        return this.__store.state.user.infoPos
    }
    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }

    @Watch('data.datePicker')
    watchDataDatePicker () {
        this.datePicker = this.data.datePicker
        this.getGzList()
    }

    @Watch('data.page')
    watchDataPage () {
        this.page = this.data.page
    }

    @Watch('data.filter')
    watchDataFilter (obj: any) {
        this.datePicker = this.data.filter.date || this.data.datePicker
        this.filter = obj
        this.getGzList()
    }

    @Watch('datePicker')
    watchDatePicker (newVal: any) {
        this.editSaveBefore()

        this.page = 1
        this.getGzList()
        this.$emit('dateChange', newVal)
    }

    @Watch('page')
    watchPage () {
        this.getGzList()
        this.$emit('pageChange', this.page)
    }

    mounted () {
        this.getGzList()
    }

    importDialogVisible (val:boolean) {
        this.importDialog.visible = val
    }

    importSubmit (obj:any) {
        this.importObj = {
            action_name: 'importSalaryNew',
            ...obj
        }
        let importFile = document.querySelector('.import_box .infos') as HTMLElement
        importFile.click()
    }

    async editSaveBefore () {
        if (!this.isEditList) return

        try {
            await this.$confirm('当前有未保存内容，是否保存？')
            this.saveEditList()
        } catch (e) {
            console.log(e)
        }
    }

    pageElClick (e: any) {
        this.pageChange(e.target.innerText * 1)
    }

    async pageChange (page: number) {
        await this.editSaveBefore()
        this.page = page
    }

    // 筛选
    async filterChange (data: any) {
        await this.editSaveBefore()

        this.page = 1
        this.filter = data
        this.getGzList()
        this.$emit('filter', this.filter)
    }

    async getGzList () {
        let filter = this.filter || {}

        const { data } = await this.__apis.gzManage.getBasicSalaryRecord({
            page: this.page,
            pagesize: this.pageSize,
            ...filter,
            time_start: this.filter.date || this.datePicker,
            time_end: this.filter.date || this.datePicker
        })

        data.list.map(v => {
            v.ActionTime = new Date(v.ActionTime).format('yyyy-MM')
        })

        this.dataObj = data
        this.dataObj.show = true
        this.copyList = this.__utils.index.copyObj(this.dataObj.list)
    }

    getEditList () {
        let list = this.dataObj.list
        return list.filter((v: any) => {
            return (
                (v.MustMoney && v.MustMoney.length) ||
                (v.GjjMoney && v.GjjMoney.length) ||
                (v.ShebaoMoney && v.ShebaoMoney.length) ||
                (v.SalaryTax && v.SalaryTax.length) ||
                (v.RealSalaryMoney && v.RealSalaryMoney.length) ||
                (v.RealWalletSalaryMoney && v.RealWalletSalaryMoney.length) ||
                (v.SalaryRemark && v.SalaryRemark.length) ||
                (v.SubsidyMoney && v.SubsidyMoney.length) ||
                (v.SubsidyRemark && v.SubsidyRemark.length) ||
                (v.ExpenseMoney && v.ExpenseMoney.length) ||
                (v.ExpenseSendMoney && v.ExpenseSendMoney.length) ||
                (v.ExpenseNotSendMoney && v.ExpenseNotSendMoney.length) ||
                (v.ExpenseRemark && v.ExpenseRemark.length) ||
                (v.RewardMoney && v.RewardMoney.length) ||
                (v.RewardRemark && v.RewardRemark.length) ||
                (v.BonusTax && v.BonusTax.length) ||
                (v.BonusSend && v.BonusSend.length) ||
                (v.OtherDeduct && v.OtherDeduct.length)
            )
        })
    }

    saveEditList () {
        let list = this.getEditList()
        if (list.length === 0) {
            return
        }

        let count = 0
        list.map(async (v: any) => {
            await this.saveFn(v)

            count++
            if (count === list.length) {
                this.$notify({
                    title: '成功',
                    message: '保存成功！',
                    type: 'success'
                })
            }
        })
    }

    async saveFn (v: any) {
        await this.__apis.gzManage.saveBasicSalaryRecord({
            id: v.Id,
            user_id: v.UserId,
            action_time: this.datePicker,
            must_money: v.MustMoney,
            shebao_money: v.ShebaoMoney,
            gjj_money: v.GjjMoney,
            salary_tax: v.SalaryTax, 			// (工资税)
            bonus_tax: v.BonusTax, 				// （提成税）
            bonus_send: v.BonusSend,			// 已发提成
            real_salary_money: v.RealSalaryMoney,
            real_wallet_salary_money: v.RealWalletSalaryMoney,
            salary_remark: v.SalaryRemark,
            subsidy_money: v.SubsidyMoney, 		// （补贴金额）
            subsidy_remark: v.SubsidyRemark,
            expense_money: v.ExpenseMoney, 		// （报销金额）
            expense_send_money: v.ExpenseSendMoney,
            expense_not_send_money: v.ExpenseNotSendMoney,
            expense_remark: v.ExpenseRemark,
            reward_money: v.RewardMoney, 		// （ 奖金）
            reward_remark: v.RewardRemark,
            other_deduct: v.OtherDeduct // 其他扣减
        })
    }

    async saveListHanle () {
        await this.saveEditList()

        this.getGzList()
        this.$emit('edit', false)
        this.$emit('refresh', true)
    }

    pushToWalletTips (type: number) {
        const text = type === 1 ? '工资' : '提成'
        this.$confirm(
            `请再次确认是否生成钱包${text}数据，生成后不可修改`,
            `是否生成钱包${text}`,
            {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                iconClass: 'el-icon-question icon_gz_save_question',
                customClass: 'my_confirm my_confirm_gz_save',
                center: true
            }).then(() => {
            this.pushToWallet(type)
        }).catch(() => {})
    }

    async pushToWallet (type: number) {
        const loading = this.$loading({
            lock: true,
            text: '正在生成...',
            spinner: 'el-icon-loading',
            customClass: 'my_loading_box',
            background: 'rgba(0, 0, 0, 0.7)'
        })
        try {
            await this.__apis.gzManage.pushToWallet(type)
            this.$notify({
                title: '成功',
                message: '生成成功！',
                type: 'success'
            })
        } catch (e) {
            this.$notify({
                title: '失败',
                message: '生成失败！',
                type: 'error'
            })
            throw e
        } finally {
            loading.close()
        }
    }

    // 导出
    exportData (type: string) {
        if (type !== 'export') return

        let data = {
            action_name: 'exportSalaryList',
            ...this.filter,
            time_start: this.filter.date || this.datePicker,
            time_end: this.filter.date || this.datePicker
        }

        this.__utils.index.downloadFile(data)
    }

    // 上传之前
    beforeUpload () {
        this.importDialog.gloading = this.$loading({
            lock: true,
            text: '正在导入...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })
    }

    // 文件上传成功
    successFile (res: any, file: any, list: any) {
        this.importDialog.gloading.close()
        if (typeof res === 'string') {
            this.$notify({
                title: '导入失败',
                message: `${res}`,
                type: 'error'
            })
            return
        }
        if (res.errCode !== 0) {
            file.status = 'error'
            list.pop()
            this.$notify({
                title: '失败',
                message: '导入失败！',
                type: 'error'
            })
        } else {
            this.$notify({
                title: '成功',
                message: '导入成功！',
                type: 'success'
            })
            this.getGzList()
        }
    }
}
