import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class SelectInput extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    clearable !: boolean

    @Prop({
        type: Boolean,
        default: false
    })
    disabled !: boolean

    @Prop({
        type: String,
        default: 'small'
    })
    size !: string

    @Prop({
        type: [String, Number],
        default: ''
    })
    value !: string | number

    @Prop({
        type: String,
        default: '请输入'
    })
    placeholder !: string

    @Prop({
        type: Boolean,
        default: false
    })
    startSearch !: boolean

    @Prop({
        type: Boolean,
        default: true
    })
    remote !: boolean

    options = []
    word = this.value

    @Watch('value')
    watchValue (val: string) {
        this.word = val
    }

    @Watch('word')
    watchWord (newVal: string) {
        this.$emit('input', newVal)
    }

    change () {
        let res = this.options.filter((v: any) => v.value === this.word)
        this.$emit('select', res[0] || {})
    }

    created () {
        if (this.word && this.startSearch) {
            this.remoteCb('')
        }
    }

    focus () {
        if (this.startSearch) {
            this.remoteCb('')
        }
    }

    remoteCb (text: string) {
        this.$emit('search', text, (data: any) => {
            this.options = data
        })
    }
}
