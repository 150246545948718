import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'
import { __role } from '@/role'
import qmPermission from 'qm-plugin-admin-permission'

@Component({
})
export default class IndexPage extends Vue {
    tabs = [
        { label: '概览', route: '/financial/gongziManage/gailan', authKey: __role.permissions.ShowSalaryOverviewPage },
        { label: '提成管理', route: '/financial/gongziManage/ticheng', authKey: __role.permissions.ShowCommissionManagePage },
        { label: '工资管理', route: '/financial/gongziManage/gongzi', authKey: __role.permissions.ShowSalaryManagePage },
        { label: '工资设置', route: '/financial/gongziManage/gzUser', authKey: __role.permissions.ShowSalarySettingPage }
    ]

    created () {
        qmPermission.filter(this.tabs)
        if (this.$route.fullPath === '/financial/gongziManage') {
            router.replace(this.tabs[0].route)
        }
    }
}
