import { Component, Vue, Prop } from 'vue-property-decorator'

interface Item {
    url: string
}

const SliderWidth = 100

@Component({
    components: {}
})
export default class CallAudioComponent extends Vue {
    @Prop({
        default () {
            return {
                url: ''
            }
        }
    })
    dataSource!: Item

    info = {
        currentTime: 0,
        duration: 0,
        play: false
    }

    audio: null | HTMLAudioElement | any = null

    get
    playPauseImg () {
        return this.info.play ? 'https://ssl.malmam.com/xiangju-static/dfs/24/6b571aba7de1b5/_E5_BD_A2_E7_8A_B6_402x_281_29.png' : 'https://ssl.malmam.com/xiangju-static/dfs/26/6b5719454fdfad/_E5_BD_A2_E7_8A_B6_402x.png'
    }

    get
    currentTime () {
        return this.timeHandle(this.info.currentTime)
    }

    get
    duration () {
        return this.timeHandle(this.info.duration || 0)
    }

    get
    sliderValue () {
        const info = this.info
        const sx = info.currentTime / info.duration
        return Number((sx * SliderWidth).toFixed(4))
    }

    timeHandle (value: number) {
        const m = Math.floor(value / 60)
        const s = Math.floor(value % 60).toFixed(0)
        const res = [m, s].map(a => a < 10 ? `0${a}` : a).join(':')
        return res
    }

    play () {
        this.audio && this.audio.play()
        this.info.play = true
    }

    pause () {
        this.audio && this.audio.pause()
        this.info.play = false
    }

    end () {
        // console.log('end')
        this.pause()
        this.audio.currentTime = 0
    }

    loadeddata () {
        // console.log('loadeddata', this.audio.duration)
        this.info.duration = this.audio.duration
    }

    timeUpdate () {
        // console.log('timeUpdate', this.audio.currentTime, this.audio.duration)
        this.info.currentTime = this.audio.currentTime
    }

    sliderInputChange (value: number) {
        const sValue = (value / SliderWidth) * this.info.duration
        // console.log('sliderInputChange', value, parseInt(String(sValue)), parseInt(this.audio.currentTime))
        if (parseInt(String(sValue)) !== parseInt(this.audio.currentTime)) {
            this.info.currentTime = this.audio.currentTime = sValue
        }
    }

    actionChange () {
        // console.log('actionChange', this.audio.paused)
        if (this.audio.paused) {
            this.play()
        } else {
            this.pause()
        }
    }

    getElementObjectByElement (element: any) {
        const obj = {} as any
        for (let k in element) {
            obj[k] = element[k]
        }
        return obj
    }

    mounted () {
        this.audio = this.$refs.audio as HTMLAudioElement
        // console.log(this.getElementObjectByElement(this.audio))
    }
}
