import Vue from 'vue'
import Vuex, { CommitOptions, DispatchOptions, Store } from 'vuex'
import user, { User, UserCommits, UserCommitTypes, UserDispatches, UserDispatchType } from './user'
import success_detail, {
    SuccessDetail,
    SuccessDetailCommits,
    SuccessDetailCommitTypes,
    SuccessDetailDispatches,
    SuccessDetailDispatchType
} from '@/store/successDetail'
import { installPlugin } from '@/util/install'
import appStateModule, { StoreAppState } from '@/store/modules/app_state'
import connectionModule, { StoreConnectionState } from '@/store/modules/connection'
import messageModule, { StoreReceivedMessageState } from '@/store/modules/message'

export enum Module {
    CONNECTION = 'connection',
    APP_STATE = 'appState',
    MESSAGE = 'message',
}
const modules = {
    [Module.CONNECTION]: connectionModule,
    [Module.APP_STATE]: appStateModule,
    [Module.MESSAGE]: messageModule
}
type ModuleStore = StoreReceivedMessageState | StoreAppState | StoreConnectionState

type CommitsTypes = UserCommitTypes | SuccessDetailCommitTypes

type DispatchTypes = UserDispatchType | SuccessDetailDispatchType

type Commits = UserCommits & SuccessDetailCommits

type Dispatches = UserDispatches & SuccessDetailDispatches

export type RootState = {
    user: User,
    success_detail: SuccessDetail
}

export type __store = {
    dispatch: <T extends DispatchTypes>(type: T, payload?: Dispatches[T], options?: DispatchOptions) => Promise<any>
    commit: <T extends CommitsTypes>(type: T, payload?: Commits[T], options?: CommitOptions) => void
    state: RootState
}

Vue.use(Vuex)

declare module 'vue/types/vue' {
    interface Vue {
        __store: __store
    }
}

type MethodModule = {
    module: <T extends ModuleStore>(module: Module) => Store<T>
}

const store = new Vuex.Store({
    modules: {
        user,
        success_detail,
        ...modules
    }
}) as Store<RootState> & MethodModule

// 获取module必须使用单例模式
const _moduleCache:{[key: string]: any} = {}
function getModuleStore<T extends ModuleStore> (module: Module): Store<T> {
    if (_moduleCache[module]) {
        return _moduleCache[module] as Store<T>
    }
    const state = (store.state as any)[module]
    const s = new Vuex.Store<T>({
        ...modules[module],
        state: <T>state,
        namespaced: true
    } as any)
    _moduleCache[module] = s
    return s as Store<T>
}

store.module = getModuleStore

export const __store: __store = {
    async dispatch (type, payload, options) {
        await store.dispatch(type, payload, options)
    },

    commit (type, payload, options) {
        store.commit(type, payload, options)
    },

    state: store.state as RootState
}

Vue.use({
    install: installPlugin('__store', __store)
})

export default store
