import { Component, Prop, Vue } from 'vue-property-decorator'
import { Notification } from 'element-ui'
import { SuccessDetailDispatchType } from '@/store/successDetail'

interface options {
    show: boolean
    remark: string
    money: string
    date: string
    id: number
}

@Component
export default class SuccessDetailEnd extends Vue {
    @Prop({
        type: Object,
        default: {
            show: false,
            remark: '',
            money: '',
            date: '',
            id: undefined
        }
    })
    options !: options

    saveCompanyMoney = {
        id: [0],
        has_deposit: false,
        money: 0
    }

    useMoneyTab = {
        cur: 0,
        list: [
            { label: '使用' },
            { label: '不使用' }
        ]
    }

    get
    useSaveCompanyMoneyStatus () {
        return this.saveCompanyMoney.has_deposit && this.useMoneyTab.cur === 0
    }

    get
    cmtBtnSaveEnabled () {
        return !!(this.options.date && this.options.money !== undefined && this.options.remark)
    }

    get
    allData () {
        return this.__store.state.success_detail.all_data
    }

    useMoney (index: number) {
        this.useMoneyTab.cur = index
    }

    async datePickerChange () {
        const opt = this.options

        if (!opt.date) {
            return
        }

        const { data } = await this.__apis.financial.getDepositRemainMoney({
            partner_id: this.allData.ChannelPartnerId,
            dao_zhang_date: opt.date,
            house_id: this.allData.HouseId
        })

        this.saveCompanyMoney = {
            id: data.id,
            has_deposit: data.has_deposit,
            money: data.remain_money
        }
    }

    closeSelf () {
        this.options.show = false
    }

    saveLoading = false
    async save () {
        if (this.useSaveCompanyMoneyStatus) {
            const money = Number(this.options.money)

            if (money > this.saveCompanyMoney.money) {
                return Notification.warning('结佣金额不能大于预付款余额！')
            }
        }

        if (this.saveLoading) return
        this.saveLoading = true
        try {
            if (this.options.id) {
                await this.__apis.financial.saveSaleDzRecord({
                    id: this.options.id,
                    sale_id: +this.$route.params.id,
                    remark: this.options.remark,
                    money: this.options.money,
                    daozhang_time: this.options.date
                })
            } else {
                await this.__apis.financial.saveUserSaleDaoZhangRecord({
                    sale_id: +this.$route.params.id,
                    remark: this.options.remark,
                    daozhang_time: this.options.date,
                    deposit_ids: this.useSaveCompanyMoneyStatus ? this.saveCompanyMoney.id : [],
                    money: Number(this.options.money)
                })
            }

            // 保存成功
            Notification.success('操作成功')
            this.__store.dispatch(SuccessDetailDispatchType.setAllData, +this.$route.params.id)

            this.closeSelf()
        } finally {
            this.saveLoading = false
        }
    }
}
