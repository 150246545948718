import { Component, Vue } from 'vue-property-decorator'
import { routerBaseUrl } from '@/router'
import { AnswerStatus } from '@/util/const'
import { UserCommitTypes } from '@/store/user'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'

@Component({
    components: {
        inputSelectSearch
    }
})
export default class ActivityTask extends Vue {
    tab = [
        {
            label: '今日待跟进',
            value: 0
        },
        {
            label: '今日带看',
            value: 1
        },
        {
            label: '即将超时',
            value: 2
        },
        {
            label: '今日提升',
            value: 3
        },
        {
            label: '新客推荐',
            value: 4
        }
    ]
    tabIndex = 0
    dataOverview = {
        dk_count: 0,
        follow_count: 0,
        overtime_count: 0
    }
    dataObj: any = {
        list: []
    }
    page = 1
    pageSize = 20
    loading = false
    showTask = false

    questionComplete = false

    taskAnswerPo: null | {
        id: number
    } = null

    private noMore:boolean = true
    private rows1:any = []
    private rows2:any = []
    private rows1Total: number = 0
    private rows2Total: number = 0
    private currentIndex = 0
    private currentCustomer:any = {}
    private currentUserCustomer:any = {}

    private dialogRobVisible:boolean = false

    get
    RobCustomer () {
        return this.__role.check(this.__role.permissions.RobCustomer)
    }

    get
    isFxs () {
        return this.__role.has([this.__role.ROLE.FENXISHI])
    }

    get
    isAnswerResolve () {
        return this.__store.state.user.resolveQuestion
    }

    get
    isHz () {
        return [2, 6].includes(this.__store.state.user.userInfo.dep)
    }

    get
    isGzFxs () {
        return [4].includes(this.__store.state.user.userInfo.dep) && !this.RobCustomer
    }

    async created () {
        if (this.RobCustomer) {
            // 队长
            this.tabIndex = 4
            this.getCustomerDate()
        } else {
            // fxs

            if (!this.__role.check(this.__role.permissions.RobCustomerAction)) {
                // 新客推荐
                this.tab = this.tab.filter(a => a.value !== 4)
            }

            if (this.__role.check(this.__role.permissions.ShowQuestionAnswerRecordPage)) {
                // await this.getQuestionTask()
                if (!this.taskAnswerPo) {
                    this.__store.commit(UserCommitTypes.setHasQuestionTaskInfo, false)
                    this.tab = this.tab.filter(a => a.value !== 3)
                }
            }

            this.getTodayTaskOverview()
            if (this.tabIndex < 3) {
                this.getData()
            }
        }
    }

    // 题库-获取是否完成每日训练
    async getQuestionTask () {
        const { data: { taskAnswerPo } } = await this.__apis.activity.questionTaskInfo()
        this.taskAnswerPo = taskAnswerPo
        if (!taskAnswerPo) return
        this.questionComplete = taskAnswerPo.status === AnswerStatus.yes
    }

    // 题库-显示每日训练弹窗或每日答题详情
    showQuestion () {
        if (this.questionComplete && this.taskAnswerPo) {
            this.__store.commit(UserCommitTypes.setSeeQuestionInfo, {
                show: true,
                item: {
                    taskId: this.taskAnswerPo.id,
                    id: 0
                }
            })
        } else {
            this.__store.commit(UserCommitTypes.setShowQuestion, true)
        }
        this.showTask = false
    }

    getCustomerInfo (item: any) {
        const tabIndex = this.tabIndex
        return tabIndex === 1
            ? item.CustomerInfo && item.CustomerInfo.mobile_fixed
            : tabIndex === 2
                ? (item.remain_hours
                    ? item.remain_hours + 'h'
                    : '')
                : item.mobile_fixed
    }

    toggleTask (bool: boolean) {
        if (bool) {
            if (this.RobCustomer) {
                this.getCustomerDate(true)
                this.showTask = bool
                return
            } else {
                this.getTodayTaskOverview()
                if (this.tabIndex < 3) {
                    this.getData()
                } else {
                    // this.getQuestionTask()
                }
            }
        }
        this.showTask = bool
    }

    changeTab (type: number) {
        if (this.loading) {
            return
        }
        this.tabIndex = type
        if (type < 3) {
            this.page = 1
            this.dataObj.list = []
            this.getData()
        } else if (type === 3) {
            this.getQuestionTask()
        } else if (type === 4) {
            this.noMore = true
            this.page = 1
            this.rows1 = []
            this.rows2 = []
            this.getCustomerDate(true)
        }
    }

    async whetherClaim (i: number) {
        this.currentIndex = i
    }
    async scrollList (e: any) {
        if (this.tabIndex !== 4) {
            if (this.page > this.dataObj.total_page) return
        }

        let toScrollHeight = e.target.scrollHeight - e.target.clientHeight
        if (toScrollHeight - e.target.scrollTop < 20) {
            try {
                await this.__utils.index.jieliu(800)
                console.log('触底')
                if (this.tabIndex === 4) {
                    this.getCustomerDate()
                    return
                }
                this.page += 1
                this.getData()
            } catch (e) {
                console.log(e)
            }
        }
    }
    robClick (v: any) {
        this.toggleTask(false)
        this.currentCustomer = v
        this.dialogRobVisible = true
    }
    async comfireRob (v: any) {
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)'
        })
        const { data } = await this.__apis.customer.robCustomerTransfer({
            id: this.currentCustomer.id,
            transferred_user_id: this.currentUserCustomer.Id
        })
        loading.close()
        this.dialogRobVisible = false
        switch (data.code) {
        case 8005:
            this.$notify({
                title: '成功',
                message: data.message,
                type: 'success'
            })
            this.noMore = true
            this.rows1 = []
            this.page = 1
            this.getCustomerDate(true)
            break
        default:
            this.$notify({
                title: '失败',
                message: data.message || '转交失败，已经被其他人认领！',
                type: 'error'
            })
            this.noMore = true
            this.rows1 = []
            this.page = 1
            this.getCustomerDate(true)
        }
    }
    tagInputSelect (e: any) {
        this.currentUserCustomer = e
    }
    open (id: number) {
        window.open(`${routerBaseUrl}/customer/detail/${id}/`, '_blank')
    }

    async getTodayTaskOverview () {
        const { data } = await this.__apis.activity.getTodayTaskOverview()

        this.dataOverview = data
    }

    async getData () {
        try {
            if (this.tabIndex > 2) return
            if (this.loading) return
            this.loading = true

            const { data } = await this.__apis.activity.getTodayTaskList({
                type: +this.tabIndex + 1, // 1  2  3  待跟进 带看 即将进入
                page: this.page,
                pagesize: this.pageSize
            })
            this.loading = false

            if (this.page > 1) {
                data.list = this.dataObj.list.concat(data.list)
            }
            this.dataObj = data
        } catch (e) {
            this.loading = false
            throw e
        }
    }
    async claimClick (v: any) {
        try {
            await this.__utils.index.jieliu(3000)
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            })
            const { data } = await this.__apis.customer.robCustomerAction(v.id, v.type)
            loading.close()
            switch (data.code) {
            case 8000:
                this.$notify({
                    title: '成功',
                    message: data.message,
                    type: 'success'
                })
                this.noMore = true
                this.rows1 = []
                this.rows2 = []
                this.page = 1
                this.getCustomerDate(true)
                break
            default:
                this.$notify({
                    title: '失败',
                    message: data.message,
                    type: 'error'
                })
                this.noMore = true
                this.rows1 = []
                this.page = 1
                this.getCustomerDate(true)
            }
        } catch (e) {
            console.log(e)
        }
    }
    detailClick (v: any) {
        this.showTask = false
        const obj: any = { name: '/customer/detail', params: { id: v.id } }
        this.$router.push(obj)
    }
    async getCustomerDate (whether: boolean = false, cb?: any) {
        if (this.page !== 1 && !this.noMore) return
        if ((this.currentIndex === 0 || whether)) {
            if (this.page === 1) { this.rows1 = []; this.noMore = true }
            const { data } = await this.__apis.customer.getWaitRobCustomerList({
                page: whether ? 1 : this.page,
                pagesize: this.pageSize
            })
            if (data.rows.length === 0) {
                this.noMore = false
            } else {
                this.page = whether ? 2 : this.page + 1
                data.rows.forEach((a:any) => {
                    a.type = data.type
                })
                this.rows1 = [...this.rows1, ...data.rows]
                this.rows1Total = data.total
            }
        }
        if (!this.RobCustomer && (this.currentIndex === 1 || whether)) {
            if (this.page === 1) { this.rows2 = []; this.noMore = true }
            const { data } = await this.__apis.customer.getHadRobCustomerList({
                page: whether ? 1 : this.page,
                pagesize: this.pageSize
            })
            if (data.rows.length === 0) {
                this.noMore = false
            } else {
                this.rows2 = [...this.rows2, ...data.rows]
                this.page = whether ? 2 : this.page + 1
                this.rows2Total = data.total
            }
        }
        typeof cb === 'function' && cb()
    }
}
