import { Component, Vue } from 'vue-property-decorator'
import AllAndDep from '../allAndDep/index.vue'

@Component({
    components: {
        AllAndDep
    }
})
export default class CustomerAllPage extends Vue {
}
