import { BaseRes, java, ListReq, ListRes, php } from '@/api/index'

interface getTodayTaskOverviewRes {
    follow_count: number
    dk_count: number
    overtime_count: number
}

interface getTodayTaskListReq extends ListReq {
    type: number
}

interface QuestionAnswerInfoList {
    analyze: string;
    answer: string;
    answer_id: null;
    correct: string;
    do_right: null | boolean; // true 或 false ,null 为未回答
    item_order: null;
    items: {
        content: string; // 选项内容
        item_uuid: string;
        prefix: string; // A,B,C,D选项
    }[];
    question_id: number;
    question_type: number;
    subject_id: number;
    title: string;
}

interface TaskAnswerPo {
    create_time: number;
    create_user: number;
    do_time: null;
    frame_text_content_id: number;
    id: number;
    question_correct: number;
    question_count: number;
    status: number; // 状态:1待完成,2已完成
    task_name: string;
}

interface questionTaskInfoRes {
    questionAnswerInfoList: QuestionAnswerInfoList[];
    taskAnswerPo: TaskAnswerPo;
}

interface questionAnswerReq {
    'task_answer_id': number,
    'question_id': number,
    'content'?: string
    'contentArray'?: string[]
}

interface questionAnswerPageReq extends ListReq{
    'question_type'?: string,
    'title'?: string,
    'subject_id'?: number,
    'create_user'?: number,
    'do_right'?: boolean,
    'dateQrl'?: {
        'query_start_time': string,
        'query_end_time': string
    }
}

interface questionAnswerPageRes {
    id: number // 回答id
    task_id: number // 每日训练Id
    question_id: number
    text_content_id: number
    question_type: number;
    subject_id: number
    do_right: boolean;
    title: string;
    create_time: number
}

interface createQuestionReq{
    question_type?: number;
    subject_id?: number;
    title: string;
    items: {
        'prefix': string
        'content': string
    }[];
    analyze?: string;
    correct_array?: string[];
    correct?: string;
    dept_id?: number;
    areas?: {
        areaId: number
        areaName: string
    }[];
    house_id?: number;
    effective_time?: string
    invalid_time?: string
}

interface questionsCompanyStatisticRes{
    'dep_id': number,
    'dep_name': string,
    'last_update_time': string,
    'last_update_user_name': string,
    'question_count': number,
    'use_question_count': number
}
interface questionsDepStatisticRes{
    'dep_id': number,
    'dep_name': string,
    'last_update_time': string,
    'last_update_user_name': string,
    'question_count': number,
    'subject_id': number,
    'subject_name': string,
    'use_question_count': number
};

interface questionsSubjectReq extends ListReq {
    subject_id: number;
    dep_id: number;
    title?: string;
}

interface questionsSubjectRes {
    answer_count: number;
    areas: {
        areaId: number
        areaName: string
    }[];
    dep_id: number;
    dep_name: string;
    last_update_time: string;
    question_id: number;
    question_type: number;
    right_rate: number;
    subject_name: string;
    title: string;
    used: boolean;
    house_name: string
}

interface questionDetailRes {
    'answer_count':number,
    'answer_right_rate':number,
    'dep_name':string,
    'mtime':string,
    house_name: string
    'question_base_info':{
        'analyze':string,
        'areas':{
            'areaId':number,
            'areaName':string
        }[],
        'correct':string,
        'correct_array': string[],
        'deleted':boolean,
        'dept_id':number,
        'effective_time':number,
        'house_id': number,
        'id':number,
        'invalid_time':number,
        'item_order':string,
        'items':{
            'content':string,
            'item_uuid':string,
            'prefix':string
        }[],
        'question_type':number,
        'subject_id':number,
        'title':string,
        'used':boolean
    },
    'subject_name':string,
    'type_name':string
}

interface usedQuestionReq{
    used: boolean;
    id: number;
}

interface deleteQuestionReq{
    delete: boolean;
    id: number;
}

interface taskConstraintReq{
    dep: number;
    id?: number;
    time_limit: number;
}
export interface getTaskConstraintRes{
    'ctime':number,
    'dep':number,
    'id':number,
    'mtime':number,
    'time_limit':number
}
export default class ApiActivity {
    // 今日任务概览
    getTodayTaskOverview (): BaseRes<getTodayTaskOverviewRes> {
        return php.post('', {
            action_name: 'getTodayTaskOverview'
        })
    }

    // 今日任务
    getTodayTaskList (data: getTodayTaskListReq) {
        return php.post('', {
            action_name: 'getTodayTaskList',
            ...data
        })
    }

    // 题库-每日训练
    questionTaskInfo (): BaseRes<questionTaskInfoRes> {
        return java.post('/admin/task/question/taskInfo', {})
    }

    // 题库-答题
    questionAnswer (data: questionAnswerReq) {
        return java.post('/admin/task/question/answer', data)
    }

    // 题库-答题记录
    questionAnswerPage (data: questionAnswerPageReq): BaseRes<ListRes<questionAnswerPageRes>> {
        return java.post('/admin/task/question/answerPage', data)
    }

    // 题库-答题记录
    questionTaskAnswer (id: number): BaseRes<questionTaskInfoRes> {
        return java.post('/admin/task/question/taskAnswer', { value: id })
    }

    // 题库-添加题目
    createQuestion (data: createQuestionReq) {
        return java.post('/admin/task/question/createQuestion', data)
    }

    // 题库-更新题目
    updateQuestion (data: createQuestionReq & {id: number}) {
        return java.post('/admin/task/question/updateQuestion', data)
    }

    // 题库-统计-全公司
    questionsCompanyStatistic (): BaseRes<questionsCompanyStatisticRes[]> {
        return java.post('/admin/questionBank/questions/statistic/company', {})
    }

    // 题库-统计-部门
    questionsDepStatistic (dep: number): BaseRes<questionsDepStatisticRes[]> {
        return java.post('/admin/questionBank/questions/statistic/dep', { dep })
    }

    // 题库-分类详细
    questionsSubject (data: questionsSubjectReq): BaseRes<ListRes<questionsSubjectRes>> {
        return java.post('/admin/questionBank/questions/statistic/subject', data)
    }

    // 题库-题目详情
    questionDetail (id: number): BaseRes<questionDetailRes> {
        return java.post('/admin/questionBank/question/detail', { id })
    }

    // 题库-禁用题目
    usedQuestion (data: usedQuestionReq) {
        return java.post('/admin/task/question/usedQuestion', data)
    }

    // 题库-删除题目
    deleteQuestion (data: deleteQuestionReq) {
        return java.post('/admin/task/question/deleteQuestion', data)
    }

    // 题库-更新或添加题库设置
    taskConstraint (data: taskConstraintReq) {
        return java.post('/admin/task/constraint/update/taskConstraint', data)
    }

    // 题库-查询题库设置
    getTaskConstraint (dep?: number): BaseRes<getTaskConstraintRes[]> {
        return java.post('/admin/task/constraint/get/taskConstraint', { dep })
    }

    // 答题统计-本周完成次数
    questionThisWeekAnswer () {
        return java.post('/admin/task/info/count/complete/thisWeek', {})
    }
}
