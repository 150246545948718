import { apiConfig } from '@/api/config'
import { __localStorage, storageName } from '@/localStorage'
import { installPlugin } from './install'
import { __store } from '@/store'
import { __apis } from '@/api'
import { LabelValueArray } from '@/types'
import { Group, UserCommitTypes } from '@/store/user'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'

dayjs.extend(isoWeek)

declare module 'vue/types/vue' {
    interface Vue {
        __utils: {
            index: Utils
            dayjs: typeof dayjs
        }
    }
}

class Utils {
    filterObject (obj: Record<never, unknown>) {
        const keys = Reflect.ownKeys(obj) as Array<keyof typeof obj>

        for (const k of keys) {
            if ([null, undefined].includes(obj[k])) {
                delete obj[k]
            }
        }

        return obj
    }

    // 两个对象的key 相等 value也相等 舍弃
    // value不相等 取左侧值
    toggleObj <T extends object> (obj: T, target: object): T {
        let key !: keyof T
        const res: T = Object.create(null)

        for (key in obj) {
            const o = Reflect.get(obj, key)
            const n = Reflect.get(target, key)

            if (n === undefined || o !== n) {
                res[key] = o
            }
        }

        return res
    }

    getLabelByValue (arr: LabelValueArray<any>, value: unknown) {
        for (const item of arr) {
            if (item.value === value) return item.label
        }

        return ''
    }

    getItemByLabel<T> (arr: LabelValueArray<T>, label: string, includes = true) {
        for (const item of arr) {
            if (includes) {
                if (item.label.includes(label)) return item
            } else if (item.label === label) return item
        }
    }

    jieliu = (function () {
        let ctime = 0

        return function (ms: number) {
            return new Promise((resolve, reject) => {
                const now = Date.now()

                if (now - ctime >= ms) {
                    resolve()
                    ctime = now
                } else {
                    reject(new Error('jieliu error'))
                }
            })
        }
    })()

    // 获取销售部门
    getXsDep () {
        return __store.state.user.infoPos.dep_arr
            .map((v) => ({ label: v.v, value: v.k, is_close: v.is_close }))
            .filter((v) => !v.is_close)
    }

    // 根据销售部门的id获取通用部门
    getComDepByDepId (dep: number) {
        const config = __store.state.user.common_dep_arr.filter(a => a.sale_dep_id).map(a => {
            return {
                info: this.getComDepInfoById(a.value),
                values: [a.sale_dep_id]
            }
        })
        const info = config.filter(a => a.values.includes(dep))
        return info.length ? info[0].info : {
            value: 0,
            name: ''
        }
    }

    getGroupsByDepId (dep: number, { hide = false } = {}) {
        let res = __store.state.user.infoPos.group_arr[dep] ? __store.state.user.infoPos.group_arr[dep].groups : []

        if (hide) {
            res = res.filter(a => !a.has_close)
        }

        return res
    }

    getFxsUsersByDepGroupId (dep: number, group: number) {
        if (!dep || !group) return []
        return __store.state.user.infoPos.fxs_arr[dep][group].map(a => {
            return {
                value: a.k,
                label: a.v
            }
        })
    }

    // 废弃战队排在最后
    sortGroups (groups: { has_close: boolean }[]) {
        groups.sort((a, b) => Number(a.has_close) - Number(b.has_close))
    }

    // 根据group_id返回战队信息
    getGroupInfoById (id: number) {
        return Object.values(__store.state.user.infoPos.group_arr).map(a => a.groups).flat().find(a => a.value === id) || { dep: 0, name: '', value: 0, is_pxb: false, has_close: false }
    }

    // 根据commonDep_id返回通用部门信息
    getComDepInfoById (id: number) {
        return Object.values(__store.state.user.common_dep_arr).find(a => a.value === id) || { value: 0, name: '' }
    }

    // 根据dep_id返回部门信息
    getDepInfoById (id: number) {
        return Object.values(__store.state.user.infoPos.dep_arr).find(a => a.k === id) || { k: 0, v: '' }
    }

    // 根据user_id返回分析师信息
    // 注意：只能搜分析师 不能搜其他人
    getUserInfoById (id: number) {
        const dep = Object.values(__store.state.user.infoPos.fxs_arr)
        for (let i = 0; i < dep.length; i++) {
            const group = Object.values(dep[i])
            for (let j = 0; j < group.length; j++) {
                const user = group[j]
                for (let z = 0; z < user.length; z++) {
                    if (user[z].k === id) return user[z]
                }
            }
        }

        return {
            deactive: false,
            k: 0,
            v: ''
        }
    }

    // 多少天前
    getDaysAgo (day: number, format = 'YYYY-MM-DD') {
        const now = new Date()
        return [this.formatDate(new Date(now.getTime() - 86400000 * day), format), this.formatDate(now, format)]
    }

    private limitRepeatClickVar = false
    // 限制按钮点击
    limitRepeatClick () {
        if (this.limitRepeatClickVar) return true

        this.limitRepeatClickVar = true

        return false
    }
    // 恢复按钮点击
    restoreRepeatClick () {
        this.limitRepeatClickVar = false
    }

    limitMoneyByInput (value: string, max: number) {
        if (value[0] === '0' && value[1] !== '.') {
            return '0'
        }

        const v = value.match(/[0-9]+\.?[0-9]{0,2}/)
        if (v) {
            return Number(v) >= max ? v.toString().slice(0, max.toString().length - 1) : v.toString()
        } else {
            return ''
        }
    }

    // 计算目标类型值
    cmtDynamicNumber (cur: number, minCur: number, maxCur: number, minTar: number, maxTar: number) {
        if (cur <= minCur) {
            return minTar
        } else if (cur >= maxCur) {
            return maxTar
        } else {
            const a = cur - minCur
            const b = maxCur - minCur

            return minTar + (a / b) * (maxTar - minTar)
        }
    }

    // 金额 / 100 整除不用小数点 小数用.00
    formatMoney (money: number) {
        return (money / 100).toFixed(2)
    }

    // 将日期转化为带时间的
    // [2010-01-01, 2010-02-01]  --->  [2010-01-01 00:00:00, 2010-02-01 23:59:59]
    // [2010-01, 2010-01]  --->  [2010-01-01 00:00:00, 2010-01-31 23:59:59]
    formatTimeStartTimeEnd (date: string[]) {
        if (date[0].length > 7) {
            return [`${date[0]} 00:00:00`, `${date[1]} 23:59:59`]
        } else {
            let day = new Date(+date[1].slice(0, 4), +date[1].slice(5), 0).getDate()
            return [`${date[0]}-01 00:00:00`, `${date[1]}-${day} 23:59:59`]
        }
    }

    // 格式化表格日期 有10 8 6种格式
    formatChartDate (date: string) {
        if (date.length === 8) {
            return `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`
        } else if (date.length === 6) {
            return `${date.slice(0, 4)}-${date.slice(4, 6)}`
        } else {
            return `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)} ${date.slice(8)}:00:00`
        }
    }

    // 搜索部门
    searchDep (text: string, cb: (data: LabelValueArray) => void) {
        const res = __store.state.user.infoPos.dep_arr.filter(a => a.v.includes(text)).map(a => {
            return {
                value: a.k,
                label: a.v
            }
        })

        cb(res)
    }

    // 搜索部门
    searchComDep (text: string, cb: (data: LabelValueArray) => void) {
        const res = __store.state.user.common_dep_arr.filter(a => a.name.includes(text)).map(a => {
            return {
                value: a.value,
                label: a.name
            }
        })

        cb(res)
    }

    // 搜索战队
    searchGroup (text: string, cb: (data: LabelValueArray<string>) => void, options?: {dep: string}) {
        let res: Group[]
        const m_dep = __store.state.user.infoPos.dep ? __store.state.user.infoPos.dep.toString() : ''

        if (!options) {
            options = {
                dep: m_dep
            }
        } else {
            options.dep = options.dep ? options.dep : m_dep
        }

        // 如果筛选了部门 则根据筛选的部门显示战队 或者根据自己所在部门进行筛选
        if (options && options.dep) {
            res = this.getGroupsByDepId(+options.dep)
        } else {
            res = Object.values(__store.state.user.infoPos.group_arr).map(a => a.groups).flat()
        }

        res = res.filter(a => a.name.includes(text))
        __utils.index.sortGroups(res)

        cb(res.filter(a => a.name.includes(text)).map(a => {
            return {
                value: a.value.toString(),
                label: a.name
            }
        }))
    }

    // 搜索员工
    async searchEmployee (text: string, cb: (data: LabelValueArray<string>) => void) {
        // 判断员工列表有无数据 如果没有则先获取数据
        const { data } = await __apis.utils.searchEmployee(text)

        cb(data.list.map(a => {
            return {
                value: a.Id.toString(),
                label: a.Username
            }
        }))
    }

    // 搜索楼盘
    async searchHouse (text: string, cb: any) {
        let { data } = await __apis.utils.searchHouse({
            term: text
        }) as any

        cb(data.map((a:any) => {
            return {
                value: a.id.toString(),
                label: a.label
            }
        }))
    }

    // 注册app滚动事件
    registerAppScroll (fn: () => void) {
        const app = document.querySelector('#app') as HTMLDivElement
        app.onscroll = fn
    }

    destroyAppScroll () {
        const app = document.querySelector('#app') as HTMLDivElement
        app.onscroll = null
    }

    // 等待
    sleep (ms: number): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(resolve, ms)
        })
    }

    // 格式化时间
    formatDate (date: Date, format: string): string {
        const time = Utils.dateToTime(date)
        const f = format.match(/[a-zA-Z]+/g)
        const kv = {
            Y: time[0],
            M: time[1],
            D: time[2],
            H: time[3],
            h: time[3],
            m: time[4],
            s: time[5]
            // 如果匹配失败 则返回 空字符串
        } as any

        if (f === null) {
            return ''
        }

        let result = format

        f.forEach(item => {
            let rv = ''

            // 找与之对应的 值
            for (const k of Object.keys(kv)) {
                if (item.indexOf(k) !== -1) {
                    rv = (item.length === 2 && kv[k] < 10 ? '0' : '') + kv[k]

                    // h 需要特殊处理一下
                    if (item === 'hh' || item === 'h') {
                        rv = (item.length === 2 && kv[k] % 12 < 10 ? '0' : '') + kv[k] % 12
                    }

                    break
                }
            }

            result = result.replace(item, rv)
        })

        return result
    }

    offsetDate (date: Date, offset = 0) {
        const localTime = date.getTime()
        const localOffset = date.getTimezoneOffset() * 60000 // getTimezoneOffset()返回是以分钟为单位，需要转化成ms
        const utc = localTime + localOffset
        return new Date(utc + (3600000 * offset))
    }

    // 返回[YY, MM, DD, HH, mm, ss]
    // eq [1997, 1, 23, 4, 56, 1]
    static dateToTime (date: Date): number[] {
        return [date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()]
    }

    copyObj <T> (a: T): T {
        return JSON.parse(JSON.stringify(a))
    }

    downloadFile (data: {[index: string]: any}, url = apiConfig.apiUrl) {
        const datas: string[] = []

        Object.keys(data).forEach(a => {
            if (['', undefined, null, NaN].includes(data[a])) return

            datas.push(`${a}=${data[a]}`)
        })

        const a = document.createElement('a')
        a.download = ''
        a.href = url + '?' + datas.join('&') + `${datas.length ? '&' : ''}ac_token=${__localStorage.getLocalStorage(storageName.TOKEN)}`
        a.target = '_blank'
        a.click()
    }

    // 判断手机号是否符合格式
    checkPhone (a: string) {
        const reg = /^1\d{10}$/
        const res = reg.test(a)
        reg.lastIndex = 0
        return res
    }

    clearTimeout (timeoutTimer: any) {
        for (let timeout in timeoutTimer) {
            clearTimeout(timeoutTimer[timeout])
        }
    }

    // 开启滚动穿透限制
    openScrollFixed () {
        document.body.style.overflow = 'hidden'
    }

    // 取消滚动穿透限制
    cancelScrollFixed () {
        document.body.style.overflow = 'inherit'
    }

    isObjAttrValid (o: any) {
        let arrText: {
            [index: string]: string
        } = {
            name: '姓名',
            mobile: '电话',
            house_name: '楼盘',
            customer_house: '楼盘',
            demand_region: '区域'
        }
        if (typeof o === 'object') {
            for (let key in o) {
                if (key !== 'remark') {
                    if (!o[key] || o[key] === '' || o[key].length === 0) {
                        key = arrText[key] || key
                        return key
                    }
                }
            }
        }
        return false
    }

    numFormat (num: number) {
        return (num.toString().indexOf('.') !== -1) ? num.toLocaleString() : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    }

    async getAdminRoleList (): Promise<Array<LabelValueArray[0] & { status: number }>> {
        let roleList = __store.state.user.roleList
        if (!roleList.length) {
            const { data } = await __apis.system.getAdminRoleList()
            roleList = data.map(a => ({ label: a.name, value: a.id, status: a.status }))
            __store.commit(UserCommitTypes.setRoleList, roleList)
        }
        return roleList
    }

    trigger (el: HTMLElement, type: string) {
        const event = document.createEvent('HTMLEvents')
        event.initEvent(type, true, true)
        el.dispatchEvent(event)
    }

    getProjectsRoles () {
        return __store.state.user.projectList
    }

    // table数据合并
    merageTableArr (arr: {name: any}[]) {
        let data: any[] = []
        let pos = 0
        arr.map((v, i) => {
            if (i === 0) {
                data.push(1)
                pos = 0
            } else {
                if (arr[i].name === arr[i - 1].name) {
                    data[pos] += 1
                    data.push(0)
                } else {
                    data.push(1)
                    pos = i
                }
            }
        })
        return data
    }

    // table返回合并行，列
    returnTableSpan (arr: any[], rowIndex: number) {
        const _row = arr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
            rowspan: _row,
            colspan: _col
        }
    }

    setXjStaticPreUrl (url: string) {
        if (!url) return ''
        const preUrl = 'https://ssl.malmam.com/xiangju-static/dfs/'
        return `${preUrl}${url}`
    }

    fileDownload (res: any) {
        const blob = new Blob([res.data], {
            type: res.data.type
        })
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载链接
        const fileName = res.headers['content-disposition']
            ? res.headers['content-disposition'].split('attachment;filename=')[1]
            : new Date().getTime()
        downloadElement.href = href
        downloadElement.download = decodeURIComponent(fileName) // 解码
        document.body.appendChild(downloadElement)
        downloadElement.click()
        document.body.removeChild(downloadElement)
        window.URL.revokeObjectURL(href) // 释放掉blob对象
    }
}

export const FriendStatus = [{
    label: '合作中',
    value: 1
}, {
    label: '暂停合作',
    value: 2
}, {
    label: '结束合作',
    value: 3
}, {
    label: '未合作',
    value: 4
}]

export const ExitStatus = [{
    label: '否',
    value: 0
}, {
    label: '是',
    value: 1
}]

export const GetMoneyStatus = [{
    label: '未结佣',
    value: 0
}, {
    label: '部分结佣',
    value: 1
}, {
    label: '完全结佣',
    value: 2
}]

export const BaobeiStatus = [{
    label: '等待处理',
    value: 0
}, {
    label: '成功',
    value: 1
}, {
    label: '失败',
    value: 2
}, {
    label: '争议',
    value: 3
}]

export const __utils = {
    index: new Utils(),
    dayjs: dayjs
}

export default {
    install: installPlugin('__utils', __utils)
}

export const groupColors: {
    [index: number]: string
} = {
    0: '#D3F261', // 未知队 - 职能部门成员
    1: '#D8F6EA', // 苏州组
    2: '#7585A2', // 龙虎队
    3: '#A285D2', // 疯狂队
    4: '#FFAB67', // 超越队
    5: '#408AF0', // 王者队
    6: '#73DEB3', // 魁拔队
    7: '#FF9C6E', // 云南队
    8: '#46A9A8', // 大圣队
    9: '#FFA8CC', // 将军队
    10: '#F7C739', // 凤凰队
    11: '#EB7E65', // 突破队
    12: '#83D0EF', // 冲锋队
    13: '#73A0FA', // 火箭队
    14: '#C7D9FD', // 飞跃队
    15: '#DBDEE6', // 荣耀队
    16: '#FF7875', // 战狼队
    17: '#FFEC3D',
    18: '#F759AB',
    19: '#722ED1',
    20: '#AD4E00',
    21: '#006D75',
    22: '#061178',
    23: '#FFD6E7',
    24: '#D3ADF7'
}
