const dateRangeUtil = {
    dayTemp: 24 * 60 * 60 * 1000,

    /***
     * 获得当前时间
     */
    getCurrentDate () {
        return new Date()
    },

    /***
     * 获得前一天
     */
    getCurrentYesterday () {
        return new Date(new Date().getTime() - this.dayTemp)
    },

    /***
     * 获得后一天
     */
    getCurrentAfterday () {
        return new Date(new Date().getTime() + this.dayTemp)
    },

    /***
     * 获得本周起止时间
     */
    getCurrentWeek () {
        // 起止日期数组
        let startStop = []
        // 获取当前时间
        let currentDate = this.getCurrentDate()
        // 返回date是一周中的某一天
        let week = currentDate.getDay()

        // 一天的毫秒数
        let millisecond = 1000 * 60 * 60 * 24
        // 减去的天数
        let minusDay = week !== 0 ? week - 1 : 6
        // alert(minusDay);
        // 本周 周一
        let monday = new Date(currentDate.getTime() - minusDay * millisecond)
        // 本周 周日
        let sunday = new Date(monday.getTime() + 6 * millisecond)
        // 添加本周时间
        startStop.push(monday) // 本周起始时间
        // 添加本周最后一天时间
        startStop.push(sunday) // 本周终止时间
        // 返回
        return startStop
    },

    /***
     * 获得下一周起止时间
     */
    getCurrenAftertWeek () {
        // 起止日期数组
        let startStop = []
        // 获取当前时间
        let currentDate = this.getCurrentDate()
        // 返回date是一周中的某一天
        let week = currentDate.getDay()

        // 一天的毫秒数
        let millisecond = 1000 * 60 * 60 * 24
        // 减去的天数
        let minusDay = week !== 0 ? week - 1 : 6
        // alert(minusDay);
        // 本周 周一
        let monday = new Date(currentDate.getTime() - minusDay * millisecond)
        // 本周 周日
        let sunday = new Date(monday.getTime() + 6 * millisecond)

        // 下周一
        let aferWeekOne = new Date(sunday.getTime() + millisecond)
        // 下周日
        let aferWeekLast = new Date(aferWeekOne.getTime() + (millisecond * 6))

        // 添加下周时间
        startStop.push(aferWeekOne) // 下周起始时间
        // 添加下周最后一天时间
        startStop.push(aferWeekLast) // 下周终止时间

        // 返回
        return startStop
    },

    /**
     * 获得上一周的起止日期
     * **/
    getPreviousWeek () {
        // 起止日期数组
        let startStop = []
        // 获取当前时间
        let currentDate = this.getCurrentDate()
        // 返回date是一周中的某一天
        let week = currentDate.getDay()
        // 一天的毫秒数
        let millisecond = 1000 * 60 * 60 * 24
        // 减去的天数
        let minusDay = week !== 0 ? week - 1 : 6
        // 获得当前周的第一天
        let currentWeekDayOne = new Date(
            currentDate.getTime() - millisecond * minusDay
        )
        // 上周最后一天即本周开始的前一天
        let priorWeekLastDay = new Date(currentWeekDayOne.getTime() - millisecond)
        // 上周的第一天
        let priorWeekFirstDay = new Date(
            priorWeekLastDay.getTime() - millisecond * 6
        )

        // 添加至数组
        startStop.push(priorWeekFirstDay)
        startStop.push(priorWeekLastDay)

        return startStop
    },

    /***
     * 获得本月的起止时间
     */
    getCurrentMonth (date?: Date) {
        // 起止日期数组
        let startStop = []
        // 获取当前时间
        let currentDate = date || this.getCurrentDate()
        // 获得当前月份0-11
        let currentMonth = currentDate.getMonth()
        // 获得当前年份4位年
        let currentYear = currentDate.getFullYear()
        // 求出本月第一天
        let firstDay = new Date(currentYear, currentMonth, 1)

        // 当为12月的时候年份需要加1
        // 月份需要更新为0 也就是下一年的第一个月
        if (currentMonth === 11) {
            currentYear++
            currentMonth = 0 // 就为
        } else {
            // 否则只是月份增加,以便求的下一月的第一天
            currentMonth++
        }

        // 一天的毫秒数
        let millisecond = 1000 * 60 * 60 * 24
        // 下月的第一天
        let nextMonthDayOne = new Date(currentYear, currentMonth, 1)
        // 求出上月的最后一天
        let lastDay = new Date(nextMonthDayOne.getTime() - millisecond)

        // 添加至数组中返回
        startStop.push(firstDay)
        startStop.push(lastDay)
        // 返回
        return startStop
    },

    /***
     * 获得下月的起止时间
     */
    getNextMonth (date?: Date) {
        // 起止日期数组
        let startStop = []
        // 获取当前时间
        let currentDate = date || this.getCurrentDate()
        // 获得当前月份0-11
        let currentMonth = currentDate.getMonth()
        // 获得当前年份4位年
        let currentYear = currentDate.getFullYear()
        // 求出本月第一天
        let firstDay = new Date(currentYear, currentMonth, 1)

        // 当为12月的时候年份需要加1
        // 月份需要更新为0 也就是下一年的第一个月
        if (currentMonth === 11) {
            currentYear++
            currentMonth = 0 // 就为
        } else {
            // 否则只是月份增加,以便求的下一月的第一天
            currentMonth++
        }

        // 下月的第一天
        let start = new Date(currentYear, currentMonth, 1)
        // 求出下月的最后一天
        let end = new Date(currentYear, currentMonth, this.getMonthDays(currentYear, currentMonth))

        // 添加至数组中返回
        startStop.push(start)
        startStop.push(end)
        // 返回
        return startStop
    },

    getMonthDays (year: number, month: number) {
        // 本月第一天 1-31
        let relativeDate = new Date(year, month, 1)
        // 获得当前月份0-11
        let relativeMonth = relativeDate.getMonth()
        // 获得当前年份4位年
        let relativeYear = relativeDate.getFullYear()

        // 当为12月的时候年份需要加1
        // 月份需要更新为0 也就是下一年的第一个月
        if (relativeMonth === 11) {
            relativeYear++
            relativeMonth = 0
        } else {
            // 否则只是月份增加,以便求的下一月的第一天
            relativeMonth++
        }
        // 一天的毫秒数
        let millisecond = 1000 * 60 * 60 * 24
        // 下月的第一天
        let nextMonthDayOne = new Date(relativeYear, relativeMonth, 1)
        // 返回得到上月的最后一天,也就是本月总天数
        return new Date(nextMonthDayOne.getTime() - millisecond).getDate()
    },

    getPriorMonthFirstDay (year: number, month: number) {
        // 年份为0代表,是本年的第一月,所以不能减
        if (month === 0) {
            month = 11 // 月份为上年的最后月份
            year-- // 年份减1
            return new Date(year, month, 1)
        }
        // 否则,只减去月份
        month--
        return new Date(year, month, 1)
    },

    /**
     * 获得上一月的起止日期
     * ***/
    getPreviousMonth () {
        // 起止日期数组
        let startStop = []
        // 获取当前时间
        let currentDate = this.getCurrentDate()
        // 获得当前月份0-11
        let currentMonth = currentDate.getMonth()
        // 获得当前年份4位年
        let currentYear = currentDate.getFullYear()
        // 获得上一个月的第一天
        let priorMonthFirstDay = this.getPriorMonthFirstDay(
            currentYear,
            currentMonth
        )
        // 获得上一月的最后一天
        let priorMonthLastDay = new Date(
            priorMonthFirstDay.getFullYear(),
            priorMonthFirstDay.getMonth(),
            this.getMonthDays(
                priorMonthFirstDay.getFullYear(),
                priorMonthFirstDay.getMonth()
            )
        )
        // 添加至数组
        startStop.push(priorMonthFirstDay)
        startStop.push(priorMonthLastDay)
        // 返回
        return startStop
    },

    /**
     * 获得前三个月的起止月份
     * ***/
    getCurrentThreeBeforeMonth (t?: number) {
        // 起止日期数组
        let startStop = []
        // 获取当前时间
        let currentDate = t ? new Date(t) : this.getCurrentDate()
        let currentMonth = currentDate.getMonth() + 1
        let currentYear = currentDate.getFullYear()
        currentMonth -= 3 % 12
        if (currentMonth <= 0) {
            currentYear -= 1
            currentMonth += 12
        }
        let startMonth = new Date(
            currentYear,
            new Date(currentDate.setMonth(currentMonth - 1)).getMonth(),
            currentDate.getDate()
        )
        let endMonth = new Date(
            currentDate.getFullYear(),
            new Date(currentDate.setMonth(this.getCurrentDate().getMonth())).getMonth(),
            currentDate.getDate()
        )
        // 添加至数组
        startStop.push(format(startMonth, 'yyyy-MM-dd'))
        startStop.push(format(endMonth, 'yyyy-MM-dd'))
        // 返回
        return startStop
    },

    /**
     * 获得前六个月的起止月份
     * ***/
    getCurrentSixBeforeMonth (t?: number) {
        // 起止日期数组
        let startStop = []
        // 获取当前时间
        let currentDate = t ? new Date(t) : this.getCurrentDate()
        let currentMonth = currentDate.getMonth() + 1
        let currentYear = currentDate.getFullYear()
        currentMonth -= 6 % 12
        if (currentMonth <= 0) {
            currentYear -= 1
            currentMonth += 12
        }
        let startMonth = new Date(
            currentYear,
            new Date(currentDate.setMonth(currentMonth - 1)).getMonth(),
            currentDate.getDate()
        )
        let endMonth = new Date(
            currentDate.getFullYear(),
            new Date(currentDate.setMonth(this.getCurrentDate().getMonth())).getMonth(),
            currentDate.getDate()
        )
        // 添加至数组
        startStop.push(format(startMonth, 'yyyy-MM-dd'))
        startStop.push(format(endMonth, 'yyyy-MM-dd'))
        // 返回
        return startStop
    },

    getCurrentYearBeforeMonth (t?: number) {
        // 起止日期数组
        let startStop = []
        // 获取当前时间
        let currentDate = t ? new Date(t) : this.getCurrentDate()
        let currentMonth = currentDate.getMonth() + 1
        let currentYear = currentDate.getFullYear()
        let startMonth = new Date(
            currentYear - 1,
            new Date(currentDate.setMonth(currentMonth - 1)).getMonth(),
            currentDate.getDate()
        )
        let endMonth = new Date(
            currentDate.getFullYear(),
            new Date(currentDate.setMonth(this.getCurrentDate().getMonth())).getMonth(),
            currentDate.getDate()
        )
        // 添加至数组
        startStop.push(format(startMonth, 'yyyy-MM-dd'))
        startStop.push(format(endMonth, 'yyyy-MM-dd'))
        // 返回
        return startStop
    },

    /**
     * 获得本年的起止月份
     * ***/
    getCurrentYearStartEndMonth () {
        // 起止日期数组
        let startStop = []
        // 获取当前时间
        let currentDate = this.getCurrentDate()
        let startMonth = new Date(currentDate.setMonth(0))
        let endMonth = new Date(currentDate.setMonth(this.getCurrentDate().getMonth()))
        // 添加至数组
        startStop.push(format(startMonth, 'yyyy-MM'))
        startStop.push(format(endMonth, 'yyyy-MM'))
        // 返回
        return startStop
    },

    format

}

export function format (date = new Date(), fmt: string) {
    date = new Date(date) || new Date()
    let o: any = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
    }
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
            )
        }
    }
    return fmt
}

export default dateRangeUtil
