import { Vue, Component } from 'vue-property-decorator'
import { Table } from '@/types'
import FilterComponent from '@/components/filters/filter/index.vue'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import { routerBaseUrl } from '@/router'
import { __utils } from '@/util'

interface TableItem {
    id: number,
    department: string,
    group: string,
    fxs: string,
    number_periods: string,
    time: string,
}

interface Filter {
    fxs: number
}

@Component({
    components: {
        SelectComponent,
        FilterComponent,
        FilterBusinessSelect
    }
})
export default class IndexPage extends Vue {
    filter: Filter = {} as Filter

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0
    }

    created () {
        this.getTableData()
    }

    async getTableData () {
        const { data } = await this.__apis.grow.getPxbList({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            employee_id: this.filter.fxs
        })

        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.employee_id,
                department: __utils.index.getDepInfoById(a.department).v,
                group: __utils.index.getGroupInfoById(a.group).name,
                fxs: a.name,
                number_periods: a.number_periods.toString(),
                time: a.time ? __utils.index.formatDate(new Date(a.time), 'YYYY-MM-DD') : ''
            }
        })
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        this.getTableData()
    }

    gotoDetail (id: number, name: string) {
        open(`${routerBaseUrl}/grow-manage/xxb-detail/${id}?name=${name}`)
    }

    searchFxs (text: string, cb: any) {
        this.__utils.index.searchEmployee(text, cb)
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }
}
