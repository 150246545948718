import { Component, Vue, Watch } from 'vue-property-decorator'
import navTab from '@/components/NavTab/index.vue'
import router from '@/router'
import { __role } from '@/role'
import qmPermission from 'qm-plugin-admin-permission'

@Component({
    components: {
        navTab
    }
})
export default class ChannelManageIndexPage extends Vue {
    @Watch('$route')
    watchRoute (to: any) {
        if (to.fullPath === '/channelManage') {
            router.replace(this.navTabList[0] ? this.navTabList[0].route : '/channelManage/cuiyong')
        }
    }
    navTabList = [
        { name: '催佣管理', route: '/channelManage/cuiyong', authKey: __role.permissions.UrgeManagePage },
        { name: '合作商管理', route: '/channelManage/hezuoshang', authKey: __role.permissions.PartnerManagePage },
        // { name: '退客审核', route: '/channelManage/invalidCustomer', authKey: __role.permissions.RetirementReviewPage },
        { name: '成交审核', route: '/channelManage/recordAudit', authKey: __role.permissions.TransactionReviewPage },
        { name: '网签审核', route: '/channelManage/signReview', authKey: __role.permissions.SignReviewPage },
        { name: '楼盘管理', route: '/channelManage/house-manage', authKey: __role.permissions.HouseManagePage },
        { name: '绩效考核', route: '/channelManage/performanceAppraisal', authKey: __role.permissions.ShowChannelAssessmentPage },
        { name: '申诉单审核', route: '/channelManage/controversialExamine', authKey: __role.permissions.ShowControversialExaminePage }
    ]

    created () {
        qmPermission.filter(this.navTabList)

        // 如果是直接跳到渠道管理根页面 那么就跳转到催佣管理页面
        if (this.$route.fullPath === '/channelManage') {
            router.replace(this.navTabList[0] ? this.navTabList[0].route : '/channelManage/cuiyong')
        }
    }
}
