import { Component, Vue, Watch } from 'vue-property-decorator'
import SubNav from '@/components/SubNav/index.vue'
import router from '@/router'
import TaskComponent from '@/components/customer/task/index.vue'
import qmPermission from 'qm-plugin-admin-permission'

@Component({
    components: {
        'sub-nav': SubNav,
        TaskComponent
    }
})
export default class CustomerIndexPage extends Vue {
    @Watch('$route')
    watchRoute (to: any) {
        if (to.fullPath === '/customer') {
            router.replace(this.dataObj.menus[0] ? this.dataObj.menus[0].route : '')
        }
    }

    dataObj = {
        menus: [
            {
                name: '新客户',
                value: 'new',
                route: {
                    name: '/customer/newcustomer'
                },
                authKey: this.__role.permissions.ShowNewCustomerPage
            },
            {
                name: '转交记录',
                value: 'zhuanjiaoRecord',
                route: {
                    name: '/customer/zjRecord'
                },
                authKey: this.__role.permissions.ForwardHistoryPage
            },
            {
                name: '共享客户',
                value: 'share',
                route: {
                    name: '/customer/share'
                },
                authKey: this.__role.permissions.ShowShareCustomerRecordPage
            },
            {
                name: '公池客户',
                value: 'pubpool',
                route: {
                    name: '/customer/gccustomer'
                },
                authKey: this.__role.permissions.PublicCustomerPage
            },
            {
                name: '重点客户',
                value: 'impt_group',
                route: {
                    name: '/customer/zdcustomer'
                },
                authKey: this.__role.permissions.ImportantCustomerPage
            },
            {
                name: '全部客户',
                value: 'list',
                route: {
                    name: '/customer/allcustomer',
                    query: {
                        type: 'list'
                    }
                },
                authKey: this.__role.permissions.AllCustomerPage
            },
            {
                name: '部门客户',
                value: 'depList',
                route: {
                    name: '/customer/departcustomer',
                    query: {
                        type: 'depList'
                    }
                },
                authKey: this.__role.permissions.DepartmentCustomerPage
            },
            {
                name: '战队客户',
                value: 'depList',
                route: {
                    name: '/customer/teamcustomer',
                    query: {
                        type: 'depList'
                    }
                },
                authKey: this.__role.permissions.TeamCustomerPage
            },
            {
                name: '我的客户',
                value: 'my',
                route: {
                    name: '/customer/mycustomer'
                },
                authKey: this.__role.permissions.MyCustomerPage
            },
            {
                name: '通讯录',
                value: 'contacts',
                route: {
                    name: '/customer/txlcustomer'
                },
                authKey: this.__role.permissions.AddressBookPage
            },
            {
                name: '客户楼盘统计',
                value: 'statistic',
                route: {
                    name: '/customer/fenxicustomer'
                },
                authKey: this.__role.permissions.CustomerAnalysisPage
            },
            {
                name: '无效客户',
                value: 'invalid_customer',
                route: {
                    name: '/customer/invalidcustomer'
                },
                authKey: this.__role.permissions.CustomerInvalidApplyRecordsPage
            },
            {
                name: '成交客户',
                value: 'cj_customer',
                route: {
                    name: '/customer/cjcustomer'
                },
                authKey: this.__role.permissions.ClinchCustomerPage
            },
            {
                name: '电话客户',
                value: 'phone_wait_dispatch',
                route: {
                    name: '/customer/phonecustomer'
                },
                authKey: this.__role.permissions.PhoneCustomerPage
            },
            {
                name: '待分配',
                value: 'wait_buy',
                route: {
                    name: '/customer/buy'
                },
                authKey: this.__role.permissions.WaitBuyPage
            }
        ]
    }

    created () {
        qmPermission.filter(this.dataObj.menus)

        this.watchRoute({
            fullPath: this.$route.fullPath
        })
    }
}
