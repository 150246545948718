import { Component, Vue } from 'vue-property-decorator'
import loadDispatchComponent from './400/index.vue'
import successDispatchRecordComponent from './record/index.vue'

@Component({
    components: {
        loadDispatchComponent,
        successDispatchRecordComponent
    }
})
export default class IndexPage extends Vue {
    pane = 'first'
}
