import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiConfig } from '@/api/config'
import mixin from '../../mixin'
import { storageName } from '@/localStorage'
import guideDialog from '@/components/customer/guideDialog/index.vue'
import chengjiaoDialog from '@/views/saleman/chengjiao/chengjiaoDialog/index.vue'
import saleDialog from '@/views/saleman/record/saleDialog/index.vue'
import StarBoxComponent from '@/components/star-box/index.vue'
import { LabelValueArray } from '@/types'
import { customerDetailRes } from '@/api/customer'
import RobotCallDetailComponent from '@/views/phoneRecord/robot/call-detail/index.vue'
import { CallDetailItem } from '@/views/phoneRecord/robot/call-detail'
import shareManageComponent from '@/views/customer/share/manage/index.vue'
import shareZjComponent from './shareZj/index.vue'
import logMoneyComponent from '@/components/customer/logMoney/index.vue'
import { ShareManage } from '../../share'
import callAudioComponent from './callAudio/index.vue'
import applyInvalidCustomerComponent from './applyInvalidCustomer/index.vue'
import createRelationCustomerComponent from './createRelationCustomer/index.vue'

function searchTreeByKey<T extends { children?: T[] }, K extends keyof T> (tree: T[], key: K, value: T[K]) {
    for (let i = 0; i < tree.length; i++) {
        if (tree[i][key] === value) {
            return tree[i]
        } else if (tree[i].children) {
            const v = searchTreeByKey(tree[i].children as T[], key, value) as T

            if (v) return v
        }
    }

    return undefined
}

type RequireInfo = {
    type: string
    typeList: string[]
    price: string
    area: string
    areaList: string[]
    region: string[]
    regionList: {
        label: string
        code: string
        children: RequireInfo['regionList']
    }[]
}

export interface ShareItem {
    id: number,
    customerId: number,
    shareId: number,
    shareName: string,
    ctime: string
}

@Component({
    components: {
        StarBoxComponent,
        guideDialog,
        chengjiaoDialog,
        saleDialog,
        RobotCallDetailComponent,
        shareManageComponent,
        shareZjComponent,
        logMoneyComponent,
        callAudioComponent,
        applyInvalidCustomerComponent,
        createRelationCustomerComponent
    },
    mixins: [mixin]
})
export default class CustomerInfoPage extends Vue {
    formLabelWidth = '122px'
    // 成交总结 || 看房总结
    dialogFormVisible = false
    titleDialogText = ''
    isGuideSuccess = ''
    form: any = {
        customer_name: '',
        customer_tel: '',
        action_time: '',
        region: '',
        house_name: '',
        house_id: '',
        channel_name: '',
        channel_username: '',
        channel_tel: '',
        zygw_name: '',
        zygw_tel: '',
        imgs: [],
        customer_info: '',
        zongjie: '',
        ext_data: []
    }
    // 图片上传
    dialogImageUrl = apiConfig.apiUrl
    fileList: any[] = []
    // 图片预览
    dialogVisible = false
    dialogImgUrl = ''
    // 转交弹窗
    dialogFormZjVisible = false
    zjForm = {
        radio_depart: 0,
        radio_group: 0,
        radio_fxs: 0
    }
    // 带看弹窗
    dialogFormGuideVisible = false
    guideForm = {
        action_name: 'saveDkPre',
        house_name: '',
        house_id: '',
        action_time: '',
        pre_work: '',
        customer_info: ''
    }
    // 成交弹窗
    dialogFormChengJiaoDataVisible = false
    tuifang = [
        { type: 0, text: '否' },
        { type: 1, text: '是' }
    ]
    formChengJiao: any = {
        action_name: 'saveSale',
        sale_type: 2,
        customer_name: '',
        customer_tel: '',
        house_name: '',
        house_id: '',
        house_money: '',
        house_no: '',
        sum_money: '',
        sum_must_dz_money: '',
        sale_time: '',
        remark: '',
        user_info: [],
        channel_name: '',
        channel_username: '',
        channel_tel: '',
        zygw_name: '',
        zygw_tel: '',
        zm: [
            { index: 0, type: 'qd', text: '签到单', url: '' },
            { index: 3, type: 'sk', text: '刷卡单', url: '' },
            { index: 1, type: 'rg', text: '认购书', url: '' },
            { index: 2, type: 'sf', text: '客户身份证', url: '' }
        ],
        qudao: ''
    }
    rulesGuide = {
        action_time: [
            { type: 'string', required: true, message: '请选择日期', trigger: 'change' }
        ],
        house_name: [
            { type: 'string', required: true, message: '请输入楼盘', trigger: 'change' }
        ],
        customer_info: [
            { type: 'string', required: true, message: '请输入客户情况', trigger: 'change' }
        ],
        pre_work: [
            { type: 'string', required: true, message: '请输入前置说明', trigger: 'change' }
        ]
    }

    rules = {
        sale_time: [
            { type: 'string', required: true, message: '请选择日期', trigger: 'change' }
        ],
        house_name: [
            { type: 'string', required: true, message: '请输入楼盘', trigger: 'change' }
        ],
        house_money: [
            { type: 'string', required: true, message: '请输入金额', trigger: 'change' }
        ],
        house_no: [
            { type: 'string', required: true, message: '请输入房号', trigger: 'change' }
        ],
        sum_money: [
            { type: 'string', required: true, message: '请输入金额', trigger: 'change' }
        ],
        // channel_name:[
        // 	{ type: 'string', required: true, message: '请输入渠道', trigger: 'change' }
        // ],
        sum_must_dz_money: [
            { type: 'string', required: true, message: '请输入应到账金额', trigger: 'change' }
        ],
        zygw_name: [
            { type: 'string', required: true, message: '请输入置业顾问', trigger: 'change' }
        ],
        qudao: [
            { type: 'string', required: true, message: '请输入渠道', trigger: 'change' }
        ],
        zm: [
            { type: 'array', required: true, message: '请上传文字中对应的图片', trigger: 'change' }
        ]
    }
    customer_id = ''
    genjin_textarea = ''
    gjTime = ''

    dataObj: any = {}
    sysTags2: {
        [index: string]: any
    } = {}
    tagCurrent = ''
    imptCurrent = 0
    house: any = {}
    addPhoneHouse: any = {
        phoneList: [],
        houseList: [],
        searchPhoneList: [],
        searchHouseList: []
    }
    basicInfo:any = {}
    dkDialogVisible = false
    cjDialogVisible = false
    saveLoading = false
    pickerOptions:any = {
        disabledDate (time:any) {
            let _now = Date.now()
            let dayTemp = 24 * 60 * 60 * 1000
            return time.getTime() < _now - dayTemp
        }
    }

    robotCallDetailComponent: {
        show: boolean
        item: CallDetailItem
    } = {
        show: false,
        item: {} as CallDetailItem
    }

    logMoneyComponent = {
        show: false,
        money: 2
    }

    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }
    get
    infoPos () {
        let infoPos = this.__store.state.user.infoPos
        this.zjForm.radio_depart = infoPos.dep
        this.zjForm.radio_group = infoPos.group
        this.zjForm.radio_fxs = infoPos.fxs
        return infoPos
    }
    get
    getActiveFxs () {
        if (!this.infoPos.fxs_arr) return []
        let fxs = this.infoPos.fxs_arr[this.zjForm.radio_depart][this.zjForm.radio_group] || []
        let res = fxs.filter((v: any) => !v.deactive)
        return res || []
    }
    get
    impt () {
        return this.__store.state.user.impt
    }
    get
    customerTags () {
        return this.__store.state.user.customerTags
    }
    get
    customerTagsArr () {
        const data = this.__utils.index.copyObj(this.__store.state.user.customerTags)
        Object.keys(data).map(v => {
            data[v].text = data[v].text.includes(':') ? data[v].text.split(':')[0] : data[v].text
        })

        if (this.dataObj.can_show_invalid_apply_button && this.dataObj.can_click_invalid_apply_button) {
            const res = [] as any
            const sort = ['0', 'A', 'B', 'C', 'D', '99', 'X', 'Y', 'Z']
            sort.forEach((v, i) => {
                if (v in data) {
                    res.push(data[v])
                } else if (v === '99') {
                    res.push({
                        text: '无效',
                        valid: true,
                        value: '99'
                    })
                }
            })
            return res
        }
        return data
    }
    get
    isZjs () {
        return this.dataObj && this.dataObj.type_array && this.dataObj.type_array.includes('转介绍')
    }

    get
    depList () {
        return this.infoPos.dep_arr
    }

    get
    getGroupsByDepId () {
        return this.__utils.index.getGroupsByDepId(this.zjForm.radio_depart, { hide: true })
    }

    get
    logsList () {
        if (this.logTabs.current === 1) {
            return this.dataObj.brief_remark
        }
        return this.dataObj.brief_remark_all
    }

    // 填写跟进
    quickFollow: {
        value: string[]
        list: string[]
        isActive: (item: string) => boolean
    } = {
        value: [],
        list: ['有意向看房', '同行/恶搞', '晚点联系', '无意向看房', '已成交', '无合适房源'],
        isActive (item) {
            return this.value.includes(item)
        }
    }
    quickFollowChange (item: string) {
        const idx = this.quickFollow.value.indexOf(item)

        if (idx !== -1) {
            this.quickFollow.value.splice(idx, 1)
            this.toggleFollow(item, false)
        } else if (this.quickFollow.value.length < 3) {
            this.quickFollow.value.push(item)
            this.toggleFollow(item, true)
        }
    }

    toggleFollow (text: string, add = true) {
        if (add) {
            this.genjin_textarea = `${text}；${this.genjin_textarea}`
        } else {
            this.genjin_textarea = this.genjin_textarea.replace(`${text}；`, '')
        }
    }

    @Watch('requireInfo.type')
    changeType (v1: string, v2: string) {
        if (!this.dataObj.show) return

        if (v2) {
            this.toggleFollow(`${v2}：${this.requireInfo.price}`, false)
        }

        this.requireInfo.price = ''
    }

    @Watch('requireInfo.price')
    changePrice (v1: string, v2: string) {
        if (!this.dataObj.show) return

        if (v2) {
            this.toggleFollow(`${this.requireInfo.type}：${v2}`, false)
        }

        if (v1) {
            this.toggleFollow(`${this.requireInfo.type}：${v1}`, true)
        }
    }

    @Watch('requireInfo.area')
    changeArea (v1: string, v2: string) {
        if (!this.dataObj.show) return

        if (v2) {
            this.toggleFollow(`面积需求：${v2}`, false)
        }

        if (v1) {
            this.toggleFollow(`面积需求：${v1}`, true)
        }
    }

    @Watch('requireInfo.region')
    changeRegion (v1: string[], v2: string[]) {
        if (!this.dataObj.show) return

        if (v2.length) {
            const v2Labels = v2.map(a => {
                const res = searchTreeByKey(this.requireInfo.regionList, 'code', a)
                return res ? res.label : ''
            })
            this.toggleFollow(`意向区域：${v2Labels}`, false)
        }

        if (v1.length) {
            const v1Labels = v1.map(a => {
                const res = searchTreeByKey(this.requireInfo.regionList, 'code', a)
                return res ? res.label : ''
            })
            this.toggleFollow(`意向区域：${v1Labels}`, true)
        }
    }

    @Watch('addPhoneHouse.houseList')
    changeHouseList (v1: LabelValueArray, v2: LabelValueArray) {
        const rawHouse = this.addPhoneHouse.houseList2 as LabelValueArray
        const labels = rawHouse.map(a => a.label)

        v1 = v1.filter(a => !labels.includes(a.label))
        v2 = v2.filter(a => !labels.includes(a.label))

        if (v2.length) {
            this.toggleFollow(`意向楼盘：${v2.map(a => a.label).join('，')}`, false)
        }

        if (v1.length) {
            this.toggleFollow(`意向楼盘：${v1.map(a => a.label).join('，')}`, true)
        }
    }

    // 需求信息
    requireInfo: RequireInfo = {
        type: '',
        typeList: ['首付预算', '总预算'],
        price: '',
        area: '',
        areaList: ['<50㎡', '50-70㎡', '50-90㎡', '90-110㎡', '>110㎡'],
        region: [],
        regionList: []
    }
    get
    priceList () {
        if (!this.requireInfo.type) return []

        return this.requireInfo.type === this.requireInfo.typeList[0] ? ['<50万', '50-100万', '100-150万', '>150万'] : ['50-100万', '100-200万', '200-300万', '300-400万', '>400万']
    }
    async initPlace () {
        const { data } = await this.__apis.utils.placeDetailById({
            idList: [33, 44]
        })

        this.requireInfo.regionList = data
    }

    tabs = {
        current: 0,
        data: ['跟进记录', '共享详情']
    }

    logTabs = {
        current: 1,
        data: ['查看完整记录', '只看人工跟进记录']
    }

    logTabsClick (index: number) {
        this.logTabs.current = index
    }

    tabsClick (index: number) {
        this.tabs.current = index
    }

    get
    otherSalemanTipsText () {
        const otherSaleManNames = this.dataObj.otherSaleManNames
        return `该客户正在被多人跟进，跟进该客户的分析师：${otherSaleManNames.join('、')}`
    }

    async created () {
        this.customer_id = this.$route.params.id
        await this.getShareListByCustomerId()
        await this.initPlace()
        await this.getHasFollow()
        await this.getData()
        await this.initRobotCallInfo()
        this.houseClickHandleInit()
    }

    houseSearchVisible () {
        if (this.dataObj.local_page_link) {
            this.$nextTick(() => {
                this.addPhoneHouse.houseList.forEach((a: any, i: number) => {
                    if (a.value === this.dataObj.house_id) {
                        $('.house_list_tags .el-tag').eq(i).addClass('link')
                    }
                })
            })
        }
    }

    houseClickHandleInit () {
        if (this.dataObj.local_page_link) {
            this.houseSearchVisible()
            $('.house_list_tags').on('click', '.el-select__tags-text', (e) => {
                e.stopPropagation()
                const index = $(e.target).parent().index()
                if (
                    index !== -1 &&
                    this.addPhoneHouse.houseList[index] &&
                    this.addPhoneHouse.houseList[index].value === this.dataObj.house_id
                ) {
                    window.open(this.dataObj.local_page_link)
                }
            })
        }
    }

    followId = 0
    async getHasFollow () {
        if (!this.__role.check(this.__role.permissions.AddCancelFollow)) return
        const { data } = await this.__apis.customer.getCustomerIsFollow(+this.customer_id)
        this.followId = data.id
    }

    // 拷贝系统标签
    sysTagsIn () {
        return this.__store.state.user.sysTags && this.__utils.index.copyObj(this.__store.state.user.sysTags)
    }

    // 图片上传
    handleRemove (file: any, fileList: any) {
        this.fileList = fileList
        this.form.imgs = fileList
    }

    handlePictureSuccess (response: any, file: any) {
        this.fileList.push({
            url: response.data.url,
            data: file,
            name: file.name
        })

        if (this.dialogFormVisible) {
            this.form.imgs = this.fileList
        }
    }

    handlePictureError () {
        this.$notify({
            title: '错误',
            message: '上传失败',
            type: 'error'
        })
    }

    addPhoneHandle () {
        this.addPhoneHouse.phoneList.push({
            value: '',
            save: false
        })
    }

    delPhoneHandle (i: number) {
        this.addPhoneHouse.phoneList.splice(i, 1)
    }

    // 请求客户信息
    async getData () {
        let res !: customerDetailRes

        try {
            this.dataObj = {}
            const { data } = await this.__apis.customer.customerDetail(this.customer_id)
            res = data
        } catch (e) {
            return
        }

        this.requireInfo.type = res.demand_budget ? this.requireInfo.typeList[0] : res.demand_budget_total ? this.requireInfo.typeList[1] : ''
        this.requireInfo.price = res.demand_budget || res.demand_budget_total
        this.requireInfo.area = res.demand_area
        this.requireInfo.region = res.demand_region.split(',').map(a => {
            const res = searchTreeByKey(this.requireInfo.regionList, 'label', a)

            return res ? res.code : ''
        })

        this.sysTags2 = this.sysTagsIn()
        res.brief_remark.map((v: any) => {
            if (v.customer_tag === '客户级别') {
                v.customer_tag = ''
            }
            if (v.customer_tag * 1 > 0) {
                v.customer_tag = ''
            }
        })

        for (let key in this.customerTags) {
            if (res.tag && res.tag === this.customerTags[key].value) {
                this.tagCurrent = this.customerTags[key].value
            }
        }
        for (let key in this.impt) {
            if (res.impt_group &&
                res.impt_group.value === this.impt[key].value) {
                this.imptCurrent = this.impt[key].value
            }
        }
        if (this.sysTags2) {
            res.sys_tags && res.sys_tags.map((v: any) => {
                for (let key in this.sysTags2) {
                    if (v.value === this.sysTags2[key].value) {
                        this.$set(this.sysTags2[key], 'active', true)
                    }
                }
            })
        }

        let houseVal = {
            label: res.house_name,
            value: res.house_id
        }
        const ex_mobile_and_house = res.ex_mobile_and_house || {
            house_list: [],
            phone_list: []
        }
        let mobileHouse:{
            house_list: {
                label: string,
                value: number
            }[]
            phone_list: {
                save: boolean,
                value: string
            }[]
        } = {
            house_list: ex_mobile_and_house.house_list ? ex_mobile_and_house.house_list.filter(v => v.value !== houseVal.value) : [],
            phone_list: ex_mobile_and_house.phone_list && ex_mobile_and_house.phone_list.length ? ex_mobile_and_house.phone_list.map(v => {
                return {
                    value: v,
                    save: true
                }
            }) : []
        }
        if (houseVal.value !== 0 && houseVal.label !== '') {
            mobileHouse.house_list.unshift(houseVal)
        }
        let searchHouseList = mobileHouse.house_list.map((v) => {
            return {
                label: v.label,
                value: v.value,
                id: v.value
            }
        })
        this.addPhoneHouse = {
            searchHouseList: searchHouseList,
            searchPhoneList: [],
            phoneList: mobileHouse.phone_list,
            houseList: mobileHouse.house_list,
            houseList2: this.__utils.index.copyObj(mobileHouse.house_list)
        }
        if (res.tk_reject_reason_list) {
            res.tk_reject_reason_list = res.tk_reject_reason_list.map(v => {
                v.date = this.__utils.index.formatDate(new Date(v.timeStamp * 1000), 'YYYY-MM-DD HH:mm:ss')
                return v
            })
        }
        // 是机器人外呼客户
        if (this.hasRobotTag(res)) {
            const followListFirst = res.brief_remark && res.brief_remark.length && res.brief_remark[res.brief_remark.length - 1]
            if (followListFirst && followListFirst.operator_id === 0 && (followListFirst.remark.includes('系统分配给') || followListFirst.remark.includes('免费认领'))) {
                followListFirst.hasRobot = true
            }
        }

        res.ptt_call_record_audio_url = this.__utils.index.setXjStaticPreUrl(res.ptt_call_record_audio_url)
        this.dataObj = res
        this.basicInfo = {
            CustomerId: this.dataObj.id,
            CustomerName: this.dataObj.name
        }

        this.$nextTick(() => {
            this.dataObj = {
                ...this.dataObj,
                show: true
            }
        })
    }

    // 保存客户信息
    async saveCustomer () {
        let sys = []
        if (this.sysTags2) {
            for (let key in this.sysTags2) {
                if (this.sysTags2[key].active) {
                    sys.push(this.sysTags2[key].value)
                }
            }
        }
        if (this.tagCurrent && this.tagCurrent === '99') {
            await this.__apis.customer.customerInvalidApply({
                customer_id: this.dataObj.id,
                apply_reason: ''
            })
            this.tagCurrent = ''
        }
        await this.__apis.customer.infoSaveCustomer({
            id: this.dataObj.id,
            name: this.dataObj.name,
            impt_group: +this.imptCurrent,
            mobile: this.dataObj.mobile_fixed,
            tag: !this.tagCurrent || this.tagCurrent === '99' ? '' : this.tagCurrent,
            sys_tags: sys.join(','),
            demand_area: this.requireInfo.area || undefined,
            // 首付预算
            demand_budget: this.requireInfo.type === this.requireInfo.typeList[0] ? this.requireInfo.price : '',
            demand_budget_total: this.requireInfo.type === this.requireInfo.typeList[1] ? this.requireInfo.price : '',
            demand_region: this.requireInfo.region.toString() || undefined,
            ex_mobile_and_house: {
                house_list: this.addPhoneHouse.houseList,
                phone_list: this.addPhoneHouse.phoneList.filter((v:any) => v.value).map((v:any) => v.value)
            },
            next_follow_time: this.gjTime
        })
    }

    // 意向楼盘
    otherHouseSearch (queryString: string) {
        this.querySearchAsync(queryString, (res: any) => {
            this.addPhoneHouse.searchHouseList = res
        })
    }

    // 楼盘匹配
    async querySearchAsync (queryString: string, cb: any) {
        if (!queryString || queryString.length === 0) {
            // eslint-disable-next-line standard/no-callback-literal
            cb([])
            return
        }

        const res = await this.__apis.utils.searchHouse({ term: queryString })
        cb(res.data)
    }

    handleHouseSelect (val: any) {
        this.house = val
    }

    intention = {
        value: 1,
        check: false
    }

    clearRate () {
        this.intention.check = false
        this.intention.value = 1
    }

    intentionChange () {
        const text = '未接通/空号/无效通话'
        if (this.intention.value === 0) {
            this.genjin_textarea = `${text} ${this.genjin_textarea}`
        } else {
            this.genjin_textarea = this.genjin_textarea.replace(`${text} `, '')
        }
    }

    noLevelWarn = false
    // 保存客户信息和跟进
    async saveCustomerLog () {
        if (this.saveLoading) {
            return
        }

        const isPhone = this.addPhoneHouse.phoneList.filter((a:any) => (a.value && !a.save)).every((a:any) => (/^1\d{10}$/).test(a.value))

        if (!isPhone) {
            return this.$notify({
                title: '警告',
                message: '请输入新增电话的正确手机号码！',
                type: 'warning'
            })
        }

        if (!this.isShare && (!this.tagCurrent || this.tagCurrent === '0')) {
            this.noLevelWarn = true
            return this.$notify({
                title: '警告',
                message: '请选择客户级别！',
                type: 'warning'
            })
        }

        this.saveLoading = true

        try {
            await this.saveCustomer()
            await this.logSave()

            await this.getData()
            this.houseClickHandleInit()
            this.clearRate()
            this.gjTime = ''

            this.$notify({
                title: '成功',
                message: '提交成功！',
                type: 'success'
            })
        } catch (e) {
            throw e
        } finally {
            this.saveLoading = false
        }
    }

    // 跟进提交
    async logSave () {
        if (!this.genjin_textarea) return
        const { data } = await this.__apis.customer.saveCustomerLog({
            id: this.dataObj.id,
            log: this.genjin_textarea,
            log_mark: this.intention.value
        })

        this.dataObj.brief_remark.unshift({
            time: new Date().format('yyyy-MM-dd hh:mm:ss'),
            operator: this.__store.state.user.userInfo.username,
            remark: this.genjin_textarea,
            tag: this.customerTags && this.customerTags[this.tagCurrent]
        })

        this.genjin_textarea = ''
        this.quickFollow.value = []

        if (data === 1) {
            this.showLogMoney()
        }

        if (this.dataObj.cj_user_info.length > 0) {
            this.$confirm('客户已成交，取消认领不会进入公池', {
                type: 'warning',
                showCancelButton: false
            })
        }
    }

    // 认领、取消客户
    async toRenling (obj: any) {
        // 客户在别人手上无法认领
        if (this.dataObj.saleman && !obj.has_take) {
            return this.$notify({
                title: '警告',
                message: `该客户已被${this.dataObj.saleman}认领`,
                type: 'warning'
            })
        }

        if (this.__utils.index.limitRepeatClick()) return

        try {
            if (obj.has_take) {
                if (this.shareList.length) {
                    try {
                        await this.$confirm(`该客户正共享给其他分析师，您可以转交给他们，继续取消认领会强制解除共享`, '确认要取消客户认领吗？', {
                            dangerouslyUseHTMLString: false,
                            confirmButtonText: '转 交',
                            cancelButtonText: '继续取消认领',
                            type: 'warning',
                            showClose: true,
                            closeOnClickModal: false,
                            customClass: 'my_confirm_share',
                            distinguishCancelAndClose: true,
                            center: true
                        })
                        this.shareZj = {
                            visible: true,
                            data: {
                                customer_id: +this.customer_id,
                                shareList: this.shareList
                            }
                        }
                    } catch (e) {
                        console.log(e)
                        if (e === 'cancel') {
                            await this.__apis.customer.cancelRenlin(obj.id)
                            this.$notify({
                                title: '成功',
                                message: obj.has_take ? '取消认领成功！' : '认领成功！',
                                type: 'success'
                            })
                            this.getData()
                            this.getShareListByCustomerId()
                        }
                    }
                } else {
                    await this.$confirm('请再次确认是否取消认领客户？', {
                        type: 'warning'
                    })
                    await this.__apis.customer.cancelRenlin(obj.id)
                    this.$notify({
                        title: '成功',
                        message: obj.has_take ? '取消认领成功！' : '认领成功！',
                        type: 'success'
                    })
                    await this.getData()
                }
            } else {
                await this.__apis.customer.renlin(obj.id)
                this.$notify({
                    title: '成功',
                    message: obj.has_take ? '取消认领成功！' : '认领成功！',
                    type: 'success'
                })
                await this.getData()
            }
        } catch (e) {} finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    // 转交相关
    zjDepartChange () {
        this.zjForm.radio_group = 0
        this.zjForm.radio_fxs = 0
    }

    zjGroupChange () {
        this.zjForm.radio_fxs = 0
    }

    toZhuanjiao () {
        if (this.__role.check(this.__role.permissions.RenLin) && !this.dataObj.has_take) {
            return this.$notify({
                title: '警告',
                message: '客户未认领,不能转交！',
                type: 'warning'
            })
        }
        this.dialogFormZjVisible = true
    }

    // 转交客户
    async saveZj () {
        if (this.__utils.index.limitRepeatClick()) return

        try {
            await this.__apis.customer.zhuanjiao({
                customer_id: this.dataObj.id,
                to_user_id: this.zjForm.radio_fxs
            })

            await this.$confirm('转交成功，点击确定返回上一页面', {
                title: '成功',
                closeOnClickModal: false,
                showCancelButton: false,
                showClose: false
            })

            window.close()
        } catch (e) {
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    // 成交带看总结
    dialogGuideSuccess (i: number) {
        this.fileList = []
        this.form.imgs = []
        this.isGuideSuccess = i.toString()
        this.titleDialogText = '带看总结'
        this.dialogFormVisible = true
        this.dkDialogVisible = true
    }

    // dialog 楼盘匹配返回
    formHouseSelect (obj: any, form: any) {
        if (this.dialogFormChengJiaoDataVisible) {
            this.formChengJiao.house_id = obj.id
        } else if (this.dialogFormVisible) {
            if (form) {
                form.house_id = obj.id
            }
        } else {
            this.guideForm.house_id = obj.id
        }
    }

    // 保存
    async saveForm () {
        let data: any = {
            customer_id: this.dataObj.id,
            customer_name: this.dataObj.name
        }

        const form = this.$refs['guideForm'] as any
        form.validate(async (valid: any) => {
            if (!valid) return
            data = Object.assign(this.guideForm, data)

            await this.saveFormRequest(data)

            form.resetFields()
            this.dialogFormGuideVisible = false
        })
    }

    // 保存请求
    async saveFormRequest (data: any) {
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)'
        })

        try {
            await this.__apis.customer.saveDkPre(data)

            this.$notify({
                title: '成功',
                message: '添加成功！',
                type: 'success'
            })
        } catch (e) {
            throw e
        } finally {
            loading.close()
        }
    }

    // 添加项目
    add_project () {
        this.form.ext_data.push({ house_name: '', channel_name: '', channel_username: '', channel_tel: '', zygw_name: '', zygw_tel: '' })
    }

    // 删除项目
    projectDel (index: number) {
        this.form.ext_data.splice(index, 1)
    }

    // 标记为无效客户
    async validCustomer () {
        await this.__apis.customer.doApplyCustomerInvalid(this.customer_id)
        this.$notify({
            title: '成功',
            message: '标记成功,等待审核！',
            type: 'success'
        })
    }

    async seePhone (item: any) {
        if (item.mobile_fixed.indexOf('*') === -1) {
            return
        }
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })

        try {
            const { data: { mobile, ex_mobile_and_house } } = await this.__apis.customer.showPubCustomerMobile(item.id)

            if (mobile) {
                item.mobile_fixed = mobile
            }
            if (ex_mobile_and_house) {
                this.addPhoneHouse.phoneList = ex_mobile_and_house.phone_list.length ? ex_mobile_and_house.phone_list.map(v => {
                    return {
                        value: v,
                        save: true
                    }
                }) : [
                    {
                        value: '',
                        save: false
                    }
                ]
            }
        } catch (e) {
            throw e
        } finally {
            loading.close()
        }
    }

    // 客户快速备注
    async saveQuickRemark () {
        const data = this.dataObj
        await this.__apis.customer.saveCustomerRemark({
            customer_id: data.id,
            remark: data.remark_fast
        })
        this.$message({
            type: 'success',
            message: '提交成功!'
        })
    }

    // 过滤类型
    typeArrayFilter (data: {type_array?: string[]}) {
        if (!data.type_array) return []
        const arr = data.type_array.filter(a => !['七鱼机器人外呼'].includes(a))
        return arr.length ? arr[0] : ''
    }

    // 至少存在一个数据
    showChannelType (item: any) {
        const isRobot = item.u_channel.includes('机器人外呼')
        return isRobot ? '' : (item.u_channel || this.typeArrayFilter(item))
    }

    // 是否有外呼标签
    hasRobotTag (data: any) {
        return data.type_array && data.type_array.includes('七鱼机器人外呼')
    }

    // 初始化外呼请求
    async initRobotCallInfo () {
        const item = this.dataObj
        const { data } = await this.__apis.phoneRecord.customerRobotCallInfo(this.dataObj.id)
        this.robotCallDetailComponent.item = {
            name: item.name,
            phone: item.mobile_fixed,
            callId: data.call_record_id,
            callDuration: data.chat_duration,
            chats: data.call_detail_list,
            level: data.level,
            url: data.full_audio_url
        }
    }

    // 显示外呼通话详情
    async showCallDetail () {
        this.robotCallDetailComponent.show = true
    }

    async addCancelFollow () {
        if (this.followId) {
            await this.__apis.customer.deleteFollowCustomer(this.followId)
        } else {
            await this.__apis.customer.saveFollowCustomer(this.dataObj.id)
        }
        this.getHasFollow()
        this.$notify({
            title: '成功',
            message: `${this.followId ? '取消' : '添加'}成功！`,
            type: 'success'
        })
    }

    shareZj: {
        visible: boolean,
        data: {
            customer_id: number,
            shareList: ShareItem[]
        }
    } = {
        visible: false,
        data: {
            customer_id: 0,
            shareList: []
        }
    }

    shareManage: ShareManage = {
        visible: false,
        data: {
            id: 0,
            userInfo: []
        }
    }

    shareList: ShareItem[] = []

    get
    isShare () {
        return this.shareList.map(a => a.shareId).includes(this.__store.state.user.userInfo.user_id)
    }

    async getShareListByCustomerId () {
        const { data } = await this.__apis.customer.getCustomerShareRecordListByCustomerId(+this.customer_id)
        this.shareList = data.map(a => {
            return {
                id: a.id,
                customerId: a.customer_id,
                shareId: a.shared_user_id,
                shareName: a.shared_user_name,
                ctime: this.__utils.index.formatDate(new Date(a.ctime), 'YYYY-MM-DD HH:mm:ss')
            }
        })
    }

    showShareManage () {
        const item = this.dataObj
        this.shareManage.data = {
            id: item.id,
            userInfo: this.shareList.map(a => {
                return {
                    shareId: a.id,
                    id: a.shareId,
                    name: a.shareName
                }
            })
        }
        this.shareManage.visible = true
    }

    async exitShare () {
        this.$confirm(`确认退出共享后将自动关闭窗口！`, '确定要退出共享吗？', {
            dangerouslyUseHTMLString: false,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            showClose: false,
            customClass: 'my_confirm_share',
            center: true
        }).then(async () => {
            await this.__apis.customer.exitShare(+this.customer_id)
            this.$notify({
                title: '成功',
                message: '退出成功！',
                type: 'success'
            })
            window.close()
        }).catch((e) => {
            console.log(e)
        })
    }

    async cancelShare (item: ShareItem) {
        if (this.__utils.index.limitRepeatClick()) return
        try {
            await this.__apis.customer.cancelShare([item.id])
            this.$notify({
                title: '成功',
                message: '取消成功！',
                type: 'success'
            })
            this.getShareListByCustomerId()
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    async cancelShareAll () {
        if (this.__utils.index.limitRepeatClick()) return
        try {
            const ids = this.shareList.map(a => a.id)
            await this.__apis.customer.cancelShare(ids)
            this.$notify({
                title: '成功',
                message: '取消成功！',
                type: 'success'
            })
            this.getShareListByCustomerId()
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    showLogMoney (money = 2) {
        this.logMoneyComponent = {
            show: true,
            money
        }
    }

    applyInvalidCustomerInfo = {
        visible: false,
        data: {
            remark: ''
        }
    }
    applyInvalidCustomer () {
        this.applyInvalidCustomerInfo = {
            visible: true,
            data: {
                remark: ''
            }
        }
    }
    async saveApplyInvalidCustomer (value: string) {
        await this.__apis.customer.customerInvalidApply({
            customer_id: this.dataObj.id,
            apply_reason: value
        })
        this.$message({
            message: '提交成功！',
            type: 'success'
        })
        await this.getData()
    }

    async beginFollow (id: number) {
        await this.__apis.customer.beginFollow(id)
        this.$notify({
            title: '成功',
            message: '提交成功！',
            type: 'success'
        })
        this.getData()
    }

    createRelationCustomerInfo = {
        visible: false,
        data: {
            customerId: 0
        }
    }

    showCreateRelationCustomerDialog () {
        this.createRelationCustomerInfo = {
            visible: true,
            data: {
                customerId: this.dataObj.id
            }
        }
    }

    toRelationCustomer (id: number) {
        this.$router.push(`/customer/detail/${id}`)
    }
}
