import { Component, Vue } from 'vue-property-decorator'
import tabsComponent from '@/components/wallet/tabs/index.vue'
import { items } from '@/components/wallet/tabs'

@Component({
    components: {
        tabsComponent
    }
})
export default class IndexPage extends Vue {
    tabsComponent: {
        active: number
        items: items[]
    } = {
        active: 0,

        items: [
            {
                text: '队长提成发放',
                url: ['/financial/group-commission']
            },
            {
                text: '战队利润提成明细',
                url: ['/financial/group-commission/profit']
            },
            {
                text: '提成发放明细',
                url: ['/financial/group-commission/profit-detail']
            }
        ]
    }
}
