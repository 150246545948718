import { Component, Vue, Watch } from 'vue-property-decorator'
import { format } from '@/util/date'
import { ReceivedMessageState, StoreReceivedMessageState } from '@/store/modules/message'
import { MessageType } from '@/types/message/msg_type'
import store, { Module } from '@/store'
import { ConnectionState, StoreConnectionState } from '@/store/modules/connection'

const msgStore = store.module<StoreReceivedMessageState>(Module.MESSAGE)
const conStore = store.module<StoreConnectionState>(Module.CONNECTION)

@Component
export default class NewsComponent extends Vue {
    private throttle = true
    private tabs:any = [
        {
            tabname: '全部通知',
            showtip: false,
            index: 0
        },
        {
            tabname: '业务通知',
            showtip: false,
            index: 1
        },
        {
            tabname: '账户通知',
            showtip: false,
            index: 2
        }
    ]
    private currentTab: any = {
        tabname: '全部通知',
        showtip: false,
        index: 0
    }
    private currentOption: any = {
        value: 0,
        label: '全部消息'
    }
    private options:any = [{
        value: 0,
        label: '全部消息'
    }, {
        value: 1,
        label: '未读消息'
    }, {
        value: 2,
        label: '已读消息'
    }]
    private value:string = '全部消息'
    private keywordTitle = ''
    private newsList: any = []
    private page = 1
    private noMore: boolean = true
    private pageSize = 20
    get
    closeSrc () {
        return 'https://ssl.malmam.com/xiangju-static/dfs/22/67bb1f4146773a/clear.png'
    }
    @Watch('newsList', { deep: true })
    changeList () {
        this.tabs[this.currentTab.index].showtip = false
        let hasRead = false
        this.newsList.map((v: any) => {
            // 显示红点
            if (v.has_read === 0) {
                hasRead = true
            }
        })
        this.tabs[this.currentTab.index].showtip = hasRead
    }
    // 消息列表
    async getMsgList (init: boolean = false) {
        if (init) {
            this.newsList = []
            this.page = 1
            this.noMore = true
        }
        if (!this.noMore) return
        const { data } = await this.__apis.home.getMsgList({
            has_read: this.currentOption.value === 0 ? undefined : this.currentOption.value - 1,
            msg_type: this.currentTab['index'] === 0 ? undefined : this.currentTab['index'],
            msg_search: this.keywordTitle === '' ? undefined : this.keywordTitle,
            page: this.page,
            pagesize: this.pageSize,
            time_start: this.throttle ? Date.parse((new Date() as any)) - 259200 * 1000 : Date.parse((new Date() as any)) - 604800 * 1000,
            time_end: Date.parse((new Date() as any))
        })
        if (data.list.length <= 0) {
            this.noMore = false
        } else {
            this.page = init ? 2 : this.page + 1
            data.list.forEach((v: any) => {
                let temArr:any = []
                const content_params = JSON.parse(v.content_params)
                v.content.split(/[{}]/).forEach((i: any) => {
                    if (i !== '') {
                        temArr.push(content_params[i] || ['', 0].includes(content_params[i]) ? content_params[i] : i)
                    }
                })
                v.ctime = format((v.ctime as Date), 'yyyy-MM-dd hh:mm:ss')
                v.real_content = temArr.join('')
                v.right_click = JSON.parse(v.url).pcUri
            })
            this.newsList = [...this.newsList, ...data.list]
        }
    }
    // 标记已读消息
    async markHasRead (all: boolean = false, ids: number) {
        const { data } = await this.__apis.home.markHasRead({
            ids: all ? [] : [ids]
        })
        !all && this.newsList.forEach((v: any) => {
            if (v.id === ids) {
                v.has_read = 1
            }
        })
        all && this.newsList.forEach((v: any) => {
            v.has_read = 1
        })
    }
    // 切换显示三天还是七天
    checkThrottle () {
        this.throttle = !this.throttle
        this.getMsgList(true)
    }
    newClick (v: any) {
        if (!JSON.parse(v.url).pcUri) {
            return
        }
        let temArr:any = []
        JSON.parse(v.url).pcUri.split(/[{}]/).forEach((i: any) => {
            if (i !== '') {
                temArr.push(JSON.parse(v.url_params)[i] ? JSON.parse(v.url_params)[i] : i)
            }
        })
        this.$router.push(temArr.join(''))
        this.$emit('popup-close')
    }
    search () {
        this.getMsgList(true)
    }
    @Watch('keywordTitle', { immediate: true })
    watchSearch () {
        if (this.keywordTitle === '') {

        } else {

        }
    }
    clearKeyword () {
        this.keywordTitle = ''
        this.getMsgList(true)
    }
    changeOption (e:any) {
        this.currentOption = this.options[Number(e)]
        this.getMsgList(true)
    }
    changeTab (index:number) {
        this.currentTab = this.tabs[index]
        this.getMsgList(true)
    }
    loadMore () {
        this.page !== 1 && this.getMsgList(false)
    }

    private unsub1!: Function
    private unsub2!: Function
    mounted () {
        this.$emit('updata-news-count', 0)
        this.getMsgList(true)
        var hiddenProperty:any = 'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null
        var visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange')
        document.addEventListener(visibilityChangeEvent, this.onVisibilityChange)

        if (conStore.state.connectionState === ConnectionState.CONNECTED) {
            this.checkNewMsg()
        }

        this.unsub1 = msgStore.subscribe((mutation) => {
            const payload = mutation.payload as ReceivedMessageState
            if (payload.LastMessage.type === MessageType.MSG_CENTER_NEW_MESSAGE) {
                // const msg = payload.LastMessage.data as MessageMsgCenterNewMessage
                // console.log('new msg center message', msg.id)
                this.checkNewMsg()
            }
        })
        this.unsub2 = conStore.subscribe((mutation) => {
            const connectionState = mutation.payload as ConnectionState
            if (connectionState === ConnectionState.CONNECTED) {
                this.checkNewMsg()
            }
        })
    }
    closePopup () {
        this.$emit('popup-close')
    }
    private newAnimeShowId: number = 0
    onVisibilityChange () {
        var hiddenProperty:any = 'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null
        if (!(document as any)[hiddenProperty]) {

        } else {

        }
    }
    async checkNewMsg () {
        // id++
        // console.log(id)
        // this.newAnimeShowId = id
        // setTimeout(() => { this.newsList.unshift({ id, real_content: '测试测试测试测试。。。', title: '我是新消息', ctime: `${format(new Date(), 'yyyy-MM-dd hh:mm:ss')} ` }) })
        const { data } = await this.__apis.home.getNewMsg({})
        if (data.id && (this.currentTab.index === 0 || this.currentTab.index === data.msg_type) && (this.currentOption.type === 0 || this.currentOption.type === data.has_read + 1)) {
            this.newAnimeShowId = data.id
            let temArr:any = []
            data.content.split(/[{}]/).forEach((i: any) => {
                if (i !== '') {
                    temArr.push(JSON.parse(data.content_params)[i] || JSON.parse(data.content_params)[i] === '' ? JSON.parse(data.content_params)[i] : i)
                }
            })
            data.ctime = `${format((data.ctime as Date), 'yyyy-MM-dd hh:mm:ss')} `
            data.real_content = temArr.join('')
            data.right_click = JSON.parse(data.url).pcUri
            setTimeout(() => { this.newsList.unshift(data) })
        }
    }

    beforeDestroy () {
        this.markHasRead(true, 0)
        this.unsub1()
        this.unsub2()
    }
}
