import { BaseRes, java, ListRes, php } from '@/api/index'
import { duijieUser } from './channelManage'
import { AxiosResponse } from 'axios'

interface searchHouseMoreRes {
    id: number
    name: string
    alias_name: string
    beian_name: string
}

interface searchHouseReq {
    term: string
    country_id_list?: number[]
    area_collection_id_list?: number[]
}

export interface searchHouseRes {
    id: number
    label: string
    value: string
}

interface searchUserReq {
    key: string
    fxs?: any
}

export interface searchUserRes extends ListRes <{
    Id: number
    Username: string
    Email: string
    Ctime: string
    Mtime: string
    Ip: number
    Province: number
    City: number
    Deactive: number
    groupName: string
    depName: string
    roleId: number
    roleName: string
}> {}

interface searchDepRes {
    [index: string]: {
        name: string
        value: number
        intro: string
        img: string
    }
}

interface searchDocRes extends ListRes<{
    Id: number
    Title: string
    Body: string
    UserId: number
    LibId: number
    DirId: number
    Ctime: string
    Mtime: string
    ModifyRecords: string
    LibInfo: {
        Id: number
        Name: string
        Description: string
        Img: string
        AdminList: string
        DirSort: string
        Ctime: string
    }
}>{}

interface searchDepReq {
    sample?: number
    no_sale_dep?: number
}

export interface getAllSalePartnerListRes extends ListRes<{
    name: string
    full_name: string
    full_name_new: string
    id: number
    duijie_user: duijieUser
}>{}

interface GetChannelMemberListRes {
    name: string
    user_id: number
    department: number
    role: number
}

interface PlaceDetailByIdReq {
    idList: number[]
}

interface PlaceDetailByIdRes {
    children: PlaceDetailByIdRes[]
    code: string
    label: string
}

export default class ApiUtils {
    // 根据省id获取区域信息
    async placeDetailById (data: PlaceDetailByIdReq) {
        const res = await java.post('/common/place/list/placeDetailByid', data, {}) as AxiosResponse<PlaceDetailByIdRes[]>

        const cs = [...res.data]

        while (cs.length) {
            if (cs[0].children.length) {
                cs.push(...cs[0].children)
            } else {
                delete cs[0].children
            }

            cs.shift()
        }

        // 返回的值中不一定包含children属性
        return res
    }

    async searchHouseMore (data: {term: string}): BaseRes<searchHouseMoreRes[]> {
        // return php.post('', {
        //     action_name: 'houseTipMore',
        //     ...data
        // })

        const res = await java.post('/admin/house/manage/getHouseListMultiFieldsSearch', {
            key: data.term
        }) as any
        res.data = res.data.rows.map((a: any) => {
            return {
                id: a.id,
                name: a.name,
                alias_name: a.alias_name,
                beian_name: a.beian_name
            }
        })
        return res
    }

    async searchHouse (data: searchHouseReq): BaseRes<searchHouseRes[]> {
        // return php.post('', {
        //     action_name: 'houseTip',
        //     ...data
        // })

        const res = await java.post('/admin/house/manage/getCommodityHouseListSearch', {
            house_name: data.term,
            country_id_list: data.country_id_list,
            area_collection_id_list: data.area_collection_id_list
        }) as any
        res.data = res.data.rows.map((a: any) => {
            return {
                id: a.house_id,
                value: a.house_name,
                label: a.house_name
            }
        })
        return res
    }

    async searchUser (data: searchUserReq): BaseRes<searchUserRes> {
        let d
        const res = { data: d } = await (php.post('', {
            action_name: 'searchUser',
            ...data
        }) as BaseRes<searchUserRes>)

        d.list.sort((a, b) => a.Deactive - b.Deactive)

        return res
    }

    searchEmployee (key: string): BaseRes<searchUserRes> {
        return php.post('', {
            action_name: 'searchEmployee',
            key
        })
    }

    // 获取所有部门
    searchDep (data?: searchDepReq): BaseRes<searchDepRes[]> {
        return php.post('', {
            action_name: 'getCompanyDepList',
            ...data
        })
    }

    // 搜索知识库
    searchDoc (keyword: string): BaseRes<searchDocRes> {
        return php.post('', {
            action_name: 'searchDoc',
            keyword
        })
    }

    // 获取所有合作渠道
    async getAllSalePartnerList (full_name = ''): BaseRes<getAllSalePartnerListRes> {
        const res = await php.post('', {
            action_name: 'getAllSalePartnerList',
            full_name,
            status: 1
        }) as AxiosResponse<getAllSalePartnerListRes>

        return res as any
    }

    // 获取渠道部成员
    getChannelMemberList (): BaseRes<GetChannelMemberListRes[]> {
        return java.post('/admin/channel/achievements/manage/channel/member/getChannelMemberList', {})
    }
}
