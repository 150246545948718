import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import inputDateRangeComponent from '@/components/filters/inputDateRange/index.ts'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import inputSelectComponent from '@/components/filters/inputSelect/index.vue'
import { storageName } from '@/localStorage'
import { routerBaseUrl } from '@/router'

@Component({
    components: {
        FilterComponent,
        InputDateRangeComponent,
        FilterBusInputSelectComponent,
        inputSelectComponent
    }
})
export default class TcDetailPage extends Vue {
    @Prop({
        default: 0,
        type: Number
    })
    monthTime !: number

    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }

    @Watch('monthTime')
    watchMonthTime (n: number) {
        if (n) {
            // 增加筛选条件
            const start = new Date(n * 1000).format('yyyy-MM-dd')
            const end = new Date(new Date(start.split('-').map((a, i) => i === 1 ? Number(a) + 1 : a).join('-')).getTime() - 86400000).format('yyyy-MM-dd')

            const startDate = this.$refs.startDate as inputDateRangeComponent
            startDate.value = [start, end]
            startDate.enter()
        }
    }

    table: any = {
        data: [],
        loading: false,
        time_start: '',
        time_end: '',
        house_name: '',
        sale_sid: '',
        cj_time_start: '',
        cj_time_end: '',
        rate: ''
    }

    pagination = {
        total: 20,
        pagesize: 20,
        current: 1
    }

    mounted () {
        this.getData()
    }

    // 跳转成交订单
    gotoSuccess (id: string) {
        open(`${routerBaseUrl}/financial/success_detail/success/${id}`, '_blank')
    }

    filterChange (data: any) {
        this.table.time_start = ''
        this.table.time_end = ''
        this.table.house_name = ''
        this.table.sale_sid = ''
        this.table.cj_time_start = ''
        this.table.cj_time_end = ''
        this.table.rate = ''

        for (let k in data) {
            if (k === 'startDate') {
                this.table.time_start = data[k][0]
                this.table.time_end = data[k][1]
            } else if (k === 'cj_time') {
                this.table.cj_time_start = data[k][0]
                this.table.cj_time_end = data[k][1]
            } else {
                this.table[k] = data[k]
            }
        }

        if (this.table.time_start === '') {
            this.$emit('update:monthTime', 0)
        }

        this.pagination.current = 1
        this.getData()
    }

    // 当前分页改变时候触发
    currentChange (page: number) {
        if (this.table.loading) return

        this.pagination.current = page
        this.getData()
    }

    async getData () {
        const { data } = await this.__apis.gzManage.getUserBonusList({
            employee_id: +this.$route.params.id,
            page: this.pagination.current,
            pagesize: this.pagination.pagesize,
            time_start: this.table.time_start,
            time_end: this.table.time_end,
            cj_time_start: this.table.cj_time_start,
            cj_time_end: this.table.cj_time_end,
            rate: this.table.rate
                ? (
                    this.table.rate < 1 ? (Number(this.table.rate) / 100).toFixed(3)
                        : (Number(this.table.rate) / 100).toFixed(2)
                ) : '',
            house_name: this.table.house_name,
            sale_sid: this.table.sale_sid
        })

        this.table.data = []
        this.pagination.total = data.total

        if (data.list.length === 0) return

        // 总计
        if (data.statistic_res) {
            this.table.all = data.statistic_res
        }
        this.table.data.push(this.table.all, ...data.list)

        // 处理一些信息
        this.table.data.forEach((a: any) => {
            a.jy_time2 = new Date(a.jy_time * 1000).format('yyyy-MM-dd')
            a.sale_time2 = new Date(a.sale_time * 1000).format('yyyy-MM-dd')
            a.bonus_rate = a.bonus_rate >= 0 ? (a.bonus_rate * 100).toFixed(1) + '%' : ''
            a.house_dep = this.__utils.index.getDepInfoById(a.house_dep).v
        })
        this.table.data[0].jy_time2 = '总计'
        this.table.data[0].sale_time2 = ''
    }

    exportList () {
        let obj = {
            action_name: 'getSaleJyBonusEsListForExport',
            employee_id: this.$route.params.id,
            time_start: this.table.time_start,
            time_end: this.table.time_end,
            house_name: this.table.house_name,
            sale_sid: this.table.sale_sid,
            cj_time_start: this.table.cj_time_start,
            cj_time_end: this.table.cj_time_end,
            rate: this.table.rate ? (Number(this.table.rate) / 100).toFixed(2) : ''
        }

        this.__utils.index.downloadFile(obj)
    }
}
