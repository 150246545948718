import { Component, Vue } from 'vue-property-decorator'
import OverviewPage from '@/views/usercenter/salary/overflow/index.vue'
import DetailPage from '@/views/usercenter/salary/detail/index.vue'

@Component({
    components: {
        OverviewPage,
        DetailPage
    }
})
export default class UserCenterSalaryPage extends Vue {
    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    table = {
        data: []
    }

    tabs = {
        active: 0
    }

    detail = {
        time: 0
    }

    gotoDetail (monthTime: number) {
        this.detail.time = monthTime

        this.showTiChengDetail()
    }

    // 显示概览页面
    showOverflow () {
        this.tabs.active = 0
    }

    // 显示提成详情页面
    showTiChengDetail () {
        this.tabs.active = 1
    }
}
