import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { getTaskConstraintRes } from '@/api/activity'

interface DepSetInfo {
    [key: string]: getTaskConstraintRes
}

@Component({
    components: {}
})
export default class QuestionBankSetComponent extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    visible!: boolean

    form: {
        dep: number | null
        target: number,
        day?: number
    } = {
        dep: null,
        target: 2,
        day: undefined
    }

    depSetInfo: DepSetInfo = {}

    get
    depArr () {
        return this.__utils.index.getXsDep()
    }

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    created () {
        this.form.dep = this.userInfo.dep || null
        this.getData(this.form.dep || undefined)
    }

    changeTarget (value: number) {
        if (value === 2) {
            this.form.day = undefined
        }
    }

    setFormData (item: getTaskConstraintRes) {
        if (!item) return
        this.form.target = item.time_limit === -1 ? 2 : 1
        this.form.day = this.form.target === 2 ? undefined : item.time_limit
    }

    async getData (value?: number) {
        const { data } = await this.__apis.activity.getTaskConstraint(value)
        this.depSetInfo = data.reduce((s, v) => {
            s[v.dep] = v
            return s
        }, {} as DepSetInfo)
        if (value) {
            this.setFormData(this.depSetInfo[value])
        }
    }

    @Watch('form.dep')
    async depChange (newValue: number, oldValue: number) {
        const defaultValue = {
            time_limit: -1
        }
        if (oldValue) {
            const oldItem = this.depSetInfo[String(oldValue)] || defaultValue
            console.log(oldValue, oldItem, this.form.day)
            const limit = oldItem.time_limit === -1 ? undefined : oldItem.time_limit
            if (this.form.day !== limit) {
                await this.save('确认要保存设置吗？', '是否保存刚才的设置再切换到其他部门设置')
            }
        }
        const newItem = this.depSetInfo[String(newValue)] || defaultValue
        this.setFormData(newItem)
    }

    async submit () {
        await this.save()
        this.close()
    }

    async save (title = '设置保存提示', message = '是否保存修改内容，新设置内容将在明天生效！') {
        try {
            await this.$confirm(message, title, {
                dangerouslyUseHTMLString: false,
                confirmButtonText: '保存',
                cancelButtonText: '取消',
                type: 'warning',
                showClose: false,
                customClass: 'my_confirm_question_bank_set',
                center: true
            })
            const dep = this.form.dep
            const item = this.depSetInfo[String(dep)]
            if (!dep) return
            await this.__apis.activity.taskConstraint({
                dep: dep,
                id: item ? item.id : undefined,
                time_limit: this.form.day || -1
            })
            this.$message({
                message: '设置成功，次日生效！',
                type: 'success'
            })
            await this.getData(this.userInfo.dep || undefined)
        } catch (e) {
            console.log(e)
        }
    }

    close () {
        this.$emit('update:visible', false)
    }
}
