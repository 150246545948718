import { BaseRes, ListReq, ListRes, java } from '@/api/index'

interface getEmployeeGrowingListReq extends ListReq {
    employee_id: number
    dep_id: number
    group_id: number
}

interface getEmployeeGrowingListRes extends ListRes<{
    start_time: number,
    group_id: number,
    user_name: string,
    employee_id: number,
    end_time: number,
    com_dep: number,
    plan_name: string
}>{}

type growList = {
    ctime: number;
    employee_id: number;
    end_time: number;
    evaluation_leader: {
        action_time: number;
        action_user_name: string;
        action_user_id: number;
        content: string;
    };
    evaluation_dep: growList['evaluation_leader'];
    evaluation_hr: growList['evaluation_leader'];
    program: growList['evaluation_leader'];
    demand: growList['evaluation_leader'];
    id: number;
    is_current: number;
    name: string;
    start_time: number;
}

interface getGrowListDetailRes {
    plan_list: growList[];
    employee_name: string;
}

interface saveGrowingPlanReq {
    id: number;
    employee_id: number;
    start_time: number;
    end_time: number;
    plan_name: string;
    demand: string;
    program: string;
    evaluation_leader: string;
    evaluation_hr: string;
    evaluation_dep: string;
}

interface getPxbListReq extends ListReq {
    employee_id?: number;
}

interface getPxbListRes extends ListRes<{
    number_periods: number;
    employee_id: number;
    name: string;
    time: string;
    department: number;
    group: number;
}> {}

export interface getPxbTrainDetailRes {
    'analog_lecture': null | number;
    'comment': string;
    'ctime': number;
    'daily_manage_lecture': null | number;
    'down_team': null | number;
    'employee_id': number;
    'end_time': null | number;
    'is_done': number;
    'knowledge_lecture': null | number;
    'mtime': number;
    'number_periods': number;
    'start_time': null | number;
    'team_leader': string;
    'training_experience': string;
}

interface saveTrainingManageReq {
    'employee_id': number;
    'analog_lecture': number | null;
    'daily_manage_lecture': number | null;
    'knowledge_lecture': number | null;
    'is_done': number;
    'number_periods': number;
    'start_time': number;
    'down_team': number;
    'end_time': number;
    'team_leader': string;
    'comment': string;
    'training_experience': string;
}

export default class APIGrow {
    // 保存培训管理
    saveTrainingManage (data: saveTrainingManageReq) {
        return java.post('/admin/hr/manage/system/document/pxb/manage/saveTrainingManage', data)
    }

    // 获取新训班详情
    getPxbTrainDetail (employee_id: number): BaseRes<getPxbTrainDetailRes> {
        return java.post('/admin/hr/manage/system/document/pxb/manage/getPxbTrainDetail', {
            employee_id
        })
    }

    // 获取培训班
    getPxbList (data: getPxbListReq): BaseRes<getPxbListRes> {
        return java.post('/admin/hr/manage/system/document/pxb/manage/getManageSystemPxbList', data)
    }

    // 获取成长档案列表
    getEmployeeGrowingList (data: getEmployeeGrowingListReq): BaseRes<getEmployeeGrowingListRes> {
        return java.post('/admin/growing/plan/getEmployeeGrowingList', {
            ...data
        })
    }

    // 获取成长档案详情
    getGrowListDetail (employee_id: number): BaseRes<getGrowListDetailRes> {
        return java.post('/admin/growing/plan/getGrowListDetail', {
            employee_id
        })
    }

    // 保存成长计划
    saveGrowingPlan (data: saveGrowingPlanReq) {
        return java.post('/admin/growing/plan/saveGrowingPlan', {
            ...data,
            '__ignore_filter_value': true
        })
    }
}
