import { Component, Vue, Watch } from 'vue-property-decorator'
import InputHouseSearch from '@/components/InputHouseSearch/index.vue'
import { storageName } from '@/localStorage'
import dataRange from '@/util/date'

const FanYongStatus = [
    {
        label: '已返佣',
        value: '1'
    },
    {
        label: '待返佣',
        value: '0'
    }
]

@Component({
    components: {
        InputHouseSearch
    }
})
export default class IndexPage extends Vue {
    form: any = {
        ctime: '',
        customer_name: '',
        customer_tel: '',
        house_name: '',
        dep: '',
        group: '',
        user_id: '',
        has_kp: '',
        has_dz: '',
        sale_time: '',
        fxs: '',
        fy_status: ''
    }
    dataObj: any = {}
    page = 1
    pageSize = 20
    exportData: any = {}
    dateFilter = ['本月', '上月', '本周', '上周', '全部']
    dateFilterCur = 4
    // 用于解决element - table的bug
    showDelay = false

    FanYongStatus = FanYongStatus

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }
    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }
    get
    cmtShowSuccessTime () {
        return ['daoZhangList', 'tuifangList'].includes(this.$route.query.type.toString())
    }
    get
    getLabel () {
        if (!this.cmtShowSuccessTime) {
            return '时间'
        } else if (this.$route.query.type === 'daoZhangList') {
            return '回佣时间'
        } else {
            return '退房时间'
        }
    }

    @Watch('$route')
    async watchRoute () {
        try {
            this.showDelay = true
            await this.getData()
        } catch (e) {
            throw e
        } finally {
            this.showDelay = false
        }
    }

    mounted () {
        this.getData()

        if (this.infoPos.dep) this.form.dep = this.infoPos.dep
        if (this.infoPos.group) this.form.group = this.infoPos.group
        if (this.infoPos.fxs) this.form.user_id = this.infoPos.fxs
    }

    showTable (type: string) {
        return this.$route.query.type === type
    }

    // 搜索
    clickSearch () {
        this.page = 1
        this.getData()
    }

    // 日期按钮
    dateFilterHandle (index: number, key: string) {
        // @ts-ignore
        this[key] = index
        let dateTime
        if (index === 1) {
            dateTime = {
                time_start: dataRange.getCurrentYesterday().format('yyyy-MM-dd'),
                time_end: dataRange.getCurrentYesterday().format('yyyy-MM-dd')
            }
        } else if (index === 2) {
            dateTime = {
                time_start: dataRange.getCurrentWeek()[0].format('yyyy-MM-dd'),
                time_end: dataRange.getCurrentWeek()[1].format('yyyy-MM-dd')
            }
        } else if (index === 3) {
            dateTime = {
                time_start: dataRange.getPreviousWeek()[0].format('yyyy-MM-dd'),
                time_end: dataRange.getPreviousWeek()[1].format('yyyy-MM-dd')
            }
        } else {
            dateTime = {
                time_start: '',
                time_end: ''
            }
        }
        this.form.ctime = [dateTime.time_start, dateTime.time_end]
    }

    pageChange (page: number) {
        this.page = page
        this.getData()
    }

    // 清空筛选
    resetFrom (formName: string) {
        const form = this.$refs[formName] as any
        form.resetFields()
        this.page = 1
        this.getData()
    }

    toEdit (id: number, bool: boolean) {
        let obj: any = {
            name: 'success_detail-success',
            params: {
                id: id
            }
        }
        if (bool) {
            let { href } = this.$router.resolve(obj)
            window.open(href, '_blank')
        } else {
            this.$router.push(obj)
        }
    }

    // 导出
    exportList () {
        if (this.exportData.page) {
            delete this.exportData.page
            delete this.exportData.pageSize
        }

        this.__utils.index.downloadFile({
            action_name: 'exportSaleList',
            ...this.exportData
        })
    }

    // 部门改变
    departSelect () {
        this.form.group = ''
    }

    // 组改变
    groupSelect () {
        this.form.user_id = ''
    }

    async getData () {
        const form = this.__utils.index.copyObj(this.form)

        if (form.user_id && this.__role.has(this.__role.TYPE.FXSSZ)) {
            form.dep = ''
            form.group = ''
        }

        if (form.fxs_id) {
            form.dep = ''
            form.group = ''
            form.user_id = form.fxs_id

            delete form.fxs
            delete form.fxs_id
        }

        const data = Object.assign(form, {
            type: this.$route.query.type,
            page: this.page,
            pagesize: this.pageSize,
            time_start: this.form.ctime && this.form.ctime[0] ? this.form.ctime[0] : '',
            time_end: this.form.ctime && this.form.ctime[1] ? this.form.ctime[1] : '',
            ctime: '',
            sale_time: ''
        })

        if (this.cmtShowSuccessTime) {
            data.cj_time_start = this.form.sale_time && this.form.sale_time[0] ? this.form.sale_time[0] : ''
            data.cj_time_end = this.form.sale_time && this.form.sale_time[1] ? this.form.sale_time[1] : ''
        }

        const res = await this.__apis.financial.getSaleList(data)

        res.data.list.map((v) => {
            v.ExtData.user_info && v.ExtData.user_info.map((vv: any) => {
                vv.rate = vv.rate === 0 ? 0 + '%' : (vv.rate * 100).toFixed(2) + '%'
            })
        })
        this.dataObj = res.data
        this.dataObj.show = true
        this.dataObj.dzInfo = this.dataObj.dzInfo ? [this.dataObj.dzInfo] : this.dataObj.dzInfo
        this.dataObj.total = Number(this.dataObj.total)
        this.exportData = data
    }

    // 搜索人
    async querySearchName (queryString: string, cb: any) {
        if (!queryString || queryString.length === 0) return

        const res = await this.__apis.utils.searchUser({
            fxs: 1,
            key: queryString
        })

        res.data.list.map((v: any) => {
            v.value = v.Username
        })
        cb(res.data.list)
    }

    nameSelect (item: any) {
        this.form.fxs = item.value
        this.form.fxs_id = item.Id
    }

    clearFxs () {
        this.form.fxs_id = ''
        this.form.fxs = ''
    }
}
