import { BaseRes, BaseSubmitRes, java, ListReq, ListRes, pp } from '@/api/index'

export interface userInfo7 {
    'role_name': string,
    'role': number,
    'group_name': string,
    'user_name': string,
    'department_name': string,
    'department': number,
    'group': number
}

interface fxsData {
    'id': number,
    'username': string,
    'email': string,
    'password': string,
    'password_salt': string,
    'password_md5': string,
    'ctime': string,
    'mtime': number,
    'ip': number,
    'province': number,
    'city': number,
    'deactive': number,
    'group': number,
    'role': number,
    'department': number,
    'department_name': string,
    'group_name': string,
    'role_name': string
    regular: number // 1 正式  0 试用
}

interface getFxsListReq extends ListReq {
    'user_id'?: number
    'dep_id'?: number
    'group_id'?: number
}

interface getFxsListRes extends ListRes<fxsData>{
}

interface getFxsDetailRes extends fxsData {
    mobile: string
    role: number
    sex: number
    probation_begin: string
    probation_end: string
}

interface createAndSaveFxsReq {
    'user_name': string,
    'mobile': string,
    'group_id': string | number,
    'common_dep_id': number,
    'dep_id': string | number,
    'company': string,
    'id'?: number // 编辑时必带
    role?: number // 编辑时必带
    regular?: number
    sex?: number
    probation_begin?: string
    probation_end?: string
}

interface userActiveChangeReq {
    leave_time?: number,
    'deactive': number, // 0 开启  1关闭
    'id': number
}

interface userChangeRecordReq extends ListReq {
    user_id?: number
}

interface userChangeRecordRes extends ListRes<{
    'old_role_name': string,
    'old_gro': number,
    'old_group_name': string,
    'role': number,
    'gro': number,
    'group_name': string,
    'dep_name': string,
    'dep': number,
    'operator': string,
    'role_name': string,
    'user_id': number,
    'old_role': number,
    'ctime': number,
    'id': number,
    'old_dep': number,
    'old_dep_name': string
    user_info: userInfo7
}>{}

export interface getAllPermissionsRes {
    id: number
    name: string
    pid: number
    client_id: client_id
    status: 1 | 2 // 0 禁用
}

export interface getAllRoleRes {
    id: number
    name: string
    permissions: number[]
}

export interface saveRolePermissionReq {
    role_id: number
    permissions_ids: number[]
}

export type client_id = 1 | 2 // 1pc 2xcx

interface getUserPermissionsRes {
    role_permissions: number[]
    user_permissions: number[]
}

export interface saveUserPermissionsReq {
    user_id: number
    permissions_ids: number[]
}

interface permissionsManageListReq extends ListReq {
    pid: number
    client_id: number
    service: number
}

interface permissionsManageListRes extends ListRes<{
    pid: number
    id: number
    name: string
    path: string
    client_id: number
    service: number
    status: number
    mtime: number
}> {}

export interface permissionsTypeRes {
    client: { [index: number]: string }
    service: permissionsTypeRes['client']
    status: permissionsTypeRes['client']
}

interface savePermissionsReq extends Omit<permissionsManageListRes['list'][0], 'mtime' | 'id'> {
    id?: number
}

export interface permissionsStatusReq {
    status: 1 | 2
    id: number
}

interface getEmployeeListReq extends ListReq {

}

interface editEmployeeInfoReq {
    'user_name': string
    'mobile': string
    'company': string
    'dep': number
    'employee_id': number
    leave_time?: number
    'user_list': {
        'fxs_dep': number
        'role_id': number
        'group_id': number
        'user_id': number
        'open_id': string
        'user_name': string
        'max_take_count': number
        'head_img': string
        'nick_name': string,
        'employee_bind': boolean
        'un_bind_open_id': boolean
        de_active: number
    }[]
}

interface createEmployeeReq {
    mobile: string
    user_name:string
    company:string
    common_dep:number
    fxs_dep:number
    group_id:number
    role_id: number
    project_roles: {
        project_id: number
        role_ids: number[]
    }[]
}

interface getEmployeeBindUserListRes {
    id: number
    name: string
    mobile:string
    company:string
    dep: number
    leave_time: number
    user_list:{
        dep_id:number
        role_id:number
        group_id:number
        user_id:number
        open_id:string
        user_name:string
        max_take_count:number
        head_img:string
        nick_name:string
        employee_bind:boolean
        de_active: number
        project_roles: {
            project_id: number
            role_ids: number[]
        }[]
    }[]
}

type getAdminRoleListRes = {
    'name': string
    'id': number
    'status': number
}[]

export interface getEmployeeListRes extends ListRes<{
    'name': string
    'company': string
    'id': number
    'user_list': {
        'role': string
        'last_login_time': number
        'user_id': number
        'user_name': string
        'register_time': number
        'group': string
        de_active: number
    }[],
    'dep': number
}>{}

interface getGroupListReq extends ListReq {}

interface getGroupListRes extends ListRes<{
    ctime: number
    dep_id: number
    end_time: number
    id: number
    is_pxb: number
    mtime: number
    user_info: userInfo7
    name: string
    start_time: number
    status: 0 | 1
}> {}

export interface disableGroupReq {
    group_id: number
    close_date: string
}

interface getGroupAndLeaderListRes {
    is_pxb: number
    dep: number
    group_name: string
    leader_list: {
        id: number
        action_time: number
        end_time: number
        leader_user_id: number
        start_time: number,
        user_info: userInfo7
    }[]
    start_time: number
    leader_user_id: number
}

interface editGroupReq {
    group_id: number
    group_name: string
    start_time: number
    is_pxb: number
}

interface createGroupReq {
    group_name: string
    is_pxb: number
    dep_id: number
    start_time: number
    leader_user_id: number
}

interface editGroupLeaderReq {
    group_id: number
    leader_list: {
        id: number
        start_time: number
        end_time: number
        leader_user_id: number
        status: number
    }[]
}

interface getPermissionProjectsRes {
    description: string
    id: number
    project_name: string
}

interface getPermissionProjectsRolesRes {
    id: number
    name: string
    permission_ids: number[]
    project_id: number
}

export default class ApiSystems {
    // 修改队长信息
    editGroupLeader (data: editGroupLeaderReq) {
        return java.post('/admin/fxs/group/action/editGroupLeader', data)
    }

    //  创建战队
    createGroup (data: createGroupReq) {
        return java.post('/admin/fxs/group/action/createGroup', data)
    }

    // 修改战队信息
    editGroup (data: editGroupReq) {
        return java.post('/admin/fxs/group/action/editGroup', data)
    }

    // 战队信息和队长记录
    getGroupAndLeaderList (group_id: number): BaseRes<getGroupAndLeaderListRes> {
        return java.post('/admin/fxs/group/action/getGroupAndLeaderList', {
            group_id
        })
    }

    // 禁用，启用战队
    disableGroup (data: disableGroupReq) {
        return java.post('/admin/fxs/group/action/disableGroup', data)
    }

    // 战队列表
    getGroupList (data: getGroupListReq): BaseRes<getGroupListRes> {
        return java.post('/admin/fxs/group/action/getGroupList', data)
    }

    // 设置权限状态
    permissionsStatus (data: permissionsStatusReq) {
        return java.post('/admin/permissions/module/set/permissions/status', data)
    }

    // 保存权限
    savePermissions (data: savePermissionsReq) {
        return java.post('/admin/permissions/module/permissions/save', data)
    }

    // 获取client，service，status类型对应内容
    permissionsType (): BaseRes<permissionsTypeRes> {
        return java.post('/admin/permissions/module/permissions/type', {})
    }

    // 获取权限列表
    permissionsManageList (data: permissionsManageListReq): BaseRes<permissionsManageListRes> {
        return java.post('/admin/permissions/module/permissions/manage/list', data)
    }

    // 保存用户权限
    saveUserPermissions (data: saveUserPermissionsReq) {
        return java.post('/admin/permissions/module/user/permissions/save', data)
    }

    // 获取用户权限
    getUserPermissions (user_id: number): BaseRes<getUserPermissionsRes> {
        return java.post('/admin/permissions/module/user/permissions/list', {
            user_id
        })
    }

    // 保存角色权限
    saveRolePermission (data: saveRolePermissionReq) {
        return java.post('/admin/permissions/module/role/permissions/save', data)
    }
    // 获取所有角色
    getAllRole (): BaseRes<getAllRoleRes[]> {
        return java.post('/admin/permissions/module/user/role/type', {
        })
    }

    // 获取所有权限
    getAllPermissions (client_id?: client_id): BaseRes<getAllPermissionsRes[]> {
        return java.post('/admin/permissions/module/permissions/list', {
            client_id
        })
    }

    // 获取角色权限模块
    getPermissionsModules (client_id?: client_id): BaseRes<getAllPermissionsRes[]> {
        return java.post('/admin/permissions/module/permissionsModules/list', {
            client_id
        })
    }

    getFxsList (data: getFxsListReq): BaseRes<getFxsListRes> {
        return java.post('/admin/user/getFxsList', {
            ...data
        })
    }

    getFxsDetail (id: number): BaseRes<getFxsDetailRes> {
        return java.post('/admin/user/getFxsDetail', {
            id: id
        })
    }

    createAndSaveFxs (data: createAndSaveFxsReq): BaseSubmitRes {
        return java.post('/admin/user/createAndSaveFxs', {
            ...data
        })
    }

    userActiveChange (data: userActiveChangeReq): BaseSubmitRes {
        return java.post('/admin/user/userActiveChange', {
            ...data
        })
    }

    userChangeRecord (data: userChangeRecordReq): BaseRes<userChangeRecordRes> {
        return java.post('/admin/user/userChangeRecord', {
            ...data
        })
    }

    // 员工列表
    getEmployeeList (data: getEmployeeListReq): BaseRes<getEmployeeListRes> {
        return java.post('/admin/employee/action/getEmployeeList', data)
    }

    // 创建员工
    createEmployee (data: createEmployeeReq): BaseSubmitRes {
        return java.post('/admin/employee/action/createEmployee', data)
    }

    // 编辑员工信息
    editEmployeeInfo (data: editEmployeeInfoReq): BaseSubmitRes {
        return java.post('/admin/employee/action/editEmployeeInfo', data)
    }

    // 获取员工和关联用户信息
    getEmployeeBindUserList (employee_id: number): BaseRes<getEmployeeBindUserListRes> {
        return java.post('/admin/employee/action/getEmployeeBindUserList', {
            employee_id
        })
    }

    // 获取所有角色
    getAdminRoleList (): BaseRes<getAdminRoleListRes> {
        return java.post('/common/role/data/getAdminRoleList', {})
    }

    // 获取所有项目
    getPermissionProjects (): BaseRes<getPermissionProjectsRes[]> {
        return java.post('/admin/permission/list/projects', { }, {
            headers: {
                'platform': 'permission' // 权限跨系统接口
            }
        })
    }

    // 查询项目下所有角色
    getPermissionProjectsRoles (project_id: number): BaseRes<getPermissionProjectsRolesRes[]> {
        return java.post('/admin/permission/list/roles', { project_id }, {
            headers: {
                'platform': 'permission'
            }
        })
    }

    getServerTime (): Promise<{time: string, timestamp: number }> {
        return pp.post('/time/now', {})
    }
}
