import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import inputSelect from '@/components/inputSelect/index.vue'
import { searchUserRes } from '@/api/utils'
import { LabelValueArray } from '@/types'

@Component({
    components: {
        inputSelect
    }
})
export default class InputSelectSearch extends Vue {
    @Prop({
        type: Boolean,
        default: true
    })
    remote !: boolean

    @Prop({
        type: Boolean,
        default: false
    })
    clearable !: boolean

    @Prop({
        type: Boolean,
        default: true
    })
    chooseYourSelf !: boolean // 是否可以选自己，默认true可以选自己

    @Prop({
        type: Boolean,
        default: false
    })
    isUserQuitFilter !: boolean // type: user 下是否离职过滤

    @Prop({
        type: Boolean,
        default: false
    })
    valueIsLabel !: boolean

    @Prop({
        type: Boolean,
        default: false
    })
    disabled !: boolean

    @Prop({
        type: String,
        default: 'user'
    })
    type !: string

    @Prop({
        type: Array,
        default: () => {
            return []
        }
    })
    source !: LabelValueArray

    @Prop({
        type: String,
        default: 'small'
    })
    size !: string

    @Prop({
        type: [String, Number],
        default: ''
    })
    value !: string

    @Prop({
        type: String,
        default: '请输入'
    })
    placeholder !: string

    @Prop({
        type: Boolean,
        default: false
    })
    startSearch !: boolean

    @Prop({
        type: [String, Number],
        default: 1
    })
    fxs !: string | number

    word = this.value

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    get
    fullName () {
        return this.__store.state.success_detail.all_channel.map((a: any) => {
            return {
                label: a.full_name,
                value: a.full_name_new,
                showName: a.full_name_new,
                id: a.id
            }
        })
    }

    @Watch('value')
    watchValue (newVal: string) {
        this.word = newVal
    }

    @Watch('word')
    watchWorld (newVal: string) {
        this.$emit('input', newVal)
    }

    // 搜索数据
    async search (text: string, cb: any) {
        let data: any = []

        if (this.type === 'house') {
            data = await this.__apis.utils.searchHouse({
                term: text
            })

            cb(data.data)
        } else if (this.type === 'user' || this.type === 'group_user') {
            const { data: res } = await this.__apis.utils.searchUser({
                key: text,
                fxs: this.fxs
            })

            data = res.list.map(a => {
                return {
                    value: this.valueIsLabel ? a.Username : a.Id,
                    label: a.Username,
                    disabled: !this.chooseYourSelf ? this.userInfo.user_id === a.Id : false,
                    ...a
                }
            })

            if (this.isUserQuitFilter) {
                data = data.filter((a: any) => !a.label.includes('离职'))
            }

            if (this.type === 'group_user') {
                data = data.filter((a: searchUserRes['list'][0]) => {
                    return a.roleId === 10
                })
            }

            cb(data)
        } else if (this.type === 'channel') {
            cb(this.fullName.filter((a: any) => a.label.includes(text)))
        } else if (this.type === 'employee') {
            const { data: res } = await this.__apis.utils.searchEmployee(text)

            data = res.list.map(a => {
                return {
                    value: a.Id,
                    label: a.Username,
                    ...a
                }
            })

            cb(data)
        } else if (this.type === 'dep') {
            this.__utils.index.searchDep(text, (data) => {
                cb(data)
            })
        } else if (this.type === 'group') {
            this.__utils.index.searchGroup(text, (data) => {
                cb(data)
            })
        } else if (this.type === 'custom') {
            cb(this.source.filter(a => a.label.includes(text)))
        }
    }

    select (obj: any) {
        this.$emit('select', obj)
    }
}
