import { Component, Prop, Vue } from 'vue-property-decorator'
import { Notification } from 'element-ui'
import inputSelectSearchComponent from '@/components/inputSelectSearch/index.vue'
import { SuccessDetailDispatchType } from '@/store/successDetail'

interface options {
    show: boolean,
    remark: string,
    money: string,
    date: string,
    user_id: number,
    id: number
}

@Component({
    components: {
        inputSelectSearchComponent
    }
})
export default class SuccessDetailCommission extends Vue {
    @Prop({
        type: Object,
        default: {
            show: false,
            money: '',
            remark: '',
            date: '',
            id: undefined,
            user_id: undefined
        }
    })
    options !: options

    get
    cmtBtnSaveEnabled () {
        return !!(this.options.date && this.options.money !== undefined && this.options.user_id !== undefined)
    }

    closeSelf () {
        this.options.show = false
    }

    async save () {
        await this.__apis.financial.saveSaleBonusRecord({
            sale_id: +this.$route.params.id,
            remark: this.options.remark,
            money: this.options.money,
            id: this.options.id,
            send_time: this.options.date,
            user_id: this.options.user_id
        })

        // 保存成功
        Notification.success('操作')
        this.__store.dispatch(SuccessDetailDispatchType.setAllData, +this.$route.params.id)

        this.closeSelf()
    }
}
