import { Component, Vue } from 'vue-property-decorator'

@Component
export default class SaleManQkIndexPage extends Vue {
    id = ''

    created () {
        this.id = this.$route ? this.$route.params.id : ''
    }
}
