import { Component, Vue } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import inputDateRange from '@/components/filters/inputDateRange/index.vue'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import { routerBaseUrl } from '@/router'

@Component({
    components: {
        FilterComponent,
        inputDateRange,
        FilterBusInputSelectComponent
    }
})
export default class IndexPage extends Vue {
    filter: any = {}
    dataObj: any = {
        list: [],
        total: 0
    }
    page = 1
    pageSize = 20

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    mounted () {
        this.getTiChengRecordList()
    }

    // 筛选
    filterChange (data: any) {
        this.page = 1
        this.filter = data
        this.getTiChengRecordList()
    }

    pageChange (page: number) {
        this.page = page
        this.getTiChengRecordList()
    }

    queryGroupSearch (queryString: string, cb: any) {
        this.__utils.index.searchGroup(queryString, cb)
    }

    toCjRecord (saleId: string) {
        open(`${routerBaseUrl}/financial/success_detail/success/${saleId}`, '_blank')
    }

    async getTiChengRecordList () {
        let filter = this.filter || {}
        const { data } = await this.__apis.gzManage.getBasicSaleBonusRecord({
            page: this.page,
            pagesize: this.pageSize,
            ...filter,
            time_start: filter.date && filter.date[0],
            time_end: filter.date && filter.date[1]
        })

        data.list.map((v: any) => {
            v.SendTime = new Date(v.SendTime).format('yyyy-MM-dd')
        })
        data.statistic_res = data.statistic_res || this.dataObj.statistic_res

        this.dataObj = data
        if (this.dataObj.statistic_res) {
            if (this.dataObj.list.length) {
                this.dataObj.list.unshift({
                    SendTime: '总计',
                    Money: this.dataObj.statistic_res.SendMoney,
                    ...this.dataObj.statistic_res
                })
            }
        }
    }
}
