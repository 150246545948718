import { Component, Vue } from 'vue-property-decorator'
import OverviewPage from '@/views/home/overview/index.vue'
import RecordStatistics from '@/views/home/recordStatistics/index.vue'
import SaleManOverviewPage from '@/views/home/saleManOverview/index.vue'
import FollowUpAnalysis from '@/views/home/followUpAnalysis/index.vue'
import TeamProfit from '@/views/home/teamProfit/index.vue'

@Component({
    components: {
        OverviewPage,
        RecordStatistics,
        SaleManOverviewPage,
        FollowUpAnalysis,
        TeamProfit
    }
})
export default class HomePage extends Vue {
    pane = 'first'
}
