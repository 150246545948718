import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class KnowledgeIndexPage extends Vue {
    keywords = ''
    searchList: any = {
        list: []
    }
    inputFocus = false
    searchVisible = false
    dataObj = {}
    total = 0
    page = 1
    pageSize = 20

    get
    getkeywords () {
        return this.$route.query.keywords as string
    }

    @Watch('$route')
    watchRoute (to: any) {
        if (to.name === '/knowledge') {
            if (to.query.keywords) {
                this.keywords = to.query.keywords
                this.searchVisible = true
                this.searchSubmit()
            } else {
                this.keywords = ''
                this.searchVisible = false
                this.getData()
            }
        }
    }

    mounted () {
        this.getData()
        if (this.getkeywords) {
            this.keywords = this.getkeywords
            this.searchVisible = true
            this.searchSubmit()
        }
    }

    // 请求数据
    async getData () {
        const res = await this.__apis.knowledge.getKnowledgeLibList()

        let datas = res.data
        datas.list.map(v => {
            v.DirSort = v.DirSort ? JSON.parse(v.DirSort) : []
        })
        this.dataObj = datas
    }

    // 分页改变
    pageChange (page: number) {
        this.page = page
    }

    // 搜索匹配
    async querySearchAsync (queryString: string, cb: any) {
        if (!queryString || queryString.length === 0) return

        const res = await this.__apis.utils.searchDoc(queryString)

        res.data.list.map((v: any) => {
            v.label = v.Title
            v.value = v.Title
        })
        cb(res.data.list)
    }

    // 搜索匹配点击
    handleSearchSelect () {
        this.searchSubmit()
    }

    // 清空
    clearWords () {
        this.searchVisible = false
        this.searchList = {}
    }

    // 搜索
    async searchSubmit () {
        if (!this.keywords || this.keywords.length === 0) return

        const res = await this.__apis.utils.searchDoc(this.keywords)

        let datas = res.data
        this.searchVisible = true
        this.total = datas.total
        this.searchList = datas
        this.$router.push({
            name: '/knowledge',
            query: {
                keywords: this.keywords
            }
        })
    }

    // 添加知识库
    addKu () {
        this.$router.push({
            name: '/knowledge/addEditKu'
        })
    }

    async getLibDoc (obj: any) {
        const res = await this.__apis.knowledge.getKnowledgeLibDetail(obj.Id)

        let datas = res.data
        let querys: any = {
            id: obj.Id
        }
        if (datas.FirstDirId) querys.cate_id = datas.FirstDirId
        if (datas.FirstDocId) querys.doc_id = datas.FirstDocId

        this.$router.push({
            name: '/knowledge/doc',
            query: querys
        })
    }

    // 知识库点击
    clickKu (obj: any) {
        this.getLibDoc(obj)
    }

    goDoc (obj: any) {
        this.$router.push({
            name: '/knowledge/doc',
            query: {
                id: obj.LibId,
                cate_id: obj.DirId,
                doc_id: obj.Id
            }
        })
    }

    goSearch () {
        this.$router.push({
            name: '/knowledge/searchDoc',
            query: {}
        })
    }
}
