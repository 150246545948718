import { Component, Vue, Watch } from 'vue-property-decorator'
import { __role } from '@/role'
import NavTab from '@/components/NavTab/index.vue'
import qmPermission from 'qm-plugin-admin-permission'
import router from '@/router'

const items = [
    {
        name: '分析师外呼记录',
        route: '/phone-record',
        authKey: __role.permissions.ShowFxsPhoneRecordPage
    },
    {
        name: '机器外呼记录',
        route: '/phone-record/robot',
        authKey: __role.permissions.ShowRobotPhoneRecordPage
    }
]
qmPermission.filter(items)

@Component({
    components: {
        NavTab
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (items[0] && to.path !== items[0].route) {
                vm.$router.push(items[0].route)
            }
        })
    }
})
export default class PhoneRecordPage extends Vue {
    @Watch('$route')
    watchRoute (to: any) {
        if (to.fullPath === '/phone-record' && items[0] && to.path !== items[0].route) {
            router.replace(items[0] ? items[0].route : '')
        }
    }

    tabs = {
        items: items
    }
}
