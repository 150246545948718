import { Component, Vue, Prop } from 'vue-property-decorator'

export interface editMoneyData {
    id: string
    [key: string]: string
    allMoney: string,
    removeMoney: string,
    department: string,
    group: string,
    remark: string,
    fxs: string,
    month: string
    userId: string
}

@Component
export default class EditMoney extends Vue {
    @Prop({
        default: '',
        type: String
    })
    type !: string
    @Prop({
        default: false,
        type: Boolean
    })
    show !: boolean
    @Prop({
        default () {
            return {
                id: '',
                allMoney: '',
                removeMoney: '',
                department: '',
                group: '',
                remark: '',
                fxs: ''
            }
        },
        type: Object
    })
    item !: editMoneyData

    form: editMoneyData = {
        id: '',
        allMoney: '',
        removeMoney: '',
        department: '',
        group: '',
        remark: '',
        fxs: '',
        month: '',
        userId: ''
    }

    get
    cmtMonthDisabled () {
        return this.type === '1'
    }

    get
    cmtGetMoney () {
        return Number(this.form.allMoney) - Number(this.form.removeMoney)
    }

    get
    cmtBtnEnterDisabled () {
        return !this.form.allMoney.trim() || !this.form.month
    }

    created () {
        this.form = this.__utils.index.copyObj(this.item)
    }

    limitMoneyInput (e: string, key: string, hasMinus = false) {
        let minus = e[0] === '-' && hasMinus ? '-' : ''

        this.form[key] = minus + this.__utils.index.limitMoneyByInput(e, 1000000)
    }

    async enter () {
        await this.__apis.wallet.saveFinanceSendRecord({
            id: this.form.id,
            type: this.type,
            must_money: this.form.allMoney,
            deduct_money: this.form.removeMoney,
            remark: this.form.remark,
            action_time: this.form.month
        })

        this.$emit('enter')
        this.close()
    }

    close () {
        this.$emit('update:show', false)
    }
}
