import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { EventBus, EventNames } from '@/util/bus'

interface list {
    id: number
    title: string
    content: string
    value: any
}

@Component
export default class FiltersIndex extends Vue {
    list: list[] = []

    @Watch('list', { deep: true })
    watchList () {
        let data = {}

        this.list.forEach(a => {
            data = {
                ...data,
                ...a.value
            }
        })

        this.$emit('filterChange', data)
    }

    @Prop({
        type: String,
        default: ''
    })
    componentId !: string

    created () {
        EventBus.$on(EventNames.filterChange + this.componentId, this.change)
    }

    getContent (item: list) {
        return Array.isArray(item.content) ? item.content.join(',') : item.content
    }

    destroyed () {
        EventBus.$off(EventNames.filterChange + this.componentId, this.change)
    }

    change (data: list) {
        const item = this.list.filter(a => {
            return a.title === data.title
        })

        if (item.length) {
            item[0].value = data.value
            item[0].content = data.content
        } else {
            this.list.push(data)
        }
    }

    // 清除某个标签
    clearTag (index: number) {
        const item = this.list.splice(index, 1)
        EventBus.$emit(EventNames.clearFilter, item[0].id)
    }

    // 清除全部标签
    clearAllTag () {
        let len = this.list.length

        for (let i = 0; i < len; i++) {
            this.clearTag(0)
        }
    }
}
