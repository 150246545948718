import { __store } from '@/store'
import { __utils } from '@/util'

export default function () {
    return {
        // 成交管理 / 成交档案
        grow_file: __store.state.user.normalData.isDuty,
        // 成交管理 / 新训班
        grow_xxb: Boolean(__utils.index.getGroupInfoById(__store.state.user.userInfo.group).is_pxb)
    }
}
