import { Component, Prop, Vue } from 'vue-property-decorator'
import SelectComponent from '@/components/filters/select/index.vue'
import { LabelValueArray } from '@/types'

@Component({
    components: {
        SelectComponent
    }
})
export default class FilterBusinessSelect extends Vue {
    value = ''

    @Prop({
        type: String,
        default: '请输入筛选条件'
    })
    title !: string

    @Prop({
        type: String,
        default: ''
    })
    propName !: string

    @Prop({
        type: String,
        default: ''
    })
    componentId !: string

    @Prop({
        type: String,
        default: '请输入'
    })
    placeholder !: string

    @Prop({
        type: String,
        default: 'user'
    })
    type !: string

    @Prop({
        type: [String, Number],
        default: 1
    })
    fxs !: string | number

    @Prop({
        type: Boolean,
        default: false
    })
    startSearch !: boolean

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    // 搜索数据
    async search (text: string, cb: any) {
        let res: LabelValueArray = []

        if (this.type === 'channel') {
            res = this.__store.state.success_detail.all_channel.filter(a => a.full_name.includes(text)).map(a => {
                return {
                    label: a.full_name,
                    value: a.id
                }
            })

            cb(res)
        } else if (this.type === 'house') {
            const { data } = await this.__apis.utils.searchHouse({
                term: text
            })
            res = data.map(a => {
                return {
                    value: a.id,
                    label: a.label
                }
            })

            cb(res)
        } else if (this.type === 'user') {
            const { data } = await this.__apis.utils.searchUser({
                key: text,
                fxs: this.fxs
            })
            res = data.list.map(a => {
                return {
                    value: a.Id,
                    label: a.Username
                }
            })

            cb(res)
        } else if (this.type === 'dep') {
            this.__utils.index.searchDep(text, cb)
        } else if (this.type === 'group') {
            this.__utils.index.searchGroup(text, cb)
        } else if (this.type === 'commonDep') {
            this.__utils.index.searchComDep(text, cb)
        }
    }
}
