import { Component, Prop, Vue } from 'vue-property-decorator'
import { RobotCallRecordItem, GetRobotCallRecordChatType } from '@/api/phoneRecord'

export type CallDetailItem = {
    name: string
    phone: string
    level: string
    callId: number
    callDuration: number
    url: string
    chats: RobotCallRecordItem['call_detail_list']
}

@Component
export default class RobotCallDetailComponent extends Vue {
    @Prop({
        default: false,
        type: Boolean
    })
    show !: boolean

    @Prop({
        default () {
            return {}
        },
        type: Object,
        required: true
    })
    item !: CallDetailItem

    GetRobotCallRecordChatType = GetRobotCallRecordChatType

    close () {
        this.$emit('update:show', false)
    }
}
