import { Component, Vue } from 'vue-property-decorator'
import mixin from '../mixin'
import { customerListRes } from '@/api/customer'

@Component({
    components: {
    },
    mixins: [mixin]
})
export default class CustomerBuyPage extends Vue {
    dataObj: customerListRes = {
        res: {}
    } as any
    page = 1
    pageSize = 50
    total = 0
    sortType = ''
    sortMode = ''

    mounted () {
        this.getData()
    }

    async getData () {
        const { data } = await this.__apis.customer.getCustomerList({
            type: 'wait_buy',
            sort_field: this.sortType,
            sort_mode: this.sortMode,
            page: this.page,
            pagesize: this.pageSize
        })

        this.dataObj = data
        this.total = data.res.total
    }
}
