import { Component, Vue, Prop } from 'vue-property-decorator'
import { ElLoadingComponent } from 'element-ui/types/loading'

export interface editMoneyData {
    id: string
    allMoney: string
    sendMoney: string
    shouldMoney: string
    department: string
    group: string
    remark: string
}

@Component
export default class EditMoney extends Vue {
    @Prop({
        default: false,
        type: Boolean
    })
    show !: boolean
    @Prop({
        default () {
            return {
                id: '',
                allMoney: '',
                sendMoney: '',
                shouldMoney: '',
                department: '',
                group: '',
                remark: ''
            }
        },
        type: Object
    })
    item !: editMoneyData

    form: editMoneyData = {} as any

    get
    cmtBtnEnterDisabled () {
        return !this.form.sendMoney.trim() || !this.form.remark.trim() || !Number(this.form.sendMoney.trim())
    }

    created () {
        this.form = this.__utils.index.copyObj(this.item)
    }

    limitMoneyInput (e: string, key: keyof editMoneyData, hasMinus = false) {
        let minus = e[0] === '-' && hasMinus ? '-' : ''

        this.form[key] = minus + this.__utils.index.limitMoneyByInput(e, 1000000)
    }

    async enter () {
        if (Number(this.form.sendMoney) > Number(this.form.shouldMoney)) {
            this.$message.error('发放金额不能大于剩余金额')
            return
        }

        let loading !: ElLoadingComponent

        try {
            await this.$confirm(`请确认是否对 <span style="color: #408AF0;">${this.form.group}</span> 发放 <br> <span style="color: #262626;font-size: 20px;">${this.form.sendMoney}</span><span style="margin-left: 6px;font-size: 12px;">元</span>`, '发放确认', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })

            loading = this.$loading({
                lock: true,
                text: '发放中...',
                spinner: 'el-icon-loading',
                customClass: 'm_loading'
            })

            await this.__apis.wallet.doSendGroupWalletMoney({
                group_id: +this.form.id,
                money: +this.form.sendMoney,
                remark: this.form.remark
            })

            await this.__utils.index.sleep(2000)

            this.$message.success('发放成功')
            this.$emit('enter')
            this.close()
        } catch (e) {
            console.log(e)
        } finally {
            loading && loading.close()
        }
    }

    close () {
        this.$emit('update:show', false)
    }
}
