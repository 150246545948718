import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import { AnswerStatus, QuestionType, SubjectType } from '@/util/const'
import { UserCommitTypes } from '@/store/user'

interface TableItem {
    id: number,
    taskId: number
    date: string,
    result: number,
    questionType: string,
    subjectType: string
    title: string
}

const subjectType = [
    {
        label: '全部',
        value: 0
    },
    ...SubjectType
]

@Component({
    components: {
    }
})
export default class QuestionBankAnswerRecordPage extends Vue {
    form = {
        subjectType: null,
        date: [],
        isError: false
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0
    }

    subjectType = subjectType

    created () {
        this.getData()
    }

    async getData () {
        const filter = this.form
        const { data } = await this.__apis.activity.questionAnswerPage({
            subject_id: filter.subjectType || undefined,
            do_right: filter.isError === false ? undefined : !filter.isError,
            dateQrl: filter.date && filter.date.length ? {
                'query_start_time': `${filter.date[0]} 00:00:00`,
                'query_end_time': `${filter.date[1]} 23:59:59`
            } : undefined,
            page: this.tableData.page,
            pagesize: this.tableData.pageSize
        })
        this.tableData.data = data.list.map(a => {
            return {
                id: a.id,
                taskId: a.task_id,
                date: this.__utils.index.formatDate(new Date(a.create_time), 'YYYY-MM-DD'),
                result: +a.do_right,
                questionType: this.__utils.index.getLabelByValue(QuestionType, a.question_type),
                subjectType: this.__utils.index.getLabelByValue(SubjectType, a.subject_id),
                title: a.title
            }
        })
        this.tableData.total = data.total
    }

    objectSpanMethod ({ column, rowIndex }: any) {
        let data:{name: any}[] = []
        if (column.property === 'date') {
            data = this.__utils.index.merageTableArr(this.tableData.data.map((v) => {
                return {
                    name: `${v.taskId}-${v.date}`
                }
            }))
            return this.__utils.index.returnTableSpan(data, rowIndex)
        }
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getData()
    }

    resetFilter () {
        const form = this.$refs['form'] as any
        form.resetFields()
        this.tableCurrentChange(1)
    }

    selectAlwaysError () {
        this.form.isError = !this.form.isError
    }

    async seeDetail (item: TableItem) {
        const currentDate = this.__utils.index.formatDate(new Date(), 'YYYY-MM-DD')
        if (item.date === currentDate) {
            const { data: { taskAnswerPo } } = await this.__apis.activity.questionTaskAnswer(item.taskId)
            if (taskAnswerPo && taskAnswerPo.status === AnswerStatus.no) {
                this.__store.commit(UserCommitTypes.setShowQuestion, true)
            } else {
                this.__store.commit(UserCommitTypes.setSeeQuestionInfo, {
                    show: true,
                    item: {
                        taskId: item.taskId,
                        id: item.id
                    }
                })
            }
        } else {
            this.__store.commit(UserCommitTypes.setSeeQuestionInfo, {
                show: true,
                item: {
                    taskId: item.taskId,
                    id: item.id
                }
            })
        }
    }
}
