import { Component, Vue, Prop } from 'vue-property-decorator'
import StarBoxComponent from '@/components/star-box/index.vue'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'
import callAudioComponent from '@/views/customer/layout/customerInfo/callAudio/index.vue'

interface CustomerInfo {
    id: number,
    name: string,
    mobile: string,
    mobileSeeBtn: boolean,
    level: string,
    impGroup: string | number,
    registerTime: string,
    sysTags: string[],
    typeArr: string[],
    channel: string,
    keyWords: string,
    beLong: string
    followList: any[]
    callRecordAudioUrl: string
    showInvalidApplyButton: boolean,
    showClickInvalidApplyButton: boolean
}

@Component({
    components: {
        StarBoxComponent,
        inputSelectSearch,
        callAudioComponent
    }
})
export default class EditCustomerLayerComponent extends Vue {
    @Prop({
        default: false
    })
    visible!: boolean

    @Prop({
        default: 0
    })
    customer_id!: number

    @Prop({
        default: false
    })
    isShare!: boolean

    editCustomerInfo: {
        name: string,
        follow: string,
        house_name: string,
        house_id: number,
        level: string,
        impGroup: number | string,
        sysTags: string[]
        nextFollowTime: string
    } = {
        name: '',
        follow: '',
        house_name: '',
        house_id: 0,
        level: '',
        impGroup: '',
        sysTags: [],
        nextFollowTime: ''
    }

    intention = {
        value: 1,
        check: false
    }

    customerInfo: CustomerInfo = {
        id: 0,
        name: '',
        mobile: '',
        mobileSeeBtn: false,
        level: '',
        impGroup: '',
        registerTime: '',
        sysTags: [],
        typeArr: [],
        channel: '',
        keyWords: '',
        beLong: '',
        followList: [],
        callRecordAudioUrl: '',
        showInvalidApplyButton: false,
        showClickInvalidApplyButton: false
    }

    pickerOptions = {
        disabledDate (time:any) {
            let _now = Date.now()
            let dayTemp = 24 * 60 * 60 * 1000
            return time.getTime() < _now - dayTemp
        }
    }

    loading = false

    get
    customerTags () {
        return this.__store.state.user.customerTags
    }

    get
    customerTagsArr () {
        const data = this.__utils.index.copyObj(this.__store.state.user.customerTags)
        Object.keys(data).map(v => {
            data[v].text = data[v].text.includes(':') ? data[v].text.split(':')[0] : data[v].text
        })
        if (this.customerInfo.showInvalidApplyButton && this.customerInfo.showClickInvalidApplyButton) {
            const res = [] as any
            const sort = ['0', 'A', 'B', 'C', 'D', '99', 'X', 'Y', 'Z']
            sort.forEach((v, i) => {
                if (v in data) {
                    data[v].text = data[v].text.includes(':') ? data[v].text.split(':')[0] : data[v].text
                    res.push(data[v])
                } else if (v === '99') {
                    res.push({
                        text: '无效',
                        valid: true,
                        value: '99'
                    })
                }
            })
            return res
        }
        return data
    }

    get
    impt () {
        return this.__store.state.user.impt
    }

    get
    sysTags () {
        return this.__store.state.user.sysTags
    }

    fadeOut = false
    close () {
        this.fadeOut = true
        setTimeout(() => {
            this.fadeOut = false
            this.$emit('update:visible', false)
        }, 200)
    }

    saveLoading = false
    noLevelWarn = false
    async save () {
        const follow = this.editCustomerInfo.follow.trim()

        if (!this.isShare && (!this.editCustomerInfo.level || this.editCustomerInfo.level === '0')) {
            this.noLevelWarn = true
            return this.$notify({
                title: '警告',
                message: '请选择客户级别！',
                type: 'warning'
            })
        }

        if (this.__utils.index.limitRepeatClick()) return
        try {
            this.saveLoading = true
            const customerInfo = this.customerInfo
            const editCustomerInfo = this.editCustomerInfo

            if (editCustomerInfo.level && editCustomerInfo.level === '99') {
                await this.__apis.customer.customerInvalidApply({
                    customer_id: customerInfo.id,
                    apply_reason: ''
                })
                this.editCustomerInfo.level = ''
            }

            await this.__apis.customer.infoSaveCustomer({
                id: customerInfo.id,
                name: editCustomerInfo.name,
                // mobile: customerInfo.mobile,
                // customer_house: editCustomerInfo.house_id,
                impt_group: editCustomerInfo.impGroup ? +editCustomerInfo.impGroup : undefined,
                tag: !editCustomerInfo.level || editCustomerInfo.level === '99' ? '' : editCustomerInfo.level,
                sys_tags: editCustomerInfo.sysTags.join(','),
                next_follow_time: editCustomerInfo.nextFollowTime
            })

            if (follow) {
                await this.__apis.customer.saveCustomerLog({
                    id: customerInfo.id,
                    log: follow,
                    log_mark: this.intention.value
                })
            }

            this.$notify({
                title: '成功',
                message: '保存成功！',
                type: 'success'
            })

            this.$emit('save')

            this.close()
        } finally {
            this.__utils.index.restoreRepeatClick()
            this.saveLoading = false
        }
    }

    intentionChange () {
        const text = '未接通/空号/无效通话'
        if (this.intention.value === 0) {
            this.editCustomerInfo.follow = `${text} ${this.editCustomerInfo.follow}`
        } else {
            this.editCustomerInfo.follow = this.editCustomerInfo.follow.replace(`${text} `, '')
        }
    }

    selectSysTags (value: string) {
        const data = this.editCustomerInfo.sysTags
        if (data.includes(value)) {
            this.editCustomerInfo.sysTags = data.filter(a => a !== value)
        } else {
            this.editCustomerInfo.sysTags.push(value)
        }
    }

    async seePhone () {
        const item = this.customerInfo
        if (!item.mobile.includes('*')) return
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })
        try {
            const { data: { mobile } } = await this.__apis.customer.showPubCustomerMobile(item.id)
            if (mobile) {
                item.mobile = mobile
            }
        } finally {
            loading.close()
        }
    }

    // 过滤类型
    typeArrayFilter (data: CustomerInfo) {
        if (!data.typeArr) return []
        const arr = data.typeArr.filter(a => !['七鱼机器人外呼'].includes(a))
        return arr.length ? arr[0] : ''
    }

    // 渠道来源和类型
    showChannelType () {
        const item = this.customerInfo
        const isRobot = item.channel.includes('机器人外呼')
        return isRobot ? '' : (item.channel || this.typeArrayFilter(item))
    }

    async getData () {
        if (!this.customer_id) return
        try {
            this.loading = true
            const { data } = await this.__apis.customer.customerDetail(String(this.customer_id))
            this.customerInfo = {
                id: data.id,
                name: data.name,
                mobile: data.mobile_fixed,
                mobileSeeBtn: data.need_show_mobile_button,
                level: data.tag,
                impGroup: data.impt_group.value,
                registerTime: this.__utils.dayjs(data.register_time).format('YYYY-MM-DD HH:mm:ss'),
                sysTags: data.sys_tags.map(a => a.value),
                typeArr: data.type_array,
                channel: data.u_channel,
                keyWords: data.u_keyword,
                beLong: [data.dep_name, data.group.name, data.saleman].filter(a => a).join('-'),
                followList: data.brief_remark,
                callRecordAudioUrl: this.__utils.index.setXjStaticPreUrl(data.ptt_call_record_audio_url),
                showInvalidApplyButton: data.can_show_invalid_apply_button,
                showClickInvalidApplyButton: data.can_click_invalid_apply_button
            }
            this.editCustomerInfo = {
                name: data.name,
                follow: '',
                house_name: data.house_name,
                house_id: data.house_id,
                level: data.tag,
                impGroup: data.impt_group.value,
                sysTags: data.sys_tags.map(a => a.value),
                nextFollowTime: ''
            }
        } finally {
            this.loading = false
        }
    }

    created () {
        this.getData()
    }
}
