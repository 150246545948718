import { Component, Vue } from 'vue-property-decorator'
import { storageName } from '@/localStorage'
type Day = 1 | 7
@Component
export default class SummaryPopup extends Vue {
    private dialogNoticeVisible: Boolean = false;
    private currentWeek: any = this.lastWeek(1, '-', new Date(), true)
    private tagWeek: string = `${this.lastWeek(1, '--', new Date(), true)} - ${this.lastWeek(7, '--', new Date(), true)}`
    private weeklyData: object = {}
    knewClick () {
        this.dialogNoticeVisible = false
    }
    /**
     *
     * @param ofWeek 周几 1-7
     * @param formatType 拼接类型
     * @param DATE 计算的日期
     * @param init 是否提前一周
     */
    lastWeek (ofWeek:Day, formatType:string, DATE:Date, init:boolean) {
        let date = new Date(this.getBeforeDate(DATE, init ? 7 : 0))
        // 先往前一周
        let day = date.getDay() || 7
        let someone = 24 * 60 * 60 * 1000
        let Monday = date.getTime() - (day - ofWeek) * someone
        return this.formatDate(new Date(Monday), formatType)
    }
    // 获取前n天的日期
    getBeforeDate (DATE:Date, n: number) {
        DATE.setDate(DATE.getDate() - n)
        return this.formatDate(DATE, '-')
    }

    formatDate (date:any, formatType:string) {
        let year = date.getFullYear()
        let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
        let weekday = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        if (formatType === '-') {
            return (year + '-' + month + '-' + weekday)
        } else {
            return (year + '年' + month + '月' + weekday + '日')
        }
    }
    pickerOptions:any = {
        disabledDate (time:any) {
            let day = new Date().getDay() || 7
            let oneDayTime = 24 * 60 * 60 * 1000
            let dayTemp = new Date().getTime() - (day - 1) * oneDayTime
            return time.getTime() > dayTemp - oneDayTime * 1
        },
        firstDayOfWeek: 1
    }
    weekChange (e: any) {
        this.currentWeek = this.lastWeek(1, '-', new Date(e), false)
        this.tagWeek = `${this.lastWeek(1, '--', new Date(e), false)} - ${this.lastWeek(7, '--', new Date(e), false)}`
        this.initWeekly(this.lastWeek(7, '-', new Date(e), false))
    }
    getWeekDate () {
        let day = new Date().getDay()
        return ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'][day]
    }
    getHours () {
        return new Date().getHours()
    }
    async initWeekly (end_time?: string) {
        const { data } = await this.__apis.home.getWeekly({
            start_time: this.currentWeek,
            end_time: end_time || this.lastWeek(7, '-', new Date(), true)
        })
        if (data) {
            this.weeklyData = data
            this.dialogNoticeVisible = true
            this.__localStorage.setLocalStorage(storageName.WEEKLYEJECT, true)
            this.__localStorage.setLocalStorage(storageName.WEEKLYEJECT_DATE, this.lastWeek(7, '-', new Date(), false))
        } else {
            this.weeklyData = {}
        }
    }
    getCurrentDate (date: any) {
        let month: any = parseInt(date.getMonth() + 1)
        let day = date.getDate()
        if (month < 10) {
            month = '0' + month
        }
        if (day < 10) {
            day = '0' + day
        }
        return date.getFullYear() + '-' + month + '-' + day
    }
    async mounted () {
        if (this.getWeekDate() === '星期一' && this.getHours() < 20) {
        } else if (this.__localStorage.getLocalStorage(storageName.WEEKLYEJECT) === true) {
            if (this.getCurrentDate(new Date()) > (this.__localStorage.getLocalStorage(storageName.WEEKLYEJECT_DATE) as string)) {
                this.__localStorage.setLocalStorage(storageName.WEEKLYEJECT, false)
                this.initWeekly()
            }
        } else {
            this.initWeekly()
        }
    }
}
