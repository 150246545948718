import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class KnowledgeAocPage extends Vue {
    ue = ''
    fixedMenu = false
    dataObj: any = {}
    defaultProps = {
        children: 'children',
        label: 'label'
    }
    dataDirObj: any = {}
    initDirArr = []
    docObj: any = {}
    docDetailObj: any = {}
    editor: any = null
    headerList = [] // 标题目录
    headerListCur = '' // 当前选中标题目录
    headerObj = '' // 隐藏展示对象

    get
    id () {
        return this.$route.query.id as string
    }
    get
    cateId () {
        return this.$route.query.cate_id as string
    }
    get
    docId () {
        return this.$route.query.doc_id as string
    }

    @Watch('$route')
    async watchRoute (to: any) {
        if (to.query.doc_id) {
            await this.getDocDetail()

            if (this.editor) {
                this.editor.isReadOnly = true
                this.editor.setData(this.docDetailObj.Body)
            }
        } else if (to.query.cate_id) {
            await this.getDirList()

            this.treeDocList()
            if (to.query.del) { // 删除后清空文档界面
                this.docDetailObj = {}
            }
        }
    }

    async mounted () {
        if (this.docId) {
            await this.getData()

            await this.getDocDetail()

            this.treeDocList()
        } else {
            await this.getData()

            this.treeDocList()
        }

        this.__utils.index.registerAppScroll(() => {
            this.scrollToFixed()
        })
    }

    destroyed () {
        this.__utils.index.destroyAppScroll()
    }

    scrollToFixed () {
        const dCon = this.$refs.d_con as any
        if (!dCon || !dCon.offsetTop) return

        let pos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

        let headHeight = document.querySelector('.header')
        let offTop = dCon.offsetTop + (headHeight ? headHeight.clientHeight : 0) + 20
        this.fixedMenu = pos >= offTop
    }

    // 跳转
    routePush (name: string, parm: string) {
        let obj: any = { name: name }
        if (parm) obj.query = parm
        this.$router.push(obj)
    }

    // 删除文档
    async delDoc () {
        try {
            await this.$confirm('确认删除文档？')

            await this.__apis.knowledge.deleteKnowledgeLibDoc(this.docId)

            this.$router.push({
                name: '/knowledge/doc',
                query: {
                    id: this.id,
                    cate_id: this.cateId,
                    del: true
                }
            } as any)

            this.$notify({
                title: '成功',
                message: '删除成功！',
                type: 'success'
            })
        } catch (e) {
            console.log(e)
        }
    }

    // 获取知识库
    async getData () {
        const res = await this.__apis.knowledge.getKnowledgeLibDetail(this.id)

        let datas: any = res.data
        datas.DirSort = datas.DirSort ? JSON.parse(datas.DirSort) : []
        this.clearTreeChild(datas.DirSort)
        this.dataObj = datas
        this.dataObj.show = true

        if (datas.DirSort.length) {
            this.getAllDocList(datas.DirSort[0].id)
        }
    }

    // 获取所有文档列表
    async getAllDocList (id: string) {
        const res = await this.__apis.knowledge.getKnowledgeDocList({
            dir_id: id,
            page: 1,
            pagesize: 100
        })

        let datas = res.data
        datas.list.map((v: any) => {
            v.label = v.Title
            v.doc = true
        })
        this.findTreeIdConcat(this.dataObj.DirSort, id, datas.list)
    }

    clearTreeChild (arr: any) {
        arr.map((v: any) => {
            v.tree = true
            if (v.children && v.children.length > 0) {
                v.childrenTree = v.children
                this.clearTreeChild(v.children)
            }
        })
    }

    // 获取知识库目录
    async getDirList () {
        const res = await this.__apis.knowledge.getKnowledgeLibDirList(this.id)

        let datas: any = res.data

        let obj: any = {}
        let arrs = []
        for (let k in datas) {
            datas[k].tree = true
            datas[k].label = datas[k].Name
            datas[k].id = datas[k].Id
            datas[k].children = []
            obj[datas[k].Pid] = []
        }
        for (let k in datas) {
            obj[datas[k].Pid].push(datas[k])
        }

        for (let k in obj) {
            if (k !== '0') {
                datas[k].children = obj[k]
            } else {
                arrs = obj[k]
            }
        }

        arrs = this.getTreeMax(arrs)

        this.initDirArr = arrs
        this.dataDirObj = datas
    }

    getTreeMax (arr: any, index?: number) {
        index = index ? index + 1 : 1
        arr.map((v: any) => {
            v.zIndex = index
            if (v.children && v.children.length > 0) {
                v.childrenTree = v.children
                this.getTreeMax(v.children, index)
            }
            if (v.children && v.children.length === 0) {
                v.children = ''
            }
        })
        return arr
    }

    // xiugai
    async treeDocList () {
        if (!this.cateId) return

        await this.getDocList()

        this.findTreeIdConcat(this.dataObj.DirSort, this.cateId, this.docObj.list)

        // 点击目标展示目录第一个文档
        if (!this.docId && this.docObj.list && this.docObj.list.length > 0) {
            if (this.docObj.list[0].Id) {
                this.$router.push({
                    name: '/knowledge/doc',
                    query: {
                        id: this.id,
                        cate_id: this.cateId,
                        doc_id: this.docObj.list[0].Id
                    }
                })
            }
        }
    }

    findTreeIdConcat (arr: any, id: string, list: any) {
        if (!arr) return
        arr.map((v: any) => {
            if (v.id === id) {
                if (v.childrenTree && v.childrenTree.length > 0) {
                    v.children = v.childrenTree.concat(list)
                } else {
                    this.$set(v, 'children', list)
                }
            } else {
                this.findTreeIdConcat(v.children, id, list)
            }
        })
    }

    // 获取文档列表
    async getDocList () {
        const res = await this.__apis.knowledge.getKnowledgeDocList({
            dir_id: this.cateId,
            page: 1,
            pagesize: 100
        })

        res.data.list.map((v: any) => {
            v.label = v.Title
            v.doc = true
        })
        this.docObj = res.data
    }

    // 获取文档详情
    async getDocDetail () {
        const res = await this.__apis.knowledge.getKnowledgeLibDocDetail(this.docId)
        this.docDetailObj = res.data
        this.getDir(this.docDetailObj.Body)
    }

    // 文档点击
    async handleDocClick (data: any, node: any) {
        if (data.tree) {
            const res = await this.__apis.knowledge.getKnowledgeDocList({
                dir_id: data.id,
                page: 1,
                pagesize: 100
            })

            res.data.list.map((v: any) => {
                v.label = v.Title
                v.doc = true
            })
            this.docObj = res.data

            this.findTreeIdConcat(this.dataObj.DirSort, data.id, this.docObj.list)
            if (data.children && data.children.length > 0) {
                node.expanded = true
            }
            // 点击目标展示目录第一个文档
            if (this.docObj.list && this.docObj.list.length > 0) {
                if (this.docObj.list[0].Id) {
                    this.$router.push({
                        name: '/knowledge/doc',
                        query: {
                            id: this.id,
                            cate_id: data.id,
                            doc_id: this.docObj.list[0].Id
                        }
                    })
                }
            } else if (this.$route.name !== '/knowledge/doc') {
                this.$router.push({
                    name: '/knowledge/doc',
                    query: {
                        id: this.id,
                        cate_id: data.id
                    }
                })
            }
        } else if (data.doc) {
            this.$router.push({
                name: '/knowledge/doc',
                query: {
                    id: this.id,
                    cate_id: data.DirId,
                    doc_id: data.Id
                }
            })
        }
    }

    editDoc () {
        this.$router.push({
            name: '/knowledge/editor',
            query: {
                id: this.id,
                cate_id: this.cateId,
                doc_id: this.docId,
                edit: true
            }
        } as any)
    }

    // 目录图标点击
    iconTree (data: any, node: any) {
        if (data.children && data.children.length > 0) {
            node.expanded = !node.expanded
        }
    }

    // 获取所有的标题元素h1-h6
    getDir (editCon: HTMLBodyElement) {
        let that = this
        let arr: any = []
        let parBox = $('<div id="editBox"></div>')
        parBox.html(editCon)
        parBox.find(':header').each(function (i, v) {
            if (!$(v).attr('id')) {
                $(v).attr('id', that.getRandomColor())
            }
            if ($(v).text() === '' || $(v).text() === ' ' ||
                $(v).text().length === 0 ||
                $(v).text() === '&nbsp;') {

            } else {
                arr.push({
                    id: $(v).attr('id'),
                    tag: v.tagName,
                    index: v.tagName[v.tagName.length - 1],
                    content: $(v).text()
                })
            }
        })
        this.headerObj = parBox.html()
        this.headerList = arr
    }

    // 滚动条滚动到元素所在位置
    toEleScrollTop (ele: string) {
        if (!ele) return
        this.headerListCur = ele
        this.$nextTick(() => {
            let eleObj = document.getElementById(ele) as HTMLElement
            const addDoc = this.$refs.add_doc as any
            const navBox = this.$refs.nav_box as any
            document.documentElement.scrollTop = document.body.scrollTop = eleObj.offsetTop + addDoc.offsetTop + navBox.offsetHeight
        })
    }

    // 随机值
    getRandomColor (): string {
        let rand = Math.floor(Math.random() * 0xFFFFFF).toString(16)

        if (rand.length === 6) {
            return rand
        } else {
            return this.getRandomColor()
        }
    }
}
