import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import UploadImg from '@/components/UploadImg/index.vue'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'
import router from '@/router'
import { LabelValueArray } from '@/types'

interface UserInfoItem {
    is_use_car: boolean // 用车
    user_id: number
    user_name: string
}

@Component({
    components: {
        UploadImg,
        inputSelectSearch
    }
})
export default class guideDialogComponent extends Vue {
    @Prop({ default: false })
    visible!: boolean

    @Prop({ default () { return {} } })
    data!: any

    @Prop({ default: '' })
    savetext!: string

    @Prop({ default: 1 })
    type!: number // 类型：1 addDkResForExamine；2 addDkRes

    guideAfterform: any = {
        user_name: '',
        customer_id: '',
        customer_name: '',
        customer_tel: '',
        action_time: '',
        region: '',
        house_name: '',
        house_id: '',
        channel_name: '',
        channel_tel: '',
        zygw_name: '',
        zygw_tel: '',
        imgs: [],
        customer_info: '',
        zongjie: '',
        after_work: '',
        ext_data: [
            {
                house_name: '',
                channel_name: '',
                channel_username: '',
                channel_tel: '',
                zygw_name: '',
                zygw_tel: ''
            }
        ],
        user_info: [],
        dz_join: false,
        cj_probability_level: 0
    }
    rules = {
        action_time: [
            { type: 'string', required: true, message: '请选择日期', trigger: 'change' }
        ],
        customer_name: [
            { type: 'string', required: true, message: '请输入客户姓名', trigger: 'change' }
        ],
        customer_info: [
            { type: 'string', required: true, message: '请输入带看后情况', trigger: 'change' }
        ],
        zongjie: [
            { type: 'string', required: true, message: '请输入应到账金额', trigger: 'change' }
        ],
        after_work: [
            { type: 'string', required: true, message: '请输入后期跟进方案', trigger: 'change' }
        ],
        imgs: [
            { type: 'array', required: true, message: '请上传图片', trigger: 'change' }
        ],
        cj_probability_level: [
            { type: 'number', required: true, message: '请评估该客户的成交可能性', trigger: 'change', min: 1, max: 10 }
        ],
        ext_data: [
            { type: 'array', required: true, message: '请添加带看项目', trigger: 'change' }
        ]
    }
    pickerOptions = {
        disabledDate (time:any) {
            let _now = Date.now()
            // 大于当前的禁止
            return time.getTime() > _now
        }
    }

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    get
    isEditSaleDisabled () {
        return !!(this.type === 2 && this.guideAfterform.id)
    }

    created () {
        const obj = this.data
        let houseList = obj.ExtData && obj.ExtData.map((v: any) => {
            return {
                baobei_id: v.BaobeiId,
                house_name: v.HouseName,
                house_id: v.HouseId,
                channel_name: v.ChannelName,
                channel_username: v.ChannelUsername,
                channel_tel: v.ChannelTel,
                zygw_name: v.ZygwName,
                zygw_tel: v.ZygwTel,
                channel_partner_id: v.ChannelPartnerId
            }
        })
        let userInfo = (obj.UserInfo && typeof obj.UserInfo === 'string') ? JSON.parse(obj.UserInfo) : (obj.UserInfo || [])
        this.guideAfterform = {
            id: obj.Id,
            customer_id: obj.CustomerId,
            customer_name: obj.CustomerName,
            house_name: obj.HouseName,
            action_time: obj.ActionTime,
            customer_info: obj.CustomerInfo,
            channel_name: obj.ChannelName,
            channel_username: obj.ChannelUsername,
            channel_tel: obj.ChannelTel,
            zygw_name: obj.ZygwName,
            zygw_tel: obj.ZygwTel,
            after_work: obj.AfterWork,
            ext_data: houseList || [],
            user_name: obj.UserName || this.__store.state.user.userInfo.username,
            imgs: obj.Imgs || [],
            user_info: userInfo || [],
            dz_join: obj.dz_join || false,
            cj_probability_level: obj.cj_probability_level || 0
        }

        if (this.type === 1 && !obj.Id) {
            this.guideAfterform.user_info.push({
                user_id: this.userInfo.user_id,
                user_name: this.guideAfterform.user_name || this.userInfo.username,
                is_use_car: false
            })
        }
    }

    searchUserChange (res: LabelValueArray[0], item: any) {
        item.user_id = res.value
        item.user_name = res.label
    }

    changeUseCar (item: UserInfoItem) {
        if (item.is_use_car) {
            this.guideAfterform.user_info.forEach((a: any) => {
                a.is_use_car = false
            })
            item.is_use_car = true
        }
    }

    addUserInfo () {
        this.guideAfterform.user_info.push({
            user_id: 0,
            user_name: '',
            is_use_car: false
        })
    }

    delUser (index: number) {
        this.guideAfterform.user_info.splice(index, 1)
    }

    // 添加项目
    add_project () {
        this.guideAfterform.ext_data.push({
            house_name: '',
            channel_name: '',
            channel_username: '',
            channel_tel: '',
            zygw_name: '',
            zygw_tel: ''
        })
    }

    // 删除项目
    projectDel (index: number) {
        this.guideAfterform.ext_data.splice(index, 1)
    }

    // 房源，渠道选择结果
    inputSelectRes (obj:any, key:string, form:any) {
        form[key] = obj.id
    }

    // 上传图片结果
    uploadImgRes (res:any, key:string, form:any) {
        form[key] = res
    }

    // 关闭弹窗事件
    closeDialog () {
        this.$emit('update:visible', false)
    }

    saveCheck () {
        const form = this.$refs['guideAfterform'] as any
        form.validate(async (valid: boolean) => {
            if (valid) {
                let isHouseChannel = this.guideAfterform.ext_data.every((v:any) => {
                    return (v.house_name !== '' &&
                        v.house_id &&
                        v.channel_name !== '' &&
                        v.channel_partner_id)
                })
                if (!isHouseChannel) {
                    return this.$notify({
                        title: '警告',
                        message: '请填写并选择楼盘和渠道！',
                        type: 'warning'
                    })
                }
                if (this.__utils.index.limitRepeatClick()) return
                try {
                    await this.saveForm()
                } catch (e) {
                    this.$message({
                        message: '提交失败！',
                        type: 'error'
                    })
                    throw e
                } finally {
                    this.__utils.index.restoreRepeatClick()
                }
            } else {
                console.log('error submit!!')
                return false
            }
        })
    }

    // 编辑保存
    async saveForm () {
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)'
        })

        try {
            let data: any = this.__utils.index.copyObj(this.guideAfterform)
            data.customer_id = this.data.CustomerId

            if (this.type === 2) {
                data.ext_data = JSON.stringify(data.ext_data)
                data.house_list = data.ext_data
                data.imgs = JSON.stringify(data.imgs)
                data.user_info = JSON.stringify(data.user_info)
                await this.__apis.customer.saveDkRes(data)
            } else {
                data.action_name = 'addDkResForExamine'
                data.house_list = data.ext_data
                await this.__apis.customer.daikanSave(data)
            }

            let isCustomerDetailPage = this.$route.name !== '/customer/detail'

            this.$notify({
                title: '成功',
                message: this.type === 2 ? '提交成功!' : isCustomerDetailPage ? '提交成功，等待审核!' : '提交成功，等待审核，即将跳转到审核页面！',
                type: 'success'
            })

            const form = this.$refs['guideAfterform'] as any
            form.resetFields()
            this.closeDialog()
            this.$emit('save')

            setTimeout(() => {
                if (this.type === 1 && !isCustomerDetailPage) {
                    router.replace('/saleman/record')
                }
            }, 3000)
        } catch (e) {
            throw e
        } finally {
            loading.close()
        }
    }
}
