import { Component, Vue, Watch } from 'vue-property-decorator'
import { EventBus, EventNames } from '@/util/bus'

@Component
export default class NoticeIndexPage extends Vue {
    dataObj = {}
    noticeStatus = [
        {
            label: '已下线',
            value: '0'
        },
        {
            label: '已上线',
            value: '1'
        }
    ]
    page = 1
    pageSize = 20
    total = 0
    dialogNoticeVisible = false
    noticeText = ''
    noticeTitle = ''

    currentNotice:any = {}
    NoticeDetailVisible:boolean = false
    NoticeEditVisible:boolean = false

    get
    notice () {
        return this.__store.state.user.notice
    }

    get
    userinfo () {
        return this.__store.state.user.userInfo
    }
    // 上线 || 下线
    async statusChange (obj: any) {
        let status = obj.Status === '1' ? 0 : 1
        await this.__apis.home.saveNotice({
            id: obj.Id,
            status
        })
        this.$notify({
            title: '成功',
            message: '修改成功！',
            type: 'success'
        })
        this.getData()
        EventBus.$emit(EventNames.upldatenotice)
    }
    handleSizeChange (val:number) {
        this.pageSize = val
        this.getData()
    }
    // 编辑
    editChange (obj: any) {
        this.currentNotice = obj
        this.NoticeEditVisible = true
    }
    // 详情
    detailChange (obj: any) {
        this.currentNotice = obj
        this.NoticeDetailVisible = true
    }
    // 取消
    cancelNotice (value:string = '') {
        this.dialogNoticeVisible = false
        this.NoticeDetailVisible = false
        this.NoticeEditVisible = false
    }
    @Watch('NoticeEditVisible')
    NoticeEditVisibleChange () {
        if (!this.NoticeEditVisible) {
            this.currentNotice.Title = this.currentNotice.TemTitle
        }
    }
    // 编辑-发布
    async editSaveNotice () {
        await this.__apis.home.saveNotice({
            id: this.currentNotice.Id,
            status: this.currentNotice.Status,
            content: JSON.stringify({
                title: this.currentNotice.Title,
                content: this.currentNotice.Content
            })
        })
        this.$notify({
            title: '成功',
            message: '修改成功！',
            type: 'success'
        })
        this.NoticeEditVisible = false
        this.getData()
        this.currentNotice.Status === '1' && EventBus.$emit(EventNames.upldatenotice)
    }
    addNotice () {
        this.dialogNoticeVisible = true
    }
    async saveNotice () {
        if (!this.noticeTitle || !this.noticeText) {
            this.$message.error(`主题或内容不能为空`)
            return
        }
        await this.__apis.home.saveNotice({
            content: JSON.stringify({
                title: this.noticeTitle,
                content: this.noticeText
            }),
            status: 0
        })

        this.$notify({
            title: '成功',
            message: '发布成功！',
            type: 'success'
        })
        this.noticeText = ''
        this.noticeTitle = ''
        this.dialogNoticeVisible = false
        this.getData()
        // EventBus.$emit(EventNames.upldatenotice)
    }

    mounted () {
        this.getData()
    }

    pageChange (page: number) {
        this.page = page
        this.getData()
    }

    async getData () {
        const { data } = await this.__apis.home.getNoticeList({
            page: this.page,
            pagesize: this.pageSize
        })
        data.list.map(i => {
            if (this.isJSON_test(i.Content)) {
                i.Title = JSON.parse(i.Content).title
                i.TemTitle = this.__utils.index.copyObj(i.Title)
                i.Content = JSON.parse(i.Content).content
            } else {
                i.Title = ''
            }
        })
        this.dataObj = data
        this.total = +data.total
    }

    async toDel (obj: any) {
        await this.__apis.home.deleteNotice(obj.Id)

        this.$notify({
            title: '成功',
            message: '删除成功！',
            type: 'success'
        })
        this.getData()
        EventBus.$emit(EventNames.upldatenotice)
    }

    isJSON_test (str: string) {
        if (typeof str === 'string') {
            try {
                var obj = JSON.parse(str)
                if (typeof obj === 'object' && obj) {
                    return true
                } else {
                    return false
                }
            } catch (e) {
                // console.log('error：' + str + '!!!' + e)
                return false
            }
        }
    }
}
