import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import E from 'wangeditor'
import { apiConfig } from '@/api/config'
import { storageName } from '@/localStorage'

@Component
export default class KnowledgeEditorWang extends Vue {
    editor: any = null // 编辑器实例
    optTreeVal: any[] = []
    treeProp = {
        label: 'label',
        value: 'id'
    }
    dataObj: any = {}
    docDetailObj: any = {}
    editDocVar = false
    docTitle = ''
    dataDirObj: any = {}
    initDirArr = []
    // wangeditor
    uploadImgUrl = apiConfig.apiUrl
    editorContent = ''
    editorMenu = [
        'head', // 标题
        'bold', // 粗体
        // 'fontSize',  // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        'video', // 插入视频
        'code', // 插入代码
        'undo', // 撤销
        'redo' // 重复
    ]
    headerList = [] // 标题目录
    headerListCur = '' // 当前选中标题目录
    headerObj = '' // 隐藏展示对象

    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }
    get
    id () {
        return this.$route.query.id as string
    }
    get
    cateId () {
        return this.$route.query.cate_id as string
    }
    get
    docId () {
        return this.$route.query.doc_id as string
    }
    get
    docAdd () {
        return this.$route.query.add
    }
    get
    editOpt () {
        return this.$route.query.edit
    }

    async mounted () {
        await this.initEditor()

        if (this.docAdd) {
            this.getData()
        } else if (this.editOpt) {
            this.editDocVar = true
            await this.getData()
            await this.getDocDetail()

            this.docTitle = this.docDetailObj.Title
            this.editorContent = this.docDetailObj.Body
            this.setEditorHtml(this.editorContent)
            this.treeDocList()
            this.result()
        }

        // 点击上传菜单，去掉上传弹窗，并自动点击上传按钮
        this.$nextTick(() => {
            let menus = document.querySelectorAll('.w-e-toolbar .w-e-menu') as NodeListOf<HTMLElement>

            for (let i = 0; i < menus.length; i++) {
                menus[i].onclick = () => {
                    if (menus[i] && menus[i].querySelector('.w-e-icon-image')) {
                        // 上传图片
                        setTimeout(() => {
                            let file = document.querySelector('#editor .w-e-panel-container .w-e-up-img-container input[type="file"]') as HTMLInputElement
                            file && file.click()
                        }, 100)
                    } else {
                        setTimeout(() => {
                            let box = document.querySelector('#editor .w-e-panel-container') as HTMLElement
                            if (box) {
                                box.className = 'w-e-panel-container show_box'
                                if (menus[i] && menus[i].querySelector('.w-e-icon-play')) {
                                    box.className = 'w-e-panel-container show_box uploadVideoBox'
                                    let inputEle = box.querySelector('.w-e-panel-tab-content input') as HTMLInputElement
                                    inputEle.placeholder = '请输入链接地址...'
                                    inputEle.onblur = () => {
                                        let src = box.querySelector('.w-e-panel-tab-content input') as HTMLInputElement
                                        if (src.value && src.value.indexOf('<iframe') === -1) {
                                            src.value = `<iframe width="100%" height="400px" src=${src.value}></iframe>`
                                        }
                                        console.log(666, src, src.value)
                                    }
                                }
                            }
                        }, 100)
                    }
                }
            }
        })
    }

    initEditor () {
        let editor = new E('.editor__toolbar', '#editor')

        editor.customConfig = {
            menus: this.editorMenu || [ // 自定义菜单配置
                'image'
            ],
            pasteFilterStyle: true, // 关闭粘贴样式的过滤
            pasteIgnoreImg: false, // 忽略粘贴内容中的图片
            uploadImgServer: this.uploadImgUrl, // 上传图片地址
            showLinkImg: false, // 隐藏“网络图片”tab
            uploadImgMaxLength: 5, // 限制一次最多上传 5 张图片
            uploadImgParams: {			// 自定义上传参数
                // 版本 <=v3.1.0 ，属性值会自动进行 encode ，此处无需 encode
                // 版本 >=v3.1.1 ，属性值不会自动 encode ，如有需要自己手动 encode
                action_name: 'uploadFile'
            },
            uploadFileName: 'file', // 自定义 fileName
            uploadImgHeaders: {		// 自定义 header
                'ac-token': this.token
            }
        }

        editor.customConfig.uploadImgHooks = {
            customInsert (insertImg: any, result: any) {
                insertImg(result.data.url)
                $('#editor').attr('style', 'height:auto !important;')
            }
        }

        editor.customConfig.customUploadImg = (files: any, insert: any) => {
            files.map((v: any) => {
                uploadImg(v)
            })

            const uploadImg = (file: any) => {
                const data = new FormData()
                data.append('action_name', 'uploadFile')
                data.append('file', file)
                axios({
                    url: apiConfig.apiUrl,
                    method: 'post',
                    data,
                    headers: {
                        'ac-token': this.__localStorage.getLocalStorage(storageName.TOKEN),
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    let resData = res.data.data
                    insert(resData.url)
                    $('#editor').attr('style', 'height:auto !important;')
                }).catch(e => {
                    throw e
                })
            }
        }

        editor.create()

        this.editor = editor
    }

    result () {
        this.getDir(this.getEditorHtml())
    }

    // 获取内容
    getEditorHtml () {
        return this.editor && this.editor.txt.html()
    }

    // 设置内容
    setEditorHtml (str: string) {
        this.editor && this.editor.txt.html(str)
    }

    // 获取所有的标题元素h1-h6
    getDir (editCon: string) {
        let that = this
        let arr: any = []
        let parBox = $('<div id="editBox"></div>')
        parBox.html(editCon)
        parBox.find(':header').each(function (i, v) {
            if (!$(v).attr('id')) {
                $(v).attr('id', that.getRandomColor())
            }
            if ($(v).text() === '' || $(v).text().length === 0 || $(v).text() === '&nbsp;') {

            } else {
                arr.push({
                    id: $(v).attr('id'),
                    tag: v.tagName,
                    index: v.tagName[v.tagName.length - 1],
                    content: $(v).text()
                })
            }
        })
        this.headerObj = parBox.html()
        this.headerList = arr
    }

    // 滚动条滚动到元素所在位置
    toEleScrollTop (ele: string) {
        if (!ele) return
        this.headerListCur = ele

        this.$nextTick(() => {
            let eleObj = document.getElementById(ele)
            let div = document.querySelector('.document-editor__editable-container')
            if (div && eleObj) div.scrollTop = eleObj.offsetTop
        })
    }

    // 随机值
    getRandomColor (): string {
        let rand = Math.floor(Math.random() * 0xFFFFFF).toString(16)
        if (rand.length === 6) {
            return rand
        } else {
            return this.getRandomColor()
        }
    }

    // 获取数据
    async getData () {
        const res = await this.__apis.knowledge.getKnowledgeLibDetail(this.id)

        let datas: any = res.data
        datas.DirSort = datas.DirSort ? JSON.parse(datas.DirSort) : []
        datas.DirSort.map((v: any) => {
            if (v.children && v.children.length > 0) {
                v.childrenTree = v.children
            }
        })
        this.clearTreeChild(datas.DirSort)
        this.dataObj = datas
        this.dataObj.show = true
        this.treeDocList()
    }

    clearTreeChild (arr: any) {
        arr && arr.map((v: any) => {
            if (v.children && v.children.length === 0) {
                v.children = ''
            } else {
                this.clearTreeChild(v.children)
            }
        })
    }

    findTreeId (arr: any, id: string, idArr: string[]) {
        if (!arr || arr.length === 0) return

        idArr = idArr || {}
        arr.map((v: any) => {
            idArr[v.id] = v
            if (v.children) {
                this.findTreeId(v.children, id, idArr)
            }
        })
    }

    // 获取知识库目录
    async getDirList () {
        const res = await this.__apis.knowledge.getKnowledgeLibDirList(this.id)

        let datas: any = res.data

        let obj: any = {}
        let arrs = []
        for (let k in datas) {
            datas[k].tree = true
            datas[k].label = datas[k].Name
            datas[k].id = datas[k].Id
            datas[k].children = []
            obj[datas[k].Pid] = []
        }
        for (let k in datas) {
            obj[datas[k].Pid].push(datas[k])
        }

        for (let k in obj) {
            if (k !== '0') {
                datas[k].children = obj[k]
            } else {
                arrs = obj[k]
            }
        }

        this.initDirArr = arrs
        this.dataDirObj = datas
    }

    // xiugai
    async treeDocList () {
        if (!this.editOpt) return

        this.clearTreeChild(this.dataObj.DirSort)
        await this.getDirList()

        this.optTreeVal = [
            this.dataDirObj[this.cateId].Pid,
            this.dataDirObj[this.cateId].Id
        ]
    }

    findTreeIdConcat (arr: any, id: string, list: any) {
        if (!arr || arr.length === 0) return
        arr.map((v: any) => {
            if (v.id === id) {
                if (v.childrenTree && v.childrenTree.length > 0) {
                    v.children = v.childrenTree.concat(list)
                } else {
                    this.$set(v, 'children', list)
                }
            } else {
                this.findTreeIdConcat(v.children, id, list)
            }
        })
    }

    // 获取文档详情
    async getDocDetail () {
        const res = await this.__apis.knowledge.getKnowledgeLibDocDetail(this.docId)

        this.docDetailObj = res.data
    }

    // 发布
    async addDoc () {
        if (this.optTreeVal.length === 0) {
            this.$notify({
                title: '警告',
                message: '请选择目录！',
                type: 'warning'
            })
            return
        }
        if (this.docTitle.length === 0) {
            this.$notify({
                title: '警告',
                message: '请输入标题！',
                type: 'warning'
            })
            return
        }

        const res = await this.__apis.knowledge.saveKnowledgeLibDoc({
            id: this.editDocVar ? this.docId : '',
            title: this.docTitle,
            body: this.getEditorHtml(),
            lib_id: this.id, // 知识库id
            dir_id: this.optTreeVal[this.optTreeVal.length - 1] // 目录ID
        })

        this.$notify({
            title: '成功',
            message: '发布成功！',
            type: 'success'
        })
        if (this.editDocVar) {
            this.$router.push({
                name: '/knowledge/doc',
                query: {
                    id: this.id,
                    cate_id: this.optTreeVal[this.optTreeVal.length - 1],
                    doc_id: this.docId
                }
            })
        }
        if (this.docAdd) {
            this.$router.push({
                name: '/knowledge/doc',
                query: {
                    id: this.id,
                    cate_id: this.optTreeVal[this.optTreeVal.length - 1],
                    doc_id: res.data.id
                }
            })
        }
    }

    // 目录选择
    handleChange () {}

    // 跳转
    routePush (name: string, parm: string) {
        let obj: any = { name: name }
        if (parm) obj.query = parm
        this.$router.push(obj)
    }
}
