import { Component, Vue } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'
import inputDateRange from '@/components/filters/inputDateRange/index.vue'
import houseBindDialog from './bind/index.vue'
import { Table } from '@/types'

interface TableItem {
    id: number,
    userId: number
    houseId: number
    channelId: number
    duiJieUser: {
        user_name: string
        mobile: string
    }[]
    houseName: string
    channelName: string,
    userName: string
    dateTimeText: string
}

interface filterData {
    user_id?: number
    house_id?: number
    bind_time?: string
    bind_start_time?: string
    bind_end_time?: string
}

@Component({
    components: {
        FilterComponent,
        FilterBusSelectComponent,
        inputDateRange,
        houseBindDialog
    }
})
export default class JiXiaoHouseBindPage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0
    }

    filter: filterData = {}

    bindHouseDialogData = {
        visible: false,
        edit: false,
        data: {}
    }

    created () {
        this.getData()
    }

    async getData () {
        const { data } = await this.__apis.channelManage.getHouseUserBindRecord({
            ...this.filter,
            page: this.tableData.page,
            pagesize: this.tableData.pageSize
        })
        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.id,
                userId: a.user_id,
                houseId: a.house_id,
                channelId: a.channel_id,
                userName: a.user_info.user_name,
                houseName: a.house_name,
                channelName: a.channel_name,
                dateTimeText: this.__utils.index.formatDate(new Date(a.bind_time), 'YYYY-MM-DD'),
                duiJieUser: a.duijie_user_info.map((a) => {
                    return {
                        user_name: a.userName,
                        mobile: a.tel
                    }
                })
            }
        })
    }

    showAddHouseBind (edit: boolean, item?: TableItem) {
        this.bindHouseDialogData.data = item || {}
        this.bindHouseDialogData.edit = edit
        this.bindHouseDialogData.visible = true
    }

    currentChange (page: number) {
        this.tableData.page = page
        this.getData()
    }

    filterChange (filter: filterData) {
        console.log(filter)
        filter.bind_start_time = filter.bind_time ? filter.bind_time[0] : ''
        filter.bind_end_time = filter.bind_time ? filter.bind_time[1] : ''
        delete filter.bind_time
        this.filter = filter
        this.getData()
    }

    async unBind (id:number) {
        await this.__apis.channelManage.deleteHouseBindUser(id)
        this.$notify({
            title: '成功',
            message: '解绑成功！',
            type: 'success'
        })
        await this.getData()
    }
}
