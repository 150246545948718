import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { __utils } from '@/util'

interface Item {
    value: Date
    select: boolean
    hover: boolean
}

@Component
export default class IndexComponent extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    show !: boolean

    @Prop({
        type: Array,
        default () {
            return []
        }
    })
    value !: Date[]

    list: Item[] = []

    // 监听年的变化 需要重新生成list，改变时间和状态
    @Watch('year', {
        immediate: true
    })
    watchYear () {
        this.selectItem = []
        this.list = []

        for (let i = 0; i < 12; i++) {
            const item: Item = {
                value: this.__utils.dayjs().year(this.year).month(i).startOf('month').startOf('date').toDate(),
                select: false,
                hover: false
            }

            this.list.push(item)
        }
    }

    selectItem: Item[] = []

    // 以开始时间的年份为默认年份
    year: number = __utils.dayjs().year()

    async created () {
        // 修改开始时间的年份
        if (this.value.length) {
            this.year = this.value[0].getFullYear()
            // fix watch recall
            await __utils.index.sleep(0)
        }

        // 传入的value 需要选中
        const months = this.value.map(a => a.getMonth())
        this.list.forEach(a => {
            months.forEach(b => {
                if (b === a.value.getMonth()) {
                    a.select = true
                    this.selectItem.push(a)
                }
            })
        })
        this.selectItem.length === 2 && this.linkItem(this.selectItem[0], this.selectItem[1])
    }

    reduceYear () {
        this.year--
    }

    addYear () {
        this.year++
    }

    getMonth (date: Date) {
        return date.getMonth() + 1
    }

    // 选择月份
    select (item: Item) {
        // 选择了两个 清空已选择
        if (this.selectItem.length === 2) {
            this.selectItem.forEach(a => {
                a.select = false
                a.hover = false
            })
            this.selectItem = []
            this.clearLink()
        }

        this.selectItem.push(item)
        item.select = true

        // 选择完成
        if (this.selectItem.length === 2) {
            this.sortItem(this.selectItem)
            this.linkItem(this.selectItem[0], this.selectItem[1])
            this.$emit('confirm', this.selectItem.map(a => a.value))
            this.close()
        }
    }

    // 移入
    mouseenter (item: Item) {
        if (this.selectItem.length !== 1) return
        this.linkItem(item, this.selectItem[0])
    }

    // 移出
    mouseleave () {
        if (this.selectItem.length === 2) return
        this.clearLink()
    }

    // 清掉链接状态
    clearLink () {
        this.list.forEach(a => {
            a.hover = false
        })
    }

    linkItem (one: Item, two: Item) {
        const dates = this.sortItem([one, two])
        let o = dates[0].value.getMonth()
        let t = dates[1].value.getMonth()

        this.list.forEach(a => {
            const m = a.value.getMonth()
            if (m >= o && m < t) {
                a.hover = true
            }
        })
    }

    sortItem (dates: Item[]) {
        return dates.sort((a, b) => {
            return a.value.getTime() - b.value.getTime()
        })
    }

    close () {
        this.$emit('update:show', false)
    }
}
