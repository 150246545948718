import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import FilterComponent from '@/components/filters/filter/index.vue'
import addComponent from './add/index.vue'
import InputSelect from '@/components/filters/inputSelect/index.vue'
import { editItem } from './add/index'

interface Filter {
    tel: string
    name: string
}

interface TableItem {
    dep: string
    name: string
    id: number
    tel: string
    status: number
    depId: number
    popVisible: boolean
}

@Component({
    components: {
        InputSelect,
        FilterComponent,
        addComponent
    }
})
export default class IndexPage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 10,
        total: 0
    }

    filter: Filter = {
        name: '',
        tel: ''
    }

    addComponent: {
        show: boolean
        item?: editItem
    } = {
        show: false,
        item: undefined
    }

    created () {
        this.getData()
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        this.getData()
    }

    add () {
        this.addComponent.item = undefined
        this.addComponent.show = true
    }

    edit (item: TableItem) {
        this.addComponent.item = {
            id: item.id,
            dep: item.depId,
            tel: item.tel,
            name: item.name
        }
        this.addComponent.show = true
    }

    showPop (item: TableItem) {
        setTimeout(() => {
            item.popVisible = true
        }, 0)
    }

    hidePop (item: TableItem) {
        item.popVisible = false
    }

    async getData () {
        const { data } = await this.__apis.channelManage.salePartnerDuiJieUserList({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            username: this.filter.name,
            tel: this.filter.tel
        })

        this.tableData.total = data.total

        this.tableData.data = data.list.map(a => {
            const deps = this.__utils.index.getDepInfoById(a.dep)

            return {
                tel: a.tel,
                name: a.username,
                id: a.id,
                dep: deps ? deps.v : '',
                depId: a.dep,
                status: a.status,
                popVisible: false
            }
        })
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getData()
    }

    async setStatus (item: TableItem) {
        try {
            await this.__apis.channelManage.duiJieUserAction({
                id: item.id,
                tel: item.tel,
                username: item.name,
                action_name: 'disable',
                status: item.status
            })

            this.$message.success('操作成功')

            item.popVisible = false
            this.getData()
        } catch (e) {
            this.$message.error(`操作失败 ${e}`)
            throw e
        }
    }

    tableRowClassFun ({ row: item }: {row: TableItem}) {
        if (item.status === 0) {
            return 'disabled'
        }
    }
}
