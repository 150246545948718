import { Component, Vue, Watch } from 'vue-property-decorator'
import InputHouseSearch from '@/components/InputHouseSearch/index.vue'
import mixin from '../mixin'

@Component({
    components: {
        InputHouseSearch
    },
    mixins: [mixin]
})
export default class CustomerAnalysisPage extends Vue {
    // 筛选
    searchVisible = true
    form: any = {
        ctime: '',
        house: '',
        intention_area: ''
    }
    // 数据
    dataObj = {}
    page = 1
    pageSize = 50
    total = 0
    dialogEdit = false
    editForm = {
        tag: '',
        impt_group: ''
    }
    formLabelWidth = '120px'
    sortType = ''
    sortMode = ''

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    @Watch('form.ctime')
    formCtime () {
        this.getData()
    }

    mounted () {
        this.getData()
    }

    // 请求函数
    async getData () {
        let form = this.__utils.index.copyObj(this.form)
        form.ctime = ''
        form.last_follow_time = ''
        let data = {
            type: 'statistic',

            ctime_begin: this.form.ctime ? this.form.ctime[0] : '',
            ctime_stop: this.form.ctime ? this.form.ctime[1] : '',

            sort_field: this.sortType,
            sort_mode: this.sortMode,

            page: this.page,
            pagesize: this.pageSize
        }
        data = Object.assign(form, data)
        const res = await this.__apis.customer.customerList(data)

        let datas: any = res.data
        datas.show = true
        this.total = datas.res.total
        this.dataObj = datas
    }

    // 搜索
    clickSearch () {
        this.page = 1
        this.getData()
    }

    // 清空筛选
    resetFrom (formName: string) {
        const form = this.$refs[formName] as any
        form.resetFields()
        this.page = 1
        this.getData()
    }

    // 筛选展开收起
    clickSearchVisible () {
        this.searchVisible = !this.searchVisible
    }
}
