import { Component, Prop, Vue } from 'vue-property-decorator'
import { apiConfig } from '@/api/config'
import { storageName } from '@/localStorage'

@Component
export default class SuccessDetailPhotos extends Vue {
    @Prop({
        type: Array,
        default () {
            return []
        }
    })
    list !: any[]

    @Prop({
        type: Boolean,
        default () {
            return true
        }
    })
    showDelete !: boolean

    uploadUrl = apiConfig.apiUrl

    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }

    get
    getImageList () {
        return this.list.map(a => a.image)
    }

    // 选择图片上传
    selectPhotoSuccess (item: any) {
        return function (res: any) {
            item.image = res.data.url
        }
    }

    // 删除等待上传图片
    removeSelectPhoto (item: any) {
        item.image = ''
    }

    // 预览图片
    previewImage (index: number) {
        const images = this.$refs.images as any
        images[index].clickHandler()
    }

    // 图片预览框点击后会触发upload
    fixImagePreview (e: MouseEvent) {
        const target = e.target as HTMLElement | null
        if (target && target.className.includes('el')) {
            e.preventDefault()
            e.cancelBubble = true
        }
    }
}
