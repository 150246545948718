import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AccountAll extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    show !: boolean

    @Prop({
        type: Object,
        default: false
    })
    source !: {
        id: number
    }

    zjForm = {
        radio_depart: 0,
        radio_group: 0,
        radio_fxs: 0
    }

    get
    infoPos () {
        let infoPos = this.__store.state.user.infoPos
        this.zjForm.radio_depart = infoPos.dep
        this.zjForm.radio_group = infoPos.group
        this.zjForm.radio_fxs = infoPos.fxs
        return infoPos
    }

    get
    depList () {
        return this.infoPos.dep_arr
    }

    get
    getGroupsByDepId () {
        return this.__utils.index.getGroupsByDepId(this.zjForm.radio_depart, { hide: true })
    }

    get
    getActiveFxs () {
        if (!this.infoPos.fxs_arr) return []
        let fxs = this.infoPos.fxs_arr[this.zjForm.radio_depart][this.zjForm.radio_group] || []
        let res = fxs.filter((v: any) => !v.deactive)
        return res || []
    }

    // 转交相关
    zjDepartChange () {
        this.zjForm.radio_group = 0
        this.zjForm.radio_fxs = 0
    }

    zjGroupChange () {
        this.zjForm.radio_fxs = 0
    }

    async save () {
        if (this.__utils.index.limitRepeatClick()) return
        try {
            const item = this.source
            await this.__apis.customer.customerCallDispatch(+item.id, this.zjForm.radio_fxs)
            this.$message.success('分配成功')
            this.close()
            this.$emit('submit')
        } catch (e) {
            this.$message.error('分配失败')
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    close () {
        this.$emit('update:show', false)
    }
}
