import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CustomerDetailPage extends Vue {
    id = ''

    mounted () {
        this.id = this.$route.params.id
    }
}
