import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import { __utils } from '@/util'
import FilterComponent from '@/components/filters/filter/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'

interface TableItem {
    department: string
    group: string
    month: string
    money: string
    remark: string
}

interface Filter {
    month: string[]
}

@Component({
    components: {
        FilterComponent,
        InputDateRangeComponent
    }
})
export default class IndexPage extends Vue {
    filter: Filter = {
        month: []
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 10,
        total: 0
    }

    mounted () {
        this.getTableData()
    }

    async getTableData () {
        const group_id = Number(this.$route.query.id as string)
        const { data } = await this.__apis.wallet.groupWalletDetailList({
            group_id,
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            time_start: this.filter.month[0],
            time_end: this.filter.month[1]
        })

        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                department: a.dep_name,
                group: a.group_name,
                month: a.ctime,
                money: this.__utils.index.formatMoney(a.money),
                remark: a.remark
            }
        })
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)

        if (!this.filter.month) {
            this.filter.month = []
        } else {
            this.filter.month = __utils.index.formatTimeStartTimeEnd(this.filter.month)
        }

        this.tableData.page = 1
        this.getTableData()
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }
}
