import { Component, Vue } from 'vue-property-decorator'
import html2canvas from 'html2canvas'
import { apiConfig } from '@/api/config'
import { storageName } from '@/localStorage'
import { ReportManageStorageName } from '@/api/reportManage'

@Component({
    filters: {
        moneyFormat (value: any) {
            return Number(value).toFixed(2)
        }
    }
})
export default class ReportManage extends Vue {
    private draw_dom!: any
    private draw_canvas!: any

    get
    showBigEvent () {
        return this.form_data.big_event[0].title || this.form_data.big_event[0].fileList.length > 0
    }

    reportIndex: 'week' | 'month' | 'season' = 'week'

    tabBarIndex: 'manage' | 'history' = 'manage'

    menuIndex: string = '1'

    tab_left_list_text: any = {
        week: {
            title1: '业绩周报',
            title2: '回款周报',
            title3: '上周回款报告',
            title4: '上周网签报告'
        },
        month: {
            title1: '业绩月报',
            title2: '回款月报',
            title3: '上月回款报告',
            title4: '上月网签报告'
        },
        season: {
            title1: '业绩季报',
            title2: '回款季报',
            title3: '上季回款报告',
            title4: '上季网签报告'
        }
    }

    date: any = {
        year_options: [{
            value: '2020',
            label: '2020年'
        }, {
            value: '2021',
            label: '2021年'
        }, {
            value: '2022',
            label: '2022年'
        }, {
            value: '2023',
            label: '2023年'
        }, {
            value: '2024',
            label: '2025年'
        }],
        month_options: [{
            value: '1月',
            label: '1月'
        }, {
            value: '2月',
            label: '2月'
        }, {
            value: '3月',
            label: '3月'
        }, {
            value: '4月',
            label: '4月'
        }, {
            value: '5月',
            label: '5月'
        }, {
            value: '6月',
            label: '6月'
        }, {
            value: '7月',
            label: '7月'
        }, {
            value: '8月',
            label: '8月'
        }, {
            value: '9月',
            label: '9月'
        }, {
            value: '10月',
            label: '10月'
        }, {
            value: '11月',
            label: '11月'
        }, {
            value: '12月',
            label: '12月'
        }],
        week_options: [{
            value: '第1周',
            label: '第1周'
        }, {
            value: '第2周',
            label: '第2周'
        }, {
            value: '第3周',
            label: '第3周'
        }, {
            value: '第4周',
            label: '第4周'
        }, {
            value: '第5周',
            label: '第5周'
        }],
        season_options: [{
            value: '第1季度',
            label: '第1季度'
        }, {
            value: '第2季度',
            label: '第2季度'
        }, {
            value: '第3季度',
            label: '第3季度'
        }, {
            value: '第4季度',
            label: '第4季度'
        }]
    }

    tab_left_list: any[] = [
        {
            title: '标题',
            is_require: true,
            index: '1',
            scrollTop: 0,
            error: false
        }, {
            title: '业绩周报',
            is_require: true,
            index: '2',
            scrollTop: 355,
            error: false
        }, {
            title: '业绩榜单',
            is_require: true,
            index: '3',
            scrollTop: 636,
            error: false
        }, {
            title: '回款周报',
            is_require: true,
            index: '4',
            scrollTop: 1182,
            error: false
        }, {
            title: '网签周报',
            is_require: true,
            index: '5',
            scrollTop: 1460,
            error: false
        }, {
            title: '各部门工作报告',
            is_require: false,
            index: '6',
            scrollTop: 1820,
            error: false
        }, {
            title: '奇思妙行大事纪',
            is_require: false,
            index: '7',
            scrollTop: '9999',
            error: false
        }
    ]

    department_list: any = [{
        name: '长三角东部分公司',
        index: 'csjd',
        input_number: 1
    }, {
        name: '长三角西部分公司',
        index: 'csjx',
        input_number: 1
    }, {
        name: '珠三角分公司',
        index: 'zsj',
        input_number: 1
    }, {
        name: '产品部',
        index: 'product',
        input_number: 1
    }, {
        name: '财务部',
        index: 'finance',
        input_number: 1
    }, {
        name: '市场部',
        index: 'market',
        input_number: 1
    }, {
        name: '人力资源部',
        index: 'resources',
        input_number: 1
    }, {
        name: '综合部',
        index: 'comprehensive',
        input_number: 1
    }, {
        name: '行思会',
        index: 'xsh',
        input_number: 1
    }, {
        name: '品牌部',
        index: 'ppb',
        input_number: 1
    }, {
        name: '奇妙学院',
        index: 'qmxy',
        input_number: 1
    }]

    form_scroll: any = null

    dialogVisible: boolean = false

    dialogImageUrl: string = ''

    person: any[] = []

    rank_list: any[] = ['nb4', 'nb5', 'nb6', 'nb7', 'nb8', 'nb9', 'nb10']

    canvas_height: number = 2868

    action: string = apiConfig.uploadAction

    form_data: any = {
        big_title: '奇思妙行周报',
        title: {
            year: '',
            month: '',
            week: '',
            season: ''
        },
        achievement: {
            all_company: '',
            csj: '',
            csjx: '',
            zsj: ''
        },
        achievement_rank: {
            nb1: [],
            nb2: [],
            nb3: [],
            nb4: [],
            nb5: [],
            nb6: [],
            nb7: [],
            nb8: [],
            nb9: [],
            nb10: []
        },
        money_back: {
            all_company: '',
            csj: '',
            csj2: '',
            zsj: ''
        },
        net_sign: {
            all_count: '',
            all_money: '',
            csj_count: '',
            csj_money: '',
            csj2_count: '',
            csj2_money: '',
            zsj_count: '',
            zsj_money: ''
        },
        report_department: {
            csjd: [],
            csjx: [],
            zsj: [],
            product: [],
            finance: [],
            market: [],
            resources: [],
            comprehensive: [],
            xsh: [],
            ppb: [],
            qmxy: []
        },
        big_event: [
            {
                title: '',
                fileList: []
            }
        ],
        showImg: ''
    }

    big_event_input_length: number = 1

    big_event_upload_index: number = 0

    token: string = ''

    getFirst: boolean = true

    tableData: {title: string, data: string}[] = []

    pageCurrent: number = 1

    pageTotal: number = 0

    tableDataAll: {title: string, data: string}[] = []

    is_windows: boolean = true

    loading: boolean = false

    mounted () {
        this.draw_dom = document.querySelector('.draw-dom')
        this.draw_canvas = document.querySelector('.draw-canvas')
        this.draw()
        this.form_scroll = document.querySelector('.form')
        this.getAccessToken()
        this.form_scroll.addEventListener('scroll', this.watchScroll)
        this.checkSystem()
    }

    avatarDefault (avatar: string) {
        const DEFAULTAVATARURL = 'http://thirdwx.qlogo.cn/mmopen/5boLUbd6u4BH8X8SJicODibZMpE0Oia8oAcLbLMyfHxUkDvTWFnibwU0xSCm8MXos5wERRpjEfA4pRgZnPEVce602UfWWjFiaxkHf/132'
        const DEFAULTAVATARURL2 = 'http://lxfm-s.malmam.com/uploads/image/140928/2748175_5_03f58d295d_o.png'
        const SHOWAVATAR = 'https://ssl.malmam.com/xiangju-static/dfs/18/5ed3d83f130bcb/aaaaaa.png'
        if (avatar.trim() === DEFAULTAVATARURL || avatar.trim() === DEFAULTAVATARURL2 || avatar === '') {
            return SHOWAVATAR
        } else {
            return avatar
        }
    }

    destroyed () {
        this.form_scroll.removeEventListener('scroll', this.watchScroll)
    }

    checkSystem () {
        this.is_windows = navigator.userAgent.toLowerCase().indexOf('windows') !== -1
    }

    watchScroll () {
        if (this.form_scroll.scrollTop >= 0 && this.form_scroll.scrollTop < 335) {
            this.menuIndex = '1'
        } else if (this.form_scroll.scrollTop >= 335 && this.form_scroll.scrollTop < 636) {
            this.menuIndex = '2'
            this.errorCheck('2')
        } else if (this.form_scroll.scrollTop >= 636 && this.form_scroll.scrollTop < 1182) {
            this.menuIndex = '3'
            this.errorCheck('3')
        } else if (this.form_scroll.scrollTop >= 1182 && this.form_scroll.scrollTop < 1460) {
            this.errorCheck('4')
            this.menuIndex = '4'
        } else if (this.form_scroll.scrollTop >= 1460 && this.form_scroll.scrollTop < 1820) {
            this.menuIndex = '5'
            this.errorCheck('5')
        } else {
            this.menuIndex = '6'
            this.errorCheck('6')
        }
    }

    errorCheck (checkIndex: string) {
        this.getFirst = false
        switch (checkIndex) {
        case '2':
            this.setTitleError()
            break
        case '3':
            this.tab_left_list[1].error = this.form_data.achievement.all_company === '' || this.form_data.achievement.csjx === '' || this.form_data.achievement.csj === '' || this.form_data.achievement.zsj === ''
            break
        case '4':
            let error_count = 0
            for (let item in this.form_data.achievement_rank) {
                this.form_data.achievement_rank[item].length === 0 && error_count++
            }
            this.tab_left_list[2].error = error_count > 0
            break
        case '5':
            this.tab_left_list[3].error = this.form_data.money_back.all_company === '' || this.form_data.money_back.csj === '' || this.form_data.money_back.zsj === ''
            break
        case '6':
            this.tab_left_list[4].error = this.form_data.net_sign.all_money === '' || this.form_data.net_sign.all_count === '' ||
                this.form_data.net_sign.zsj_money === '' || this.form_data.net_sign.zsj_count === '' ||
                this.form_data.net_sign.csj_money === '' || this.form_data.net_sign.csj_count === ''
            break
        default:
            this.setTitleError()
            this.tab_left_list[1].error = this.form_data.achievement.all_company === '' || this.form_data.achievement.csjx === '' || this.form_data.achievement.csj === '' || this.form_data.achievement.zsj === ''
            let err = 0
            for (let item in this.form_data.achievement_rank) {
                this.form_data.achievement_rank[item].length === 0 && err++
            }
            this.tab_left_list[2].error = err > 0
            this.tab_left_list[3].error = this.form_data.money_back.all_company === '' || this.form_data.money_back.csj === '' || this.form_data.money_back.zsj === ''
            this.tab_left_list[4].error = this.form_data.net_sign.all_money === '' || this.form_data.net_sign.all_count === '' ||
                this.form_data.net_sign.zsj_money === '' || this.form_data.net_sign.zsj_count === '' ||
                this.form_data.net_sign.csj_money === '' || this.form_data.net_sign.csj_count === ''
            break
        }
    }

    setTitleError () {
        if (this.reportIndex === 'week') {
            this.tab_left_list[0].error = this.form_data.title.year === '' || this.form_data.title.month === '' || this.form_data.title.week === ''
        } else if (this.reportIndex === 'month') {
            this.tab_left_list[0].error = this.form_data.title.year === '' || this.form_data.title.month === ''
        } else {
            this.tab_left_list[0].error = this.form_data.title.year === '' || this.form_data.title.season === ''
        }
    }

    clear () {
        if (this.draw_canvas.children[0]) {
            this.draw_canvas.removeChild(this.draw_canvas.children[0])
        }
    }

    draw () {
        this.loading = true
        this.canvas_height = this.draw_dom.clientHeight / 2
        html2canvas(this.draw_dom, {
            removeContainer: false,
            useCORS: true,
            width: 375,
            height: this.canvas_height,
            scale: 4
        }).then((canvas) => {
            this.clear()
            this.loading = false
            this.draw_canvas.appendChild(canvas)
        })
    }

    async downLoad () {
        this.errorCheck('7')
        const check:boolean = !this.tab_left_list[0].error && !this.tab_left_list[1].error && !this.tab_left_list[2].error && !this.tab_left_list[3].error

        if (!check) {
            this.$message({
                message: '有必填项未填写',
                type: 'warning'
            })
            return
        }

        try {
            await this.$confirm('下载报告后，将自动生成报告记录。可在生成记录里\n' +
                '点击查看。', '提示', {
                confirmButtonText: '确定下载',
                cancelButtonText: '取消',
                type: 'warning'
            })

            this.$message({
                type: 'success',
                message: '下载成功!'
            })

            const MIME_TYPE = 'image/jpeg'
            const imgUrl = this.draw_canvas.children[0].toDataURL(MIME_TYPE)
            let link = document.createElement('a')
            link.download = this.form_data.big_title
            link.href = imgUrl
            link.dataset.downloadurl = [MIME_TYPE, link.download, link.href].join(':')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.saveImg(imgUrl)
        } catch (e) {
            console.log(e)
        }
    }

    async saveImg (imgUrl: string) {
        await this.getAccessToken()
        let formData = new FormData()
        formData.append('file', this.dataURItoBlob(imgUrl))
        this.__apis.reportManage.uploadFile({
            token: this.token,
            filename: this.form_data.big_title,
            file: formData
        }).then((res) => {
            this.saveData(res.url)
        })
    }

    saveData (url: string) {
        this.form_data.showImg = url
        this.form_data.createTime = Date.now()
        if (this.reportIndex === 'week') {
            this.form_data.title.season = ''
        } else if (this.reportIndex === 'month') {
            this.form_data.title.week = ''
            this.form_data.title.season = ''
        } else {
            this.form_data.title.week = ''
            this.form_data.title.month = ''
        }
        this.__apis.reportManage.listStoragePush({
            list: ReportManageStorageName.HISTORY,
            data: this.form_data
        })
    }

    // base64转成blob对象
    dataURItoBlob (base64Data: any) {
        let byteString
        if (base64Data.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(base64Data.split(',')[1])
        } else byteString = unescape(base64Data.split(',')[1])
        let mimeString = base64Data
            .split(',')[0]
            .split(':')[1]
            .split(';')[0]
        let ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ia], { type: mimeString })
    }

    menuTopSelect (tab: any) {
        this.form_scroll.scrollTop = 0
        this.menuIndex = '1'
        this.reportIndex = tab
        this.tab_left_list[1].title = this.tab_left_list_text[this.reportIndex].title1
        this.tab_left_list[3].title = this.tab_left_list_text[this.reportIndex].title2
        this.tab_left_list[4].title = this.tab_left_list_text[this.reportIndex].title4
        switch (tab) {
        case 'week':
            this.form_data.big_title = '奇思妙行周报'
            break
        case 'month':
            this.form_data.big_title = '奇思妙行月报'
            break
        case 'season':
            this.form_data.big_title = '奇思妙行季报'
            break
        default:
            break
        }
    }

    menuLeftSelect (tab: any) {
        this.menuIndex = tab
    }

    addInput (index: string) {
        this.department_list[index].input_number++
    }

    addEvent () {
        this.form_data.big_event.push({
            title: '',
            fileList: []
        })
        this.big_event_input_length++
        this.$nextTick(() => {
            this.form_scroll.scrollTop = 9999
        })
    }

    anchorScroll (scrollTop: number) {
        this.form_scroll.scrollTop = scrollTop
    }

    uploadSuccess (file: any) {
        this.form_data.big_event[this.big_event_upload_index].fileList.push(file.url)
    }

    uploadClick (index: number) {
        this.big_event_upload_index = index - 1
    }

    achievementRankInput (key: any) {
        this.errorCheck('4')
        this.__apis.utils.searchUser({
            key,
            fxs: 1
        }).then((res: any) => {
            this.person = res.data.list.map((res: any) => {
                return {
                    label: res.Username,
                    value: res.Username + ',' + res.groupName + ',' + this.avatarDefault(res.Avatar),
                    key: res.Id
                }
            })
        })
    }

    uploadRemove (file: any, fileList: any) {
        setTimeout(() => {
            this.form_data.big_event[this.big_event_upload_index].fileList = []
            this.form_data.big_event[this.big_event_upload_index].fileList = fileList.map((res: any) => res.response.url)
        }, 200)
    }

    uploadBefore () {
        if (this.__localStorage.getLocalStorage(storageName.REPORTMANAGEUPLOADTOKEN)) {
            const token_info = this.__localStorage.getLocalStorage(storageName.REPORTMANAGEUPLOADTOKEN)
            if (Date.now() > token_info.expires_in) {
                this.getAccessToken()
            } else {
                this.token = token_info.token
            }
        } else {
            this.getAccessToken()
        }
    }

    getAccessToken () {
        this.__apis.reportManage.uploadFileAccessToken().then((res) => {
            this.token = res.access_token
            this.__localStorage.setLocalStorage(storageName.REPORTMANAGEUPLOADTOKEN, {
                token: res.access_token,
                expires_in: res.expires_in * 1000 + Date.now()
            })
        })
    }

    checkTitle () {
        this.errorCheck('2')
    }

    checkAchievement () {
        this.errorCheck('3')
    }

    checkMoneyBack () {
        this.errorCheck('5')
    }

    checkNetSign () {
        this.errorCheck('6')
    }

    tabBarChange (tab: 'manage' | 'history') {
        this.tabBarIndex = tab
        if (tab === 'history') {
            this.getHistoryData()
        }
    }

    getHistoryData () {
        this.__apis.reportManage.listStorageList({
            list: ReportManageStorageName.HISTORY
        }).then((res:any) => {
            this.tableDataAll = res.list.map((res:any) => {
                return {
                    title: res.data.big_title,
                    date: res.data.title.year + '年 ' + res.data.title.month + ' ' + res.data.title.week + ' ' + res.data.title.season,
                    showImg: res.data.showImg,
                    createTime: res.data.createTime ? new Date(res.data.createTime).format('yyyy-MM-dd') : '',
                    id: res.id
                }
            })
            this.pageTotal = this.tableDataAll.length
            this.tableData = this.tableDataAll.slice(0, 10)
        })
    }

    showImg (index: number) {
        open((this.tableData[index] as any).showImg)
    }

    async deleteImg (id: number) {
        try {
            await this.$confirm('删除之后无法恢复,你确定要删除吗?', '提示', {
                confirmButtonText: '确定删除',
                cancelButtonText: '取消',
                type: 'warning'
            })

            const res = await this.__apis.reportManage.listStorageDelete({
                list: ReportManageStorageName.HISTORY,
                id
            })

            if (res.errcode === 0) {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                })
                this.getHistoryData()
            }
        } catch (e) {
            console.log(e)
        }
    }

    handleCurrentChange (page: number) {
        this.tableData = []
        this.tableData = this.tableDataAll.slice((page - 1) * 10, (page - 1) * 10 + 10)
    }
}
