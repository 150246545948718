import { BaseRes, BaseSubmitRes, ListReq, ListRes, php, pp, PubbyListRes, java, ListRes2 } from '@/api/index'
import { dk_pre_list1 } from '@/api/home'
import { User } from '@/store/user'
import { LabelValueArray } from '@/types'
import { __utils } from '@/util'
import { __localStorage, storageName } from '@/localStorage'
import { apiConfig } from '@/api/config'

export interface userInfo6 {
    UserName: string
    Avatar: string
    Department: number
    RoleName: string
    RoleId: number
    DepartmentName: string
    CommonDepartment: number
    CommonDepartmentName: string
}

export interface getRepeatPhoneCustomerListRes {
    demand_area: string
    // 首付预算
    demand_budget: string
    demand_budget_total: string
    id: number
    name: string
    impt_group: {
        text: string,
        value: number
    }
    number: number
    type: string
    house_id: number
    daikan_time: string
    register_time: string
    phone_dispatch_status?: number
    tag: string
    dep: number
    dep_name: string
    group: {
        name: string
    }
    cps_id: false
    ip: string
    ip_address_data: {
        code: number
        data: {
            ip: string
            country: string
            area: string
            region: string
            city: string
            county: string
            isp: string
            country_id: string
            area_id: string
            region_id: string
            city_id: string
            county_id: string
            isp_id: string
        }
    }
    mobile_location: string
    u_keyword: string
    u_type: string
    u_other: string
    u_channel: string
    search_word: string
    demand_region: string
    last_follow_time: string
    sys_tags: User['sysTags']
    status: number
    ip_address_string: string
    saleman: string
    saleman_new: string
    fenxishi: string
    is_repeat: number
    type_array: string[]
    house_name: string
    first_follow_time: number
    first_follow_time_diff: string
    first_follow_user_id: string
    mobile_location_province: string
    mobile_location_city: string
    cj_user_info: any[]
    city: string
    mobile_visible: false
    mobile_fixed: string
    brief_remark: any[]
    brief_remark_gl: any[]
    is_pub_pool: number
    has_take: false
    HasJoinContactsInfo: false
    mobile_valid: number
    need_show_mobile_button: boolean
    mark: number
    share_info_list: {
        customer_id: number
        id: number
        shared_user_id: number
        shared_user_name: string
    }[]

    can_show_invalid_apply_button: boolean // 申请按钮是否显示
    can_click_invalid_apply_button: boolean // 申请按钮是否可以点击
    can_show_begin_follow_button: boolean // 是否显示开始跟进按钮
}

interface createCustomerReq {
    name: string
    mobile: string
    customer_house: string | number
    bind_customer_id?: number
    link_id?: string
}

interface customerListReq extends ListReq {
    type?: string
    sort_field?: string
    sort_mode?: string
    impt_group?: number
    dep?: number,
    group?: number,
    user_id?: number
    yesterdayToPubPool?: string | number
    groupPubPool?: string | number
    sys_tag?: string
    ctime_begin?: string
    ctime_stop?: string
    last_follow_time_begin?: string
    last_follow_time_stop?: string
    ctime?: string,
    last_follow_time?: string,
    name?: string,
    mobile?: string,
    house?: string,
    tag?: string,
    intention_area?: string,
    keywords?: string,
    source_type?: string,
    cj_dep?: string,
    cj_group?: string,
    cj_fxs?: string
    is_not_follow?: number
    mark?: number
    shared_user_id?: number
}

export interface customerListRes extends ListReq {
    res: ListRes<null> & {
        customers: getRepeatPhoneCustomerListRes[]
    }
    init_data: any[]
}

interface customerSaveReq {
    id: number
    name?: string
    impt_group?: number
    mobile?: string
    customer_house?: number
    tag?: string
    sys_tags?: string
    demand_budget_total?: string
    demand_area?: string
    demand_region?: string
    demand_budget?: string
    log?: string
    next_follow_time?: string
    ex_mobile_and_house?: string
    __ignore_filter_value?: boolean
}

interface reviewCustomerInvalidApplyRes {
    id: number
    status: number
    tag: string
    log: string
}

interface invalidCustomerStatisticReq {
    time_start: string
    time_end: string
    index: number
}

interface invalidCustomerStatisticRes {
    [index: number]: {
        name: string
        count: number
    }
}

interface getInvalidCustomerApplyListReq extends ListReq {
    status: number
}

interface getInvalidCustomerApplyListRes extends ListRes <{
    Id: number
    UserId: number
    Dep: number
    Gro: number
    CustomerId: number
    Status: number
    ExtData: string
    Ctime: string
    Mtime: string
    UserInfo: userInfo6
    CustomerInfo: getRepeatPhoneCustomerListRes
}> {}

interface saveCustomerReq {
    id: string,
    remark_name: string,
    note: string,
    group_id: string,
    user_tags: string
}

interface saveDkPreReq {
    id: number
    house_name: string
    action_time: string
    pre_work: string
    customer_info: string
    status: number
}

interface deleteDkCjReq {
    type: string
    id: number
}

interface getDkPreListReq extends ListReq {
    customer_id: string
}

interface saveDkCjCommentReq {
    record_id: string
    content: string
    type: number
}

export type customerDetailRes = getRepeatPhoneCustomerListRes & {
    JoinContactsInfo: {
        Id: number
        UserId: number
        CustomerId: number
        GroupId: number
        RemarkUsername: string
        Note: string
        UserTags: string
        SystemTags: string
        Phone: string
        ExtData: string
        Ctime: string
        Mtime: string
    }
    ex_mobile_and_house: {
        phone_list: string[]
        house_list: {
            label: string
            value: number
        }[]
    }
    tk_reject_reason_list?: {
        date?: string
        reason: string
        timeStamp: number
        username: string
    }[]

    ptt_call_record_audio_url: string

    can_show_invalid_apply_button: boolean // 申请按钮是否显示
    can_click_invalid_apply_button: boolean // 申请按钮是否可以点击
    can_show_begin_follow_button: boolean // 是否显示开始跟进按钮
}

interface getCustomerListReq {

}

interface zhuanjiaoReq {
    customer_id: number
    to_user_id: number
}

interface CancelMyContactsReq {
    id?: string
    customer_id?: string
}

interface getCjResListReq extends ListReq {
    customer_id: string
}

interface getCjResListRes extends ListRes<dk_pre_list1 & {
    ChannelUsername: null | string
    ChannelName: null | string
    ChannelTel: null | string
    ZygwName: null | string
    ZygwTel: null | string
    Imgs: string[]
    CustomerInfo: string
    Result: string
    cj_probability_level?: number
}> {}

interface saveDkResReq {
    id: number
    customer_id: number
    customer_name: string
    house_name: string
    action_time: number
    customer_info: string
    channel_name: string
    channel_username: string
    channel_tel: string
    zygw_name: string
    zygw_tel: string
    after_work:string
    ext_data: string
    user_name: string
}

interface getFxsAreaHouseSearchReq {
    'search-keyword'?: string
    country_id?: any
}

interface saveFxsAreaReq {
    id: number
    place_info: string
}

interface getFxsAreaByGroupIdReq {
    group_id: number
}

interface saveOrderCustomerReq {
    accept_re_push?: number
    'type1_count': number
    'place_info': string
    is_additional?: string
}

interface getCustomerOrderRecordReq extends ListReq {
    'user_id'?: number
    'dep_id'?: number
    'group_id'?: number
    order_time_start?: string
}

interface examineInvalidCustomerReq {
    'id': number
    'status': number
    reject_reason?: string
}

interface getDailyWorkExamineListReq extends ListReq {
    'type'?: number
    status?: number,
    group_id?: number,
    user_id?: number,
    time_start?: string,
    time_end?: string
}

interface examineDailyWorkExamineReq {
    id: number,
    status: number,
    reject_reason: string
    is_controversial?: number
    remark?: string
}

export interface getOrderInitInfoRes {
    area: {
        id: string
        name: string
    }[]
    next_week_remain_count: number // // 剩余可预约数量
    remain_count: number // 剩余补预约数量
    max_overdraft: number // 最大透支金额
    max_order_count: number // 最大预约数量
    has_order_count: number // 本月已经预约数量
    next_month_has_order_count: number // 下月已经预约数量
    show_accept_re_push_button: boolean
    price: {
        start_count: number
        end_count: number
        price: number
    }[]
}

interface getCustomerOrderRecordRes extends ListRes<{
    Id: number
    UserId: number
    DepId: number
    GroupId: number
    Type1Count: number
    Type1CompleteCount: number
    HouseMatchCount: number
    InvalidCount: number
    PlaceInfo: {
        house_id: number
        house_name: string
        area_list: {
            id: number
            name: string
        }[]
    }
    MoneyUse: {
        fxs_money: number,
        dz_money: number,
        fxs_epid: number,
        dz_epid: number,
        fxs_money_per: number,
        dz_money_per: number
    }
    OrderTimeStart: string
    OrderTimeEnd: string
    Status: number
    OrderType: number
    Ctime: string
    Mtime: string
    CanCancel: boolean
    UserInfo: userInfo6
}>{
    statistic_res: {
        count1: string
        complete_count: string
    }
}

export interface customerOrderReportQueryReq {
    pagesize?: number
    start_date: string
    end_date: string
    group_by: 'team' | 'user' | 'department' | 'area'
    dep_ids: number[]
    team_ids: number[]
    aggregate?: boolean
}

export interface customerOrderReportQueryRes {
    complete_customers: number
    department_name: string
    house_match_count: number
    house_match_ratio: number
    house_name: string
    invalid_count: number
    invalid_ratio: number
    order_customers: number
    team_name: string
    username: string
    percentage: number
    roi: string
}

interface customerOrderQueryByTimeIntervalReq {
    start_date: string
    end_date: string
    dep_ids: number[]
    team_ids: number[]
    aggregate?: boolean
}

export interface customerOrderQueryByTimeIntervalRes {
    complete_customers: number
    house_match_count: number
    house_match_ratio: number
    invalid_count: number
    invalid_ratio: number
    username: string
    date: string
}

interface customerReportQueryByGroupReq {
    dep_ids: number[]
    start_date: string
    end_date: string
    aggregate?: boolean
}

interface customerReportQueryByGroupRes {
    customers: number
    house_name: string
}

interface setGroupOrderAreaReq {
    order_area: {
        data: any[]
        group_id: number
    }[]
    del_area?: {
        data: any[]
        group_id: number
    }[]
}

interface getGroupOrderAreaReq {
    group_id?: number
}

interface getGroupOrderAreaRes extends Array <{
    'group_id': number
    'group_name': string
    'group_order_area': {
        'name': string
        'id': number
    }[]
}>{}

interface createMarketingCustomerReq {
    link_id?: number | string
    type?: number // 1电话 2微信 - 之前的参数
    name: string
    mobile: string
    customer_house: number
    log: string
    customer_type: number
}

interface getTransferLogListReq extends ListReq {
    'customer_phone'?: string
    'customer_id'?: number
    'transfer_user_id'?: number
    'transferred_user_id'?: number
    'start_time'?: string
    'end_time'?: string
    'customer_type'?: string
}

interface getTransferLogListRes extends ListRes<{
    customer_type: number
    transfer_user_name: string
    transferred_user_name: string
    transfer_users_id: number[]
    customer_phone: string
    transfer_groups_id: number[]
    transferred_group_id: number
    transfer_group_id: number
    transfer_user_id: number
    ctime: string
    id: number
    customer_name: string
    customer_id: number
    transferred_user_id: number
}>{
    statistic_res: {
    }
}

interface groupTurnDownRecordReq extends ListReq {}

interface groupTurnDownRecordRes extends ListRes<{
    customer_id: number
    customer_name: string
    customer_phone: string
    fxs_name: string
    mtime: number
}>{}

type getLastPhoneDispatchListRes = customerListRes['res']['customers']

interface addSWTCustomerReq {
    name?: string
    site_url?: string
    house_id: number
    phone: string
    operate_log: string
}

interface ShowPubCustomerMobileRes {
    mobile: string,
    ex_mobile_and_house: {
        phone_list: string[]
    }
}

type GetDaiKanResListReq = ListReq & Partial<{
    group_id: number
    dep_id: number
    user_id: number
    customer_name: string
    house_id: number
    dateQrl: {
        query_start_time: string
        query_end_time: string
    }
    customer_tel: string
}>

type GetDaiKanResListRes = ListRes<{
    id: number
    customer_id: number
    remark: string
    user_names: string[]
    customer_name: string
    ctime: number
    customer_tel: string
    action_time: number
    ext_data: string
    imgs: string
    customer_info: string
    after_work: string
    user_info: string
    cj_probability_level?: number
}>

export type GetInvalidCustomerApplyPageReq = ListReq & Partial<{
    invalid_customer_for_examine: 1
    user_id: number
    dep_id: number
    group_id: number
    customer_id: number
    apply_time: {
        query_start_time: string
        query_end_time: string
    }
    ctime: {
        query_start_time: string
        query_end_time: string
    }
    house_id: number
    status: number
    has_dk: number
    customer_type?: number
}>

type GetInvalidCustomerApplyPageRes = ListRes<{
    id: number
    apply_status: string
    apply_time: number
    apply_user_name: string
    ctime: number
    customer_name: string
    customer_id: string
    customer_type: number
    reject_reason: string
    customer_tel: string
    house_name: string
    dep_id: number
    group_id: number
    logs: {
        operator: string
        remark: string
        time: string
    }[]
}>

interface customerCallReq extends ListReq{
    customer_tel?: string
    customer_name?: string
    registerDate?: {
        query_start_time: string
        query_end_time: string
    }
    dep?: number
    origin?: string // 来源:ftt(400) toutiao(头条)
    mark?: string // 标记
    sort_field?: string
    sort_mode?: string
    mark_list?: string[]
}

interface customerCallRes extends ListRes<{
    'c_time': number,
    'call_status': number,
    'call_video_url': string
    'comein_phone': string,
    'customer_id': number,
    'customer_name': string,
    'customer_type': number,
    'demand_region': string,
    'dep': number,
    'follower_logs': {
        log: string
    }[],
    'has_dispatch': false,
    'house_name': string,
    'house_id': number,
    'id': number,
    'link_id': number,
    'mark': string,
    'mobile_location': string,
    'origin': string,
    'phone': string,
    'register_time': number,
    'sys_tags': string
    type_array: string[]
}>{}

interface addCallInfoReq {
    call_id: number
    house_id: number
    customer_name: string
    sys_tags: string
    operate_logs: string[]
}

interface customerCallInvalidReq {
    call_id: number
    mark: string
}

interface saveCustomerRemarkReq {
    customer_id: number
    remark: string
}

interface claimCustomerListReq {
    id?: string
    page?: number
    pagesize?: number
}

interface claimCustomerListRes {
    page: number
    pagesize: number
    rows: {
        customer_name: string
        demandRegion: string
        id: number
        mobile_hide?: string
        mobile?: string
    }[]
}
interface robCustomerActionRes {
    code: number
    customer_id: number
    message: string
}
interface robCustomerTransferReq {
    id: number
    transferred_user_id: string
}

type InfoSaveCustomerReq = {
    log_mark?: number
    ex_mobile_and_house?: {
        house_list: LabelValueArray
        phone_list: string[]
    }
} & Omit<customerSaveReq, 'ex_mobile_and_house'>

interface GetDepCustomerOrderRecordStatisticsReq {
    startDate: string
    endDate: string
}

interface GetDepCustomerOrderRecordStatisticsRes {
    completeCustomerCount: number
    depId: number
    groupId: number
    userId: number
    houseMatchCount: number
    invalidCount: number
    orderCustomerCount: number
    house_name: string
    percentage: number
    roi: number
}

type GetFxsCustomerOrderRecordStatisticsReq = GetDepCustomerOrderRecordStatisticsReq & {
    group_id: number
}

type GetGroupCustomerOrderRecordStatisticsReq = GetDepCustomerOrderRecordStatisticsReq & {
    dep_id: number
}

type GetOrderRecordHouseHotRankReq = GetGroupCustomerOrderRecordStatisticsReq & {
    type: 'order' | 'online'
}

type GetOrderRecordHouseHotRankRes = {
    house_id: number
    house_name: string
    percentage: number
    size: number
}

type GetDepGroupByDayDistributionsStatisticsRecordReq = GetGroupCustomerOrderRecordStatisticsReq & GetFxsCustomerOrderRecordStatisticsReq

type GetDepGroupByDayDistributionsStatisticsRecordRes = {
    completeCustomerCount: number
    today: string
    dep_id: number
}

interface followCustomerListDtoListQueryReq extends ListReq {
    sort_field?: string,
    sort_mode?: string,
    tag?: string
    'fxs_user_id'?: number,
    'mark'?: number,
    'group_id'?: number,
    dai_kan_count?: string
    es_comparison_type?: string
}

interface followCustomerListDtoListQueryRes {
    rows: getRepeatPhoneCustomerListRes[]
    total: number
    page: number
    pagesize: number
}

interface shareToSomeOneReq {
    'customer_id': number,
    'to_fxs_user_id': number
}

interface getCustomerShareRecordListByCustomerIdRes {
    ctime: number;
    customer_id: number;
    id: number;
    mtime: number;
    share_dep_id: number;
    share_group_id: number;
    share_user_id: number;
    shared_dep_id: number;
    shared_group_id: number;
    shared_user_id: number;
    shared_user_name: string;
    status: number;
}

interface saveCustomerLogReq{
    id: number;
    log: string;
    log_mark: number;
}

interface saveDaiKanReq {
    action_name: string
    action_time: string
    customer_id: number
    customer_name: string
    customer_info: string
    after_work: string
    ext_data: {
        channel_partner_id: number
        channel_name: string
        house_id: number
        house_name: string
        channel_tel: string
        channel_username: string
        zygw_name: string
        zygw_tel: string
    }[]
    imgs: string[]
    user_name: string
    user_info: {
        is_use_car: boolean // 用车
        user_id: number
        user_name: string
    }[]
}

interface getAreaSearchReq {
    dep_id: number
    getFuzzy_search_name?: string
}

interface getAreaSearchRes {
    dep_id: number
    id: number
    name: string
}

export type AreaSearchResList = BaseRes<ListRes2<getAreaSearchRes>>

interface customerInvalidApplyReq{
    customer_id: number;
    apply_reason: string;
}

interface getCustomerInvalidApplyRecordsReq extends ListReq {
    status?: number,
    dep_id?: number,
    gro_id?: number,
    user_id?: number;
    apply_time_start?: string;
    apply_time_end?: string;
}

interface getCustomerInvalidApplyRecordsRes extends ListRes<{
    apply_reason:string,
    apply_time:string,
    apply_user_dep_id:number,
    apply_user_group_id:number,
    apply_user_name:string,
    customer_id:number,
    customer_name:string,
    customer_register_time:string,
    house_name:string,
    id:number,
    mobile:string,
    reject_reason:string,
    status:number
    show_button_status: number // 0 不显示 1显示队长按钮 2显示客服按钮
}> {}

interface customerInvalidCheckReq {
    id: number;
    status: number; // 1驳回   2通过
    reject_reason?: string;
}

interface invalidStatusRes {
    [key: string]: string
}

interface customerBindReq {
    customer_id: number;
    bind_customer_id: number;
}

export default class ApiCustomer {
    // 添加客户跟进
    saveCustomerLog (data: saveCustomerLogReq) {
        return java.post('/admin/customer/info/saveCustomerLog', {
            ...data
        })
    }

    // 快捷操作保存 - java
    infoSaveCustomer (data: InfoSaveCustomerReq) {
        return java.post('/admin/customer/info/saveCustomer', {
            ...data,
            __ignore_filter_value: true
        })
    }

    // 部门发客趋势
    getDepGroupByDayDistributionsStatisticsRecord (data: GetDepGroupByDayDistributionsStatisticsRecordReq): BaseRes<GetDepGroupByDayDistributionsStatisticsRecordRes[]> {
        return java.post('/admin/customer/order/statistics/getDepGroupByDayDistributionsStatisticsRecord', data)
    }

    // 获取楼盘预约热度
    getOrderRecordHouseHotRank (data: GetOrderRecordHouseHotRankReq): BaseRes<GetOrderRecordHouseHotRankRes[]> {
        return java.post('/admin/customer/order/statistics/getOrderRecordHouseHotRank', data)
    }

    // 获取分析师预约数据
    getFxsCustomerOrderRecordStatistics (data: GetFxsCustomerOrderRecordStatisticsReq): BaseRes<GetDepCustomerOrderRecordStatisticsRes[]> {
        return java.post('/admin/customer/order/statistics/getFxsCustomerOrderRecordStatistics', data)
    }

    // 获取部门(所有战队)预定客户统计数据
    getGroupCustomerOrderRecordStatistics (data: GetGroupCustomerOrderRecordStatisticsReq): BaseRes<GetDepCustomerOrderRecordStatisticsRes[]> {
        return java.post('/admin/customer/order/statistics/getGroupCustomerOrderRecordStatistics', data)
    }

    // 获取部门预定客户统计数据
    getDepCustomerOrderRecordStatistics (data: GetDepCustomerOrderRecordStatisticsReq): BaseRes<GetDepCustomerOrderRecordStatisticsRes[]> {
        return java.post('/admin/customer/order/statistics/getDepCustomerOrderRecordStatistics', data)
    }

    // 无效客户列表 - java
    getInvalidCustomerApplyPage (data: GetInvalidCustomerApplyPageReq): BaseRes<GetInvalidCustomerApplyPageRes> {
        return java.post('/admin/customer/invalid/apply/page', data)
    }

    // 获取带看总结列表 - java
    getDaiKanResList (data: GetDaiKanResListReq): BaseRes<GetDaiKanResListRes> {
        return java.post('/admin/daikan/res/page', {
            ...data
        })
    }

    // 显示公池号码
    showPubCustomerMobile (id: number): BaseRes<ShowPubCustomerMobileRes> {
        return php.post('', {
            action_name: 'showPubCustomerMobile',
            customer_id: id
        })
    }

    // 添加客户
    addSWTCustomer (data: addSWTCustomerReq) {
        return java.post('/admin/house/detail/weChat/customer/createShangWuTongCustomer', data)
    }

    // 分配列表
    getLastPhoneDispatchList (): BaseRes<getLastPhoneDispatchListRes> {
        return php.post('', {
            action_name: 'getLastPhoneDispatchList'
        })
    }

    // 获取退客驳回记录
    groupTurnDownRecord (data: groupTurnDownRecordReq): BaseRes<groupTurnDownRecordRes> {
        return java.post('/admin/customer/invalid/apply/groupTurnDownRecord', data)
    }

    // 预定客户发客趋势信息
    async customerOrderQueryByTimeInterval (data: customerOrderQueryByTimeIntervalReq): PubbyListRes<customerOrderQueryByTimeIntervalRes> {
        const dep_ids = data.dep_ids
        const team_ids = data.team_ids
        delete data.dep_ids
        delete data.team_ids

        const response = await this.getDepGroupByDayDistributionsStatisticsRecord({
            startDate: data.start_date,
            endDate: data.end_date,
            dep_id: dep_ids[0],
            group_id: team_ids[0]
        })

        return {
            rows: response.data.map(a => {
                return {
                    date: a.today,
                    complete_customers: a.completeCustomerCount,
                    house_match_count: 0,
                    house_match_ratio: 0,
                    invalid_count: 0,
                    invalid_ratio: 0,
                    username: ''
                }
            }),
            total: 0,
            total_found: 0
        }
    }

    // 预约客户统计
    async customerOrderReportQuery (data: customerOrderReportQueryReq): PubbyListRes<customerOrderReportQueryRes> {
        const dep_ids = data.dep_ids
        const team_ids = data.team_ids
        delete data.dep_ids
        delete data.team_ids

        let res !: GetDepCustomerOrderRecordStatisticsRes[]

        if (data.group_by === 'department') {
            res = (await this.getDepCustomerOrderRecordStatistics({
                startDate: data.start_date,
                endDate: data.end_date
            })).data
        } else if (data.group_by === 'team') {
            res = (await this.getGroupCustomerOrderRecordStatistics({
                startDate: data.start_date,
                endDate: data.end_date,
                dep_id: dep_ids[0]
            })).data
        } else if (data.group_by === 'user') {
            res = (await this.getFxsCustomerOrderRecordStatistics({
                startDate: data.start_date,
                endDate: data.end_date,
                group_id: team_ids[0]
            })).data
        } else if (data.group_by === 'area') {
            const response = (await this.getOrderRecordHouseHotRank({
                startDate: data.start_date,
                endDate: data.end_date,
                dep_id: dep_ids[0],
                type: 'order'
            })).data

            res = response.map(a => {
                return {
                    completeCustomerCount: 0,
                    depId: 0,
                    groupId: 0,
                    userId: 0,
                    houseMatchCount: 0,
                    invalidCount: 0,
                    orderCustomerCount: a.size,
                    house_name: a.house_name,
                    percentage: a.percentage,
                    roi: 0
                }
            })
        }

        return {
            rows: res.map(a => {
                return {
                    dep_id: a.depId,
                    complete_customers: a.completeCustomerCount,
                    department_name: __utils.index.getDepInfoById(a.depId).v,
                    house_match_count: a.houseMatchCount,
                    house_match_ratio: a.houseMatchCount / a.completeCustomerCount,
                    house_name: a.house_name,
                    invalid_count: a.invalidCount,
                    invalid_ratio: a.invalidCount / a.completeCustomerCount,
                    order_customers: a.orderCustomerCount,
                    team_name: __utils.index.getGroupInfoById(a.groupId).name,
                    username: __utils.index.getUserInfoById(a.userId).v,
                    percentage: a.percentage,
                    roi: (a.roi || 0).toFixed(1)
                }
            }),
            total: 0,
            total_found: 0
        }
    }

    // 重复客户列表
    getRepeatPhoneCustomerList (id: number): BaseRes<getRepeatPhoneCustomerListRes[]> {
        return php.post('', {
            action_name: 'getRepeatPhoneCustomerList',
            id
        })
    }

    // 认领客户
    renlin (id: number) {
        return php.post('', {
            action_name: 'renlin',
            id
        })
    }

    // 取消认领客户
    cancelRenlin (id: number) {
        return php.post('', {
            action_name: 'cancelRenlin',
            id
        })
    }

    // 新增客户
    createCustomer (data: createCustomerReq) {
        return php.post('', {
            action_name: 'createCustomer',
            ...data
        })
    }

    // 客户列表
    customerList (data: customerListReq): BaseRes<customerListRes> {
        return php.post('', {
            action_name: 'customerList',
            ...data
        })
    }

    // 快捷操作保存
    customerSave (data: customerSaveReq) {
        return php.post('', {
            action_name: 'customerSave',
            ...data
        })
    }

    // 客户保存
    saveCustomer (data: saveCustomerReq) {
        return php.post('', {
            action_name: 'saveCustomer',
            ...data
        })
    }

    // 查看无效客户审核
    reviewCustomerInvalidApply (data: reviewCustomerInvalidApplyRes) {
        return php.post('', {
            action_name: 'reviewCustomerInvalidApply',
            ...data
        })
    }

    // 无效客户
    invalidCustomerStatistic (data: invalidCustomerStatisticReq): BaseRes<invalidCustomerStatisticRes> {
        return php.post('', {
            action_name: 'invalidCustomerStatistic',
            ...data
        })
    }

    // 无效客户审核列表
    getInvalidCustomerApplyList (data: getInvalidCustomerApplyListReq): BaseRes<getInvalidCustomerApplyListRes> {
        return php.post('', {
            action_name: 'getInvalidCustomerApplyList',
            ...data
        })
    }

    // 保存下次带看
    saveDkPre (data: saveDkPreReq) {
        return php.post('', {
            action_name: 'saveDkPre',
            ...data
        })
    }

    // 删除下次带看
    deleteDkCj (data: deleteDkCjReq) {
        return php.post('', {
            action_name: 'deleteDkCj',
            ...data
        })
    }

    // 获取带看列表
    getDkPreList (data: getDkPreListReq): BaseRes<getCjResListRes> {
        return php.post('', {
            action_name: 'getDkPreList',
            ...data
        })
    }

    // 获取带看列表
    getDkResList (data: getDkPreListReq): BaseRes<getCjResListRes> {
        return php.post('', {
            action_name: 'getDkResList',
            ...data
        })
    }

    // 保存带看评论
    saveDkCjComment (data: saveDkCjCommentReq) {
        return php.post('', {
            action_name: 'saveDkCjComment',
            ...data
        })
    }

    // 客户详情
    customerDetail (id: string): BaseRes<customerDetailRes> {
        return java.post('/admin/customer/info/getCustomerDetail', {
            id
        })
    }

    // 客户列表
    getCustomerList (data: customerListReq): BaseRes<customerListRes> {
        return java.post('/admin/customer/info/getCustomerList', data)
    }

    // 转交
    zhuanjiao (data: zhuanjiaoReq) {
        return php.post('', {
            action_name: 'zhuanjiao',
            ...data
        })
    }

    // 标记为无效客户
    doApplyCustomerInvalid (customer_id: string) {
        return php.post('', {
            action_name: 'doApplyCustomerInvalid',
            customer_id
        })
    }

    // 保存编辑成交
    saveCjRes (data: any) {
        return php.post('', {
            action_name: 'saveCjRes',
            ...data
        })
    }

    // 获取成交列表
    getCjResList (data: getCjResListReq): BaseRes<getCjResListRes> {
        return php.post('', {
            action_name: 'getCjResList',
            ...data
        })
    }

    // 保存带看总结
    saveDkRes (data: saveDkResReq) {
        return php.post('', {
            action_name: 'saveDkRes',
            ...data
        })
    }

    // 新的添加带看总结
    addDkResForExamine (data: saveDkResReq) {
        return php.post('', {
            action_name: 'addDkResForExamine',
            ...data
        })
    }

    // 新的添加成交总结
    addSaleRecordForExamine (data: any) {
        return php.post('', {
            action_name: 'addSaleRecordForExamine',
            ...data
        })
    }

    // 预约客户
    saveOrderCustomer (data: saveOrderCustomerReq) {
        return php.post('', {
            action_name: 'saveOrderCustomer',
            ...data
        })
    }

    // 预约记录
    async getCustomerOrderRecord (data: getCustomerOrderRecordReq): BaseRes<getCustomerOrderRecordRes> {
        const res = await php.post('', {
            action_name: 'getCustomerOrderRecord',
            ...data
        }) as any

        res.data.list.forEach((a: any) => {
            a.PlaceInfo = JSON.parse(a.PlaceInfo) || {}
            a.MoneyUse = JSON.parse(a.MoneyUse) || {}
        })

        return res
    }

    // 预约客户详情
    getCustomerOrderWithCustomerRecordList (id:number) {
        return php.post('', {
            action_name: 'getCustomerOrderWithCustomerRecordList',
            order_id: id
        })
    }

    // 取消预约
    cancelOrderCustomer (id:number) {
        return php.post('', {
            action_name: 'cancelOrderCustomer',
            id: id
        })
    }

    // 申请无效客户
    applyInvalidCustomer (id: number) {
        return php.post('', {
            action_name: 'applyInvalidCustomer',
            customer_id: id
        })
    }

    // 无效客户审核
    examineInvalidCustomer (data: examineInvalidCustomerReq) {
        return php.post('', {
            action_name: 'examineInvalidCustomer',
            ...data
        })
    }

    // 带看成交审核列表
    getDailyWorkExamineList (data: getDailyWorkExamineListReq) {
        return php.post('', {
            action_name: 'getDailyWorkExamineList',
            ...data
        })
    }

    // 带看成交审核
    examineDailyWorkExamine (data: examineDailyWorkExamineReq) {
        return php.post('', {
            action_name: 'examineDailyWorkExamine',
            ...data
        })
    }

    // 根据战队id获取战队所有人的预约区域信息
    getFxsAreaByGroupId (data: getFxsAreaByGroupIdReq) {
        return php.post('', {
            action_name: 'getFxsAreaByGroupId',
            ...data
        })
    }

    // 保存 战队分析师 预约区域
    saveFxsArea (data: saveFxsAreaReq) {
        return php.post('', {
            action_name: 'saveFxsArea',
            ...data
        })
    }

    // 获取当前分析师的 预约区域
    getOrderInitInfo (): BaseRes<getOrderInitInfoRes> {
        return php.post('', {
            action_name: 'getOrderInitInfo'
        })
    }

    // 获取客户预约金额
    getFxsOrderPerMoney () {
        return php.post('', {
            action_name: 'getFxsOrderPerMoney'
        })
    }

    // 当前分析师的预约区域，获取可搜索楼盘
    getFxsAreaHouseSearch (data: getFxsAreaHouseSearchReq) {
        let params:any = {
            action_name: 'houseSearch',
            ...data
        }
        return php.post('', params, {
            transformRequest: [function () {
                let ret = ''
                for (let it in params) {
                    if (it === 'country_id') {
                        let str = params[it].map((v:any) => {
                            return encodeURIComponent('country_id[]') + '=' + encodeURIComponent(v) + '&'
                        })
                        ret += str.join('')
                    } else {
                        ret += encodeURIComponent(it) + '=' + encodeURIComponent(params[it]) + '&'
                    }
                }
                return ret
            }]
        })
    }

    // 预约选择楼盘验证
    orderCustomerHouseCheck (house_id: number) {
        return php.post('', {
            action_name: 'orderCustomerHouseCheck',
            house_id,
            ignore: 1
        })
    }

    // 设置战队预定区域
    setGroupOrderArea (data: setGroupOrderAreaReq): BaseSubmitRes {
        return java.post('/admin/group/customer/order/setGroupOrderArea', data)
    }

    // 获取战队预定区域
    getGroupOrderArea (data:getGroupOrderAreaReq): BaseRes<getGroupOrderAreaRes> {
        return java.post('/admin/group/customer/order/getGroupOrderArea', data)
    }

    // 添加广告客户
    createMarketingCustomer (data: createMarketingCustomerReq): BaseSubmitRes {
        return php.post('', {
            action_name: 'createMarketingCustomer',
            ...data
        })
    }

    // 转交记录
    getTransferLogList (data: getTransferLogListReq): BaseRes<getTransferLogListRes> {
        return java.post('/admin/customer/transfer/log/getTransferLogList', data)
    }

    // 电话客户
    async customerCall (data: customerCallReq): BaseRes<customerCallRes> {
        const res = await java.post('/admin/customerCall/page', data)
        res.data.list.forEach((a: any) => {
            a.follower_logs = a.follower_logs === '' ? [] : JSON.parse(a.follower_logs)
        })
        return res as any
    }

    // 电话客户设置楼盘
    addCallInfo (data: addCallInfoReq) {
        return java.post('/admin/customerCall/addCallInfo', data)
    }

    // 电话客户标记无效
    customerCallInvalid (data: customerCallInvalidReq) {
        return java.post('/admin/customerCall/mark', data)
    }

    // 电话客户分配
    customerCallDispatch (id: number, dispatch_user?: number, override_type?: number) {
        return java.post('/admin/customerCall/dispatch', {
            id,
            preAllocatedSaleMan: dispatch_user,
            overrideType: override_type
        })
    }

    // 进线丢入公池
    toPubPool (id: number) {
        return java.post('/admin/customerCall/toPubPool', {
            value: id
        })
    }

    // 存储客户快速备注
    saveCustomerRemark (data: saveCustomerRemarkReq) {
        return java.post('/admin/customer/info/saveCustomerRemark', data)
    }

    // 关注 - 是否关注
    getCustomerIsFollow (customer_id: number):BaseRes<{id: number}> {
        return java.post('/admin/follow/customer/isFollow', { customer_id })
    }
    // 关注 - 保存关注客户
    saveFollowCustomer (customer_id: number) {
        return java.post('/admin/follow/customer/saveFollowCustomer', { customer_id })
    }
    // 关注 - 取消关注
    deleteFollowCustomer (id: number) {
        return java.post('/admin/follow/customer/deleteFollowCustomer', { id })
    }
    // 关注 - 关注客户列表
    followCustomerListDtoListQuery (data: followCustomerListDtoListQueryReq): BaseRes<followCustomerListDtoListQueryRes> {
        return java.post('/admin/follow/customer/followCustomerListDtoListQuery', data)
    }

    // 共享-共享客户给某人
    shareToSomeOne (data: shareToSomeOneReq) {
        return java.post('/admin/customer/share/shareToSomeOne', data)
    }
    // 共享-取消共享客户
    cancelShare (ids: number[]) {
        return java.post('/admin/customer/share/cancelShare', { ids })
    }
    // 共享-退出共享
    exitShare (customer_id: number) {
        return java.post('/admin/customer/share/exitShare', { customer_id })
    }
    // 共享-获取当前客户所有共享分析师
    getCustomerShareRecordListByCustomerId (customer_id: number): BaseRes<getCustomerShareRecordListByCustomerIdRes[]> {
        return java.post('/admin/customer/share/getCustomerShareRecordListByCustomerId', { customer_id })
    }

    // 添加带看
    daikanSave (data: saveDaiKanReq) {
        return java.post('/admin/daikan/operaction/daikanSave', data)
    }

    // 认领客户列表
    getWaitRobCustomerList (data: claimCustomerListReq) {
        return java.post('/admin/rob/customer/getWaitRobCustomerList', data)
    }

    // 已认领客户列表
    getHadRobCustomerList (data: claimCustomerListReq) {
        return java.post('/admin/rob/customer/getHadRobCustomerList', data)
    }

    // 获取最新客户
    getRobotCallNewCustomer () {
        return java.post('/admin/rob/customer/getRobotCallNewCustomer', { })
    }

    // 认领客户
    robCustomerAction (id: number, type: number): BaseRes<robCustomerActionRes> {
        return java.post('/admin/rob/customer/robCustomerAction', { id, type })
    }

    // 转交客户
    robCustomerTransfer (data: robCustomerTransferReq) {
        return java.post('/admin/rob/customer/robCustomerTransfer', data)
    }

    // 今日不再弹出
    todayNonPop () {
        return java.post('/admin/rob/customer/todayNonPop', {})
    }

    // 楼盘集合-搜索
    getAreaSearch (data: getAreaSearchReq): BaseRes<ListRes2<getAreaSearchRes>> {
        return java.post('/admin/house/area/manage/getAreaSearch', data)
    }

    // 反馈无效-提交申请
    customerInvalidApply (data: customerInvalidApplyReq) {
        return java.post('/admin/customer/invalid/customerInvalidApply', {
            ...data,
            __ignore_filter_value: true
        })
    }

    // 反馈无效-审核列表
    getCustomerInvalidApplyRecords (data: getCustomerInvalidApplyRecordsReq): BaseRes<getCustomerInvalidApplyRecordsRes> {
        return java.post('/admin/customer/invalid/getCustomerInvalidApplyRecords', data)
    }

    // 反馈无效-审核
    customerInvalidCheck (data: customerInvalidCheckReq) {
        return java.post('/admin/customer/invalid/customerInvalidCheck', data)
    }

    // 反馈无效-审核状态
    invalidStatus (): BaseRes<invalidStatusRes> {
        return java.post('/admin/customer/invalid/invalidStatus', {})
    }

    // 开始跟进客户
    beginFollow (id: number) {
        return java.post('/admin/customer/info/beginFollow', { id })
    }

    // 客户导出
    customerExport (data: customerListReq) {
        return java.post('/admin/customer/download', data, {
            responseType: 'blob'
        })
    }
}
