import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class HeaderComponent extends Vue {
    @Prop({
        type: String,
        default: ''
    })
    title !: string

    @Prop({
        type: String,
        default: ''
    })
    desc !: string

    @Prop({
        type: Boolean,
        default: false
    })
    showBread !: boolean

    @Prop({
        type: String,
        default: '/'
    })
    separator !: string

    @Prop({
        type: Array,
        default () {
            return []
        }
    })
    breadcrumbs !: {label: string, path?: string}[]

    @Watch('title')
    watchTitle (newVal: string) {
        const len = this.breadcrumbs.length - 1
        this.breadcrumbs[len].label = newVal
    }

    created () {
        this.breadcrumbs.push({
            label: this.title
        })
    }
}
