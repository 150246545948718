import { Component, Vue, Watch } from 'vue-property-decorator'
import navTab from '@/components/NavTab/index.vue'
import qmPermission from 'qm-plugin-admin-permission'
import { __role } from '@/role'
import router from '@/router'

const menus = [
    {
        name: '答题记录',
        route: { path: '/questionBank/answerRecord' },
        authKey: __role.permissions.ShowQuestionAnswerRecordPage
    },
    {
        name: '题库统计',
        route: { path: '/questionBank/statistics' },
        authKey: __role.permissions.ShowQuestionBankStatisticPage
    },
    {
        name: '题库管理',
        route: { path: '/questionBank/manage' },
        authKey: __role.permissions.ShowQuestionBankMangePage
    }
]

qmPermission.filter(menus)

@Component({
    components: {
        navTab
    }
})
export default class QuestionBankIndex extends Vue {
    menus = menus

    @Watch('$route')
    watchRoute (to: any) {
        if (to.fullPath === '/questionBank') {
            router.replace(menus[0].route)
        }
    }

    created () {
        if (this.$route.fullPath === '/questionBank') {
            this.menus[0] && this.$router.push(this.menus[0].route)
        }
    }
}
