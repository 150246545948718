import { Component, Prop, Vue } from 'vue-property-decorator'
import { EventBus, EventNames, filterId } from '@/util/bus'

@Component
export default class FiltersNumberRange extends Vue {
    value = []
    visible = false
    isEnter = false
    id = 0

    @Prop({
        type: String,
        default: ''
    })
    title !: string

    @Prop({
        type: String,
        default: ''
    })
    propName !: string

    @Prop({
        type: String,
        default: '请输入'
    })
    placeholder !: []

    @Prop({
        type: String,
        default: ''
    })
    componentId !: string

    created () {
        this.id = filterId.value
        filterId.value++
        EventBus.$on(EventNames.clearFilter, this.clear)
    }

    destroyed () {
        EventBus.$off(EventNames.clearFilter, this.clear)
    }

    // 这条筛选被清空
    clear (id: number) {
        if (this.id === id) {
            this.value = []
            this.isEnter = false
        }
    }

    closeRangEditor () {
        this.value = []
    }

    cancel () {
        this.visible = false
    }

    enter () {
        // 这里需要通知筛选词条组件进行更新
        EventBus.$emit(EventNames.filterChange + this.componentId, {
            title: this.title + '：',
            content: this.value,
            id: this.id,
            value: {
                [this.propName]: this.value
            }
        })

        this.isEnter = true
        this.cancel()
    }
}
