import { BaseRes, java, ListReq, ListRes, php, ListRes2 } from '@/api/index'
import { getRepeatPhoneCustomerListRes, userInfo6 } from '@/api/customer'

interface getRenlinWorkInfoReq {
    dep: number
    group: number
    time_start: string
    time_end: string
}

interface getRenlinWorkInfoRes {
    group_name: {
        name: string
        value: number
        dep: number
    }
    new: {
        fxs_list: {
            user_name: string
            count: number
            user_id: number
        }[]
        total: number
    }
    old: getRenlinWorkInfoRes['new']
    all: getRenlinWorkInfoRes['new']
}

interface getFollowLogWorkInfoRes {
    group_name: getRenlinWorkInfoRes['group_name']
    valid: getRenlinWorkInfoRes['new']
    invalid: getRenlinWorkInfoRes['new']
}

interface changeLogTagReq {
    log_id: number
    is_valid: string
}

interface getFollowLogListReq extends ListReq {
    dep: number
    time_start: string
    time_end: string
    is_valid: string
}

interface getFollowLogListRes extends ListRes <{
    customer_id: number
    customer_status: number
    operator_id: number
    is_real_be_follow: number
    is_valid: number
    is_first_follow: number
    group: number
    dep: number
    time: string
    doc_id: number
    remark: string
    CustomerInfo: {
        logs: {
            time: string
            remark: string
            customer_status: string
            operator: string
        }[]
        SysTags: string[]
    }
    CustomerAllInfo: getRepeatPhoneCustomerListRes
    UserInfo: userInfo6
}> {
    valid_count: number
    invalid_count: number
}

interface getFollowCustomerListReq extends ListReq {
    dep: number
    group: number
    user_id: number
    time_start: string
    time_end: string
}

interface getFollowCustomerListRes extends ListRes <{
    customer_id: number
    customer_status: number
    operator_id: number
    is_real_be_follow: number
    is_valid: number
    is_first_follow: number
    group: number
    dep: number
    time: string
    doc_id: number
    remark: string
    CustomerInfo: {
        logs: {
            time: string
            remark: string
            customer_status: string
            operator: string
        }[]
        SysTags: string[]
    }
    CustomerAllInfo: getRepeatPhoneCustomerListRes
    UserInfo: userInfo6
}> {}

interface getRenlinListReq extends getFollowCustomerListReq {
    type: string
}

interface getRenlinListRes extends ListRes <{
    Id: number
    CustomerId: number
    UserId: number
    Dep: number
    Gro: number
    Type: number
    ExtData: {
        is_zj: boolean
    }
    Ctime: string
    CustomerInfo: {
        logs: {
            time: string
            remark: string
            customer_status: string
            operator: string
        }[]
        SysTags: string[]
    }
    CustomerAllInfo: getRepeatPhoneCustomerListRes
    UserInfo: userInfo6
}> {}

interface getCustomerDistributionReq extends ListReq{
    is_valid?: number
    start_date?: string;
    end_date?: string;
    dep_id?: number;
    group_id?: number;
    user_id?: number;

    has_dk?: number
    has_cj?: number
}

export interface getCustomerDistributionRes{
    customer_mark: number,
    customer_id: number
    customer_name: string,
    customer_phone: string,
    distribution_time: number,
    fxs_name: string,
    house_name: string

    has_dk: number
    has_cj: number
}

interface getCustomerDistributionAggsReq{
    start_date?: string;
    end_date?: string;
    dep_id?: number;
    group_id?: number;
    is_include_leave?: boolean
}

interface getCustomerDistributionAggsRes {
    rows: {
        customer_count: number
        dep_id: number
        fxs_name: string
        group_id: number
    }[]
}

interface getCustomerLogRecordsReq extends getCustomerDistributionReq{

}

export interface getCustomerLogRecordsRes {
    customer_mark: number
    customer_name: string
    customer_phone: string
    customer_tag: string
    fxs_name: string
    house_name: string
    log_text: string
    log_time: number
}

interface getCustomerLogAggsReq extends getCustomerDistributionAggsReq{

}

interface getCustomerLogAggsRes {
    rows: {
        log_count: number
        dep_id: number
        fxs_name: string
        group_id: number
    }[]
}

export default class ApiSaleMan {
    getRenlinWorkInfo (data: getRenlinWorkInfoReq): BaseRes<getRenlinWorkInfoRes> {
        return php.post('', {
            action_name: 'getRenlinWorkInfo',
            ...data
        })
    }

    getFollowLogWorkInfo (data: getRenlinWorkInfoReq): BaseRes<getFollowLogWorkInfoRes> {
        return php.post('', {
            action_name: 'getFollowLogWorkInfo',
            ...data
        })
    }

    getFollowCustomerWorkInfo (data: getRenlinWorkInfoReq): BaseRes<getFollowLogWorkInfoRes> {
        return php.post('', {
            action_name: 'getFollowCustomerWorkInfo',
            ...data
        })
    }

    // 有效无效
    changeLogTag (data: changeLogTagReq) {
        return php.post('', {
            action_name: 'changeLogTag',
            ...data
        })
    }

    getFollowLogList (data: getFollowLogListReq): BaseRes<getFollowLogListRes> {
        return php.post('', {
            action_name: 'getFollowLogList',
            ...data
        })
    }

    getFollowCustomerList (data: getFollowCustomerListReq): BaseRes<getFollowCustomerListRes> {
        return php.post('', {
            action_name: 'getFollowCustomerList',
            ...data
        })
    }

    getRenlinList (data: getRenlinListReq): BaseRes<getRenlinListRes> {
        return php.post('', {
            action_name: 'getRenlinList',
            ...data
        })
    }

    // 分配客户情况-fxs
    getCustomerDistribution (data: getCustomerDistributionReq): BaseRes<ListRes2<getCustomerDistributionRes>> {
        return java.post('/admin/fxs/work/detail/getCustomerDistribution', data)
    }

    // 分配客户情况聚合数据-省长总监
    getCustomerDistributionAggs (data: getCustomerDistributionAggsReq): BaseRes<getCustomerDistributionAggsRes> {
        return java.post('/admin/fxs/work/detail/getCustomerDistributionAggs', data)
    }

    // 分析师发布跟进情况-fxs
    getCustomerLogRecords (data: getCustomerLogRecordsReq): BaseRes<ListRes2<getCustomerLogRecordsRes>> {
        return java.post('/admin/fxs/work/detail/getCustomerLogRecords', data)
    }

    // 发布跟进聚合数据-省长总监
    getCustomerLogAggs (data: getCustomerLogAggsReq): BaseRes<getCustomerLogAggsRes> {
        return java.post('/admin/fxs/work/detail/getCustomerLogAggs', data)
    }
}
