import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'

interface TableItem {
    id: number
    depName: string
    groupName: string
    groupLeaderName: string,

    sendMoney: number,
    sendMonth: string,
    sendDate: string
    sendUserName: string
    remark: string
}

@Component({
    components: {
        InputSelectSearch
    }
})
export default class HomeTeamProfitPage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0,
        loading: false
    }

    get
    depList () {
        return this.__utils.index.getXsDep()
    }

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    created () {
        this.getData()
    }

    async getData () {
        this.tableData.loading = true
        const query = this.$route.query as {
            commision_id: string
            profit_id: string
        }
        const { data } = await this.__apis.financial.getCommisionSendRecord({
            commision_id: query.commision_id || undefined,
            profit_id: query.profit_id || undefined
        })
        this.tableData.data = data.list.map(a => {
            return {
                id: a.id,
                depName: this.__utils.index.getDepInfoById(a.dep_id).v,
                groupName: this.__utils.index.getGroupInfoById(a.group_id).name,
                groupLeaderName: a.leader_username || '未知',

                sendMoney: a.money,
                sendMonth: this.__utils.dayjs(a.month_date).format('YYYY-MM'),
                sendDate: this.__utils.dayjs(a.ctime).format('YYYY-MM-DD HH:mm'),
                sendUserName: a.operator,

                remark: a.remark
            }
        })
        this.tableData.total = data.total
        this.tableData.loading = false
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getData()
    }
}
