import { Component, Vue, Prop } from 'vue-property-decorator'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'
import { searchUserRes } from '@/api/utils'

interface Item {
    [index: string]: string
    id: string
    userId: string
    userName: string
    month: string
    money: string
    remark: string
}

@Component({
    components: {
        inputSelectSearch
    }
})
export default class ImportComponent extends Vue {
    @Prop({
        default: false,
        type: Boolean
    })
    show !: boolean

    @Prop({
        default: () => {
            return {
                id: '',
                userId: '',
                userName: '',
                month: '',
                money: '',
                remark: ''
            }
        },
        type: Object
    })
    item !: Item

    get
    cmtBtnEnterDisabled () {
        let result = true

        for (let key in this.item) {
            if (key !== 'remark') {
                result = result && !!this.item[key]
            }
        }

        return !result
    }

    limitMoneyInput (e: string, key: string, hasMinus = false) {
        let minus = e[0] === '-' && hasMinus ? '-' : ''

        this.item[key] = minus + this.__utils.index.limitMoneyByInput(e, 1000000)
    }

    selectName (a: searchUserRes['list'][0]) {
        this.item.userId = a.Id.toString()
    }

    async enter () {
        if (this.__utils.index.limitRepeatClick()) return

        try {
            await this.__apis.financial.commisionSend({
                commsion_id: +this.item.id,
                money: this.item.money,
                remark: this.item.remark
            })

            this.success()
        } catch (e) {
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    success () {
        this.$emit('enter')
        this.close()
    }

    close () {
        this.$emit('update:show', false)
    }
}
