import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class NavTab extends Vue {
    @Prop({
        type: Array,
        default () {
            return []
        }
    })
    data !: any
}
