import { Component, Vue, Prop } from 'vue-property-decorator'

interface dataSource {
    remark: string
}

@Component({
    components: {
    }
})
export default class ApplyInvalidCustomerDialogComponent extends Vue {
    @Prop({
        default: false
    })
    visible!: boolean

    @Prop({
        default: '无效客户申请'
    })
    title!: string

    @Prop({
        default: '请填写申请理由~'
    })
    placeholder!: string

    @Prop({
        default () {
            return {
                remark: ''
            }
        }
    })
    data!: dataSource

    save () {
        this.$emit('save', this.data.remark)
        this.close()
    }

    close () {
        this.$emit('update:visible', false)
    }
}
