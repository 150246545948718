import { pp, ppUpload } from '@/api/index'

export enum ReportManageStorageName {
    HISTORY = 'history'
}

interface uploadAccessTokenRes {
    access_token: string,
    scope: string,
    expires_in: number
}

interface uploadFileReq {
    token: string,
    filename: string,
    file: any
}

interface uploadFileRes {
    id: number
    size: number
    format: string
    url: string
}

interface listStorageReq {
    list: string,
    data: any
}

interface listStorageRes {
    id: number
}

interface listStorageListReq {
    list: string
}

interface listStorageList {
    data: [],
    id: number
}

interface listStorageListRes {
    list: listStorageList[]
}

interface listStorageDeleteReq {
    list: string,
    id: number
}

interface listStorageDeleteRes {
    errcode: number,
    errmsg: string
}

export default class ApiReportManage {
    // 获取AccessToken
    uploadFileAccessToken (): Promise<uploadAccessTokenRes> {
        return pp.post('/upload/access_token', {
            scope: 'upload.file'
        })
    }
    // 文件上传
    uploadFile (data: uploadFileReq): Promise<uploadFileRes> {
        return ppUpload.post('', data.file, {
            headers: {
                'Access-Token': data.token,
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    // 列表存储工具
    // 添加到列表
    listStoragePush (data: listStorageReq): Promise<listStorageRes> {
        return pp.post('/util/list-storage/push?scope=share', data)
    }
    // 获取列表
    listStorageList (data: listStorageListReq): Promise<listStorageListRes> {
        return pp.post('/util/list-storage/list?scope=share', data)
    }
    // 删除记录
    listStorageDelete (data: listStorageDeleteReq): Promise<listStorageDeleteRes> {
        return pp.post('/util/list-storage/delete?scope=share', data)
    }
}
