import { Component, Vue, Watch } from 'vue-property-decorator'
import addComponent from '@/components/success-detail/billing/index.vue'
import { Notification } from 'element-ui'
import { SuccessDetailDispatchType } from '@/store/successDetail'
import { getSuccessDetailRes } from '@/api/financial'

@Component({
    components: {
        addComponent
    }
})
export default class IndexPage extends Vue {
    addComponentOptions = {
        show: false,
        date: '',
        money: '',
        remark: '',
        id: undefined
    }

    add: any = {
        status: {
            value: '未开票',
            data: [
                {
                    label: '未开票',
                    value: 0
                }, {
                    label: '部分开票',
                    value: 1
                }, {
                    label: '完全开票',
                    value: 2
                }, {
                    label: '无需开票',
                    value: 3
                }
            ]
        }
    }

    table: getSuccessDetailRes['KaiPiaoList'] = []

    @Watch('allData')
    watchAllData () {
        this.loadBillingData()
    }

    get
    allData () {
        return this.__store.state.success_detail.all_data
    }

    created () {
        if (this.allData.Id !== undefined) {
            this.loadBillingData()
        }
    }

    // 过滤每一列的类容
    formatColumn (r: any, c: any, cellValue: any) {
        if (typeof cellValue === 'number') {
            return cellValue
        } else {
            return cellValue || ''
        }
    }

    // 修改记录
    editRecord (item: any) {
        this.addComponentOptions.date = item.row.KaipiaoTime
        this.addComponentOptions.remark = item.row.Remark
        this.addComponentOptions.money = item.row.Money
        this.addComponentOptions.id = item.row.Id
        this.addComponentOptions.show = true
    }

    // 删除记录
    async deleteRecord (item: any) {
        await this.__apis.financial.deleteKpDzFyTfRecords({
            id: item.row.Id,
            type: 'kp'
        })

        // 保存成功
        Notification.success('删除开票记录成功')

        this.__store.dispatch(SuccessDetailDispatchType.setAllData, +this.$route.params.id)
    }

    // 修改开票状态
    async changeStatus (data: any) {
        await this.__apis.financial.saveSale({
            id: this.$route.params.id,
            kaipiao_status: data
        })

        // 保存成功
        Notification.success('开票状态修改成功')

        this.__store.dispatch(SuccessDetailDispatchType.setAllData, +this.$route.params.id)
    }

    showAddComponent () {
        this.addComponentOptions.date = ''
        this.addComponentOptions.remark = ''
        this.addComponentOptions.money = ''
        this.addComponentOptions.id = undefined
        this.addComponentOptions.show = true
    } // 显示添加弹窗

    loadBillingData () {
        // 处理状态
        this.add.status.value = this.allData.KaipiaoStatus

        this.table = this.allData.KaiPiaoList
    }
}
