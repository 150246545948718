import { Component, Vue, Prop } from 'vue-property-decorator'
import { LabelValueArray } from '@/types'

type types = 'all' | 't' | 'lt' | 'w' | 'lw' | 'm' | 'lm' | 'l12' | 'y' | 'ly'

@Component
export default class HomeDateComponent extends Vue {
    @Prop({
        type: Array,
        default () {
            return ['all', 't', 'lt', 'w', 'lw', 'm', 'lm']
        }
    })
    type !: types[]

    @Prop({
        type: String,
        default () {
            return 'all'
        }
    })
    current !: string

    @Prop({
        type: String,
        default () {
            return ''
        }
    })
    format !: string

    @Prop({
        type: Object,
        default () {
            return {}
        }
    })
    pickerOptions !: Object

    time: {
        list: LabelValueArray<number[]>
        value: string
    } = {
        list: [],
        value: ''
    }

    date: number[] = []

    created () {
        this.initTime()
        this.timeChange()
    }

    callData () {
        const data = this.date.map(a => this.format ? this.__utils.dayjs(a).format(this.format) : a)

        this.$emit('input', data)
        this.$emit('change', data, this.time.value)
    }

    dateChange () {
        // 格式化时间
        this.date = [this.__utils.dayjs(this.date[0]).startOf('d').valueOf(), this.__utils.dayjs(this.date[1]).endOf('d').valueOf()]

        this.time.value = 'none'

        this.time.list.forEach(a => {
            if (a.value.join() === this.date.join()) {
                this.time.value = a.label
            }
        })

        this.callData()
    }

    timeChange () {
        // 修改日期选择框的值，这里也可以使用watch
        const v = this.__utils.index.getItemByLabel(this.time.list, this.time.value, false)

        if (!v) return

        this.date = v.value

        this.callData()
    }

    initTime () {
        const map: {
            [k in types]: LabelValueArray<number[]>[0]
        } = {
            all: {
                label: '全部',
                value: []
            },
            t: {
                label: '今日',
                value: []
            },
            lt: {
                label: '昨日',
                value: []
            },
            w: {
                label: '本周',
                value: []
            },
            lw: {
                label: '上周',
                value: []
            },
            m: {
                label: '本月',
                value: []
            },
            lm: {
                label: '上月',
                value: []
            },
            l12: {
                label: '近12个月',
                value: []
            },
            y: {
                label: '今年',
                value: []
            },
            ly: {
                label: '去年',
                value: []
            }
        }
        const date = this.__utils.dayjs()
        const yesterday = date.subtract(1, 'd')
        const lastWeek = date.subtract(1, 'w')
        const lastMonth = date.subtract(1, 'M')
        const lastYear = date.subtract(1, 'y')

        map.all.value = [this.__utils.dayjs('2018-01-01').startOf('d').valueOf(), this.__utils.dayjs().startOf('d').valueOf()]
        map.t.value = [date.startOf('d').valueOf(), date.endOf('d').valueOf()]
        map.lt.value = [yesterday.startOf('d').valueOf(), yesterday.endOf('d').valueOf()]
        map.w.value = [date.startOf('isoWeek').valueOf(), date.endOf('isoWeek').valueOf()]
        map.lw.value = [lastWeek.startOf('isoWeek').valueOf(), lastWeek.endOf('isoWeek').valueOf()]
        map.m.value = [date.startOf('M').valueOf(), date.endOf('M').valueOf()]
        map.lm.value = [lastMonth.startOf('M').valueOf(), lastMonth.endOf('M').valueOf()]
        map.l12.value = [date.subtract(365, 'd').startOf('d').valueOf(), date.endOf('d').valueOf()]
        map.y.value = [date.startOf('y').valueOf(), date.endOf('y').valueOf()]
        map.ly.value = [lastYear.startOf('y').valueOf(), lastYear.endOf('y').valueOf()]

        let index = 0
        this.time.list = this.type.map((a, i) => {
            if (this.current && a === this.current) {
                index = i
            }
            return map[a]
        })

        this.time.value = this.time.list[index].label
    }
}
