import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class QuestionBankEndAlertComponent extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    visible!: boolean

    @Prop({
        type: String,
        default: ''
    })
    title!: string

    @Prop({
        type: String,
        default: ''
    })
    content!: string

    close () {
        this.$emit('update:visible', false)
    }
}
