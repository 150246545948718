export type TableItem = {
    dep: string
    successMoney: number
    successTotal: number
    lookNumber: string
    forwardCustomers: number
    newCustomers: number
    validCustomerRate: string
    lookRate: string
    successRate: string
    customerTransform: number
    efficiency: string
    invoiceMoney: number
    arriveMoney: number
    arriveProfit: number
    rebateMoney: number
    checkoutMoney: number
    checkoutRate: string
    lookSuccessTransform: string
    monthPrediction: string
    lastMonthPrediction: string
    successMedian: number
    firstFollowMedian: number
    forwardTotalValidCount: string
    takeToLookCustomerRate: string
}

export const tableList = [
    {
        label: '部门',
        prop: 'dep',
        width: 90
    },
    {
        label: '成交金额',
        prop: 'successMoney',
        width: 120
    },
    {
        label: '成交套数',
        prop: 'successTotal',
        width: 104
    },
    {
        label: '带看数',
        prop: 'lookNumber',
        width: 104
    },
    {
        label: '跟进客户数',
        prop: 'forwardCustomers',
        width: 104
    },
    {
        label: '总跟进数/有效跟进数',
        prop: 'forwardTotalValidCount',
        width: 160
    },
    {
        label: '分配客户数',
        prop: 'newCustomers',
        width: 104
    },
    {
        label: '客户有效率',
        prop: 'validCustomerRate',
        width: 104
    },
    {
        label: '带看率',
        prop: 'lookRate',
        width: 104
    },
    {
        label: '分配客户带看率',
        prop: 'takeToLookCustomerRate',
        width: 120
    },
    {
        label: '成交率',
        prop: 'successRate',
        width: 104
    },
    {
        label: '单客转化',
        prop: 'customerTransform',
        width: 104
    },
    {
        label: '人效',
        prop: 'efficiency',
        width: 104
    },
    {
        label: '开票金额',
        prop: 'invoiceMoney',
        width: 120
    },
    {
        label: '到账金额',
        prop: 'arriveMoney',
        width: 120
    },
    {
        label: '到账利润',
        prop: 'arriveProfit',
        width: 120
    },
    {
        label: '返佣金额',
        prop: 'rebateMoney',
        width: 120
    },
    {
        label: '退房金额',
        prop: 'checkoutMoney',
        width: 130
    },
    {
        label: '退房率',
        prop: 'checkoutRate',
        width: 104
    },
    {
        label: '带看成交转化',
        prop: 'lookSuccessTransform',
        width: 104
    },
    {
        label: '本月预测',
        prop: 'monthPrediction',
        width: 104
    },
    {
        label: '下月预测',
        prop: 'lastMonthPrediction',
        width: 104
    },
    {
        label: '成交周期中位数',
        prop: 'successMedian',
        width: 120
    },
    {
        label: '首次跟进周期中位数',
        prop: 'firstFollowMedian',
        width: 150
    },
    {
        label: '客户平均跟进人数',
        prop: 'customerAvgUserCount',
        width: 150
    }
]
