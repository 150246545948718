import { Component, Vue } from 'vue-property-decorator'
import { LabelValueArray, Table } from '@/types'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'
import dateRangeUtil from '@/util/date'

interface TableItem {
    id: number
    depName: string
    groupName: string
    groupLeaderMoney: string
    saleMoney: string
    customerMoney: string
    userMoney: string
    fxsProfitMoney: string

    sendUserMoney: string,
    noSendUserMoney: string
}

interface Filter {
    dep: undefined | number,
    month: string,
    fastMonth: {
        current: number,
        data: LabelValueArray<string>
    }
}

@Component({
    components: {
        InputSelectSearch
    }
})
export default class HomeTeamProfitPage extends Vue {
    filter: Filter = {
        dep: undefined,
        month: '',
        fastMonth: {
            current: 1,
            data: [
                {
                    label: '本月',
                    value: dateRangeUtil.getCurrentMonth()[0].format('yyyy-MM')
                },
                {
                    label: '上月',
                    value: dateRangeUtil.getPreviousMonth()[0].format('yyyy-MM')
                }
            ]
        }
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0,
        loading: false
    }

    get
    depList () {
        return this.__utils.index.getXsDep()
    }

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    created () {
        if (this.infoPos.dep) {
            this.filter.dep = this.infoPos.dep
        }
        this.fastMonthHandle(this.filter.fastMonth.current)
    }

    async getData () {
        this.tableData.loading = true
        const filter = this.filter
        const { data } = await this.__apis.home.getProfitRecord({
            month: filter.month + '-01',
            dep_id: filter.dep
        })
        this.tableData.data = data.map(a => {
            return {
                id: a.id,
                depName: this.__utils.index.getDepInfoById(a.dep_id).v,
                groupName: this.__utils.index.getGroupInfoById(a.group_id).name,

                groupLeaderName: a.leader_username,

                saleMoney: a.total_sale_money.toFixed(2),

                customerMoney: a.customer_cost.toFixed(2),
                userMoney: a.person_cost.toFixed(2),
                fxsProfitMoney: a.fxs_commision_cost.toFixed(2),

                groupLeaderMoney: a.leader_commision.toFixed(2),

                chengBenMoney: (a.fxs_commision_cost + a.person_cost + a.customer_cost).toFixed(2),

                sendUserMoney: a.send_money.toFixed(2),
                noSendUserMoney: (a.leader_commision - a.send_money).toFixed(2)
            }
        })
        this.tableData.total = data.length
        this.tableData.loading = false
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getData()
    }

    resetFilter () {
        this.filter = {
            dep: undefined,
            month: '',
            fastMonth: {
                current: 0,
                data: [
                    {
                        label: '本月',
                        value: dateRangeUtil.getCurrentMonth()[0].format('yyyy-MM')
                    },
                    {
                        label: '上月',
                        value: dateRangeUtil.getPreviousMonth()[0].format('yyyy-MM')
                    }
                ]
            }
        }
        this.fastMonthHandle(this.filter.fastMonth.current)
    }

    fastMonthHandle (index: number) {
        this.filter.fastMonth.current = index
        this.filter.month = this.filter.fastMonth.data[index].value
        this.getData()
    }

    monthChange (value: string) {
        this.filter.fastMonth.data.forEach((a, i) => {
            this.filter.fastMonth.current = a.value === value ? i : -1
        })
    }

    toDetail (item: TableItem) {
        this.$router.push(`/financial/group-commission/profit-detail?profit_id=${item.id}`)
    }
}
