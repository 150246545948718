import { Vue, Component, Prop } from 'vue-property-decorator'
import { LabelValueArray } from '@/types'

export type propsOption = {
    show: boolean;
    item: {
        id: number;
        name: string;
        time: string[];
        need: string;
        plan: string;
        rs: string;
        leader: string;
        dep: string;
    };
}

@Component
export default class EditComponent extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    show !: boolean

    @Prop({
        type: Object,
        default () {
            return {
                id: 0,
                name: '',
                time: [],
                need: '',
                plan: '',
                rs: '',
                leader: '',
                dep: ''
            }
        }
    })
    item !: propsOption['item']

    get
    cmtBtnEnterDisabled () {
        return !(this.form.name && this.form.time && this.form.time.length)
    }

    form: {
        id: number
        employee_id: number
        name: string
        time: string[]
        list: LabelValueArray<string>
    } = {
        id: 0,
        employee_id: 0,
        name: '',
        time: [],
        list: []
    }

    created () {
        const item = this.item

        this.form = {
            id: item.id,
            employee_id: Number(this.$route.params.id),
            name: item.name,
            time: item.time,
            list: [
                {
                    label: '成长需求',
                    value: item.need
                }, {
                    label: '成长计划',
                    value: item.plan
                }, {
                    label: '人事评价',
                    value: item.rs
                }, {
                    label: '上级评价',
                    value: item.leader
                }, {
                    label: '部门负责人评价',
                    value: item.dep
                }
            ]
        }
    }

    close () {
        this.$emit('update:show', false)
    }

    async enter () {
        if (this.__utils.index.limitRepeatClick()) return

        try {
            const form = this.form
            const item = this.item

            // 输入内容没有改变 则不传
            await this.__apis.grow.saveGrowingPlan(this.__utils.index.toggleObj({
                id: form.id,
                employee_id: form.employee_id,
                start_time: new Date(form.time[0]).getTime(),
                end_time: new Date(form.time[1]).getTime(),
                plan_name: form.name,
                demand: form.list[0].value,
                program: form.list[1].value,
                evaluation_hr: form.list[2].value,
                evaluation_leader: form.list[3].value,
                evaluation_dep: form.list[4].value
            }, {
                demand: item.need,
                program: item.plan,
                evaluation_hr: item.rs,
                evaluation_leader: item.leader,
                evaluation_dep: item.dep
            }))

            this.$message.success('保存成功')
            this.$emit('enter')
            this.close()
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }
}
