import { Component, Prop, Vue } from 'vue-property-decorator'
import { Notification } from 'element-ui'
import { SuccessDetailDispatchType } from '@/store/successDetail'

interface options {
    show: boolean
    remark: string
    id: number
}

@Component
export default class SuccessDetailExit extends Vue {
    @Prop({
        type: Object,
        default: {
            show: false,
            remark: '',
            id: undefined
        }
    })
    options !: options

    get
    cmtBtnSaveEnabled () {
        return !!(this.options.remark)
    }

    closeSelf () {
        this.options.show = false
    }

    async save () {
        if (this.__utils.index.limitRepeatClick()) return

        try {
            await this.__apis.financial.saveSaleTroubleRecord({
                id: 0,
                sale_id: +this.$route.params.id,
                remark: this.options.remark,
                has_trouble: 1
            })

            // 保存成功
            Notification.success('操作成功')
            this.__store.dispatch(SuccessDetailDispatchType.setAllData, +this.$route.params.id)

            this.closeSelf()
        } catch (e) {
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }
}
