import { Component, Vue } from 'vue-property-decorator'
import { LabelValueArray, Table } from '@/types'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'
import dateRangeUtil from '@/util/date'
import SendComponent from './send/index.vue'

const GroupCommissionRecordStatus = [
    {
        label: '未发放',
        value: 0
    },
    {
        label: '已发放',
        value: 1
    }
]

interface TableItem {
    id: number
    depName: string
    groupName: string
    groupLeaderId: number
    groupLeaderName: string
    date: string
    mustMoney: string
    sendMoney: string
    status: string
    remark: string

    commisionDetail: {
        name: string
        month: string
        commision: string
        profit_id: number
    }[]
}

interface Filter {
    dep: undefined | number,
    month: string,
    fastMonth: {
        current: number,
        data: LabelValueArray<string>
    }
}

@Component({
    components: {
        InputSelectSearch,
        SendComponent
    }
})
export default class HomeTeamProfitPage extends Vue {
    filter: Filter = {
        dep: undefined,
        month: '',
        fastMonth: {
            current: 1,
            data: [
                {
                    label: '本月',
                    value: dateRangeUtil.getCurrentMonth()[0].format('yyyy-MM')
                },
                {
                    label: '上月',
                    value: dateRangeUtil.getPreviousMonth()[0].format('yyyy-MM')
                }
            ]
        }
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0,
        loading: false
    }

    get
    depList () {
        return this.__utils.index.getXsDep()
    }

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    created () {
        if (this.infoPos.dep) {
            this.filter.dep = this.infoPos.dep
        }
        this.fastMonthHandle(this.filter.fastMonth.current)
    }

    async getData () {
        this.tableData.loading = true
        const filter = this.filter
        const { data } = await this.__apis.financial.getCommisionRecord({
            month: filter.month + '-01',
            dep_id: filter.dep
        })
        data.sort((a, b) => a.status - b.status)
        this.tableData.data = data.map(a => {
            return {
                id: a.id,
                depName: this.__utils.index.getDepInfoById(a.dep_id).v,
                groupName: this.__utils.index.getGroupInfoById(a.group_id).name,
                groupLeaderId: a.leader_user_id,
                groupLeaderName: a.leader_username || '未知',
                date: this.__utils.dayjs(a.month_date).format('YYYY-MM'),
                mustMoney: a.money.toFixed(2),
                sendMoney: a.send_money,
                status: this.__utils.index.getLabelByValue(GroupCommissionRecordStatus, a.status),
                remark: a.remark,
                commisionDetail: a.commision_detail.groupCommisionItemVoList.map(v => {
                    return {
                        name: a.leader_username,
                        commision: v.commision.toFixed(2),
                        month: this.__utils.dayjs(v.month).format('YYYY-MM'),
                        profit_id: v.profit_id
                    }
                })
            }
        })
        this.tableData.total = data.length
        this.tableData.loading = false
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getData()
    }

    resetFilter () {
        this.filter = {
            dep: undefined,
            month: '',
            fastMonth: {
                current: 0,
                data: [
                    {
                        label: '本月',
                        value: dateRangeUtil.getCurrentMonth()[0].format('yyyy-MM')
                    },
                    {
                        label: '上月',
                        value: dateRangeUtil.getPreviousMonth()[0].format('yyyy-MM')
                    }
                ]
            }
        }
        this.fastMonthHandle(this.filter.fastMonth.current)
    }

    fastMonthHandle (index: number) {
        this.filter.fastMonth.current = index
        this.filter.month = this.filter.fastMonth.data[index].value
        this.getData()
    }

    monthChange (value: string) {
        this.filter.fastMonth.data.forEach((a, i) => {
            this.filter.fastMonth.current = a.value === value ? i : -1
        })
    }

    sendComponent = {
        visible: false,
        item: {
            id: 0,
            userId: 0,
            userName: '',
            month: '',
            money: '',
            remark: ''
        }
    }

    showSendComponent (item: TableItem) {
        this.sendComponent = {
            visible: true,
            item: {
                id: item.id,
                userId: item.groupLeaderId,
                userName: item.groupLeaderName,
                month: item.date,
                money: '',
                remark: ''
            }
        }
    }

    toDetail (item: TableItem) {
        this.$router.push(`/financial/group-commission/profit-detail?commision_id=${item.id}`)
    }
}
