import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AccountUserList extends Vue {
    @Prop({
        type: String,
        default: ''
    })
    name !: string

    @Prop({
        type: Number,
        default: 0
    })
    id !: number

    @Prop({
        type: Boolean,
        default: false
    })
    show !: boolean

    exitTime: Date = new Date()

    async enter () {
        await this.__apis.system.userActiveChange({
            leave_time: this.exitTime.getTime(),
            deactive: 1,
            id: this.id
        })

        this.close()
        this.$emit('save')
    }

    close () {
        this.$emit('update:show', false)
    }
}
