import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import FilterComponent from '@/components/filters/filter/index.vue'
import dateRangeSelectComponent from '@/components/wallet/date-range-select/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'
import InputSelectComponent from '@/components/filters/inputSelect/index.vue'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import createUserComponent from '@/views/system/account/user/createUser/index.vue'
import editUserComponent from '@/views/system/account/user/editUser/index.vue'
import { UserCommitTypes } from '@/store/user'

interface userInfo {
    'role': string
    'last_login_time': string
    'user_id': number
    'user_name': string
    'register_time': string
    'group': string
    'groupName': string
    deActiveName: string
}

export interface TableItem {
    id: number
    name: string
    dep: number
    depName: string
    company: string
    sourceData: Partial<userInfo>[]
    user_list: Partial<userInfo>
}

@Component({
    components: {
        FilterComponent,
        dateRangeSelectComponent,
        SelectComponent,
        InputDateRangeComponent,
        FilterBusinessSelect,
        InputSelectComponent,
        FilterBusInputSelectComponent,
        createUserComponent,
        editUserComponent
    }
})
export default class AccountUserChangeRecords extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 10,
        total: 0
    }

    filter:{
        user_id?: number
        time_start?: string
        time_end?: string
    } = {}

    createUser = {
        visible: false,
        data: {}
    }

    editUser = {
        visible: false,
        data: {}
    }

    created () {
        this.getPermissionProjects()
        this.getData()
    }

    async getPermissionProjects () {
        const { data } = await this.__apis.system.getPermissionProjects()
        const p = data.map(a => {
            return this.__apis.system.getPermissionProjectsRoles(a.id)
        })
        const res = await Promise.all(p)
        const projectList = res.map((a, i) => {
            return {
                ...data[i],
                name: data[i].project_name,
                roles: a.data
            }
        })
        this.__store.commit(UserCommitTypes.setProjectList, projectList)
    }

    objectSpanMethod ({ column, rowIndex }: any) {
        let data:{name: any}[] = []
        if (column.label === 'ID') {
            data = this.merageArr(this.tableData.data.map((v) => {
                return {
                    name: v.id
                }
            }))
            return this.returnTableSpan(data, rowIndex)
        } else if (column.label === '姓名') {
            data = this.merageArr(this.tableData.data.map((v) => {
                return {
                    name: `${v.id}-${v.name}`
                }
            }))
            return this.returnTableSpan(data, rowIndex)
        } else if (column.label === '部门') {
            data = this.merageArr(this.tableData.data.map((v) => {
                return {
                    name: `${v.id}-${v.name}-${v.depName}`
                }
            }))
            return this.returnTableSpan(data, rowIndex)
        } else if (column.label === '操作') {
            data = this.merageArr(this.tableData.data.map((v) => {
                return {
                    name: `${v.id}`
                }
            }))
            return this.returnTableSpan(data, rowIndex)
        }
    }

    // 数据合并
    merageArr (arr: {name: any}[]) {
        let data: any[] = []
        let pos = 0
        arr.map((v, i) => {
            if (i === 0) {
                data.push(1)
                pos = 0
            } else {
                if (arr[i].name === arr[i - 1].name) {
                    data[pos] += 1
                    data.push(0)
                } else {
                    data.push(1)
                    pos = i
                }
            }
        })
        return data
    }

    // 返回合并行，列
    returnTableSpan (arr: any[], rowIndex: number) {
        const _row = arr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
            rowspan: _row,
            colspan: _col
        }
    }

    createUserHandle () {
        this.createUser.visible = true
        this.createUser.data = {}
    }

    editUserHandle (item:TableItem) {
        this.editUser.visible = true
        this.editUser.data = item
    }

    async getData () {
        let { data } = await this.__apis.system.getEmployeeList({
            ...this.filter,
            page: this.tableData.page,
            pagesize: this.tableData.pageSize
        })
        this.tableData.total = data.total
        let res:TableItem[] = []
        data.list.map(v => {
            let wrapData:TableItem = {
                id: v.id,
                name: v.name,
                dep: v.dep,
                depName: this.__utils.index.getComDepInfoById(v.dep).name,
                company: v.company,
                sourceData: v.user_list.map(a => {
                    return {
                        ...a,
                        deActiveName: a.de_active === 1 ? '禁用' : '开启',
                        register_time: a.register_time ? this.__utils.index.formatDate(new Date(a.register_time), 'YYYY-MM-DD HH:mm') : '',
                        last_login_time: a.last_login_time ? this.__utils.index.formatDate(new Date(a.last_login_time), 'YYYY-MM-DD HH:mm') : '',
                        groupName: a.group
                    }
                }),
                user_list: {}
            }
            wrapData.sourceData.map(a => {
                wrapData = {
                    ...wrapData,
                    user_list: a
                }
                res.push(wrapData)
            })
        })
        this.tableData.data = res
    }

    filterChange (item: any) {
        console.log('filter', item)
        Object.keys(item).map((v:any) => {
            if (v === 'ctime') {
                item.time_start = item[v][0] + ' 00:00:00'
                item.time_end = item[v][1] + ' 23:59:59'
                delete item[v]
            }
            if (v.includes('id')) {
                item[v] = +item[v]
            }
        })
        this.filter = item
        this.tableData.page = 1
        this.getData()
    }

    tableCurrentChange (page: number) {
        this.tableData.page = page
        this.getData()
    }
}
