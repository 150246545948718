import { Component, Prop, Vue } from 'vue-property-decorator'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'
import { searchHouseRes } from '@/api/utils'
import { User } from '@/store/user'

export interface EditData {
    id: string
    name: string
    mobile: string
    house_id: string
    house_name: string
    ctime: string
    tags: User['sysTags']
    remark: string
}

@Component({
    components: {
        inputSelectSearch
    }
})
export default class CustomerPhoneEditComponent extends Vue {
    @Prop({
        default: false,
        type: Boolean
    })
    show !: boolean

    @Prop({
        default: '',
        type: String
    })
    from !: string

    @Prop({
        default () {
            return {
                name: '',
                mobile: '',
                house_id: '',
                house_name: '',
                ctime: '',
                tags: []
            }
        },
        type: Object
    })
    item !: EditData

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    get
    sysTags () {
        return this.__store.state.user.sysTags
    }

    get
    cmtBtnEnterDisabled () {
        const res = this.form.name.trim() && this.form.house_id.trim() && this.form.house_name.trim()
        return !res
    }

    form: EditData = {
        id: '',
        mobile: '',
        ctime: '',
        name: '',
        house_name: '',
        house_id: '',
        tags: [],
        remark: ''
    }

    created () {
        this.form = this.__utils.index.copyObj(this.item)
    }

    // 新增标签
    addTag (item: EditData['tags'][0]) {
        const texts = this.form.tags.map(a => a.text)
        const index = texts.indexOf(item.text)
        if (index !== -1) {
            this.form.tags.splice(index, 1)
        } else {
            this.form.tags.push(item)
        }
    }

    cmtTagsActive (item: EditData['tags'][0]) {
        return this.form.tags.map(a => a.text).includes(item.text)
    }

    houseList: {
        label: string,
        value: number,
        aliasName: string,
        beiAnName: string
    }[] = []
    async searchHouse (text: string) {
        const { data } = await this.__apis.utils.searchHouseMore({
            term: text
        })
        this.houseList = data.map(a => {
            return {
                label: a.name,
                value: a.id,
                aliasName: a.alias_name,
                beiAnName: a.beian_name
            }
        })
    }

    selectHouse (a: searchHouseRes) {
        this.form.house_id = String(a.value)
        this.form.house_name = a.label
    }

    async enter () {
        if (this.__utils.index.limitRepeatClick()) return
        try {
            const logs = [this.form.remark.trim()]

            await this.__apis.customer.addCallInfo({
                call_id: +this.form.id,
                house_id: +this.form.house_id,
                customer_name: this.form.name,
                sys_tags: this.form.tags.map(a => a.value).toString(),
                operate_logs: logs.filter(a => a)
            })

            this.$emit('success')
            this.close()
        } catch (e) {
            this.$message.error('分配失败')
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    close () {
        this.$emit('update:show', false)
    }
}
