import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ReasonComponent extends Vue {
    get
    disableBtnEnter () {
        return !this.form.remark.trim()
    }

    form = {
        remark: ''
    }

    close () {
        this.$emit('update:show', false)
    }

    enter () {
        this.$emit('enter', this.form.remark)
        this.close()
    }
}
