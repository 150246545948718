import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ButtonsComponent extends Vue {
    @Prop({
        default: 0,
        type: Number
    })
    index !: number

    @Prop({
        default () {
            return []
        },
        type: Array
    })
    items !: string[]

    changeTab (index: number) {
        this.$emit('change', index)
    }
}
