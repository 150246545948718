import { Component, Vue } from 'vue-property-decorator'
import tabsComponent from '@/components/wallet/tabs/index.vue'
import { items } from '@/components/wallet/tabs'

@Component({
    components: {
        tabsComponent
    }
})
export default class IndexPage extends Vue {
    tabsComponent: {
        active: number
        items: items[]
    } = {
        active: 0,

        items: [
            {
                text: '钱包列表',
                url: ['/financial/chongzhi-wallet']
            },
            {
                text: '钱包充值',
                url: ['/financial/chongzhi-wallet/recharge']
            },
            {
                text: '提现审核',
                url: ['/financial/chongzhi-wallet/review'],
                count: 0
            },
            {
                text: '钱包明细',
                url: ['/financial/chongzhi-wallet/record-detail']
            }
        ]
    }
}
