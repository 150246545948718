import { Component, Vue, Prop } from 'vue-property-decorator'
import qmPermission from 'qm-plugin-admin-permission'

export interface items {
    text: string
    url: string[]
    count?: number
    authKey?: string
}

@Component
export default class TabsComponent extends Vue {
    @Prop({
        default: 0,
        type: Number
    })
    index !: number

    @Prop({
        default () {
            return []
        },
        type: Array
    })
    items !: items[]

    created () {
        qmPermission.filter(this.items)

        this.activeTab()

        this.$on('hook:destroyed', this.$router.afterEach(this.activeTab))
    }

    activeTab () {
        // 确定 tabs 的激活态
        const urls = this.items.map(a => a.url)
        let index = 0

        for (let i = 0; i < urls.length; i++) {
            if (urls[i].includes(this.$route.path)) {
                index = i
                break
            }
        }
        this.$emit('update:index', index)
    }

    changeTab (index: number) {
        if (this.items[index].url[0] === this.$route.path) return
        this.$emit('update:index', index)

        if (!this.items[index].url[0]) return
        this.$router.push(this.items[index].url[0])
    }
}
