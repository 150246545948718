import { Component, Vue } from 'vue-property-decorator'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import InputSelectComponent from '@/components/filters/inputSelect/index.vue'
import FilterComponent from '@/components/filters/filter/index.vue'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'
import NumberRangeComponent from '@/components/filters/numberRange/index.vue'
import { __utils, ExitStatus, GetMoneyStatus } from '@/util'
import { routerBaseUrl } from '@/router'

interface table {
    data: {
        allMoney: string
        allHouse: string
        getMoney: string
        notMoney: string
        getOrder: string
        notOrder: string
        exit: string
        friendHouse: string
        getMoneyDay: string
        mustMoney: string
    }[]
}

@Component({
    components: {
        FilterBusInputSelectComponent,
        InputSelectComponent,
        FilterComponent,
        CheckBoxComponent,
        InputDateRangeComponent,
        FilterBusSelectComponent,
        NumberRangeComponent
    }
})
export default class IndexPage extends Vue {
    table: table = {
        data: []
    }

    orderTable: {
        [index: string]: any
    } = {
        data: [],
        loading: false,
        cj_time_start: '',
        cj_time_end: '',
        cj_money_min: '',
        cj_money_max: '',
        fxs_user_id: '',
        customer_name: '',
        customer_phone: '',
        house_name: '',
        jy_money_min: '',
        jy_money_max: '',
        kp_money_min: '',
        kp_money_max: '',
        has_tuifan: '',
        jy_status: ''
    }

    pagination = {
        total: 0,
        pagesize: 20,
        current: 1
    }

    isExitFilter = {
        data: __utils.index.copyObj(ExitStatus)
    }

    getMoneyStatusFilter = {
        data: __utils.index.copyObj(GetMoneyStatus)
    }

    created () {
        this.loadOverflow()
        this.loadOrderList()
    }

    // 筛选发送变化
    filterChange (data: any) {
        this.orderTable = {
            data: this.orderTable.data,
            loading: this.orderTable.loading,
            cj_time_start: '',
            cj_time_end: '',
            cj_money_min: '',
            cj_money_max: '',
            fxs_user_id: '',
            customer_name: '',
            customer_phone: '',
            house_name: '',
            jy_money_min: '',
            jy_money_max: '',
            kp_money_min: '',
            kp_money_max: '',
            has_tuifan: '',
            jy_status: ''
        }

        for (let k in data) {
            if (k === 'successDate') {
                this.orderTable.cj_time_start = data[k][0]
                this.orderTable.cj_time_end = data[k][1]
            } else if (k === 'allMoney') {
                this.orderTable.cj_money_min = data[k][0]
                this.orderTable.cj_money_max = data[k][1]
            } else if (k === 'getMoney') {
                this.orderTable.jy_money_min = data[k][0]
                this.orderTable.jy_money_max = data[k][1]
            } else if (k === 'getMoney') {
                this.orderTable.kp_money_min = data[k][0]
                this.orderTable.kp_money_max = data[k][1]
            } else {
                this.orderTable[k] = data[k]
            }
        }

        this.pagination.current = 1
        this.loadOrderList()
    }

    // 跳转成交详情
    gotoSuccessDetail (item: any) {
        open(`${routerBaseUrl}/financial/success_detail/success/${item.id}`, '_blank')
    }

    // 当前分页改变时候触发
    currentChange (page: number) {
        if (this.orderTable.loading) return

        this.pagination.current = page
        this.loadOrderList()
    }

    // 订单列表
    async loadOrderList () {
        try {
            if (this.orderTable.loading) return
            this.orderTable.loading = true
            this.orderTable.data = []

            const { data } = await this.__apis.channelManage.getPartnerOrderList({
                sale_partner_id: this.$route.params.id,
                page: this.pagination.current,
                pagesize: this.pagination.pagesize,
                cj_time_start: this.orderTable.cj_time_start,
                cj_time_end: this.orderTable.cj_time_end,
                cj_money_min: this.orderTable.cj_money_min,
                cj_money_max: this.orderTable.cj_money_max,
                fxs_user_id: this.orderTable.fxs_user_id,
                customer_name: this.orderTable.customer_name,
                customer_phone: this.orderTable.customer_phone,
                house_name: this.orderTable.house_name,
                jy_money_min: this.orderTable.jy_money_min,
                jy_money_max: this.orderTable.jy_money_max,
                kp_money_min: this.orderTable.kp_money_min,
                kp_money_max: this.orderTable.kp_money_max,
                has_tuifan: this.orderTable.has_tuifan,
                jy_status: this.orderTable.jy_status
            })

            this.orderTable.loading = false
            this.pagination.total = data.total

            data.list.forEach(a => {
                this.orderTable.data.push({
                    time: new Date(a.sale_time * 1000).format('yyyy-MM-dd'),
                    people: JSON.parse(a.fxs_user_info).map((a: any) => a.user_name).join('/'),
                    house: a.house_name,
                    name: a.customer_name,
                    phone: a.customer_phone,
                    allMoney: a.sale_sum_money + '元',
                    getMoney: a.jieyong_money + '元',
                    ticketMoney: a.kaipiao_money + '元',
                    isExit: a.has_tui_fang === 0 ? '否' : '是',
                    status: GetMoneyStatus.filter(g => g.value === a.jieyong_status)[0].label,
                    id: a.sale_id
                })
            })
        } catch (e) {
            this.orderTable.loading = false
            throw e
        }
    }

    // 数据概况
    async loadOverflow () {
        const { data } = await this.__apis.channelManage.getPartnerBizData(+this.$route.params.id)

        this.table.data.push({
            allMoney: data.SumCjMoney + '元',
            allHouse: data.SumCjCount + '套',
            getMoney: data.JieyongMoney + '元',
            notMoney: data.UnJieyongMoney + '元',
            getOrder: data.Jy2Count + '单',
            notOrder: data.Jy0Count + '单',
            exit: data.TfCount + '套',
            friendHouse: data.HouseCount + '套',
            getMoneyDay: data.JyAvgDays + '天',
            mustMoney: data.SumMustDzMoney + '元'
        })
    }
}
