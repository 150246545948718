import { Component, Vue } from 'vue-property-decorator'
import tabComponent from '@/components/success-detail/tabs/index.vue'
import { SuccessDetailDispatchType } from '@/store/successDetail'

interface tabsOptions {
    data: {
        label: string
        route: string
        title: string
    }[]
}

@Component({
    components: {
        tabComponent
    }
})
export default class IndexPage extends Vue {
    tabsOptions: tabsOptions = {
        data: []
    }

    async created () {
        const names = ['成交信息', '网签记录', '开票记录', '结佣记录', '返佣记录', '退房记录', '提成管理', '备注信息', '问题单']
        const routes = ['/success', '/web-sign', '/billing', '/end', '/back', '/exit', '/commission', '/remark', '/trouble']
        const titles = ['success_detail-success', 'success_detail-webSign', 'success_detail-billing', 'success_detail-end', 'success_detail-back', 'success_detail-exit', 'success_detail-commission', 'success_detail-remark', 'success_detail-trouble']

        names.forEach((a, i) => {
            this.tabsOptions.data.push({
                label: a,
                route: `/financial/success_detail${routes[i]}/${this.$route.params.id}`,
                title: titles[i]
            })
        })

        this.__store.dispatch(SuccessDetailDispatchType.setAllData, +this.$route.params.id)
    }
}
