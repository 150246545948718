import { installPlugin } from '@/util/install'

declare module 'vue/types/vue' {
    interface Vue {
        __localStorage: LocalStorage
    }
}

export const enum storageName {
    PHONE = 'newadmin_phone',
    TOKEN = 'qm_token',
    NEWTZ = 'newadmin_new_tz',
    NEWUSER = 'newadmin_new_user',
    NOTICEID = 'newadmin_noticeid',
    REPORTMANAGEUPLOADTOKEN = 'newadmin_report_manage_upload_token',
    ANSWERDATE = 'newadmin_answer_date',
    ANSWERCLOSE = 'newadmin_answer_close',
    WEEKLYEJECT = 'weekly',
    WEEKLYEJECT_DATE = 'weekly_date',
    QUESTIONDEP = 'newadmin_question_dep',
    PUBLICPOOLCUSTOMERFILTER = 'newadmin_public_pool_filter'
}

interface _LocalStorage {
    [storageName.PHONE]: string
    [storageName.TOKEN]: string
    [storageName.NEWTZ]: boolean
    [storageName.NEWUSER]: boolean
    [storageName.NOTICEID]: string
    [storageName.REPORTMANAGEUPLOADTOKEN]: any
    [storageName.WEEKLYEJECT]: boolean
    [storageName.WEEKLYEJECT_DATE]: string
    [storageName.ANSWERDATE]: string
    [storageName.ANSWERCLOSE]: string
    [storageName.QUESTIONDEP]: string
    [storageName.PUBLICPOOLCUSTOMERFILTER]: any
}

class LocalStorage {
    public setLocalStorage <T extends storageName> (key: T, value: _LocalStorage[T]) {
        localStorage.setItem(key, JSON.stringify(value))
    }

    public getLocalStorage <T extends storageName> (key: T): null | _LocalStorage[T] {
        const value = localStorage.getItem(key) as any

        if (!value) {
            return null
        }

        return JSON.parse(value)
    }

    public removeLocalStorage <T extends storageName> (key: T) {
        localStorage.removeItem(key)
    }
}

// 在 Vue 实例中注入 同时 开放实例本身
export const __localStorage = new LocalStorage()
export default {
    install: installPlugin('__localStorage', __localStorage)
}
