import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
    components: {
    }
})
export default class LogMoneyDialogComponent extends Vue {
    @Prop({
        default: false
    })
    visible!: boolean

    @Prop({
        default: 2
    })
    money!: number

    created () {
        setTimeout(() => {
            this.close()
        }, 1400)
    }

    close () {
        this.$emit('update:visible', false)
    }
}
