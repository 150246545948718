import { Component, Vue, Watch } from 'vue-property-decorator'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'
import HomeDateComponent from '@/views/home/components/date/index.vue'
import OverviewChartComponent from '@/views/home/overview/chart/index.vue'
import { __store } from '@/store'
import { DepInfoVar } from '@/util/const'

interface PanelDataMap {
    [key: string]: {
        title: string
        total: string
        last?: string
        unit?: string
    }
}

interface Panes {
    title: string,
    small: string,
    smallUrl: string,
    session: {
        alertId?: number,
        showAlert?: boolean
        title: string,
        total: string | number,
        showLast: boolean
        lastText: string
        unit?: string,
        last?: string | number,
    }[]
}

@Component({
    components: {
        OverviewChartComponent,
        HomeDateComponent,
        InputSelectSearch
    }
})
export default class OverviewPage extends Vue {
    filter = {
        dep: {
            label: '销售部门：',
            value: __store.state.user.infoPos.dep || '',
            disabled: !!__store.state.user.infoPos.dep,
            placeholder: '请选择部门'
        },
        group: {
            label: '战队：',
            value: __store.state.user.infoPos.group || '',
            disabled: !!__store.state.user.infoPos.group,
            placeholder: '请选择战队'
        },
        user: {
            label: '分析师：',
            value: __store.state.user.infoPos.fxs || '',
            disabled: !!__store.state.user.infoPos.fxs,
            fxs: 1,
            placeholder: '请选择分析师'
        }
    }

    date = []
    type = 'none'

    panes: Panes[] = [
        {
            title: '工作详情',
            small: '查看我的客户',
            smallUrl: this.__role.has([this.__role.ROLE.FENXISHI], false) ? '/customer/mycustomer'
                : this.__role.has([this.__role.ROLE.DUIZHANG], false) ? '/customer/teamcustomer?type=depList'
                    : this.__role.has([this.__role.ROLE.SHENGZHANG], false) ? '/customer/departcustomer?type=depList'
                        : '/customer/allcustomer?type=list',
            session: []
        },
        {
            title: '成交情况',
            small: '查看成交明细',
            smallUrl: '/financial/success?type=getHouseSaleList',
            session: []
        },
        {
            title: '重点客户情况',
            small: '查看重点客户明细',
            smallUrl: '/customer/zdcustomer',
            session: []
        }
    ]

    get
    filterData () {
        return {
            'dep': +this.filter.dep.value || undefined,
            'gro': +this.filter.group.value || undefined,
            'user_id': +this.filter.user.value || undefined,
            'query_date_type': 'DAY',
            'dateQrl': {
                'query_start_time': this.date[0] + ' 00:00:00',
                'query_end_time': this.date[1] + ' 23:59:59'
            }
        }
    }

    @Watch('filter', { deep: true })
    async filterChange () {
        this.getPanelData()
    }

    created () {
        // 省长处理下，不禁用部门
        if (this.__role.has([this.__role.ROLE.SHENGZHANG], false)) {
            this.filter.dep.disabled = false
        }
    }

    dateChange (date: number[], type: string) {
        this.type = type
        this.getPanelData()
    }

    async getPanelData () {
        const filter = this.filterData
        const [
            { data: workInfo },
            { data: clinchInfo },
            { data: importantCustomerInfo }
        ] = await Promise.all([
            this.__apis.home.workInfo(filter),
            this.__apis.home.clinchInfo(filter),
            this.__apis.home.importantCustomerInfo(filter)
        ])

        const workInfoMap: PanelDataMap = {
            1: {
                title: '新客户数',
                total: 'new_customer',
                last: 'last_new_customer'
            },
            2: {
                title: '跟进客户数',
                total: 'follower_customer',
                last: 'last_follower_customer'
            },
            3: {
                title: '发布跟进数',
                total: 'follow_count',
                last: 'last_follow_count'
            },
            4: {
                title: '带看客户数',
                total: 'daikan_customer',
                last: 'last_daikan_customer'
            },
            5: {
                title: '成交客户数',
                total: 'cj_customer',
                last: 'last_cj_customer'
            }
        }
        const clinchInfoMap: PanelDataMap = {
            6: {
                title: '成交套数',
                total: 'cjCount',
                last: 'last_cjCount',
                unit: '套'
            },
            7: {
                title: '成交金额',
                total: 'cjAmount',
                last: 'last_cjAmount'
            },
            8: {
                title: '返佣金额',
                total: 'fanyonAmount',
                last: 'last_fanyonAmount'
            },
            9: {
                title: '开票金额',
                total: 'kaipiaoAmount',
                last: 'last_kaipiaoAmount'
            },
            10: {
                title: '到账金额',
                total: 'dzAmount',
                last: 'last_dzAmount'
            },
            11: {
                title: '到账利润',
                total: 'earnedProfit',
                last: 'last_earnedProfit'
            },
            12: {
                title: '退房金额',
                total: 'tuifangAmount',
                last: 'last_tuifangAmount'
            },
            13: {
                title: '单客转化',
                total: 'conversionRate',
                last: 'last_conversionRate'
            }
        }
        const importantCustomerInfoMap: PanelDataMap = {
            1: {
                title: '近期重点客户',
                total: 'zd_customer'
            },
            2: {
                title: '即将带看客户',
                total: 'jjdk_customer'
            },
            3: {
                title: '带看未成交客户',
                total: 'dkmcj_customer',
                last: 'last_daikan_customer'
            },
            4: {
                title: '最近成交客户',
                total: 'zjcj_customer'
            },
            5: {
                title: 'ABC客户超时未跟进',
                total: 'abc_customer'
            },
            6: {
                title: '转交客户提醒',
                total: 'transfer_customer'
            },
            7: {
                title: '即将进入公池',
                total: 'pub_customer'
            }
        }

        const showLast = !['全部', 'none'].includes(this.type)
        const lastText = showLast ? this.type.slice(-1) : ''

        this.panes[0].session = Object.keys(workInfoMap).map(a => {
            return {
                alertId: +a,
                title: workInfoMap[a].title,
                total: workInfo[workInfoMap[a].total as 'new_customer'],
                last: workInfo[workInfoMap[a].last as 'new_customer'],
                showLast,
                lastText,
                unit: '人',
                showAlert: false
            }
        })
        this.panes[1].session = Object.keys(clinchInfoMap).map(a => {
            return {
                alertId: +a,
                title: clinchInfoMap[a].title,
                total: this.moneyToFixed(clinchInfoMap[a].title, clinchInfo[clinchInfoMap[a].total as 'cjCount']),
                last: this.moneyToFixed(clinchInfoMap[a].title, clinchInfo[clinchInfoMap[a].last as 'cjCount']),
                showLast,
                lastText,
                unit: clinchInfoMap[a].unit || '',
                showAlert: false
            }
        })
        this.panes[2].session = Object.keys(importantCustomerInfoMap).map(a => {
            return {
                title: importantCustomerInfoMap[a].title,
                total: importantCustomerInfo[importantCustomerInfoMap[a].total as 'abc_customer'],
                showLast: false,
                lastText: '',
                unit: '人'
            }
        })
    }

    moneyToFixed (title: string, money: number) {
        return title.includes('金额') || title.includes('利润') ? `¥ ${+money.toFixed(2)}` : money
    }

    selectData (key: string) {
        if (key === 'dep') {
            this.filter.group.value = __store.state.user.infoPos.group || ''
            this.filter.user.value = __store.state.user.infoPos.fxs || ''
        } else if (key === 'group') {
            this.filter.user.value = __store.state.user.infoPos.fxs || ''
        }
    }

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    searchData (key: string) {
        const dep = this.filter.dep.value
        const group = this.filter.group.value
        if (key === 'dep') {
            if (this.infoPos.dep >= DepInfoVar.KUNMING) {
                return this.__utils.index.getXsDep().filter(a => a.value === this.infoPos.dep)
            }
            return this.__utils.index.getXsDep()
        } else if (key === 'group') {
            return this.__utils.index.getGroupsByDepId(+dep).map(a => {
                return {
                    value: a.value,
                    label: a.name
                }
            })
        } else {
            return this.__utils.index.getFxsUsersByDepGroupId(+dep, +group)
        }
    }

    jump (url: string) {
        let { href } = this.$router.resolve(url)
        window.open(href, '_blank')
    }
}
