import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import guideDialog from '@/components/customer/guideDialog/index.vue'

@Component({
    components: {
        guideDialog
    }
})
export default class GuideDealRecordAuditPage extends Vue {
    @Prop({ default () { return {} } })
    form!:any

    dataObj: any = {}
    page = 1
    pageSize = 20

    editItemObj = {
        visible: false,
        data: {}
    }

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    @Watch('form', { deep: true })
    formChange () {
        this.pageChange(1)
    }

    mounted () {
        this.getData()
    }

    editRecord (item:any) {
        // console.log(item)
        let data = JSON.parse(JSON.stringify(item.Data))
        objectToUppercase(data).ExtData.forEach((v:any) => {
            objectToUppercase(v)
        })
        data = objectToUppercase(data)
        this.editItemObj.data = {
            Id: item.Id,
            ...data
        }
        this.editItemObj.visible = true

        function objectToUppercase (obj:any) {
            if (Object.prototype.toString.call(obj).includes('Object')) {
                Object.keys(obj).map((v:any) => {
                    if (Object.prototype.toString.call(obj[v]).includes('Object')) {
                        obj[v] = objectToUppercase(obj[v])
                    } else {
                        let old = v
                        v = v.split('')
                        for (let i = 0; i < v.length; i++) {
                            if (i === 0) {
                                v[i] = v[i].toString().toUpperCase()
                            }
                            if (v[i] === '_') {
                                v[i + 1] = v[i + 1].toString().toUpperCase()
                                v.splice(i, 1)
                            }
                        }
                        obj[v.join('')] = obj[old]
                    }
                })
                return obj
            }
        }
    }

    // 分页改变
    pageChange (page: number) {
        this.page = page
        this.getData()
    }

    // fetch
    async getData () {
        let form = this.__utils.index.copyObj(this.form)
        form.ctime = ''

        let data:any = {}
        Object.assign(data, form, {
            type: 1,
            page: this.page,
            pagesize: this.pageSize,
            time_start: this.form.ctime ? this.form.ctime[0] : '',
            time_end: this.form.ctime ? this.form.ctime[1] : ''
        })

        if (this.infoPos.dep) data.dep_id = ''
        if (this.infoPos.group) data.group_id = ''

        let res = await this.__apis.customer.getDailyWorkExamineList(data)

        res.data.list.forEach((v: any) => {
            v.RejectReason = v.RejectReason ? v.RejectReason.trim() : v.RejectReason
            v.Data = v.Data ? JSON.parse(v.Data) : {}
            if (v.Data.ext_data === '[object Object]') {
                v.Data.ext_data = ''
            }
            v.Data.ext_data = v.Data.ext_data ? JSON.parse(v.Data.ext_data) : []
            // 修正channel_id
            v.Data.ext_data.map((a:any) => {
                if (a.channel_id && !a.channel_partner_id) {
                    a.channel_partner_id = a.channel_id
                }
            })
            v.Data.imgs = v.Data.imgs ? JSON.parse(v.Data.imgs) : []
            v.Data.user_info = v.Data.user_info ? JSON.parse(v.Data.user_info) : []
            v.userStr = v.Data.user_info.map((a:any) => a.user_name + (a.is_use_car ? ' (用车人) ' : '')).join(', ')
            v.isEdit = (new Date().getTime() - new Date(v.Ctime.split(' ')[0]).getTime()) < 30 * (24 * 60 * 60 * 1000)
        })
        this.dataObj = res.data
        this.dataObj.show = true
    }
}
