import { Component, Vue } from 'vue-property-decorator'
import { storageName } from '@/localStorage'

const hiddenProperty = 'hidden' in document ? 'hidden'
    : 'webkitHidden' in document ? 'webkitHidden'
        : 'mozHidden' in document ? 'mozHidden' : null

@Component
export default class IndexPageVisibleMinXin extends Vue {
    visibilityChangeEvent = ''

    created () {
        this.visibilityInit()
    }

    visibilityInit () {
        if (!hiddenProperty) return
        this.visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange')
        document.addEventListener(this.visibilityChangeEvent, this.onVisibilityPageChange)
        this.$on('hook:destroyed', () => {
            document.removeEventListener(this.visibilityChangeEvent, this.onVisibilityPageChange)
        })
    }

    onVisibilityPageChange () {
        if (!hiddenProperty) return
        const doc = document as any
        if (!doc[hiddenProperty]) {
            if (!this.tValid()) {
                (location as any).reload()
            }
        }
    }

    tValid () {
        const t = this.__localStorage.getLocalStorage(storageName.TOKEN)
        if (!t) return false
        const tTime = Number(t.substring(34, 44)) * 1000
        const tId = Number(t.substring(44))

        const now = new Date().getTime()
        const userId = this.__store.state.user.userInfo.user_id
        // console.log(id, timeStamp, now, now < tTime)
        // console.log(this.__utils.dayjs(tTime).format('YYYY-MM-DD HH:mm:ss'))
        // console.log(this.__utils.dayjs(now).format('YYYY-MM-DD HH:mm:ss'))
        return now < tTime && userId === tId
    }
}
