import Vue from 'vue'

export let filterId = {
    value: 0
}

export const EventNames = {
    filterChange: 'filterChange',
    clearFilter: 'clearFilter',
    upldatenotice: 'upldatenotice',
    LOGIN: 'login',
    LOGOUT: 'logout'
}

export const EventBus = new Vue()
