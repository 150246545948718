import { BaseRes, ListReq, ListRes, php, java } from '@/api/index'
import { User } from '@/store/user'

export interface dk_pre_list1 {
    Id: number
    UserId: number
    UserName: string
    CustomerId: number
    CustomerName: string
    CustomerTel: string
    Dep: number
    Gro: number
    ActionTime: string
    HouseName: string
    CustomerInfo: string
    ExtData: string
    Ctime: string
    Comments: string[]
    GroupName: string
}

type dk_pre_list2 = dk_pre_list1 & {
    ChannelUsername: string | null
    ChannelName: string | null
    ChannelTel: string | null
    ZygwName: string | null
    ZygwTel: string | null
    Imgs: string[]
}

interface wordBoardReq {
    time_start?: string
    time_end?: string
    type: 1 | 2 | 3 | 4 | 5 | 6
}

interface wordBoard {
    median_cj_days: number
    sale_money_total: number
    sale_total: number
    fxs_count: number
    valid_follow_customer_num: number
    ivalid_follow_customer_num: number
    valid_follow_log_num: number
    ivalid_follow_log_num: number
    all_follow_log_num: number
    kaipiao_money_total: number
    daozhang_money_total: number
    daozhang_money_total2: number
    fanyong_money_total: number
    tuifan_money_total: number
    work_user_count: string
    renlin_new_total: number
    renlin_old_total: number
    valid_customer_num: number
    all_customer_num: number
    qc_customer_num: number
    qccjzh: number
    dk_res_group_by_customer_count: number
    dk_res_count: number
    dk_pre_count: number
    cjzh: number
    dk_rate: number
    cj_rate: number
    new_customer_count: number
    dkcj_zh: number
    avg_cj_days: number
    name: string
}

type wordBoardResT = {
    1: {
        work_info: wordBoardRes['work_info']
    },
    2: {
        msg_info: wordBoardRes['msg_info']
    },
    3: {
        impt_customer_info: wordBoardRes['impt_customer_info']
    },
    4: {
        dk_pre_list_1: wordBoardRes['dk_pre_list_1'],
        dk_pre_list_2: wordBoardRes['dk_pre_list_2']
    },
    5: {
        sale_list: wordBoardRes['sale_list']
    },
    6: {
        cj_res_list: wordBoardRes['cj_res_list']
    }
}

export interface wordBoardRes {
    // 工作情况
    work_info: {
        my: wordBoard & {
            shouyongyuce: {
                gonshi: number[]
                shouyongInfo: {
                    current_month_yuce: {
                        gongshi: string
                        money: number
                    }
                    next_month_yuce: {
                        gongshi: string
                        money: number
                    }
                }
            }
        }
        other: wordBoardRes['work_info']['my']
    }

    // 消息提醒
    msg_info: {
        msg_total: number
        msg_list: {
            [index: number]: ListRes<any> & {
                msg_name: string
                type: number
            }
        }
    }

    // 重点客户情况
    impt_customer_info: {
        menus: {
            name: string
            impt_group: number
        }[]
        is_leader: boolean
    }

    // 最近带看情况 - 即将带看
    dk_pre_list_1: ListRes<dk_pre_list1 & {
        PreWork: string
        Status: number
    }>
    dk_pre_list_2: ListRes<dk_pre_list2 & {
        AfterWork: string
    }>

    sale_list: ListRes<{
        Id: string
        UserId: string
        CustomerTel: string
        CustomerName: string
        HouseId: string
        HouseName: string
        Type: string
        SaleType: string
        Money: string
        Remark: string
        SaleTime: string
        SumMoney: string
        HouseMoney: string
        Pid: string
        ExtData: {
            user_info: {
                name: string
                money: string
                user_id: number
                dep: number
                group: number
                user_name: string
                rate: number
            }[]
            mtime: number
        }
        Sid: string
        HasTuifan: string
        Dep: string
        Gro: string
        CustomerId: string
        ChannelName: string
        ChannelUsername: string
        ChannelTel: string
        ZygwName: string
        ZygwTel: string
        HasWangqian: string
        PzImgs: string
        JieyongStatus: string
        ChannelPartnerId: string
        KaipiaoStatus: string
        FanyongStatus: string
        NextJieyongTime: null | string
        ExpectWangqianTime: null | string
        ChannelUserNote: string
        ChannelUserJob: string
        SumMustDzMoney: string
        MustDzMoney: string
        Ctime: string
        UserHouseRate: string
        HouseNo: null | string
        KaiPiaoMoney: null | string
        DaoZhangMoney: null | string
        FanYongMoney: null | string
        UserIds: number[]
        DepName: string
        GroupName: string
        user_name: string
        user_name_union: string
        CustomerChannel: string
        CustomerTypeArray: string[]
        HouseDemandRegion: string
    }> & {
        total_money: number
        total_sale_count: number
    }

    cj_res_list: ListRes<dk_pre_list2 & {
        CjDays: number
        Result: string
    }>
}

interface imptGroupCountInfoReq {
    impt_group: number
    group: number
}

export interface imptGroupCountInfoRes {
    group_name: {
        name: string
        value: number
        dep: number
    }
    fxs_list: {
        user_name: string
        count: number
    }[]
    total: number
}

interface homeRes {
    menus: (User['menus'][0] & {jump?: boolean})[]
    dgu_arr: User['infoPos']
    user_info: User['userInfo']
    sys_tags: User['sysTags']
    impt_group: User['impt']
    customer_tags: User['customerTags']
    notice: User['notice']
    customer_tag_map: User['customerTagMap']
    utm_channel: string[]
    source_type: User['sourceType'][0]
    common_dep_arr: User['common_dep_arr']
    company: User['company']
}

interface saveNoticeReq {
    content?: string
    id?: string
    status?: number
}

interface getNoticeListRes extends ListRes <homeRes['notice']['notice']> {}

interface checkHasNoReadMsgRes {
    has_no_read: boolean
}

interface saleWorkBoardReq {
    time_start: string
    time_end: string
}

type Shouyongyuce = { shouyongyuce: false | { shouyongInfo: { current_month_yuce: { money: number }, next_month_yuce: { money: number } } } }

interface saleWorkBoardRes {
    work_info: {
        [index: string]: {
            [index: string]: wordBoard & Shouyongyuce
        }
        dep: {
            [index: string]: wordBoard & Shouyongyuce
        }
        group: saleWorkBoardRes['work_info']['dep']
    }
    renlin_list: boolean
    follow_log_list: boolean
    follow_customer_list: boolean
}

interface getSaleWorkInfoReq {
    dep?: number | null
    group?: number | null
    time_start: string
    time_end: string
    type: number
}

interface getSaleWorkInfoRes {
    [index: string]: saleWorkBoardRes['work_info']['dep'][0]
}

export interface baseWorkInfoReq extends Partial<{
    'dep': number,
    'gro': number,
    'user_id': number,
    'query_date_type': string,
    'dateQrl': {
        'query_start_time': string,
        'query_end_time': string
    },
    period?: string
}>{}

interface workInfoRes {
    new_customer: number // 新客
    follower_customer: number // 跟进客户
    daikan_customer: number // 带看客户
    cj_customer: number // 成交客户
    last_cj_customer: number // 最近成交客户
    last_daikan_customer: number // 最近带看客户
    last_follower_customer: number // 最近跟进客户
    last_new_customer: number // 最近新客
}

interface clinchInfoRes {
    cjAmount: number;
    cjCount: number;
    conversionRate: number;
    dzAmount: number;
    earnedProfit: number;
    fanyonAmount: number;
    kaipiaoAmount: number;
    last_cjAmount: number;
    last_cjCount: number;
    last_conversionRate: number;
    last_dzAmount: number;
    last_earnedProfit: number;
    last_fanyonAmount: number;
    last_kaipiaoAmount: number;
    last_tuifangAmount: number;
    tuifangAmount: number;
}

interface importantCustomerInfoRes {
    abc_customer: number;
    dkmcj_customer: number;
    jjdk_customer: number;
    pub_customer: number;
    zd_customer: number;
    zjcj_customer: number;
}

interface RowColumnCount extends RowColumn {
    userCount: number
}

export interface TrendRes {
    allCount: number
    depCount: number
    groupCount: number
    result: KeyStringRowColumnRes<RowColumnCount>
}

interface customerConversionInfoReq {
    'dep'?: number,
    'group'?: number,
    'time_end': string,
    'time_start': string,
    'type': number
}

interface customerConversionInfo {
    deal: {
        dealCustomerCount: number;
        realCustomerCount: number;
    };
    follow: {
        followCustomerCount: number;
        realCustomerCount: number;
    };
    id: number;
    name: string;
    takeToLook: {
        realCustomerCount: number;
        takeToLookCustomerCount: number;
    };
}

interface customerConversionInfoRes{
    rateList: customerConversionInfo[];
    totalRate: customerConversionInfo;
}

interface baseQueryTimeType {
    'dateQrl': {
        'query_end_time': string,
        'query_start_time': string
    },
    'dep'?: number,
    'query_date_type': string
}

interface dealStatisticReq extends TimeStartEnd{
    dep_id?: number
}

interface RowColumn {
    'column': string,
    'row': string
}

interface RowColumnRate extends RowColumn {
    rate: string
}

export interface KeyStringRowColumnRes<T = RowColumn> {
    [key: string]: T[]
}

interface incomeAverageStatisticRes extends RowColumn{
}

interface dealDateDetailStatisticRes extends KeyStringRowColumnRes<RowColumnRate> {
}

interface fanyongDateDetailStatisticRes extends KeyStringRowColumnRes<RowColumnRate> {
}

interface TimeStartEnd {
    time_end: string // yyyy-MM-dd
    time_start: string
}

interface groupWorkBoardReq extends TimeStartEnd{
    dep?: number
}

interface userWorkBoardReq extends TimeStartEnd{
    dep?: number
    group?: number
}

interface WorkBoardRes {
    avg_income: number;
    customer_avg_user_count: number
    customer_goback_amount: number;
    deal_cycle_median: number;
    deal_order_count: number;
    deal_rate: string;
    effect_customer_count: number;
    fanyong_amount: number;
    forecast: null | {
        shouyongInfo: {
            current_month_yuce: {
                money: number
            },
            next_month_yuce: {
                money: number
            }
        }
    };
    income_amount: number;
    income_profit: number;
    invoice_amount: number;
    name: string;
    new_customer_totcal_count: number;
    real_customer_count: number;
    total_follow_count: number
    real_follow_count: number
    sale_money_total: number;
    take_to_look_conver_deal_rate: string;
    take_to_look_count: number;
    take_to_look_rate: string;
    all_deal_cycle_median: number
    all_first_follow_cycle_median: number
    first_follow_cycle_median: number
    customer_invalid_count: number
    efficiency: number
    take_to_look_customer_rate: string // 分配客户带看率
    take_to_look_customer_count: number
}

interface getWeeklyReq {
    end_time: string
    start_time: string
}
interface getWeeklyRes {
    wallet_detail: {
        follow_count: number
        follow_income: number
        order_customer_allowance_count: number
        order_customer_allowance_total: number
        order_customer_refund: number
        order_customer_success_count: number
        order_customer_total_count: number
        order_customer_totaol_pay: string
        take_to_look_count: number
        take_to_look_count_imcome: number
    }
    week_rank: {
        avgIncomeRank: {
            depRank: number
            groupRank: number
            score: number
        }
        depId: number
        firstFollowIntervalRank: {
            depRank: number
            groupRank: number
            score: number
        }
        groupId: number
        takeToLookRateRank: {
            depRank: number
            groupRank: number
            score: number
        }
        userId:number
    }
    work_record: {
        deal_custome_count: number
        deal_order_count: number
        last_week_take_to_look_count: number
        newCustomerCount: number
        real_customer_count: number
        sale_money_total: number
        take_to_look_count: number
        take_to_look_rate: string
        order_customer_count: number
        order_customer_follow_count: number
    }
}

interface getNewCustomerFirstFollowAggreMedianReq {
    start_time: string
    end_time: string
    group_id?: any
    dep_id?: any
    aggre_column: string
}
interface getNewCustomerFirstFollowAggreMedianRes {
    rows:{
        median_key_name: string,
        median_key: number,
        median_value: number
    }[]
}
interface getDispatchCustomerAggreDataReq {
    start_time: string
    end_time: string
    aggre_column: string
    group_id?: any
    dep_id?: any
}

interface getAllCustomerFirstFollowAggreMedianReq {
    start_time: string
    end_time: string
    group_id?: number
    dep_id?: number
}

interface getLeaderLowMarkFollowStatisticsReq {
    start_time: string
    end_time: string
    dep_id?: number
}

interface getLeaderLowMarkFollowStatisticsRes {
    rows:{
        all_count: number
        follow_count: number
        userName: string
        user_id: number
    }[]
}
interface getMsgListReq {
    has_read?: number
    msg_type?: number
    msg_search?: string
    page?: number
    pagesize?: number
    time_start?: number
    time_end?: number
}

interface getMsgListRes {
    current_page: number
    pagesize: number
    statistic_res: string
    total: number
    total_page: number
    list: [
        {
            code: number
            content: string
            content_params: string
            ctime: number
            has_read: number
            id: number
            mtime: number
            title: string
            to_user: number
            type: number
            url: string
            url_params: string
        }
    ]
}

interface getNewMsgRes {
    total_unread: number
    code: number
    content: string
    content_params: string
    ctime: number | string | Date
    has_read: number
    msg_type?: number
    id: number
    mtime: number
    title: string
    to_user: number
    type: number
    url: string
    url_params: string
    real_content?: string
    right_click?: any
}
interface markHasReadReq {
    ids: number[] | null
}

interface getProfitRecordReq{
    month: string;
    dep_id?: number;
}
interface getProfitRecordRes{
    ctime: number;
    customer_cost: number;
    dep_id: number;
    fxs_commision_cost: number;
    group_id: number;
    id: number;
    leader_commision: number;
    leader_user_id: number;
    leader_username: string
    month_date: number;
    mtime: number;
    person_cost: number;
    profit_money: number;
    total_sale_money: number;
    customer_order_money: number // 客户预约支出
    leader_final_money: number // 队长预计收入

    send_money: number
}

interface modifyPersonCostReq{
    id: number;
    money: number;
}

interface warningMsgListRes{
    id: number;
    msg: string;
    name: string;
}

export default class ApiHome {
    // 工作情况
    workBoard <T extends wordBoardReq> (data: T): BaseRes<wordBoardResT[T['type']]> {
        return php.post('', {
            action_name: 'workBoard',
            ...data
        })
    }

    // 重点客户情况
    imptGroupCountInfo (data: imptGroupCountInfoReq): BaseRes<imptGroupCountInfoRes> {
        return php.post('', {
            action_name: 'imptGroupCountInfo',
            ...data
        })
    }

    // 清空提醒
    clearMsg () {
        return php.post('', {
            action_name: 'clearMsg'
        })
    }

    home (): BaseRes<homeRes> {
        return php.post('', {
            action_name: 'home'
        })
    }

    // 个人中心通知是否已读
    checkHasNoReadMsg (): BaseRes<checkHasNoReadMsgRes> {
        return php.post('', {
            action_name: 'checkHasNoReadMsg'
        })
    }

    // 发表通知
    saveNotice (data: saveNoticeReq) {
        return php.post('', {
            action_name: 'saveNotice',
            ...data
        })
    }

    // 销售工作详情
    saleWorkBoard (data: saleWorkBoardReq): BaseRes<saleWorkBoardRes> {
        return php.post('', {
            action_name: 'saleWorkBoard',
            ...data
        })
    }

    // 获取销售详情列表
    getSaleWorkInfo (data: getSaleWorkInfoReq): BaseRes<getSaleWorkInfoRes> {
        return php.post('', {
            action_name: 'getSaleWorkInfo',
            ...data
        })
    }

    // 获取公告列表
    getNoticeList (data: ListReq): BaseRes<getNoticeListRes> {
        return php.post('', {
            action_name: 'getNoticeList',
            ...data
        })
    }

    // 删除公告
    deleteNotice (id: number) {
        return php.post('', {
            action_name: 'deleteNotice',
            id
        })
    }

    // 工作情况
    workInfo (data: baseWorkInfoReq): BaseRes<workInfoRes> {
        return java.post('/admin/data/workInfo', data)
    }

    // 成交情况
    clinchInfo (data: baseWorkInfoReq): BaseRes<clinchInfoRes> {
        return java.post('/admin/data/clinchInfo', data)
    }

    // 重点客户情况
    importantCustomerInfo (data: baseWorkInfoReq): BaseRes<importantCustomerInfoRes> {
        return java.post('/admin/data/importantCustomer', data)
    }

    // 新客户趋势
    newCustomerTrend (data: baseWorkInfoReq): BaseRes<TrendRes> {
        return java.post('/admin/data/trend/newCustomer', data)
    }

    // 跟进客户趋势
    followerCustomerTrend (data: baseWorkInfoReq): BaseRes<TrendRes> {
        return java.post('/admin/data/trend/followerCustomer', data)
    }

    // 发布跟进数趋势
    followerCountTrend (data: baseWorkInfoReq): BaseRes<TrendRes> {
        return java.post('/admin/data/trend/followCount', data)
    }

    // 带看客户趋势
    daikanCountTrend (data: baseWorkInfoReq): BaseRes<TrendRes> {
        return java.post('/admin/data/trend/daikanCount', data)
    }

    // 成交客户趋势
    cjCountTrend (data: baseWorkInfoReq): BaseRes<TrendRes> {
        return java.post('/admin/data/analysis/dealCustomerCount/statistic/dateDetail', data)
    }

    // 成交金额趋势
    cjHouseMoneyAmountTrend (data: baseWorkInfoReq): BaseRes<TrendRes> {
        return java.post('/admin/data/trend/cjHouseMoneyAmount', data)
    }

    // 客户转化趋势
    customerRateTrend (data: baseWorkInfoReq): BaseRes<TrendRes> {
        return java.post('/admin/data/trend/customerRate', data)
    }

    // 返佣金额趋势
    fanyonAmountTrend (data: baseWorkInfoReq): BaseRes<TrendRes> {
        return java.post('/admin/data/trend/fanyonAmountTrend', data)
    }

    // 开票金额趋势
    kaipiaoAmountTrend (data: baseWorkInfoReq): BaseRes<TrendRes> {
        return java.post('/admin/data/trend/kaipiaoAmount', data)
    }

    // 到账金额趋势
    dzyjAmountTrend (data: baseWorkInfoReq): BaseRes<TrendRes> {
        return java.post('/admin/data/trend/dzyjAmount', data)
    }

    // 到账利润趋势
    earnedProfitTrend (data: baseWorkInfoReq): BaseRes<TrendRes> {
        return java.post('/admin/data/trend/earnedProfit', data)
    }

    // 退房金额趋势
    tuifangAmoutTrend (data: baseWorkInfoReq): BaseRes<TrendRes> {
        return java.post('/admin/data/trend/tuifangAmout', data)
    }

    // 成交套数趋势
    cjHouseCountTrend (data: baseWorkInfoReq): BaseRes<TrendRes> {
        return java.post('/admin/data/trend/cjCount', data)
    }

    // --------------------

    // 客户转化数据漏斗接口
    customerConversionInfo (data: customerConversionInfoReq): BaseRes<customerConversionInfoRes> {
        return java.post('/admin/data/analysis/customer/conversion/info', data)
    }

    // 成交金额统计饼图
    dealStatistic (data: dealStatisticReq): BaseRes<RowColumn[]> {
        return java.post('/admin/data/analysis/deal/statistic', data)
    }

    // 单客创收统计
    incomeAverageStatistic (data: baseQueryTimeType): BaseRes<incomeAverageStatisticRes[]> {
        return java.post('/admin/data/analysis/income/average', data)
    }

    // 成交金额时间段划分
    dealDateDetailStatistic (data: baseQueryTimeType): BaseRes<dealDateDetailStatisticRes> {
        return java.post('/admin/data/analysis/deal/statistic/dateDetail', data)
    }

    // 返佣金额统计时间分组
    fanyongDateDetailStatistic (data: baseQueryTimeType): BaseRes<fanyongDateDetailStatisticRes> {
        return java.post('/admin/data/analysis/fanyong/statistic/dateDetail', data)
    }

    // 部门或小组带看数量
    daikanStatistic (data: baseQueryTimeType): BaseRes<KeyStringRowColumnRes<RowColumnRate>> {
        return java.post('/admin/data/analysis/daikan/statistic', data)
    }

    // 分配客户数据统计
    distributionStatistic (data: baseQueryTimeType): BaseRes<KeyStringRowColumnRes<RowColumn>> {
        return java.post('/admin/data/analysis/distribution/statistic', data)
    }

    // 部门数据面板
    depWorkBoard (data: TimeStartEnd): BaseRes<WorkBoardRes[]> {
        return java.post('/admin/data/analysis/depWorkBoard', data)
    }
    // 战队数据面板
    groupWorkBoard (data: groupWorkBoardReq): BaseRes<WorkBoardRes[]> {
        return java.post('/admin/data/analysis/groupWorkBoard', data)
    }
    // 分析师数据面板
    userWorkBoard (data: userWorkBoardReq): BaseRes<WorkBoardRes[]> {
        return java.post('/admin/data/analysis/userWorkBoard', data)
    }

    // 获取个人周报
    getWeekly (data: getWeeklyReq): BaseRes<getWeeklyRes> {
        return java.post('/admin/data/analysis/userWeekDataAnalysis', {
            ...data
        })
    }
    // 跟进中位数聚合统计
    getNewCustomerFirstFollowAggreMedian (data: getNewCustomerFirstFollowAggreMedianReq): BaseRes<getNewCustomerFirstFollowAggreMedianRes> {
        return java.post('/admin/customer/follow/statistics/getNewCustomerFirstFollowAggreMedian', {
            ...data
        })
    }
    // 分配客户平均数
    getDispatchCustomerAggreData (data: getDispatchCustomerAggreDataReq): BaseRes<any> {
        return java.post('/admin/customer/follow/statistics/getDispatchCustomerAggreData', {
            ...data
        })
    }
    // 整合筛选跟进中位数跟进
    getAllCustomerFirstFollowAggreMedian (data: getAllCustomerFirstFollowAggreMedianReq): BaseRes<any> {
        return java.post('/admin/customer/follow/statistics/getAllCustomerFirstFollowAggreMedian', {
            ...data
        })
    }
    // 队长跟进统计
    getLeaderLowMarkFollowStatistics (data: getLeaderLowMarkFollowStatisticsReq): BaseRes<getLeaderLowMarkFollowStatisticsRes> {
        return java.post('/admin/customer/follow/statistics/getLeaderLowMarkFollowStatistics', {
            ...data
        })
    }
    // 获取消息列表
    getMsgList (data: getMsgListReq): BaseRes<getMsgListRes> {
        return java.post('/admin/msgCenter/getMsgList', {
            ...data
        })
    }
    // 获取最新消息
    getNewMsg (data: any): BaseRes<getNewMsgRes> {
        return java.post('/admin/msgCenter/getNewMsg', {
            ...data
        })
    }
    // 标记消息已读
    markHasRead (data: markHasReadReq): BaseRes<any> {
        return java.post('/admin/msgCenter/markHasRead', {
            ...data
        })
    }

    // 战队利润列表
    getProfitRecord (data: getProfitRecordReq): BaseRes<getProfitRecordRes[]> {
        return java.post('/admin/profit/getProfitRecord', data)
    }

    // 人员成本修改
    modifyPersonCost (data: modifyPersonCostReq) {
        return java.post('/admin/profit/modifyPersonCost', data)
    }

    // 获取警告消息列表
    warningMsgList (): BaseRes<warningMsgListRes[]> {
        return java.post('/admin/warningMsg/list', {})
    }

    // 关闭警告消息
    warningMsgClose (msg_id: number) {
        return java.post('/admin/warningMsg/close', { msg_id })
    }
}
