import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { getAllSalePartnerListRes } from '@/api/utils'

@Component
export default class InputSearch extends Vue {
    @Prop({
        type: String,
        default: 'user'
    })
    type !: string

    @Prop({
        type: String,
        default: ''
    })
    size !: string

    @Prop({
        type: String,
        default: ''
    })
    value !: string

    @Prop({
        type: String,
        default: '请输入关键词'
    })
    placeholder !: string

    word = this.value
    selectObj = {}
    options: getAllSalePartnerListRes['list'] = []
    typeList: {
        [index: string]: {
            fetch: string
            select: string
        }
    } = {
        'user': {
            fetch: 'userSearch',
            select: 'userSelect'
        },
        'house': {
            fetch: 'houseSearch',
            select: 'houseSelect'
        },
        'qd': {
            fetch: 'qdSearch',
            select: 'qdSelect'
        }
    }

    @Watch('value')
    watchValue (newVal: string) {
        this.word = newVal
    }

    @Watch('word')
    watchWorld (newVal: string) {
        this.$emit('input', newVal)
    }

    @Watch('selectObj')
    watchSelectObj () {
        this.$emit('my-select', this.selectObj)
    }

    get
    mode () {
        return this.type
    }
    get
    placeText () {
        return this.placeholder
    }
    get
    fetchHandle () {
        // @ts-ignore
        return this[this.typeList[this.mode].fetch]
    }
    get
    selectHandle () {
        // @ts-ignore
        return this[this.typeList[this.mode].select]
    }
    get
    typeListInit () {
        let obj = {}
        for (let key in this.typeList) {
            if (key === this.mode) {
                obj = {
                    [key]: this.typeList[key]
                }
            }
        }
        return obj
    }

    // 清空input事件
    clearInput () {
        this.word = ''
        this.selectObj = {}
    }

    // 搜索人
    async userSearch (queryString: string, cb: any) {
        const res = await this.__apis.utils.searchUser({
            key: queryString
        })

        res.data.list.map((v: any) => {
            v.value = v.Username + '(' + v.groupName + ')'
        })
        cb(res.data.list)
    }

    userSelect (obj: any) {
        this.word = obj.value
        this.selectObj = obj
    }

    // 楼盘匹配
    async houseSearch (queryString: string, cb: any) {
        const res = await this.__apis.utils.searchHouse({
            term: queryString
        })

        cb(res.data)
    }

    houseSelect (obj: any) {
        this.word = obj.value
        this.selectObj = obj
    }

    // 渠道合作商匹配
    async qdSearch (queryString: string, cb: any) {
        const res = await this.__apis.utils.getAllSalePartnerList(queryString)

        res.data.list.map((v: any) => {
            v.showName = `${v.full_name}`
            v.value = v.full_name_new
        })
        this.options = res.data.list
        cb(res.data.list)
    }

    qdSelect (obj: any) {
        this.word = obj.value
        this.selectObj = obj
    }
}
