import { Component, Vue } from 'vue-property-decorator'
import SetCookieComponent from './setCookie/index.vue'
import { apiConfig } from '@/api/config'
import { __localStorage, storageName } from '@/localStorage'

export enum PlatformStatus {
    online = 1,
    offline = 0
}

enum PlatformType {
    validCode = 'validCode',
    cookie = 'cookie'
}

const PlatformStatusList = [
    {
        label: '掉线',
        value: 0
    },
    {
        label: '在线',
        value: 1
    }
]

interface TableItem {
    key: string,
    name: string,
    cookieName: string
    status: number,
    statusText: string,
    type: string,
    value: string
    time: number
}

@Component({
    components: {
        SetCookieComponent
    }
})
export default class MarketLoginListPage extends Vue {
    loginData: TableItem[] = []
    loading = false

    getValidCodeSrc (item: TableItem) {
        return `${apiConfig.phpUrl}/index/phone-verify-code?key=${item.key}&time=${item.time}&ac_token=${__localStorage.getLocalStorage(storageName.TOKEN)}`
    }

    refreshImage (item: TableItem) {
        item.time = new Date().getTime()
    }

    btnDisabled (item: TableItem) {
        return this.isValidCode(item) ? !item.value : false
    }

    // 掉线
    isOffline (item: TableItem) {
        return item.status === PlatformStatus.offline
    }

    // 验证码形式
    isValidCode (item: TableItem) {
        return item.type === PlatformType.validCode
    }

    loginPlatform (item: TableItem) {
        if (item.type === PlatformType.validCode) {
            this.validCodeLogin(item)
        } else {
            this.cookieLoginShowDialog(item)
        }
    }

    async validCodeLogin (item: TableItem) {
        await this.__apis.market.login400({
            type: item.key,
            code: item.value
        })
        this.getData()
        this.$notify({
            title: '成功',
            message: '登录成功',
            type: 'success'
        })
    }

    cookieDialog = {
        visible: false,
        data: {}
    }

    cookieLoginShowDialog (item: TableItem) {
        this.cookieDialog.visible = true
        this.cookieDialog.data = item
    }

    async getData () {
        try {
            this.loading = true
            const [
                { data: dataAccount },
                { data: dataAccountStatus }
            ] = await Promise.all([
                this.__apis.market.getAccountList(),
                this.__apis.market.checkLoginStatus()
            ])
            this.loginData = dataAccount.map((a, i) => {
                const is400 = a.customerChannelType === 1
                return {
                    key: a.showEnName,
                    name: a.showCnName,
                    status: dataAccountStatus[a.showEnName],
                    statusText: this.__utils.index.getLabelByValue(PlatformStatusList, dataAccountStatus[a.showEnName]),
                    type: is400 ? PlatformType.validCode : PlatformType.cookie,
                    cookieName: a.cookieName,
                    value: '',
                    time: new Date().getTime() - i
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        } finally {
            this.loading = false
        }
    }

    async checkStatus () {
        const { data: dataAccountStatus } = await this.__apis.market.checkLoginStatus()
        this.loginData.forEach(a => {
            a.status = dataAccountStatus[a.key]
            a.statusText = this.__utils.index.getLabelByValue(PlatformStatusList, dataAccountStatus[a.key])
        })
        this.$notify({
            title: '成功',
            message: '刷新成功',
            type: 'success'
        })
    }

    created () {
        this.getData()
    }
}
