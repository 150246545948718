import { Component, Vue } from 'vue-property-decorator'
import InputHouseSearch from '@/components/InputHouseSearch/index.vue'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'
import dataRange from '@/util/date.ts'
import qmPermission from 'qm-plugin-admin-permission'

@Component({
    components: {
        InputHouseSearch,
        inputSelectSearch
    }
})
export default class IndexPage extends Vue {
    form: any = {
        ctime: '',
        customer_name: '',
        customer_tel: '',
        house_name: '',
        dep: '',
        group: '',
        user_id: '',
        has_kp: '',
        has_dz: '',
        has_fy: '',
        has_wangqian: '',
        has_tuifan: '',
        has_trouble: '',
        channel_name: '',
        channel_partner_id: ''
    }
    dateFilter = ['本月', '上月', '本周', '上周', '全部']
    dateFilterCur = 4
    dataObj: any = {}
    page = 1
    pageSize = 20
    exportData: any = {}
    loading = false

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    mounted () {
        this.form.dep = this.infoPos.dep
        this.form.group = this.infoPos.group
        this.form.user_id = this.infoPos.fxs

        this.getData()
    }

    async getData () {
        this.loading = true
        let form = this.__utils.index.copyObj(this.form)
        const fxs_day = form.fxs_day || 0

        if (this.__role.has([this.__role.ROLE.FENXISHI]) && form.user_id) {
            form.dep = ''
            form.group = ''
        }
        if (form.fxs_id) {
            // 搜索分析师
            form.dep = ''
            form.group = ''
            form.user_id = form.fxs_id
            delete form.fxs
            delete form.fxs_day
        }
        let data = Object.assign(form, {
            type: this.$route.query.type,
            page: this.page,
            pagesize: this.pageSize,
            time_start: this.form.ctime && this.form.ctime[0] ? this.form.ctime[0] : '',
            time_end: this.form.ctime && this.form.ctime[1] ? this.form.ctime[1] : '',
            ctime: ''
        })

        const res = await this.__apis.financial.getSaleList(data)
        const { data: saleAreaBuild } = await this.__apis.financial.getHouseSaleAreaBuild(data)

        res.data.list.map((v, i) => {
            v.ExtData.user_info && v.ExtData.user_info.map((vv: any) => {
                vv.rate = vv.rate === 0 ? 0 + '%' : (vv.rate * 100).toFixed(4) + '%'
            })
            const yj = v.SumMoney * 1
            const fy = (v as any).FanYongMoney * 1
            const yd = v.SumMustDzMoney * 1
            if (yj + fy - yd >= 0.01 || yj + fy - yd <= -0.01) {
                (res.data.list[i] as any).is_wrong_money = true // 金额有问题
            }
        })

        this.dataObj = {
            ...res.data,
            saleAreaBuild: saleAreaBuild.toFixed(2),
            cjInfoShow: res.data.cjInfo ? [res.data.cjInfo] : this.dataObj.cjInfoShow
        }

        this.exportData = data

        // 返佣率，成交到佣率
        this.dataObj.cjInfoShow.forEach((a: any) => {
            a.fanyong_rate = (a.current_cj_fanyong_money / a.sale_must_dz_money_total * 100).toFixed(2) + '%'
            a.success_daoyong_rate = (a.current_cj_daozhang_money / a.sale_must_dz_money_total * 100).toFixed(2) + '%'
            a.yongjin_rate = (a.sale_must_dz_money_total / a.house_sum_money * 100).toFixed(2) + '%'
            a.month_average_value = ''
            a.rz_day = ''

            // 搜索分析师才会显示 月平均佣金 入职天数
            if (fxs_day && qmPermission.check(this.__role.permissions.DaysAndCommission)) {
                a.month_average_value = (a.sale_money_total / fxs_day * 30.5).toFixed(2)
                a.rz_day = fxs_day
            }
        })
        this.loading = false
    }

    toCustomerDetail (id: number) {
        let { href } = this.$router.resolve({
            path: `/customer/detail/${id}`
        })
        window.open(href, '_blank')
    }

    toEdit (id: number, bool: boolean) {
        let obj = {
            path: '/financial/success_detail/success/' + id
        }
        if (bool) {
            let { href } = this.$router.resolve(obj)
            window.open(href, '_blank')
        } else {
            this.$router.push(obj)
        }
    }

    // 部门改变
    departSelect () {
        this.form.group = ''
        this.form.user_id = ''
    }

    // 组改变
    groupSelect () {
        this.form.user_id = ''
    }

    // 日期按钮
    dateFilterHandle (index: number, key: string) {
        // @ts-ignore
        this[key] = index
        let dateTime
        if (index === 1) {
            dateTime = {
                time_start: dataRange.getCurrentYesterday().format('yyyy-MM-dd'),
                time_end: dataRange.getCurrentYesterday().format('yyyy-MM-dd')
            }
        } else if (index === 2) {
            dateTime = {
                time_start: dataRange.getCurrentWeek()[0].format('yyyy-MM-dd'),
                time_end: dataRange.getCurrentWeek()[1].format('yyyy-MM-dd')
            }
        } else if (index === 3) {
            dateTime = {
                time_start: dataRange.getPreviousWeek()[0].format('yyyy-MM-dd'),
                time_end: dataRange.getPreviousWeek()[1].format('yyyy-MM-dd')
            }
        } else {
            dateTime = {
                time_start: '',
                time_end: ''
            }
        }
        this.form.ctime = [dateTime.time_start, dateTime.time_end]
    }

    pageChange (page: number) {
        this.page = page
        this.getData()
    }

    // 搜索
    clickSearch () {
        this.page = 1
        this.getData()
    }

    // 清空筛选
    resetFrom (formName: string) {
        const form = this.$refs[formName] as any
        form.resetFields()
        this.form.channel_partner_id = ''
        this.clearFxs()
        this.page = 1
        this.getData()
    }

    // 导出
    exportList () {
        this.exportData.action_name = 'exportSaleList'
        if (this.exportData.page) {
            delete this.exportData.page
            delete this.exportData.pageSize
        }

        this.__utils.index.downloadFile({
            ...this.exportData
        })
    }

    // 搜索人
    async querySearchName (queryString: string, cb: any) {
        if (!queryString || queryString.length === 0) return

        const res = await this.__apis.utils.searchUser({
            fxs: 1,
            key: queryString
        })

        res.data.list.map((v: any) => {
            v.value = v.Username
        })
        cb(res.data.list)
    }

    nameSelect (item: any) {
        this.form.fxs = item.value
        this.form.fxs_id = item.Id

        // 分析师入职天数
        const day = item.Ctime && item.Ctime.match(/^\d+-\d+-\d+/)
        if (day) {
            this.form.fxs_day = Math.floor((item.LastLoginTime * 1000 - new Date(day[0]).getTime()) / 86400000)
        }
    }

    clearFxs () {
        this.form.fxs_id = ''
        this.form.fxs = ''
        this.form.fxs_day = 0
    }
}
