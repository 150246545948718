import { Component, Vue, Watch } from 'vue-property-decorator'
import dataRange from '@/util/date.ts'
import { apiConfig } from '@/api/config'
import { storageName } from '@/localStorage'

@Component({
    components: {
    }
})
export default class SaleManChengJiaoPage extends Vue {
    dataObj: any = {}
    page = 1
    pageSize = 20
    dialogCommetVisible = false
    commet = ''
    commetId = ''
    dialogFormVisible = false
    titleDialogText = '成交总结'
    form: any = {
        customer_name: '',
        customer_tel: '',
        action_time: '',
        region: '',
        house_name: '',
        house_id: '',
        channel_name: '',
        channel_username: '',
        channel_tel: '',
        zygw_name: '',
        zygw_tel: '',
        imgs: '',
        customer_info: '',
        result: '',
        ext_data: [
            {
                house_name: '',
                channel_name: '',
                channel_username: '',
                channel_tel: '',
                zygw_name: '',
                zygw_tel: ''
            }
        ]
    }
    editCurrent = {}
    formLabelWidth = '120px'
    // 图片上传
    dialogImageUrl = apiConfig.apiUrl
    dialogImagePrev = ''
    dialogVisible = false
    fileList: any[] = []
    dateFilter = ['不限', '昨天', '本周', '上周']
    dateFilterCur = 0
    formSearch: any = {
        ctime: '',
        username: '',
        phone: '',
        house_name: '',
        dep: '',
        group: '',
        user_id: ''
    }
    guideType = 0
    guideTypeList = ['成交总结']

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }
    get
    userInfo () {
        return this.__store.state.user.userInfo
    }
    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }

    @Watch('formSearch', { deep: true })
    formSearchChange () {
        this.pageChange(1)
    }

    mounted () {
        this.formSearch.dep = this.infoPos.dep
        this.formSearch.group = this.infoPos.group
        this.formSearch.user_id = this.infoPos.fxs

        this.getData()
    }

    async getData () {
        let form = this.__utils.index.copyObj(this.formSearch)
        form.ctime = ''
        let data = Object.assign(form, {
            action_name: 'getCjResList',
            page: this.page,
            pagesize: this.pageSize,

            time_start: this.formSearch.ctime ? this.formSearch.ctime[0] : '',
            time_end: this.formSearch.ctime ? this.formSearch.ctime[1] : ''
        })
        if (this.infoPos.dep) data.dep = ''
        if (this.infoPos.group) data.group = ''

        const res = await this.__apis.customer.getCjResList(data)

        res.data.list.map((v: any) => {
            if (v.ExtData === '[object Object],[object Object]' ||
                v.ExtData === '[object Object]' || !v.ExtData) {
                v.ExtData = []
            }
            v.ExtData = !v.ExtData ? [] : JSON.parse(v.ExtData)
            v.ExtData2 = v.ExtData
        })
        this.dataObj = res.data
        this.dataObj.show = true
    }

    // 图片上传
    handleRemove (file: any, fileList: any) {
        this.fileList = fileList
    }

    handlePictureCardPreview (file: any) {
        this.dialogImagePrev = file.url
        this.dialogVisible = true
    }

    handlePictureSuccess (response: any, file: any) {
        this.fileList.push({
            url: response.data.url,
            data: file,
            name: file.name
        })
    }

    handlePictureError () {
        this.$notify({
            title: '错误',
            message: '上传失败',
            type: 'error'
        })
    }

    // 分页改变
    pageChange (page: number) {
        this.page = page
        this.getData()
    }

    // 评论弹窗
    commetDialog (obj: any) {
        this.commetId = obj.Id
        this.dialogCommetVisible = true
    }

    // 保存评论
    async addCommet () {
        await this.__apis.customer.saveDkCjComment({
            record_id: this.commetId,
            content: this.commet,
            type: 3
        })

        this.dialogCommetVisible = false
        this.$notify({
            title: '成功',
            message: '评论成功！',
            type: 'success'
        })
        this.commet = ''
        this.getData()
    }

    // 编辑弹窗
    editDialog (objs: any) {
        let obj = this.__utils.index.copyObj(objs)
        this.editCurrent = obj
        this.form = {
            id: obj.Id,
            customer_id: obj.CustomerId,
            customer_name: obj.CustomerName,
            house_name: obj.HouseName,
            action_time: obj.ActionTime,
            customer_info: obj.CustomerInfo,
            channel_name: obj.ChannelName,
            channel_username: obj.ChannelUsername,
            channel_tel: obj.ChannelTel,
            zygw_name: obj.ZygwName,
            zygw_tel: obj.ZygwTel,
            result: obj.Result,
            ext_data: obj.ExtData,
            user_name: obj.UserName
        }
        if (obj.Imgs && obj.Imgs.length > 0) {
            this.fileList = obj.Imgs.map((v: any) => {
                return { url: v }
            })
        } else {
            this.fileList = []
        }
        this.dialogFormVisible = true
    }

    // 编辑保存
    async saveForm () {
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)'
        })

        try {
            let data = this.__utils.index.copyObj(this.form)
            data.imgs = this.fileList.map((a:any) => a.url).join(',')
            data.ext_data = JSON.stringify(data.ext_data)

            await this.__apis.customer.saveCjRes(data)

            this.$notify({
                title: '成功',
                message: '保存成功！',
                type: 'success'
            })
            this.dialogFormVisible = false
            this.getData()
        } catch (e) {
            throw e
        } finally {
            loading.close()
        }
    }

    // 楼盘匹配
    async querySearchAsync (queryString: string, cb: any) {
        if (!queryString || queryString.length === 0) {
            // eslint-disable-next-line standard/no-callback-literal
            cb([])
            return
        }

        const res = await this.__apis.utils.searchHouse({
            term: queryString
        })
        cb(res.data)
    }

    handleHouseSelect (val: any) {
        this.form.house_name = val.label
        this.form.house_id = val.id
    }

    // 部门改变
    departSelect () {
        this.formSearch.group = ''

        if (!this.formSearch.dep) {
            this.formSearch.user_id = ''
        }
    }

    // 组改变
    groupSelect () {
        this.formSearch.user_id = ''
    }

    // 日期按钮
    dateFilterHandle (index: number, key: string) {
        // @ts-ignore
        this[key] = index
        let dateTime
        if (index === 1) {
            dateTime = {
                time_start: dataRange.getCurrentYesterday().format('yyyy-MM-dd'),
                time_end: dataRange.getCurrentYesterday().format('yyyy-MM-dd')
            }
        } else if (index === 2) {
            dateTime = {
                time_start: dataRange.getCurrentWeek()[0].format('yyyy-MM-dd'),
                time_end: dataRange.getCurrentWeek()[1].format('yyyy-MM-dd')
            }
        } else if (index === 3) {
            dateTime = {
                time_start: dataRange.getPreviousWeek()[0].format('yyyy-MM-dd'),
                time_end: dataRange.getPreviousWeek()[1].format('yyyy-MM-dd')
            }
        } else {
            dateTime = {
                time_start: '',
                time_end: ''
            }
        }
        this.formSearch.ctime = [dateTime.time_start, dateTime.time_end]
    }

    // 搜索
    clickSearch () {
        this.page = 1
        this.getData()
    }

    // 清空筛选
    resetFrom (formName: string) {
        const form = this.$refs[formName] as any

        this.dateFilterCur = 0
        form.resetFields()
        this.getData()
    }

    routerPush (obj: any, bool: boolean) {
        if (bool) {
            let { href } = this.$router.resolve(obj)
            window.open(href, '_blank')
        } else {
            this.$router.push(obj)
        }
    }

    // 添加项目
    add_project () {
        this.form.ext_data.push({
            house_name: '',
            channel_name: '',
            channel_username: '',
            channel_tel: '',
            zygw_name: '',
            zygw_tel: ''
        })
    }

    // 删除项目
    projectDel (index: number) {
        this.form.ext_data.splice(index, 1)
    }

    async delItem (obj: any) {
        try {
            await this.$confirm('确认删除？')

            await this.__apis.customer.deleteDkCj({
                type: 'cj_res',
                id: obj.Id
            })

            this.$notify({
                title: '成功',
                message: '删除成功！',
                type: 'success'
            })
            this.getData()
        } catch (e) {
            console.log(e)
        }
    }
    selectHandle (index: number, key: string) {
        // @ts-ignore
        this[key] = index
        this.page = 1
    }
}
