import { Component, Vue, Prop } from 'vue-property-decorator'
import { __utils } from '@/util'

export interface editMoneyData {
    [key: string]: string
    employeeId: string
    allMoney: string,
    department: string,
    group: string,
    fxs: string,
    month: string
    remark: string
}

@Component
export default class EditMoney extends Vue {
    @Prop({
        default: '',
        type: String
    })
    type !: string
    @Prop({
        default: false,
        type: Boolean
    })
    show !: boolean
    @Prop({
        default () {
            return {
                employeeId: '',
                allMoney: '',
                department: '',
                group: '',
                fxs: '',
                month: '',
                remark: ''
            }
        },
        type: Object
    })
    item !: editMoneyData

    form: editMoneyData = {
        employeeId: '',
        allMoney: '',
        department: '',
        group: '',
        fxs: '',
        month: '',
        remark: ''
    }

    get
    cmtBtnEnterDisabled () {
        return !this.form.allMoney.trim() || !this.form.month
    }

    created () {
        this.form = this.__utils.index.copyObj(this.item)
    }

    limitMoneyInput (e: string, key: string, hasMinus = false) {
        let minus = e[0] === '-' && hasMinus ? '-' : ''

        this.form[key] = minus + this.__utils.index.limitMoneyByInput(e, 1000000)
    }

    async enter () {
        const { data } = await this.__apis.wallet.addFinanceOutRecord({
            month: __utils.index.formatDate(new Date(this.form.month), 'YYYY-MM-DD'),
            money: +this.form.allMoney,
            employee_id: +this.form.employeeId,
            remark: this.form.remark
        })

        if (data.error_message) {
            return this.$message({
                type: 'error',
                message: data.error_message
            })
        }

        this.$emit('enter')
        this.close()
    }

    close () {
        this.$emit('update:show', false)
    }
}
