import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class CreateUserComponent extends Vue {
    @Prop({
        default: false
    })
    visible!:boolean

    form: {
        commonDep: string | number
        dep: string | number
        group: string | number
        name: string
        mobile: string
        company: string
        role?: number
        projectRole: number[][]
    } = {
        name: '',
        mobile: '',
        company: '',
        commonDep: '',
        dep: '',
        group: '',
        role: undefined,
        projectRole: []
    }

    rules = {
        name: [
            { required: true, message: '请输入姓名', trigger: 'change' }
        ],
        mobile: [
            { required: true, message: '请输入手机号', trigger: 'change' },
            { min: 11, max: 11, message: '请输入正确手机号', trigger: 'blur' }
        ],
        company: [
            { required: true, message: '请选择公司', trigger: 'change' }
        ],
        commonDep: [
            { required: true, message: '请选择通用部门', trigger: 'change' }
        ]
    }

    get
    comanyList () {
        return this.__store.state.user.company
    }

    roleList:{
        label: string
        value: number
        status: number
    }[] = []

    projectRolesProps = {
        multiple: true,
        label: 'name',
        value: 'id',
        children: 'roles'
    }

    get
    projectRoles () {
        return this.__utils.index.getProjectsRoles()
    }

    get
    common_dep_arr () {
        return this.__store.state.user.common_dep_arr
    }

    get
    depList () {
        return this.__utils.index.getXsDep()
    }

    get
    groupList () {
        return this.__utils.index.getGroupsByDepId(+this.form.dep)
    }

    async created () {
        this.roleList = await this.__utils.index.getAdminRoleList()
    }

    close () {
        this.$emit('update:visible', false)
        const form = this.$refs['form'] as any
        form.resetFields()
    }

    checkValid () {
        const form = this.$refs['form'] as any
        form.validate((valid: any) => {
            if (valid) {
                this.save()
            } else {
                return false
            }
        })
    }

    saveProjectRoles (projectRole: number[][]) {
        const roleObj:{[key:string]: number[]} = projectRole.reduce((r:{[key:number]: number[]}, a) => {
            if (!r[a[0]]) {
                r[a[0]] = []
            }
            r[a[0]].push(a[1])
            return r
        }, {})
        console.log(roleObj)
        const roleList = this.projectRoles.map(a => {
            return {
                project_id: a.id,
                role_ids: roleObj[a.id] || []
            }
        })
        console.log(roleList)
        return roleList
    }

    async save () {
        let loading:any

        if (this.__utils.index.limitRepeatClick()) return

        try {
            loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            })

            await this.__apis.system.createEmployee({
                mobile: this.form.mobile,
                user_name: this.form.name,
                company: this.form.company,
                common_dep: +this.form.commonDep,
                fxs_dep: +this.form.dep,
                group_id: +this.form.group,
                role_id: this.form.role || 0,
                project_roles: this.saveProjectRoles(this.form.projectRole)
            })

            loading.close()
            this.close()
            this.$emit('save')
            this.$notify({
                title: '成功',
                message: '保存成功！',
                type: 'success'
            })
        } catch (e) {
            loading && loading.close()
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }
}
