import { Component, Vue } from 'vue-property-decorator'
import InputHouseSearch from '@/components/InputHouseSearch/index.vue'

import FilterComponent from '@/components/filters/filter/index.vue'
import inputDateRange from '@/components/filters/inputDateRange/index.vue'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'
import { GetInvalidCustomerApplyPageReq } from '@/api/customer'

@Component({
    components: {
        InputHouseSearch,
        FilterComponent,
        inputDateRange,
        FilterBusSelectComponent,
        FilterBusInputSelectComponent
    }
})
export default class InvalidRecordPage extends Vue {
    dataObj: any = {
        res: []
    }
    page = 1
    pageSize = 50
    total = 0
    sortType = ''
    sortMode = ''
    formLabelWidth = '90px'
    filter: {
        ctime?: GetInvalidCustomerApplyPageReq['ctime']
        apply_time?: GetInvalidCustomerApplyPageReq['ctime']
    } = {}
    applyInvalidStatus = [
        { label: '', value: -1 }, // 不能申请 false
        { label: '申请中', value: 0 }, // false
        { label: '通过', value: 1 }, // false
        { label: '驳回', value: 2 }, // true
        { label: '', value: 3 } // 从来没有申请，且可以申请 true
    ]
    statusTotal = {}

    get infoPos () {
        return this.__store.state.user.infoPos
    }

    created () {
        this.getAllTotal()
        this.getData()
    }

    async getAllTotal () {
        let arr = [1, 2, 0].map((v) => {
            return this.__apis.customer.getInvalidCustomerApplyPage({
                status: v,
                page: 1,
                ...this.filter
            })
        })
        let res = await Promise.all(arr)
        this.statusTotal = res.map((v) => v.data.total)
    }

    async getData () {
        const ctime = this.filter.ctime && this.__utils.index.formatTimeStartTimeEnd([this.filter.ctime.query_start_time, this.filter.ctime.query_end_time])
        const applyTime = this.filter.apply_time && this.__utils.index.formatTimeStartTimeEnd([this.filter.apply_time.query_start_time, this.filter.apply_time.query_end_time])

        const { data } = await this.__apis.customer.getInvalidCustomerApplyPage({
            ...this.filter,
            page: this.page,
            pagesize: this.pageSize,
            ctime: ctime && {
                query_start_time: ctime[0],
                query_end_time: ctime[1]
            },
            apply_time: applyTime && {
                query_start_time: applyTime[0],
                query_end_time: applyTime[1]
            }
        })

        this.dataObj = data

        this.dataObj.list = data.list.map(a => {
            return {
                dep_name: this.__utils.index.getDepInfoById(a.dep_id).v,
                group_name: this.__utils.index.getGroupInfoById(a.group_id).name,
                Status_str: (this.applyInvalidStatus.filter((b: any) => b.value === Number(a.apply_status))[0] || {}).label || '',
                house: a.house_name,
                username: a.apply_user_name,
                customerName: a.customer_name,
                customerTel: a.customer_tel,
                ctime: this.__utils.index.formatDate(new Date(a.apply_time), 'YYYY-MM-DD HH:mm:ss'),
                Status: Number(a.apply_status),
                CustomerId: a.customer_id,
                joinTime: this.__utils.index.formatDate(new Date(a.ctime * 1000), 'YYYY-MM-DD HH:mm:ss')
            }
        })
        this.total = data.total
    }

    // 筛选
    filterChange (data: any) {
        if ('date' in data) {
            data['apply_time'] = {
                query_start_time: data.date[0],
                query_end_time: data.date[1]
            }
            delete data.date
        }

        if ('ctime' in data) {
            data['ctime'] = {
                query_start_time: data.ctime[0],
                query_end_time: data.ctime[1]
            }
        }

        this.page = 1
        this.filter = data

        this.getData()
        this.getAllTotal()
    }

    toDetail (obj: any, bool: boolean) {
        obj = { name: '/customer/detail', params: { id: obj.CustomerId } }
        if (bool) {
            let { href } = this.$router.resolve(obj)
            window.open(href, '_blank')
        } else {
            this.$router.push(obj)
        }
    }

    pageChange (page:number) {
        this.page = page
        this.getData()
    }
}
