import { Component, Vue } from 'vue-property-decorator'
import noData from '@/components/NoData/index.vue'

@Component({
    components: {
        noData
    }
})
export default class CustomerComGuiDePage extends Vue {
    dialogCommetVisible = false
    commet = ''
    commetId = ''
    dataObj = {}
    guideStatus = [
        {
            value: 1,
            label: '未带看'
        }, {
            value: 2,
            label: '已带看'
        }
    ]
    // 带看弹窗
    dialogFormGuideVisible = false
    guideForm: any = {
        house_name: '',
        house_id: '',
        action_time: '',
        pre_work: '',
        customer_info: ''
    }
    editCurrent = {}
    formLabelWidth = '120px'
    page = 1
    pageSize = 20

    mounted () {
        this.getData()
    }

    // 分页改变
    pageChange (page: number) {
        this.page = page
        this.getData()
    }

    // 带看状态
    async guideStatusChange (obj: any) {
        await this.__apis.customer.saveDkPre({
            id: obj.Id,
            house_name: obj.HouseName,
            action_time: obj.ActionTime,
            pre_work: obj.PreWork,
            customer_info: obj.CustomerInfo,
            status: obj.Status
        })

        this.$notify({
            title: '成功',
            message: '修改成功！',
            type: 'success'
        })
        this.getData()
    }

    // 评论弹窗
    commetDialog (obj: any) {
        this.commetId = obj.Id
        this.dialogCommetVisible = true
    }

    // 保存评论
    async addCommet () {
        await this.__apis.customer.saveDkCjComment({
            record_id: this.commetId,
            content: this.commet,
            type: 1
        })

        this.dialogCommetVisible = false
        this.$notify({
            title: '成功',
            message: '评论成功！',
            type: 'success'
        })
        this.commet = ''
        this.getData()
    }

    // 编辑弹窗
    editDialog (obj: any) {
        this.editCurrent = obj
        this.guideForm = {
            customer_id: obj.CustomerId,
            customer_name: obj.CustomerName,
            house_name: obj.HouseName,
            action_time: obj.ActionTime,
            pre_work: obj.PreWork,
            customer_info: obj.CustomerInfo,
            id: obj.Id,
            user_name: obj.UserName
        }
        this.dialogFormGuideVisible = true
    }

    // 编辑保存
    async saveForm () {
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)'
        })

        try {
            let data = this.guideForm
            await this.__apis.customer.saveDkPre(data)

            this.$notify({
                title: '成功',
                message: '保存成功！',
                type: 'success'
            })

            const form = this.$refs['guideForm'] as any
            form.resetFields()

            this.dialogFormGuideVisible = false
            this.getData()
            loading.close()
        } catch (e) {
            loading.close()
            throw e
        }
    }

    // 楼盘匹配
    async querySearchAsync (queryString: string, cb: any) {
        if (!queryString || queryString.length === 0) return

        const res = await this.__apis.utils.searchHouse({ term: queryString })
        cb(res.data)
    }

    handleHouseSelect (val: any) {
        this.guideForm.house_name = val.label
        this.guideForm.house_id = val.id
    }

    // fetch
    async getData () {
        const res = await this.__apis.customer.getDkPreList({
            customer_id: this.$route.params.id,
            page: this.page,
            pagesize: this.pageSize
        })

        this.dataObj = res.data
    }

    async delItem (obj: any) {
        try {
            await this.$confirm('确认删除？')

            await this.__apis.customer.deleteDkCj({
                type: 'dk_pre',
                id: obj.Id
            })

            this.$notify({
                title: '成功',
                message: '删除成功！',
                type: 'success'
            })
            this.getData()
        } catch (e) {
            console.log(e)
        }
    }
}
