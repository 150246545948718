import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import selectComponent from '@/components/filters/select/index.vue'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'
import importDialogComponent from '../importDialog/index.vue'
import { storageName } from '@/localStorage'
import { apiConfig } from '@/api/config'

@Component({
    components: {
        FilterComponent,
        selectComponent,
        FilterBusInputSelectComponent,
        FilterBusSelectComponent,
        importDialogComponent
    },
    inject: ['parentThis']
})
export default class IndexPage extends Vue {
    @Prop({
        type: Object,
        default () {
            return {
                page: 1,
                pageSize: 10,
                datePicker: ''
            }
        }
    })
    data !: {
        page: number
        pageSize: number
        datePicker: string
    }
    @Prop({
        type: Boolean,
        default: false
    })
    refresh !: boolean

    dataObj: any = { list: [], total: 0 }
    page = this.data.page
    pageSize = this.data.pageSize
    datePicker = this.data.datePicker
    filter: any = {}
    uploadUrl = apiConfig.apiUrl
    uploadList = []
    importObj = {
        action_name: 'importSalaryNew',
        action_time: '',
        type: -1
    }
    importDialog:any = {
        visible: false,
        gloading: null
    }

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }
    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }

    @Watch('data.datePicker')
    watchDataDatePicker (newVal: any) {
        this.datePicker = newVal
    }

    @Watch('data.page')
    watchDataPage () {
        this.page = this.data.page
    }

    @Watch('datePicker')
    watchDatePicker (newVal: any) {
        this.page = 1
        this.getGzList()
        this.$emit('dateChange', newVal)
    }

    @Watch('page')
    watchPage () {
        this.getGzList()
        this.$emit('pageChange', this.page)
    }

    @Watch('refresh')
    watchRefresh () {
        this.getGzList()
        this.$emit('refresh', false)
    }

    mounted () {
        this.getGzList()
    }

    pushToWalletTips (type: number) {
        const text = type === 1 ? '工资' : '提成'
        this.$confirm(
            `请再次确认是否生成钱包${text}数据，生成后不可修改`,
            `是否生成钱包${text}`,
            {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                iconClass: 'el-icon-question icon_gz_save_question',
                customClass: 'my_confirm my_confirm_gz_save',
                center: true
            }).then(() => {
            this.pushToWallet(type)
        }).catch(() => {})
    }

    async pushToWallet (type: number) {
        const loading = this.$loading({
            lock: true,
            text: '正在生成...',
            spinner: 'el-icon-loading',
            customClass: 'my_loading_box',
            background: 'rgba(0, 0, 0, 0.7)'
        })
        try {
            await this.__apis.gzManage.pushToWallet(type)
            this.$notify({
                title: '成功',
                message: '生成成功！',
                type: 'success'
            })
        } catch (e) {
            this.$notify({
                title: '失败',
                message: '生成失败！',
                type: 'error'
            })
            throw e
        } finally {
            loading.close()
        }
    }

    importDialogVisible (val:boolean) {
        this.importDialog.visible = val
    }

    importSubmit (obj:any) {
        this.importObj = {
            action_name: 'importSalaryNew',
            ...obj
        }
        let importFile = document.querySelector('.import_box .infos') as HTMLElement
        importFile.click()
    }

    pageChange (page: number) {
        this.page = page
    }

    async getGzList () {
        let filter = this.filter || {}
        const { data } = await this.__apis.gzManage.getBasicSalaryRecord({
            page: this.page,
            pagesize: this.pageSize,
            ...filter,
            time_start: filter.date || this.datePicker,
            time_end: filter.date || this.datePicker
        })

        data.list.map(v => {
            v.ActionTime = new Date(v.ActionTime).format('yyyy-MM')
        })
        data.statistic_res = data.statistic_res || this.dataObj.statistic_res
        for (let key in data.statistic_res) {
            let k = this.isFirstUpper(key)
            data.statistic_res[k] = data.statistic_res[key]
        }
        this.dataObj = data
        this.dataObj.show = data
        if (this.dataObj.statistic_res) {
            this.dataObj.list.unshift({
                ActionTime: '总计',
                BonusSend: data.statistic_res.bonus_has_send_money,
                BonusRealSend: data.statistic_res.bonus_real_send_money,
                OtherDeduct: data.statistic_res.other_deduct_money,
                ...data.statistic_res
            })
        }
    }

    isFirstUpper (name: string) {
        if (name.indexOf('_') === -1) return name

        const names = name.split('_')
        let res = names.map(v => {
            v = v.replace(v[0], v[0].toUpperCase())
            return v
        })
        return res.join('')
    }

    // 筛选
    filterChange (data: any) {
        this.page = 1
        this.filter = data
        this.getGzList()
    }

    queryGroupSearch (queryString: string, cb: any) {
        this.__utils.index.searchGroup(queryString, cb)
    }

    editList () {
        this.$emit('edit', true)
        this.$emit('filter', this.filter)
    }

    // 导出
    exportData (type: string) {
        if (type !== 'export') return

        let data = {
            action_name: 'exportSalaryList',
            ...this.filter,
            time_start: this.filter.date || this.datePicker,
            time_end: this.filter.date || this.datePicker
        }

        this.__utils.index.downloadFile(data)
    }

    // 上传之前
    beforeUpload () {
        this.importDialog.gloading = this.$loading({
            lock: true,
            text: '正在导入...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })
    }

    // 文件上传成功
    successFile (res: any, file: any, list: any) {
        this.importDialog.gloading.close()
        if (typeof res === 'string') {
            this.$notify({
                title: '导入失败',
                message: `${res}`,
                type: 'error'
            })
            return
        }
        if (res.errCode !== 0) {
            file.status = 'error'
            list.pop()
            this.$notify({
                title: '失败',
                message: '导入失败！',
                type: 'error'
            })
        } else {
            this.$notify({
                title: '成功',
                message: '导入成功！',
                type: 'success'
            })
            this.getGzList()
        }
    }
}
