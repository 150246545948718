import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
    components: {}
})
export default class JiXiaoAddScoreComponent extends Vue {
    @Prop({
        default: false
    })
    visible!:boolean

    @Prop({
        default: () => {
            return {
                user_name: '',
                user_id: 0
            }
        }
    })
    data!: {
        user_name: string
        user_id: number
    }

    form: {
        [index: string]: string
        score: string
        remark: string
    } = {
        score: '',
        remark: ''
    }

    rules = {
        score: [
            { required: true, message: '请输入积分', trigger: 'change' }
        ]
    }

    limitMoneyInput (e: string, key: string, hasMinus = false) {
        let minus = e[0] === '-' && hasMinus ? '-' : ''

        this.form[key] = minus + this.__utils.index.limitMoneyByInput(e, 1000000)
    }

    close () {
        this.$emit('update:visible', false)
        const form = this.$refs['form'] as any
        form.resetFields()
    }

    checkValid () {
        const form = this.$refs['form'] as any
        form.validate((valid: any) => {
            if (valid) {
                this.save()
            } else {
                return false
            }
        })
    }

    async save () {
        let loading:any

        if (this.__utils.index.limitRepeatClick()) return

        try {
            loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            })

            await this.__apis.channelManage.saveScoreRecord({
                user_id: this.data.user_id,
                score: +this.form.score,
                remarks: this.form.remark
            })

            loading.close()
            this.close()
            this.$emit('save')
            this.$notify({
                title: '成功',
                message: '保存成功！',
                type: 'success'
            })
        } catch (e) {
            loading && loading.close()
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }
}
