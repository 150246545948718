import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SubNav extends Vue {
    @Prop({
        type: Object,
        default () {
            return {}
        }
    })
    data !: any

    dataObj = {}

    mounted () {
        this.dataObj = this.data
    }
}
