import { Component, Vue } from 'vue-property-decorator'

@Component
export default class KnowledgeAddCateOkPage extends Vue {
    data = [{
        label: '简介',
        children: [{
            label: '二级 1-1',
            children: [{
                label: '三级 1-1-1'
            }]
        }]
    }, {
        label: '房产术语',
        children: [{
            label: '二级 2-1',
            children: [{
                label: '三级 2-1-1'
            }]
        }, {
            label: '二级 2-2',
            children: [{
                label: '三级 2-2-1'
            }]
        }]
    }, {
        label: '房产名词',
        children: [{
            label: '二级 3-1',
            children: [{
                label: '三级 3-1-1'
            }]
        }, {
            label: '二级 3-2',
            children: [{
                label: '三级 3-2-1'
            }]
        }]
    }]
    dataObj: any = {}
    defaultProps = {
        children: 'children',
        label: 'label'
    }
    dataDirObj = {}
    initDirArr = []
    dataResList: any = []

    get
    id () {
        return this.$route.query.id as string
    }

    mounted () {
        this.getData()
    }

    handleNodeClick (data: any) {
        if (!data.id) return

        this.$router.push({
            name: '/knowledge/doc',
            query: {
                id: this.id,
                cate_id: data.id
            }
        })
    }

    routePush (obj: any) {
        this.$router.push(obj)
    }

    // 新建文档
    addDoc () {
        this.$router.push({
            name: '/knowledge/editor',
            query: {
                id: this.id,
                add: true
            }
        } as any)
    }

    async getData () {
        const res = await this.__apis.knowledge.getKnowledgeLibDetail(this.id)

        let datas = res.data
        datas.DirSort = datas.DirSort ? JSON.parse(datas.DirSort) : []
        this.dataObj = datas

        await this.getDirList()
        this.initTreeData()
    }

    // 获取知识库目录
    async getDirList () {
        const res = await this.__apis.knowledge.getKnowledgeLibDirList(this.id)

        let datas: any = res.data

        let obj: any = {}
        let arrs = []
        for (let k in datas) {
            datas[k].tree = true
            datas[k].label = datas[k].Name
            datas[k].id = datas[k].Id
            datas[k].children = []
            obj[datas[k].Pid] = []
        }
        for (let k in datas) {
            obj[datas[k].Pid].push(datas[k])
        }

        for (let k in obj) {
            if (k !== '0') {
                datas[k].children = obj[k]
            } else {
                arrs = obj[k]
            }
        }

        this.initDirArr = arrs
        this.dataDirObj = res.data
    }

    // 初始化目录数据
    initTreeData () {
        this.dataResList = [
            {
                id: 0,
                label: this.dataObj.Name,
                children: this.initDirArr
            }
        ]
    }
}
