import { Vue, Component } from 'vue-property-decorator'
import { Table } from '@/types'
import FilterComponent from '@/components/filters/filter/index.vue'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import { routerBaseUrl } from '@/router'
import { __utils } from '@/util'

interface TableItem {
    id: number,
    department: string,
    group: string,
    fxs: string,
    plan: string,
    startTime: string,
    remain: string,
}

interface Filter {
    department: number
    group: number
    fxs: number
}

@Component({
    components: {
        SelectComponent,
        FilterComponent,
        FilterBusinessSelect
    }
})
export default class IndexPage extends Vue {
    filter: Filter = {} as Filter

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0
    }

    created () {
        this.getTableData()
    }

    async getTableData () {
        const { data } = await this.__apis.grow.getEmployeeGrowingList({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            dep_id: this.filter.department,
            group_id: this.filter.group,
            employee_id: this.filter.fxs
        })

        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.employee_id,
                department: __utils.index.getComDepInfoById(a.com_dep).name,
                group: __utils.index.getGroupInfoById(a.group_id).name,
                fxs: a.user_name,
                plan: a.plan_name,
                startTime: a.start_time ? __utils.index.formatDate(new Date(a.start_time), 'YYYY-MM-DD') : '',
                remain: a.start_time ? Math.max(0, Math.floor((a.end_time - Date.now()) / 86400000) + 1) + '天' : ''
            }
        })
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        this.getTableData()
    }

    gotoDetail (id: number) {
        open(`${routerBaseUrl}/grow-manage/file-detail/${id}`)
    }

    searchFxs (text: string, cb: any) {
        this.__utils.index.searchEmployee(text, cb)
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }
}
