import { Component, Vue } from 'vue-property-decorator'

interface Item {
    id: number,
    msg: string
}

@Component
export default class TopBarWaringMsgListComponent extends Vue {
    list: Item[] = []

    async close (item: Item) {
        await this.__apis.home.warningMsgClose(item.id)
        this.getData()
    }

    async getData () {
        const { data } = await this.__apis.home.warningMsgList()
        this.list = data.map(a => {
            return {
                id: a.id,
                msg: a.msg
            }
        })
    }

    timer: number | null = null
    createTask () {
        if (this.timer) clearInterval(this.timer)
        this.timer = setInterval(() => {
            this.getData()
        }, 30 * 60 * 1000)
    }

    async created () {
        await this.getData()
        this.createTask()
        this.$on('hook:destroyed', () => {
            if (this.timer) clearInterval(this.timer)
        })
    }
}
