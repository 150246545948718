import { BaseRes, ListReq, ListRes, php } from '@/api/index'
import { getMyBonusListRes, getMySalaryBonusOverViewRes, getMySalaryBonusOverViewResStatisticRes } from '@/api/user'
import { bonusDetail } from '@/api/financial'

interface getSalaryBonusOverViewReq extends ListReq {
    time_start: string
    time_end: string
}

interface saveBasicSalaryRecordReq {
    id: number
    user_id: number
    action_time: string
    must_money: number
    shebao_money: number
    gjj_money: number
    salary_tax: number		// (工资税)
    bonus_tax: number				// （提成税）
    bonus_send: number			// 已发提成
    real_salary_money: number
    real_wallet_salary_money: number
    salary_remark: string
    subsidy_money: string 		// （补贴金额）
    subsidy_remark: string
    expense_money: string 		// （报销金额）
    expense_send_money: string
    expense_not_send_money: string
    expense_remark: string
    reward_money: string	// （ 奖金）
    reward_remark: string
    other_deduct: string // 其他扣减
    bonus_real_send?: string // 实际发放提成
}

interface getUserBonusListReq extends ListReq {
    employee_id: number
    time_start: string
    time_end: string
    cj_time_start: string
    cj_time_end: string
    rate: string
    house_name: string
    sale_sid: number
}

interface getBasicSalaryRecordRes extends ListRes<{
    Id: number
    UserId: number
    Dep: number
    CommonDep: number
    Gro: number
    Username: string
    MustMoney: null | number
    ShebaoMoney: null | number
    GjjMoney: null | number
    SalaryTax: null | number
    BonusTax: null | number
    BonusSend: null | number
    RealSalaryMoney: null | number
    RealWalletSalaryMoney: null | number
    SalaryRemark: string
    SubsidyMoney: null | number
    SubsidyRemark: string
    ExpenseMoney: null | number
    ExpenseSendMoney: null | number
    ExpenseNotSendMoney: null | number
    ExpenseRemark: string
    RewardMoney: null | number
    RewardRemark: string
    ActionTime: string
    Ctime: string
    Mtime: string
    CommonDepName: string
    DepName: string
    GroupName: string
}>{
    statistic_res: {
        [index: string]: any
    } & getMySalaryBonusOverViewResStatisticRes
}

interface getSalaryBonusOverViewRes extends ListRes<getMySalaryBonusOverViewResStatisticRes & {
    key: number
    doc_count: number
    group_name: string
    user_id: number
    common_dep_name: string
    dep_name: string
    username: string
}>{
    statistic_res: getMySalaryBonusOverViewResStatisticRes
}

interface getBasicSaleBonusRecordRes extends ListRes<bonusDetail>{
    statistic_res: {
        SendMoney: number
    }
}

interface getEmployeeRecordReq extends ListReq {
    with_user?: number
}

interface saveEmployeeRecordReq {
    id?: number
    status: number
}

export default class ApiGzManage {
    // 获取概览列表
    getSalaryBonusOverView (data: getSalaryBonusOverViewReq): BaseRes<getSalaryBonusOverViewRes> {
        return php.post('', {
            action_name: 'getSalaryBonusOverView',
            ...data
        })
    }

    // 获取提成情况列表
    getBonusOverView (data: getSalaryBonusOverViewReq): BaseRes<getMySalaryBonusOverViewRes> {
        return php.post('', {
            action_name: 'getBonusOverView',
            ...data
        })
    }

    // 获取提成发放记录列表
    getBasicSaleBonusRecord (data: getSalaryBonusOverViewReq): BaseRes<getBasicSaleBonusRecordRes> {
        return php.post('', {
            action_name: 'getBasicSaleBonusRecord',
            ...data
        })
    }

    // 获取工资管理列表
    getBasicSalaryRecord (data: getSalaryBonusOverViewReq): BaseRes<getBasicSalaryRecordRes> {
        return php.post('', {
            action_name: 'getBasicSalaryRecord',
            ...data
        })
    }

    // 编辑工资管理列表
    saveBasicSalaryRecord (data: saveBasicSalaryRecordReq) {
        return php.post('', {
            action_name: 'saveBasicSalaryRecord',
            ...data
        })
    }

    // 获取提成记录
    getUserBonusList (data: getUserBonusListReq): BaseRes<getMyBonusListRes> {
        return php.post('', {
            action_name: 'getUserBonusList',
            ...data
        })
    }

    // 工资设置，员工列表
    getEmployeeRecord (data: getEmployeeRecordReq) {
        return php.post('', {
            action_name: 'getEmployeeRecord',
            ...data
        })
    }

    // 保存工资记录
    saveEmployeeRecord (data: saveEmployeeRecordReq) {
        return php.post('', {
            action_name: 'saveEmployeeRecord',
            ...data
        })
    }

    // 一键生成钱包(工资|提成)
    pushToWallet (type: number) {
        return php.post('', {
            action_name: 'pushToWallet',
            type
        })
    }
}
