import { Component, Prop, Vue } from 'vue-property-decorator'

export interface editItem {
    id?: number
    dep?: number
    name: string
    tel: string
}

@Component
export default class FriendManageAddFriend extends Vue {
    @Prop({
        type: Boolean,
        default () {
            return false
        }
    })
    show !: boolean

    @Prop({
        type: Object,
        default () {
            return {
                id: undefined,
                dep: undefined,
                name: '',
                tel: ''
            }
        }
    })
    item !: editItem

    form: editItem = {} as editItem

    get
    getTitle () {
        return this.form.id ? '编辑对接人' : '新建对接人'
    }

    get
    deps () {
        return this.__store.state.user.infoPos.dep_arr
    }

    get
    btnAddDisabled () {
        return !(this.form.dep && this.form.name.trim() && this.form.tel.trim())
    }

    created () {
        this.form = this.__utils.index.copyObj(this.item)
    }

    async save () {
        if (this.__utils.index.limitRepeatClick()) return

        try {
            await this.__apis.channelManage.duiJieUserAction({
                action_name: this.form.id ? 'edit' : 'add',
                username: this.form.name,
                tel: this.form.tel,
                id: this.form.id,
                dep: this.form.dep
            })

            this.$message.success('操作成功')
            this.$emit('enter')
            this.cancel()
        } catch (e) {
            this.$message.error(`操作失败 ${e}`)
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    cancel () {
        // 修改父级 show 的值
        this.$emit('update:show', false)
    }
}
