import { Component, Mixins, Watch } from 'vue-property-decorator'
import ActivityTaskComponent from '@/components/activity/task/index.vue'
import RankComponent from '@/components/activity/rank/index.vue'
import questionEditComponent from '@/components/questionBank/edit/index.vue'
import NoticeComponent from '@/components/index/notice/index.vue'
import NewsComponent from '@/components/index/news/index.vue'
import { SuccessDetailCommitTypes } from '@/store/successDetail'
import { __role } from '@/role'
import { getMyAllAccountRes } from '@/api/user'
import { storageName } from '@/localStorage'
import { UserCommitTypes } from '@/store/user'
import { routerBaseUrl } from '@/router'
import login from 'qm-plugin-admin-login'
import Permissions from '@/role/permissions'
import qmPermission from 'qm-plugin-admin-permission'
import SummaryPopup from '@/components/summaryPopup/index.vue'
import seeQuestionComponent from '@/components/questionBank/see/index.vue'
import RecommendPopup from '@/components/recommendPopup/index.vue'
import IndexPageVisibleMinXin from './minxin'
import warningMsgComponent from './warningMsg/index.vue'
import store, { Module } from '@/store'
import { ReceivedMessageState, StoreReceivedMessageState } from '@/store/modules/message'
import { MessageType } from '@/types/message/msg_type'
import { ConnectionState, StoreConnectionState } from '@/store/modules/connection'

const msgStore = store.module<StoreReceivedMessageState>(Module.MESSAGE)
const conStore = store.module<StoreConnectionState>(Module.CONNECTION)

type menu = {
    route?: string
    routes?: menu[]
    text?: string
    authKey?: Permissions
    all?: boolean
    jump?: boolean
    select?: number
    key?: string
    sub?: boolean
}

const MAX_MENU_LEN = 8

@Component({
    components: {
        ActivityTaskComponent,
        NoticeComponent,
        NewsComponent,
        RankComponent,
        SummaryPopup,
        questionEditComponent,
        seeQuestionComponent,
        RecommendPopup,
        warningMsgComponent
    }
})
export default class IndexPage extends Mixins(IndexPageVisibleMinXin) {
    private showNewsPopup: boolean = false
    private newCount: number = 0
    get
    realNewCount () {
        return this.newCount > 100 ? '99+' : this.newCount
    }
    get
    newTz () {
        return this.__store.state.user.tz
    }

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }
    get
    newsSrc () {
        return 'https://ssl.malmam.com/xiangju-static/dfs/21/6999de42700950/new.png'
    }
    get
    avatarSrc () {
        if (this.__store.state.user.userInfo.avatar === 'http://lxfm-s.malmam.com/uploads/image/140928/2748175_5_03f58d295d_o.png') {
            return 'https://ssl.malmam.com/xiangju-static/dfs/18/59dfb662c08c75/default.png'
        } else {
            return this.__store.state.user.userInfo.avatar
        }
    }

    get
    isNotFoundPage () {
        return this.$route.name === '/404'
    }

    logout: {
        show: boolean
        account: getMyAllAccountRes[]
    } = {
        show: false,
        account: []
    }

    menus: menu[] = [
        {
            route: '/home',
            text: '概况',
            authKey: __role.permissions.HomePage
        }, {
            route: '/customer',
            text: '客户',
            authKey: __role.permissions.ShowCustomerPage
        }, {
            route: '/saleman',
            text: '销售',
            authKey: __role.permissions.ShowSaleManPage
        }, {
            route: '/financial',
            text: '财务',
            authKey: __role.permissions.FinancialManagePage
        }, {
            route: '/channelManage',
            text: '渠道',
            authKey: __role.permissions.ShowChannelPage
        }, {
            route: ({
                production: 'https://admin.91xiangju.com/marketadmin/',
                development: 'http://192.168.1.66/marketadmin-dev/'
            } as any)[process.env.NODE_ENV],
            text: '市场推广',
            jump: true,
            authKey: __role.permissions.HouseAdminPage
        }, {
            route: ({
                production: 'https://admin.91xiangju.com/houseadmin/',
                development: 'http://192.168.1.66/houseadmin-dev/'
            } as any)[process.env.NODE_ENV],
            text: '房源管理',
            jump: true,
            authKey: __role.permissions.OldHouseAdmin
        }, {
            route: '/phone-record',
            text: '通话记录',
            authKey: __role.permissions.PhoneRecodePage
        }, {
            route: '/qm-wallet',
            text: '奇妙钱包',
            authKey: __role.permissions.QmWalletPage
        }, {
            route: '/report-manage',
            text: '报告管理',
            authKey: __role.permissions.ReportManagePage
        }, {
            route: '/system',
            text: '系统管理',
            authKey: __role.permissions.ShowSystemPage
        }, {
            route: ({
                production: 'https://admin.91xiangju.com/renshiadmin/',
                development: 'http://192.168.1.66/renshiadmin-dev/'
            } as any)[process.env.NODE_ENV],
            text: '人事管理',
            jump: true,
            authKey: __role.permissions.PersonManagePage,
            all: false
        },
        {
            route: '/grow-manage',
            text: '成长管理',
            key: __role.KEYS.grow_file,
            all: false
        },
        {
            route: ({
                production: 'https://admin.91xiangju.com/frontend-platform/auth',
                development: 'http://192.168.1.66/frontend_platform_dev/auth'
            } as any)[process.env.NODE_ENV],
            text: '权限管理',
            jump: true,
            authKey: __role.permissions.ShowPermissionMenu
        }, {
            route: '/questionBank',
            text: '题库管理',
            authKey: __role.permissions.ShowQuestionBankPage
        }, {
            route: '/market',
            text: '市场管理',
            authKey: __role.permissions.ShowMarketPage
        }, {
            route: '/friendsCircle',
            text: '朋友圈'
        }
    ]

    @Watch('$route')
    routeChange () {
        for (let a of this.menus) {
            if (a.routes) {
                a.select = a.routes.findIndex((a) => {
                    return this.$route.path.includes(a.route || '')
                })
            }
        }
    }

    async created () {
        this.__role.filter(this.menus)
        qmPermission.filter(this.menus)

        this.menusOverInit()

        for (let a of this.menus) {
            if (a.routes) {
                a.select = a.routes.findIndex((a) => {
                    return this.$route.path.includes(a.route || '')
                })
            }
        }

        const name = this.$router.resolve(this.$route.path).route.name

        if (name && name.includes('404')) {
            if (this.menus[0]) {
                this.$router.replace(String(this.menus[0].route)).catch(() => {})
            }
        }

        // 获取账号列表
        this.getAllAccount()

        if (this.isNotFoundPage) return

        // this.checkHasNoReadMsg()

        // 获取所有渠道
        const { data: channel } = await this.__apis.utils.getAllSalePartnerList()
        this.__store.commit(SuccessDetailCommitTypes.setAllChannel, channel.list)
    }

    overMenus: menu[] = []

    menusOverInit () {
        const menus = this.menus
        if (menus.length > MAX_MENU_LEN) {
            this.overMenus = menus.slice(MAX_MENU_LEN)
            this.menus = [
                ...menus.slice(0, MAX_MENU_LEN),
                {
                    sub: true,
                    routes: this.overMenus,
                    select: -1
                }
            ]
        }
    }

    // 二级导航跳转
    async changeItemSelect (item:menu, index: number) {
        this.menus[MAX_MENU_LEN].select = index
        if (item.jump) {
            return this.toJump(item)
        }
        if (item.route) {
            await this.$router.replace(item.route)
        }
    }

    // 获取账号列表
    async getAllAccount () {
        const { data } = await this.__apis.user.getMyAllAccount()
        this.logout.account = data
    }

    // 个人中心通知是否已读
    async checkHasNoReadMsg () {
        const res = await this.__apis.home.checkHasNoReadMsg()
        let datas = res.data
        this.__localStorage.setLocalStorage(storageName.NEWTZ, datas.has_no_read)
        this.__store.commit(UserCommitTypes.insertInfo, { key: 'tz', value: datas.has_no_read })
    }

    // 退出登录
    exit () {
        // await this.__apis.user.logout()
        this.__localStorage.removeLocalStorage(storageName.TOKEN)
        login.logout()
    }

    // 修改当前登录账号
    async changeAccount (item: getMyAllAccountRes) {
        if (item.id === this.userInfo.user_id) return

        const t = item.ac_token
        const tTime = Number(t.substring(34, 44)) * 1000
        const now = new Date().getTime()
        if (tTime < now) {
            await this.getAllAccount()
            item = this.logout.account.find(a => a.id === item.id) as getMyAllAccountRes
        }

        this.__localStorage.setLocalStorage(storageName.TOKEN, item.ac_token)
        location.reload()
    }
    // 切换消息弹窗状态
    toggleNews () {
        this.showNewsPopup = !this.showNewsPopup
        if (!this.showNewsPopup) {

        } else {
            this.newCount = 0
        }
    }
    async checkNewMsg () {
        const { data } = await this.__apis.home.getNewMsg({})
        if (data) {
            this.newCount = data.total_unread
        }
    }

    // 刷新消息数字
    updataNewsCount (e: any) {
        this.newCount = e
    }
    onVisibilityChange () {
        var hiddenProperty:any = 'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null
        if (!(document as any)[hiddenProperty]) {

        } else {

        }
    }
    private unsub1!: Function
    private unsub2!: Function
    mounted () {
        var hiddenProperty:any = 'hidden' in document ? 'hidden' : 'webkitHidden' in document ? 'webkitHidden' : 'mozHidden' in document ? 'mozHidden' : null
        var visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange')

        const doc = document as any
        doc['events'] = doc['events'] || {}

        if (!doc['events'][visibilityChangeEvent]) {
            doc['events'][visibilityChangeEvent] = true
            document.removeEventListener(visibilityChangeEvent, this.onVisibilityChange)
            document.addEventListener(visibilityChangeEvent, this.onVisibilityChange)
        }

        if (conStore.state.connectionState === ConnectionState.CONNECTED) {
            this.checkNewMsg()
        }

        this.unsub1 = msgStore.subscribe((mutation) => {
            const payload = mutation.payload as ReceivedMessageState
            if (payload.LastMessage.type === MessageType.MSG_CENTER_NEW_MESSAGE) {
                // const msg = payload.LastMessage.data as MessageMsgCenterNewMessage
                // console.log('new msg center message', msg.id)
                this.checkNewMsg()
            }
        })
        this.unsub2 = conStore.subscribe((mutation) => {
            const connectionState = mutation.payload as ConnectionState
            if (connectionState === ConnectionState.CONNECTED) {
                this.checkNewMsg()
            }
        })
    }
    beforeDestroy () {
        this.unsub1()
        this.unsub2()
    }
    gotoUserCenter () {
        this.__localStorage.setLocalStorage(storageName.NEWUSER, true)
        window.open(routerBaseUrl + '/usercenter', '_blank')
    }

    toJump (item: any) {
        open(item.route, '_blank')
    }
}
