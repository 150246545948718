import { Component, Vue } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import { Table } from '@/types'
import { ChongZhiWalletMoneyCashOutExamineStatusList } from '@/util/const'
import ReasonComponent from '@/components/wallet/reason/index.vue'

interface TableItem {
    id: number
    employeeId: string
    department: string,
    fxs: string,
    money: string,
    remark: string
    rejectReason: string,
    status: string,
    ctime: string,
    sendTime: string
}

interface Filter {
    [index: string]: string
    fxs: string
}

@Component({
    components: {
        FilterComponent,
        SelectComponent,
        ReasonComponent
    }
})
export default class IndexPage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 50
    }

    filter: Filter = {
        fxs: ''
    }

    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        this.getTableData()
    }

    async pass (item: TableItem) {
        try {
            await this.$confirm(`你确定通过 <span style="color: #408AF0;">${item.department} ${item.fxs}</span> 的提现申请吗`, '提现申请审核', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })

            await this.__apis.chongzhiWallet.cashOutExamine({
                cashout_id: item.id,
                status: 1
            })

            this.$message({
                type: 'success',
                message: '已通过'
            })
            this.getTableData()
        } catch (e) {
            console.log(e)
        }
    }

    reasonComponent = {
        show: false,
        id: 0
    }

    showReasonComponent (item: TableItem) {
        this.reasonComponent.show = true
        this.reasonComponent.id = item.id
    }

    async reject (reason: string) {
        await this.__apis.chongzhiWallet.cashOutExamine({
            cashout_id: this.reasonComponent.id,
            status: 2,
            reject_reason: reason
        })

        this.$message({
            type: 'success',
            message: '已驳回'
        })
        this.getTableData()
    }

    searchFxs (text: string, cb: any) {
        this.__utils.index.searchEmployee(text, cb)
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }

    async getTableData () {
        const { data } = await this.__apis.chongzhiWallet.cashoutList({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            employee_id: this.filter.fxs || (this.$route.query.employee_id ? this.$route.query.employee_id as string : undefined)
        })

        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.id,
                employeeId: a.employee_id.toString(),
                department: this.__utils.index.getComDepInfoById(a.employee_common_dep).name,
                fxs: a.employee_user_name,
                remark: a.remark,
                money: this.__utils.index.formatMoney(a.money),
                rejectReason: a.reject_reason,
                status: this.__utils.index.getLabelByValue(ChongZhiWalletMoneyCashOutExamineStatusList, a.status),
                ctime: this.__utils.dayjs(a.ctime).format('YYYY-MM-DD HH:mm'),
                sendTime: a.send_time ? this.__utils.dayjs(a.send_time).format('YYYY-MM-DD HH:mm') : ''
            }
        })
    }

    created () {
        this.getTableData()
    }
}
