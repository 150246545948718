import { Component, Vue } from 'vue-property-decorator'
import noData from '@/components/NoData/index.vue'
import { apiConfig } from '@/api/config'
import { storageName } from '@/localStorage'

@Component({
    components: {
        noData
    }
})
export default class CustomerChengJiaoTotalPage extends Vue {
    dataObj: any = {}
    page = 1
    pageSize = 20
    dialogCommetVisible = false
    commet = ''
    commetId = ''
    // 成交总结 || 看房总结
    dialogFormVisible = false
    titleDialogText = '成交总结'
    form: any = {
        customer_name: '',
        customer_tel: '',
        action_time: '',
        region: '',
        house_name: '',
        house_id: '',
        channel_name: '',
        channel_username: '',
        channel_tel: '',
        zygw_name: '',
        zygw_tel: '',
        imgs: '',
        customer_info: '',
        result: '',
        ext_data: [
            {
                house_name: '',
                channel_name: '',
                channel_username: '',
                channel_tel: '',
                zygw_name: '',
                zygw_tel: ''
            }
        ]
    }
    editCurrent = {}
    formLabelWidth = '120px'
    // 图片上传
    dialogImageUrl = apiConfig.apiUrl
    dialogImagePrev = ''
    dialogVisible = false
    fileList: any[] = []

    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }

    mounted () {
        this.getData()
    }

    async getData () {
        const res = await this.__apis.customer.getCjResList({
            customer_id: this.$route.params.id,
            page: this.page,
            pagesize: this.pageSize
        })

        res.data.list.map((v: any) => {
            if (v.ExtData === '[object Object],[object Object]') {
                v.ExtData = []
            }
            v.ExtData = v.ExtData ? JSON.parse(v.ExtData) : []
            v.ExtData2 = this.__utils.index.copyObj(v.ExtData)
        })
        this.dataObj = res.data
        this.dataObj.show = true
    }

    // 图片上传
    handleRemove (file: any, fileList: any) {
        this.fileList = fileList
    }

    handlePictureCardPreview (file: any) {
        this.dialogImagePrev = file.url
        this.dialogVisible = true
    }

    handlePictureSuccess (response: any, file: any) {
        this.fileList.push({
            url: response.data.url,
            data: file,
            name: file.name
        })
    }

    handlePictureError () {
        this.$notify({
            title: '错误',
            message: '上传失败',
            type: 'error'
        })
    }

    // 分页改变
    pageChange (page: number) {
        this.page = page
        this.getData()
    }

    // 评论弹窗
    commetDialog (obj: any) {
        this.commetId = obj.Id
        this.dialogCommetVisible = true
    }

    // 保存评论
    async addCommet () {
        await this.__apis.customer.saveDkCjComment({
            record_id: this.commetId,
            content: this.commet,
            type: 3
        })

        this.dialogCommetVisible = false
        this.commet = ''
        this.getData()

        this.$notify({
            title: '成功',
            message: '评论成功！',
            type: 'success'
        })
    }

    // 编辑弹窗
    editDialog (objs: any) {
        let obj = this.__utils.index.copyObj(objs)
        this.editCurrent = obj
        this.form = {
            id: obj.Id,
            customer_id: obj.CustomerId,
            customer_name: obj.CustomerName,
            house_name: obj.HouseName,
            action_time: obj.ActionTime,
            customer_info: obj.CustomerInfo,
            channel_name: obj.ChannelName,
            channel_username: obj.ChannelUsername,
            channel_tel: obj.ChannelTel,
            zygw_name: obj.ZygwName,
            zygw_tel: obj.ZygwTel,
            result: obj.Result,
            ext_data: obj.ExtData,
            user_name: obj.UserName
        }
        if (obj.Imgs && obj.Imgs.length > 0) {
            this.fileList = obj.Imgs.map((v: any) => {
                return { url: v }
            })
        } else {
            this.fileList = []
        }
        this.dialogFormVisible = true
    }

    // 编辑保存
    async saveForm () {
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)'
        })

        try {
            let data = this.__utils.index.copyObj(this.form)
            if (this.fileList && this.fileList.length > 0) {
                data.imgs = this.fileList.map((v: any) => {
                    return v.url
                })
            } else {
                data.imgs = []
            }
            data.imgs = JSON.stringify(data.imgs)
            data.ext_data = JSON.stringify(data.ext_data)

            await this.__apis.customer.saveCjRes(data)

            this.$notify({
                title: '成功',
                message: '保存成功！',
                type: 'success'
            })
            this.dialogFormVisible = false
            this.getData()
        } catch (e) {
            throw e
        } finally {
            loading.close()
        }
    }

    // 楼盘匹配
    async querySearchAsync (queryString: string, cb: any) {
        if (!queryString || queryString.length === 0) {
            // eslint-disable-next-line standard/no-callback-literal
            cb([])
            return
        }

        const res = await this.__apis.utils.searchHouse({
            term: queryString
        })
        cb(res.data)
    }

    handleHouseSelect (val: any) {
        this.form.house_name = val.label
        this.form.house_id = val.id
    }

    // 添加项目
    add_project () {
        this.form.ext_data.push({
            house_name: '',
            channel_name: '',
            channel_username: '',
            channel_tel: '',
            zygw_name: '',
            zygw_tel: ''
        })
    }

    // 删除项目
    projectDel (index: number) {
        this.form.ext_data.splice(index, 1)
    }

    async delItem (obj: any) {
        try {
            await this.$confirm('确认删除？')

            await this.__apis.customer.deleteDkCj({
                type: 'cj_res',
                id: obj.Id
            })

            this.$notify({
                title: '成功',
                message: '删除成功！',
                type: 'success'
            })
            this.getData()
        } catch (e) {
            console.log(e)
        }
    }
}
