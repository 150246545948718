import { Component, Vue } from 'vue-property-decorator'
import dataRange from '@/util/date.ts'
import { Table } from '@/types'
import { __utils } from '@/util'
import { getCustomerDistributionRes } from '@/api/saleman'

interface TableItem extends Omit<getCustomerDistributionRes, 'distribution_time'>{
    distribution_time: string
}

const DefaultDate = {
    start: '2020-01-01',
    end: __utils.dayjs().format('YYYY-MM-DD')
}

@Component
export default class SaleManRenLingPage extends Vue {
    dateFilter = ['不限', '昨天', '本周', '上周']
    dateFilterCur = 0

    form: any = {
        ctime: '',
        dep: '',
        group: '',
        user_id: '',
        has_dk: undefined,
        has_cj: undefined
    }

    dkStatusData = [
        {
            label: '未带看',
            value: 0
        },
        {
            label: '已带看',
            value: 1
        }
    ]

    cjStatusData = [
        {
            label: '未成交',
            value: 0
        },
        {
            label: '已成交',
            value: 1
        }
    ]

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 50,
        pageSizes: [10, 20, 50, 100],
        total: 0,
        loading: false
    }

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    get
    depList () {
        return this.__utils.index.getXsDep()
    }

    created () {
        this.form.dep = this.infoPos.dep
        this.form.group = this.infoPos.group
        this.form.user_id = this.infoPos.fxs
        this.getData()
    }

    async getData () {
        try {
            this.tableData.loading = true
            const date: any = this.form.ctime
            const { data } = await this.__apis.saleman.getCustomerDistribution({
                start_date: date && date[0] ? date[0] : DefaultDate.start,
                end_date: date && date[1] ? date[1] : DefaultDate.end,
                dep_id: this.form.user_id ? undefined : this.form.dep,
                group_id: this.form.user_id ? undefined : this.form.group,
                user_id: this.form.user_id,
                has_dk: this.form.has_dk,
                has_cj: this.form.has_cj,
                page: this.tableData.page,
                pagesize: this.tableData.pageSize
            })

            this.tableData.data = data.rows.map(a => {
                return {
                    ...a,
                    distribution_time: this.__utils.dayjs(a.distribution_time).format('YYYY-MM-DD HH:mm:ss'),
                    hasDk: this.__utils.index.getLabelByValue(this.dkStatusData, a.has_dk || 0),
                    hasCj: this.__utils.index.getLabelByValue(this.cjStatusData, a.has_cj || 0)
                }
            })
            this.tableData.total = data.total
        } catch (e) {
            throw e
        } finally {
            this.tableData.loading = false
        }
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getData()
    }

    tableCurrentChange (page: number) {
        this.tableData.page = page
        this.getData()
    }

    // 部门改变
    departSelect () {
        this.form.group = ''

        if (!this.form.dep) {
            this.form.user_id = ''
        }
    }

    // 组改变
    groupSelect () {
        this.form.user_id = ''
    }

    // 日期按钮
    dateFilterHandle (index: number) {
        this.dateFilterCur = index
        let dateTime
        if (index === 1) {
            dateTime = {
                time_start: dataRange.getCurrentYesterday().format('yyyy-MM-dd'),
                time_end: dataRange.getCurrentYesterday().format('yyyy-MM-dd')
            }
        } else if (index === 2) {
            dateTime = {
                time_start: dataRange.getCurrentWeek()[0].format('yyyy-MM-dd'),
                time_end: dataRange.getCurrentWeek()[1].format('yyyy-MM-dd')
            }
        } else if (index === 3) {
            dateTime = {
                time_start: dataRange.getPreviousWeek()[0].format('yyyy-MM-dd'),
                time_end: dataRange.getPreviousWeek()[1].format('yyyy-MM-dd')
            }
        } else {
            dateTime = {
                time_start: '',
                time_end: ''
            }
        }
        this.form.ctime = [dateTime.time_start, dateTime.time_end]
    }

    // 清空筛选
    resetFrom (formName: string) {
        const form = this.$refs[formName] as any
        form.resetFields()
        this.dateFilterCur = 0
        this.tableData.page = 1
        this.getData()
    }

    routerPush (item: TableItem) {
        const { href } = this.$router.resolve({ name: '/customer/detail', params: { id: String(item.customer_id) } })
        window.open(href, '_blank')
    }

    followData: {
        name: string
        date: string
        remark: string
    }[] = []

    async getFollow (item: TableItem) {
        const { data } = await this.__apis.customer.customerDetail(String(item.customer_id))
        this.followData = data.brief_remark.map(a => {
            return {
                name: a.operator,
                date: a.time,
                remark: a.remark
            }
        })
    }
}
