import { Component, Vue } from 'vue-property-decorator'
import { LabelValueArray, Table } from '@/types'
import dateRangeSelectComponent from '@/components/wallet/date-range-select/index.vue'
import FilterComponent from '@/components/filters/filter/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'
import InputSelectComponent from '@/components/filters/inputSelect/index.vue'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'
import shareManageComponent from './manage/index.vue'
import { routerBaseUrl } from '@/router'
import editCustomerLayer from '@/components/customer/editCustomerLayer/index.vue'

interface TableItem {
    id: number
    customerId: number
    customerName: string
    mobile: string
    mobileValid: number
    mobileLocation: string
    ip: string,
    ipAddressString: string
    needShowMobileButton: boolean
    demandRegion: string
    houseName: string
    houseId: number
    isRepeat: number
    level: string
    sysTags: {
        text: string
        value: string
    }[]
    impGroup: {
        text: string
        value: number
    }
    registerTime: string
    followTime: string
    shareFxsList: {
        customer_id: number
        id: number
        shared_user_id: number
        shared_user_name: string
    }[]
    mark: number
    groupLeaderFollowLast: {
        remark: string
        date: string
    }
    fxsFollowLast: {
        remark: string
        date: string
        mark: number
    }
    saleman:string
}

interface Filter {
    group: string | number
    customerName: string,
    customerMobile: string,
    level: string,
    houseId: number,
    houseName: string,
    intentionArea: string,
    fxsId: number
    fxsName: string
    shareFxsId: number
    shareFxsName: string
}

export interface ShareManage {
    visible: boolean
    data: {
        id: number
        userInfo: {
            shareId: number
            id: number
            name: string
        }[]
    }
}

@Component({
    components: {
        FilterComponent,
        dateRangeSelectComponent,
        SelectComponent,
        InputDateRangeComponent,
        FilterBusinessSelect,
        InputSelectComponent,
        InputSelectSearch,
        shareManageComponent,
        editCustomerLayer
    }
})
export default class CustomerShareRecordPage extends Vue {
    tab = {
        current: 'first',
        data: [
            { label: '共享的客户', value: 'first' },
            { label: '被共享的客户', value: 'second' }
        ]
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 50,
        pageSizes: [10, 20, 50, 100],
        total: 0
    }

    loading = false

    filter: Filter = {
        group: '',
        customerName: '',
        customerMobile: '',
        level: '',
        houseId: 0,
        houseName: '',
        intentionArea: '',
        fxsId: 0,
        fxsName: '',
        shareFxsId: 0,
        shareFxsName: ''
    }

    editCustomer = {
        visible: false,
        data: {}
    }

    shareManage: ShareManage = {
        visible: false,
        data: {
            id: 0,
            userInfo: []
        }
    }

    get
    isFirst () {
        return this.tab.current === 'first'
    }

    get
    isFxs () {
        return this.__role.has([this.__role.ROLE.FENXISHI], false)
    }

    get
    customerTags () {
        return this.__store.state.user.customerTags
    }

    created () {
        if (this.isFxs && this.isFirst) {
            this.filter.shareFxsName = this.__store.state.user.userInfo.username
        } else {
            this.tab.data = this.tab.data.filter(a => a.value !== 'first')
            this.tab.current = 'second'
        }
        this.getData()
    }

    async getData () {
        this.loading = true
        try {
            let { data } = await this.__apis.customer.getCustomerList({
                type: this.tab.current === 'first' ? 'share_out' : 'share_in',
                name: this.filter.customerName,
                mobile: this.filter.customerMobile,
                tag: this.filter.level,
                house: this.filter.houseName,
                intention_area: this.filter.intentionArea,
                user_id: this.filter.shareFxsId || undefined,
                shared_user_id: this.filter.fxsId || undefined,
                page: this.tableData.page,
                pagesize: this.tableData.pageSize
            })
            const res = data.res
            this.tableData.total = res.total
            this.tableData.data = res.customers.map(v => {
                return {
                    id: v.id,
                    customerId: v.id,
                    customerName: v.name,
                    mobile: v.mobile_fixed,
                    mobileValid: v.mobile_valid,
                    mobileLocation: v.mobile_location,
                    ip: v.ip,
                    ipAddressString: v.ip_address_string,
                    needShowMobileButton: v.need_show_mobile_button,
                    demandRegion: v.demand_region,
                    houseName: v.house_name,
                    houseId: v.house_id,
                    isRepeat: v.is_repeat,
                    level: v.tag,
                    sysTags: v.sys_tags,
                    impGroup: v.impt_group,
                    registerTime: v.register_time,
                    followTime: v.first_follow_time_diff,
                    mark: v.mark,
                    shareFxsList: v.share_info_list,
                    groupLeaderFollowLast: v.brief_remark_gl && v.brief_remark_gl.length ? {
                        remark: v.brief_remark_gl[0].remark,
                        date: v.brief_remark_gl[0].time
                    } : {
                        remark: '',
                        date: ''
                    },
                    fxsFollowLast: v.brief_remark && v.brief_remark.length ? {
                        remark: v.brief_remark[0].remark,
                        date: v.brief_remark[0].time,
                        mark: v.brief_remark[0].mark
                    } : {
                        remark: '',
                        date: '',
                        mark: 0
                    },
                    saleman: v.saleman
                }
            })
        } finally {
            this.loading = false
        }
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getData()
    }

    tableCurrentChange (page: number) {
        this.tableData.page = page
        this.getData()
    }

    filterSubmit () {
        this.tableData.page = 1
        this.getData()
    }

    filterReset () {
        this.filter = {
            group: '',
            customerName: '',
            customerMobile: '',
            level: '',
            houseId: 0,
            houseName: '',
            intentionArea: '',
            fxsId: 0,
            fxsName: '',
            shareFxsId: 0,
            shareFxsName: this.tab.current === 'second' ? '' : this.__store.state.user.userInfo.username
        }
        this.tableData.page = 1
        this.getData()
    }

    selectSearchHouse (item: LabelValueArray[0] & {id: number}) {
        this.filter.houseId = item.id
        this.filter.houseName = item.label
    }

    selectSearchFxs (item: LabelValueArray[0]) {
        this.filter.shareFxsId = item.value
        this.filter.shareFxsName = item.label
    }

    selectSearchShareFxs (item: LabelValueArray[0]) {
        this.filter.fxsId = item.value
        this.filter.fxsName = item.label
    }

    toDetail (customerId: string) {
        window.open(`${routerBaseUrl}/customer/detail/${customerId}?share_list=true`, '_blank')
    }

    toFastEdit (item: TableItem) {
        this.editCustomer.data = {
            hideGroupTag: this.tab.current === 'second',
            id: item.customerId,
            name: item.customerName,
            mobile_fixed: item.mobile,
            house_id: item.houseId,
            tag: item.level,
            impt_group: item.impGroup,
            sys_tags: item.sysTags
        }
        this.editCustomer.visible = true
    }

    async seePhone (item: TableItem) {
        if (!item.mobile.includes('*')) {
            return
        }
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })
        try {
            const res = await this.__apis.customer.showPubCustomerMobile(item.id)
            if (res.data.mobile) {
                item.mobile = res.data.mobile
            }
        } catch (e) {
            throw e
        } finally {
            loading.close()
        }
    }

    async showManage (item: TableItem) {
        this.shareManage.data = {
            id: item.id,
            userInfo: item.shareFxsList.map(a => {
                return {
                    shareId: a.id,
                    id: a.shared_user_id,
                    name: a.shared_user_name
                }
            })
        }
        this.shareManage.visible = true
    }

    async exitShare (item: TableItem) {
        await this.__apis.customer.exitShare(item.customerId)
        this.$notify({
            title: '成功',
            message: '退出成功！',
            type: 'success'
        })
        this.tableData.data = this.tableData.data.filter(a => a.id !== item.id)
    }

    tabClick () {
        if (this.isFxs && this.isFirst) {
            this.filter.shareFxsName = this.tab.current === 'second' ? '' : this.__store.state.user.userInfo.username
        }
        this.tableData.page = 1
        this.getData()
    }

    searchUserList: LabelValueArray = []
    async searchUser (text: string) {
        const { data: res } = await this.__apis.utils.searchUser({
            key: text,
            fxs: 1
        })

        this.searchUserList = res.list.map(a => {
            return {
                value: a.Id,
                label: a.Username
            }
        })
    }

    editCustomerLayer = {
        visible: false,
        customerId: 0
    }

    editCustomerInfo (customerId: number) {
        this.editCustomerLayer = {
            visible: true,
            customerId
        }
    }
}
