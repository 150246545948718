import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import { SubjectType } from '@/util/const'
import questionBankSetComponent from '@/components/questionBank/set/index.vue'
import { storageName } from '@/localStorage'

interface TableItem {
    id: number,
    depId: number
    depName: string
    subjectType: number
    subjectName: string
    questionTotal: number
    questionUseTotal: number
    updateTime: string
    operateName: string
}

const subjectType = [
    {
        label: '全部',
        value: 0
    },
    ...SubjectType
]

@Component({
    components: {
        questionBankSetComponent
    }
})
export default class QuestionBankManagePage extends Vue {
    form: {
        dep?: number
    } = {
        dep: undefined
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0
    }

    questionBankSet = {
        visible: false,
        item: {}
    }

    get
    depArr () {
        return this.__utils.index.getXsDep()
    }

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    created () {
        const dep = this.__localStorage.getLocalStorage(storageName.QUESTIONDEP)
        // 身份-参数-存的值
        this.form.dep = this.userInfo.dep || +this.$route.query.dep || (dep ? +dep : 6)
        this.getData()
    }

    async getData () {
        const filter = this.form
        const { data } = await this.__apis.activity.questionsDepStatistic(filter.dep || 0)
        this.tableData.data = data.map(a => {
            return {
                id: 0,
                depId: a.dep_id,
                depName: a.dep_id === 0 ? '公司' : a.dep_name,
                subjectType: a.subject_id || 1,
                subjectName: a.subject_name,
                questionTotal: a.question_count,
                questionUseTotal: a.use_question_count,
                updateTime: this.__utils.index.formatDate(new Date(a.last_update_time), 'YYYY-MM-DD HH:mm:ss'),
                operateName: a.last_update_user_name
            }
        })
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getData()
    }

    resetFilter () {
        const form = this.$refs['form'] as any
        form.resetFields()
        this.tableCurrentChange(1)
    }

    async seeDetail (item: TableItem) {
        this.$router.push({ name: '/questionBank/manage/classification', params: { type: String(item.subjectType), dep: String(item.depId) } })
    }

    showQuestionSet () {
        this.questionBankSet.visible = true
    }

    depChange (value: number) {
        this.__localStorage.setLocalStorage(storageName.QUESTIONDEP, String(value))
        this.getData()
    }
}
