import { Component, Vue } from 'vue-property-decorator'
import sendComponent from './send/index.vue'
import { routerBaseUrl } from '@/router'
import { editMoneyData } from './send'

interface TableItem {
    id: number
    department: string
    group: string
    has_close: boolean
    sum_money: string
    send_money: string
    reduce_money: string
}

interface _sendComponent {
    show: boolean
    data: editMoneyData
}

@Component({
    components: {
        sendComponent
    }
})
export default class IndexPage extends Vue {
    sendComponent: _sendComponent = {
        show: false,
        data: {
            id: '',
            allMoney: '',
            sendMoney: '',
            shouldMoney: '',
            department: '',
            group: '',
            remark: ''
        }
    }

    tableData: TableItem[] = []

    mounted () {
        this.getTableData()
    }

    async getTableData () {
        const { data } = await this.__apis.wallet.groupWalletAllList()

        this.tableData = data.map(a => {
            const group = this.__utils.index.getGroupInfoById(a.group_id)

            return {
                id: a.group_id,
                department: a.dep_name,
                group: group.name,
                has_close: group.has_close,
                sum_money: this.__utils.index.formatMoney(a.sum_money),
                send_money: this.__utils.index.formatMoney(-a.send_money),
                reduce_money: this.__utils.index.formatMoney(a.sum_money + a.send_money)
            }
        })

        this.__utils.index.sortGroups(this.tableData)
    }

    gotoDetail (id: number) {
        open(`${routerBaseUrl}/financial/wallet/group-detail?id=${id}`)
    }

    showSend (item: TableItem) {
        this.sendComponent = {
            show: true,
            data: {
                id: item.id.toString(),
                allMoney: item.sum_money.toString(),
                sendMoney: '',
                shouldMoney: item.reduce_money.toString(),
                department: item.department,
                group: item.group,
                remark: ''
            }
        }
    }
}
