import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SuccessDetailTabs extends Vue {
    tabs = {
        value: '成交信息'
    }

    @Prop({
        type: Object,
        default () {
            return {
                data: []
            }
        }
    })
    options !: {data: any[]}

    created () {
        for (let i = 0; i < this.options.data.length; i++) {
            const data = this.options.data[i]
            if (this.$route.name === data.title) {
                this.tabs.value = data.label
                break
            }
        }
    }

    changeTabs (item: any) {
        if (this.$route.path === item.route) return

        this.tabs.value = item.label
        this.$router.push(item.route)
    }
}
