import { Component, Vue, Watch } from 'vue-property-decorator'
import dataRange from '@/util/date.ts'
import { apiConfig } from '@/api/config'
import { storageName } from '@/localStorage'
import InputSearch from '@/components/InputSearch/index.vue'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'
import { searchHouseRes } from '@/api/utils'

@Component({
    components: {
        InputSearch,
        InputSelectSearch
    }
})
export default class SaleManGuideRecord extends Vue {
    dialogCommetVisible = false
    commet = ''
    commetId = ''
    dataObj: any = {}
    guideStatus = [
        {
            value: 1,
            label: '未带看'
        }, {
            value: 2,
            label: '已带看'
        }
    ]
    // 带看弹窗
    dialogFormGuideVisible = false
    guideForm: any = {
        house_name: '',
        house_id: '',
        action_time: '',
        pre_work: '',
        customer_info: ''
    }
    editCurrent = {}
    formLabelWidth = '120px'
    page = 1
    pageSize = 20

    form: any = {
        ctime: '',
        username: '',
        phone: '',
        house: '',
        houseId: '',
        dep: '',
        group: '',
        user_id: ''
    }
    guideType = 0
    guideTypeList = ['带看总结']
    dialogFormVisible = false
    guideAfterform: any = {
        customer_name: '',
        customer_tel: '',
        action_time: '',
        region: '',
        house_name: '',
        house_id: '',
        channel_name: '',
        channel_tel: '',
        zygw_name: '',
        zygw_tel: '',
        imgs: '',
        customer_info: '',
        zongjie: '',
        ext_data: [
            {
                house_name: '',
                channel_name: '',
                channel_username: '',
                channel_tel: '',
                zygw_name: '',
                zygw_tel: ''
            }
        ]
    }
    dialogType = ''
    // 图片上传
    dialogImageUrl = apiConfig.apiUrl
    dialogImagePrev = ''
    dialogVisible = false
    fileList: any[] = []

    dateFilter = ['不限', '昨天', '本周', '上周']
    dateFilterCur = 0

    dateFilter2 = ['不限', '明天', '本周', '下周']
    dateFilterCur2 = 0

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }
    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    get
    depList () {
        return this.__utils.index.getXsDep()
    }

    get
    fxsListInGroup () {
        const list = this.form.group ? this.infoPos.fxs_arr[this.form.dep][this.form.group] : []
        if (this.userInfo.user_id === 1279) {
            list.push(
                { deactive: 1, k: 1481, v: '老队-吴敏' },
                { deactive: 1, k: 1215, v: '老队-包晓龙' },
                { deactive: 1, k: 1428, v: '老队-陈先福' },
                { deactive: 1, k: 1667, v: '老队-黄超' },
                { deactive: 1, k: 1878, v: '老队-褚浩' },
                { deactive: 1, k: 1296, v: '老队-舒志明' },
                { deactive: 1, k: 1760, v: '老队-余本春' }
            )
        }
        return list
    }

    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }

    selectHouse (a: searchHouseRes) {
        this.form.houseId = a.id
        this.form.house = a.label
    }

    mounted () {
        this.form.dep = this.infoPos.dep
        this.form.group = this.infoPos.group
        this.form.user_id = this.infoPos.fxs
        this.getData()
    }

    // 图片上传
    handleRemove (file: any, fileList: any) {
        this.fileList = fileList
    }

    handlePictureCardPreview (file: any) {
        this.dialogImagePrev = file.url
        this.dialogVisible = true
    }

    handlePictureSuccess (response: any, file: any) {
        this.fileList.push({
            url: response.data.url,
            data: file,
            name: file.name
        })
    }

    handlePictureError () {
        this.$notify({
            title: '错误',
            message: '上传失败',
            type: 'error'
        })
    }

    // 分页改变
    pageChange (page: number) {
        this.page = page
        this.getData()
    }

    // 带看状态
    async guideStatusChange (obj: any) {
        await this.__apis.customer.saveDkPre({
            id: obj.Id,
            house_name: obj.HouseName,
            action_time: obj.ActionTime,
            pre_work: obj.PreWork,
            customer_info: obj.CustomerInfo,
            status: obj.Status
        })

        this.$notify({
            title: '成功',
            message: '修改成功！',
            type: 'success'
        })
        this.getData()
    }

    // 评论弹窗
    commetDialog (obj: any, type: string) {
        this.commetId = obj.Id
        this.dialogCommetVisible = true
        this.dialogType = type
    }

    async addCommet () {
        await this.__apis.customer.saveDkCjComment({
            record_id: this.commetId,
            content: this.commet,
            type: this.dialogType === 'before' ? 1 : 2
        })

        this.dialogCommetVisible = false
        this.$notify({
            title: '成功',
            message: '评论成功！',
            type: 'success'
        })
        this.commet = ''
        this.getData()
    }

    // 编辑弹窗
    editDialog (objs: any, type: string) {
        let obj = this.__utils.index.copyObj(objs)
        this.editCurrent = obj

        if (type === 'before') {
            this.guideForm = {
                id: obj.Id,
                customer_id: obj.CustomerId,
                customer_name: obj.CustomerName,
                house_name: obj.HouseName,
                action_time: obj.ActionTime,
                pre_work: obj.PreWork,
                customer_info: obj.CustomerInfo,
                user_name: obj.UserName
            }
            this.dialogFormVisible = false
            this.dialogFormGuideVisible = true
        } else {
            let houseList = obj.ExtData.map((v: any) => {
                return {
                    house_name: v.HouseName,
                    house_id: v.HouseId,
                    channel_name: v.ChannelName,
                    channel_username: v.ChannelUsername,
                    channel_tel: v.ChannelTel,
                    zygw_name: v.ZygwName,
                    zygw_tel: v.ZygwTel,
                    channel_partner_id: v.ChannelPartnerId
                }
            })
            this.guideAfterform = {
                id: obj.Id,
                customer_id: obj.CustomerId,
                customer_name: obj.CustomerName,
                house_name: obj.HouseName,
                action_time: obj.ActionTime,
                customer_info: obj.CustomerInfo,
                channel_name: obj.ChannelName,
                channel_username: obj.ChannelUsername,
                channel_tel: obj.ChannelTel,
                zygw_name: obj.ZygwName,
                zygw_tel: obj.ZygwTel,
                after_work: obj.AfterWork,
                ext_data: houseList || [],
                user_name: obj.UserName
            }
            if (obj.Imgs && obj.Imgs.length > 0) {
                this.fileList = obj.Imgs.map((v: any) => {
                    return { url: v }
                })
            } else {
                this.fileList = []
            }
            this.dialogFormVisible = true
            this.dialogFormGuideVisible = false
        }
    }

    // 编辑保存
    async saveForm (type: string) {
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)'
        })

        try {
            let data: any
            if (type === 'dialogFormVisible') {
                data = this.__utils.index.copyObj(this.guideAfterform)
                data.ext_data = JSON.stringify(data.ext_data)
                data.house_list = data.ext_data
                data.imgs = this.fileList.map((a:any) => a.url).join(',')
                data.filter_set = true
                await this.__apis.customer.saveDkRes(data)
            } else {
                data = this.__utils.index.copyObj(this.guideForm)
                await this.__apis.customer.saveDkPre(data)
            }

            this.$notify({
                title: '成功',
                message: '保存成功！',
                type: 'success'
            })
            if (type === 'dialogFormVisible') {
                const form = this.$refs['guideAfterform'] as any
                form.resetFields()
                this.dialogFormVisible = false
            } else {
                const form = this.$refs['guideForm'] as any
                form.resetFields()
                this.dialogFormGuideVisible = false
            }
            this.getData()
        } catch (e) {
            throw e
        } finally {
            loading.close()
        }
    }

    async querySearchAsync (queryString: string, cb: any) {
        if (!queryString || queryString.length === 0) {
            // eslint-disable-next-line standard/no-callback-literal
            cb([])
            return
        }

        const res = await this.__apis.utils.searchHouse({
            term: queryString
        })
        cb(res.data)
    }

    handleHouseSelect (val: any) {
        this.guideForm.house_name = val.label
        this.guideForm.house_id = val.id
    }

    async getData () {
        const form = Object.assign(this.__utils.index.copyObj(this.form), {
            page: this.page,
            pagesize: this.pageSize,
            time_start: this.form.ctime ? this.form.ctime[0] : '',
            time_end: this.form.ctime ? this.form.ctime[1] : ''
        })

        if (this.infoPos.dep) form.dep = ''
        if (this.infoPos.group) form.group = ''

        if (this.guideType) {
            const { data } = await this.__apis.customer.getDkPreList(form)

            data.list.forEach((v: any) => {
                if (v.ExtData === '[object Object],[object Object]') {
                    v.ExtData = ''
                }
                v.ExtData = v.ExtData ? JSON.parse(v.ExtData) : []
                v.ExtData2 = v.ExtData
            })

            this.dataObj = data
        } else {
            const { data } = await this.__apis.customer.getDaiKanResList({
                page: form.page,
                pagesize: form.pagesize,
                group_id: form.user_id ? undefined : form.group,
                dep_id: form.dep,
                user_id: form.user_id,
                customer_name: form.username,
                house_id: form.houseId,
                customer_tel: form.phone,
                dateQrl: this.form.ctime && {
                    query_start_time: form.time_start,
                    query_end_time: form.time_end
                }
            })

            this.dataObj = {
                ...data,
                list: data.list.map(a => {
                    const UserInfo = a.user_info ? JSON.parse(a.user_info) : []
                    const userStr = a.user_names.join(', ')

                    let ExtData = a.ext_data
                    if (ExtData === '[object Object],[object Object]') {
                        ExtData = ''
                    }
                    ExtData = ExtData ? JSON.parse(ExtData) : []

                    return {
                        Id: a.id,
                        userStr,
                        UserInfo,
                        UserName: userStr,
                        Ctime: this.__utils.index.formatDate(new Date(a.ctime), 'YYYY-MM-DD HH:mm:ss'),
                        CustomerName: a.customer_name,
                        CustomerTel: a.customer_tel,
                        ActionTime: this.__utils.index.formatDate(new Date(a.action_time), 'YYYY-MM-DD'),
                        ExtData,
                        Imgs: JSON.parse(a.imgs),
                        CustomerInfo: a.customer_info,
                        CustomerId: a.customer_id,
                        AfterWork: a.after_work,
                        remark: a.remark,
                        cj_probability_level: a.cj_probability_level ? a.cj_probability_level : undefined
                    }
                })
            }
        }

        this.dataObj.show = true
    }

    departSelect () {
        this.form.group = ''

        if (!this.form.dep) {
            this.form.user_id = ''
        }
    }

    groupSelect () {
        this.form.user_id = ''
    }

    selectHandle (index: number, key: string) {
        // @ts-ignore
        this[key] = index
        this.pageChange(1)
    }

    dateFilterHandle (index: number, key: string) {
        // @ts-ignore
        this[key] = index
        let dateTime
        if (key === 'dateFilterCur') {
            if (index === 1) {
                dateTime = {
                    time_start: dataRange.getCurrentYesterday().format('yyyy-MM-dd'),
                    time_end: dataRange.getCurrentYesterday().format('yyyy-MM-dd')
                }
            } else if (index === 2) {
                dateTime = {
                    time_start: dataRange.getCurrentWeek()[0].format('yyyy-MM-dd'),
                    time_end: dataRange.getCurrentWeek()[1].format('yyyy-MM-dd')
                }
            } else if (index === 3) {
                dateTime = {
                    time_start: dataRange.getPreviousWeek()[0].format('yyyy-MM-dd'),
                    time_end: dataRange.getPreviousWeek()[1].format('yyyy-MM-dd')
                }
            } else {
                dateTime = {
                    time_start: '',
                    time_end: ''
                }
            }
        } else {
            if (index === 1) {
                dateTime = {
                    time_start: dataRange.getCurrentAfterday().format('yyyy-MM-dd'),
                    time_end: dataRange.getCurrentAfterday().format('yyyy-MM-dd')
                }
            } else if (index === 2) {
                dateTime = {
                    time_start: dataRange.getCurrentWeek()[0].format('yyyy-MM-dd'),
                    time_end: dataRange.getCurrentWeek()[1].format('yyyy-MM-dd')
                }
            } else if (index === 3) {
                dateTime = {
                    time_start: dataRange.getCurrenAftertWeek()[0].format('yyyy-MM-dd'),
                    time_end: dataRange.getCurrenAftertWeek()[1].format('yyyy-MM-dd')
                }
            } else {
                dateTime = {
                    time_start: '',
                    time_end: ''
                }
            }
        }
        this.form.ctime = [dateTime.time_start, dateTime.time_end]
    }

    // 搜索
    clickSearch () {
        this.pageChange(1)
    }

    // 清空筛选
    resetFrom (formName: string) {
        const form = this.$refs[formName] as any

        this.dateFilterCur = 0
        this.dateFilterCur2 = 0
        form.resetFields()
        this.pageChange(1)
    }

    routerPush (obj: any, bool: boolean) {
        if (bool) {
            let { href } = this.$router.resolve(obj)
            window.open(href, '_blank')
        } else {
            this.$router.push(obj)
        }
    }

    // 添加项目
    add_project () {
        this.guideAfterform.ext_data.push({
            house_name: '',
            channel_name: '',
            channel_username: '',
            channel_tel: '',
            zygw_name: '',
            zygw_tel: ''
        })
    }

    // 删除项目
    projectDel (index: number) {
        this.guideAfterform.ext_data.splice(index, 1)
    }

    async delItem (obj: any) {
        try {
            await this.$confirm('确认删除？')

            await this.__apis.customer.deleteDkCj({
                type: 'dk_pre',
                id: obj.Id
            })

            this.$notify({
                title: '成功',
                message: '删除成功！',
                type: 'success'
            })
            this.getData()
        } catch (e) {
            console.log(e)
        }
    }

    // 渠道搜索返回
    qudaoSelect (obj: any, form: any) {
        if (this.dialogFormVisible) {
            form.channel_partner_id = obj.id
        }
    }
}
