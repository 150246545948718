import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AccountAll extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    show !: boolean

    get
    btnSaveDisabled () {
        return !this.form.phone.trim() || !this.form.houseId.trim()
    }

    form = {
        phone: '',
        name: '',
        houseId: '',
        siteUrl: '',
        remark: ''
    }

    async save () {
        if (!this.__utils.index.checkPhone(this.form.phone)) {
            this.$message.error('请填写正确的手机号')
            return
        }

        try {
            if (!this.form.siteUrl.trim()) {
                await this.$confirm('没有来源网址，客户的渠道信息将会为空，请确认你的操作', {
                    type: 'warning'
                })
            }

            await this.__apis.customer.addSWTCustomer({
                name: this.form.name,
                house_id: Number(this.form.houseId),
                site_url: this.form.siteUrl,
                phone: this.form.phone,
                operate_log: this.form.remark
            })

            this.$message.success('添加成功')
            this.close()
        } catch (e) {}
    }

    close () {
        this.$emit('update:show', false)
    }
}
