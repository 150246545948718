import { Component, Vue } from 'vue-property-decorator'
import inputSelect from '@/components/filters/inputSelect/index.vue'
import FilterComponent from '@/components/filters/filter/index.vue'
import { Table } from '@/types'
import { searchHouseRes } from '@/api/utils'

interface TableItem {
    id: number
    oldStartDate: string
    oldEndDate: string
    oldPromiseMoney: string
    oldPromiseRemain: string
    oldHouses: searchHouseRes[]
    newStartDate: string
    newEndDate: string
    newPromiseMoney: string
    newPromiseRemain: string
    newHouses: searchHouseRes[]
    changeDate: string
    operate: string
}

interface Filter {
    deposit_id?: number
}

@Component({
    components: {
        inputSelect,
        FilterComponent
    }
})
export default class FriendChangePage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0
    }

    filter:Filter = {}

    created () {
        if (this.$route.query.record_id) {
            this.filter.deposit_id = +this.$route.query.record_id
        }
        this.getData()
    }

    async getData () {
        const { data } = await this.__apis.channelManage.getSalePartnerDepositChangeRecordPageList({
            partner_id: +this.$route.params.id,
            ...this.filter,
            page: this.tableData.page,
            pagesize: this.tableData.pageSize
        })
        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.deposit_id,
                oldStartDate: this.__utils.index.formatDate(new Date(a.begin_time_from), 'YYYY-MM-DD'),
                oldEndDate: this.__utils.index.formatDate(new Date(a.end_time_from), 'YYYY-MM-DD'),
                oldPromiseMoney: a.deposit_money_from.toFixed(2),
                oldPromiseRemain: a.remain_money_from.toFixed(2),
                oldHouses: a.house_list_from,
                newStartDate: this.__utils.index.formatDate(new Date(a.begin_time_to), 'YYYY-MM-DD'),
                newEndDate: this.__utils.index.formatDate(new Date(a.end_time_to), 'YYYY-MM-DD'),
                newPromiseMoney: a.deposit_money_to.toFixed(2),
                newPromiseRemain: (a.remain_money_to || 0).toFixed(2),
                newHouses: a.house_list_to,
                changeDate: this.__utils.index.formatDate(new Date(a.ctime), 'YYYY-MM-DD hh:mm'),
                operate: a.user_info.user_name
            }
        })
    }

    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1
        this.getData()
    }

    currentChange (page: number) {
        this.tableData.page = page
        this.getData()
    }
}
