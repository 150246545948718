import { Component, Prop, Vue } from 'vue-property-decorator'
import { EventBus, EventNames, filterId } from '@/util/bus'
import dateUtils from '@/util/date'

@Component
export default class FiltersInputDateRange extends Vue {
    value: string[] | string = []
    visible = false
    isEnter = false
    id = 0

    @Prop({
        type: String,
        default: ''
    })
    title !: string

    @Prop({
        type: String,
        default: ''
    })
    propName !: string

    @Prop({
        type: String,
        default: ''
    })
    componentId !: string

    @Prop({
        type: String,
        default: '请输入'
    })
    placeholder !: string

    @Prop({
        type: Boolean,
        default: false
    })
    filterable !: boolean

    @Prop({
        type: String,
        default: 'daterange'
    })
    type !: string

    @Prop({
        type: String,
        default: 'yyyy-MM-dd'
    })
    valueFormat !: string

    @Prop({
        type: String,
        default: 'yyyy-MM-dd'
    })
    format !: string

    get
    cmtBtnEnterDisabled () {
        return !this.value || !this.value.length
    }

    created () {
        this.value = this.type.includes('range') ? [] : ''
        this.id = filterId.value
        filterId.value++
        EventBus.$on(EventNames.clearFilter, this.clear)
    }

    destroyed () {
        EventBus.$off(EventNames.clearFilter, this.clear)
    }

    // 这条筛选被清空
    clear (id: number) {
        if (this.id === id) {
            this.value = this.type === 'week' ? '' : []
            this.isEnter = false
        }
    }

    cancel () {
        this.visible = false
    }

    enter () {
        let resWeek:any = this.type === 'week' ? new Date('' + this.value).getTime() + (6 * 24 * 60 * 60 * 1000) : new Date().getTime()
        // 这里需要通知筛选词条组件进行更新
        EventBus.$emit(EventNames.filterChange + this.componentId, {
            title: this.title + '：',
            content: this.type === 'week' ? [this.value, dateUtils.format(new Date(resWeek), 'yyyy-MM-dd')] : this.value,
            id: this.id,
            value: {
                [this.propName]: this.type === 'week' ? [this.value, dateUtils.format(new Date(resWeek), 'yyyy-MM-dd')] : this.value
            }
        })

        this.isEnter = true
        this.cancel()
    }
}
