import { BaseRes, java, ListReq, ListRes, php } from '.'
import { OnlineSignRecordResStatus } from '@/util/const'
import { AxiosResponse } from 'axios'

export interface bonusDetail {
    Ctime: string
    Dep: number
    DepName: string
    Gro: number
    GroupName: string
    Id: number
    Money: number
    Mtime:null
    Remark: string
    SaleId: number
    SendTime: string
    UserId: number
    Username: string
}

export interface getSuccessDetailRes {
    BonusList: Array<bonusDetail | {
        DepName: string
        GroupName: string
        Money: number
        Remark: string
        SendTime: string
        Username: string
    }>
    ChannelName: string
    ChannelPartnerId: number
    ChannelTel: string
    ChannelUserJob: string
    ChannelUserNote: string
    ChannelUsername: string
    CjDays: number
    Ctime: string
    CuiyongList: any[]
    CustomerId: number
    CustomerName: string
    CustomerTel: string
    DaoZhangList: {
        Ctime: string
        DaozhangTime: string
        Dep: number
        Id: number
        Money: number
        Remark: string
        SaleId: number
        Status: number
    }[]
    Dep: number
    DuijieUserList: any[]
    ExpectWangqianTime: string | null
    ExtData: {
        mtime: number
        user_info: {
            dep: number
            group: number
            money: number
            name: string
            user_id: number
            user_name: string
        }[]
    }
    FanYongList: {
        ActionTime: string
        Ctime: string
        Dep: number
        Id: number
        Money: number
        Remark: string
        SaleId: number
        Status: string
    }[]
    FanyongStatus: number
    Gro: number
    HasTuifan: number
    HasWangqian: number
    HasTrouble: number
    HouseId: number
    HouseMoney: number
    HouseName: string
    HouseNo: string
    Id: number
    JieyongStatus: number
    KaiPiaoList: {
        Ctime: string
        Dep: number
        Id: number
        KaipiaoTime: string
        Money: number
        Remark: string
        SaleId: number
    }[]
    KaipiaoStatus: number
    Money: number
    MustDzMoney: number
    NextJieyongTime: string | null
    Pid: number
    PzImgs: {
        bb: string
        qd: string
        rg: string
        sf: string
        sk: string
    }
    Remark: string
    SaleTime: string
    SaleType: number
    Sid: number
    SumMoney: number
    SumMustDzMoney: number
    TuiFangList: {
        Ctime: string
        ExtData: string
        Id: number
        Remark: string
        SaleId:number
        TuifanTime: string
    }[]
    Type: number
    UserHouseRate: number
    UserId: number
    WangQianList: {
        ActionTime: string
        Ctime: string
        ExtData: string
        HouseNo: string
        Id: number
        JieyongTime: null | string
        Remark: string
        SaleId: number
        WqNo: string
    }[]
    TroubleList: {
        Ctime: string
        Id: number
        Remark: string
        SaleId:number
        UserId: number
        Username: string
    }[]
    RemarkList: {
        Ctime: string
        Id: number
        Remark: string
        SaleId:number
        UserId: number
        Username: string
    }[]

    MoneyChangeList: {
        Ctime: string
        Id: number
        Remark: string
        SaleId:number
        OldMoney: number
        Money: number
        Operator: string
    }[]
    ZygwName: string
    ZygwTel: string
    IsControversial: number
}

interface saleMoneyChangeListReq extends ListReq {
    type?: string
    time_start?: string
    time_end?: string
    ctime?: string
    customer_name?: string
    customer_tel?: string
    house_name?: string
    dep?: number
    group?: number
    user_id?: number
    has_kp?: string
    has_dz?: string
}

interface saleMoneyChangeListRes extends ListRes <getSuccessDetailRes> {
    cjInfo?: string
}

interface saveSaleReq {
    id: string | number
    next_jieyong_time?: string
    expect_wangqian_time?: string
    customer_tel?: string
    customer_name?: string
    pz_imgs?: string
    zygw_name?: string
    zygw_tel?: string
    house_id?: string | number
    house_name?: string
    house_money?: string | number
    house_no?: string
    remark?: string
    sale_time?: string
    channel_partner_id?: number
    channel_name?: string
    sum_must_dz_money?: string | number
    sum_money?: string | number
    change_money_remark?: string
    jieyong_status?: string
    kaipiao_status?: string
    fanyong_status?: string
    is_controversial?: number
}

interface deleteRecordReq {
    id: string
    type: string
}

interface saleRecord {
    id: number
    sale_id: number
    remark: string
}

export interface saveSaleWqRecordReq extends saleRecord {
    ext_data: string // json string
    house_no: string
    wq_no: string
    action_time: string
    jieyong_time:string,
    no_reward?: number, // 不奖励钱包
}
interface saveSaleKpRecordReq extends saleRecord {
    money: string
    kaipiao_time: string
}

interface saveSaleDzRecordReq extends saleRecord {
    money: string
    daozhang_time: string
}

interface saveSaleFyRecordReq extends saleRecord {
    money: string
    action_time: string
    status: string
}

interface saveSaleTfRecordReq extends saleRecord {
    tuifan_time: string
}

// 设置问题单
interface saveSaleTroubleRecordReq extends saleRecord {
    has_trouble: number // 0,1
}

interface saveSaleRemarkReq extends saleRecord {
    // no other params
}

interface saveSaleBonusRecordReq extends saleRecord {
    money: string
    send_time: string
    user_id: number
}

interface OnlineSignRecordReq {
    value: number
}

export type OnlineSignAudit = {
    id: number
    audit_opinion: string
    sign_time: number
    sign_house_no: string
    sign_no: string
    sign_remark: string
    sign_image: string
    last_reviewer: string
    last_review_time: string
    review_state: OnlineSignRecordResStatus
}

type OnlineSign = {
    id: number
    action_time: number
    house_no: string
    wq_no: string
    remark: string
    ext_data: string
}

type OnlineSignRecordResponse = {
    online_sign: OnlineSign | null
    online_sign_audit: OnlineSignAudit | null
}

export type OnlineSignImages = {
    contract: string[]
    receipt: string[]
    relationship: string[]
    book: string[]
    other: string[]
}

interface OnlineSignRecordRes {
    id: number
    last_reviewer?: string
    last_review_time?: string
    review_state: OnlineSignRecordResStatus
    images: OnlineSignImages
    audit_opinion?: string
    sign_time: number
    sign_house_no: string
    sign_no: string
    sign_remark?: string
}

export interface OnlineSignApplyReq {
    sid: number
    sign_time: string
    sign_house_no: string
    sign_no: string
    images: string
    sign_remark: string
}

export interface OnlineSignResetApplyReq extends Omit<OnlineSignApplyReq, 'sid'> {
    apply_online_id: number
}

interface getDepositRemainMoneyReq {
    partner_id: number
    dao_zhang_date: string
    house_id: number
}

interface getDepositRemainMoneyRes {
    'id': number[],
    'has_deposit': boolean,
    'remain_money': number
}

type SaveUserSaleDaoZhangRecordReq = {
    sale_id: number
    remark: string
    money: number
    daozhang_time: string
    deposit_ids: number[]
}

type DeleteUserSaleDaoZhangRecordReq = {
    id: number
}

interface saveExpectWangQianTimeReq {
    'id': number,
    'expect_wq_time': string
}

interface getHouseSaleAreaBuildReq{
    customer_name: string;
    house_name: string;
    user_id: string;
    has_kp: string;
    has_dz: string;
    has_wangqian: string;
    channel_name: string;
    channel_partner_id: string;
    fxs_id: string;
    time_start: string;
    time_end: string;
}

interface getCommisionRecordReq{
    month: string;
    dep_id?: number;
}

interface getCommisionRecordRes{
    commision_detail: {
        dep_id:number,
        groupCommisionItemVoList:{
            commision: number,
            month: number,
            profit_id: number
        }[],
        group_id:number,
        leader_user_id:number,
        month:number
    };
    ctime: number;dep_id: number;detail: string;
    group_id: number;id: number;leader_user_id: number;
    leader_username: string;money: number;
    month_date: number;mtime: number;
    remark: string;
    send_money: string;
    status: number;
}

interface getCommisionSendRecordReq{
    commision_id?: string;
    profit_id?: string;
}
interface getCommisionSendRecordRes {
    commision_id: number;
    ctime: number;
    dep_id: number;
    group_id: number;
    id: number;
    leader_user_id: number;
    leader_username: string;
    money: number;
    month_date: number;
    mtime: number;
    operator: string
    remark: string
}

interface commisionSendReq{
    commsion_id: number;
    money: string;
    remark: string;
}

export default class ApiFinancial {
    // 删除到账记录
    deleteUserSaleDaoZhangRecord (data: DeleteUserSaleDaoZhangRecordReq) {
        return java.post('/admin/user/house/sale/daozhang/deleteUserSaleDaoZhangRecord', data)
    }

    // 保存到账记录
    saveUserSaleDaoZhangRecord (data: SaveUserSaleDaoZhangRecordReq) {
        return java.post('/admin/user/house/sale/daozhang/saveUserSaleDaoZhangRecord', data)
    }

    // 重新提交网签
    onlineSignResetApply (data: OnlineSignResetApplyReq) {
        return java.post('/admin/onlineSign/resetApply', data)
    }

    // 添加网签
    onlineSignApply (data: OnlineSignApplyReq) {
        return java.post('/admin/onlineSign/apply', data)
    }

    // 成交网签记录
    async onlineSignRecord (data: OnlineSignRecordReq): BaseRes<OnlineSignRecordRes | null> {
        const res = await java.post('/admin/onlineSign/houseSale', data) as AxiosResponse<OnlineSignRecordResponse>

        const sign = res.data.online_sign
        const audit = res.data.online_sign_audit
        let resData !: OnlineSignRecordRes

        if (sign) {
            const ext = sign.ext_data ? JSON.parse(sign.ext_data) : {}
            const images = ext.sign_image ? JSON.parse(ext.sign_image) : {}

            resData = {
                images: {
                    book: images.book || [],
                    contract: images.contract || [],
                    receipt: images.receipt || [],
                    relationship: images.relationship || [],
                    other: images.other || []
                },
                last_reviewer: ext.last_reviewer,
                last_review_time: ext.last_review_time,
                review_state: OnlineSignRecordResStatus.Pass,
                sign_no: sign.wq_no,
                sign_house_no: sign.house_no,
                id: sign.id,
                sign_remark: sign.remark,
                sign_time: sign.action_time
            }
        } else if (audit) {
            const ext = audit.sign_image ? JSON.parse(audit.sign_image) : {}

            resData = {
                audit_opinion: audit.audit_opinion,
                images: {
                    book: ext.book || [],
                    contract: ext.contract || [],
                    receipt: ext.receipt || [],
                    relationship: ext.relationship || [],
                    other: ext.other || []
                },
                last_reviewer: audit.last_reviewer,
                last_review_time: audit.last_review_time,
                review_state: audit.review_state,
                sign_no: audit.sign_no,
                sign_house_no: audit.sign_house_no,
                id: audit.id,
                sign_remark: audit.sign_remark,
                sign_time: audit.sign_time
            }
        }

        return {
            ...res,
            data: resData
        } as never
    }

    // 获取佣金更改记录
    getSaleList (data: saleMoneyChangeListReq): BaseRes<saleMoneyChangeListRes> {
        return php.post('', {
            action_name: 'getSaleList',
            ...data
        })
    }

    // 获取成交信息
    getSaleDetail (id: number): BaseRes<getSuccessDetailRes> {
        return php.post('', {
            action_name: 'getSaleDetail',
            id
        })
    }

    // 保存编辑成交记录
    saveSale (data: saveSaleReq) {
        return php.post('', {
            action_name: 'saveSale',
            ...data
        })
    }

    saveSaleWqRecord (data: saveSaleWqRecordReq) {
        return php.post('', {
            action_name: 'saveSaleWqRecord',
            ...data
        })
    }

    // 新增开票记录
    saveSaleKpRecord (data: saveSaleKpRecordReq) {
        return php.post('', {
            action_name: 'saveSaleKpRecord',
            ...data
        })
    }

    // 新增结佣记录
    saveSaleDzRecord (data: saveSaleDzRecordReq) {
        return php.post('', {
            action_name: 'saveSaleDzRecord',
            ...data
        })
    }

    // 新增返佣记录
    saveSaleFyRecord (data: saveSaleFyRecordReq) {
        return php.post('', {
            action_name: 'saveSaleFyRecord',
            ...data
        })
    }

    // 新增退房记录
    saveSaleTfRecord (data: saveSaleTfRecordReq) {
        return php.post('', {
            action_name: 'saveSaleTfRecord',
            ...data
        })
    }

    saveSaleTroubleRecord (data: saveSaleTroubleRecordReq) {
        return php.post('', {
            action_name: 'saveSaleTroubleRecord',
            ...data
        })
    }

    saveSaleRemark (data: saveSaleRemarkReq) {
        return java.post('/admin/houseSale/remark/saveHouseSaleServiceRemark', data)
    }

    // 删除记录
    deleteKpDzFyTfRecords (data: deleteRecordReq) {
        return php.post('', {
            action_name: 'deleteKpDzFyTfRecords',
            ...data
        })
    }

    // 新增提成发放记录
    saveSaleBonusRecord (data: saveSaleBonusRecordReq) {
        return php.post('', {
            action_name: 'saveSaleBonusRecord',
            ...data
        })
    }

    // 获取预付款
    getDepositRemainMoney (data: getDepositRemainMoneyReq): BaseRes<getDepositRemainMoneyRes> {
        return java.post('/admin/sale_partner/get/deposit/remain/money', data)
    }

    // 修改预计网签时间
    saveExpectWangQianTime (data: saveExpectWangQianTimeReq) {
        return java.post('/admin/user/house/sale/saveExpectWangQianTime', data)
    }

    // 获取销售面积
    getHouseSaleAreaBuild (data: getHouseSaleAreaBuildReq) {
        return java.post('/admin/user/house/sale/getHouseSaleAreaBuild', data)
    }

    /* 战队提成相关 */

    // 战队提成发放列表
    getCommisionRecord (data: getCommisionRecordReq): BaseRes<getCommisionRecordRes[]> {
        return java.post('/admin/commision/getCommisionRecord', data)
    }

    // 提成发放明细
    getCommisionSendRecord (data: getCommisionSendRecordReq): BaseRes<ListRes<getCommisionSendRecordRes>> {
        return java.post('/admin/commision/getCommisionSendRecord', data)
    }

    // 提成发放
    commisionSend (data: commisionSendReq) {
        return java.post('/admin/commision/commisionSend', data)
    }
}
