import { Component, Vue } from 'vue-property-decorator'
import { __utils } from '@/util'
import monthPicker from './monthPicker/index.vue'
import { __store } from '@/store'

function formatDate (date: Date, start = true) {
    let res = __utils.dayjs(date)
    const of = start ? res.startOf : res.endOf
    res = of.call(res, 'month')
    res = of.call(res, 'date')
    return res.format('YYYY-MM-DD HH:mm:ss')
}

@Component({
    components: {
        monthPicker
    }
})
export default class RankComponent extends Vue {
    alert = {
        show: false
    }

    get
    year () {
        return this.monthPicker.time[0].getFullYear()
    }

    get
    startMonth () {
        return this.monthPicker.time[0].getMonth() + 1
    }

    get
    endMonth () {
        return this.monthPicker.time[1].getMonth() + 1
    }

    get
    deps () {
        const dep = this.__store.state.user.userInfo.dep
        const xsDep = this.__utils.index.getXsDep().filter(a => !dep || a.value === dep)
        if (dep) {
            return xsDep
        }
        return [
            ...xsDep,
            {
                value: 0,
                label: '总排行榜'
            }
        ]
    }

    dep = __store.state.user.userInfo.dep
    selectDep () {
        this.monthPicker.show = false
        this.dimension.active = 0
        this.getData()
    }

    monthPicker = {
        show: false,
        time: [__utils.dayjs().toDate(), __utils.dayjs().toDate()]
    }
    showMonthPicker () {
        this.monthPicker.show = !this.monthPicker.show
    }
    confirmPicker (dates: Date[]) {
        this.monthPicker.time = dates
        this.getData()
    }

    created () {
        this.getData()
    }

    toggleAlert () {
        this.alert.show = !this.alert.show
    }

    showZq (index: number) {
        return index !== 2 || this.dep === 0
    }

    dimension = {
        active: 0,
        list: ['个人', '战队', '战区']
    }

    selectDimension (index: number) {
        if (this.dimension.active === index) return

        this.dimension.active = index
        this.getData()
    }

    table: {
        showMy?: boolean
        rank: string
        dimension: string
        money: string
    }[] = []

    get getDimensionLabel () {
        if (this.dimension.active === 0) return '分析师'
        return this.dimension.list[this.dimension.active]
    }

    getRankData () {
        return {
            type: 'month',
            start_time: formatDate(this.monthPicker.time[0]),
            end_time: formatDate(this.monthPicker.time[1], false),
            dep_id: this.dep
        }
    }

    async getPersonalRankList () {
        const { data } = await this.__apis.user.getPersonalRankList(this.getRankData())
        this.table = data.list.map((a, i) => {
            return {
                rank: (i + 1).toString(),
                dimension: a.user_name,
                money: `${a.total_money}元`
            }
        })

        // 我是分析师则添加
        if (data.is_show) {
            const myRank = this.table.filter(a => a.dimension === this.__store.state.user.userInfo.username)[0]
            this.table.push({
                showMy: true,
                rank: myRank ? myRank.rank : '未上榜',
                dimension: this.__store.state.user.userInfo.username,
                money: `${data.total_money}元`
            })
        }
    }

    async getGroupRankList () {
        const { data } = await this.__apis.user.getGroupRankList(this.getRankData())

        this.table = data.map((a, i) => {
            const group = this.__utils.index.getGroupInfoById(a.group)
            return {
                rank: (i + 1).toString(),
                dimension: group.name,
                money: `${a.total_money}元`
            }
        })

        const table = this.table
        this.table = this.table.slice(0, 10)
        const group = this.__utils.index.getGroupInfoById(this.__store.state.user.userInfo.group)

        if (group.dep) {
            const myRank = table.filter(a => a.dimension === group.name)[0]
            if (!myRank) return

            this.table.push({
                showMy: true,
                rank: Number(myRank.rank) <= 10 ? myRank.rank : '未上榜',
                dimension: myRank.dimension,
                money: myRank.money
            })
        }
    }

    async getDepRankList () {
        const { data } = await this.__apis.user.getDepRankList(this.getRankData())

        this.table = data.map((a, i) => {
            const dep = this.__utils.index.getDepInfoById(a.dep).v
            return {
                rank: (i + 1).toString(),
                dimension: dep,
                money: `${a.total_money}元`
            }
        })
    }

    async getData () {
        await [this.getPersonalRankList, this.getGroupRankList, this.getDepRankList][this.dimension.active]()

        let i = this.table.length
        for (i; i < 10; i++) {
            this.table.push({
                rank: (i + 1).toString(),
                dimension: '-',
                money: '-'
            })
        }
    }
}
