import { Component, Vue } from 'vue-property-decorator'
import inputSelect from '@/components/filters/inputSelect/index.vue'
import FilterComponent from '@/components/filters/filter/index.vue'
import { Table } from '@/types'

interface TableItem {
    id: number
    houseName: string
    userName: string
    hyDate: string
    hyMoney: string
}

interface Filter {
    id?: number
}

@Component({
    components: {
        inputSelect,
        FilterComponent
    }
})
export default class FriendUsePage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0
    }

    filter = {}

    created () {
        this.getData()
    }

    async getData () {
        const { data } = await this.__apis.channelManage.getSalePartnerDepositUseRecordPageList({
            partner_id: +this.$route.params.id,
            ...this.filter,
            page: this.tableData.page,
            pagesize: this.tableData.pageSize
        })
        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.id,
                houseName: a.house_name,
                userName: a.user_info.user_name,
                hyDate: this.__utils.index.formatDate(new Date(a.ctime), 'YYYY-MM-DD'),
                hyMoney: a.money
            }
        })
    }

    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1
        this.getData()
    }

    currentChange (page: number) {
        this.tableData.page = page
        this.getData()
    }
}
