import { Component, Vue } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import { Table } from '@/types'

interface TableItem {
    department: string
    group: string
    fxs: string
    month: string
    commission: string
}

interface Filter {
    [index: string]: string[]
    time: string[]
}

@Component({
    components: {
        FilterComponent,
        InputDateRangeComponent
    }
})
export default class CommissionDetailComponent extends Vue {
    filter: Filter = {
        time: []
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 50
    }

    created () {
        this.getTableData()
    }

    async getTableData () {
        const { data } = await this.__apis.wallet.getUnSendBonusRecordList({
            employee_id: Number(this.$route.query.id),
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            time_start: this.filter.time[0],
            time_end: this.filter.time[1]
        })

        const all = this.tableData.data[0]
        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                department: a.UserInfo.DepartmentName,
                group: a.UserInfo.GroupName,
                fxs: a.UserInfo.UserName,
                commission: this.__utils.index.formatMoney(a.Money),
                month: a.ActionTime.slice(0, 7)
            }
        })

        const stat = data.statistic_res ? {
            department: '汇总',
            group: '',
            fxs: '',
            month: '',
            commission: this.__utils.index.formatMoney(Number(data.statistic_res))
        } : all
        this.tableData.data.unshift(stat)
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        if (!this.filter.time) {
            this.filter.time = []
        } else {
            this.filter.time = this.__utils.index.formatTimeStartTimeEnd(this.filter.time)
        }

        this.getTableData()
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }
}
