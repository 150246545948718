import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import UploadImg from '@/components/UploadImg/index.vue'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'
import router from '@/router'
import { customerListRes } from '@/api/customer'
import { BaseRes } from '@/api'

@Component({
    components: {
        UploadImg,
        inputSelectSearch
    }
})
export default class saleDialogComponent extends Vue {
    @Prop({ default: false })
    visible!:boolean

    @Prop({ default () { return {} } })
    data!:any

    @Prop({ default: '' })
    savetext!:string

    dialogFormVisible = false
    formLabelWidth = '136px'
    formChengJiao: any = {
        customer_refer_text: '',
        bind_customer_id: 0,
        refer: 0,
        sale_type: 2,
        customer_id: '',
        customer_name: '',
        customer_tel: '',
        house_name: '',
        house_id: '',
        house_money: '',
        house_no: '',
        sum_money: '',
        sum_must_dz_money: '',
        sale_time: '',
        remark: '',
        user_info: [],
        channel_partner_id: '',
        channel_name: '',
        channel_username: '',
        channel_tel: '',
        zygw_name: '',
        zygw_tel: '',
        fy_money: '',
        fy_remark: '',
        zm: [
            { index: 0, type: 'bb', text: '报备截图', url: [] },
            { index: 1, type: 'qd', text: '签到单', url: [] },
            { index: 2, type: 'sk', text: '刷卡单', url: [] },
            { index: 3, type: 'rg', text: '认购书', url: [] },
            { index: 4, type: 'sf', text: '客户身份证', url: [] }
        ],
        qudao: '',
        expect_wangqian_time: '',
        building_area: ''
    }
    rules = {
        building_area: [
            { type: 'string', required: true, message: '请输入建筑面积', trigger: 'change' }
        ],
        sale_time: [
            { type: 'string', required: true, message: '请选择日期', trigger: 'change' }
        ],
        house_name: [
            { type: 'string', required: true, message: '请输入楼盘', trigger: 'change' }
        ],
        house_money: [
            { type: 'string', required: true, message: '请输入金额', trigger: 'change' }
        ],
        house_no: [
            { type: 'string', required: true, message: '请输入房号', trigger: 'change' }
        ],
        sum_money: [
            { type: 'string', required: true, message: '请输入金额', trigger: 'change' }
        ],
        sum_must_dz_money: [
            { type: 'string', required: true, message: '请输入应到账金额', trigger: 'change' }
        ],
        zygw_name: [
            { type: 'string', required: true, message: '请输入置业顾问', trigger: 'change' }
        ],
        qudao: [
            { type: 'string', required: true, message: '请输入渠道', trigger: 'change' }
        ],
        zm: [
            { type: 'array', required: true, message: '请上传文字中对应的图片', trigger: 'change' }
        ],
        customer_name: [
            { type: 'string', required: true, message: '请输入客户姓名', trigger: 'change' }
        ],
        user_info: [
            { type: 'array', required: true, message: '请输入并选择成交人', trigger: 'change' }
        ],
        fy_remark: [
            { type: 'string', validator: this.fyMoneyValid, message: '请输入返佣备注', trigger: 'change' }
        ]
    }

    pickerOptions:any = {
        disabledDate (time:any) {
            let _now = Date.now()
            // 大于当前的禁止
            return time.getTime() > _now
        }
    }

    fyMoneyValid (rule:any, value:any, callback:any) {
        console.log(222, value)
        if (
            this.formChengJiao.fy_money &&
            this.formChengJiao.fy_money > 0
        ) {
            value = value ? value.trim() : ''
            if ([undefined, null, ''].includes(value)) {
                callback(new Error(rule.message))
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    @Watch('data')
    dataChange (obj:any) {
        // console.log(111, obj)
        let zm = this.formChengJiao.zm
        obj.PzImgs = JSON.stringify(obj.PzImgs)
        obj.PzImgs = obj.PzImgs ? JSON.parse(obj.PzImgs) : {}
        Object.keys(obj.PzImgs).map((v:any) => {
            zm.map((a:any) => {
                if (a.type === v) {
                    a.url = [obj.PzImgs[v]]
                }
            })
        })
        this.formChengJiao = {
            customer_refer_text: '',
            bind_customer_id: obj.BindCustomerId,
            refer: +obj.BindCustomerId ? 1 : 0,
            sale_type: 2,
            id: obj.Id,
            customer_id: obj.CustomerId,
            customer_name: obj.CustomerName,
            customer_tel: obj.CustomerTel,
            house_name: obj.HouseName,
            house_id: obj.HouseId,
            house_money: obj.HouseMoney,
            house_no: obj.HouseNo,
            sum_money: obj.SumMoney,
            sum_must_dz_money: obj.SumMustDzMoney,
            sale_time: obj.SaleTime,
            remark: obj.Remark,
            user_info: obj.ExtData ? obj.ExtData.user_info : [],
            channel_name: obj.ChannelName,
            channel_partner_id: obj.ChannelPartnerId,
            channel_username: obj.ChannelUsername,
            channel_tel: obj.ChannelTel,
            zygw_name: obj.ZygwName,
            zygw_tel: obj.ZygwTel,
            zm: zm,
            qudao: obj.ChannelName,
            fy_money: obj.FyMoney,
            fy_remark: obj.FyRemark,
            expect_wangqian_time: obj.ExpectWangqianTime,
            building_area: obj.BuildingArea
        }
        // console.log(33, obj.BindCustomerId, obj.ChannelPartnerId, this.formChengJiao)
        this.formChengJiao.channel_partner_id = obj.ChannelPartnerId
        Number(obj.BindCustomerId) > 0 && this.getCustomerDetail()
    }

    get
    sumMoney () {
        return String(this.formChengJiao.sum_must_dz_money - (this.formChengJiao.fy_money || 0))
    }

    @Watch('formChengJiao.sum_must_dz_money')
    mustMoneyChange () {
        this.formChengJiao.sum_money = this.sumMoney
    }

    @Watch('formChengJiao.fy_money')
    fyMoneyChange () {
        this.formChengJiao.sum_money = this.sumMoney
    }

    @Watch('formChengJiao.refer')
    referChange () {
        this.handleClearReferClick()
    }

    async querySearchPhone (queryString: string, cb: Function) {
        if (!queryString || queryString.length < 3) {
            // eslint-disable-next-line standard/no-callback-literal
            cb([])
            return
        }
        const tasks:BaseRes<customerListRes>[] = []
        tasks.push(this.__apis.customer.customerList({
            type: 'my',
            mobile: queryString,
            page: 1,
            pagesize: 100
        }))
        tasks.push(this.__apis.customer.customerList({
            type: 'share_in',
            mobile: queryString,
            page: 1,
            pagesize: 100
        }))
        tasks.push(this.__apis.customer.customerList({
            type: 'share_out',
            mobile: queryString,
            page: 1,
            pagesize: 100
        }))
        tasks.push(this.__apis.customer.customerList({
            type: 'my_followed',
            mobile: queryString,
            page: 1,
            pagesize: 100
        }))
        // 部门公池
        tasks.push(this.__apis.customer.customerList({
            type: 'pubpool',
            mobile: queryString,
            page: 1,
            pagesize: 100
        }))
        // 战队公池
        tasks.push(this.__apis.customer.customerList({
            type: 'pubpool',
            groupPubPool: 1,
            mobile: queryString,
            page: 1,
            pagesize: 100
        }))
        const ret = await Promise.all(tasks)
        const list: any[] = []
        const ids: number[] = []
        ret.forEach(v => {
            const res = v.data.res
            res.customers.filter(v => !v.type_array.includes('转介绍')).forEach(v => {
                if (ids.includes(v.id) || ids.length > 100) {
                    return
                }
                list.push(v)
                ids.push(v.id)
            })
        })
        cb(list.map(v => {
            return {
                id: v.id,
                name: v.name,
                mobile_fixed: v.mobile_fixed,
                u_channel: v.u_channel
            }
        }))
    }

    handleSelectPhone (item:any) {
        console.log(22, item)
        this.formChengJiao.customer_refer_text = `${item.mobile_fixed}/${item.name}/${item.u_channel}`
        this.formChengJiao.bind_customer_id = item.id
    }

    handleClearReferClick () {
        this.formChengJiao.bind_customer_id = 0
        this.formChengJiao.customer_refer_text = ''
        let autocompletezjs = this.$refs.autocompletezjs as any
        if (autocompletezjs) autocompletezjs.suggestions = []
    }

    // 客户信息
    async getCustomerDetail () {
        let { data } = await this.__apis.customer.customerDetail(this.formChengJiao.bind_customer_id)
        this.formChengJiao.customer_refer_text = `${data.mobile_fixed}/${data.name}/${data.u_channel}`
        this.formChengJiao.bind_customer_id = data.id
    }

    // 添加成交人
    addCjUser () {
        this.formChengJiao.user_info.push({
            name: '',
            money: '',
            user_id: ''
        })
    }

    // del成交人
    delCjUser (index: number) {
        this.formChengJiao.user_info.splice(index, 1)
    }

    // 成交人选择
    nameSelect (item: any, i: number) {
        console.log(222, item)
        if (item.Id) {
            this.formChengJiao.user_info[i].user_id = item.Id
            this.formChengJiao.user_info[i].name = item.label
        }
        console.log(555, this.formChengJiao.user_info[i].name)
    }

    // 房源项目选择
    HouseSelect (obj: any, key:string, form: any) {
        form.house_name = obj.label
        form[key] = obj.id
    }

    // 渠道搜索选择
    qudaoSelect (obj: any, key:string, form: any) {
        form[key] = obj.id
    }

    // 图片上传返回
    uploadImgRes (res:any, item:any) {
        item.url = res
    }

    // 关闭弹窗事件
    closeDialog () {
        this.$emit('update:visible', false)
    }

    limitMoneyInput (e: string, key: 'building_area', hasMinus = false) {
        let minus = e[0] === '-' && hasMinus ? '-' : ''

        this.formChengJiao[key] = minus + this.__utils.index.limitMoneyByInput(e, 1000000)
    }

    // 编辑保存
    async saveForm () {
        const form = this.$refs['formChengJiao'] as any
        form.validate(async (valid: any) => {
            if (valid) {
                if (
                    !this.formChengJiao.house_id || this.formChengJiao.house_id.length === 0
                ) {
                    return this.$message({
                        message: '楼盘不存在，请重新选择成交楼盘!!',
                        type: 'warning'
                    })
                }
                if (this.formChengJiao.user_info.length === 0) {
                    return this.$message({
                        message: '请填写成交人!!',
                        type: 'warning'
                    })
                }
                let userBl = this.formChengJiao.user_info.every((v:any) => {
                    return (v.name && v.user_id)
                })
                if (!userBl) {
                    return this.$message({
                        message: '请填写并选择成交人!!',
                        type: 'warning'
                    })
                }

                // 成交分析师对应佣金（未填｜0）提示
                const hasUserNoMoney = this.formChengJiao.user_info.every((v:any) => {
                    return (v.name && v.user_id && v.money && +v.money !== 0)
                })
                if (!hasUserNoMoney) {
                    return this.$message({
                        message: '请为成交分析师完善成交佣金!!',
                        type: 'warning'
                    })
                }

                let sumTotal = 0
                this.formChengJiao.user_info.map((v: any) => {
                    sumTotal += v.money * 1
                })
                if (this.formChengJiao.sum_money * 1 !== sumTotal) {
                    return this.$message({
                        message: '佣金比例出错,请重新填写!!',
                        type: 'warning'
                    })
                }

                if (this.data.isZjs) {
                    if (this.formChengJiao.refer === 1) {
                        if (!this.formChengJiao.bind_customer_id) {
                            return this.$message({
                                message: '请输入并选择关联客户!!',
                                type: 'warning'
                            })
                        }
                    } else {
                        this.formChengJiao.bind_customer_id = 0
                    }
                } else {
                    delete this.formChengJiao.bind_customer_id
                }

                if (!this.formChengJiao.channel_partner_id) {
                    return this.$message({
                        message: '渠道不存在，请输入并选择渠道!',
                        type: 'warning'
                    })
                }

                let resZm: any = {}
                this.formChengJiao.zm.map((v: any) => {
                    resZm[v.type] = v.url[0] || ''
                })
                if (
                    !resZm['bb'] ||
                    !resZm['qd'] ||
                    !resZm['sk']
                ) {
                    return this.$message({
                        message: '请上传成交凭证!!',
                        type: 'warning'
                    })
                }

                if (
                    this.formChengJiao.fy_money &&
                    this.formChengJiao.fy_money > 0
                ) {
                    if ([undefined, null, ''].includes(this.formChengJiao.fy_remark)) {
                        return this.$message({
                            message: '请填写返佣备注!',
                            type: 'warning'
                        })
                    }
                }

                let formData = this.__utils.index.copyObj(this.formChengJiao)
                let data:any = {}
                Object.assign(data, formData)

                data.user_info = JSON.stringify(data.user_info)
                data.pz_imgs = JSON.stringify(resZm)
                data.channel_name = data.qudao

                delete data.qudao
                delete data.zm
                delete data.customer_refer_text
                delete data.refer

                await this.saveQuest(data)
            } else {
                console.log('error submit!!')
                return false
            }
        })
    }

    async saveQuest (data:any) {
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)'
        })
        try {
            await this.__apis.customer.addSaleRecordForExamine(data)
            let isCustomerDetailPage = this.$route.name !== '/customer/detail'
            this.$notify({
                title: '成功',
                message: isCustomerDetailPage ? '保存成功，等待审核！' : '保存成功，等待审核，即将跳转到审核页面！',
                type: 'success'
            })
            const formChengJiao = this.$refs['formChengJiao'] as any
            formChengJiao.resetFields()
            this.formChengJiao.zygw_tel = ''
            this.formChengJiao.zm.map((v: any) => {
                v.url = []
            })
            this.closeDialog()
            this.$emit('save')
            setTimeout(() => {
                if (!isCustomerDetailPage) {
                    router.replace('/saleman/record?type=1')
                }
            }, 3000)
        } catch (e) {
            throw e
        } finally {
            loading.close()
        }
    }
}
