import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { AnswerStatus, QuestionType, QuestionTypeEnum } from '@/util/const'
import { storageName } from '@/localStorage'
import { problemInfo, problemItem } from '../type'
import { UserCommitTypes } from '@/store/user'
import seeQuestionComponent from '../see'
import endDialogComponent from './end/index.vue'

@Component({
    components: {
        seeQuestionComponent,
        endDialogComponent
    }
})
export default class EditAnswerComponent extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    visible!: boolean

    loading = true

    isFilterStorage = false

    problemInfo: problemInfo = {
        current: 0,
        data: [],
        date: '',
        id: 0
    }

    questionTime: {
        timer: number | null,
        time: number
    } = {
        timer: null,
        time: 0
    }

    questionStatus = 1

    start = false

    endDialogComponent = {
        visible: false,
        title: '',
        content: ''
    }

    get
    problemCurrentItem () {
        return this.problemInfo.data.length ? this.problemInfo.data[this.problemInfo.current] : {
            id: 0,
            qType: 1,
            type: '',
            title: '',
            options: [],
            answer: [],
            userAnswer: [],
            yes: false,
            showRes: false
        }
    }

    get
    isEnd () {
        const problemInfo = this.problemInfo
        return problemInfo.current >= problemInfo.data.length - 1
    }

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    get
    currentData () {
        return this.__utils.index.formatDate(new Date(), 'YYYY-MM-DD')
    }

    get
    showQuestion () {
        return this.__store.state.user.showQuestion
    }

    get
    userAnswerText () {
        return this.problemCurrentItem.userAnswer.join(',')
    }

    get
    answerText () {
        return this.problemCurrentItem.answer.join(',')
    }

    @Watch('showQuestion')
    async showQuestionChange (val: boolean) {
        // 二次显示处理
        console.log('showQuestionChange', val)
        if (val && !this.start) {
            this.loading = false
            this.isFilterStorage = true
            await this.init()
            this.isFilterStorage = false
            if (this.questionStatus === AnswerStatus.yes) {
                this.__store.commit(UserCommitTypes.setSeeQuestionInfo, {
                    show: true,
                    item: {
                        taskId: this.problemInfo.id,
                        id: 0
                    }
                })
            }
            this.loading = true
        }
        if (this.start) {
            this.start = false
        }
    }

    created () {
        // this.init()
    }

    async init () {
        //  权限-分析师
        if (!this.__role.check(this.__role.permissions.ShowQuestionAnswerRecordPage)) {
            return
        }
        if (!this.isFilterStorage) {
            // 关闭过弹窗
            // if (!this.initStorageFilter(this.__localStorage.getLocalStorage(storageName.ANSWERCLOSE), storageName.ANSWERCLOSE)) {
            //     return
            // }
            // 完成过题库
            if (!this.initStorageFilter(this.__localStorage.getLocalStorage(storageName.ANSWERDATE), storageName.ANSWERDATE)) {
                return
            }
        }
        await this.initHasProblem()
        this.initQuestionTime()
    }

    initStorageFilter (str: string | null, key: storageName.ANSWERCLOSE | storageName.ANSWERDATE) {
        if (str) {
            const [userId, date] = str.split('/')
            if (
                +userId === this.userInfo.user_id &&
                date === this.currentData
            ) {
                return false
            } else {
                this.__localStorage.removeLocalStorage(key)
            }
            return true
        }
        return true
    }

    async initHasProblem () {
        const { data: { questionAnswerInfoList, taskAnswerPo } } = await this.__apis.activity.questionTaskInfo()
        if (!taskAnswerPo) return
        this.problemInfo.date = this.__utils.index.formatDate(new Date(taskAnswerPo.create_time), 'YYYY-MM-DD')
        this.problemInfo.id = taskAnswerPo.id
        let unAnswer = false
        this.problemInfo.data = questionAnswerInfoList.map((a, index) => {
            const isManySel = [QuestionTypeEnum.manySelect].includes(a.question_type)
            const isPd = [QuestionTypeEnum.judgeSelect].includes(a.question_type)
            const content = a.items.map((v, i) => {
                const label = !isPd ? `${v.prefix}. ${v.content}` : v.content
                return {
                    label: label,
                    value: v.prefix
                }
            })
            const isAnswer = a.do_right === null // 未回答
            const correct = isManySel ? a.correct.split(',') : [a.correct] // 答案
            // 找到第一个未做的题目
            if (isAnswer && !unAnswer) {
                this.problemInfo.current = index
                unAnswer = true
            }
            return {
                id: a.question_id,
                type: this.__utils.index.getLabelByValue(QuestionType, a.question_type),
                qType: a.question_type,
                title: a.title,
                options: content,
                answer: correct,
                userAnswer: isAnswer ? [] : a.answer.split(','),
                yes: isAnswer ? false : (a.answer === correct.join(',')),
                showRes: !isAnswer
            }
        })
        if (taskAnswerPo.status === AnswerStatus.no) {
            this.start = true
            this.__store.commit(UserCommitTypes.setShowQuestion, true)
        } else {
            this.__store.commit(UserCommitTypes.setShowQuestion, false)
            this.__store.commit(UserCommitTypes.setResolveQuestion, true)
            this.__localStorage.setLocalStorage(storageName.ANSWERDATE, `${taskAnswerPo.create_user}/${this.problemInfo.date}`)
        }
        this.questionStatus = taskAnswerPo.status
    }

    initQuestionTime () {
        this.questionTime.timer && clearInterval(this.questionTime.timer)
        this.questionTime.timer = setInterval(() => {
            this.questionTime.time += 1
        }, 1000)
    }

    later () {
        this.close()
        this.__localStorage.setLocalStorage(storageName.ANSWERCLOSE, `${this.userInfo.user_id}/${this.currentData}`)
    }

    answerClick (value: string) {
        const problemCurrentItem = this.problemCurrentItem
        if (problemCurrentItem.showRes) return
        if ([QuestionTypeEnum.manySelect].includes(problemCurrentItem.qType)) {
            const index = problemCurrentItem.userAnswer.indexOf(value)
            if (index > -1) {
                this.problemCurrentItem.userAnswer.splice(index, 1)
            } else {
                this.problemCurrentItem.userAnswer.push(value)
            }
        } else {
            this.problemCurrentItem.userAnswer = [value]
        }
    }

    btnEnterDisabled (item: problemItem) {
        return !item.userAnswer.length
    }

    async next () {
        if (this.isEnd) {
            this.close()
            this.__store.commit(UserCommitTypes.setResolveQuestion, true)
            this.__localStorage.setLocalStorage(storageName.ANSWERDATE, `${this.userInfo.user_id}/${this.problemInfo.date}`)
            await this.showEndDialog()
        } else {
            this.problemInfo.current += 1
        }
    }

    async showEndDialog () {
        const { data: answerCount } = await this.__apis.activity.questionThisWeekAnswer()
        const data = this.problemInfo.data
        const rightCount = data.filter(a => !a.yes).length
        this.endDialogComponent = {
            visible: true,
            title: `恭喜你完成了第${answerCount}组练习`,
            content: `发现了${rightCount}个你不熟悉的知识，系统会为你优化练习方案`
        }
    }

    async enter (item: problemItem) {
        if (this.__utils.index.limitRepeatClick()) return
        try {
            const isManySelect = item.qType === QuestionTypeEnum.manySelect
            await this.__apis.activity.questionAnswer({
                task_answer_id: this.problemInfo.id,
                question_id: item.id,
                content: !isManySelect ? item.userAnswer.join(',') : undefined,
                contentArray: isManySelect ? item.userAnswer : undefined
            })
            item.yes = item.answer.sort().join(',') === item.userAnswer.sort().join(',')
            item.showRes = true
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    close () {
        this.__store.commit(UserCommitTypes.setShowQuestion, false)
    }

    destroyed () {
        this.questionTime.timer && clearInterval(this.questionTime.timer)
    }

    hideQuestionEndDialog () {
        this.endDialogComponent.visible = false
    }
}
