import { Component, Prop, Vue } from 'vue-property-decorator'
import { Notification } from 'element-ui'
import { FriendStatus } from '@/util'
import { searchDuiJieUserByNameRes } from '@/api/channelManage'

type people = {
    id: number
    tel: string
    job: string
    note: string
}

@Component
export default class FriendManageAddFriend extends Vue {
    @Prop({
        type: Boolean,
        default () {
            return false
        }
    })
    show !: boolean

    get
    btnAddDisabled () {
        return !(this.friendName.trim() && this.friendSimpleName.trim() && this.status.value && this.intro.trim() && this.housePeople.filter(a => a.id).length)
    }

    housePeople: people[] = [{
        id: undefined as any,
        tel: '',
        job: '',
        note: ''
    }]

    friendName = '' // 合作商全称

    friendSimpleName = '' // 合作商简称

    status: {
        data: {
            value: number
            label: string
        }[]
        value: undefined | number
    } = {
        value: undefined,
        data: FriendStatus
    } // 合作状态

    intro = ''

    addHousePeople () {
        this.housePeople.push({
            id: undefined as any,
            tel: '',
            job: '',
            note: ''
        })
    }

    duijieUsersBySearch: searchDuiJieUserByNameRes = []

    // 搜索对接人
    async searchDuijieUser (word: string) {
        const { data } = await this.__apis.channelManage.searchDuiJieUserByName(word)
        this.duijieUsersBySearch = data
    }

    // 选择对接人 - 填充手机号
    selectDuijieUser (item: people) {
        const data = this.duijieUsersBySearch.filter(a => a.id === Number(item.id))[0]
        if (!data) return

        item.tel = data.tel
    }

    removeHousePeople (index: number) {
        this.housePeople.splice(index, 1)
    }

    async save () {
        if (this.__utils.index.limitRepeatClick()) return

        try {
            await this.__apis.channelManage.saveSalePartner({
                name: this.friendSimpleName,
                full_name: this.friendName,
                dep: this.__store.state.user.userInfo.dep,
                introduction: this.intro,
                status: this.status.value,
                duijie_user: JSON.stringify(this.housePeople.map(a => {
                    return {
                        id: a.id,
                        note: a.note,
                        job: a.job
                    }
                }))
            })

            // 保存成功
            Notification.success('保存成功')
            this.$emit('change')

            this.addHousePeople()
            this.cancel()
        } catch (e) {
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    cancel () {
        // 修改父级 show 的值
        this.$emit('update:show', false)
    }
}
