import { Component, Vue, Watch } from 'vue-property-decorator'
import tabsComponent from '@/components/wallet/tabs/index.vue'
import { items } from '@/components/wallet/tabs'
import { __store } from '@/store'
import { UserDispatchType } from '@/store/user'

@Component({
    components: {
        tabsComponent
    }
})
export default class IndexPage extends Vue {
    @Watch('__store.state.user.financialReviewCount')
    watchReviewCount () {
        if (this.tabsComponent.items[1]) {
            this.tabsComponent.items[1].count = __store.state.user.financialReviewCount
        }
    }

    tabsComponent: {
        active: number
        items: items[]
    } = {
        active: 0,

        items: [
            {
                text: '个人钱包',
                url: ['/financial/wallet'],
                authKey: this.__role.permissions.ShowFinancialWalletPersonPage
            },
            {
                text: '提现审核',
                url: ['/financial/wallet/review'],
                count: 0,
                authKey: this.__role.permissions.ShowFinancialWalletReviewPage
            },
            {
                text: '战队钱包',
                url: ['/financial/wallet/group', '/financial/wallet/group-detail'],
                authKey: this.__role.permissions.ShowFinancialWalletTeamPage
            }
        ]
    }

    async created () {
        this.__store.dispatch(UserDispatchType.setFinancialReviewCount)
        this.tabsComponent.items[1].count = this.__store.state.user.financialReviewCount
    }
}
