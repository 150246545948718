import FilterComponent from '@/components/filters/filter/index.vue'
import inputDateRange from '@/components/filters/inputDateRange/index.vue'
import InputSelectComponent from '@/components/filters/inputSelect/index.vue'
import selectComponent from '@/components/filters/select/index.vue'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'
import FilterNumberComponent from '@/components/filters/number/index.vue'
import DateUtils from '@/util/date.ts'
import { Component, Vue } from 'vue-property-decorator'

@Component({
    components: {
        FilterComponent,
        inputDateRange,
        InputSelectComponent,
        selectComponent,
        CheckBoxComponent,
        FilterNumberComponent,
        FilterBusSelectComponent,
        FilterBusInputSelectComponent
    }
})
export default class FxsPhoneRecordPage extends Vue {
    dataObj:any = {
        list: [],
        total: 0
    }
    total = 0
    billsec = ''
    page= 1
    pageSize= 20
    filter = {}
    phoneStatus= [
        {
            label: '接通',
            value: 1
        },
        {
            label: '未接通',
            value: 2
        }
    ]
    phoneRecordVisible= false
    phoneRecordInfo= {
        url: ''
    }
    get infoPos () {
        return this.__store.state.user.infoPos
    }
    mounted () {
        this.getData()
        this.getData(true)
    }
    // 秒转时分秒
    formatSeconds (value:any) {
        let result:any = parseInt(value)
        let h = Math.floor(result / 3600)
        let m = Math.floor((result / 60 % 60))
        let s = Math.floor((result % 60))
        result = [h, m, s].map(v => v < 10 ? '0' + v : v).join(':')
        return result
    }
    // 获取数据
    async getData (aggregate = false) {
        const filter = JSON.parse(JSON.stringify(this.filter)) || {}
        const defaultDate:any = DateUtils.getCurrentYearBeforeMonth()

        filter.filters = filter.filters || []

        const res = await this.__apis.phoneRecord.getPhoneRecordList({
            start_date: filter.start_date || DateUtils.format(defaultDate[0], 'yyyy-MM-dd'),
            end_date: filter.start_date || DateUtils.format(defaultDate[1], 'yyyy-MM-dd'),
            page: this.page,
            pagesize: this.pageSize,
            aggregate: aggregate, 	// 获取聚合结果，以及比较真实的total数
            ...filter
        })

        if (aggregate) {
            this.total = res.total_found
            this.billsec = this.formatSeconds(res.rows[0].billsec)
            return
        }

        res.rows && res.rows.map((v) => {
            v.created_time = new Date(v.created_time).format('yyyy-MM-dd hh:mm')
            v.billsec = this.formatSeconds(v.billsec)
            v.departmentStr = this.__utils.index.getDepInfoById(v.department).v
            v.teamStr = this.__utils.index.getGroupInfoById(v.team).name
        })

        this.dataObj = res
        this.total = res.total_found
    }
    // 分页改变
    pageChange (page:number) {
        this.page = page
        this.getData()
    }
    // 筛选弹窗相关
    filterChange (data:any) {
        console.log('filterChange', data)
        let filter = []
        for (let key in data) {
            if (key === 'date') {
                data['start_date'] = data[key][0]
                data['end_date'] = data[key][1]
            } else {
                let typeKey:any = {
                    id: 19,
                    caller_id: 20,
                    middle_id: 20,
                    callee_id: 20,
                    status: data[key] === 1 ? 21 : 22,
                    billsec: 11
                }
                let valueArr = [data[key]]
                if (key === 'status') {
                    valueArr = (data[key] === '' ? [''] : ['NORMAL_CLEARING'])
                } else if (key === 'billsec') {
                    valueArr = data[key]
                } else if ([19].includes(typeKey[key])) {
                    valueArr = [+data[key]]
                }
                filter.push({
                    'column': key,
                    'type': typeKey[key] || 19,
                    'value': valueArr
                })
            }
            delete data[key]
        }
        data.filters = filter
        this.filter = data
        this.page = 1
        this.getData()
        this.getData(true)
    }
    // 战队数据
    queryGroupSearch (queryString:string, cb:any) {
        this.__utils.index.searchGroup(queryString, cb)
    }
    // 音频播放
    async RecordEdit (item:any) {
        const res = await this.__apis.phoneRecord.getPhoneRecordInfo(item.id)
        this.phoneRecordInfo = {
            url: res.record_audio.url
        }
        this.phoneRecordVisible = true
    }
    phoneRecordCancel () {
        this.phoneRecordVisible = false
    }
}
