
export interface PlaceItem {
    label: string
    value: string
    pid: string
    type: number
    children?: PlaceItem[]
}

const placesJson: PlaceItem[] = [
    {
        'value': '33',
        'label': '浙江省',
        'pid': '1',
        'type': 1,
        'children': [{
            'value': '3301',
            'label': '杭州市',
            'pid': '33',
            'type': 2,
            'children': [{
                'value': '330102',
                'label': '上城区',
                'pid': '3301',
                'type': 3
            }, {
                'value': '330103',
                'label': '下城区',
                'pid': '3301',
                'type': 3
            }, {
                'value': '330104',
                'label': '江干区',
                'pid': '3301',
                'type': 3
            }, {
                'value': '330105',
                'label': '拱墅区',
                'pid': '3301',
                'type': 3
            }, {
                'value': '330106',
                'label': '西湖区',
                'pid': '3301',
                'type': 3
            }, {
                'value': '330108',
                'label': '滨江区',
                'pid': '3301',
                'type': 3
            }, {
                'value': '330109',
                'label': '萧山区',
                'pid': '3301',
                'type': 3
            }, {
                'value': '330110',
                'label': '余杭区',
                'pid': '3301',
                'type': 3
            }, {
                'value': '330111',
                'label': '富阳区',
                'pid': '3301',
                'type': 3
            }, {
                'value': '330122',
                'label': '桐庐县',
                'pid': '3301',
                'type': 3
            }, {
                'value': '330127',
                'label': '淳安县',
                'pid': '3301',
                'type': 3
            }, {
                'value': '330182',
                'label': '建德市',
                'pid': '3301',
                'type': 3
            }, {
                'value': '330185',
                'label': '临安市',
                'pid': '3301',
                'type': 3
            }, {
                'value': '330112',
                'label': '钱塘区',
                'pid': '3301',
                'type': 3
            }, {
                'value': '330113',
                'label': '临平区',
                'pid': '3301',
                'type': 3
            }]
        }, {
            'value': '3302',
            'label': '宁波市',
            'pid': '33',
            'type': 2,
            'children': [{
                'value': '330203',
                'label': '海曙区',
                'pid': '3302',
                'type': 3
            }, {
                'value': '330204',
                'label': '江东区',
                'pid': '3302',
                'type': 3
            }, {
                'value': '330205',
                'label': '江北区',
                'pid': '3302',
                'type': 3
            }, {
                'value': '330206',
                'label': '北仑区',
                'pid': '3302',
                'type': 3
            }, {
                'value': '330211',
                'label': '镇海区',
                'pid': '3302',
                'type': 3
            }, {
                'value': '330212',
                'label': '鄞州区',
                'pid': '3302',
                'type': 3
            }, {
                'value': '330225',
                'label': '象山县',
                'pid': '3302',
                'type': 3
            }, {
                'value': '330226',
                'label': '宁海县',
                'pid': '3302',
                'type': 3
            }, {
                'value': '330281',
                'label': '余姚市',
                'pid': '3302',
                'type': 3
            }, {
                'value': '330282',
                'label': '慈溪市',
                'pid': '3302',
                'type': 3
            }, {
                'value': '330283',
                'label': '奉化市',
                'pid': '3302',
                'type': 3
            }]
        }, {
            'value': '3303',
            'label': '温州市',
            'pid': '33',
            'type': 2,
            'children': [{
                'value': '330302',
                'label': '鹿城区',
                'pid': '3303',
                'type': 3
            }, {
                'value': '330303',
                'label': '龙湾区',
                'pid': '3303',
                'type': 3
            }, {
                'value': '330304',
                'label': '瓯海区',
                'pid': '3303',
                'type': 3
            }, {
                'value': '330305',
                'label': '洞头区',
                'pid': '3303',
                'type': 3
            }, {
                'value': '330324',
                'label': '永嘉县',
                'pid': '3303',
                'type': 3
            }, {
                'value': '330326',
                'label': '平阳县',
                'pid': '3303',
                'type': 3
            }, {
                'value': '330327',
                'label': '苍南县',
                'pid': '3303',
                'type': 3
            }, {
                'value': '330328',
                'label': '文成县',
                'pid': '3303',
                'type': 3
            }, {
                'value': '330329',
                'label': '泰顺县',
                'pid': '3303',
                'type': 3
            }, {
                'value': '330381',
                'label': '瑞安市',
                'pid': '3303',
                'type': 3
            }, {
                'value': '330382',
                'label': '乐清市',
                'pid': '3303',
                'type': 3
            }]
        }, {
            'value': '3304',
            'label': '嘉兴市',
            'pid': '33',
            'type': 2,
            'children': [{
                'value': '330402',
                'label': '南湖区',
                'pid': '3304',
                'type': 3
            }, {
                'value': '330411',
                'label': '秀洲区',
                'pid': '3304',
                'type': 3
            }, {
                'value': '330421',
                'label': '嘉善县',
                'pid': '3304',
                'type': 3
            }, {
                'value': '330424',
                'label': '海盐县',
                'pid': '3304',
                'type': 3
            }, {
                'value': '330481',
                'label': '海宁市',
                'pid': '3304',
                'type': 3
            }, {
                'value': '330482',
                'label': '平湖市',
                'pid': '3304',
                'type': 3
            }, {
                'value': '330483',
                'label': '桐乡市',
                'pid': '3304',
                'type': 3
            }]
        }, {
            'value': '3305',
            'label': '湖州市',
            'pid': '33',
            'type': 2,
            'children': [{
                'value': '330502',
                'label': '吴兴区',
                'pid': '3305',
                'type': 3
            }, {
                'value': '330503',
                'label': '南浔区',
                'pid': '3305',
                'type': 3
            }, {
                'value': '330521',
                'label': '德清县',
                'pid': '3305',
                'type': 3
            }, {
                'value': '330522',
                'label': '长兴县',
                'pid': '3305',
                'type': 3
            }, {
                'value': '330523',
                'label': '安吉县',
                'pid': '3305',
                'type': 3
            }]
        }, {
            'value': '3306',
            'label': '绍兴市',
            'pid': '33',
            'type': 2,
            'children': [{
                'value': '330602',
                'label': '越城区',
                'pid': '3306',
                'type': 3
            }, {
                'value': '330603',
                'label': '柯桥区',
                'pid': '3306',
                'type': 3
            }, {
                'value': '330604',
                'label': '上虞区',
                'pid': '3306',
                'type': 3
            }, {
                'value': '330624',
                'label': '新昌县',
                'pid': '3306',
                'type': 3
            }, {
                'value': '330681',
                'label': '诸暨市',
                'pid': '3306',
                'type': 3
            }, {
                'value': '330683',
                'label': '嵊州市',
                'pid': '3306',
                'type': 3
            }]
        }, {
            'value': '3307',
            'label': '金华市',
            'pid': '33',
            'type': 2,
            'children': [{
                'value': '330702',
                'label': '婺城区',
                'pid': '3307',
                'type': 3
            }, {
                'value': '330703',
                'label': '金东区',
                'pid': '3307',
                'type': 3
            }, {
                'value': '330723',
                'label': '武义县',
                'pid': '3307',
                'type': 3
            }, {
                'value': '330726',
                'label': '浦江县',
                'pid': '3307',
                'type': 3
            }, {
                'value': '330727',
                'label': '磐安县',
                'pid': '3307',
                'type': 3
            }, {
                'value': '330781',
                'label': '兰溪市',
                'pid': '3307',
                'type': 3
            }, {
                'value': '330782',
                'label': '义乌市',
                'pid': '3307',
                'type': 3
            }, {
                'value': '330783',
                'label': '东阳市',
                'pid': '3307',
                'type': 3
            }, {
                'value': '330784',
                'label': '永康市',
                'pid': '3307',
                'type': 3
            }]
        }, {
            'value': '3308',
            'label': '衢州市',
            'pid': '33',
            'type': 2,
            'children': [{
                'value': '330802',
                'label': '柯城区',
                'pid': '3308',
                'type': 3
            }, {
                'value': '330803',
                'label': '衢江区',
                'pid': '3308',
                'type': 3
            }, {
                'value': '330822',
                'label': '常山县',
                'pid': '3308',
                'type': 3
            }, {
                'value': '330824',
                'label': '开化县',
                'pid': '3308',
                'type': 3
            }, {
                'value': '330825',
                'label': '龙游县',
                'pid': '3308',
                'type': 3
            }, {
                'value': '330881',
                'label': '江山市',
                'pid': '3308',
                'type': 3
            }]
        }, {
            'value': '3309',
            'label': '舟山市',
            'pid': '33',
            'type': 2,
            'children': [{
                'value': '330902',
                'label': '定海区',
                'pid': '3309',
                'type': 3
            }, {
                'value': '330903',
                'label': '普陀区',
                'pid': '3309',
                'type': 3
            }, {
                'value': '330921',
                'label': '岱山县',
                'pid': '3309',
                'type': 3
            }, {
                'value': '330922',
                'label': '嵊泗县',
                'pid': '3309',
                'type': 3
            }]
        }, {
            'value': '3310',
            'label': '台州市',
            'pid': '33',
            'type': 2,
            'children': [{
                'value': '331002',
                'label': '椒江区',
                'pid': '3310',
                'type': 3
            }, {
                'value': '331003',
                'label': '黄岩区',
                'pid': '3310',
                'type': 3
            }, {
                'value': '331004',
                'label': '路桥区',
                'pid': '3310',
                'type': 3
            }, {
                'value': '331021',
                'label': '玉环县',
                'pid': '3310',
                'type': 3
            }, {
                'value': '331022',
                'label': '三门县',
                'pid': '3310',
                'type': 3
            }, {
                'value': '331023',
                'label': '天台县',
                'pid': '3310',
                'type': 3
            }, {
                'value': '331024',
                'label': '仙居县',
                'pid': '3310',
                'type': 3
            }, {
                'value': '331081',
                'label': '温岭市',
                'pid': '3310',
                'type': 3
            }, {
                'value': '331082',
                'label': '临海市',
                'pid': '3310',
                'type': 3
            }]
        }, {
            'value': '3311',
            'label': '丽水市',
            'pid': '33',
            'type': 2,
            'children': [{
                'value': '331102',
                'label': '莲都区',
                'pid': '3311',
                'type': 3
            }, {
                'value': '331121',
                'label': '青田县',
                'pid': '3311',
                'type': 3
            }, {
                'value': '331122',
                'label': '缙云县',
                'pid': '3311',
                'type': 3
            }, {
                'value': '331123',
                'label': '遂昌县',
                'pid': '3311',
                'type': 3
            }, {
                'value': '331124',
                'label': '松阳县',
                'pid': '3311',
                'type': 3
            }, {
                'value': '331125',
                'label': '云和县',
                'pid': '3311',
                'type': 3
            }, {
                'value': '331126',
                'label': '庆元县',
                'pid': '3311',
                'type': 3
            }, {
                'value': '331127',
                'label': '景宁畲族自治县',
                'pid': '3311',
                'type': 3
            }, {
                'value': '331181',
                'label': '龙泉市',
                'pid': '3311',
                'type': 3
            }]
        }]
    },
    {
        'value': '44',
        'label': '广东省',
        'pid': '1',
        'type': 1,
        'children': [{
            'value': '4401',
            'label': '广州市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '440103',
                'label': '荔湾区',
                'pid': '4401',
                'type': 3
            }, {
                'value': '440104',
                'label': '越秀区',
                'pid': '4401',
                'type': 3
            }, {
                'value': '440105',
                'label': '海珠区',
                'pid': '4401',
                'type': 3
            }, {
                'value': '440106',
                'label': '天河区',
                'pid': '4401',
                'type': 3
            }, {
                'value': '440111',
                'label': '白云区',
                'pid': '4401',
                'type': 3
            }, {
                'value': '440112',
                'label': '黄埔区',
                'pid': '4401',
                'type': 3
            }, {
                'value': '440113',
                'label': '番禺区',
                'pid': '4401',
                'type': 3
            }, {
                'value': '440114',
                'label': '花都区',
                'pid': '4401',
                'type': 3
            }, {
                'value': '440115',
                'label': '南沙区',
                'pid': '4401',
                'type': 3
            }, {
                'value': '440117',
                'label': '从化区',
                'pid': '4401',
                'type': 3
            }, {
                'value': '440118',
                'label': '增城区',
                'pid': '4401',
                'type': 3
            }]
        }, {
            'value': '4402',
            'label': '韶关市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '440203',
                'label': '武江区',
                'pid': '4402',
                'type': 3
            }, {
                'value': '440204',
                'label': '浈江区',
                'pid': '4402',
                'type': 3
            }, {
                'value': '440205',
                'label': '曲江区',
                'pid': '4402',
                'type': 3
            }, {
                'value': '440222',
                'label': '始兴县',
                'pid': '4402',
                'type': 3
            }, {
                'value': '440224',
                'label': '仁化县',
                'pid': '4402',
                'type': 3
            }, {
                'value': '440229',
                'label': '翁源县',
                'pid': '4402',
                'type': 3
            }, {
                'value': '440232',
                'label': '乳源瑶族自治县',
                'pid': '4402',
                'type': 3
            }, {
                'value': '440233',
                'label': '新丰县',
                'pid': '4402',
                'type': 3
            }, {
                'value': '440281',
                'label': '乐昌市',
                'pid': '4402',
                'type': 3
            }, {
                'value': '440282',
                'label': '南雄市',
                'pid': '4402',
                'type': 3
            }]
        }, {
            'value': '4403',
            'label': '深圳市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '440303',
                'label': '罗湖区',
                'pid': '4403',
                'type': 3
            }, {
                'value': '440304',
                'label': '福田区',
                'pid': '4403',
                'type': 3
            }, {
                'value': '440305',
                'label': '南山区',
                'pid': '4403',
                'type': 3
            }, {
                'value': '440306',
                'label': '宝安区',
                'pid': '4403',
                'type': 3
            }, {
                'value': '440307',
                'label': '龙岗区',
                'pid': '4403',
                'type': 3
            }, {
                'value': '440308',
                'label': '盐田区',
                'pid': '4403',
                'type': 3
            }]
        }, {
            'value': '4404',
            'label': '珠海市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '440402',
                'label': '香洲区',
                'pid': '4404',
                'type': 3
            }, {
                'value': '440403',
                'label': '斗门区',
                'pid': '4404',
                'type': 3
            }, {
                'value': '440404',
                'label': '金湾区',
                'pid': '4404',
                'type': 3
            }]
        }, {
            'value': '4405',
            'label': '汕头市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '440507',
                'label': '龙湖区',
                'pid': '4405',
                'type': 3
            }, {
                'value': '440511',
                'label': '金平区',
                'pid': '4405',
                'type': 3
            }, {
                'value': '440512',
                'label': '濠江区',
                'pid': '4405',
                'type': 3
            }, {
                'value': '440513',
                'label': '潮阳区',
                'pid': '4405',
                'type': 3
            }, {
                'value': '440514',
                'label': '潮南区',
                'pid': '4405',
                'type': 3
            }, {
                'value': '440515',
                'label': '澄海区',
                'pid': '4405',
                'type': 3
            }, {
                'value': '440523',
                'label': '南澳县',
                'pid': '4405',
                'type': 3
            }]
        }, {
            'value': '4406',
            'label': '佛山市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '440604',
                'label': '禅城区',
                'pid': '4406',
                'type': 3
            }, {
                'value': '440605',
                'label': '南海区',
                'pid': '4406',
                'type': 3
            }, {
                'value': '440606',
                'label': '顺德区',
                'pid': '4406',
                'type': 3
            }, {
                'value': '440607',
                'label': '三水区',
                'pid': '4406',
                'type': 3
            }, {
                'value': '440608',
                'label': '高明区',
                'pid': '4406',
                'type': 3
            }]
        }, {
            'value': '4407',
            'label': '江门市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '440703',
                'label': '蓬江区',
                'pid': '4407',
                'type': 3
            }, {
                'value': '440704',
                'label': '江海区',
                'pid': '4407',
                'type': 3
            }, {
                'value': '440705',
                'label': '新会区',
                'pid': '4407',
                'type': 3
            }, {
                'value': '440781',
                'label': '台山市',
                'pid': '4407',
                'type': 3
            }, {
                'value': '440783',
                'label': '开平市',
                'pid': '4407',
                'type': 3
            }, {
                'value': '440784',
                'label': '鹤山市',
                'pid': '4407',
                'type': 3
            }, {
                'value': '440785',
                'label': '恩平市',
                'pid': '4407',
                'type': 3
            }]
        }, {
            'value': '4408',
            'label': '湛江市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '440802',
                'label': '赤坎区',
                'pid': '4408',
                'type': 3
            }, {
                'value': '440803',
                'label': '霞山区',
                'pid': '4408',
                'type': 3
            }, {
                'value': '440804',
                'label': '坡头区',
                'pid': '4408',
                'type': 3
            }, {
                'value': '440811',
                'label': '麻章区',
                'pid': '4408',
                'type': 3
            }, {
                'value': '440823',
                'label': '遂溪县',
                'pid': '4408',
                'type': 3
            }, {
                'value': '440825',
                'label': '徐闻县',
                'pid': '4408',
                'type': 3
            }, {
                'value': '440881',
                'label': '廉江市',
                'pid': '4408',
                'type': 3
            }, {
                'value': '440882',
                'label': '雷州市',
                'pid': '4408',
                'type': 3
            }, {
                'value': '440883',
                'label': '吴川市',
                'pid': '4408',
                'type': 3
            }]
        }, {
            'value': '4409',
            'label': '茂名市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '440902',
                'label': '茂南区',
                'pid': '4409',
                'type': 3
            }, {
                'value': '440904',
                'label': '电白区',
                'pid': '4409',
                'type': 3
            }, {
                'value': '440981',
                'label': '高州市',
                'pid': '4409',
                'type': 3
            }, {
                'value': '440982',
                'label': '化州市',
                'pid': '4409',
                'type': 3
            }, {
                'value': '440983',
                'label': '信宜市',
                'pid': '4409',
                'type': 3
            }]
        }, {
            'value': '4412',
            'label': '肇庆市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '441202',
                'label': '端州区',
                'pid': '4412',
                'type': 3
            }, {
                'value': '441203',
                'label': '鼎湖区',
                'pid': '4412',
                'type': 3
            }, {
                'value': '441204',
                'label': '高要区',
                'pid': '4412',
                'type': 3
            }, {
                'value': '441223',
                'label': '广宁县',
                'pid': '4412',
                'type': 3
            }, {
                'value': '441224',
                'label': '怀集县',
                'pid': '4412',
                'type': 3
            }, {
                'value': '441225',
                'label': '封开县',
                'pid': '4412',
                'type': 3
            }, {
                'value': '441226',
                'label': '德庆县',
                'pid': '4412',
                'type': 3
            }, {
                'value': '441284',
                'label': '四会市',
                'pid': '4412',
                'type': 3
            }]
        }, {
            'value': '4413',
            'label': '惠州市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '441302',
                'label': '惠城区',
                'pid': '4413',
                'type': 3
            }, {
                'value': '441303',
                'label': '惠阳区',
                'pid': '4413',
                'type': 3
            }, {
                'value': '441322',
                'label': '博罗县',
                'pid': '4413',
                'type': 3
            }, {
                'value': '441323',
                'label': '惠东县',
                'pid': '4413',
                'type': 3
            }, {
                'value': '441324',
                'label': '龙门县',
                'pid': '4413',
                'type': 3
            }]
        }, {
            'value': '4414',
            'label': '梅州市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '441402',
                'label': '梅江区',
                'pid': '4414',
                'type': 3
            }, {
                'value': '441403',
                'label': '梅县区',
                'pid': '4414',
                'type': 3
            }, {
                'value': '441422',
                'label': '大埔县',
                'pid': '4414',
                'type': 3
            }, {
                'value': '441423',
                'label': '丰顺县',
                'pid': '4414',
                'type': 3
            }, {
                'value': '441424',
                'label': '五华县',
                'pid': '4414',
                'type': 3
            }, {
                'value': '441426',
                'label': '平远县',
                'pid': '4414',
                'type': 3
            }, {
                'value': '441427',
                'label': '蕉岭县',
                'pid': '4414',
                'type': 3
            }, {
                'value': '441481',
                'label': '兴宁市',
                'pid': '4414',
                'type': 3
            }]
        }, {
            'value': '4415',
            'label': '汕尾市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '441502',
                'label': '城区',
                'pid': '4415',
                'type': 3
            }, {
                'value': '441521',
                'label': '海丰县',
                'pid': '4415',
                'type': 3
            }, {
                'value': '441523',
                'label': '陆河县',
                'pid': '4415',
                'type': 3
            }, {
                'value': '441581',
                'label': '陆丰市',
                'pid': '4415',
                'type': 3
            }]
        }, {
            'value': '4416',
            'label': '河源市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '441602',
                'label': '源城区',
                'pid': '4416',
                'type': 3
            }, {
                'value': '441621',
                'label': '紫金县',
                'pid': '4416',
                'type': 3
            }, {
                'value': '441622',
                'label': '龙川县',
                'pid': '4416',
                'type': 3
            }, {
                'value': '441623',
                'label': '连平县',
                'pid': '4416',
                'type': 3
            }, {
                'value': '441624',
                'label': '和平县',
                'pid': '4416',
                'type': 3
            }, {
                'value': '441625',
                'label': '东源县',
                'pid': '4416',
                'type': 3
            }]
        }, {
            'value': '4417',
            'label': '阳江市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '441702',
                'label': '江城区',
                'pid': '4417',
                'type': 3
            }, {
                'value': '441704',
                'label': '阳东区',
                'pid': '4417',
                'type': 3
            }, {
                'value': '441721',
                'label': '阳西县',
                'pid': '4417',
                'type': 3
            }, {
                'value': '441781',
                'label': '阳春市',
                'pid': '4417',
                'type': 3
            }]
        }, {
            'value': '4418',
            'label': '清远市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '441802',
                'label': '清城区',
                'pid': '4418',
                'type': 3
            }, {
                'value': '441803',
                'label': '清新区',
                'pid': '4418',
                'type': 3
            }, {
                'value': '441821',
                'label': '佛冈县',
                'pid': '4418',
                'type': 3
            }, {
                'value': '441823',
                'label': '阳山县',
                'pid': '4418',
                'type': 3
            }, {
                'value': '441825',
                'label': '连山壮族瑶族自治县',
                'pid': '4418',
                'type': 3
            }, {
                'value': '441826',
                'label': '连南瑶族自治县',
                'pid': '4418',
                'type': 3
            }, {
                'value': '441881',
                'label': '英德市',
                'pid': '4418',
                'type': 3
            }, {
                'value': '441882',
                'label': '连州市',
                'pid': '4418',
                'type': 3
            }]
        }, {
            'value': '4419',
            'label': '东莞市',
            'pid': '44',
            'type': 2
        }, {
            'value': '4420',
            'label': '中山市',
            'pid': '44',
            'type': 2
        }, {
            'value': '4451',
            'label': '潮州市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '445102',
                'label': '湘桥区',
                'pid': '4451',
                'type': 3
            }, {
                'value': '445103',
                'label': '潮安区',
                'pid': '4451',
                'type': 3
            }, {
                'value': '445122',
                'label': '饶平县',
                'pid': '4451',
                'type': 3
            }]
        }, {
            'value': '4452',
            'label': '揭阳市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '445202',
                'label': '榕城区',
                'pid': '4452',
                'type': 3
            }, {
                'value': '445203',
                'label': '揭东区',
                'pid': '4452',
                'type': 3
            }, {
                'value': '445222',
                'label': '揭西县',
                'pid': '4452',
                'type': 3
            }, {
                'value': '445224',
                'label': '惠来县',
                'pid': '4452',
                'type': 3
            }, {
                'value': '445281',
                'label': '普宁市',
                'pid': '4452',
                'type': 3
            }]
        }, {
            'value': '4453',
            'label': '云浮市',
            'pid': '44',
            'type': 2,
            'children': [{
                'value': '445302',
                'label': '云城区',
                'pid': '4453',
                'type': 3
            }, {
                'value': '445303',
                'label': '云安区',
                'pid': '4453',
                'type': 3
            }, {
                'value': '445321',
                'label': '新兴县',
                'pid': '4453',
                'type': 3
            }, {
                'value': '445322',
                'label': '郁南县',
                'pid': '4453',
                'type': 3
            }, {
                'value': '445381',
                'label': '罗定市',
                'pid': '4453',
                'type': 3
            }]
        }]
    },
    {
        'value': '53',
        'label': '云南省',
        'pid': '1',
        'type': 1,
        'children': [{
            'value': '5301',
            'label': '昆明市',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '530102',
                'label': '五华区',
                'pid': '5301',
                'type': 3
            }, {
                'value': '530103',
                'label': '盘龙区',
                'pid': '5301',
                'type': 3
            }, {
                'value': '530111',
                'label': '官渡区',
                'pid': '5301',
                'type': 3
            }, {
                'value': '530112',
                'label': '西山区',
                'pid': '5301',
                'type': 3
            }, {
                'value': '530113',
                'label': '东川区',
                'pid': '5301',
                'type': 3
            }, {
                'value': '530114',
                'label': '呈贡区',
                'pid': '5301',
                'type': 3
            }, {
                'value': '530122',
                'label': '晋宁县',
                'pid': '5301',
                'type': 3
            }, {
                'value': '530124',
                'label': '富民县',
                'pid': '5301',
                'type': 3
            }, {
                'value': '530125',
                'label': '宜良县',
                'pid': '5301',
                'type': 3
            }, {
                'value': '530126',
                'label': '石林彝族自治县',
                'pid': '5301',
                'type': 3
            }, {
                'value': '530127',
                'label': '嵩明县',
                'pid': '5301',
                'type': 3
            }, {
                'value': '530128',
                'label': '禄劝彝族苗族自治县',
                'pid': '5301',
                'type': 3
            }, {
                'value': '530129',
                'label': '寻甸回族彝族自治县',
                'pid': '5301',
                'type': 3
            }, {
                'value': '530181',
                'label': '安宁市',
                'pid': '5301',
                'type': 3
            }]
        }, {
            'value': '5303',
            'label': '曲靖市',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '530302',
                'label': '麒麟区',
                'pid': '5303',
                'type': 3
            }, {
                'value': '530321',
                'label': '马龙县',
                'pid': '5303',
                'type': 3
            }, {
                'value': '530322',
                'label': '陆良县',
                'pid': '5303',
                'type': 3
            }, {
                'value': '530323',
                'label': '师宗县',
                'pid': '5303',
                'type': 3
            }, {
                'value': '530324',
                'label': '罗平县',
                'pid': '5303',
                'type': 3
            }, {
                'value': '530325',
                'label': '富源县',
                'pid': '5303',
                'type': 3
            }, {
                'value': '530326',
                'label': '会泽县',
                'pid': '5303',
                'type': 3
            }, {
                'value': '530328',
                'label': '沾益县',
                'pid': '5303',
                'type': 3
            }, {
                'value': '530381',
                'label': '宣威市',
                'pid': '5303',
                'type': 3
            }]
        }, {
            'value': '5304',
            'label': '玉溪市',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '530402',
                'label': '红塔区',
                'pid': '5304',
                'type': 3
            }, {
                'value': '530421',
                'label': '江川县',
                'pid': '5304',
                'type': 3
            }, {
                'value': '530422',
                'label': '澄江县',
                'pid': '5304',
                'type': 3
            }, {
                'value': '530423',
                'label': '通海县',
                'pid': '5304',
                'type': 3
            }, {
                'value': '530424',
                'label': '华宁县',
                'pid': '5304',
                'type': 3
            }, {
                'value': '530425',
                'label': '易门县',
                'pid': '5304',
                'type': 3
            }, {
                'value': '530426',
                'label': '峨山彝族自治县',
                'pid': '5304',
                'type': 3
            }, {
                'value': '530427',
                'label': '新平彝族傣族自治县',
                'pid': '5304',
                'type': 3
            }, {
                'value': '530428',
                'label': '元江哈尼族彝族傣族自治县',
                'pid': '5304',
                'type': 3
            }]
        }, {
            'value': '5305',
            'label': '保山市',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '530502',
                'label': '隆阳区',
                'pid': '5305',
                'type': 3
            }, {
                'value': '530521',
                'label': '施甸县',
                'pid': '5305',
                'type': 3
            }, {
                'value': '530523',
                'label': '龙陵县',
                'pid': '5305',
                'type': 3
            }, {
                'value': '530524',
                'label': '昌宁县',
                'pid': '5305',
                'type': 3
            }, {
                'value': '530581',
                'label': '腾冲市',
                'pid': '5305',
                'type': 3
            }]
        }, {
            'value': '5306',
            'label': '昭通市',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '530602',
                'label': '昭阳区',
                'pid': '5306',
                'type': 3
            }, {
                'value': '530621',
                'label': '鲁甸县',
                'pid': '5306',
                'type': 3
            }, {
                'value': '530622',
                'label': '巧家县',
                'pid': '5306',
                'type': 3
            }, {
                'value': '530623',
                'label': '盐津县',
                'pid': '5306',
                'type': 3
            }, {
                'value': '530624',
                'label': '大关县',
                'pid': '5306',
                'type': 3
            }, {
                'value': '530625',
                'label': '永善县',
                'pid': '5306',
                'type': 3
            }, {
                'value': '530626',
                'label': '绥江县',
                'pid': '5306',
                'type': 3
            }, {
                'value': '530627',
                'label': '镇雄县',
                'pid': '5306',
                'type': 3
            }, {
                'value': '530628',
                'label': '彝良县',
                'pid': '5306',
                'type': 3
            }, {
                'value': '530629',
                'label': '威信县',
                'pid': '5306',
                'type': 3
            }, {
                'value': '530630',
                'label': '水富县',
                'pid': '5306',
                'type': 3
            }]
        }, {
            'value': '5307',
            'label': '丽江市',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '530702',
                'label': '古城区',
                'pid': '5307',
                'type': 3
            }, {
                'value': '530721',
                'label': '玉龙纳西族自治县',
                'pid': '5307',
                'type': 3
            }, {
                'value': '530722',
                'label': '永胜县',
                'pid': '5307',
                'type': 3
            }, {
                'value': '530723',
                'label': '华坪县',
                'pid': '5307',
                'type': 3
            }, {
                'value': '530724',
                'label': '宁蒗彝族自治县',
                'pid': '5307',
                'type': 3
            }]
        }, {
            'value': '5308',
            'label': '普洱市',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '530802',
                'label': '思茅区',
                'pid': '5308',
                'type': 3
            }, {
                'value': '530821',
                'label': '宁洱哈尼族彝族自治县',
                'pid': '5308',
                'type': 3
            }, {
                'value': '530822',
                'label': '墨江哈尼族自治县',
                'pid': '5308',
                'type': 3
            }, {
                'value': '530823',
                'label': '景东彝族自治县',
                'pid': '5308',
                'type': 3
            }, {
                'value': '530824',
                'label': '景谷傣族彝族自治县',
                'pid': '5308',
                'type': 3
            }, {
                'value': '530825',
                'label': '镇沅彝族哈尼族拉祜族自治县',
                'pid': '5308',
                'type': 3
            }, {
                'value': '530826',
                'label': '江城哈尼族彝族自治县',
                'pid': '5308',
                'type': 3
            }, {
                'value': '530827',
                'label': '孟连傣族拉祜族佤族自治县',
                'pid': '5308',
                'type': 3
            }, {
                'value': '530828',
                'label': '澜沧拉祜族自治县',
                'pid': '5308',
                'type': 3
            }, {
                'value': '530829',
                'label': '西盟佤族自治县',
                'pid': '5308',
                'type': 3
            }]
        }, {
            'value': '5309',
            'label': '临沧市',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '530902',
                'label': '临翔区',
                'pid': '5309',
                'type': 3
            }, {
                'value': '530921',
                'label': '凤庆县',
                'pid': '5309',
                'type': 3
            }, {
                'value': '530922',
                'label': '云县',
                'pid': '5309',
                'type': 3
            }, {
                'value': '530923',
                'label': '永德县',
                'pid': '5309',
                'type': 3
            }, {
                'value': '530924',
                'label': '镇康县',
                'pid': '5309',
                'type': 3
            }, {
                'value': '530925',
                'label': '双江拉祜族佤族布朗族傣族自治县',
                'pid': '5309',
                'type': 3
            }, {
                'value': '530926',
                'label': '耿马傣族佤族自治县',
                'pid': '5309',
                'type': 3
            }, {
                'value': '530927',
                'label': '沧源佤族自治县',
                'pid': '5309',
                'type': 3
            }]
        }, {
            'value': '5323',
            'label': '楚雄彝族自治州',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '532301',
                'label': '楚雄市',
                'pid': '5323',
                'type': 3
            }, {
                'value': '532322',
                'label': '双柏县',
                'pid': '5323',
                'type': 3
            }, {
                'value': '532323',
                'label': '牟定县',
                'pid': '5323',
                'type': 3
            }, {
                'value': '532324',
                'label': '南华县',
                'pid': '5323',
                'type': 3
            }, {
                'value': '532325',
                'label': '姚安县',
                'pid': '5323',
                'type': 3
            }, {
                'value': '532326',
                'label': '大姚县',
                'pid': '5323',
                'type': 3
            }, {
                'value': '532327',
                'label': '永仁县',
                'pid': '5323',
                'type': 3
            }, {
                'value': '532328',
                'label': '元谋县',
                'pid': '5323',
                'type': 3
            }, {
                'value': '532329',
                'label': '武定县',
                'pid': '5323',
                'type': 3
            }, {
                'value': '532331',
                'label': '禄丰县',
                'pid': '5323',
                'type': 3
            }]
        }, {
            'value': '5325',
            'label': '红河哈尼族彝族自治州',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '532501',
                'label': '个旧市',
                'pid': '5325',
                'type': 3
            }, {
                'value': '532502',
                'label': '开远市',
                'pid': '5325',
                'type': 3
            }, {
                'value': '532503',
                'label': '蒙自市',
                'pid': '5325',
                'type': 3
            }, {
                'value': '532504',
                'label': '弥勒市',
                'pid': '5325',
                'type': 3
            }, {
                'value': '532523',
                'label': '屏边苗族自治县',
                'pid': '5325',
                'type': 3
            }, {
                'value': '532524',
                'label': '建水县',
                'pid': '5325',
                'type': 3
            }, {
                'value': '532525',
                'label': '石屏县',
                'pid': '5325',
                'type': 3
            }, {
                'value': '532527',
                'label': '泸西县',
                'pid': '5325',
                'type': 3
            }, {
                'value': '532528',
                'label': '元阳县',
                'pid': '5325',
                'type': 3
            }, {
                'value': '532529',
                'label': '红河县',
                'pid': '5325',
                'type': 3
            }, {
                'value': '532530',
                'label': '金平苗族瑶族傣族自治县',
                'pid': '5325',
                'type': 3
            }, {
                'value': '532531',
                'label': '绿春县',
                'pid': '5325',
                'type': 3
            }, {
                'value': '532532',
                'label': '河口瑶族自治县',
                'pid': '5325',
                'type': 3
            }]
        }, {
            'value': '5326',
            'label': '文山壮族苗族自治州',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '532601',
                'label': '文山市',
                'pid': '5326',
                'type': 3
            }, {
                'value': '532622',
                'label': '砚山县',
                'pid': '5326',
                'type': 3
            }, {
                'value': '532623',
                'label': '西畴县',
                'pid': '5326',
                'type': 3
            }, {
                'value': '532624',
                'label': '麻栗坡县',
                'pid': '5326',
                'type': 3
            }, {
                'value': '532625',
                'label': '马关县',
                'pid': '5326',
                'type': 3
            }, {
                'value': '532626',
                'label': '丘北县',
                'pid': '5326',
                'type': 3
            }, {
                'value': '532627',
                'label': '广南县',
                'pid': '5326',
                'type': 3
            }, {
                'value': '532628',
                'label': '富宁县',
                'pid': '5326',
                'type': 3
            }]
        }, {
            'value': '5328',
            'label': '西双版纳傣族自治州',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '532801',
                'label': '景洪市',
                'pid': '5328',
                'type': 3
            }, {
                'value': '532822',
                'label': '勐海县',
                'pid': '5328',
                'type': 3
            }, {
                'value': '532823',
                'label': '勐腊县',
                'pid': '5328',
                'type': 3
            }]
        }, {
            'value': '5329',
            'label': '大理白族自治州',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '532901',
                'label': '大理市',
                'pid': '5329',
                'type': 3
            }, {
                'value': '532922',
                'label': '漾濞彝族自治县',
                'pid': '5329',
                'type': 3
            }, {
                'value': '532923',
                'label': '祥云县',
                'pid': '5329',
                'type': 3
            }, {
                'value': '532924',
                'label': '宾川县',
                'pid': '5329',
                'type': 3
            }, {
                'value': '532925',
                'label': '弥渡县',
                'pid': '5329',
                'type': 3
            }, {
                'value': '532926',
                'label': '南涧彝族自治县',
                'pid': '5329',
                'type': 3
            }, {
                'value': '532927',
                'label': '巍山彝族回族自治县',
                'pid': '5329',
                'type': 3
            }, {
                'value': '532928',
                'label': '永平县',
                'pid': '5329',
                'type': 3
            }, {
                'value': '532929',
                'label': '云龙县',
                'pid': '5329',
                'type': 3
            }, {
                'value': '532930',
                'label': '洱源县',
                'pid': '5329',
                'type': 3
            }, {
                'value': '532931',
                'label': '剑川县',
                'pid': '5329',
                'type': 3
            }, {
                'value': '532932',
                'label': '鹤庆县',
                'pid': '5329',
                'type': 3
            }]
        }, {
            'value': '5331',
            'label': '德宏傣族景颇族自治州',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '533102',
                'label': '瑞丽市',
                'pid': '5331',
                'type': 3
            }, {
                'value': '533103',
                'label': '芒市',
                'pid': '5331',
                'type': 3
            }, {
                'value': '533122',
                'label': '梁河县',
                'pid': '5331',
                'type': 3
            }, {
                'value': '533123',
                'label': '盈江县',
                'pid': '5331',
                'type': 3
            }, {
                'value': '533124',
                'label': '陇川县',
                'pid': '5331',
                'type': 3
            }]
        }, {
            'value': '5333',
            'label': '怒江傈僳族自治州',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '533321',
                'label': '泸水县',
                'pid': '5333',
                'type': 3
            }, {
                'value': '533323',
                'label': '福贡县',
                'pid': '5333',
                'type': 3
            }, {
                'value': '533324',
                'label': '贡山独龙族怒族自治县',
                'pid': '5333',
                'type': 3
            }, {
                'value': '533325',
                'label': '兰坪白族普米族自治县',
                'pid': '5333',
                'type': 3
            }]
        }, {
            'value': '5334',
            'label': '迪庆藏族自治州',
            'pid': '53',
            'type': 2,
            'children': [{
                'value': '533401',
                'label': '香格里拉市',
                'pid': '5334',
                'type': 3
            }, {
                'value': '533422',
                'label': '德钦县',
                'pid': '5334',
                'type': 3
            }, {
                'value': '533423',
                'label': '维西傈僳族自治县',
                'pid': '5334',
                'type': 3
            }]
        }]
    },
    {
        'value': '12',
        'label': '天津市',
        'pid': '1',
        'type': 1,
        'children': [{
            'value': '1201',
            'label': '市辖区',
            'pid': '12',
            'type': 2,
            'children': [{
                'value': '120101',
                'label': '和平区',
                'pid': '1201',
                'type': 3
            }, {
                'value': '120102',
                'label': '河东区',
                'pid': '1201',
                'type': 3
            }, {
                'value': '120103',
                'label': '河西区',
                'pid': '1201',
                'type': 3
            }, {
                'value': '120104',
                'label': '南开区',
                'pid': '1201',
                'type': 3
            }, {
                'value': '120105',
                'label': '河北区',
                'pid': '1201',
                'type': 3
            }, {
                'value': '120106',
                'label': '红桥区',
                'pid': '1201',
                'type': 3
            }, {
                'value': '120110',
                'label': '东丽区',
                'pid': '1201',
                'type': 3
            }, {
                'value': '120111',
                'label': '西青区',
                'pid': '1201',
                'type': 3
            }, {
                'value': '120112',
                'label': '津南区',
                'pid': '1201',
                'type': 3
            }, {
                'value': '120113',
                'label': '北辰区',
                'pid': '1201',
                'type': 3
            }, {
                'value': '120114',
                'label': '武清区',
                'pid': '1201',
                'type': 3
            }, {
                'value': '120115',
                'label': '宝坻区',
                'pid': '1201',
                'type': 3
            }, {
                'value': '120116',
                'label': '滨海新区',
                'pid': '1201',
                'type': 3
            }, {
                'value': '120117',
                'label': '宁河区',
                'pid': '1201',
                'type': 3
            }, {
                'value': '120118',
                'label': '静海区',
                'pid': '1201',
                'type': 3
            }]
        }, {
            'value': '1202',
            'label': '县',
            'pid': '12',
            'type': 2,
            'children': [{
                'value': '120225',
                'label': '蓟县',
                'pid': '1202',
                'type': 3
            }]
        }]
    }
]

export default placesJson
