import { Component, Vue } from 'vue-property-decorator'
import applyTableComponent from './applyTable/index.vue'
import statisticsComponent from './statistics/index.vue'

interface tabsDataItem {
    label: string
    value: number;
    total: number;
}

@Component({
    components: {
        applyTableComponent,
        statisticsComponent
    }
})
export default class InvalidCustomerAuditPage extends Vue {
    tabs = {
        current: 0,
        has_daikan: false,
        customer_type: '',
        data: [
            { label: '待审核', value: 0, total: 0 },
            { label: '已驳回', value: 2, total: 0 },
            { label: '已通过', value: 1, total: 0 },
            { label: '退客统计', value: 3, total: 0 }
        ]
    }

    get
    hasDaikanText () {
        return this.tabs.current === 1 ? '驳回被带看' : '通过被带看'
    }

    created () {
        this.getTotalData()
    }

    async getTotalData () {
        this.tabs.data.map((v: tabsDataItem) => {
            this.getTotal(v)
        })
    }

    async getTotal (item: tabsDataItem) {
        const data:{
            status: number,
            page: number,
            has_dk?: number
        } = {
            status: item.value,
            page: 1
        }

        if (data.status === this.tabs.data[this.tabs.current].value && this.tabs.has_daikan) {
            data.has_dk = 1
        }

        const res = await this.__apis.customer.getInvalidCustomerApplyPage(data)
        item.total = res.data.total
    }

    async tabsClick (index: number) {
        if (index === this.tabs.current) return
        this.tabs.current = index
        if (this.tabs.has_daikan) {
            this.tabs.has_daikan = false
            await this.getTotalData()
        }
    }

    refreshDataTotal (total: number) {
        this.tabs.data[this.tabs.current].total = total
    }
}
