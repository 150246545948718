import { BaseRes, java, ListReq, ListRes2, php } from '@/api/index'

interface getAccountListRes{
    cookieName: string;
    customerChannelType: number;
    customerChannelTypeStr: string;
    showCnName: string;
    showEnName: string;
}

interface checkLoginStatusRes {
    [key: string]: number
}

interface LoginBase {
    code: string
    type: string
}

interface saveOcpcBindHouseRecordReq{
    id?: number;
    house_id: number;
    ocpc_name: string;
    link: string;
}

interface ocpcBindHouseRecordQueryReq extends ListReq{
    start_time?: string;
    end_time?: string;
    ocpc_name?: string;
    house_id?: number;
    link?: string
}

interface ocpcBindHouseRecordQueryRes {
    ctime: number
    ext_data: string
    house_id: number
    house_name: string
    id: number
    link: string
    ocpc_id: number
    ocpc_name: string
}

export default class ApiMarket {
    // 获取所有登录账号
    getAccountList (): BaseRes<getAccountListRes[]> {
        return java.post('/admin/customerChannelAccount/getAccountList', {})
    }

    // 获取所有登录账号状态
    checkLoginStatus (): BaseRes<checkLoginStatusRes> {
        return php.post('', {
            action_name: 'getAllAccountStatus'
        })
    }

    // 400登录
    login400 (data: LoginBase) {
        return php.post('', {
            action_name: 'login400',
            ...data
        })
    }

    // 设置cookie登录
    loginSetCookie (data: LoginBase & {cookie_name: string}) {
        return php.post('', {
            action_name: 'setCookie',
            ...data
        })
    }

    // ocpc 保存
    saveOcpcBindHouseRecord (data: saveOcpcBindHouseRecordReq) {
        return java.post('/admin/ocpc/local/bind/house/saveOcpcBindHouseRecord', data)
    }

    // ocpc 搜索
    ocpcBindHouseRecordQuery (data: ocpcBindHouseRecordQueryReq): BaseRes<ListRes2<ocpcBindHouseRecordQueryRes>> {
        return java.post('/admin/ocpc/local/bind/house/ocpcBindHouseRecordQuery', data)
    }

    // ocpc 删除
    deleteOcpcBindHouseRecord (id: number) {
        return java.post('/admin/ocpc/local/bind/house/deleteOcpcBindHouseRecord', { id })
    }
}
