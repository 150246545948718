import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import selectComponent from '@/components/filters/select/index.vue'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'
import { routerBaseUrl } from '@/router'

@Component({
    components: {
        FilterComponent,
        selectComponent,
        FilterBusInputSelectComponent,
        FilterBusSelectComponent
    }
})
export default class IndexPage extends Vue {
    @Prop({
        type: String,
        default: ''
    })
    date !: string

    filter = {}
    datePicker = this.date
    dataObj: any = {
        list: [],
        total: 0
    }
    page = 1
    pageSize = 20

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    @Watch('date')
    watchDate (newVal: any) {
        this.datePicker = newVal
        this.page = 1
        this.getTiChengQkList()
    }

    mounted () {
        this.getTiChengQkList()
    }

    // 筛选
    filterChange (data: any) {
        this.page = 1
        this.filter = data
        this.getTiChengQkList()
    }

    pageChange (page: number) {
        this.page = page
        this.getTiChengQkList()
    }

    queryGroupSearch (queryString: string, cb: any) {
        this.__utils.index.searchGroup(queryString, cb)
    }

    toTcDetail (id: string) {
        open(`${routerBaseUrl}/financial/gongziManage/tcDetail/${id}`, '_blank')
    }

    async getTiChengQkList () {
        let filter = this.filter || {}
        const { data } = await this.__apis.gzManage.getBonusOverView({
            page: this.page,
            pagesize: this.pageSize,
            ...filter,
            time_start: this.datePicker,
            time_end: this.datePicker
        })

        data.list.map((v: any) => {
            v.month_time = new Date(v.month_time * 1000).format('yyyy-MM')
        })
        data.statistic_res = data.statistic_res || this.dataObj.statistic_res
        this.dataObj = data

        if (this.dataObj.statistic_res) {
            this.dataObj.list.unshift({
                username: '总计',
                ...data.statistic_res
            })
        }
        this.$emit('filter', filter)
    }
}
