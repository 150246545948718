import { Component, Vue } from 'vue-property-decorator'

@Component
export default class KnowledgeSearchDocPage extends Vue {
    keywords = ''
    searchList: any = {
        list: []
    }
    searchVisible = false
    dataObj = {}
    total = 0
    page = 1
    pageSize = 20

    // 分页改变
    pageChange (page: number) {
        this.page = page
    }

    async querySearchAsync (queryString: string, cb: any) {
        if (!queryString || queryString.length === 0) {
            this.clearWords()
            return
        }

        const res = await this.__apis.utils.searchDoc(queryString)

        res.data.list.map((v: any) => {
            v.label = v.Title
            v.value = v.Title
        })
        cb(res.data.list)
    }

    // 搜索匹配点击
    handleSearchSelect () {
        this.searchSubmit()
    }

    // 清空
    clearWords () {
        this.searchVisible = false
        this.searchList = {}
    }

    // 搜索
    async searchSubmit () {
        if (!this.keywords || this.keywords.length === 0) return

        const res = await this.__apis.utils.searchDoc(this.keywords)

        let datas = res.data
        this.searchVisible = true
        this.total = datas.total
        this.searchList = datas
    }

    goDoc (obj: any) {
        this.$router.push({
            name: '/knowledge/doc',
            query: {
                id: obj.LibId,
                cate_id: obj.DirId,
                doc_id: obj.Id
            }
        })
    }
}
