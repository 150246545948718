import { Component, Vue, Prop } from 'vue-property-decorator'
import { QuestionType } from '@/util/const'

interface Item {
    id: number
    ids: number[]
}

interface QuestionDetail {
    index: number,
    depName: string;
    subjectType: string;
    updateTime: string;
    content: string;
    questionType: string;
    questionSelect: {
        'content':string,
        'item_uuid':string,
        'prefix':string
    }[];
    right: string;
    scope: string;
    answerCount: number;
    rightRate: string;
    used: boolean;
    questionTimeScope: string;
}

@Component({
    components: {}
})
export default class QuestionBankSeeQuestionDialogComponent extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    visible!: boolean

    @Prop({
        default () {
            return {}
        }
    })
    item!: Item

    questionDetail: QuestionDetail = {
        index: 0,
        depName: '',
        subjectType: '',
        updateTime: '',
        content: '',
        questionType: '',
        questionSelect: [],
        right: '',
        scope: '',
        answerCount: 0,
        rightRate: '',
        used: false,
        questionTimeScope: ''
    }

    created () {
        this.getData(this.item.id)
    }

    close () {
        this.$emit('update:visible', false)
    }

    async getData (id: number) {
        try {
            const {
                data: {
                    question_base_info,
                    house_name,
                    mtime,
                    dep_name,
                    subject_name,
                    answer_right_rate,
                    answer_count
                }
            } = await this.__apis.activity.questionDetail(id)
            this.questionDetail = {
                index: this.item.ids.reduce((s, v, i) => v === id ? i : s, 0),
                depName: dep_name,
                subjectType: subject_name,
                updateTime: this.__utils.index.formatDate(new Date(mtime), 'YYYY-MM-DD HH:mm:ss'),
                content: question_base_info.title,
                questionType: this.__utils.index.getLabelByValue(QuestionType, question_base_info.question_type),
                questionSelect: question_base_info.items,
                right: question_base_info.correct || question_base_info.correct_array.join(','),
                scope: house_name || question_base_info.areas.map(a => a.areaName).join(','),
                answerCount: answer_count,
                rightRate: answer_right_rate + '%',
                used: question_base_info.used,
                questionTimeScope: question_base_info.invalid_time
                    ? [question_base_info.effective_time, question_base_info.invalid_time].map(a => this.__utils.index.formatDate(new Date(a), 'YYYY-MM-DD')).join('/')
                    : ''
            }
        } catch (e) {
            this.$notify({
                title: '错误',
                message: '数据获取失败！',
                type: 'error'
            })
            throw e
        }
    }

    prev () {
        if (this.questionDetail.index > 0) {
            this.getData(this.item.ids[this.questionDetail.index - 1])
        }
    }

    next () {
        if (this.questionDetail.index < this.item.ids.length - 1) {
            this.getData(this.item.ids[this.questionDetail.index + 1])
        }
    }
}
