import { Component, Vue } from 'vue-property-decorator'
import tabsComponent from '@/components/system/tabs/index.vue'
import qmPermission from 'qm-plugin-admin-permission'
import AddHotArticleComponent from './addHotArticle/index.vue'

@Component({
    components: {
        AddHotArticleComponent,
        tabsComponent
    }
})
export default class FriendCircleIndexPage extends Vue {
    tabs = {
        current: 0,
        data: [
            {
                text: '文章列表',
                url: ['/friendsCircle']
            },
            {
                text: '访问列表',
                url: ['/friendsCircle/seeUser']
            }
        ]
    }

    addHotArt = {
        visible: false,
        data: {
            value: ''
        }
    }

    created () {
        qmPermission.filter(this.tabs.data)
    }

    showAddHotArticle () {
        this.addHotArt = {
            visible: true,
            data: {
                value: ''
            }
        }
    }
}
