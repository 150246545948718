import { __apis } from '@/api'
import { getSuccessDetailRes } from '@/api/financial'
import { getAllSalePartnerListRes } from '@/api/utils'

export enum SuccessDetailCommitTypes {
    setAllChannel = 'success_detail/setAllChannel'
}
export enum SuccessDetailDispatchType {
    setAllData = 'success_detail/setAllData'
}
export type SuccessDetailCommits = {
    [SuccessDetailCommitTypes.setAllChannel]: getAllSalePartnerListRes['list']
}
export type SuccessDetailDispatches = {
    [SuccessDetailDispatchType.setAllData]: number
}

export interface SuccessDetail {
    all_channel: getAllSalePartnerListRes['list']
    all_data: getSuccessDetailRes
}
const initSuccessDetailState: SuccessDetail = {
    all_channel: [],
    all_data: {
        BonusList: [],
        ChannelName: '',
        ChannelPartnerId: 0,
        ChannelTel: '',
        ChannelUserJob: '',
        ChannelUserNote: '',
        ChannelUsername: '',
        CjDays: 0,
        Ctime: '',
        CuiyongList: [],
        CustomerId: 0,
        CustomerName: '',
        CustomerTel: '',
        DaoZhangList: [],
        Dep: 0,
        DuijieUserList: [],
        ExpectWangqianTime: null,
        ExtData: {
            mtime: 0,
            user_info: []
        },
        FanYongList: [],
        FanyongStatus: 0,
        Gro: 0,
        HasTuifan: 0,
        HasWangqian: 0,
        HasTrouble: 0,
        HouseId: 0,
        HouseMoney: 0,
        HouseName: '',
        HouseNo: '',
        Id: 0,
        JieyongStatus: 0,
        KaiPiaoList: [],
        KaipiaoStatus: 0,
        Money: 0,
        MustDzMoney: 0,
        NextJieyongTime: null,
        Pid: 0,
        PzImgs: {
            bb: '',
            qd: '',
            rg: '',
            sf: '',
            sk: ''
        },
        Remark: '',
        SaleTime: '',
        SaleType: 0,
        Sid: 0,
        SumMoney: 0,
        SumMustDzMoney: 0,
        TuiFangList: [],
        Type: 0,
        UserHouseRate: 0,
        UserId: 0,
        WangQianList: [],
        TroubleList: [],
        RemarkList: [],
        MoneyChangeList: [],
        ZygwName: '',
        ZygwTel: '',
        IsControversial: 0
    }
}

export default {
    namespaced: true,

    state: initSuccessDetailState,

    mutations: {
        setAllChannel (state: SuccessDetail, data: SuccessDetailCommits[SuccessDetailCommitTypes.setAllChannel]) {
            state.all_channel = data
        }
    },

    actions: {
        async setAllData (state: {state: SuccessDetail}, id: SuccessDetailDispatches[SuccessDetailDispatchType.setAllData]) {
            const { data: allData } = await __apis.financial.getSaleDetail(id)

            allData.KaiPiaoList.forEach(a => {
                a.KaipiaoTime = a.KaipiaoTime.split(' ')[0]
            })

            allData.DaoZhangList.forEach(a => {
                a.DaozhangTime = a.DaozhangTime.split(' ')[0]
            })

            allData.FanYongList.forEach(a => {
                a.Status = !a.Status ? '待返佣' : '已返佣'
            })

            allData.TuiFangList.forEach(a => {
                a.TuifanTime = a.TuifanTime.split(' ')[0]
            })

            if (allData.BonusList.length > 0) {
                allData.BonusList.unshift({
                    Username: '总计',
                    GroupName: '',
                    DepName: '',
                    Money: allData.BonusList.length ? allData.BonusList.map(a => a.Money).reduce((a, b) => a + b) : 0,
                    SendTime: '',
                    Remark: ''
                })
                allData.BonusList.forEach(a => {
                    a.SendTime = a.SendTime.split(' ')[0]
                })
            }

            state.state.all_data = allData
        }
    }
}
