import { Component, Vue } from 'vue-property-decorator'
import EditComponent from './edit/index.vue'
import { EditData } from './edit'
import { searchHouseRes } from '@/api/utils'

type EditComponent_i = {
    show: boolean
    item?: EditData
}

interface TableData {
    data: {
        id: number
        startDate: string
        endDate: string
        promiseMoney: string
        promiseRemain: string
        remark: string
        warning: boolean
        houses: searchHouseRes[]
    }[]
}

@Component({
    components: {
        EditComponent
    }
})
export default class PromisePage extends Vue {
    created () {
        this.getTableData()
    }

    get promiseMoney () {
        let res = 0

        this.tableData.data.map(a => +a.promiseMoney).forEach(a => {
            res += a
        })

        return res
    }

    get promiseRemain () {
        let res = 0

        this.tableData.data.map(a => +a.promiseRemain).forEach(a => {
            res += a
        })

        return res
    }

    tableData: TableData = {
        data: []
    }

    async getTableData () {
        const { data } = await this.__apis.channelManage.depositList({
            partner_id: +this.$route.params.id
        })

        this.tableData.data = data.map(a => {
            return {
                id: a.id,
                startDate: this.__utils.index.formatDate(new Date(a.begin_time), 'YYYY-MM-DD'),
                endDate: this.__utils.index.formatDate(new Date(a.end_time), 'YYYY-MM-DD'),
                promiseMoney: a.deposit_money.toFixed(2),
                promiseRemain: a.remain_money.toFixed(2),
                remark: a.remark,
                houses: JSON.parse(a.house_list),
                warning: a.warning
            }
        })
    }

    edit (item: TableData['data'][0]) {
        this.showEditComponent({
            id: item.id,
            start: item.startDate,
            end: item.endDate,
            money: item.promiseMoney,
            house: item.houses,
            remark: item.remark,
            remainder: item.promiseRemain
        })
    }

    remove () {}

    editComponent: EditComponent_i = {
        show: false
    }

    showEditComponent (item?: EditComponent_i['item']) {
        this.editComponent.item = item
        this.editComponent.show = true
    }

    // 设置特殊行样式
    tableRowClassName ({ rowIndex }: any) {
        return this.processPromiseMoney(rowIndex)
    }

    // 合作商 保证金剩余 不足 30%
    processPromiseMoney (index: number) {
        const data = this.tableData.data[index]

        if (!data) return ''

        return data.warning ? 'promiseMoney' : ''
    }

    toChangeRecord (item: TableData['data'][0]) {
        let { href } = this.$router.resolve(`/channelManage/hezuoshang/change/${+this.$route.params.id}?record_id=${item.id}`)
        window.open(href, '_blank')
    }
}
