import { Component, Vue } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'
import InputSelectComponent from '@/components/filters/inputSelect/index.vue'
import { Table } from '@/types'
import importComponent from '@/components/wallet/import/index.vue'
import addMoneyComponent from './add/index.vue'

interface editMoneyData {
    [key: string]: string
    employeeId: string
    allMoney: string,
    department: string,
    group: string,
    fxs: string,
    month: string
}

interface TableItem {
    id: number
    department: string,
    group: string,
    fxs: string,
    allMoney: string,
    removeMoney: string,
    getMoney: string,
    userId: string
}

interface Filter {
    [index: string]: string
    department: string
    group: string
    fxs: string
    time: string
    status: string
}

@Component({
    components: {
        FilterComponent,
        SelectComponent,
        FilterBusinessSelect,
        InputSelectComponent,
        importComponent,
        addMoneyComponent
    }
})
export default class IndexPage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 50
    }

    filter: Filter = {
        status: '',
        department: '',
        group: '',
        fxs: '',
        time: ''
    }

    type = '5'

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        this.getTableData()
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }

    importComponent = {
        show: false
    }

    showImportComponent () {
        this.importComponent.show = true
    }

    importSuccess () {
        this.$message({
            type: 'success',
            message: '添加成功'
        })

        this.tableData.page = 1
        this.getTableData()
    }

    addMoneyComponent: {
        show: boolean,
        data: editMoneyData
    } = {
        show: false,
        data: {
            employeeId: '',
            department: '',
            group: '',
            fxs: '',
            allMoney: '',
            month: ''
        }
    }

    showEditMoneyComponent (item: TableItem) {
        this.addMoneyComponent.show = true
        this.addMoneyComponent.data.department = item.department
        this.addMoneyComponent.data.group = item.group
        this.addMoneyComponent.data.fxs = item.fxs
        this.addMoneyComponent.data.allMoney = ''
        this.addMoneyComponent.data.month = ''
        this.addMoneyComponent.data.employeeId = item.id.toString()
    }

    editMoneySuccess () {
        this.$message({
            type: 'success',
            message: '修改成功'
        })

        this.getTableData()
    }

    toMoneyDetail (item: TableItem) {
        const { href } = this.$router.resolve({
            path: `/financial/wallet/chongzhiCard/detail/${item.id}`
        })
        window.open(href, '_blank')
    }

    async getTableData () {
        const { data } = await this.__apis.wallet.rechargeFinanceRecordQuery({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            dep_id: +this.filter.department || undefined,
            group_id: +this.filter.group || undefined,
            employee_name: this.filter.fxs
        })

        this.tableData.total = data.total
        this.tableData.data = data.rows.map(a => {
            return {
                id: a.employee_id,
                department: this.__utils.index.getDepInfoById(a.dep_id).v,
                group: this.__utils.index.getGroupInfoById(a.group_id).name,
                fxs: a.employee_name,
                allMoney: this.__utils.index.formatMoney(a.in_finance_money),
                removeMoney: this.__utils.index.formatMoney(a.out_finance_money),
                getMoney: this.__utils.index.formatMoney(a.in_finance_money - a.out_finance_money),
                userId: a.employee_id.toString()
            }
        })
    }

    created () {
        this.getTableData()
    }
}
