import { Component, Vue, Watch } from 'vue-property-decorator'
import HomeDateComponent from '@/views/home/components/date/index.vue'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'
import echarts, { ECharts } from 'echarts'
import { setLineChart, colors } from '../helper'
import { tableList, TableItem } from './const'
import dateRangeUtil from '@/util/date'
import { apiConfig } from '@/api/config'
import XLSX from 'xlsx'
import { DepInfoVar } from '@/util/const'

function usePickerLimitDisabledDate (disabledDate: Function) {
    let pickerMinDate: null | Date = null
    const pickerOptions = {
        disabledDate (time: Date) {
            return disabledDate.call(this, time, pickerMinDate)
        },
        onPick ({ maxDate, minDate }: {maxDate: Date, minDate: Date}) {
            if (minDate && !maxDate) {
                pickerMinDate = minDate
            }
            if (maxDate) {
                pickerMinDate = null
            }
        }
    }
    return pickerOptions
}

type Link = {
    label: string,
    value: number
}

interface TransformFunnelData {
    id: number,
    name: string,
    followCount: number,
    followRate: string,
    lookCount: string,
    lookRate: string,
    dealCount: number
    dealRate: string
}

interface NameValue {
    name: string
    value: string
}

interface ChartDateData {
    name: string
    data: string[]
    date: string[]
    rate?: string[]
}

interface HistoryChartData {
    cjMoney: NameValue[],
    cjMoneyRate: ChartDateData[],
    singleCustomerMoney: NameValue[],
    fyMoneyRate: ChartDateData[],
    daikanCountRate: ChartDateData[],
    orderDispatchCustomer: ChartDateData[]
}

@Component({
    components: {
        HomeDateComponent,
        InputSelectSearch
    }
})
export default class SaleManOverviewPage extends Vue {
    groupFilter: null | number = null
    depFilter: null | number = null

    links: {
        list: Link[]
        active: Link['value']
    } = {
        list: [{
            label: '按部门',
            value: 1
        }, {
            label: '按战队',
            value: 2
        }, {
            label: '按分析师',
            value: 3
        }],
        active: 1
    }

    date: string[] = []

    table: {
        data: TableItem[],
        list: typeof tableList,
        loading: boolean
    } = {
        data: [],
        list: tableList,
        loading: false
    }

    transformFunnelData: TransformFunnelData[] = []

    historyPickerOptions = usePickerLimitDisabledDate((time: Date, startDate?: Date) => {
        if (startDate) {
            const startDateTime = new Date(startDate).getTime()
            const days = dateRangeUtil.getMonthDays(time.getFullYear(), time.getMonth())
            const timeStamp = 1 * 24 * 60 * 60 * 1000
            const max = startDateTime + (days * timeStamp)
            const min = startDateTime - (days * timeStamp)
            return time.getTime() <= max && time.getTime() > min
        }
        return false
    })
    history: {
        dep: string | number
        date: string[]
    } = {
        dep: -1,
        date: []
    }
    historyChartData: HistoryChartData = {
        cjMoney: [],
        cjMoneyRate: [],
        singleCustomerMoney: [],
        fyMoneyRate: [],
        daikanCountRate: [],
        orderDispatchCustomer: []
    }

    get
    tableList () {
        let list = this.table.list
        if (this.links.active !== 2) {
            list = list.filter(a => a.prop !== 'efficiency')
        }
        if (this.links.active === 3) {
            list = list.filter(a => a.prop !== 'customerAvgUserCount')
        }
        return list
    }

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    get
    isKm () {
        return this.infoPos.dep >= DepInfoVar.KUNMING
    }

    get
    depData () {
        if (this.isKm) {
            return this.__utils.index.getXsDep().filter(a => a.value === this.infoPos.dep)
        }
        return this.__utils.index.getXsDep()
    }

    get
    depList () {
        let data = this.__utils.index.getXsDep()
        if (this.isKm) {
            return data.filter(a => a.value === this.infoPos.dep)
        }
        return [{ value: -1, label: '全部' }, ...data]
    }

    created () {
        if (this.infoPos.dep) {
            this.history.dep = this.infoPos.dep
        }
    }

    changeType (item: Link) {
        this.links.active = item.value

        if (this.links.active !== 3) {
            this.groupFilter = null
        }
        if (this.links.active !== 2) {
            this.depFilter = null
        }

        this.saleManData()
        this.customerConversionInfo()
    }

    selectGroup () {
        this.$nextTick(() => {
            this.saleManData()
            this.customerConversionInfo()
        })
    }

    dateChange () {
        this.saleManData()
        this.customerConversionInfo()
    }

    async saleManData () {
        const active = this.links.active

        if (active === 2 && !this.depFilter) {
            this.table.data = []
            return
        }
        if (active === 3 && !this.groupFilter) {
            this.table.data = []
            return
        }

        this.table.loading = true
        this.table.data = []

        let filter = {
            type: active,
            time_start: this.date[0],
            time_end: this.date[1]
        }

        let res
        if (this.links.active === 1) {
            res = await this.__apis.home.depWorkBoard(filter)
        } else if (this.links.active === 2) {
            res = await this.__apis.home.groupWorkBoard({
                ...filter,
                dep: this.depFilter || undefined
            })
        } else {
            res = await this.__apis.home.userWorkBoard({
                ...filter,
                group: this.groupFilter || undefined
            })
        }
        const { data } = res

        const total = {
            dep: '总计',
            successMoney: 0,
            successTotal: 0,
            lookNumber: 0,
            forwardCustomers: 0,
            newCustomers: 0,
            lookRate: 0,
            successRate: 0,
            customerTransform: 0,
            efficiency: '',
            validCustomer: 0,
            invoiceMoney: 0,
            arriveMoney: 0,
            arriveProfit: 0,
            rebateMoney: 0,
            checkoutMoney: 0,
            checkoutRate: 0,
            lookSuccessTransform: 0,
            monthPrediction: 0,
            lastMonthPrediction: 0,
            successMedian: 0,
            firstFollowMedian: 0,
            totalFollowCount: 0,
            realFollowCount: 0,
            forwardTotalValidCount: '',
            validCustomerCount: 0,
            validCustomerRate: '',
            takeToLookCustomerRate: '',
            takeToLookCustomerCount: 0
        }
        this.table.data = data.map(a => {
            total.successMoney += a.sale_money_total
            total.successTotal += a.deal_order_count
            total.lookNumber += a.take_to_look_count
            total.forwardCustomers += a.real_customer_count
            total.newCustomers += a.new_customer_totcal_count
            total.lookRate += Number(a.take_to_look_rate)
            total.successRate += Number(a.deal_rate)
            total.customerTransform += Number(a.avg_income)
            total.validCustomer += a.effect_customer_count
            total.invoiceMoney += a.invoice_amount
            total.arriveMoney += a.income_amount
            total.arriveProfit += a.income_profit
            total.rebateMoney += a.fanyong_amount
            total.checkoutMoney += a.customer_goback_amount
            total.checkoutRate += Number((a.customer_goback_amount / Math.max(1, a.sale_money_total + a.customer_goback_amount) * 100).toFixed(2))
            total.lookSuccessTransform += Number(a.take_to_look_conver_deal_rate)
            total.monthPrediction += Number(a.forecast ? a.forecast.shouyongInfo.current_month_yuce.money.toFixed(0) : 0)
            total.lastMonthPrediction += Number(a.forecast ? a.forecast.shouyongInfo.next_month_yuce.money.toFixed(0) : 0)
            total.successMedian = a.all_deal_cycle_median
            total.firstFollowMedian = a.all_first_follow_cycle_median
            total.totalFollowCount += a.total_follow_count
            total.realFollowCount += a.real_follow_count
            total.validCustomerCount += a.customer_invalid_count
            total.takeToLookCustomerCount += a.take_to_look_customer_count
            return {
                dep: a.name,
                successMoney: a.sale_money_total,
                successTotal: a.deal_order_count,
                lookNumber: a.take_to_look_count.toFixed(2),
                forwardCustomers: a.real_customer_count,
                forwardTotalValidCount: `${a.total_follow_count} / ${a.real_follow_count}`,
                newCustomers: a.new_customer_totcal_count,
                lookRate: a.take_to_look_rate + '%',
                successRate: a.deal_rate + '%',
                customerTransform: a.avg_income,
                efficiency: String(a.efficiency),
                validCustomer: a.effect_customer_count,
                invoiceMoney: a.invoice_amount,
                arriveMoney: a.income_amount,
                arriveProfit: a.income_profit,
                rebateMoney: a.fanyong_amount,
                checkoutMoney: a.customer_goback_amount,
                checkoutRate: (a.customer_goback_amount / Math.max(1, a.sale_money_total + a.customer_goback_amount) * 100).toFixed(2) + '%',
                lookSuccessTransform: a.take_to_look_conver_deal_rate + '%',
                monthPrediction: a.forecast ? a.forecast.shouyongInfo.current_month_yuce.money.toFixed(0) : '',
                lastMonthPrediction: a.forecast ? a.forecast.shouyongInfo.next_month_yuce.money.toFixed(0) : '',
                successMedian: a.deal_cycle_median,
                firstFollowMedian: a.first_follow_cycle_median,
                validCustomerRate: (
                    +a.new_customer_totcal_count === 0 ? 0
                        : (a.new_customer_totcal_count - a.customer_invalid_count) / a.new_customer_totcal_count * 100
                ).toFixed(2) + '%',
                customerAvgUserCount: a.customer_avg_user_count,
                takeToLookCustomerRate: (+a.take_to_look_customer_rate).toFixed(2) + '%'
            }
        })
        this.table.data.unshift({
            ...total,
            successTotal: Number(total.successTotal.toFixed(2)),
            lookNumber: total.lookNumber.toFixed(2),
            lookRate: ((total.lookNumber / total.newCustomers) * 100).toFixed(2) + '%',
            customerTransform: Number((total.successMoney / total.newCustomers).toFixed(2)),
            rebateMoney: Number(total.rebateMoney.toFixed(2)),
            checkoutMoney: Number(total.checkoutMoney.toFixed(2)),
            successRate: ((total.successTotal / total.newCustomers) * 100).toFixed(2) + '%',
            checkoutRate: (total.checkoutMoney / Math.max(1, total.successMoney + total.checkoutMoney) * 100).toFixed(2) + '%',
            lookSuccessTransform: ((total.successTotal / total.lookNumber) * 100).toFixed(2) + '%',
            monthPrediction: total.monthPrediction ? String(total.monthPrediction) : '',
            lastMonthPrediction: total.lastMonthPrediction ? String(total.lastMonthPrediction) : '',
            forwardTotalValidCount: `${total.totalFollowCount} / ${total.realFollowCount}`,
            validCustomerRate: (
                +total.newCustomers === 0 ? 0 : (total.newCustomers - total.validCustomerCount) / total.newCustomers * 100
            ).toFixed(2) + '%',
            takeToLookCustomerRate: ((total.takeToLookCustomerCount / total.newCustomers) * 100).toFixed(2) + '%'
        })
        this.table.loading = false
    }
    async customerConversionInfo () {
        const active = this.links.active

        if (active === 3) return

        if (active === 2 && !this.depFilter) {
            this.transformFunnelData = []
            return
        }
        if (active === 3 && !this.groupFilter) {
            this.transformFunnelData = []
            return
        }

        const { data: { rateList, totalRate } } = await this.__apis.home.customerConversionInfo({
            type: this.links.active,
            time_start: this.date[0],
            time_end: this.date[1],
            dep: this.links.active === 3 ? this.__utils.index.getGroupInfoById(Number(this.groupFilter) as number).dep : (this.depFilter || undefined),
            group: this.groupFilter || undefined
        })
        this.transformFunnelData = [
            totalRate,
            ...rateList
        ].map(a => {
            return {
                id: a.id,
                name: a.name,
                followCount: a.follow.followCustomerCount,
                followRate: this.countZeroHandle(a.follow.followCustomerCount, a.follow.realCustomerCount),
                lookCount: a.takeToLook.takeToLookCustomerCount.toFixed(2),
                lookRate: this.countZeroHandle(a.takeToLook.takeToLookCustomerCount, a.takeToLook.realCustomerCount),
                dealCount: a.deal.dealCustomerCount,
                dealRate: this.countZeroHandle(a.deal.dealCustomerCount, a.deal.realCustomerCount)
            }
        })
    }
    countZeroHandle (count: number, count2: number) {
        if (count2 === 0) return '0%'
        return `${((count / count2) * 100).toFixed(2)}%`
    }

    @Watch('history', { deep: true })
    async historyDateChange () {
        await this.historyFilterChange()
        this.initCharts()
    }

    historyPickerChange (data: string[], type?: number) {
        this.lookPreFilter.date = data
    }
    async historyFilterChange () {
        const [
            cjMoney,
            cjMoneyRate,
            singleCustomerMoney,
            fyMoneyRate
        ] = await Promise.all([
            this.dealMoneyStatistic(),
            this.dealDateDetailStatistic(),
            this.incomeAverageStatistic(),
            this.fanyongDateDetailStatistic()
        ])
        this.historyChartData = {
            ...this.historyChartData,
            cjMoney,
            cjMoneyRate,
            singleCustomerMoney,
            fyMoneyRate
        }
    }

    async dealMoneyStatistic () {
        const { data } = await this.__apis.home.dealStatistic({
            dep_id: this.history.dep !== -1 ? +this.history.dep : undefined,
            time_start: this.history.date[0],
            time_end: this.history.date[1]
        })
        return data.map(a => {
            return {
                name: a.row || '未知',
                value: a.column
            }
        })
    }
    async incomeAverageStatistic () {
        const { data } = await this.__apis.home.incomeAverageStatistic(this.getDkDistributionStatisticFilter())
        return data.map(a => {
            return {
                name: a.column,
                value: a.row
            }
        })
    }
    async fanyongDateDetailStatistic () {
        const { data } = await this.__apis.home.fanyongDateDetailStatistic(this.getDkDistributionStatisticFilter())
        return Object.keys(data).map(a => {
            return {
                name: a,
                type: 'money',
                data: data[a].map(a => a.row),
                date: data[a].map(a => (
                    a.column.includes('/')
                        ? a.column.replace(/-/g, '.') : a.column)
                ),
                rate: data[a].map(a => a.rate)
            }
        })
    }
    async dealDateDetailStatistic () {
        const { data } = await this.__apis.home.dealDateDetailStatistic(this.getDkDistributionStatisticFilter())
        return Object.keys(data).map(a => {
            return {
                name: a,
                type: 'money',
                data: data[a].map(a => a.row),
                date: data[a].map(a => (
                    a.column.includes('/')
                        ? a.column.replace(/-/g, '.') : a.column)
                ),
                rate: data[a].map(a => a.rate)
            }
        })
    }

    async daikanStatistic () {
        const { data } = await this.__apis.home.daikanStatistic(this.getDkDistributionStatisticFilter())
        return Object.keys(data).map(a => {
            return {
                name: a,
                data: data[a].map(a => a.row),
                date: data[a].map(a => (
                    a.column.includes('/')
                        ? a.column.replace(/-/g, '.') : a.column)
                ),
                rate: data[a].map(a => a.rate)
            }
        })
    }
    async distributionStatistic () {
        const { data } = await this.__apis.home.distributionStatistic(this.getDkDistributionStatisticFilter())
        return Object.keys(data).map(a => {
            return {
                name: a,
                data: data[a].map(a => a.row),
                date: data[a].map(a => (
                    a.column.includes('/')
                        ? a.column.replace(/-/g, '.') : a.column)
                )
            }
        })
    }

    getDkDistributionStatisticFilter () {
        return {
            dep: this.history.dep !== -1 ? +this.history.dep : undefined,
            query_date_type: this.queryDateTypeHandle(
                this.lookPreFilter.date.length ? this.lookPreFilter.date : this.history.date
            ) as string,
            dateQrl: {
                query_start_time: this.lookPreFilter.date[0] || this.history.date[0],
                query_end_time: this.lookPreFilter.date[1] || this.history.date[1]
            }
        }
    }
    queryDateTypeHandle (dateArr: string[]) {
        if (!dateArr.length || dateArr.length !== 2) return 'DAY'
        dateArr = dateArr.map(a => a.replace(/-/g, '/'))
        const timeStamp = (new Date(dateArr[1]).getTime() - new Date(dateArr[0]).getTime()) / (1 * 24 * 60 * 60 * 1000)
        if (timeStamp < 28) {
            return 'DAY'
        } else if (timeStamp >= 28 && timeStamp <= 31) {
            return 'WEEK'
        } else if (timeStamp > 31) {
            return 'MONTH'
        }
    }

    lookPreFilterOptions = usePickerLimitDisabledDate((time: Date, startDate?: Date) => {
        if (startDate) {
            const startDateTime = startDate.getTime()
            const days = dateRangeUtil.getMonthDays(time.getFullYear(), time.getMonth())
            const timeStamp = 1 * 24 * 60 * 60 * 1000
            const maxMonth = startDateTime + (days * timeStamp)
            const minMonth = startDateTime - (days * timeStamp)
            const max7 = startDateTime + (6 * timeStamp)
            const min7 = startDateTime - (6 * timeStamp)
            const getTime = time.getTime()
            return (getTime > min7 || getTime < minMonth) && (getTime < max7 || getTime > maxMonth)
        }
        return false
    })
    lookPreFilter: {
        date: string[]
    } = {
        date: []
    }
    lookPreOptionalDate: undefined | number = undefined

    @Watch('lookPreFilter.date')
    async lookPreFilterDateChange () {
        if (!this.lookPreFilter.date.length) return
        this.lookPreOptionalDate = 1
        const [daikanCountRate, orderDispatchCustomer] = await Promise.all([
            this.daikanStatistic(),
            this.distributionStatistic()
        ])
        this.historyChartData = {
            ...this.historyChartData,
            daikanCountRate,
            orderDispatchCustomer
        }
        this.lookDispatchChart()
    }

    lookDispatchChart () {
        const lookChart = echarts.init(this.$refs.lookChart as HTMLDivElement)
        const preChart = echarts.init(this.$refs.preChart as HTMLDivElement)
        setLineChart(lookChart, this.historyChartData.daikanCountRate, true) // 带看数/带看率
        setLineChart(preChart, this.historyChartData.orderDispatchCustomer) // 预约分配客户数
    }

    initCharts () {
        const depMoneyAnalysisChart = echarts.init(this.$refs.depMoneyAnalysisChart as HTMLDivElement)
        const successMoneyAnalysisChart = echarts.init(this.$refs.successMoneyAnalysisChart as HTMLDivElement)
        const customerGetChart = echarts.init(this.$refs.customerGetChart as HTMLDivElement)
        const backMoneyChart = echarts.init(this.$refs.backMoneyChart as HTMLDivElement)

        this.setDepMoneyAnalysisChart(depMoneyAnalysisChart, this.historyChartData.cjMoney) // 各部门成交金额统计
        this.setCustomerGetChart(customerGetChart, this.historyChartData.singleCustomerMoney) // 单客创收
        setLineChart(backMoneyChart, this.historyChartData.fyMoneyRate, true) // 返佣金额返佣率
        setLineChart(successMoneyAnalysisChart, this.historyChartData.cjMoneyRate, true) // 成交金额/成交率

        this.lookDispatchChart()
    }

    setCustomerGetChart (chart: ECharts, data: NameValue[]) {
        const colors = ['rgba(146, 112, 202, 0.85)', 'rgba(146, 112, 202, 0.85)', 'rgba(146, 112, 202, 0.85)']
        const legend = data.map(a => a.name)
        const chartData = data.map((a, i: number) => {
            return {
                value: a.value,
                itemStyle: {
                    color: colors[0]
                }
            }
        })
        chart.setOption({
            tooltip: {
                trigger: 'axis',
                padding: [8, 12],
                backgroundColor: 'rgba(255,255,255,1)',
                extraCssText: 'box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);border-radius: 2px;',
                textStyle: {
                    color: 'rgba(0, 0, 0, 0.8)'
                },
                formatter: (params: any) => {
                    // console.log(params)
                    params = params[0]
                    let result = `<div>${params.name}</div>
                        <div>
                            <span style="display:inline-block;vertical-align: middle;margin-right:4px;border-radius:50%;
                                width:10px;height:10px;background-color:${params.color};">
                            </span>
                            <span style='margin-right:4px;'>
                                ¥${this.__utils.index.numFormat(+params.data.value)}
                            </span>
                            </span>
                        </div>`
                    return result
                }
            },
            color: colors,
            grid: {
                left: 0,
                top: '4%',
                right: '9%',
                bottom: 24,
                containLabel: true
            },
            xAxis: {
                axisLine: {
                    show: true
                },
                axisTick: {
                    alignWithLabel: true
                },
                type: 'value',
                splitLine: {
                    show: false
                }
            },
            yAxis: {
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                type: 'category',
                data: legend
            },
            series: [
                {
                    type: 'bar',
                    barMaxWidth: 30,
                    data: chartData,
                    barGap: 0,
                    label: {
                        show: true,
                        position: 'right',
                        // fontWeight: 'bold',
                        color: 'rgba(0, 0, 0, 0.6)',
                        borderType: '',
                        textBorderWidth: 0,
                        textBorderColor: 'transparent',
                        textBorderType: ''
                    }
                }
            ] as any
        })
    }

    setDepMoneyAnalysisChart (chart: ECharts, data: NameValue[]) {
        const legend = data.map(a => a.name)
        chart.setOption({
            tooltip: {
                trigger: 'item',
                padding: [8, 12],
                backgroundColor: 'rgba(255,255,255,1)',
                extraCssText: 'box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);border-radius: 2px;',
                textStyle: {
                    color: 'rgba(0, 0, 0, 0.8)'
                },
                formatter: (params: any) => {
                    let result = `<div>
                        <span style="display:inline-block;vertical-align: middle;margin-right:4px;border-radius:50%;
                            width:10px;height:10px;background-color:${params.color};">
                        </span>
                        <span style='margin-right:4px;'>
                            ${params.data.name}
                        </span>
                        ¥${this.__utils.index.numFormat(+params.data.value)} / ${params.percent}%
                        </span>
                    </div>`
                    return result
                }
            },
            color: colors,
            legend: {
                show: true,
                type: 'scroll',
                orient: 'vertical',
                right: 10,
                top: 'middle',
                data: legend,
                icon: 'circle',
                itemWidth: 8,
                itemHeight: 8,
                textStyle: {
                    borderRadius: 4
                }
            },
            series: [
                {
                    type: 'pie',
                    radius: '78%',
                    center: ['43%', '50%'],
                    label: {
                        formatter: '{b}',
                        color: '#333',
                        lineHeight: 16
                    },
                    avoidLabelOverlap: true, // 开启防重叠策略
                    data: data
                }
            ]
        })
    }

    exportWorkBoard () {
        this.__utils.index.downloadFile({
            type: this.links.active,
            time_start: this.date[0],
            time_end: this.date[1],
            group: this.groupFilter || undefined
        }, apiConfig.jUrl + '/admin/data/analysis/userWorkBoard/download')
    }

    xlsxExport () {
        const list = this.table.data.reduce((s, a) => {
            s.push(
                this.table.list.map((b, i) => {
                    return a[b.prop as keyof TableItem]
                })
            )
            return s
        }, [] as any[])
        const ws = XLSX.utils.aoa_to_sheet([
            this.table.list.map(a => a.label),
            ...list
        ])
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'SheetJS')
        XLSX.writeFile(wb, `${this.date[0]}/${this.date[1]}_${Date.now()}.xlsx`)
    }

    // 过滤每一列的类容
    formatColumn (r: any, c: any, cellValue: any) {
        if (c.label.includes('金额') || c.label.includes('利润')) {
            return this.__utils.index.numFormat(cellValue)
        }
        return cellValue
    }
}
