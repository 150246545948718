import { ECharts } from 'echarts'
import { __utils } from '@/util/index.ts'
import Format = echarts.EChartOption.Tooltip.Format;

export const colors = [
    'rgba(91, 143, 249, 0.85)',
    'rgba(90, 216, 166, 0.85)',
    'rgba(246, 189, 22, 1)',

    'rgba(42, 99, 202, 0.85)',
    'rgba(69, 54, 177, 0.85)',
    'rgba(109, 200, 236, 0.85)',
    'rgba(146, 112, 202, 0.85)',
    'rgba(255, 157, 77, 0.85)',
    'rgba(38, 154, 153, 0.85)',
    'rgba(90, 212, 216, 0.85)',
    'rgba(255, 153, 195, 0.85)',
    'rgba(243, 43, 203, 0.85)',
    'rgba(189, 210, 253, 0.85)',
    'rgba(90, 216, 166, 0.85)',
    'rgba(189, 239, 219, 0.85)',
    'rgba(170, 202, 112, 0.85)',
    'rgba(194, 200, 213, 0.85)',
    'rgba(246, 22, 22, 0.85)',
    'rgba(251, 229, 162, 0.85)',
    'rgba(232, 104, 74, 0.85)'
]

export function setLineChart (
    chart: ECharts,
    data: { name: string, data: string[], date: string[], rate?: string[], type?: string }[],
    formatRate: boolean = false,
    series?: any
) {
    chart.clear()
    const first = data[0] || {
        name: '',
        data: [],
        date: [],
        type: ''
    }
    // 剪去一部分大于当前日期的数据
    data.forEach(a => {
        for (let i = a.date.length - 1; i > 0; i--) {
            let b = a.date[i]
            if (b.includes('/')) {
                let c = b.split('/')
                if (
                    new Date(c[0].replace('.', '/')).getTime() > Date.now()
                ) {
                    a.date.splice(i, 1)
                    a.data.splice(i, 1)
                } else {
                    break
                }
            } else {
                const d = b.split('-')
                if (d.length === 2 && new Date(b).getTime() > new Date().getTime()) {
                    a.date.splice(i, 1)
                    a.data.splice(i, 1)
                }
            }
        }
    })
    chart.setOption({
        tooltip: {
            trigger: 'axis',
            padding: [
                10,
                16,
                4,
                16 // 左
            ],
            backgroundColor: 'rgba(255,255,255,1)',
            extraCssText: 'box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);border-radius: 2px;',
            textStyle: {
                color: 'rgba(0, 0, 0, 0.8)'
            },
            formatter (params: Format[]) {
                let result = ''
                params.sort((a, b) => b.data - a.data).forEach((item, index) => {
                    if (index === 0) {
                        result += `<div style='margin-bottom: 8px;'>${item.name}</div>`
                    }
                    item.seriesIndex = item.seriesIndex || 0
                    let rate = ''
                    // if (data && data[item.seriesIndex] && data[item.seriesIndex].rate) {
                    //     // @ts-ignore
                    //     rate = data[item.seriesIndex].rate[item.dataIndex]
                    // }
                    const curItem = data.filter(a => a.name === item.seriesName)
                    rate = curItem[0] && curItem[0].rate && item.dataIndex !== undefined ? curItem[0].rate[item.dataIndex] : ''
                    result += `<div style="margin-bottom: 8px;">
                        <span style="display:inline-block;vertical-align: middle;margin-right:5px;border-radius:50%;
                            width:10px;height:10px;background-color:${colors[item.seriesIndex || 0]};">
                        </span>
                        <span style='display: inline-block;min-width: 86px;margin-right: 0px;'>
                            ${item.seriesName}
                        </span>
                        ${first.type === 'money' ? `¥${__utils.index.numFormat(+item.data)}` : item.data}<span style='visibility: ${formatRate ? 'visible' : 'hidden'}'>/${rate}%
                        </span>
                    </div>`
                })
                return result
            }
        } as unknown as echarts.EChartOption.Tooltip,
        color: colors,
        legend: {
            left: 0,
            top: 10,
            itemWidth: 12,
            itemHeight: 2,
            icon: 'rect',
            data: data.map(a => a.name)
        },
        grid: {
            left: 8,
            right: '6%',
            bottom: 22,
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: first.date
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            }
        },
        series: series || data.map(a => {
            return {
                type: 'line',
                symbol: 'emptyCircle',
                name: a.name,
                data: a.data
            }
        })
    })
}
