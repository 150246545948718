import { Component, Vue } from 'vue-property-decorator'
import gzList from '@/views/financial/gongziManage/layout/components/gzList/index.vue'
import gzEdit from '@/views/financial/gongziManage/layout/components/gzEdit/index.vue'

import dateUtil from '@/util/date.ts'

@Component({
    components: {
        gzList,
        gzEdit
    },
    provide () {
        return {
            'parentThis': this
        }
    }
})
export default class IndexPage extends Vue {
    datePicker = new Date(dateUtil.getPreviousMonth()[0]).format('yyyy-MM') || ''
    page = 1
    pageSize = 10
    componentCur = 'gzList'
    refresh = false
    filter = {}

    pageChange (page: number) {
        this.page = page
    }

    editListHandle (isEdit: boolean) {
        this.componentCur = isEdit ? 'gzEdit' : 'gzList'
    }

    refreshHandle (bl: any) {
        this.refresh = bl
    }

    filterHandle (obj: any) {
        this.filter = obj
    }

    dateChange (val: any) {
        this.datePicker = val
    }
}
