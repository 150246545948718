import { Component, Vue } from 'vue-property-decorator'
import buttonsComponent from '@/components/wallet/buttons/index.vue'
import endComponent from './end/index.vue'
import waitComponent from './wait/index.vue'
import endDetailComponent from './end-detail/index.vue'
import WaitCommissionComponent from '@/views/qmWallet/fxs/wait-commission/index.vue'
import NonWageSurplusComponent from '@/views/qmWallet/fxs/non-wage-surplus/index.vue'
import { DepInfoVar } from '@/util/const'

@Component({
    components: {
        buttonsComponent,
        endComponent,
        waitComponent,
        endDetailComponent,
        WaitCommissionComponent,
        NonWageSurplusComponent
    }
})
export default class IndexPage extends Vue {
    buttonsComponent = {
        active: 0,
        items: ['已到账', '未到账', '已到账明细', '未发放提成']
    }

    get
    isKm () {
        return this.__store.state.user.infoPos.dep >= DepInfoVar.KUNMING
    }

    created () {
        if (this.isKm) {
            this.buttonsComponent.items = ['已到账', '已到账明细']
        }
    }

    tabsChange (a: number) {
        this.buttonsComponent.active = a
    }
}
