import { Component, Vue, Watch } from 'vue-property-decorator'
import { Table } from '@/types'
import echarts, { EChartOption } from 'echarts'
import FilterComponent from '@/components/filters/filter/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'
import dateRangeSelectComponent from '@/components/wallet/date-range-select/index.vue'
import { __utils } from '@/util'

interface TableItem {
    id: number,
    department: string,
    group: string,
    fxs: string,
    type: string,
    remark: string,
    time: string,
    money: string
}

interface Filter {
    [index: string]: string | string[]
    fxs: string
    time: string[]
    type: string
}

const currentTime = __utils.index.formatTimeStartTimeEnd(['2020-03-01', __utils.index.formatDate(new Date(), 'YYYY-MM-DD')])

@Component({
    components: {
        SelectComponent,
        FilterComponent,
        InputDateRangeComponent,
        CheckBoxComponent,
        dateRangeSelectComponent
    }
})
export default class IndexPage extends Vue {
    // 时间发生变更
    @Watch('dateRangeSelectComponent.time')
    watchDateRange () {
        this.drawCharts()
    }

    groupName = ''

    filter: Filter = {
        type: '',
        department: '',
        group: '',
        fxs: '',
        time: []
    }

    types = [
        {
            value: 1,
            label: '带看'
        }, {
            value: 2,
            label: '成交'
        }, {
            value: 3,
            label: '跟进'
        }, {
            value: 4,
            label: '发放'
        }
    ]

    chars = {
        money: '',
        total_money: ''
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 50
    }

    dateRangeSelectComponent = {
        time: currentTime// 本月
    }

    mounted () {
        if (this.__role.has([this.__role.ROLE.SHENGZHANG])) {
            this.drawCharts()
        }

        this.getTableData()

        this.groupName = decodeURIComponent(this.$route.query.name.toString())
    }

    async getTableData () {
        const { data } = await this.__apis.wallet.getGroupWalletRecordList({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            group_id: this.$route.query.id.toString(),
            time_start: this.filter.time[0],
            time_end: this.filter.time[1],
            type: this.filter.type
        })

        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.Id,
                department: a.DepName,
                group: a.GroupName,
                fxs: '',
                type: this.types.filter(p => p.value === a.Type)[0].label,
                remark: a.Remark,
                time: a.Ctime,
                money: this.__utils.index.formatMoney(a.Money)
            }
        })
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        if (!this.filter.time) {
            this.filter.time = []
        } else {
            this.filter.time = this.__utils.index.formatTimeStartTimeEnd(this.filter.time)
        }

        this.getTableData()
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }

    async drawCharts () {
        const chart = echarts.init(document.querySelector('.echarts-instance') as HTMLDivElement)

        const { data } = await this.__apis.wallet.groupWalletStatistics({
            time_start: this.dateRangeSelectComponent.time[0],
            time_end: this.dateRangeSelectComponent.time[1],
            group_id: Number(this.$route.query.id)
        })

        const charListValue = Object.values(data.char_list)[0]
        this.chars.total_money = this.__utils.index.formatMoney(data.money_info.total_money)
        this.chars.money = this.__utils.index.formatMoney(data.money_info.money_list[0].money)

        let option: EChartOption = {
            xAxis: {
                type: 'category',
                data: charListValue.map(a => this.__utils.index.formatChartDate(a.date)),
                splitLine: {
                    show: false
                },
                axisLabel: {
                    formatter (val: string) {
                        return val.replace(' ', '\n')
                    },
                    color: 'rgba(0,0,0,0.45)'
                },
                axisTick: {
                    alignWithLabel: true
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(0, 0, 0, .1)'
                    }
                }
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: 'rgba(0,0,0,0.45)',
                    verticalAlign: 'middle'
                }
            },
            color: ['#FA8C16'],
            tooltip: {
                trigger: 'axis'
            },
            series: [{
                data: charListValue.map(a => this.__utils.index.formatMoney(a.value)),
                lineStyle: {
                    color: '#FA8C16'
                },
                type: 'line',
                symbol: 'none'
            }],
            grid: {
                width: 752,
                height: 148,
                top: 12,
                left: 0,
                containLabel: true
            }
        }

        chart.setOption(option)
    }
}
