import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ReasonComponent extends Vue {
    @Prop({
        default: false,
        type: Boolean
    })
    show !: boolean

    textarea = ''

    get
    cmtBtnEnterDisabled () {
        return !this.textarea.trim()
    }

    close () {
        this.$emit('update:show', false)
    }

    enter () {
        this.$emit('enter', this.textarea)
        this.close()
    }
}
