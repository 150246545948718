import { Component, Vue } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import { Table } from '@/types'
import { ChongZhiWalletMoneyActionTypeList } from '@/util/const'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'

interface TableItem {
    id: number
    employeeId: string
    department: string,
    fxs: string,
    money: string,
    remark: string
}

interface Filter {
    [index: string]: string
    fxs: string
    type: string
}

@Component({
    components: {
        FilterComponent,
        SelectComponent,
        CheckBoxComponent
    }
})
export default class IndexPage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 50
    }

    filter: Filter = {
        fxs: '',
        type: ''
    }

    ChongZhiWalletMoneyActionTypeList = ChongZhiWalletMoneyActionTypeList

    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        this.getTableData()
    }

    searchFxs (text: string, cb: any) {
        this.__utils.index.searchEmployee(text, cb)
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }

    async getTableData () {
        const { data } = await this.__apis.chongzhiWallet.walletRecordList({
            type: +this.filter.type || undefined,
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            employee_id: this.filter.fxs || (this.$route.query.employee_id ? this.$route.query.employee_id as string : undefined)
        })

        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.employee_id,
                department: this.__utils.index.getComDepInfoById(a.employee_common_dep).name,
                fxs: a.employee_user_name,
                remark: a.remark,
                money: this.__utils.index.formatMoney(a.money),
                employeeId: a.employee_id.toString(),
                ctime: this.__utils.dayjs(a.ctime).format('YYYY-MM-DD HH:mm'),
                type: this.__utils.index.getLabelByValue(ChongZhiWalletMoneyActionTypeList, a.type),
                oldMoney: this.__utils.index.formatMoney(a.wallet_money_before),
                newMoney: this.__utils.index.formatMoney(a.wallet_money_after)
            }
        })
    }

    created () {
        this.getTableData()
    }
}
