import { Component, Vue, Watch } from 'vue-property-decorator'
import echarts from 'echarts'
import { Table } from '@/types'
import { routerBaseUrl } from '@/router'
import { __utils, groupColors } from '@/util'
import dateRangeSelectComponent from '@/components/wallet/date-range-select/index.vue'
import FilterComponent from '@/components/filters/filter/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import dateRange from '@/util/date'
import EChartOption = echarts.EChartOption

interface TableItem {
    id: number
    department: string
    group: string
    month: string
    money: string
}

interface Filter {
    [index: string]: string | string[]
    department: string
    group: string
    month: string[]
}

interface Chart {
    loading: boolean
    department: number
    money: string
    total_money: string
    legends: {
        money: string
        name: string
        color: string
    }[]
}

const currentTime = __utils.index.formatTimeStartTimeEnd(dateRange.getCurrentMonth().map(a => `${__utils.index.formatDate(a, 'YYYY-MM-DD')}`))

@Component({
    components: {
        dateRangeSelectComponent,
        FilterComponent,
        InputDateRangeComponent,
        SelectComponent
    }
})
export default class IndexPage extends Vue {
    // 时间发生变更
    @Watch('dateRangeSelectComponent.time')
    watchDateRange () {
        this.drawCharts()
    }

    get
    deps () {
        const dep_arr = this.__store.state.user.infoPos.dep_arr
        return dep_arr.concat({ k: 0, v: '全部' })
    }

    filter: Filter = {
        department: '',
        group: '',
        month: currentTime
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 100,
        total: 0
    }

    chart: Chart = {
        department: 0,
        money: '',
        total_money: '',
        legends: [],
        loading: false
    }

    dateRangeSelectComponent = {
        time: __utils.index.formatTimeStartTimeEnd(['2020-03-01', __utils.index.formatDate(new Date(), 'YYYY-MM-DD')])
    }

    mounted () {
        this.getTableData()

        this.drawCharts()
    }

    async getTableData () {
        const { data } = await this.__apis.wallet.groupWalletMonthRecordES({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            dep_id: this.filter.department,
            group_id: this.filter.group,
            time_start: this.filter.month[0],
            time_end: this.filter.month[1]
        })

        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.group_id,
                department: a.dep_name,
                group: a.group_name,
                month: this.__utils.index.formatChartDate(a.month),
                money: this.__utils.index.formatMoney(a.money)
            }
        })
    }

    searchDep (text: string, cb: any) {
        this.__utils.index.searchDep(text, cb)
    }

    searchGroup (text: string, cb: any) {
        this.__utils.index.searchGroup(text, cb, { dep: this.filter.department })
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        if (!this.filter.month) {
            this.filter.month = currentTime
        } else {
            this.filter.month = __utils.index.formatTimeStartTimeEnd(this.filter.month)
        }

        this.getTableData()
    }

    gotoDetail (id: number, name: string) {
        open(`${routerBaseUrl}/qm-wallet/group/detail?id=${id}&name=${encodeURIComponent(name)}`, '_blank')
    }

    async drawCharts () {
        // 表格高度重新设置
        this.chart.loading = true

        const { data } = await this.__apis.wallet.groupWalletStatistics({
            dep_id: this.chart.department || undefined,
            time_start: this.dateRangeSelectComponent.time[0],
            time_end: this.dateRangeSelectComponent.time[1]
        })
        const colors = groupColors

        // 有可能部门下面没有战队
        let allMoney = 0
        if (data.money_info.money_list.length) {
            allMoney = data.money_info.money_list.map(a => a.money).reduce((a, b) => a + b)
        }
        this.chart.money = this.__utils.index.formatMoney(allMoney)

        this.chart.total_money = this.__utils.index.formatMoney(data.money_info.total_money)
        this.chart.legends = data.money_info.money_list.map((a) => {
            return {
                name: a.group_name,
                money: this.__utils.index.formatMoney(a.money),
                color: colors[a.group_id]
            }
        })

        // 表格高度重新设置
        await this.__utils.index.sleep(0)
        this.chart.loading = false
        await this.__utils.index.sleep(0)

        // 战队收入文字大小动态设置
        const echartsBoxHeight = $('.charts .money-chart .echars-box').height() as number
        $('.charts .money-chart .echars-box .center-label .bottom').css({
            fontSize: this.__utils.index.cmtDynamicNumber(echartsBoxHeight, 199, 300, 22, 34),
            marginTop: this.__utils.index.cmtDynamicNumber(echartsBoxHeight, 199, 300, 1, 16)
        })

        // 饼图
        if (this.__role.has(this.__role.TYPE.SZCWKJ)) {
            const chart = echarts.init(document.querySelector('.echarts-bing-instance') as HTMLDivElement)
            const option: EChartOption = {
                tooltip: {
                    trigger: 'item'
                },
                color: this.chart.legends.map(a => a.color),
                series: [ {
                    type: 'pie',
                    radius: ['72%', '90%'],
                    avoidLabelOverlap: true,
                    label: {
                        normal: {
                            show: false
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data: data.money_info.money_list.map((a) => {
                        return {
                            value: this.__utils.index.formatMoney(a.money),
                            name: a.group_name,
                            itemStyle: {
                                color: colors[a.group_id]
                            }
                        }
                    }) as any[]
                }],
                grid: {
                    width: 428,
                    height: '100%',
                    top: 0,
                    left: 0,
                    containLabel: true
                }
            }
            chart.setOption(option)
        }

        // 折线图
        const chart = echarts.init(document.querySelector('.echarts-instance') as HTMLDivElement)
        let series: any[] = []

        // 日期
        let dates: string[] = []
        const charListValues = Object.values(data.char_list)
        if (charListValues.length) {
            dates = charListValues[0].map(a => this.__utils.index.formatChartDate(a.date))
        }

        data.money_info.money_list.forEach((a) => {
            const b = data.char_list[a.group_id]
            series.push({
                data: b.map(c => this.__utils.index.formatMoney(c.value)),
                lineStyle: {
                    color: colors[a.group_id]
                },
                type: 'line',
                smooth: true,
                symbol: 'none'
            })
        })

        const option: EChartOption = {
            xAxis: {
                type: 'category',
                data: dates,
                splitLine: {
                    show: false
                },
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    formatter (val: string) {
                        return val.replace(' ', '\n')
                    },
                    color: 'rgba(0,0,0,0.45)'
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(0, 0, 0, .1)'
                    }
                }
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: 'rgba(0,0,0,0.45)',
                    verticalAlign: 'middle'
                }
            },
            tooltip: {
                trigger: 'axis',
                formatter (a) {
                    let res = ''

                    if (Array.isArray(a)) {
                        res = `<div>${a[0].name}</div>`

                        // 根据最大的金额设置width
                        let moneyLabelWidth = 0
                        a.forEach(b => {
                            moneyLabelWidth = Math.max(moneyLabelWidth, b.data.length)
                        })

                        a.forEach((b, i) => {
                            res += `<div>
${b.marker}
<span style="display: inline-block;margin-right: 8px;width: ${moneyLabelWidth * 8}px">${b.data}</span>
<span>${data.money_info.money_list[i].group_name}</span>
</div>`
                        })
                    } else {
                        res = `<div>${a.name}</div>`

                        res += `<div>${a.marker}
<span style="margin-right: 8px;">${a.data}</span>
<span>${data.money_info.money_list[0].group_name}</span>
</div>`
                    }

                    return res
                }
            },
            color: this.chart.legends.map(a => a.color),
            series: series,
            grid: {
                width: '100%',
                height: this.__role.has(this.__role.TYPE.SZCWKJ) ? '95%' : 148,
                top: 12,
                left: 0,
                containLabel: true
            }
        }
        chart.setOption(option)
    }
}
