import { Component, Vue } from 'vue-property-decorator'

@Component
export default class KnowLedgeAddCatePage extends Vue {
    labelTemp = ''
    labelFocus = false
    editData = {}
    editId = ''
    data: any[] = []
    dataObj: any = {}

    get
    id () {
        return this.$route.query.id as string
    }

    mounted () {
        this.getData()
    }

    // 获取知识库详情
    async getData () {
        const res = await this.__apis.knowledge.getKnowledgeLibDetail(this.id)

        let datas = res.data
        datas.DirSort = datas.DirSort ? JSON.parse(datas.DirSort) : []
        this.data = [
            {
                id: 0,
                label: datas.Name,
                children: datas.DirSort
            }
        ]
        this.dataObj = datas
    }

    // 添加目录
    async append (data: any, node: any) {
        if (node.level >= 3) {
            this.$notify({
                title: '警告',
                message: '目录最多三层！',
                type: 'warning'
            })
            return
        }
        const newChild = {
            id: '',
            label: '新目录',
            children: []
        }
        if (!data.children) {
            this.$set(data, 'children', [])
        }
        let obj = {
            name: newChild.label,
            lib_id: this.id, // 词库id
            pid: !data.id ? 0 : data.id
        }

        const res = await this.__apis.knowledge.saveKnowledgeLibDir(obj)

        newChild.id = res.data.id
        data.children.push(newChild)
        this.saveMenu(true)
    }

    // 删除目录
    async remove (node: any, data: any) {
        const parent = node.parent
        const children = parent.data.children || []
        const index = children.findIndex((d: any) => d.id === data.id)

        await this.__apis.knowledge.deleteKnowledgeLibDir(data.id)

        children.splice(index, 1)
        this.saveMenu(true)
    }

    // tree label 点击
    labelInputClick (data: any, node: any) {
        if (!data.id) return

        this.labelTemp = data.label
        this.editId = node.id
        this.editData = data
        this.labelFocus = true
    }

    // tree hide
    hideInput (data: any, node: any) {
        if (this.editId === node.id && this.labelFocus) return
        this.editId = ''
    }

    focusInput () {
        this.labelFocus = true
    }

    blurInput (data: any) {
        this.labelFocus = false
        data.label = this.labelTemp.trim() || '新目录'
        this.saveDirName(data)
    }

    hideAll () {
        this.editId = ''
    }

    enterInput (data: any) {
        this.blurInput(data)
        this.hideAll()
    }

    // 修改目录名
    async saveDirName (data: any) {
        await this.__apis.knowledge.saveKnowledgeLibDir({
            id: data.id,
            name: data.label
        })
    }

    // 点击保存
    async saveMenu (bl: boolean) {
        await this.__apis.knowledge.saveKnowledgeLib({
            id: this.id,
            dir_sort: JSON.stringify(this.data[0].children)
        })

        if (bl) return
        this.$router.push({
            name: '/knowledge/addCateOk',
            query: {
                id: this.id
            }
        })
    }
}
