import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import mixin from '../../mixin'
import EditComponent from '@/components/customer/phone/edit/index.vue'
import InvalidComponent from '@/components/customer/phone/invaild/index.vue'
import marketCustomerComponent from '@/components/customer/phone/marketCustomer/index.vue'
import { EditData } from '@/components/customer/phone/edit'
import { User } from '@/store/user'
import { routerBaseUrl } from '@/router'

interface TableItem {
    id: string
    customer_id: number
    house_id: string
    name: string
    mobile_fixed: string
    house_name: string
    demand_region: string
    type_array: string[]
    register_time: string
    has_dispatch: boolean
    tags: User['sysTags']
    remark: {
        log: string
    }[]
}

@Component({
    mixins: [mixin],
    components: {
        EditComponent,
        InvalidComponent,
        marketCustomerComponent
    }
})
export default class IndexPage extends Vue {
    invalidComponent = {
        show: false,
        id: ''
    }

    editComponent: {
        show: boolean
        item: EditData
    } = {
        show: false,
        item: {
            id: '',
            name: '',
            mobile: '',
            ctime: '',
            house_id: '',
            house_name: '',
            tags: [],
            remark: ''
        }
    }

    addMarketingCustomerComponent = {
        show: false
    }

    table: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 10,
        total: 0
    }

    // 排序需要用到的
    sortType = ''

    // 排序需要用到的
    sortMode = ''

    form = {
        ctime: [],
        name: '',
        mobile: ''
    }

    get
    sysTags () {
        return this.__store.state.user.sysTags
    }

    mounted () {
        this.getData()
    }

    addMarketCustomer () {
        this.addMarketingCustomerComponent.show = true
    }

    gotoDetail (item: TableItem) {
        open(`${routerBaseUrl}/customer/detail/${item.customer_id}`)
    }

    // 分配客户
    async setItem (item: TableItem) {
        try {
            await this.__apis.customer.customerCallDispatch(+item.id)
            this.getData()
            this.$message.success('分配成功')
        } catch (e) {
            this.$message.error('分配失败')
            throw e
        }
    }

    // 标记无效
    setInvalid (item: TableItem) {
        this.invalidComponent.id = item.id
        this.invalidComponent.show = true
    }

    cmtBtnEditDisabled (item: TableItem) {
        return item.has_dispatch
    }

    // 客户姓名 意向楼盘
    cmtBtnSetDisabled (row: TableItem) {
        return !(row.house_id !== '0' && row.name) || this.cmtBtnEditDisabled(row)
    }

    // 操作客户成功
    editSuccess () {
        this.getData()
    }

    editItem (item: TableItem) {
        this.editComponent.item = {
            house_name: item.house_name,
            ctime: item.register_time,
            house_id: item.house_id,
            mobile: item.mobile_fixed,
            name: item.name,
            tags: item.tags,
            id: item.id,
            remark: item.remark[0] ? item.remark[0].log : ''
        }

        this.editComponent.show = true
    }

    async getData () {
        this.table.data = []

        const { data } = await this.__apis.customer.customerCall({
            origin: 'toutiao',
            sort_field: this.sortType,
            sort_mode: this.sortMode,
            customer_name: this.form.name,
            customer_tel: this.form.mobile,
            registerDate: this.form.ctime && this.form.ctime.length ? {
                query_start_time: `${this.form.ctime[0]} 00:00:00`,
                query_end_time: `${this.form.ctime[1]} 23:59:59`
            } : undefined,
            page: this.table.page,
            pagesize: this.table.pageSize
        })
        this.table.data = data.list.map(a => {
            return {
                name: a.customer_name,
                house_name: a.house_name,
                mobile_fixed: a.phone,
                demand_region: a.demand_region,
                type_array: a.type_array,
                register_time: this.__utils.index.formatDate(new Date(a.register_time), 'YYYY-MM-DD HH:mm:ss'),
                has_dispatch: a.has_dispatch,
                house_id: (a.house_id || 0).toString(),
                tags: a.sys_tags.length ? a.sys_tags.split(',').reduce((s, a) => {
                    s.push(this.sysTags[a as any])
                    return s
                }, [] as User['sysTags']) : [],
                id: a.id.toString(),
                customer_id: a.customer_id,
                remark: a.follower_logs
            }
        })
        this.table.total = data.total
    }

    pageChange (page: number) {
        this.table.page = page
        this.getData()
    }

    clickSearch () {
        this.table.page = 1
        this.getData()
    }

    resetFrom () {
        const form = this.$refs['form'] as any
        form.resetFields()
        this.clickSearch()
    }

    async toPubPool (id: number) {
        await this.__apis.customer.toPubPool(id)
        this.$notify({
            title: '成功',
            message: '提交成功！',
            type: 'success'
        })
        await this.getData()
    }
}
