import { Component, Vue, Watch } from 'vue-property-decorator'
import navTab from '@/components/NavTab/index.vue'
import qmPermission from 'qm-plugin-admin-permission'
import { __role } from '@/role'
import router from '@/router'

const menus = [
    {
        name: '平台登录',
        route: { path: '/market/loginList' },
        authKey: __role.permissions.ShowMarketPlatformLoginPage
    },
    {
        name: 'ocpc绑定',
        route: { path: '/market/ocpc' },
        authKey: __role.permissions.ShowOcpcListPage
    }
]

qmPermission.filter(menus)

@Component({
    components: {
        navTab
    }
})
export default class MarketIndexPage extends Vue {
    menus = menus

    @Watch('$route')
    watchRoute (to: any) {
        if (to.fullPath === '/market') {
            menus[0] && router.replace(menus[0].route)
        }
    }

    created () {
        if (this.$route.fullPath === '/market') {
            this.menus[0] && this.$router.push(this.menus[0].route)
        }
    }
}
