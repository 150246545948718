import { Component, Vue } from 'vue-property-decorator'
import noData from '@/components/NoData/index.vue'
import InputSearch from '@/components/InputSearch/index.vue'
import guideDialogComponent from '@/components/customer/guideDialog/index.vue'

@Component({
    components: {
        noData,
        InputSearch,
        guideDialogComponent
    }
})
export default class CustomerGuiDeTotalPage extends Vue {
    formLabelWidth = '120px'
    dataObj: any = {}
    page = 1
    pageSize = 20

    dialogCommetVisible = false
    commet = ''
    commetId = ''

    editCurrent = {}
    dialogFormVisible = false

    created () {
        this.getData()
    }

    async getData () {
        const res = await this.__apis.customer.getDkResList({
            customer_id: this.$route.params.id,
            page: this.page,
            pagesize: this.pageSize
        })

        res.data.list.map((v: any) => {
            if (v.ExtData === '[object Object],[object Object]') {
                v.ExtData = []
            }
            if (v.ExtData === '[object Object]') {
                v.ExtData = ''
            }
            v.ExtData = v.ExtData !== '' ? JSON.parse(v.ExtData) : []
            v.ExtData2 = v.ExtData
            v.UserInfo = v.UserInfo ? JSON.parse(v.UserInfo) : []
            v.userStr = v.UserInfo.map((a:any) => a.user_name).join(', ')
        })
        this.dataObj = res.data
        this.dataObj.show = true
    }

    // 分页改变
    pageChange (page: number) {
        this.page = page
        this.getData()
    }

    // 评论弹窗
    commetDialog (obj: any) {
        this.commetId = obj.Id
        this.dialogCommetVisible = true
    }

    // 保存评论
    async addCommet () {
        await this.__apis.customer.saveDkCjComment({
            record_id: this.commetId,
            content: this.commet,
            type: 2
        })

        this.dialogCommetVisible = false
        this.commet = ''
        this.getData()

        this.$notify({
            title: '成功',
            message: '评论成功！',
            type: 'success'
        })
    }

    // 编辑弹窗
    editDialog (objs: any) {
        this.editCurrent = this.__utils.index.copyObj(objs)
        this.dialogFormVisible = true
    }
}
