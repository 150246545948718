import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import mixin from '@/views/customer/mixin'

interface TableItem {
    id: string
    saleman?: string
    is_repeat: number
    name: string
    mobile_fixed: string
    house_name: string
    demand_region: string
    type_array: string[]
    register_time: string
    remark: string[]
}

@Component({
    mixins: [mixin]
})
export default class IndexPage extends Vue {
    table: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 10,
        total: 0
    }

    mounted () {
        this.getRecordData()
    }

    async getRecordData () {
        const { data } = await this.__apis.customer.getLastPhoneDispatchList()

        this.table.data = data.map(a => {
            return {
                saleman: a.saleman,
                name: a.name,
                house_name: a.house_name,
                mobile_fixed: a.mobile_fixed,
                is_repeat: a.is_repeat,
                demand_region: a.demand_region,
                type_array: a.type_array,
                register_time: a.register_time,
                id: a.id.toString(),
                remark: a.brief_remark
            }
        })

        this.table.total = this.table.data.length
    }
}
