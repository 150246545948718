import { Component, Vue, Prop } from 'vue-property-decorator'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'

interface dataSource {
    customerId: number
}

@Component({
    components: {
        inputSelectSearch
    }
})
export default class ApplyInvalidCustomerDialogComponent extends Vue {
    @Prop({
        default: false
    })
    visible!: boolean

    @Prop({
        default: '创建关联客户'
    })
    title!: string

    @Prop({
        default: '请填写申请理由~'
    })
    placeholder!: string

    @Prop({
        default () {
            return {
                customerId: 0
            }
        }
    })
    data!: dataSource

    formLabelWidth = '70px'

    form: {
        customer_name: string,
        customer_tel: string,
        house_name: string,
        house_id?: number
    } = {
        customer_name: '',
        customer_tel: '',
        house_name: '',
        house_id: undefined
    }

    get
    btnSaveDisable () {
        return Object.keys(this.form).some(a => !this.form[a as 'customer_name'])
    }

    selectHouse (item: {id: number}) {
        this.form.house_id = item.id
    }

    btnText = '保存'
    async save () {
        if (!/^1[3456789]\d{9}$/.test(this.form.customer_tel)) {
            return this.$notify({
                title: '警告',
                message: '手机号填写错误!请重新填写',
                type: 'warning'
            })
        }

        if (this.__utils.index.limitRepeatClick()) return
        const btnText = this.btnText
        this.btnText = '保存中...'
        try {
            const form = this.form
            const { data } = await this.__apis.customer.createCustomer({
                name: form.customer_name,
                mobile: form.customer_tel,
                customer_house: form.house_id as number,
                bind_customer_id: this.data.customerId
            })
            this.$emit('save', data.id)
            this.close()
            this.$notify({
                title: '成功',
                message: '提交成功！',
                type: 'success'
            })
        } finally {
            this.btnText = btnText
            this.__utils.index.restoreRepeatClick()
        }
    }

    close () {
        this.$emit('update:visible', false)
    }
}
