import { Component, Vue, Watch } from 'vue-property-decorator'
import echars from 'echarts'

@Component
export default class IndexPage extends Vue {
    @Watch('filter', { deep: true })
    watchFilter () {
        this.getData()
    }

    filter: {
        dep: number,
        date: string[]
    } = {
        date: this.__utils.index.getDaysAgo(30),
        dep: 0
    }

    chart = {
        xxl: 0,
        search: 0,
        phone: 0,
        ai: 0,
        all: 0
    }

    get
    deps () {
        return [{ label: '全部', value: 0 }].concat(this.__utils.index.getXsDep())
    }

    mounted () {
        this.getData()
    }

    async getData () {
        const { data: { rows } } = await this.__apis.channelManage.getOrderCustomerInvalidApplyStatistics({
            start_date: this.filter.date[0],
            end_date: this.filter.date[1],
            dep_id: this.filter.dep === 0 ? undefined : this.filter.dep
        })

        const types = {
            search: 0,
            xxl: 0,
            all: 0,
            phone: 0,
            ai: 0
        }

        rows.forEach(a => {
            const type = ({
                1: 'search',
                2: 'xxl',
                3: 'phone',
                4: 'ai'
            })[a.type as 1 | 2 | 3 | 4] as keyof typeof types

            if (type) {
                types[type] = a.count
                types.all += a.count
            }
        })

        this.chart.ai = types.ai
        this.chart.phone = types.phone
        this.chart.xxl = types.xxl
        this.chart.search = types.search
        this.chart.all = types.all

        this.drawChart()
    }

    drawChart () {
        const chart = this.$refs.chart as HTMLDivElement
        echars.init(chart).setOption({
            tooltip: {
                trigger: 'axis'
            },
            grid: {
                top: '41px',
                left: '16px',
                right: '40px',
                bottom: '0',
                containLabel: true
            },
            xAxis: {
                name: '类型',
                type: 'category',
                data: ['信息流客户', '搜索客户', '电话客户', 'AI外呼'],
                axisTick: {
                    alignWithLabel: true
                }
            },
            yAxis: {
                type: 'value',
                name: '人数',
                nameGap: 25,
                nameTextStyle: {
                    align: 'right'
                }
            },
            series: [
                {
                    type: 'bar',
                    data: [{
                        value: this.chart.xxl,
                        itemStyle: {
                            color: 'rgba(63, 138, 240, 0.85)'
                        },
                        label: {
                            show: true
                        }
                    }, {
                        value: this.chart.search,
                        itemStyle: {
                            color: 'rgba(63, 138, 240, 0.85)'
                        },
                        label: {
                            show: true
                        }
                    }, {
                        value: this.chart.phone,
                        itemStyle: {
                            color: 'rgba(63, 138, 240, 0.85)'
                        },
                        label: {
                            show: true
                        }
                    }, {
                        value: this.chart.ai,
                        itemStyle: {
                            color: 'rgba(63, 138, 240, 0.85)'
                        },
                        label: {
                            show: true
                        }
                    }],
                    barWidth: 80
                }
            ]
        })
    }
}
