import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'
import { apiConfig } from '@/api/config'
import { storageName } from '@/localStorage'

@Component
export default class VueCropperIndex extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    visible !: boolean

    dialogVisible = true

    // 裁剪组件的基础配置option
    option = {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 150, // 默认生成截图框宽度
        autoCropHeight: 150, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
    }

    // 防止重复提交
    loading = false

    // 图片上传
    dialogImageUrl = apiConfig.apiUrl
    fileList = []

    fileName = '' // 本机文件地址
    uploadImgRes = {} // 上传后的图片对象

    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }

    @Watch('dialogVisible')
    watchDialogVisible () {
        this.$emit('cropper-visible', false)
    }

    mounted () {
        // this.option = this.options;
        this.dialogVisible = this.visible
    }

    // 本地选择图片
    uploadImg (e: any, num: number) {
        // 上传图片
        const file = e.target.files[0]
        this.fileName = file.name
        if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
            alert('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种')
            return false
        }
        const reader = new FileReader()
        reader.onload = (e: any) => {
            let data
            if (typeof e.target.result === 'object') {
                // 把Array Buffer转化为blob 如果是base64不需要
                data = window.URL.createObjectURL(new Blob([e.target.result]))
            } else {
                data = e.target.result
            }
            if (num === 1) {
                this.option.img = data // 把值给vue-cropper的option
            }
        }
        // 转化为blob
        reader.readAsArrayBuffer(file)
    }

    // 点击裁剪，拿到处理后的地址
    finish () {
        const cropper = this.$refs.cropper as any

        cropper.getCropBlob((data: string) => {
            this.upload(data)
        })
    }

    // 拿到地址后上传头像
    async upload (file: any) {
        try {
            this.loading = true
            const { access_token } = await this.__apis.reportManage.uploadFileAccessToken()
            const formData = new FormData()
            formData.append('action_name', 'uploadFile')
            formData.append('file', file)
            const res = await this.__apis.reportManage.uploadFile({
                token: access_token,
                filename: `${new Date().getTime()}${Math.random().toFixed(5)}`,
                file: formData
            })
            this.uploadImgRes = res
            this.$emit('cropper-ok', res)
            this.$emit('cropper-visible', false)
        } finally {
            this.loading = false
        }
    }
}
