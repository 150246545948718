import { Component, Prop, Vue } from 'vue-property-decorator'
import { LabelValueArray } from '@/types'
import echarts from 'echarts'
import { config, Config } from '@/views/home/overview/chart/const'
import { baseWorkInfoReq, TrendRes } from '@/api/home'
import { setLineChart } from '@/views/home/helper'

function queryDateTypeHandle (dateArr: string[]) {
    if (!dateArr.length || dateArr.length !== 2) return 'DAY'
    dateArr = dateArr.map(a => a.replace(/-/g, '/'))
    const timeStamp = (new Date(dateArr[1]).getTime() - new Date(dateArr[0]).getTime()) / (1 * 24 * 60 * 60 * 1000)
    if (timeStamp < 28) {
        return 'DAY'
    } else if (timeStamp >= 28 && timeStamp <= 31) {
        return 'DAY'
    } else if (timeStamp > 31) {
        return 'MONTH'
    }
}

@Component
export default class OverviewChartComponent extends Vue {
    @Prop({
        type: Object,
        default: () => {
            return {}
        }
    })
    filter !: baseWorkInfoReq

    @Prop({
        type: Number,
        default: 0
    })
    alertId !: number

    buttons: {
        list: LabelValueArray<number[]>,
        active: number
    } = {
        list: [],
        active: -1
    }

    check: {
        list: string[]
        value: string[]
    } = {
        list: ['所属战队平均线', '所属部门平均线', '全国平均线'],
        value: []
    }

    config: Config = {
        title: '',
        tip: ''
    }

    chartData: {
        name: string
        data: string[]
        date: string[]
    }[] = []

    created () {
        this.initButtons()
        if (this.filter.user_id) {
            this.check.list.push('分析师')
        }
        this.check.value = this.check.list.concat()

        this.config = config[this.alertId]
    }

    mounted () {
        this.chartLoad()
    }

    async chartLoad () {
        await this.getChartData()
        this.chartRender()
    }

    async getChartData () {
        const buttonsDate = this.buttons.active !== -1 ? this.buttons.list[this.buttons.active].value : undefined
        const dateQrl = buttonsDate ? {
            'query_start_time': this.__utils.index.formatDate(new Date(buttonsDate[0]), 'YYYY-MM-DD 00:00:00'),
            'query_end_time': this.__utils.index.formatDate(new Date(buttonsDate[1]), 'YYYY-MM-DD 23:59:59')
        } : this.filter.dateQrl
        const filter = {
            ...this.filter,
            dateQrl,
            query_date_type: buttonsDate
                ? (this.buttons.list.length > 1 ? ['DAY', 'WEEK', 'MONTH'][this.buttons.active] : 'MONTH')
                : queryDateTypeHandle([
                    dateQrl ? dateQrl.query_start_time : '',
                    dateQrl ? dateQrl.query_end_time : ''
                ]),
            period: this.buttons.list.length > 1 ? ['LAST30DAY', 'LAST12WEEK', 'LAST12MONTH'][this.buttons.active] : 'LAST12MONTH'
        }
        let res: {
            data: TrendRes
        } = {
            data: {
                allCount: 0,
                depCount: 0,
                groupCount: 0,
                result: {}
            }
        }
        if (this.alertId === 1) {
            res = await this.__apis.home.newCustomerTrend(filter)
        } else if (this.alertId === 2) {
            res = await this.__apis.home.followerCustomerTrend(filter)
        } else if (this.alertId === 3) {
            res = await this.__apis.home.followerCountTrend(filter)
        } else if (this.alertId === 4) {
            res = await this.__apis.home.daikanCountTrend(filter)
        } else if (this.alertId === 5) {
            res = await this.__apis.home.cjCountTrend(filter)
        } else if (this.alertId === 6) {
            res = await this.__apis.home.cjHouseCountTrend(filter)
        } else if (this.alertId === 7) {
            res = await this.__apis.home.cjHouseMoneyAmountTrend(filter)
        } else if (this.alertId === 8) {
            res = await this.__apis.home.fanyonAmountTrend(filter)
        } else if (this.alertId === 9) {
            res = await this.__apis.home.kaipiaoAmountTrend(filter)
        } else if (this.alertId === 10) {
            res = await this.__apis.home.dzyjAmountTrend(filter)
        } else if (this.alertId === 11) {
            res = await this.__apis.home.earnedProfitTrend(filter)
        } else if (this.alertId === 12) {
            res = await this.__apis.home.tuifangAmoutTrend(filter)
        } else if (this.alertId === 13) {
            res = await this.__apis.home.customerRateTrend(filter)
        }

        let { data: { result } } = res

        const map: {
            [key:string]: string
        } = {
            'allList': '全国平均线',
            'depList': '所属部门平均线',
            'groupList': '所属战队平均线'
        }

        if (filter.user_id) {
            map['userList'] = '分析师'
        }
        const chartData = Object.keys(result).map(a => {
            return {
                name: map[a as 'allList'],
                data: result[a].map(v => {
                    return (Number(v.row) / (v.userCount || 1)).toFixed(2)
                }),
                date: result[a].map(a => a.column)
            }
        })

        this.chartData = chartData
    }

    chartRender () {
        const chart = echarts.init(this.$refs.chart as HTMLDivElement)
        const data = this.chartData.filter(a => this.check.value.includes(a.name))
        setLineChart(chart, data, false, data.map(a => {
            return {
                type: 'line',
                symbol: 'emptyCircle',
                lineStyle: {
                    type: a.name === '分析师' ? 'solid' : 'dotted'
                },
                name: a.name,
                data: a.data
            }
        }))
    }

    initButtons () {
        const date = this.__utils.dayjs()
        const end = date.endOf('d').valueOf()

        this.buttons.list = [
            {
                label: '近30天',
                value: [date.subtract(30, 'd').startOf('d').valueOf(), end]
            },
            {
                label: '近12周',
                value: [date.subtract(84, 'd').startOf('d').valueOf(), end]
            },
            {
                label: '近12月',
                value: [date.subtract(365, 'd').startOf('d').valueOf(), end]
            }
        ]

        // 大于之后的筛选只有一个
        if (this.alertId > 5) {
            this.buttons.list = [this.buttons.list[this.buttons.list.length - 1]]
        }
    }

    buttonsClick (index: number) {
        this.buttons.active = this.buttons.active === index ? -1 : index
        this.chartLoad()
    }

    checkValueChange () {
        this.chartRender()
    }
}
