import { Component, Vue } from 'vue-property-decorator'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import FilterComponent from '@/components/filters/filter/index.vue'
import InputSelectComponent from '@/components/filters/inputSelect/index.vue'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'

@Component({
    components: {
        InputDateRangeComponent,
        FilterComponent,
        InputSelectComponent,
        FilterBusInputSelectComponent,
        FilterBusSelectComponent
    }
})
export default class IndexPage extends Vue {
    table: any = {
        data: [],
        loading: false,
        start_time: '',
        end_time: '',
        customer_name: '',
        user_id: 0,
        house_name: ''
    }
    pagination = {
        total: 20,
        pagesize: 20,
        current: 1
    }

    created () {
        this.getData()
    }

    async getData () {
        try {
            if (this.table.loading) return
            this.table.loading = true
            this.table.data = []

            const { data } = await this.__apis.financial.getSaleList({
                type: 'saleMoneyChangeList',
                page: this.pagination.current,
                pagesize: this.pagination.pagesize,
                time_start: this.table.start_time,
                time_end: this.table.end_time,
                customer_name: this.table.customer_name,
                user_id: this.table.user_id,
                house_name: this.table.house_name
            })

            this.table.loading = false

            this.pagination.total = +data.total
            data.list.forEach((a: any) => {
                a.change_time = a.Atime.split(' ')[0]
                a.sale_time = a.SaleTime.split(' ')[0]

                this.table.data.push(a)
            })
        } catch (e) {
            this.table.loading = false
            throw e
        }
    }

    // 当前分页改变时候触发
    currentChange (page: number) {
        if (this.table.loading) return

        this.pagination.current = page
        this.getData()
    }

    // 筛选发送变化
    filterChange (data: any) {
        this.table.start_time = ''
        this.table.end_time = ''
        this.table.user_id = ''
        this.table.house_name = ''
        this.table.customer_name = ''

        for (let k in data) {
            if (k === 'sale_time') {
                this.table.start_time = data[k][0]
                this.table.end_time = data[k][1]
            } else {
                this.table[k] = data[k]
            }
        }

        this.pagination.current = 1
        this.getData()
    }

    toEdit (id: number, bool: boolean) {
        let obj = {
            path: '/financial/success_detail/success/' + id
        }
        if (bool) {
            let { href } = this.$router.resolve(obj)
            window.open(href, '_blank')
        } else {
            this.$router.push(obj)
        }
    }
}
