import { Component, Prop, Vue } from 'vue-property-decorator'
import { searchHouseRes } from '@/api/utils'

export interface EditData {
    id?: number
    start: string
    end: string
    money: string
    house: searchHouseRes[]
    remark: string
    remainder: string
}

@Component
export default class EditComponent extends Vue {
    @Prop()
    show !: boolean

    @Prop({
        type: Object,
        default () {
            return {
                start: '',
                end: '',
                money: '',
                house: [],
                remark: '',
                remainder: ''
            }
        }
    })
    item !: EditData

    houses: searchHouseRes[] = []

    get getTitle () {
        return this.form.id === undefined ? '添加预付款' : '修改预付款'
    }

    get btnSaveDisabled () {
        return !(this.form.start && this.form.end && this.form.money && this.getHouseList() && this.form.remark)
    }

    form: EditData = {} as EditData

    created () {
        this.form = this.__utils.index.copyObj(this.item)

        this.houses = this.form.house
    }

    getHouseList () {
        return this.form.house.length ? JSON.stringify(this.form.house) : ''
    }

    async save () {
        const date = this.__utils.index.formatTimeStartTimeEnd([this.form.start, this.form.end])

        await this.__apis.channelManage.saveDeposit({
            begin_time: date[0],
            end_time: date[1],
            deposit_money: +this.form.money,
            remain_money: +this.form.remainder,
            remark: this.form.remark,
            house_list: this.getHouseList(),
            partner_id: +this.$route.params.id,
            id: this.form.id
        })

        this.$message.success('保存成功')
        this.$emit('save')
        this.close()
    }

    close () {
        this.$emit('update:show', false)
    }

    async searchHouse (text: string) {
        const { data } = await this.__apis.utils.searchHouse({
            term: text
        })

        this.houses = data
    }
}
