import { Component, Vue } from 'vue-property-decorator'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'

@Component({
    components: {
        InputSelectSearch
    }
})
export default class IndexPage extends Vue {
    id: number = undefined as any // 注意 这里要保证id一定有

    exceptDays = [
        {
            label: '半个月'
        }, {
            label: '一个月'
        }, {
            label: '两个月'
        }, {
            label: '三个月'
        }, {
            label: '四个月'
        }, {
            label: '五个月'
        }, {
            label: '六个月'
        }, {
            label: '六个月以上'
        }
    ]

    channelBind: {
        exceptDay: string
        houseName: string
        houseId: number
        channelId: number
        friends: {
            id: number
        }[]
    } = {
        exceptDay: undefined as any,
        houseName: undefined as any,
        channelId: undefined as any,
        houseId: undefined as any,
        friends: [{
            id: undefined as any
        }]
    }

    created () {
        this.id = +this.$route.query.id

        if (this.id) {
            this.getData()
            this.getHistory()
        }
    }

    history: {
        content: string
        date: string
        time: string
    }[] = []

    async getHistory () {
        const { data } = await this.__apis.channelManage.channelChangeRecordList(this.id)

        this.history = data.sort((a, b) => b.mtime - a.mtime).map(a => {
            const date = this.__utils.index.formatDate(new Date(a.mtime), 'YYYY-MM-DD HH:mm:ss').split(' ')
            return {
                content: a.note,
                date: date[0],
                time: date[1]
            }
        })
    }

    async getData () {
        const { data } = await this.__apis.channelManage.houseBindDetail(this.id)
        this.channelBind.channelId = data.channel_id
        this.channelBind.friends = data.duijie_user.map(a => {
            return {
                id: a.id
            }
        })
        this.channelBind.exceptDay = data.return_time
        this.channelBind.houseName = data.house_name
        this.channelBind.houseId = data.house_id
    }

    async save () {
        if (this.__utils.index.limitRepeatClick()) return

        try {
            await this.__apis.channelManage.houseBindSave({
                id: this.id,
                house_id: this.channelBind.houseId,
                house_name: this.channelBind.houseName,
                return_time: this.channelBind.exceptDay,
                channel_id: this.channelBind.channelId,
                channel_name: this.__utils.index.getLabelByValue(this.fullName, this.channelBind.channelId),
                duijie_user: this.channelBind.friends.filter(a => a.id).map(a => a.id)
            })

            this.$message.success('保存成功')

            // 跳转列表页
            await this.__utils.index.sleep(1000)
            this.$router.replace('/channelManage/house-manage')
        } catch (e) {
            this.$message.error(`保存失败, ${e.message}`)
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    get
    cmtBtnSaveEnabled () {
        return this.channelBind.houseName && this.channelBind.houseId && this.channelBind.exceptDay && this.channelBind.friends.length
    }

    get
    getChannelPeople () {
        const item = this.fullName.filter(a => a.value === this.channelBind.channelId)

        return item.length ? item[0].duijie_user : []
    }

    get
    fullName () {
        return this.__store.state.success_detail.all_channel.map(a => {
            return {
                label: a.full_name,
                value: a.id,
                duijie_user: a.duijie_user
            }
        })
    }

    addFriend () {
        const item = this.getChannelPeople[0]

        this.channelBind.friends.push({
            id: item ? item.id : undefined as any
        })
    }

    selectFriend () {
        const item = this.getChannelPeople[0]
        if (!item) return

        this.channelBind.friends.forEach(a => {
            a.id = item.id
        })
    }

    // 获取对接人联系方式
    getPeopleTelByIdAndPeople (id: number) {
        const friend = this.fullName.filter(a => a.value === this.channelBind.channelId)[0]
        if (!friend) return ''

        const people = friend.duijie_user.filter(a => a.id === id)[0]
        if (!people) return ''

        return people.tel
    }

    // 删除渠道对接人
    deletePeople (index: number) {
        this.channelBind.friends.splice(index, 1)
    }

    selectHouse (data: {label: string, id: number}) {
        this.channelBind.houseId = data.id
    }
}
