import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'

interface TableItem {
    id: number
    department: string,
    group: string,
    fxs: string,
    removeMoney: string,
    month: string,
    remark: string
    operateUser: string
    operateTime: string
}

@Component({
    components: {}
})
export default class ChongZhiCardDetailPage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 50
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }

    async getTableData () {
        const { data } = await this.__apis.wallet.financeOutRecordQuery({
            employee_id: +this.$route.params.id,
            page: this.tableData.page,
            pagesize: this.tableData.pageSize
        })
        this.tableData.total = data.total
        this.tableData.data = data.rows.map(a => {
            return {
                id: 0,
                department: this.__utils.index.getDepInfoById(a.dep_id).v,
                group: this.__utils.index.getGroupInfoById(a.group_id).name,
                fxs: a.employee_name,
                removeMoney: this.__utils.index.formatMoney(a.money),
                month: a.month ? this.__utils.dayjs(a.month).format('YYYY-MM') : '',
                remark: a.remark,
                operateUser: a.action_user_name,
                operateTime: this.__utils.dayjs(a.ctime).format('YYYY-MM-DD HH:mm:ss')
            }
        })
    }

    created () {
        this.getTableData()
    }
}
