import { Component, Vue } from 'vue-property-decorator'
import VueCropper from '@/components/vue-cropper/index.vue'
import SalaryComponent from '@/views/usercenter/salary/index.vue'
import { apiConfig } from '@/api/config'
import { storageName } from '@/localStorage'
import { UserCommitTypes } from '@/store/user'
import SuccessRecordComponent from '@/views/usercenter/successRecord/index.vue'

@Component({
    components: {
        VueCropper,
        SalaryComponent,
        SuccessRecordComponent
    }
})
export default class UserCenterIndexPage extends Vue {
    // 编辑弹窗
    userInfoDialog = false
    formLabelWidth = '46px'
    userInfoForm: any = {
        name: '',
        alias: '',
        work: '',
        dep: '',
        desc: '',
        imgs: []
    }
    // 图片上传
    dialogImageUrl = apiConfig.apiUrl
    fileList: any[] = []
    // 用户，部门，岗位 信息
    userInfoObj: any = {}
    userDepObj = {}
    userJobObj = {}
    cropper_show = false
    activeName = 'salary'
    faTieObj = {}
    collectObj = {}
    tzObj: any = {}
    hasReadMsg = {}
    total = 0
    page = 1
    pageSize = 20
    cropperRes = {}
    myIpInfo:any = {}

    get
    avatorSrc () {
        if (this.userInfoObj.basic_info.Avatar === 'http://lxfm-s.malmam.com/uploads/image/140928/2748175_5_03f58d295d_o.png') {
            return 'https://ssl.malmam.com/xiangju-static/dfs/18/59dfb662c08c75/default.png'
        } else {
            return this.userInfoObj.basic_info.Avatar
        }
    }
    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }
    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    async mounted () {
        await Promise.all([this.getDepData(), this.getJobData()])

        this.getData()
        this.getMyIpRegister()
        this.getTzData()
    }

    // 注册IP电话
    async getMyIpRegister () {
        const res:any = await this.__apis.user.getMyIpRegister()
        if (res.errcode === 0) {
            this.getMyIpInfo()
        }
    }

    // 获取IP电话信息
    async getMyIpInfo () {
        const data = await this.__apis.user.getMyIpInfo()
        this.myIpInfo = data.user
    }

    // 获取个人信息
    async getData () {
        const res = await this.__apis.user.userCenterHome()

        let datas = res.data
        this.userInfoObj = datas
        this.userInfoObj.show = true

        let userInfo = this.__utils.index.copyObj(this.userInfo)
        userInfo.avatar = datas.basic_info.Avatar
        this.__store.commit(UserCommitTypes.setUserInfo, userInfo)
    }

    async getDepData () {
        const res = await this.__apis.utils.searchDep()

        this.userDepObj = res.data
    }

    async getJobData () {
        const res = await this.__apis.business.getCompanyJobList()

        this.userJobObj = res.data
    }

    // 显示修改弹窗
    showUserInfoDialog () {
        let userInfo = this.userInfoObj.basic_info
        this.userInfoForm = {
            name: userInfo.UserName,
            alias: userInfo.NickName,
            work: userInfo.JobId === 0 ? '' : String(userInfo.JobId),
            desc: userInfo.Intro,
            imgs: userInfo.Avatar ? userInfo.Avatar.split(',') : []
        }
        this.userInfoDialog = true
    }

    // 保存修改
    async saveUserInfoDialog () {
        let userInfoForm = this.userInfoForm
        let data = {
            nick_name: userInfoForm.alias,
            job_id: userInfoForm.work,
            dep_id: userInfoForm.dep,
            intro: userInfoForm.desc || '',
            filter_set: true
        }
        await this.__apis.user.saveUserInfo(data)

        this.$notify({
            title: '成功',
            message: '保存成功',
            type: 'success'
        })
        this.userInfoDialog = false
        this.resetFormDialog()
        this.getData()
    }

    // 重置弹窗form
    resetFormDialog () {
        const form = this.$refs['userInfoForm'] as any
        form.resetFields()
    }

    handlePictureSuccess (response: any, file: any) {
        this.fileList = [file]
        this.userInfoForm.imgs = [file.response.data.url]
    }

    handlePictureError (err: any) {
        console.log(err)
        this.$notify({
            title: '错误',
            message: '上传失败',
            type: 'error'
        })
    }

    handleRemove () {
        this.fileList = []
        this.userInfoForm.imgs = []
    }

    cropperDialog () {
        this.cropper_show = true
    }

    cropperDialogHide () {
        this.cropper_show = false
    }

    async cropperOK (res: any) {
        this.cropperRes = res
        let data = {
            action_name: 'saveUserInfo',
            avatar: res.url || ''
        }

        await this.__apis.user.saveUserInfo(data)

        this.$notify({
            title: '成功',
            message: '保存成功',
            type: 'success'
        })
        this.getData()
    }

    // 底部tab
    tabClick (tab: any) {
        this.page = 1
        switch (tab.name) {
        case 'first':
            this.getTzData()
            break
        case 'second':
            this.getUserFaTieData()
            break
        case 'third':
            this.getCollectData()
            break
        default:
            break
        }
    }

    // tab 通知数据
    async getTzData () {
        const res = await this.__apis.user.getAdminNoticeList()

        let datas = res.data
        let obj: any = {}
        datas.list && datas.list.map((v: any) => {
            v.BizData = v.BizData.indexOf('{') > -1 ? JSON.parse(v.BizData) : v.BizData
            v.CtimeRes = v.Ctime
            v.CtimeRes = v.CtimeRes && v.CtimeRes.split(' ')[0]
            if (!obj[v.CtimeRes]) {
                obj[v.CtimeRes] = []
            }
            obj[v.CtimeRes].push(v)
        })
        this.tzObj = datas
        this.tzObj.resObj = obj
        // this.checkHasNoReadMsg()
    }

    // tab 我的发帖数据
    async getUserFaTieData () {
        let data = {
            type: 3,
            user_id: this.userInfo.user_id,
            page: this.page,
            pagesize: this.pageSize
        }
        const res = await this.__apis.community.getForumList(data)

        this.faTieObj = res.data
    }

    // 我的收藏数据
    async getCollectData () {
        const res = await this.__apis.user.getCollectForumList({
            user_id: this.userInfo.user_id,
            page: this.page,
            pagesize: this.pageSize
        })

        this.collectObj = res.data
    }

    // 已读未读
    async checkHasNoReadMsg () {
        const res = await this.__apis.home.checkHasNoReadMsg()

        let datas = res.data
        this.hasReadMsg = datas
        this.__localStorage.setLocalStorage(storageName.NEWTZ, datas.has_no_read)
        this.__store.commit(UserCommitTypes.insertInfo, { key: 'tz', value: datas.has_no_read })
    }

    // 分页改变
    pageChange (page: number) {
        this.page = page
        let name = this.activeName
        switch (name) {
        case 'first':
            break
        case 'second':
            this.getUserFaTieData()
            break
        case 'third':
            this.getCollectData()
            break
        default:
            break
        }
    }
}
