export class RespError extends Error {
    public code: number

    constructor (code = 0, message: string) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(message)

        this.message = message
        this.name = 'RespError'
        // Custom debugging information
        this.code = code
        // this.message = params.length > 0 ? params[0] : "";

        // Set the prototype explictilly
        if ((Object as any).setPrototypeOf) {
            (Object as any).setPrototypeOf(this, RespError.prototype)
        } else {
            // eslint-disable-next-line no-proto
            (this as any).__proto__ = RespError.prototype
        }

        // Object.defineProperty(this, "name", {
        //  get() { return this.constructor.name }
        // });

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if ((Error as any).captureStackTrace) {
            (Error as any).captureStackTrace(this, this.constructor)
        }
        // console.log(this.stack)
    }

    // inspect  () {
    //  return this.stack
    // }

    public toString () {
        let name = this.name
        name = (name === undefined) ? 'RespError' : String(name)

        let msg = this.message
        msg = (msg === undefined) ? '' : String(msg)

        const code = (this.code === undefined) ? '' : '[' + String(this.code) + ']'
        const outs = [] as string[]
        if (name !== '') {
            outs.push(name + ':')
        }
        if (code !== '') {
            outs.push(code)
        }
        if (msg !== '') {
            outs.push(msg)
        }

        return outs.join(' ')
    }
}

// @ts-ignore
export const FormatError = function (res, errmap = {}, prefix = '发生错误: ') {
    if (res instanceof Error) {
        const e = res
        let errmsg = e.message
        errmap = typeof (errmap) === 'object' ? errmap : {}
        if (e instanceof RespError) {
            const errcode = e.code
            // @ts-ignore
            errmsg = (errcode in errmap) ? errmap[errcode] : (prefix + '[' + errcode + '] ' + e.message)
        }
        return errmsg
    }
    if (typeof res.errMsg === 'string') {
        return res.errMsg
    }
    return String(res)
}
