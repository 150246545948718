import { Component, Mixins } from 'vue-property-decorator'
import InputHouseSearch from '@/components/InputHouseSearch/index.vue'
import CustomerMixin from '../mixin'

@Component({
    components: {
        InputHouseSearch
    },
    beforeRouteUpdate (to) {
        // fix 页面跳转同组件情况下 无法跳转的问题
        open(`${location.pathname}?type=${to.query.type}`, '_self')
    }
})
export default class CustomerAllPage extends Mixins(CustomerMixin) {
    searchVisible = true
    form: any = {
        ctime: '',
        last_follow_time: '',
        name: '',
        mobile: '',
        house: '',
        tag: '',
        impt_group: '',
        dep: '',
        group: '',
        user_id: '',
        keywords: '',
        intention_area: '',
        source_type: '',
        mark: '',
        renling_type: '',
        dai_kan_count: '',
        es_comparison_type: '',
        utms_channel: ''
    }
    dataObj = {}
    page = 1
    pageSize = 50
    total = 0
    sysTags2: {
        [index: string]: any
    } = {}
    sysTags3: {
        [index: string]: any
    } = {}
    dialogEdit = false
    editForm = {
        tag: '',
        impt_group: '',
        log: ''
    }
    formLabelWidth = '120px'
    sortType = ''
    sortMode = ''
    loading = false

    exportParams: any = {}

    utms_channel_arr= [
        // { value: '', text: '全部' },
        { value: '百度', text: '百度' },
        { value: '高德', text: '高德' }
    ]

    get
    infoPos () {
        let infoPos = this.__store.state.user.infoPos
        this.form.dep = infoPos.dep
        this.form.group = infoPos.group
        this.form.user_id = infoPos.fxs
        return infoPos
    }

    mounted () {
        this.sysTags2 = this.sysTagsIn()
        this.sysTags3 = this.sysTagsIn()
        this.getData()
    }

    // 客户来源筛选
    changeFromType (value: string) {
        if (this.form.renling_type === value) {
            this.form.renling_type = ''
        } else {
            this.form.renling_type = value
        }
    }

    // 客户级别筛选
    changeLevel (value: string) {
        if (this.form.tag === value) {
            this.form.tag = ''
        } else {
            this.form.tag = value
        }
    }

    // 拷贝系统标签
    sysTagsIn () {
        return this.__store.state.user.sysTags && this.__utils.index.copyObj(this.__store.state.user.sysTags)
    }

    // 请求函数
    async getData () {
        this.loading = true
        let sys = []
        if (this.sysTags2) {
            for (let key in this.sysTags2) {
                if (this.sysTags2[key].active) {
                    sys.push(this.sysTags2[key].value)
                }
            }
        }
        let data = {
            type: this.$route.query.type + '',
            sys_tag: sys.join(','),

            ctime_begin: this.form.ctime ? this.form.ctime[0] : '',
            ctime_stop: this.form.ctime ? this.form.ctime[1] : '',

            last_follow_time_begin: this.form.last_follow_time ? this.form.last_follow_time[0] : '',
            last_follow_time_stop: this.form.last_follow_time ? this.form.last_follow_time[1] : '',

            sort_field: this.sortType,
            sort_mode: this.sortMode,

            dai_kan_count: +this.form.dai_kan_count || undefined,
            es_comparison_type: this.form.es_comparison_type || undefined,

            page: this.page,
            pagesize: this.pageSize
        }

        let form = this.__utils.index.copyObj(this.form)
        form.ctime = ''
        form.last_follow_time = ''

        data = Object.assign(form, data)

        const res = await this.__apis.customer.getCustomerList(data)

        this.exportParams = data
        this.dataObj = res.data
        this.total = res.data.res.total
        this.loading = false
    }

    // 搜索
    clickSearch () {
        this.page = 1
        this.getData()
    }

    // 清空筛选
    resetFrom (formName: string) {
        const form = this.$refs[formName] as any
        form.resetFields()
        for (let key in this.sysTags2) {
            this.$set(this.sysTags2[key], 'active', false)
        }
        this.form.renling_type = ''
        this.form.es_comparison_type = ''
        this.page = 1
        this.getData()
    }

    // 筛选展开收起
    clickSearchVisible () {
        this.searchVisible = !this.searchVisible
    }

    // 部门改变
    departSelect () {
        this.form.group = 0
    }

    // 组改变
    groupSelect () {
        this.form.user_id = 0
    }

    btnLoading = false
    async exportTable () {
        if (this.btnLoading) return
        try {
            this.btnLoading = true
            const res = await this.__apis.customer.customerExport({ ...this.exportParams, page: 1, pagesize: 5000 })
            // console.log(res)
            this.__utils.index.fileDownload(res)
        } finally {
            this.btnLoading = false
        }
    }
}
