import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import FilterComponent from '@/components/filters/filter/index.vue'
import ListFilterComponent from '@/components/filters/list/index.vue'
import EditComponent from './edit/index.vue'
import { getKeyByValue, RoleManage, roleType, tree_refresh } from '../utils'
import { editItem } from './edit'
import { permissionsStatusReq } from '@/api/system'

interface Filter {
    client: number
    server: number
    module: number
}

export interface TableItem {
    id: number
    client: number
    server: number
    module: number
    path: string
    name: string
    mtime: string
    status: number
}

@Component({
    components: {
        FilterComponent,
        ListFilterComponent,
        EditComponent
    }
})
export default class IndexPage extends Vue {
    editComponent: {
        show: boolean
        item: editItem
    } = {
        show: false,
        item: {} as any
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0
    }

    filter: Filter = {
        client: null,
        server: null,
        module: null
    } as any

    TYPES: {
        service: roleType
        client: roleType
        modules: roleType
    } = {
        service: {},
        client: {},
        modules: {}
    }

    async created () {
        this.getData()

        const { SERVICE, CLIENT, MODULES } = await RoleManage()
        this.TYPES = {
            service: SERVICE,
            client: CLIENT,
            modules: MODULES
        }
    }

    async getData () {
        let { data } = await this.__apis.system.permissionsManageList({
            pid: this.filter.module,
            client_id: this.filter.client,
            service: this.filter.server,
            page: this.tableData.page,
            pagesize: this.tableData.pageSize
        })
        this.tableData.total = data.total
        this.tableData.data = data.list.map(v => {
            return {
                name: v.name,
                path: v.path,
                server: v.service,
                id: v.id,
                module: v.pid,
                mtime: this.__utils.index.formatDate(new Date(v.mtime), 'YYYY.M.D'),
                client: v.client_id,
                status: v.status
            }
        })
    }

    // 筛选确定
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        // 筛选的返回值是value 需要获取这些value 对应的key
        this.filter.client = Number(getKeyByValue(this.TYPES.client, this.filter.client))
        this.filter.server = Number(getKeyByValue(this.TYPES.service, this.filter.server))
        this.filter.module = Number(getKeyByValue(this.TYPES.modules, this.filter.module))

        this.getData()
    }

    getValues <T extends Object> (obj: T) {
        return Object.values(obj)
    }

    // 新增
    add () {
        this.editComponent = {
            show: true,
            item: {} as any
        }
    }

    // 编辑
    edit (item: TableItem) {
        if (item.status !== 1) return

        this.editComponent = {
            show: true,
            item: {
                client: item.client,
                id: item.id,
                server: item.server,
                path: item.path,
                des: item.name,
                module: item.module
            }
        }
    }

    // 禁用 | 启用
    async setStatus (id: permissionsStatusReq['id'], status: permissionsStatusReq['status']) {
        await this.__apis.system.permissionsStatus({
            id,
            status
        })

        tree_refresh.value = true
        this.$message.success('操作成功')
        this.getData()
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getData()
    }

    tableRowClassFun ({ row: item }: {row: TableItem}) {
        if (item.status === 2) {
            return 'disabled'
        }
    }
}
