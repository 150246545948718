import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import { SubjectType } from '@/util/const'
import addQuestionComponent from '@/components/questionBank/addQuestion/index.vue'
import seeQuestionComponent from '@/components/questionBank/seeQuestion/index.vue'

export interface TableItem {
    id: number,
    depId: number
    depName: string
    subjectType: number
    subjectName: string
    updateTime: string
    questionStem: string
    questionType: number
    applicationScope: string
    answerCount: number
    rightRate: string
    status: number
    statusText: string
}

const subjectType = [
    {
        label: '全部',
        value: 0
    },
    ...SubjectType
]

const QuestionUsedList: {
    [key: string]: string
} = {
    0: '禁用中',
    1: '启用中'
}

@Component({
    components: {
        addQuestionComponent,
        seeQuestionComponent
    }
})
export default class QuestionBankManageClassificationPage extends Vue {
    form = {
        dep: null,
        subjectType: null,
        stem: ''
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0
    }

    subjectType = subjectType

    addQuestionComponent = {
        visible: false,
        item: {}
    }

    seeQuestionComponent = {
        visible: false,
        item: {}
    }

    get
    subjectName () {
        const value = Number(this.$route.params.type) || 1
        return (SubjectType.find(a => a.value === value) || {
            label: '',
            value: 0
        }).label
    }

    get
    depArr () {
        return this.__utils.index.getXsDep()
    }

    created () {
        this.getData()
    }

    async getData () {
        const filter = this.form
        const { data } = await this.__apis.activity.questionsSubject({
            dep_id: +this.$route.params.dep,
            subject_id: +this.$route.params.type,
            title: filter.stem || undefined,
            page: this.tableData.page,
            pagesize: this.tableData.pageSize
        })
        this.tableData.data = data.list.map(a => {
            return {
                id: a.question_id,
                depId: a.dep_id,
                depName: a.dep_name,
                subjectType: 0,
                subjectName: a.subject_name,
                updateTime: this.__utils.index.formatDate(new Date(a.last_update_time), 'YYYY-MM-DD HH:mm:ss'),
                questionStem: a.title,
                questionType: a.question_type,
                applicationScope: a.areas.length ? a.areas.map(a => a.areaName).join(',') : a.house_name,
                answerCount: a.answer_count,
                rightRate: a.right_rate + '%',
                status: Number(a.used),
                statusText: QuestionUsedList[Number(a.used)]
            }
        })
        this.tableData.total = data.total
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getData()
    }

    resetFilter () {
        const form = this.$refs['form'] as any
        form.resetFields()
        this.tableCurrentChange(1)
    }

    async seeDetail (item: TableItem) {
        this.seeQuestionComponent = {
            visible: true,
            item: {
                id: item.id,
                ids: this.tableData.data.map(a => a.id)
            }
        }
    }

    async close (item: TableItem) {
        if (this.__utils.index.limitRepeatClick()) return
        try {
            await this.__apis.activity.usedQuestion({
                id: item.id,
                used: !item.status
            })
            this.$message({
                message: item.status ? '已停用题目，次日生效' : '已启用题目，次日生效',
                type: 'success'
            })
            await this.getData()
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    async del (item: TableItem) {
        if (this.__utils.index.limitRepeatClick()) return
        try {
            await this.__apis.activity.deleteQuestion({
                id: item.id,
                delete: true
            })
            this.$message({
                message: '删除成功',
                type: 'success'
            })
            await this.getData()
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    showAddQuestionDialog (item: TableItem) {
        this.addQuestionComponent = {
            visible: true,
            item
        }
    }
}
