import { Component, Prop, Vue } from 'vue-property-decorator'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'
import { searchHouseRes } from '@/api/utils'

export interface EditData {
    name: string
    mobile: string
    house_id: string
    house_name: string
    remark: string
    link_id: string
}

@Component({
    components: {
        inputSelectSearch
    }
})
export default class CustomerPhoneEditComponent extends Vue {
    @Prop({
        default: false,
        type: Boolean
    })
    show !: boolean

    get
    cmtBtnEnterDisabled () {
        const res = this.form.name.trim() && this.form.mobile.trim() && this.form.house_id.trim() && this.form.house_name.trim()
        return !res
    }

    form: EditData = {
        mobile: '',
        name: '',
        house_name: '',
        house_id: '',
        remark: '',
        link_id: ''
    }

    channelMap = [
        {
            label: '未知渠道',
            value: '20977021',
            type: 6
        },
        {
            label: '线下推广',
            value: '20977020',
            type: 6
        },
        {
            label: '高德来电',
            value: '20977019',
            type: 6
        },
        {
            label: '百度',
            value: '20977018',
            type: 6
        },
        {
            label: '头条',
            value: '22305975',
            type: 49
        },
        {
            label: '小红书',
            value: '29989234',
            type: 5 // OTHER
        }
    ]

    selectHouse (a: searchHouseRes) {
        this.form.house_id = a.id.toString()
        this.form.house_name = a.value
    }

    async enter () {
        try {
            const linkItem = this.channelMap.find(a => a.value === this.form.link_id)
            const customerType = linkItem ? linkItem.type : 6
            await this.__apis.customer.createMarketingCustomer({
                customer_type: customerType,
                link_id: this.form.link_id,
                mobile: this.form.mobile,
                name: this.form.name,
                customer_house: +this.form.house_id,
                log: this.form.remark
            })

            this.$message.success('提交成功')
            this.$emit('success')
            this.close()
        } catch (e) {
            this.$message.error('分配失败')
            throw e
        }
    }

    close () {
        this.$emit('update:show', false)
    }
}
