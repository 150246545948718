import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class StarBoxComponent extends Vue {
    @Prop({
        type: Object,
        default () {
            return {}
        }
    })
    config !: {
        value: number,
        check: boolean
    }

    rateChange (value: number) {
        this.config.value = value

        if (value > 0) {
            this.config.check = false
        }

        this.$emit('change', '未接通/空号/无效通话，不打分')
    }
}
