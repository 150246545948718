import { Component, Vue } from 'vue-property-decorator'
import HomeDateComponent from '@/views/home/components/date/index.vue'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'
import echarts, { ECharts } from 'echarts'

interface FollowChartData {
    depProportionOfVisitorsChart: any[],
    depNewCustomerFollowUpChart: any[],
    groupFollowUpCycleChart: any[],
    userFollowUpCycleChart: any[],
    groupLowStarCustomerChart: any[]
}
enum AggreColumn {
    '部门' = 'department',
    '战队' = 'group',
    '分析师' = 'first_follow_user'
}
@Component({
    components: {
        HomeDateComponent,
        InputSelectSearch
    }
})
export default class followUpAnalysis extends Vue {
    groupFilter: null | number = null
    depFilter: null | number = null
    date: string[] = []
    loading: boolean = false
    get
    groupZD () {
        return this.depFilter ? this.__utils.index.getGroupsByDepId(+this.depFilter).map(a => {
            return {
                value: a.value,
                label: a.name
            }
        }) : []
    }
    get
    groupFXS () {
        return this.groupFilter && this.depFilter ? this.__store.state.user.infoPos.fxs_arr[this.depFilter][this.groupFilter].map(a => {
            return {
                value: a.k,
                label: a.v
            }
        }) : []
    }
    followChartData: FollowChartData = {
        depProportionOfVisitorsChart: [],
        depNewCustomerFollowUpChart: [],
        groupFollowUpCycleChart: [],
        userFollowUpCycleChart: [],
        groupLowStarCustomerChart: []
    }
    dateChange () {
        this.$nextTick(() => {
            this.initCharts()
        })
    }

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    get
    depData () {
        return this.__utils.index.getXsDep()
    }

    created () {
        if (this.infoPos.dep) {
            this.depFilter = this.infoPos.dep
        }
    }

    private depNewCustomerFollowUpOption:boolean = false
    private groupFollowUpCycleOption:boolean = false
    private userFollowUpCycleOption:boolean = false
    private groupLowStarCustomerOption:boolean = false
    checkOption (type: string) {
        switch (type) {
        case 'depNewCustomerFollowUp':
            this.depNewCustomerFollowUpOption = !this.depNewCustomerFollowUpOption
            break
        case 'groupFollowUpCycle':
            this.groupFollowUpCycleOption = !this.groupFollowUpCycleOption
            break
        case 'userFollowUpCycle':
            this.userFollowUpCycleOption = !this.userFollowUpCycleOption
            break
        case 'groupLowStarCustomer':
            this.groupLowStarCustomerOption = !this.groupLowStarCustomerOption
            break
        default:;
        }
    }
    selectGroup (sname: string) {
        this.$nextTick(() => {
            switch (sname) {
            case 'depFilter':
                this.groupFilter = null
                break
            case 'groupFilter':
                break
            default:;
            }
            this.initCharts()
        })
    }

    initChartsVm () {
        return {
            depProportionOfVisitorsChart: echarts.init(this.$refs.depProportionOfVisitors as HTMLDivElement), // 各部门进客占比
            depNewCustomerFollowUpChart: echarts.init(this.$refs.depNewCustomerFollowUp as HTMLDivElement), // 部门新客户跟进统计
            groupFollowUpCycleChart: echarts.init(this.$refs.groupFollowUpCycle as HTMLDivElement), // 战队新客首次跟进周期（分钟）
            userFollowUpCycleChart: echarts.init(this.$refs.userFollowUpCycle as HTMLDivElement), // 分析师新客首次跟进周期（分钟）
            groupLowStarCustomerChart: echarts.init(this.$refs.groupLowStarCustomer as HTMLDivElement) // 战队新客1星/未打分跟进率
        }
    }

    initCharts () {
        this.followChartData = {
            depProportionOfVisitorsChart: [],
            depNewCustomerFollowUpChart: [],
            groupFollowUpCycleChart: [],
            userFollowUpCycleChart: [],
            groupLowStarCustomerChart: []
        }
        const {
            depProportionOfVisitorsChart,
            depNewCustomerFollowUpChart,
            groupFollowUpCycleChart,
            userFollowUpCycleChart,
            groupLowStarCustomerChart
        } = this.initChartsVm()
        this.getNewCustomerFirstFollowAggreMedian(AggreColumn['部门'], async (res: any) => {
            const { data } = await this.__apis.home.getAllCustomerFirstFollowAggreMedian({
                start_time: this.date[0] || '',
                end_time: this.date[1] || '',
                // group_id: this.groupFilter || undefined,
                dep_id: this.depFilter || undefined
            })
            res.rows.unshift({
                median_key_name: '汇总',
                median_value: data.median_total
            })
            res.rows.forEach((ele: any) => {
                ele.median_value = ele.median_value === null ? ele.median_value : ele.median_value.toFixed(1)
            })
            this.followChartData['depNewCustomerFollowUpChart'] = res.rows
            this.TiaoChart(depNewCustomerFollowUpChart, res.rows)
        }, !true)
        this.getNewCustomerFirstFollowAggreMedian(AggreColumn['战队'], (data: any) => {
            data.rows.forEach((ele: any) => {
                ele.median_value = ele.median_value === null ? ele.median_value : ele.median_value.toFixed(1)
            })
            data.rows.sort(function (x:any, y:any) {
                return x.median_value - y.median_value
            })
            this.followChartData['groupFollowUpCycleChart'] = data.rows
            this.TiaoChart(groupFollowUpCycleChart, data.rows)
        })
        this.getNewCustomerFirstFollowAggreMedian(AggreColumn['分析师'], (data: any) => {
            data.rows.forEach((ele: any) => {
                ele.median_value = ele.median_value === null ? ele.median_value : ele.median_value.toFixed(1)
            })
            data.rows.sort(function (x:any, y:any) {
                return x.median_value - y.median_value
            })
            this.followChartData['userFollowUpCycleChart'] = data.rows
            this.TiaoChart(userFollowUpCycleChart, this.getUserFollowUpCycleCurrentSize(data.rows)[0] || [])
        })
        this.getDispatchCustomerAggreData(AggreColumn['部门'], (data: any) => {
            let temRows: any[] = []
            data.rows.map((item:any) => {
                if (item.dep_id !== 5) {
                    temRows.push({
                        dep_id: item.dep_id,
                        group_id: item.group_id,
                        total: item.total,
                        dep_name: this.__utils.index.getDepInfoById(item.dep_id)['v'],
                        group_name: this.__utils.index.getGroupInfoById(item.group_id)['name']
                    })
                }
            })
            this.followChartData['depProportionOfVisitorsChart'] = temRows
            this.BingChart(depProportionOfVisitorsChart, temRows)
        }, !true)
        this.getLeaderLowMarkFollowStatistics((data: any) => {
            data.rows.forEach((ele: any) => {
                if (ele.follow_count !== 0 || ele.all_count !== 0) {
                    ele.rate = (ele.follow_count / ele.all_count * 100).toFixed(1) + '%'
                } else {
                    ele.rate = '0%'
                }
            })
            this.followChartData['groupLowStarCustomerChart'] = data.rows
            this.TwoTiaoChart(groupLowStarCustomerChart, data.rows)
        })
    }
    getUserFollowUpCycleCurrentSize (arr: any[]) {
        let index = 0
        let size = 15
        let res = []
        arr = arr.sort(function (x:any, y:any) {
            return x.median_value - y.median_value
        }).reverse()
        while (index < arr.length) {
            res.push(arr.slice(index, (index + size)).reverse())
            index += size
        }
        return res
    }
    userFollowUpCycleOptionChange (e: any) {
        console.log(e)
        this.TiaoChart(this.initChartsVm().userFollowUpCycleChart, this.getUserFollowUpCycleCurrentSize(this.followChartData['userFollowUpCycleChart'])[e - 1])
    }
    async getNewCustomerFirstFollowAggreMedian (type:AggreColumn, cb: any = false, unupdate: boolean = false) {
        const { data } = await this.__apis.home.getNewCustomerFirstFollowAggreMedian({
            start_time: this.date[0] || '',
            end_time: this.date[1] || '',
            group_id: !unupdate ? this.groupFilter : undefined,
            dep_id: !unupdate ? this.depFilter : undefined,
            aggre_column: type
        })
        cb && cb(data)
    }
    async getDispatchCustomerAggreData (type:AggreColumn, cb: any = false, unupdate: boolean = false) {
        const { data } = await this.__apis.home.getDispatchCustomerAggreData({
            start_time: this.date[0] || '',
            end_time: this.date[1] || '',
            group_id: !unupdate ? this.groupFilter : undefined,
            dep_id: !unupdate ? this.depFilter : undefined,
            aggre_column: type
        })
        cb && cb(data)
    }
    async getLeaderLowMarkFollowStatistics (cb: any = false) {
        const { data } = await this.__apis.home.getLeaderLowMarkFollowStatistics({
            start_time: this.date[0] || '',
            end_time: this.date[1] || '',
            dep_id: this.depFilter || undefined
        })
        cb && cb(data)
    }
    TiaoChart (chart: ECharts, rows: any[]) {
        const colors = ['rgba(91, 143, 249, 0.85)']
        const yAxisData = rows.map((item: any) => { return item.median_key_name })
        const seriesData = rows.map((item: any) => { return item.median_value })
        chart.setOption({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (params: any) => {
                    params = params[0]
                    let result = `<div style="background:rgba(255,255,255,.9);color:rgba(0,0,0,.8);padding:10px 16px;box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);border-radius:4px">
                                        <div>
                                            ${params.name}
                                        </div>
                                        <div style="margin-top:6px">
                                            <span style="display:inline-block;background:#5B8FF9;margin-right:4px;margin-bottom:2px;border-radius:50%;width:6px;height:6px;">
                                            </span>
                                            首次跟进周期中位数
                                            <span style='margin-left:4px;'>
                                                ${params.data}
                                            </span>
                                        </div>
                                  </div>`
                    return result
                },
                backgroundColor: '0'
            },
            grid: {
                left: '0.4%',
                top: '6%',
                right: '6%',
                bottom: '8%',
                containLabel: true
            },
            color: colors,
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01]
            },
            yAxis: {
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                type: 'category',
                data: yAxisData
            },
            series: [
                {
                    barMaxWidth: 43,
                    type: 'bar',
                    data: seriesData,
                    barGap: 0,
                    label: {
                        show: true,
                        fontSize: 12,
                        position: 'right',
                        color: '#595959',
                        textBorderColor: 'transparent',
                        textBorderWidth: 0
                    }
                }
            ] as any
        })
    }
    TwoTiaoChart (chart: ECharts, rows: any[]) {
        const colors = ['rgba(91, 143, 249, 0.85)', 'rgba(90, 216, 166, 0.85)', '#F6BD16']
        const yAxisData = rows.map((item: any) => { return item.group_name })
        const seriesData2 = rows.map((item: any) => { return item.follow_count })
        const seriesData3 = rows.map((item: any) => { return item.all_count })
        const seriesData1 = rows.map((item: any) => { return item.follow_count === 0 || item.all_count === 0 ? 0 : (item.follow_count / item.all_count * 100).toFixed(1) })
        chart.setOption({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (params: any) => {
                    let result = `<div style="background:rgba(255,255,255,.9);color:rgba(0,0,0,.8);padding:10px 16px;box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);border-radius:4px">
                                        <div>
                                            ${params[0].name}
                                        </div>
                                        <div style="margin-top:6px">
                                            <span style="display:inline-block;background:rgba(91, 143, 249, 0.85);margin-right:4px;margin-bottom:2px;border-radius:50%;width:6px;height:6px;">
                                            </span>
                                            跟进率
                                            <span style='margin-left:4px;'>
                                                ${params[0].data}%
                                            </span>
                                        </div>
                                        <div style="margin-top:6px">
                                            <span style="display:inline-block;background:rgba(90, 216, 166, 0.85);margin-right:4px;margin-bottom:2px;border-radius:50%;width:6px;height:6px;">
                                            </span>
                                            跟进客户数
                                            <span style='margin-left:4px;'>
                                                ${params[1].data}
                                            </span>
                                        </div>
                                        <div style="margin-top:6px">
                                            <span style="display:inline-block;background:#F6BD16;margin-right:4px;margin-bottom:2px;border-radius:50%;width:6px;height:6px;">
                                            </span>
                                            应跟客户数
                                            <span style='margin-left:4px;'>
                                                ${params[2].data}
                                            </span>
                                        </div>
                                  </div>`
                    return result
                },
                backgroundColor: '0'
            },
            grid: {
                left: '0.4%',
                top: '6%',
                right: '6%',
                bottom: '8%',
                containLabel: true
            },
            color: colors,
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01]
            },
            yAxis: {
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                type: 'category',
                data: yAxisData
            },
            series: [
                {
                    barMaxWidth: 13,
                    type: 'bar',
                    data: seriesData1,
                    barGap: 0,
                    label: {
                        show: true,
                        formatter: (params: any) => {
                            console.log(params)
                            if (params.value === 0) {
                                return ``
                            }
                            return `${params.data}%`
                        },
                        fontSize: 12,
                        color: '#595959',
                        position: 'right',
                        textBorderColor: 'transparent',
                        textBorderWidth: 0
                    }
                },
                {
                    barMaxWidth: 13,
                    type: 'bar',
                    data: seriesData2,
                    barGap: 0,
                    label: {
                        show: true,
                        fontSize: 12,
                        position: 'right',
                        color: '#595959',
                        textBorderColor: 'transparent',
                        textBorderWidth: 0
                    }
                },
                {
                    barMaxWidth: 13,
                    type: 'bar',
                    data: seriesData3,
                    barGap: 0,
                    label: {
                        show: true,
                        formatter: (params: any) => {
                            console.log(params)
                            if (params.value === 0) {
                                return ``
                            }
                            return `${params.data}`
                        },
                        fontSize: 12,
                        position: 'right',
                        color: '#595959',
                        textBorderColor: 'transparent',
                        textBorderWidth: 0
                    }
                }
            ] as any
        })
    }
    BingChart (chart: ECharts, rows: any[]) {
        const Color:any = {
            '杭州东部': '#F6BD16',
            '杭州西部': 'rgba(91, 143, 249, 0.85)',
            '广东销售部': 'rgba(90, 216, 166, 0.85)'
        }
        const seriesData = rows.map((item: any) => { return { name: item.dep_name, value: item.total } })
        const colors = seriesData.map((v:any) => {
            return Color[v.name]
        })
        chart.setOption({
            tooltip: {
                trigger: 'item',
                formatter: (params: any) => {
                    let result = `<div style="background:rgba(255,255,255,.9);color:rgba(0,0,0,.8);padding:10px 16px;box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);border-radius:4px">
                                        <div>
                                            ${params.name}
                                        </div>
                                        <div style="margin-top:6px">
                                            <span style="display:inline-block;background:#5B8FF9;margin-right:4px;margin-bottom:2px;border-radius:50%;width:6px;height:6px;">
                                            </span>
                                            <span style='margin-left:4px;'>
                                                进客数${params.value} / 占比 ${params.percent}%
                                            </span>
                                        </div>
                                  </div>`
                    return result
                },
                backgroundColor: '0'
            },
            color: colors,
            legend: {
                show: true,
                type: 'scroll',
                orient: 'vertical',
                right: 10,
                top: 'middle',
                icon: 'circle',
                itemWidth: 8,
                itemHeight: 8,
                textStyle: {
                    borderRadius: 4
                }
            },
            series: [
                {
                    name: '访问来源',
                    type: 'pie',
                    radius: '66%',
                    data: seriesData,
                    center: ['42%', '49%'],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        })
    }
}
