import { Component, Vue, Prop } from 'vue-property-decorator'

interface Item {
    id: number
    value: string
}

@Component
export default class TeamProfitEditComponent extends Vue {
    @Prop({
        default: false,
        type: Boolean
    })
    show !: boolean

    @Prop({
        default: () => {},
        type: Object
    })
    item !: Item

    get
    cmtBtnEnterDisabled () {
        return !this.item.value.trim()
    }

    close () {
        this.$emit('update:show', false)
    }

    enter () {
        this.$emit('enter', this.item)
        this.close()
    }
}
