import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import { searchHouseRes } from '@/api/utils'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'
import SaveOcpcComponent from './save/index.vue'

interface TableItem {
    id: number,
    ocpcId: number
    ocpcName: string
    houseId: number
    houseName: string,
    link: string,
    date: string
}

@Component({
    components: {
        InputSelectSearch,
        SaveOcpcComponent
    }
})
export default class MarketOCPCRecordPage extends Vue {
    form = {
        name: '',
        link: '',
        house_id: 0,
        house_name: '',
        date: []
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0,
        loading: false
    }

    saveOcpc: {
        visible: boolean,
        data: Omit<TableItem, 'date'>
    } = {
        visible: false,
        data: {
            id: 0,
            ocpcId: 0,
            ocpcName: '',
            houseId: 0,
            houseName: '',
            link: ''
        }
    }

    created () {
        this.getData()
    }

    async getData () {
        try {
            this.tableData.loading = true
            const filter = this.form
            const { data } = await this.__apis.market.ocpcBindHouseRecordQuery({
                start_time: filter.date[0] ? `${filter.date[0]} 00:00:00` : undefined,
                end_time: filter.date[1] ? `${filter.date[1]} 23:59:59` : undefined,
                ocpc_name: filter.name || undefined,
                house_id: filter.house_id || undefined,
                link: filter.link || undefined,
                page: this.tableData.page,
                pagesize: this.tableData.pageSize
            })
            this.tableData.data = data.rows.map(a => {
                return {
                    id: a.id,
                    ocpcId: a.ocpc_id,
                    ocpcName: a.ocpc_name,
                    houseId: a.house_id,
                    houseName: a.house_name,
                    link: a.link,
                    date: this.__utils.dayjs(a.ctime).format('YYYY-MM-DD HH:mm:ss')
                }
            })
            this.tableData.total = data.total
        } finally {
            this.tableData.loading = false
        }
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getData()
    }

    resetFilter () {
        const form = this.$refs['form'] as any
        form.resetFields()
        this.form.house_id = 0
        this.tableCurrentChange(1)
    }

    selectHouse (a: searchHouseRes) {
        this.form.house_id = a.id
        this.form.house_name = a.label
    }

    addItem () {
        this.saveOcpc = {
            visible: true,
            data: {
                id: 0,
                ocpcId: 0,
                ocpcName: '',
                houseId: 0,
                houseName: '',
                link: ''
            }
        }
    }

    editItem (item: TableItem) {
        this.saveOcpc = {
            visible: true,
            data: JSON.parse(JSON.stringify(item))
        }
    }

    async delItem (item: TableItem) {
        if (this.__utils.index.limitRepeatClick()) return
        try {
            await this.__apis.market.deleteOcpcBindHouseRecord(item.id)
            this.$message.success('删除成功！')
            this.getData()
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }
}
