import { __apis } from '@/api'
import { __store } from '@/store'
import { __utils } from '@/util'

export type Group = {
    dep: number
    name: string
    value: number
    is_pxb?: boolean
    has_close: boolean
}

type UserKey = keyof User
export enum UserCommitTypes {
    common_dep_arr = 'user/common_dep_arr',
    insertInfo = 'user/insertInfo',
    setUserInfo = 'user/setUserInfo',
    setInfoPos = 'user/setInfoPos',
    setImpt = 'user/setImpt',
    setCustomerTags = 'user/setCustomerTags',
    setcustomerTagMap = 'user/setcustomerTagMap',
    setSysTags = 'user/setSysTags',
    setMenus = 'user/setMenus',
    setGuideStatus = 'user/setGuideStatus',
    setNotice = 'user/setNotice',
    sourceType = 'user/sourceType',
    utmChannel = 'user/utmChannel',
    company = 'user/company',
    financialReviewCount = 'user/financialReviewCount',
    setRoleList = 'user/setRoleList',
    setNormalData = 'user/setNormalData',
    setProjectList = 'user/setProjectList',
    setResolveQuestion = 'user/setResolveQuestion',
    setShowQuestion = 'user/setShowQuestion',
    setSeeQuestionInfo = 'user/setSeeQuestionInfo',
    setHasQuestionTaskInfo = 'user/setHasQuestionTaskInfo'
}
export enum UserDispatchType {
    getUserInfo = 'user/getUserInfo',
    getBaseInfo = 'user/getBaseInfo',
    setFinancialReviewCount = 'user/setFinancialReviewCount'
}
export type UserCommits = {
    [UserCommitTypes.common_dep_arr]: User['common_dep_arr']
    [UserCommitTypes.insertInfo]: {key: UserKey, value: any}
    [UserCommitTypes.setUserInfo]: User['userInfo']
    [UserCommitTypes.setInfoPos]: User['infoPos']
    [UserCommitTypes.setImpt]: User['impt']
    [UserCommitTypes.setCustomerTags]: User['customerTags']
    [UserCommitTypes.setcustomerTagMap]: User['customerTagMap']
    [UserCommitTypes.setSysTags]: User['sysTags']
    [UserCommitTypes.setMenus]: User['menus']
    [UserCommitTypes.setGuideStatus]: User['guideStatus']
    [UserCommitTypes.setNotice]: User['notice']
    [UserCommitTypes.sourceType]: User['sourceType'][0]
    [UserCommitTypes.utmChannel]: string[]
    [UserCommitTypes.company]: {name: string}[]
    [UserCommitTypes.financialReviewCount]: number
    [UserCommitTypes.setRoleList]: User['roleList']
    [UserCommitTypes.setNormalData]: User['normalData']
    [UserCommitTypes.setProjectList]: User['projectList']
    [UserCommitTypes.setResolveQuestion]: User['resolveQuestion'],
    [UserCommitTypes.setShowQuestion]: User['showQuestion'],
    [UserCommitTypes.setSeeQuestionInfo]: User['seeQuestionInfo'],
    [UserCommitTypes.setHasQuestionTaskInfo]: User['hasQuestionTaskInfo'],
}
export type UserDispatches = {
    [UserDispatchType.getUserInfo]: void
    [UserDispatchType.setFinancialReviewCount]: void
    [UserDispatchType.getBaseInfo]: void
}
export interface User {
    [index: string]: any
    financialReviewCount: number
    infoPos: {
        dep: number
        dep_arr: {
            k: number
            v: string
            is_close?: boolean
        }[]
        dep_init: number
        fxs: number
        fxs_arr: {
            [index: string]: {
                [index: string]: {
                    deactive?: number
                    k: number
                    v: string
                }[]
            }
        }
        fxs_init: number
        group: number
        group_arr: {
            [index: string]: {
                groups: Group[]
            }
        }
        group_init: number
    }
    common_dep_arr: {
        name: string
        value: number
        sale_dep_id: number
        is_sale?: number
    }[]
    guideStatus: any[]
    userInfo: {
        avatar: string
        dep: number
        group: number
        role_id: number
        role_name: string
        role_type: number
        user_id: number
        username: string
    }
    menus: {
        route: string
        text: string
        value: number
    }[]
    impt: {
        [index: string]: {
            text: string
            value: number
        }
    }
    customerTagMap: {
        [index: string]: {
            text: string
            value: string
            valid: boolean
            color: string
        }
    }
    customerTags: {
        [index: string]: {
            text: string
            value: string
            valid: boolean
        }
    }
    notice: {
        can_pub_notice: boolean
        notice: {
            TemTitle?: string
            Title?: string
            Content: string
            Ctime: string
            Dep: string
            ExtData: string
            Gro: string
            Id: string
            Status: string
            Type: string
            UserId: string
            UserName: string
        }
    }
    sourceType: {
        [index: string]: string
        text: string
        value: string
    }[]
    sysTags: {
        text: string
        value: string
    }[]
    utmChannel: {
        text: string
        value: number
    }[]
    company: {
        name: string
    }[]
    tz: boolean
    // 日期选择默认日期
    dateTimeObj: {
        time_start: string
        time_end: string
    }
    roleList: {
        label: string
        value: number
        status: number
    }[]
    normalData: {
        duty: number
        isDuty: boolean
    }
    projectList: {
        id: number
        project_name: string
        description: string
        roles: {
            id: number
            name: string
            permission_ids: number[]
            project_id: number
        }[]
    }[]
    // 是否完成每日训练
    resolveQuestion: boolean
    // 每日训练弹窗
    showQuestion: boolean
    // 查看题库弹窗
    seeQuestionInfo: {
        show: boolean,
        item: {
            taskId: number,
            id: number
        }
    },
    // 有没有题库任务
    hasQuestionTaskInfo: boolean
}
const initUserState: User = {
    is_duty: false,
    common_dep_arr: [],
    infoPos: {
        dep: 0,
        dep_arr: [],
        dep_init: 0,
        fxs: 0,
        fxs_arr: {},
        fxs_init: 0,
        group: 0,
        group_arr: {},
        group_init: 0
    },
    userInfo: {
        avatar: '',
        dep: 0,
        group: 0,
        role_id: 0,
        role_name: '',
        role_type: 0,
        user_id: 0,
        username: ''
    },
    guideStatus: [],
    menus: [],
    impt: {},
    customerTagMap: {},
    customerTags: {},
    notice: {
        can_pub_notice: false,
        notice: {
            Content: '',
            Ctime: '',
            Dep: '',
            ExtData: '',
            Gro: '',
            Id: '',
            Status: '',
            Type: '',
            UserId: '',
            UserName: ''
        }
    },
    sourceType: [],
    sysTags: [],
    utmChannel: [],
    company: [],
    tz: false,
    financialReviewCount: 0,
    // 日期选择默认日期
    dateTimeObj: {
        time_start: formatDate(),
        time_end: formatDate()
    },
    roleList: [],
    normalData: {
        duty: 0,
        isDuty: false
    },
    projectList: [],
    resolveQuestion: false,
    showQuestion: false,
    seeQuestionInfo: {
        show: false,
        item: {
            taskId: 0,
            id: 0
        }
    },
    hasQuestionTaskInfo: true
}

export default {
    namespaced: true,

    state: initUserState,

    mutations: {
        setNormalData (state: User, obj: UserCommits[UserCommitTypes.setNormalData]) {
            state.normalData = obj
        },

        common_dep_arr (state: User, obj: UserCommits[UserCommitTypes.common_dep_arr]) {
            state.common_dep_arr = obj
        },

        insertInfo (state: User, obj: UserCommits[UserCommitTypes.insertInfo]) {
            state[obj.key] = obj.value
        },

        setUserInfo (state: User, obj: UserCommits[UserCommitTypes.setUserInfo]) {
            state.userInfo = obj
        },

        setInfoPos (state: User, obj: UserCommits[UserCommitTypes.setInfoPos]) {
            if (obj) {
                let dep = 0
                let group = 0
                let userId = 0

                obj.dep_arr.forEach(a => {
                    // 部门没有对应的groups
                    obj.group_arr[a.k] = obj.group_arr[a.k] || { groups: [] }
                    __utils.index.sortGroups(obj.group_arr[a.k].groups)
                    // 部门没有对应的fxs
                    obj.fxs_arr[a.k] = obj.fxs_arr[a.k] || {}
                })

                Object.values(obj.fxs_arr).map(a => {
                    Object.values(a).map(a => a.sort((a, b) => Number(a.deactive) - Number(b.deactive)))
                })

                if (obj.dep) {
                    obj.dep_arr.map((v) => {
                        if (v.k === obj.dep) {
                            dep = v.k
                        }
                    })
                } else {
                    dep = obj.dep_arr[0].k || 0
                }
                if (obj.group) {
                    obj.group_arr[obj.dep].groups.map((v) => {
                        if (v.value === obj.group) {
                            group = v.value
                        }
                    })
                } else {
                    group = (obj.group_arr[dep] && obj.group_arr[dep].groups[0].value) || 0
                }
                if (obj.fxs) {
                    obj.fxs_arr[obj.dep] && obj.fxs_arr[obj.dep][obj.group] && obj.fxs_arr[obj.dep][obj.group].map((v) => {
                        if (v.k === obj.fxs) {
                            userId = v.k
                        }
                    })
                } else {
                    userId = (dep && group && obj.fxs_arr[dep][group] && obj.fxs_arr[dep][group][0] && obj.fxs_arr[dep][group][0].k) || 0
                }
                obj.dep_init = dep
                obj.group_init = group
                obj.fxs_init = userId
            }
            state.infoPos = obj
        },

        setImpt (state: User, obj: UserCommits[UserCommitTypes.setImpt]) {
            if (obj) {
                obj[0] = {
                    text: '未分组',
                    value: 0
                }
            }
            state.impt = obj
            // 重点客户
        },

        setCustomerTags (state: User, obj: UserCommits[UserCommitTypes.setCustomerTags]) {
            obj[0] = {
                text: '客户级别',
                valid: true,
                value: ''
            }
            state.customerTags = obj
        },

        setcustomerTagMap (state: User, obj: UserCommits[UserCommitTypes.setcustomerTagMap]) {
            state.customerTagMap = obj
        },

        setSysTags (state: User, obj: UserCommits[UserCommitTypes.setSysTags]) {
            state.sysTags = obj
        },

        setMenus (state: User, obj: UserCommits[UserCommitTypes.setMenus]) {
            state.menus = obj
        },

        setGuideStatus (state: User, obj: UserCommits[UserCommitTypes.setGuideStatus]) {
            state.guideStatus = obj
        },

        setNotice (state: User, obj: UserCommits[UserCommitTypes.setNotice]) {
            state.notice = obj
        },

        sourceType (state: User, obj: UserCommits[UserCommitTypes.sourceType]) {
            let arrTmep: User['sourceType'] = []
            for (let key in obj) {
                arrTmep.push({
                    text: obj[key],
                    value: key
                })
            }
            arrTmep.unshift({
                text: '来源类型',
                value: ' '
            })
            state.sourceType = arrTmep
        },

        utmChannel (state: User, arr: UserCommits[UserCommitTypes.utmChannel]) {
            const res = arr.map((v, i) => {
                return {
                    text: v,
                    value: i
                }
            })
            res.unshift({
                text: '来源渠道',
                value: 0
            })
            state.utmChannel = res
        },

        company (state: User, arr: UserCommits[UserCommitTypes.company]) {
            state.company = arr
        },

        financialReviewCount (state: User, value: UserCommits[UserCommitTypes.financialReviewCount]) {
            state.financialReviewCount = value
        },

        setRoleList (state: User, value: UserCommits[UserCommitTypes.setRoleList]) {
            state.roleList = value
        },

        setProjectList (state: User, value: UserCommits[UserCommitTypes.setProjectList]) {
            state.projectList = value
        },

        setResolveQuestion (state: User, value: UserCommits[UserCommitTypes.setResolveQuestion]) {
            state.resolveQuestion = value
        },

        setShowQuestion (state: User, value: UserCommits[UserCommitTypes.setShowQuestion]) {
            state.showQuestion = value
        },

        setSeeQuestionInfo (state: User, value: UserCommits[UserCommitTypes.setSeeQuestionInfo]) {
            state.seeQuestionInfo = value
        },

        setHasQuestionTaskInfo (state: User, value: UserCommits[UserCommitTypes.setHasQuestionTaskInfo]) {
            state.hasQuestionTaskInfo = value
        }
    },

    actions: {
        async getBaseInfo () {
            const { data } = await __apis.user.userBaseInfo()

            __store.commit(UserCommitTypes.setNormalData, {
                ...data,
                isDuty: !!data.duty
            })
        },

        async getUserInfo () { // 检验是否登录请求
            const { data } = await __apis.home.home()

            __store.commit(UserCommitTypes.setInfoPos, data.dgu_arr)
            __store.commit(UserCommitTypes.setUserInfo, data.user_info)
            __store.commit(UserCommitTypes.setcustomerTagMap, data.customer_tag_map)
            __store.commit(UserCommitTypes.setCustomerTags, data.customer_tags)
            __store.commit(UserCommitTypes.setNotice, data.notice)
            __store.commit(UserCommitTypes.setImpt, data.impt_group)
            __store.commit(UserCommitTypes.setSysTags, data.sys_tags)
            __store.commit(UserCommitTypes.sourceType, data.source_type)
            __store.commit(UserCommitTypes.utmChannel, data.utm_channel)
            __store.commit(UserCommitTypes.common_dep_arr, data.common_dep_arr)
            __store.commit(UserCommitTypes.company, data.company)
        },

        async setFinancialReviewCount () {
            const { data } = await __apis.wallet.getWalletCashOutRecord({
                send_time_end: '',
                send_time_start: '',
                time_start: '',
                time_end: '',
                status: '0',
                page: 1,
                pagesize: 1,
                group_id: ''
            })
            __store.commit(UserCommitTypes.financialReviewCount, data.total)
        }
    }
}

// 时间格式化
function formatDate (timeStamp = +new Date()) {
    let date = new Date(timeStamp)
    return date.getFullYear() + '-' +
        prefixZero(date.getMonth() + 1) + '-' +
        prefixZero(date.getDate())
}

// 数字格式化
function prefixZero (num: number) {
    return num >= 10 ? num : '0' + num
}
