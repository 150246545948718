import { Component, Vue } from 'vue-property-decorator'
import { LabelValueArray, Table } from '@/types'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'
import applyInvalidCustomerComponent from '@/views/customer/layout/customerInfo/applyInvalidCustomer/index.vue'

interface TableItem {
    id: number
    ctime: string
    depName: string
    groupName: string
    userName: string
    customerId: number
    customerName: string
    customerTel: string
    houseName: string
    registerTime: string
    statusText: string
    status: number
    applyReason: string
    rejectReason: string
    showStatus: number
}

interface Filter {
    depId?: number,
    groupId?: number,
    userId?: number,
    status?: number,
    ctime: string[]
}
@Component({
    components: {
        InputSelectSearch,
        applyInvalidCustomerComponent
    }
})
export default class CustomerInvalidExaminePage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 50,
        pageSizes: [10, 20, 50, 100],
        total: 0,
        loading: false
    }

    filter: Filter = {
        depId: undefined,
        groupId: undefined,
        userId: undefined,
        status: undefined,
        ctime: []
    }

    StatusList: LabelValueArray = []

    rejectInfo = {
        visible: false,
        data: {
            id: 0,
            status: 0,
            remark: ''
        }
    }

    async rejectSave (value: string) {
        await this.__apis.customer.customerInvalidCheck({
            id: this.rejectInfo.data.id,
            status: this.rejectInfo.data.status,
            reject_reason: value
        })
        this.$message({
            message: '驳回成功！',
            type: 'success'
        })
        this.getData()
    }

    reject (item: TableItem, status: number) {
        this.rejectInfo = {
            visible: true,
            data: {
                id: item.id,
                status: status,
                remark: ''
            }
        }
    }

    // 0不显示操作按钮 1没丢公池 2有丢公池
    async pass (item: TableItem, status: number) {
        await this.__apis.customer.customerInvalidCheck({
            id: item.id,
            status
        })
        this.$message({
            message: '通过成功！',
            type: 'success'
        })
        this.getData()
    }

    toCustomerDetail (item: TableItem) {
        const { href } = this.$router.resolve(`/customer/detail/${item.customerId}`)
        window.open(href, '_blank')
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getData()
    }

    resetFilter (formName: string) {
        const form = this.$refs[formName] as any
        form.resetFields()
        this.tableCurrentChange(1)
    }

    async getData () {
        try {
            this.tableData.loading = true
            const filter = this.filter
            const { data } = await this.__apis.customer.getCustomerInvalidApplyRecords({
                status: filter.status,
                dep_id: filter.depId,
                gro_id: filter.groupId,
                user_id: filter.userId,
                apply_time_start: filter.ctime && filter.ctime[0] ? filter.ctime[0] + ' 00:00:00' : undefined,
                apply_time_end: filter.ctime && filter.ctime[1] ? filter.ctime[1] + ' 23:59:59' : undefined,
                page: this.tableData.page,
                pagesize: this.tableData.pageSize
            })
            this.tableData.data = data.list.map(a => {
                return {
                    id: a.id,
                    ctime: this.__utils.dayjs(a.apply_time).format('YYYY-MM-DD HH:mm'),
                    depName: this.__utils.index.getDepInfoById(a.apply_user_dep_id).v,
                    groupName: this.__utils.index.getGroupInfoById(a.apply_user_group_id).name,
                    userName: a.apply_user_name,
                    customerId: a.customer_id,
                    customerName: a.customer_name,
                    customerTel: a.mobile,
                    houseName: a.house_name,
                    registerTime: this.__utils.dayjs(a.customer_register_time).format('YYYY-MM-DD'),
                    statusText: this.__utils.index.getLabelByValue(this.StatusList, a.status),
                    status: a.status,
                    applyReason: a.apply_reason,
                    rejectReason: a.reject_reason,
                    showStatus: a.show_button_status
                }
            })
            this.tableData.total = data.total
        } finally {
            this.tableData.loading = false
        }
    }

    async getInvalidStatus () {
        const { data } = await this.__apis.customer.invalidStatus()
        this.StatusList = Object.keys(data).map((a) => {
            return {
                label: data[a],
                value: +a
            }
        })
    }

    async created () {
        if (this.__role.has([this.__role.ROLE.KEFU], false)) {
            this.filter.status = 2
        }
        await this.getInvalidStatus()
        await this.getData()
    }
}
