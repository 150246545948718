import { Component, Vue } from 'vue-property-decorator'
import EditComponent from '@/components/signOperate/index.vue'
import { Options } from '@/components/signOperate'
import { OnlineSignRecordResStatus, webSignStatus } from '@/util/const'
import { OnlineSignImages, saveSaleWqRecordReq } from '@/api/financial'
import { SuccessDetailDispatchType } from '@/store/successDetail'
import { format } from '@/util/date'

type TableItem = {
    id: number
    actionTime: string
    houseNo: string
    wqNo: string
    status: OnlineSignRecordResStatus
    remark?: string
    reviewRemark?: string
    reviewer?: string
    reviewTime?: string
} & Partial<OnlineSignImages>

@Component({
    components: {
        EditComponent
    }
})
export default class IndexPage extends Vue {
    get getButtonText () {
        if (!this.table.length) return ''
        const data = this.table[0]

        return data.status === OnlineSignRecordResStatus.Reject ? '重新提交' : '查看详情'
    }

    show = false

    editComponent: {
        show: boolean
        options?: Options
    } = {
        show: false,
        options: undefined
    }

    id = 0

    table: TableItem[] = []

    get
    allData () {
        return this.__store.state.success_detail.all_data
    }
    get
    my () {
        return this.__store.state.user.userInfo
    }

    async created () {
        this.id = Number(this.$route.params.id)

        await this.getData()

        if (this.allData.Id !== undefined) {
            this.loadWebSignData()
        }

        this.show = true
    }

    showEditComponent (item?: TableItem) {
        this.editComponent.show = true
        this.editComponent.options = item && {
            id: item.id,
            remark: item.remark || '',
            receipt: item.receipt || [],
            relationship: item.relationship || [],
            contract: item.contract || [],
            book: item.book || [],
            other: item.other || [],
            signId: item.wqNo,
            signNo: item.houseNo,
            time: item.actionTime,
            status: item.status,
            reviewRemark: item.reviewRemark,
            reviewer: item.reviewer,
            reviewTime: item.reviewTime
        }
    }

    getStatusText (item: TableItem) {
        return this.__utils.index.getLabelByValue(webSignStatus, item.status)
    }

    // 加载数据
    async getData () {
        const { data } = await this.__apis.financial.onlineSignRecord({
            value: this.id
        })

        if (!data) {
            return
        }

        this.table = [{
            id: data.id,
            actionTime: this.__utils.index.formatDate(new Date(data.sign_time), 'YYYY-MM-DD'),
            houseNo: data.sign_house_no,
            wqNo: data.sign_no,
            status: data.review_state,
            remark: data.sign_remark,
            reviewRemark: data.audit_opinion,
            contract: data.images.contract,
            receipt: data.images.receipt,
            relationship: data.images.relationship,
            book: data.images.book,
            other: data.images.other,
            reviewer: data.last_reviewer,
            reviewTime: data.last_review_time && this.__utils.index.formatDate(new Date(data.last_review_time), 'YYYY-MM-DD')
        }]
    }

    isReject (status: OnlineSignRecordResStatus) {
        return status === OnlineSignRecordResStatus.Reject
    }

    isWait (status: OnlineSignRecordResStatus) {
        return status === OnlineSignRecordResStatus.New
    }

    isPass (status: OnlineSignRecordResStatus) {
        return status === OnlineSignRecordResStatus.Pass
    }

    add = {
        time: ''
    }

    // 选择时间
    async changeTime () {
        await this.__apis.financial.saveExpectWangQianTime({
            id: this.id,
            expect_wq_time: this.add.time
        })

        // 保存成功
        this.$message.success('预计网签时间修改成功')

        this.__store.dispatch(SuccessDetailDispatchType.setAllData, +this.$route.params.id)
    }

    // 加载数据
    loadWebSignData () {
        // 预计网签时间
        this.add.time = this.allData.ExpectWangqianTime || ''
    }

    isFastSaveWangqianLoading: boolean = false
    // 一键设置网签
    async fastSaveWangqian () {
        if (this.isFastSaveWangqianLoading) {
            return
        }

        if (!confirm('设置之后无法撤销，确定要设置已网签吗？')) {
            return
        }

        this.isFastSaveWangqianLoading = true
        // console.log(this.allData.Id)
        // console.log(this.allData.Sid)

        try {
            const uid = +this.my.user_id
            const username = this.my.username
            // 直接构造网签信息
            const ext_data = '{"last_reviewer":' + JSON.stringify(username) + ',"last_review_time":' + (new Date()).getTime() + ',"review_state":"PASS","last_reviewer_id":' + JSON.stringify(uid) + ',"sign_image":"{\\"contract\\":[],\\"receipt\\":[],\\"relationship\\":[],\\"book\\":[],\\"other\\":[]}"}'
            // console.log(JSON.parse(ext_data))
            await this.__apis.financial.saveSaleWqRecord({
                sale_id: this.allData.Sid,
                ext_data: ext_data,
                house_no: 'F-0000',
                wq_no: 'Q-0000',
                action_time: format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
                remark: '由' + username + '一键生成',
                no_reward: 1
            } as saveSaleWqRecordReq)

            // 保存成功
            this.$message.success('操作成功')
            await this.__store.dispatch(SuccessDetailDispatchType.setAllData, +this.$route.params.id)
            await this.getData()
        } catch (e) {
            this.$message.error(e.message)
        } finally {
            this.isFastSaveWangqianLoading = false
        }
    }
}
