import { Component, Vue, Watch } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import selectComponent from '@/components/filters/select/index.vue'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'

import dateUtil from '@/util/date.ts'
import { routerBaseUrl } from '@/router'

@Component({
    components: {
        FilterComponent,
        selectComponent,
        FilterBusInputSelectComponent,
        FilterBusSelectComponent
    }
})
export default class IndexPage extends Vue {
    dataObj: any = {
        list: [],
        total: 1
    }
    page = 1
    pageSize = 20
    datePicker = dateUtil.getCurrentYearStartEndMonth() || ''
    filter: any = {}

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    @Watch('datePicker')
    watchDatePicker () {
        this.getGaiLanList()
    }

    mounted () {
        this.getGaiLanList()
    }

    pageChange (page: number) {
        this.page = page
        this.getGaiLanList()
    }

    // 导出
    exportData () {
        let time_start = this.filter.date ? this.filter.date[0] : this.datePicker[0]
        let time_end = this.filter.date ? this.filter.date[1] : this.datePicker[1]

        let data: any = {
            action_name: 'exportSalaryManageOverViewList',
            ...this.filter,
            time_start,
            time_end
        }

        this.__utils.index.downloadFile(data)
    }

    async getGaiLanList () {
        let filter = this.__utils.index.copyObj(this.filter)
        let time_start = filter.date ? filter.date[0] : this.datePicker[0]
        let time_end = filter.date ? filter.date[1] : this.datePicker[1]
        delete filter.date
        const { data } = await this.__apis.gzManage.getSalaryBonusOverView({
            page: this.page,
            pagesize: this.pageSize,
            ...filter,
            time_start: time_start,
            time_end: time_end
        })

        data.list.map((v: any) => {
            v.month_time = `${time_start}/${time_end}`
        })
        data.statistic_res = data.statistic_res || this.dataObj.statistic_res

        this.dataObj = data
        if (this.dataObj.statistic_res) {
            this.dataObj.list.unshift({
                month_time: '总计',
                ...data.statistic_res
            })
        }
    }

    // 筛选
    filterChange (data: any) {
        this.page = 1
        this.filter = data
        this.getGaiLanList()
    }

    queryGroupSearch (queryString: string, cb: any) {
        this.__utils.index.searchGroup(queryString, cb)
    }

    toTcDetail (id: string) {
        open(`${routerBaseUrl}/financial/gongziManage/tcDetail/${id}`, '_blank')
    }
}
