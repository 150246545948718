import { Component, Vue } from 'vue-property-decorator'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import FilterComponent from '@/components/filters/filter/index.vue'

@Component({
    components: {
        InputDateRangeComponent,
        FilterComponent
    }
})
export default class UserCenterOverflowPage extends Vue {
    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    table: any = {
        data: [],
        all: {},
        loading: false,
        time_start: '',
        time_end: ''
    }

    pagination = {
        total: 20,
        pagesize: 20,
        current: 1
    }

    mounted () {
        this.getData()
    }

    // 跳转提成详情页面
    gotoDetail (time: any) {
        this.$emit('gotoDetail', time)
    }

    filterChange (data: any) {
        this.table.time_start = ''
        this.table.time_end = ''

        for (let k in data) {
            if (k === 'startDate') {
                this.table.time_start = data[k][0]
                this.table.time_end = data[k][1]
            } else {
                // @ts-ignore
                this.table[k] = data[k]
            }
        }

        this.pagination.current = 1
        this.getData()
    }

    // 当前分页改变时候触发
    currentChange (page: number) {
        if (this.table.loading) return

        this.pagination.current = page
        this.getData()
    }

    async getData () {
        const { data } = await this.__apis.user.getMySalaryBonusOverView({
            page: this.pagination.current,
            pagesize: this.pagination.pagesize,
            time_start: this.table.time_start,
            time_end: this.table.time_end
        })

        this.table.data = []
        this.pagination.total = data.total
        if (data.list.length === 0) return

        // 总计
        if (data.statistic_res) {
            this.table.all = data.statistic_res
        }

        this.table.data.push(this.table.all, ...data.list)

        // 处理一些信息
        this.table.data.forEach((a: any) => {
            a.month_time2 = new Date(a.month_time * 1000).format('yyyy-MM')
        })
        this.table.data[0].month_time2 = '总计'
    }
}
