import { Component, Vue } from 'vue-property-decorator'
import tabsComponent from '@/components/wallet/tabs/index.vue'
import { __role } from '@/role'

@Component({
    components: {
        tabsComponent
    }
})
export default class IndexPage extends Vue {
    tabsComponent = {
        active: 0,
        items: [
            {
                text: '个人钱包',
                authKey: __role.permissions.PersonWalletPage,
                url: ['/qm-wallet', '/qm-wallet/fxs/detail']
            },
            // {
            //     text: '战队钱包',
            //     url: ['/qm-wallet/group', '/qm-wallet/group/detail']
            // },
            {
                text: '提现记录',
                authKey: __role.permissions.WithdrawRecordPage,
                url: ['/qm-wallet/record']
            }
        ]
    }

    created () {
        const item = this.tabsComponent.items[this.tabsComponent.active]
        if (item && !item.url.some(a => this.$route.path.includes(a))) {
            this.$router.push(item.url[0])
        }
    }
}
