import { Component, Vue, Watch } from 'vue-property-decorator'
import addComponent from '@/components/success-detail/commission/index.vue'
import { Notification } from 'element-ui'
import { SuccessDetailDispatchType } from '@/store/successDetail'
import { getSuccessDetailRes } from '@/api/financial'

@Component({
    components: {
        addComponent
    }
})
export default class IndexPage extends Vue {
    addComponentOptions = {
        show: false,
        money: '',
        remark: '',
        date: '',
        id: undefined,
        user_id: undefined
    }

    table: getSuccessDetailRes['BonusList'] = []

    @Watch('allData')
    watchAllData () {
        this.loadData()
    }

    get
    allData () {
        return this.__store.state.success_detail.all_data
    }

    created () {
        if (this.allData.Id !== undefined) {
            this.loadData()
        }
    }

    // 过滤每一列的类容
    formatColumn (r: any, c: any, cellValue: any) {
        if (typeof cellValue === 'number') {
            return cellValue
        } else {
            return cellValue || ''
        }
    }

    // 修改记录
    editRecord (item: any) {
        this.addComponentOptions.date = item.row.SendTime
        this.addComponentOptions.remark = item.row.Remark
        this.addComponentOptions.money = item.row.Money
        this.addComponentOptions.id = item.row.Id
        this.addComponentOptions.user_id = item.row.Username
        this.addComponentOptions.show = true
    }

    // 删除记录
    async deleteRecord (item: any) {
        await this.__apis.financial.deleteKpDzFyTfRecords({
            id: item.row.Id,
            type: 'bonus'
        })

        // 保存成功
        Notification.success('删除提成记录成功')

        this.__store.dispatch(SuccessDetailDispatchType.setAllData, +this.$route.params.id)
    }

    showAddComponent () {
        this.addComponentOptions.date = ''
        this.addComponentOptions.remark = ''
        this.addComponentOptions.money = ''
        this.addComponentOptions.id = undefined
        this.addComponentOptions.user_id = undefined
        this.addComponentOptions.show = true
    } // 显示添加弹窗

    loadData () {
        this.table = this.allData.BonusList
    }
}
