import { Component, Mixins } from 'vue-property-decorator'
import InputHouseSearch from '@/components/InputHouseSearch/index.vue'
import CustomerMixin from '../mixin'
import { customerListRes } from '@/api/customer'
import { storageName } from '@/localStorage'

const CustomerFromType = [
    {
        label: '搜索客户',
        value: 1
    },
    {
        label: '信息流客户',
        value: 2
    },
    {
        label: '电话客户',
        value: 3
    }
]

const CustomerMobileValidType = [
    {
        label: '过验',
        value: 1
    },
    {
        label: '未过验',
        value: 0
    }
]

const CustomerDemandType = [
    {
        label: '住宅',
        value: 1
    },
    {
        label: '公寓',
        value: 2
    }
]

const CustomerFollowFilterType = [
    {
        label: '包含',
        value: 1
    },
    {
        label: '排除',
        value: 0
    }
]

const CustomerGuideStatusType = [
    {
        label: '已带看',
        value: 1
    },
    {
        label: '未带看',
        value: 0
    }
]

@Component({
    components: {
        InputHouseSearch
    }
})
export default class CustomerPublicPage extends Mixins(CustomerMixin) {
    customerSelectCurrent = 0
    searchVisible = true

    CustomerFromType = CustomerFromType
    CustomerMobileValidType = CustomerMobileValidType
    CustomerDemandType = CustomerDemandType
    CustomerFollowFilterType = CustomerFollowFilterType
    CustomerGuideStatusType = CustomerGuideStatusType

    form = {
        ctime: '',
        last_follow_time: '',
        name: '',
        mobile: '',
        house: '',
        tag: '',
        impt_group: '',
        intention_area: '',
        keywords: '',
        source_type: '',
        mark: '',
        renling_type: '',
        dai_kan_count: '',
        es_comparison_type: '',

        mobile_valid: '',
        house_property_type: '',
        customer_type: '',
        mobile_location: '',
        key_word_include: '',
        key_words_log: []
    }
    dataObj: customerListRes = {
        res: {
            customers: []
        }
    } as any
    page = 1
    pageSize = 50
    total = 0
    sysTags2: {
        [index: string]: any
    } = {}
    sysTags3: {
        [index: string]: any
    } = {}
    dialogEdit = false
    editForm = {
        tag: '',
        impt_group: -1,
        log: ''
    }
    formLabelWidth = '120px'
    sortType = ''
    sortMode = ''
    loading = false

    exportParams: any = {}

    get
    customerTags () {
        const data = this.__store.state.user.customerTags
        return Object.keys(data).reduce((s, v) => {
            if (data[v].valid && data[v].value) {
                s[v] = data[v]
            }
            return s
        }, {} as any)
    }

    mounted () {
        this.sysTags2 = this.sysTagsIn()
        this.sysTags3 = this.sysTagsIn()

        // const filterStorage = this.__localStorage.getLocalStorage(storageName.PUBLICPOOLCUSTOMERFILTER) as any
        // if (filterStorage) {
        //     console.log('filterStorage', filterStorage)
        //     this.form = filterStorage
        // }

        this.getData()
    }

    // 客户来源筛选
    changeFromType (value: string) {
        if (this.form.renling_type === value) {
            this.form.renling_type = ''
        } else {
            this.form.renling_type = value
        }
    }

    // 客户级别筛选
    changeLevel (value: string) {
        if (this.form.tag === value) {
            this.form.tag = ''
        } else {
            this.form.tag = value
        }
    }

    // 拷贝系统标签
    sysTagsIn () {
        return this.__store.state.user.sysTags && this.__utils.index.copyObj(this.__store.state.user.sysTags)
    }

    async getData () {
        this.loading = true
        let sys = []
        if (this.sysTags2) {
            for (let key in this.sysTags2) {
                if (this.sysTags2[key].active) {
                    sys.push(this.sysTags2[key].value)
                }
            }
        }

        let form = this.__utils.index.copyObj(this.form)
        let data = {
            ...form,
            type: 'pubpool',
            page: this.page,
            pagesize: this.pageSize,

            yesterdayToPubPool: this.customerSelectCurrent === 0 ? 1 : '', // 昨日进入公池
            groupPubPool: this.customerSelectCurrent === 2 ? 1 : '', // 部门公池客户

            tag: form.tag === '0' ? undefined : form.tag,
            sys_tag: sys.join(','),

            mark: form.mark !== '' ? +form.mark : undefined,
            impt_group: form.impt_group !== '' ? +form.impt_group : undefined,

            ctime_begin: form.ctime ? form.ctime[0] : '',
            ctime_stop: form.ctime ? form.ctime[1] : '',

            last_follow_time_begin: form.last_follow_time ? form.last_follow_time[0] : '',
            last_follow_time_stop: form.last_follow_time ? form.last_follow_time[1] : '',

            sort_field: this.sortType,
            sort_mode: this.sortMode,

            dai_kan_count: form.dai_kan_count === '' ? undefined : form.dai_kan_count,
            es_comparison_type: form.dai_kan_count !== '' ? (form.dai_kan_count ? 'gte' : 'eq') : undefined,

            mobile_valid: form.mobile_valid === '' ? undefined : Boolean(form.mobile_valid),
            house_property_type: form.house_property_type || undefined,
            customer_type: form.customer_type || undefined,
            mobile_location: form.mobile_location || undefined,
            key_word_include: form.key_word_include === '' ? undefined : Boolean(form.key_word_include),
            key_words_log: form.key_word_include !== '' ? form.key_words_log : undefined
        }
        data.ctime = ''
        data.last_follow_time = ''

        const res = await this.__apis.customer.getCustomerList(data)

        this.exportParams = data

        this.__localStorage.setLocalStorage(storageName.PUBLICPOOLCUSTOMERFILTER, this.form)

        this.dataObj = res.data
        this.total = res.data.res.total
        this.loading = false
    }

    // 搜索
    clickSearch () {
        this.page = 1
        this.getData()
    }

    // 清空筛选
    resetFrom (formName: string) {
        const form = this.$refs[formName] as any
        form.resetFields()
        for (let key in this.sysTags2) {
            this.$set(this.sysTags2[key], 'active', false)
        }
        this.form.renling_type = ''
        this.form.es_comparison_type = ''
        this.form.key_word_include = ''
        this.page = 1
        this.getData()
    }

    // 导出客户
    btnLoading = false
    async exportTable () {
        if (this.btnLoading) return
        try {
            this.btnLoading = true
            const res = await this.__apis.customer.customerExport({ ...this.exportParams, page: 1, pagesize: 5000 })
            // console.log(res)
            this.__utils.index.fileDownload(res)
        } finally {
            this.btnLoading = false
        }
    }

    // 筛选展开收起
    clickSearchVisible () {
        this.searchVisible = !this.searchVisible
    }

    async toRenling (obj: any) {
        this.loading = true
        if (this.__utils.index.limitRepeatClick()) return
        try {
            if (obj.has_take) {
                await this.__apis.customer.cancelRenlin(obj.id)
            } else {
                await this.__apis.customer.renlin(obj.id)
            }

            this.$notify({
                title: '成功',
                message: obj.has_take ? '取消认领成功！' : '认领成功！',
                type: 'success'
            })
            // @ts-ignore
            obj.saleman = obj.has_take ? '' : this.userInfo.username
            obj.has_take = !obj.has_take
        } catch (e) {
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
            this.loading = false
        }
    }
}
