import { Vue, Component } from 'vue-property-decorator'
import pageHeaderComponent from '../../header/index.vue'
import peiXunComponent from './peixun/index.vue'
import { getPxbTrainDetailRes } from '@/api/grow'

@Component({
    components: {
        pageHeaderComponent,
        peiXunComponent
    }
})
export default class IndexPage extends Vue {
    headerComponent = {
        breadcrumbs: [
            {
                label: '成长管理',
                path: '/grow-manage'
            }, {
                label: '新训班',
                path: '/grow-manage/xxb'
            }
        ]
    }

    peiXunData: getPxbTrainDetailRes = {
        analog_lecture: null,
        comment: '',
        ctime: 0,
        daily_manage_lecture: null,
        down_team: null,
        employee_id: 0,
        end_time: 0,
        is_done: 0,
        knowledge_lecture: null,
        mtime: 0,
        number_periods: 0,
        start_time: 0,
        team_leader: '',
        training_experience: ''
    }

    async getData () {
        this.loadingToggle(true)
        const { data } = await this.__apis.grow.getPxbTrainDetail(+this.$route.params.id)

        this.peiXunData = {
            ...data,
            daily_manage_lecture: data.daily_manage_lecture || null,
            analog_lecture: data.analog_lecture || null,
            knowledge_lecture: data.knowledge_lecture || null
        }

        this.loadingToggle(false)
    }

    loading = false
    loadingToggle (val: boolean) {
        this.loading = val
    }

    get
    title () {
        return this.$route.query.name + '培训管理'
    }

    created () {
        this.getData()
    }
}
