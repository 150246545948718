import { Component, Mixins } from 'vue-property-decorator'
import CustomerMixin from '../mixin'

@Component({
    components: {
    }
})
export default class CustomerImportantPage extends Mixins(CustomerMixin) {
    tableData = {
        page: 1,
        pageSize: 50,
        pageSizes: [10, 20, 50, 100],
        total: 0
    }
    dataObj = {} as any
    customerSelectCurrent = 1

    sysTags3: {
        [index: string]: any
    } = {}
    dialogEdit = false
    editForm = {
        tag: '',
        impt_group: -1,
        log: '',
        gjTime: '',
        dai_kan_count: '',
        es_comparison_type: ''
    }

    form: {
        [key: string]: any
    } = {
        dep: '',
        group: '',
        user_id: '',
        mark: '',
        source_type: '',
        has_guide: 0
    }
    loading = false

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    get
    showFollowCustomerList () {
        return this.__role.check(this.__role.permissions.FollowCustomerList)
    }

    get
    imtMenus () {
        let res: any = {}

        for (let key in this.__store.state.user.impt) {
            if (key !== '0') {
                res[key] = this.__store.state.user.impt[key]
            }
        }

        if (this.showFollowCustomerList) {
            res['0'] = {
                text: '我的关注',
                value: 0
            }
        }

        return res
    }

    created () {
        this.form.dep = this.infoPos.dep
        this.form.group = this.infoPos.group
        this.form.user_id = this.infoPos.fxs

        this.sysTags3 = this.sysTagsIn()

        if (this.showFollowCustomerList) {
            this.customerSelectCurrent = 0
        }

        this.getData()
    }

    filterReset () {
        this.form = {
            dep: this.infoPos.dep,
            group: this.infoPos.group,
            user_id: this.infoPos.fxs,
            mark: '',
            source_type: '',
            has_guide: '',
            dai_kan_count: '',
            es_comparison_type: ''
        }
        this.level.active = ''
        this.filterSubmit()
    }

    filterSubmit () {
        this.tableData.page = 1
        this.getData()
    }

    // 客户级别筛选
    changeLevel (value: string) {
        if (this.level.active === value) {
            this.level.active = ''
        } else {
            this.level.active = value
        }
    }

    changeDep () {
        this.form.group = ''
    }

    changeGroup () {
        this.form.user_id = ''
    }

    // 拷贝系统标签
    sysTagsIn () {
        return this.__store.state.user.sysTags && this.__utils.index.copyObj(this.__store.state.user.sysTags)
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getData()
    }

    async cancelFollow (item: any) {
        if (item.follow_id) {
            await this.__apis.customer.deleteFollowCustomer(item.follow_id)
        }
        this.getData()
        this.$notify({
            title: '成功',
            message: `取消成功！`,
            type: 'success'
        })
    }

    toDetail (obj: any, bool: boolean) {
        if (bool) {
            let { href } = this.$router.resolve({ name: '/customer/detail', params: { id: obj.id } })
            window.open(href, '_blank')
        } else {
            this.$router.push(obj)
        }
    }

    async getData () {
        try {
            this.loading = true

            if (this.customerSelectCurrent === 0) {
                let { data } = await this.__apis.customer.followCustomerListDtoListQuery({
                    sort_field: this.sortType === 'reg_time' ? 'ctime' : this.sortType,
                    sort_mode: this.sortMode,
                    tag: this.level.active,
                    fxs_user_id: this.form.user_id,
                    group_id: this.form.group,
                    mark: this.form.mark,
                    dai_kan_count: this.form.dai_kan_count,
                    es_comparison_type: this.form.es_comparison_type,
                    page: this.tableData.page,
                    pagesize: this.tableData.pageSize
                })
                this.dataObj = {
                    res: {
                        customers: data.rows
                    }
                }
                this.tableData.total = data.total
            } else {
                let { data } = await this.__apis.customer.getCustomerList({
                    type: 'impt_group',
                    impt_group: this.customerSelectCurrent,
                    sort_field: this.sortType,
                    sort_mode: this.sortMode,
                    page: this.tableData.page,
                    pagesize: this.tableData.pageSize,
                    ...this.form,
                    tag: this.level.active
                })
                this.dataObj = data
                this.tableData.total = data.res.total
            }
        } finally {
            this.loading = false
        }
    }
}
