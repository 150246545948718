import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import FilterComponent from '@/components/filters/filter/index.vue'
import dateRangeSelectComponent from '@/components/wallet/date-range-select/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'

interface TableItem {
    Id: number
    UserName: string
    depName: string
    GroupName: string
    RoleName: string
    OldDepName: string
    OldGroupName: string
    OldRoleName: string
    Ctime: string
    Operator: string
}

@Component({
    components: {
        FilterComponent,
        dateRangeSelectComponent,
        SelectComponent,
        InputDateRangeComponent,
        FilterBusinessSelect
    }
})
export default class AccountUserChangeRecords extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 10,
        total: 0
    }

    filter:{
        user_id?: number
        time_start?: string
        time_end?: string
    } = {}

    created () {
        this.getData()
    }

    async getData () {
        let { data } = await this.__apis.system.userChangeRecord({
            ...this.filter,
            page: this.tableData.page,
            pagesize: this.tableData.pageSize
        })
        this.tableData.total = data.total
        this.tableData.data = data.list.map(v => {
            return {
                Id: v.id,
                UserName: v.user_info.user_name,
                depName: v.dep_name,
                GroupName: v.group_name,
                RoleName: v.role_name,
                OldDepName: v.old_dep_name,
                OldGroupName: v.old_group_name,
                OldRoleName: v.old_role_name,
                Ctime: this.__utils.index.formatDate(new Date(v.ctime), 'YYYY-MM-DD'),
                Operator: v.operator
            }
        })
    }

    filterChange (item: any) {
        console.log('filter', item)
        Object.keys(item).map((v:any) => {
            if (v === 'ctime') {
                item.time_start = item[v][0] + ' 00:00:00'
                item.time_end = item[v][1] + ' 23:59:59'
                delete item[v]
            }
        })
        this.filter = item
        this.tableData.page = 1
        this.getData()
    }

    tableCurrentChange (page: number) {
        this.tableData.page = page
        this.getData()
    }
}
