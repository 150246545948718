import { Component, Prop, Vue } from 'vue-property-decorator'
import UploadImage from '@/components/UploadImg/index.vue'
import { OnlineSignRecordResStatus, webSignStatus } from '@/util/const'
import { OnlineSignImages } from '@/api/financial'

export type Options = OnlineSignImages & {
    id?: number
    time: string
    signNo: string
    signId: string
    remark: string
    reviewer?: string
    reviewTime?: string
    status?: OnlineSignRecordResStatus
    reviewRemark?: string
}

@Component({
    components: {
        UploadImage
    }
})
export default class EditComponent extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    show !: boolean

    @Prop({
        type: Object,
        default () {
            return {}
        }
    })
    options !: Options

    @Prop({
        type: Number,
        default: 0
    })
    sid !: number

    get
    isEdit () {
        if (this.sid) {
            return this.editMode
        } else {
            return !this.form.status || this.isReject
        }
    }

    get
    isPass () {
        return this.form.status === OnlineSignRecordResStatus.Pass
    }

    get
    isWait () {
        return this.form.status === OnlineSignRecordResStatus.New
    }

    get
    isReject () {
        return this.form.status === OnlineSignRecordResStatus.Reject
    }

    get
    getStatusText () {
        return this.__utils.index.getLabelByValue(webSignStatus, this.form.status)
    }

    get
    showSubmitButtons () {
        if (this.editMode || this.rejectMode) return false

        return !this.sid && (!this.form.status || this.form.status === OnlineSignRecordResStatus.Reject)
    }

    get
    showReviewButtons () {
        if (this.editMode || this.rejectMode) return false

        return this.sid && this.form.status === OnlineSignRecordResStatus.New
    }

    get
    showEditButtons () {
        return this.editMode
    }

    get
    showRejectButtons () {
        return this.rejectMode
    }

    get
    getTitle () {
        if (this.sid) {
            return this.form.status === OnlineSignRecordResStatus.New ? '网签审核' : '网签详情'
        } else {
            return this.form.status ? '网签数据详情' : '网签数据录入'
        }
    }

    form: Options = {} as Options
    form1: Options = {} as Options // 只在审核的编辑模式中需要用到，用于还原之前的数据

    isSubmit = false

    id = 0

    visible = false

    initFormData (item: Options) {
        this.form = this.__utils.index.copyObj(item)
    }

    async created () {
        this.id = Number(this.$route.params.id)

        this.initFormData(this.options)

        await this.getDetail()

        this.visible = true
    }

    async getDetail () {
        if (!this.sid) {
            return
        }

        const { data } = await this.__apis.channelManage.onlineSignDetail({
            value: this.sid
        })

        if (!data) return

        this.form = {
            id: this.sid,
            time: this.__utils.index.formatDate(new Date(data.sign_time), 'YYYY-MM-DD'),
            signNo: data.sign_house_no,
            signId: data.sign_no,
            remark: data.sign_remark,
            contract: data.contract,
            receipt: data.receipt,
            relationship: data.relationship,
            book: data.book,
            other: data.other,
            reviewer: this.__utils.index.formatDate(new Date(data.last_review_time), 'YYYY-MM-DD'),
            reviewTime: data.last_reviewer,
            status: data.review_state,
            reviewRemark: data.audit_opinion
        }

        this.form1 = this.__utils.index.copyObj(this.form)
    }

    async pass () {
        try {
            await this.$confirm('确认通过后成交补贴立即发放给分析师', {
                customClass: 'signOperate-confirm',
                showClose: false,
                title: '确认通过吗？',
                type: 'warning',
                center: true
            })

            await this.__apis.channelManage.onlineSignAuditPass({
                value: this.sid
            })

            this.$message.success('审核通过')
            this.enter()
        } catch (e) {}
    }

    editMode = false

    showEdit () {
        this.editMode = true
    }

    hideEdit () {
        this.editMode = false
        this.initFormData(this.form1)
    }

    async saveEdit () {
        await this.__apis.channelManage.onlineSignEdit({
            ...this.getComBody(),
            apply_online_id: this.sid
        })

        await this.getDetail()

        this.$message.success('保存成功')

        this.hideEdit()
    }

    get
    btnRejectEnterDisabled () {
        return !this.rejectReason.trim()
    }

    rejectMode = false

    rejectReason = ''

    showReject () {
        this.rejectMode = true
    }

    hideReject () {
        this.rejectMode = false
    }

    async saveReject () {
        try {
            await this.$confirm('确认驳回后需要分析师重新提交', {
                customClass: 'signOperate-confirm',
                showClose: false,
                title: '确认驳回吗？',
                type: 'warning',
                center: true
            })

            await this.__apis.channelManage.onlineSignAuditReject({
                online_sign_id: this.sid,
                audit_opinion: this.rejectReason
            })

            this.$message.success('驳回成功')
            this.enter()
        } catch (e) {}
    }

    getComBody () {
        return {
            sign_time: this.form.time,
            sign_house_no: this.form.signNo,
            sign_no: this.form.signId,
            sign_remark: this.form.remark,
            images: JSON.stringify(this.__utils.index.filterObject({
                contract: this.form.contract,
                receipt: this.form.receipt,
                relationship: this.form.relationship,
                book: this.form.book,
                other: this.form.other
            }))
        }
    }

    async submit () {
        this.isSubmit = true

        if ([this.form.time, this.form.signNo, this.form.signId].some(a => {
            return this.isError(a)
        })) {
            return
        }

        try {
            await this.$confirm('数据提交后无法修改，请仔细核对', {
                title: '是否确认提交?',
                type: 'warning',
                center: true,
                customClass: 'webSign-edit-dialog-confirm',
                cancelButtonText: '返回'
            })

            // 修改
            if (this.form.id) {
                await this.__apis.financial.onlineSignResetApply({
                    ...this.getComBody(),
                    apply_online_id: this.form.id
                })
            } else {
                // 新增
                await this.__apis.financial.onlineSignApply({
                    ...this.getComBody(),
                    sid: this.id
                })
            }

            this.$message.success('提交成功')
            this.enter()
        } catch (e) {}
    }

    enter () {
        this.$emit('enter')
        this.close()
    }

    isError (val: string | undefined | string[]) {
        if (!this.isSubmit) return false

        if (typeof val === 'string') {
            return !val.trim()
        }

        return !val || !val.length
    }

    close () {
        this.$emit('update:show', false)
    }
}
