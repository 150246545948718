import { Component, Prop, Vue } from 'vue-property-decorator'
import { LabelValueArray } from '@/types'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'

export type CallEditItem = {
    id: number
    name: string
    phone: string
    houseName: string
    houseId: number
    registerTime: string
    sysTag: number[]
    log: string
}

@Component({
    components: {
        inputSelectSearch
    }
})
export default class RobotCallEditComponent extends Vue {
    @Prop({
        default: false,
        type: Boolean
    })
    show !: boolean

    @Prop({
        default () {
            return {}
        },
        type: Object,
        required: true
    })
    item !: CallEditItem

    get
    sysTags () {
        const sysTags = this.__store.state.user.sysTags
        return sysTags && this.__utils.index.copyObj(sysTags)
    }

    selectHouse (e: {id: number, label: string}) {
        this.item.houseId = e.id
        this.item.houseName = e.label
    }

    sysTagHandle (item: {text: string, value: number}) {
        const sysTag = this.item.sysTag
        if (sysTag.includes(item.value)) {
            sysTag.splice(sysTag.indexOf(item.value), 1)
        } else {
            sysTag.push(item.value)
        }
    }

    async save () {
        if (this.__utils.index.limitRepeatClick()) return
        try {
            const item = this.item
            await this.__apis.phoneRecord.saveQiYuCustomerInfo({
                id: item.id,
                customer_name: item.name,
                house_id: item.houseId,
                house_name: item.houseName,
                sys_tags: item.sysTag,
                operate_log: item.log
            })
            this.$message.success(`提交成功！`)
            this.close()
            this.$emit('change')
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    close () {
        this.$emit('update:show', false)
    }
}
