import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import InputHouseSearch from '@/components/InputHouseSearch/index.vue'

import FilterComponent from '@/components/filters/filter/index.vue'
import inputDateRange from '@/components/filters/inputDateRange/index.vue'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'
import orderCustomerDialog from '../orderCustomerDialog/index.vue'
import { Table } from '@/types'

@Component({
    components: {
        InputHouseSearch,
        FilterComponent,
        inputDateRange,
        FilterBusSelectComponent,
        FilterBusInputSelectComponent,
        orderCustomerDialog
    }
})
export default class OrderRecordPage extends Vue {
    @Prop({ default: false })
    refresh!:boolean

    recordType: {
        [index: number]: string
    } = {
        0: '正常预约',
        1: '本月补预约',
        2: '退客自动补预约',
        3: '抢客'
    }

    tableData: Table<{
        id: number
        dep: string
        group: string
        fxs: string
        ctime: string
        orderTimeStart: string
        orderTimeEnd: string
        houseName: string
        orderStatus: string
        recordType: string
        recordTypeIndex?: number
        money: {
            fxs: string
            group: string
        }
        type1CompleteCount?: number
        userId?: number
        canCancel?: boolean
    }> = {
        data: [],
        total: 0,
        page: 1,
        pageSize: 50
    }

    page = 1
    total = 0
    sortType = ''
    sortMode = ''
    formLabelWidth = '90px'
    filter = {}
    applyDetail = {
        visible: false,
        data: {}
    }
    order = {
        visible: false,
        data: {}
    }

    get infoPos () {
        return this.__store.state.user.infoPos
    }

    get userInfo () {
        return this.__store.state.user.userInfo
    }

    @Watch('refresh')
    refreshChange () {
        this.getData()
    }

    mounted () {
        this.getData()
    }

    async getData () {
        const { data } = await this.__apis.customer.getCustomerOrderRecord({
            ...this.filter,
            page: this.page,
            pagesize: this.tableData.pageSize
        })

        this.tableData.data = []
        data.list.forEach((v) => {
            this.tableData.data.push({
                id: v.Id,
                dep: v.UserInfo.DepartmentName,
                group: this.__utils.index.getGroupInfoById(v.GroupId).name,
                fxs: v.UserInfo.UserName,
                ctime: v.Ctime,
                orderTimeStart: this.__utils.dayjs(v.OrderTimeStart).format('YYYY-MM'),
                orderTimeEnd: v.OrderTimeEnd,
                houseName: v.PlaceInfo.house_name,
                orderStatus: `${v.Type1CompleteCount}/${v.Type1Count}`,
                recordType: this.recordType[v.OrderType],
                recordTypeIndex: v.OrderType,
                money: {
                    fxs: this.__utils.index.formatMoney(v.MoneyUse.fxs_money),
                    group: this.__utils.index.formatMoney(v.MoneyUse.dz_money)
                },
                type1CompleteCount: v.Type1CompleteCount,
                userId: v.UserId,
                canCancel: v.CanCancel
            })
        })
        data.total > 0 && this.tableData.data.unshift({
            id: 0,
            dep: '合计',
            group: '',
            fxs: '',
            ctime: '',
            orderTimeStart: '',
            orderTimeEnd: '',
            houseName: '',
            orderStatus: `${data.statistic_res.complete_count || (data.statistic_res && data.statistic_res.complete_count)}/${data.statistic_res.count1 || (data.statistic_res && data.statistic_res.count1)}`,
            recordType: '',
            money: {
                fxs: '',
                group: ''
            }
        })
        this.tableData.total = data.total
    }
    // 筛选
    filterChange (data: any) {
        if (data.orderDate) {
            data.order_time_start = data.orderDate
            delete data.orderDate
        }
        this.filter = data
        this.page = 1
        this.getData()
    }

    async showOrderCustomer (id:number) {
        let res = await this.__apis.customer.getCustomerOrderWithCustomerRecordList(id)
        this.applyDetail.data = res.data
        this.applyDetail.visible = true
    }

    async cancelOrder (id:number) {
        try {
            await this.$confirm('请再次确认是否取消客户预约！', '确认取消预约', {
                confirmButtonText: '确 定',
                cancelButtonText: '取 消',
                type: 'warning',
                customClass: 'my_confirm',
                center: true
            })

            await this.__apis.customer.cancelOrderCustomer(id)
            this.$message({
                type: 'success',
                message: '取消预约成功!'
            })
            this.getData()
        } catch (e) {
            console.log(e)
        }
    }

    pageChange (page:number) {
        this.page = page
        this.getData()
    }
}
