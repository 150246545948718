import { Component, Prop, Vue } from 'vue-property-decorator'
import importComponent from '@/components/wallet/import/index.vue'
import editMoneyComponent from '@/components/wallet/edit-money/index.vue'
import { Table } from '@/types'
import { editMoneyData } from '@/components/wallet/edit-money'
import FilterComponent from '@/components/filters/filter/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'

interface TableItem {
    id: number,
    department: string,
    group: string,
    fxs: string,
    month: string,
    allMoney: string,
    removeMoney: string,
    getMoney: string,
    status: string,
    remark: string
    userId: string
}

interface Filter {
    [index: string]: string
    department: string
    group: string
    fxs: string
    time: string
    status: string
}

@Component({
    components: {
        importComponent,
        editMoneyComponent,
        FilterComponent,
        SelectComponent,
        InputDateRangeComponent,
        CheckBoxComponent,
        FilterBusinessSelect
    }
})
export default class IndexPage extends Vue {
    @Prop({
        default: '',
        type: String
    })
    type !: string

    filter: Filter = {
        status: '',
        department: '',
        group: '',
        fxs: '',
        time: ''
    }

    status = [
        {
            value: 0,
            label: '未发放'
        }, {
            value: 1,
            label: '已发放'
        }
    ]

    importComponent = {
        show: false
    }

    editMoneyComponent: {
        show: boolean,
        data: editMoneyData
    } = {
        show: false,
        data: {
            id: '',
            department: '',
            group: '',
            fxs: '',
            allMoney: '',
            removeMoney: '',
            remark: '',
            month: '',
            userId: ''
        }
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 50
    }

    selectData: number[] = []

    created () {
        this.getTableData()
    }

    // 删除记录
    async deleteRecord (item: TableItem) {
        try {
            await this.$confirm(`<span style="color: #F5222D;">请确定是否要删除 ${item.fxs} 资金发放吗？</span>`, '删除发放', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })

            await this.__apis.wallet.deleteSendRecord(item.id)

            this.$message({
                type: 'success',
                message: '已删除'
            })

            this.getTableData()
        } catch (e) {
            console.log(e)
        }
    }

    async exportData () {
        await this.__utils.index.downloadFile({
            action_name: 'exportFinanceSendRecord',
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            dep_id: this.filter.department,
            group_id: this.filter.group,
            employee_id: this.filter.fxs,
            type: this.type,
            action_time: this.filter.time,
            status: this.filter.status
        })
    }

    editMoneySuccess () {
        this.$message({
            type: 'success',
            message: '修改成功'
        })

        this.getTableData()
    }

    importSuccess () {
        this.$message({
            type: 'success',
            message: '添加成功'
        })

        this.tableData.page = 1
        this.getTableData()
    }

    selectable (item: TableItem) {
        return item.status === '未发放'
    }

    async getTableData () {
        const { data } = await this.__apis.wallet.getFinanceSendRecordList({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            common_dep_id: this.filter.department,
            group_id: this.filter.group,
            employee_id: this.filter.fxs,
            type: this.type,
            action_time: this.filter.time,
            status: this.filter.status
        })

        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.Id,
                department: a.UserInfo.CommonDepartmentName,
                group: a.UserInfo.GroupName,
                fxs: a.UserInfo.UserName,
                month: a.ActionTime.slice(0, 7),
                allMoney: this.__utils.index.formatMoney(a.MustMoney),
                removeMoney: this.__utils.index.formatMoney(a.DeductMoney),
                getMoney: this.__utils.index.formatMoney(a.FinalMoney),
                status: this.status.filter(s => s.value === a.Status)[0].label,
                remark: a.Remark,
                userId: a.UserInfo.UserId.toString()
            }
        })
        this.tableData.data.unshift({
            id: -1,
            department: '合计',
            group: '',
            fxs: '',
            month: '',
            allMoney: this.__utils.index.formatMoney(+data.statistic_res.MustMoney),
            removeMoney: this.__utils.index.formatMoney(+data.statistic_res.DeductMoney),
            getMoney: this.__utils.index.formatMoney(+data.statistic_res.FinalMoney),
            status: '',
            remark: '',
            userId: ''
        })
    }

    searchGroup (text: string, cb: any) {
        this.__utils.index.searchGroup(text, cb, { dep: this.filter.department })
    }

    searchFxs (text: string, cb: any) {
        this.__utils.index.searchEmployee(text, cb)
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        this.getTableData()
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }

    // 金额发放
    async doSendMoney (id: number | number[]) {
        if (typeof id === 'number') {
            await this.__apis.wallet.doSendMoney(id)
        } else {
            const promises = id.map(a => this.__apis.wallet.doSendMoney(a))

            await Promise.all(promises)
        }

        // 金额发放完毕 重新加载列表
        this.getTableData()

        this.$message({
            type: 'success',
            message: '已发放'
        })
    }

    // 一键通过
    async allSend () {
        try {
            await this.$confirm(`<span style="color: #F5222D;">你选择了 ${this.selectData.length} 个选项，是否一键发放所选选项
请再次确认发放金额准确无误，发放后无法修改`, '金额一键发放', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })

            this.doSendMoney(this.selectData)
        } catch (e) {
            console.log(e)
        }
    }

    async send (item: TableItem) {
        try {
            await this.$confirm(`<span style="color: #F5222D;">请再次确认发放金额准确无误，发放后无法修改</span>`, '金额发放', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })

            this.doSendMoney(item.id)
        } catch (e) {
            console.log(e)
        }
    }

    showEditMoneyComponent (item: TableItem) {
        this.editMoneyComponent.show = true
        this.editMoneyComponent.data.department = item.department
        this.editMoneyComponent.data.group = item.group
        this.editMoneyComponent.data.fxs = item.fxs
        this.editMoneyComponent.data.allMoney = item.allMoney
        this.editMoneyComponent.data.removeMoney = item.removeMoney
        this.editMoneyComponent.data.remark = item.remark
        this.editMoneyComponent.data.month = item.month + '-01 00:00:00'
        this.editMoneyComponent.data.id = item.id.toString()
        this.editMoneyComponent.data.userId = item.userId
    }

    showImportComponent () {
        this.importComponent.show = true
    }

    selectionChange (a: TableItem[]) {
        this.selectData = a.map((b) => b.id)
    }
}
