import { ReceivedMessage } from '@/types/received_message'
import { Module } from 'vuex'

export interface ReceivedMessageState {
    LastMessage: ReceivedMessage<any>,
    LastMessageId: number,
}

export const initReceivedMessageState: ReceivedMessageState = {
    LastMessage: {} as ReceivedMessage<any>,
    LastMessageId: 0
}

export interface StoreReceivedMessageState {
    receivedMessage: ReceivedMessageState
}

export enum MessageActions {
    RECEIVE_MESSAGE = 'RECEIVE_MESSAGE',
}

export default <Module<StoreReceivedMessageState, any>>{
    namespaced: true,
    state: () => ({
        receivedMessage: initReceivedMessageState
    }),
    mutations: {
        [MessageActions.RECEIVE_MESSAGE] (state: StoreReceivedMessageState, payload: ReceivedMessageState) {
            state.receivedMessage = {
                ...state.receivedMessage,
                LastMessage: payload.LastMessage,
                LastMessageId: state.receivedMessage.LastMessageId + 1
            }
        }
    }
}
