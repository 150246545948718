import { Component, Vue, Watch } from 'vue-property-decorator'
import guideRecordAudit from './guideRecordAudit/index.vue'
import saleRecordAudit from './saleRecordAudit/index.vue'
import saleDialog from './saleDialog/index.vue'
import dataRange from '@/util/date.ts'
import { __store } from '@/store'

@Component({
    components: {
        guideRecordAudit,
        saleRecordAudit,
        saleDialog
    }
})
export default class SalemanSaleGuideRecordResultPage extends Vue {
    tabs = {
        current: 0,
        data: ['带看审核', '成交审核']
    }
    form: any = {
        ctime: '',
        username: '',
        phone: '',
        house: '',
        dep_id: __store.state.user.infoPos.dep,
        group_id: __store.state.user.infoPos.group,
        user_id: __store.state.user.infoPos.fxs
    }
    dateFilter = ['不限', '昨天', '本周', '上周']
    dateFilterCur = 0

    dateFilter2 = ['不限', '明天', '本周', '下周']
    dateFilterCur2 = 0
    refresh = false

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    @Watch('form.dep_id')
    depChange () {
        this.form.group_id = ''
        this.form.user_id = ''
    }

    @Watch('form.group_id')
    groupChange () {
        this.form.user_id = ''
    }

    mounted () {
        this.tabsChange(+this.$route.query.type || 0)
    }

    tabsChange (index:number) {
        this.tabs.current = index
    }

    selectHandle (index: number, key: string) {
        // @ts-ignore
        this[key] = index
    }

    // 日期按钮
    dateFilterHandle (index: number, key: string) {
        // @ts-ignore
        this[key] = index
        let dateTime
        if (key === 'dateFilterCur') {
            if (index === 1) {
                dateTime = {
                    time_start: dataRange.getCurrentYesterday().format('yyyy-MM-dd'),
                    time_end: dataRange.getCurrentYesterday().format('yyyy-MM-dd')
                }
            } else if (index === 2) {
                dateTime = {
                    time_start: dataRange.getCurrentWeek()[0].format('yyyy-MM-dd'),
                    time_end: dataRange.getCurrentWeek()[1].format('yyyy-MM-dd')
                }
            } else if (index === 3) {
                dateTime = {
                    time_start: dataRange.getPreviousWeek()[0].format('yyyy-MM-dd'),
                    time_end: dataRange.getPreviousWeek()[1].format('yyyy-MM-dd')
                }
            } else {
                dateTime = {
                    time_start: '',
                    time_end: ''
                }
            }
        } else {
            if (index === 1) {
                dateTime = {
                    time_start: dataRange.getCurrentAfterday().format('yyyy-MM-dd'),
                    time_end: dataRange.getCurrentAfterday().format('yyyy-MM-dd')
                }
            } else if (index === 2) {
                dateTime = {
                    time_start: dataRange.getCurrentWeek()[0].format('yyyy-MM-dd'),
                    time_end: dataRange.getCurrentWeek()[1].format('yyyy-MM-dd')
                }
            } else if (index === 3) {
                dateTime = {
                    time_start: dataRange.getCurrenAftertWeek()[0].format('yyyy-MM-dd'),
                    time_end: dataRange.getCurrenAftertWeek()[1].format('yyyy-MM-dd')
                }
            } else {
                dateTime = {
                    time_start: '',
                    time_end: ''
                }
            }
        }
        this.form.ctime = [dateTime.time_start, dateTime.time_end]
    }

    // 刷新数据
    refreshData () {
        let componentEle:any
        if (this.tabs.current === 0) {
            componentEle = this.$refs.guideRecordAudit
        } else {
            componentEle = this.$refs.saleRecordAudit
        }
        componentEle.getData()
    }

    // 搜索
    clickSearch () {
        this.refreshData()
    }

    // 清空筛选
    resetFrom (formName: string) {
        const form = this.$refs[formName] as any
        this.dateFilterCur = 0
        this.dateFilterCur2 = 0
        form.resetFields()
        this.refreshData()
    }
}
