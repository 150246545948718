import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import UploadImg from '@/components/UploadImg/index.vue'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'

@Component({
    components: {
        UploadImg,
        inputSelectSearch
    }
})
export default class ChengjiaoDialogComponent extends Vue {
    @Prop({ default: false })
    visible!:boolean

    @Prop({ default () { return {} } })
    data!:any

    dialogFormVisible = false
    formLabelWidth = '120px'
    guideAfterform: any = {
        user_name: '',
        customer_id: '',
        customer_name: '',
        customer_tel: '',
        action_time: '',
        region: '',
        house_name: '',
        house_id: '',
        channel_name: '',
        channel_tel: '',
        zygw_name: '',
        zygw_tel: '',
        imgs: [],
        customer_info: '',
        zongjie: '',
        ext_data: [
            {
                house_name: '',
                channel_name: '',
                channel_username: '',
                channel_tel: '',
                zygw_name: '',
                zygw_tel: ''
            }
        ]
    }
    rules = {
        action_time: [
            { type: 'string', required: true, message: '请选择日期', trigger: 'change' }
        ],
        customer_name: [
            { type: 'string', required: true, message: '请输入客户姓名', trigger: 'change' }
        ],
        customer_info: [
            { type: 'string', required: true, message: '请输入客户情况', trigger: 'change' }
        ],
        result: [
            { type: 'string', required: true, message: '请输入经验分享', trigger: 'change' }
        ],
        imgs: [
            { type: 'array', required: true, message: '请上传图片', trigger: 'change' }
        ],
        ext_data: [
            { type: 'array', required: true, message: '请添加成交项目', trigger: 'change' }
        ]
    }
    pickerOptions:any = {
        disabledDate (time:any) {
            let _now = Date.now()
            // 大于当前的禁止
            return time.getTime() > _now
        }
    }

    @Watch('data')
    dataChange (obj:any) {
        let houseList = obj.ExtData || []
        this.guideAfterform = {
            id: obj.Id,
            customer_id: obj.CustomerId,
            customer_name: obj.CustomerName,
            house_name: obj.HouseName,
            action_time: obj.ActionTime,
            customer_info: obj.CustomerInfo,
            channel_name: obj.ChannelName,
            channel_username: obj.ChannelUsername,
            channel_tel: obj.ChannelTel,
            zygw_name: obj.ZygwName,
            zygw_tel: obj.ZygwTel,
            result: obj.Result,
            ext_data: houseList || [],
            user_name: obj.UserName || this.__store.state.user.userInfo.username,
            imgs: obj.Imgs
        }
    }

    // 添加项目
    add_project () {
        this.guideAfterform.ext_data.push({
            house_name: '',
            channel_name: '',
            channel_username: '',
            channel_tel: '',
            zygw_name: '',
            zygw_tel: ''
        })
    }

    // 删除项目
    projectDel (index: number) {
        this.guideAfterform.ext_data.splice(index, 1)
    }

    // inputselect选择结果
    inputSelectRes (obj:any, key:string, form:any) {
        form[key] = obj.id
    }

    uploadImgRes (res:any, key:string, form:any) {
        form[key] = res
    }

    closeDialog () {
        this.$emit('update:visible', false)
    }

    saveCheck () {
        const form = this.$refs['dialog_form'] as any
        form.validate(async (valid: any) => {
            if (valid) {
                let isHouse = false
                this.guideAfterform.ext_data.map((v: any) => {
                    if (v.house_name && v.house_id) {
                        isHouse = true
                    }
                })
                if (!isHouse) {
                    return this.$notify({
                        title: '警告',
                        message: '请填写并选择项目数据！',
                        type: 'warning'
                    })
                }
                await this.saveForm()
            } else {
                console.log('error submit!!')
                return false
            }
        })
    }

    // 编辑保存
    async saveForm () {
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)'
        })

        try {
            let data: any
            data = this.__utils.index.copyObj(this.guideAfterform)
            data.ext_data = JSON.stringify(data.ext_data)
            data.imgs = JSON.stringify(data.imgs)

            await this.__apis.customer.saveCjRes(data)

            this.$notify({
                title: '成功',
                message: '保存成功！',
                type: 'success'
            })

            const form = this.$refs['dialog_form'] as any
            form.resetFields()
            this.guideAfterform = Object.assign(this.guideAfterform, {
                house_name: '',
                action_time: '',
                customer_info: '',
                channel_name: '',
                channel_username: '',
                channel_tel: '',
                zygw_name: '',
                zygw_tel: '',
                result: '',
                ext_data: [
                    {
                        house_name: '',
                        channel_name: '',
                        channel_username: '',
                        channel_tel: '',
                        zygw_name: '',
                        zygw_tel: ''
                    }
                ],
                imgs: []
            })
            this.closeDialog()
            this.$emit('save')
        } catch (e) {
            throw e
        } finally {
            loading.close()
        }
    }
}
