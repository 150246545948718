import { Component, Vue } from 'vue-property-decorator'
import { apiConfig } from '@/api/config'
import { storageName } from '@/localStorage'

@Component
export default class KnowledgeAddEditKuPage extends Vue {
    form: any = {
        name: '',
        desc: '',
        imgs: '',
        user: []
    }
    formLabelWidth = '90px'
    // 图片上传
    dialogImageUrl = apiConfig.apiUrl
    fileList: any = []
    userList: any = []

    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }
    get
    id () {
        return this.$route.query.id as string
    }
    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    mounted () {
        if (this.id) {
            this.getData()
        }
    }

    async getData () {
        const res = await this.__apis.knowledge.getKnowledgeLibDetail(this.id)

        let datas = res.data
        let users = datas.AdminList.map((v: any) => {
            return v.UserId
        })
        let userList = datas.AdminList.map((v: any) => {
            v.UserInfo.value = v.UserId
            v.UserInfo.label = v.UserInfo.UserName
            return v.UserInfo
        })
        this.form = {
            name: datas.Name,
            desc: datas.Description,
            imgs: datas.Img ? datas.Img.split(',') : [],
            user: users,
            dir_sort: datas.DirSort
        }
        this.userList = userList
    }

    // 楼盘匹配
    async querySearchAsync (queryString: string, cb: any) {
        if (!queryString || queryString.length === 0) {
            // eslint-disable-next-line standard/no-callback-literal
            cb([])
            return
        }

        const res = await this.__apis.utils.searchHouse({
            term: queryString
        })
        cb(res.data)
    }

    handlePictureSuccess (response: any, file: any) {
        this.fileList = [file]
        this.form.imgs = [file.response.data.url]
    }

    handlePictureError (err: any) {
        console.log(err)
        this.$notify({
            title: '错误',
            message: '上传失败',
            type: 'error'
        })
    }

    handleRemove () {
        this.fileList = []
        this.form.imgs = []
    }

    async saveForm () {
        this.form.user.push(String(this.userInfo.user_id))
        this.userList.push({
            label: this.userInfo.username,
            value: String(this.userInfo.user_id)
        })

        this.form.user = [...new Set(this.form.user)]

        let img = this.form.imgs ? this.form.imgs.join(',') : ''
        let data: any = {
            name: this.form.name,
            description: this.form.desc,
            img: img,
            admin_list: this.form.user,
            dir_sort: this.form.dir_sort // （词库目录内部的排序关系）
        }
        if (this.id) {
            data.id = this.id
        }

        const res = await this.__apis.knowledge.saveKnowledgeLib(data)

        this.$router.push({
            name: '/knowledge/addCate',
            query: {
                id: res.data.id
            }
        })
    }

    async remoteMethod (query: string) {
        if (query !== '') {
            const res = await this.__apis.utils.searchUser({
                fxs: 0,
                key: query
            })

            res.data.list.map((v: any) => {
                v.value = String(v.Id)
                v.label = v.Username + '(' + v.groupName + ')'
            })
            this.userList = res.data.list
        } else {
            this.userList = []
        }
    }

    async delKu () {
        try {
            await this.$confirm('请先删除知识库下目录和文章，在确认删除知识库！')

            await this.__apis.knowledge.deleteKnowledgeLib(this.id)

            this.$notify({
                title: '成功',
                message: '删除成功！',
                type: 'success'
            })
            this.$router.push({
                name: '/knowledge'
            })
        } catch (e) {
            console.log(e)
        }
    }
}
