import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import FilterComponent from '@/components/filters/filter/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'

interface TableItem {
    id: number,
    type: string,
    remark: string,
    time: string,
    money: string,
    balance: string
}

interface Filter {
    [index: string]: string | string[]
    type: string
    time: string[]
}

@Component({
    components: {
        FilterComponent,
        InputDateRangeComponent,
        CheckBoxComponent
    }
})
export default class IndexPage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 50
    }

    all = {
        id: -1,
        time: '汇总',
        type: '-',
        remark: '-',
        money: '-',
        balance: '-'
    }

    userName = ''

    types = [
        {
            value: 1,
            label: '日常工作'
        }, {
            value: 2,
            label: '工资'
        }, {
            value: 3,
            label: '业绩'
        }, {
            value: 4,
            label: '奖金'
        }, {
            value: 5,
            label: '预约'
        }, {
            value: 6,
            label: '收益'
        }, {
            value: 7,
            label: '提现'
        }, {
            value: 8,
            label: '车补'
        }, {
            value: 9,
            label: '充值'
        }
    ]

    filter: Filter = {
        type: '',
        time: []
    }

    created () {
        this.userName = decodeURIComponent(this.$route.query.name.toString())
        this.getTableData()
    }

    async getTableData () {
        const { data } = await this.__apis.wallet.fxsWalletRecord({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            time_start: this.filter.time[0],
            time_end: this.filter.time[1],
            employee_id: this.$route.query.id.toString(),
            type: this.filter.type
        })

        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.employee_id,
                time: a.ctime,
                type: this.types.filter(t => t.value === a.type)[0].label,
                remark: a.remark,
                money: this.__utils.index.formatMoney(a.money),
                balance: this.__utils.index.formatMoney(a.after_money)
            }
        })

        // 汇总数据存着
        if (this.tableData.page === 1) {
            this.all = {
                id: -1,
                type: '-',
                remark: '-',
                time: '汇总',
                money: this.__utils.index.formatMoney(data.statistic_res.money),
                balance: '-'
            }
        }

        this.tableData.data.unshift(this.all)
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        if (!this.filter.time) {
            this.filter.time = []
        } else {
            this.filter.time = this.__utils.index.formatTimeStartTimeEnd(this.filter.time)
        }

        this.getTableData()
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }
}
