interface apiEnvMap {
    [propName: string]: any
}

const BaseTestUrl = 'http://192.168.3.103:8000'

// php:   http://staging.test.91xiangju.com/api/v1/new_admin
// java:  http://j.test.91xiangju.com/

// api相关生产开发映射
const apiEnvMap:apiEnvMap = {
    phpUrl: {
        'development': `${BaseTestUrl}`,
        'production': 'https://admin.91xiangju.com'
    },
    // 请求地址
    apiUrl: {
        'development': `${BaseTestUrl}/api/v1/new_admin`,
        'production': 'https://admin.91xiangju.com/api/v1/new_admin'
    },
    ppApiUrl: {
        'development': `${BaseTestUrl}/xiangju/service/api`,
        'production': 'https://xiangju-service.malmam.com/service/api'
    },
    uploadAction: {
        'development': `${BaseTestUrl}/xiangju/service/upload`,
        'production': 'https://xiangju-service.malmam.com/service/upload'
    },
    jUrl: {
        'development': `${BaseTestUrl}`,
        'production': 'https://xiangju-service.malmam.com/jsrv'
    },
    MESSAGE_SERVER: {
        'development': `wss://push.wss.malmam.com/test/xiangju/service`,
        'production': 'wss://push.wss.malmam.com/xiangju/service'
    }
}

const env = process.env.NODE_ENV as any

// 最终api导出配置
export const apiConfig = {
    phpUrl: apiEnvMap.phpUrl[env],
    apiUrl: apiEnvMap.apiUrl[env],
    ppApiUrl: apiEnvMap.ppApiUrl[env],
    uploadAction: apiEnvMap.uploadAction[env],
    jUrl: apiEnvMap.jUrl[env],
    MESSAGE_SERVER: apiEnvMap.MESSAGE_SERVER[env]
}
