import { Component, Prop, Vue } from 'vue-property-decorator'
import areaOptions from '@/util/places'
import { DepInfoVar } from '@/util/const'
import { AreaSearchResList } from '@/api/customer'

type LabelValueChildren<T = number> = {
    label: string
    value: T
    children?: LabelValueChildren[]
}

enum DispatchCustomerStatusEnum {
    '开启'= '1',
    '关闭'= '3'
}

@Component({
    components: {}
})
export default class AreaSetComponent extends Vue {
    @Prop({ default: false })
    visible!: boolean

    areaSet: any = {
        visible: false,
        loading: false,
        data: {},
        dep: '',
        oldGroupArr: [],
        dzHasAreaList: [],
        groupArr: [],
        groupCurrent: 0,
        szDataCur: 0,
        szData: ['区域设置', '区域查看']
    }

    get
    showAreaSee () {
        return this.__role.has([this.__role.ROLE.RENSHI], false)
    }

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    get
    depArr () {
        return this.__utils.index.getXsDep()
    }

    get
    areaOptions () {
        return this.houseAreaData
        // let area = areaOptions
        // let dep = this.infoPos.dep
        // if (dep) {
        //     return dep === 2 ? area.filter((v) => ['浙江省'].includes(v.label))
        //         : dep === 4 ? area.filter((v) => ['广东省'].includes(v.label))
        //             : dep === DepInfoVar.KUNMING ? area.filter((v) => ['云南省'].includes(v.label))
        //                 : area
        // } else {
        //     return area
        // }
    }

    // 根据部门id返回战队列表
    groupArr (dep: number) {
        return this.__utils.index.getGroupsByDepId(dep, { hide: true }).map((a) => {
            return {
                label: a.name,
                value: [],
                id: a.value
            }
        })
    }

    // 部门切换
    depChange (id: number) {
        this.areaSet.dep = id
        this.areaSet.groupArr = this.groupArr(id)
        this.areaSet.groupCurrent = 0
        this.areaSet.groupArr.map((a:any, i:number) => {
            if (a.id === this.infoPos.group) {
                this.areaSet.groupCurrent = i
            }
        })
        this.tabsGroupClick(this.areaSet.groupCurrent || 0)
    }

    // 战队切换
    tabsGroupClick (index: number) {
        this.areaSet.groupCurrent = index
        this.getFxsAreaByGroupId()
    }

    areaSetVisible () {
        if (this.__role.has([this.__role.ROLE.SHENGZHANG], false)) {
            // 获取队伍的区域 （省长）
            this.getGroupOrderArea()
        } else if (this.__role.has([this.__role.ROLE.DUIZHANG], false)) {
            // 获取省长设置的队伍的区域 （队长）
            this.getDzHasArea(this.infoPos.group)
        }

        this.depChange(this.infoPos.dep || this.infoPos.dep_arr[0].k)
        this.areaSet.visible = true
    }

    cancelAreaSet () {
        this.areaSet.visible = false
        this.$emit('update:visible', false)
    }

    cascaderChange (res:any, item:any) {
        // console.log(222, res)
        let arr:any = []
        res.filter((v:any) => {
            this.getValueObj(v, this.areaOptions, arr)
        })
        item.place_list = arr
        // console.log(33, item.place_list)
    }

    getValueObj (v:string, arr:any, item:any) {
        if (!arr) return
        arr.map((b:any) => {
            if (b.value === v) {
                return item.push({
                    id: b.value,
                    name: b.label
                })
            } else {
                if (b.children && b.children.length) {
                    return this.getValueObj(v, b.children, item)
                }
            }
        })
        return item
    }

    async saveFxsArea (close: boolean = true) {
        const placeInfo = JSON.parse(JSON.stringify(this.areaSet.data.PlaceInfo))

        placeInfo.forEach((v:any) => {
            v.next_open_time = v.next_open_time ? Math.floor(
                new Date(String(v.next_open_time).replace(/-/g, '/')).getTime() / 1000
            ) : ''
            delete v.show_date
            delete v.value
        })

        await this.__apis.customer.saveFxsArea({
            id: this.areaSet.data.Id,
            place_info: JSON.stringify(placeInfo)
        })
        if (close) {
            this.cancelAreaSet()
        }
        this.$notify({
            title: '成功',
            message: '提交成功！',
            type: 'success'
        })
    }

    async getFxsAreaByGroupId () {
        this.areaSet.loading = true
        if (!this.areaSet.groupArr[this.areaSet.groupCurrent]) {
            console.log('战队不存在！！！')
            return
        }
        let { data } = await this.__apis.customer.getFxsAreaByGroupId({
            group_id: this.areaSet.groupArr[this.areaSet.groupCurrent].id
        })
        data.PlaceInfo = data.PlaceInfo ? JSON.parse(data.PlaceInfo) : []
        // console.log('getFxsAreaByGroupId', data)
        data.PlaceInfo.forEach((v:any) => {
            let obj:any = {}
            v.place_list.map((a:any) => {
                obj[a.id] = a
            })
            v.value = Object.keys(obj)
            v.place_list = Object.keys(obj).map((d:any) => {
                return obj[d]
            })
            v.dispatch_status = v.dispatch_status ? String(v.dispatch_status) : DispatchCustomerStatusEnum.开启
            v.open_dispatch_time = v.open_dispatch_time || Math.floor(new Date().getTime() / 1000)
            v.next_open_time = v.next_open_time && v.dispatch_status === DispatchCustomerStatusEnum.关闭 ? this.__utils.dayjs(v.next_open_time * 1000).format('YYYY-MM-DD HH:mm:ss') : '' // 时间戳 秒
        })
        this.areaSet.data = data
        this.areaSet.loading = false
    }

    // 获取队伍的区域 （队长）
    async getDzHasArea (id:number) {
        if (!id) return
        let { data } = await this.__apis.customer.getGroupOrderArea({
            group_id: id
        })
        // console.log('getDzHasArea', data)
        this.areaSet.dzHasAreaList = data[0] ? data[0].group_order_area.map((v) => {
            return {
                label: v.name,
                value: v.id
            }
        }) : []
    }

    // 省长设置查看切换
    szDataChange (index:number) {
        this.areaSet.szDataCur = index
        if (index === 0) {
            this.getGroupOrderArea()
        } else {
            this.depChange(this.infoPos.dep || this.userInfo.dep)
        }
    }

    // 省长设置队伍区域
    cascaderGroupChange (res:any, item:any) {
        // console.log('cascaderGroupChange', res, item)
        let arr:any = []
        res.filter((v:any) => {
            this.getValueObj(v, this.areaOptions, arr)
        })
        item.place_list = arr
        // console.log('place_list', item.place_list)
    }

    // 省长保存设置
    async saveSzSetArea () {
        const data: {
            del_area: any[]
            order_area: any[]
        } = {
            del_area: [],
            order_area: []
        }

        data.order_area = this.areaSet.groupArr.map((v:any, i:number) => {
            let oldPlaceList = this.areaSet.oldGroupArr[i].value && this.areaSet.oldGroupArr[i].value.map((a: any) => Number(a))
            let vId = v.value.map((a: any) => Number(a))
            let id = v.id

            data.del_area.push({
                group_id: id,
                data: oldPlaceList ? oldPlaceList.filter((a:any) => !vId.includes(a)) : []
            })

            return {
                group_id: id,
                data: v.place_list || []
            }
        })

        data.del_area = data.del_area.filter((v:any) => v.data.length)

        await this.__apis.customer.setGroupOrderArea(data)

        this.$notify({
            title: '成功',
            message: '保存成功！',
            type: 'success'
        })
        this.getGroupOrderArea()
    }

    // 获取省长设置区域
    async getGroupOrderArea () {
        if (!this.infoPos.dep) return
        let { data } = await this.__apis.customer.getGroupOrderArea({})
        // console.log('getGroupOrderArea', data)
        this.areaSet.groupArr.forEach((vv:any) => {
            vv.value = []
            vv.place_list = []
            data.forEach((v:any) => {
                if (v.group_id === vv.id) {
                    vv.value = v.group_order_area.map((a:any) => a.id)
                    vv.place_list = v.group_order_area
                }
            })
        })
        this.areaSet.oldGroupArr = this.__utils.index.copyObj(this.areaSet.groupArr)
    }

    // 发客修改
    async dispatchSwitchChange (e: string, item: any) {
        if (e === DispatchCustomerStatusEnum.开启) {
            item.open_dispatch_time = Math.floor(new Date().getTime() / 1000)
        }
        await this.saveFxsArea(false)
        await this.getFxsAreaByGroupId()
    }

    // 关闭发客
    async switchCloseChange (item: any) {
        try {
            if (item.dispatch_status === DispatchCustomerStatusEnum.开启) {
                this.closeNextOpenTime(item)
                item.dispatch_status = DispatchCustomerStatusEnum.关闭
                await this.dispatchSwitchChange(DispatchCustomerStatusEnum.关闭, item)
            }
        } catch (e) {
            console.log(e)
            item.dispatch_status = DispatchCustomerStatusEnum.开启
        }
    }

    // 关闭时间选择提示
    closeNextOpenTime (item: any) {
        this.$set(item, 'show_date', false)
    }

    async getAreaData () {
        const dep = this.infoPos.dep
        const depArr = dep ? this.depArr.filter(a => a.value === dep) : this.depArr
        const p = [] as AreaSearchResList[]
        depArr.forEach(a => {
            p.push(
                this.__apis.customer.getAreaSearch({
                    dep_id: a.value
                })
            )
        })
        const data = await Promise.all(p)
        const rows = data.map(a => a.data.rows.map(a => {
            return {
                label: a.name,
                value: a.id
            }
        }))
        const res = depArr.map((a, i) => {
            return {
                label: a.label,
                value: a.value + 99999999,
                children: rows[i]
            }
        })

        // const res = rows.reduce((s, a) => {
        //     return [...s, ...a]
        // }, [])

        // console.log(rows, res)
        this.houseAreaData = res
    }

    houseAreaData: LabelValueChildren[] = []

    async created () {
        await this.getAreaData()
        this.areaSetVisible()
        if (this.showAreaSee) {
            this.szDataChange(1)
        }
    }
}
