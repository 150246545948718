import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class NoData extends Vue {
    @Prop({
        type: String,
        default () {
            return ''
        }
    })
    text !: string

    tips_text = '暂无数据~'

    mounted () {
        if (this.text) this.tips_text = this.text
    }
}
