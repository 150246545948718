import { Component, Vue } from 'vue-property-decorator'
import tabsComponent from '@/components/wallet/tabs/index.vue'

@Component({
    components: {
        tabsComponent
    }
})
export default class IndexPage extends Vue {
    tabsComponent = {
        active: 0,
        items: [
            {
                text: '考核得分',
                url: ['/channelManage/performanceAppraisal', '/channelManage/performanceAppraisal/score/detail']
            }, {
                text: '楼盘绑定',
                url: ['/channelManage/performanceAppraisal/houseBind']
            }
        ]
    }
}
