import { Component, Vue, Watch } from 'vue-property-decorator'
import { problemInfo } from '../type'
import { QuestionType, QuestionTypeEnum } from '@/util/const'
import { UserCommitTypes } from '@/store/user'

@Component
export default class SeeAnswerComponent extends Vue {
    problemInfo: problemInfo = {
        current: 0,
        data: [],
        date: '',
        id: 0
    }

    get
    problemCurrentItem () {
        return this.problemInfo.data.length ? this.problemInfo.data[this.problemInfo.current] : {
            id: 0,
            qType: 1,
            type: '',
            title: '',
            options: [],
            answer: [],
            userAnswer: [],
            yes: false,
            showRes: false
        }
    }

    get
    isEnd () {
        const problemInfo = this.problemInfo
        return problemInfo.current >= problemInfo.data.length - 1
    }

    get
    seeQuestionInfo () {
        return this.__store.state.user.seeQuestionInfo
    }

    get
    userAnswerText () {
        return this.problemCurrentItem.userAnswer.join(',')
    }

    get
    answerText () {
        return this.problemCurrentItem.answer.join(',')
    }

    @Watch('seeQuestionInfo')
    seeQuestionInfoChange () {
        if (!this.seeQuestionInfo.show) return
        const item = this.seeQuestionInfo.item
        const problemInfo = this.problemInfo
        if (problemInfo.id && problemInfo.data.length) {
            if (problemInfo.id === item.taskId) {
                problemInfo.data.some((a, i) => {
                    if (a.answerId === item.id) {
                        problemInfo.current = i
                        return true
                    }
                })
            }
        }
        this.getData()
    }

    async getData () {
        const seeQuestionInfo = this.seeQuestionInfo
        if (!seeQuestionInfo.show) return
        const item = seeQuestionInfo.item
        const { data: { questionAnswerInfoList, taskAnswerPo } } = await this.__apis.activity.questionTaskAnswer(item.taskId)
        const problemInfo: problemInfo = {
            current: 0,
            data: [],
            date: this.__utils.index.formatDate(new Date(taskAnswerPo.create_time), 'YYYY-MM-DD'),
            id: taskAnswerPo.id
        }
        problemInfo.data = questionAnswerInfoList.map((a, index) => {
            const isManySel = [QuestionTypeEnum.manySelect].includes(a.question_type)
            const isPd = [QuestionTypeEnum.judgeSelect].includes(a.question_type)
            const content = a.items.map((v, i) => {
                const label = !isPd ? `${v.prefix}. ${v.content}` : v.content
                return {
                    label: label,
                    value: v.prefix
                }
            })
            const isAnswer = a.do_right === null // 未回答
            const correct = isManySel ? a.correct.split(',') : [a.correct] // 答案
            if (item.id && item.id === a.answer_id) {
                problemInfo.current = index
            }
            return {
                id: a.question_id,
                type: this.__utils.index.getLabelByValue(QuestionType, a.question_type),
                qType: a.question_type,
                title: a.title,
                options: content,
                answer: correct,
                answerId: a.answer_id || 0,
                userAnswer: isAnswer ? [] : a.answer.split(','),
                yes: isAnswer ? false : (a.answer === correct.join(',')),
                showRes: !isAnswer
            }
        })
        this.problemInfo = problemInfo
    }

    prev () {
        if (this.problemInfo.current > 0) {
            this.problemInfo.current -= 1
        }
    }

    next () {
        if (!this.isEnd) {
            this.problemInfo.current += 1
        }
    }

    close () {
        this.__store.commit(UserCommitTypes.setSeeQuestionInfo, {
            show: false,
            item: {
                taskId: 0,
                id: 0
            }
        })
    }

    isAnswerRightByValue (value: string) {
        return this.problemCurrentItem.answer.includes(value)
    }

    isUserAnswerRightByValue (value: string) {
        return !this.problemCurrentItem.yes && this.problemCurrentItem.userAnswer.includes(value)
    }
}
