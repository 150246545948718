import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import FilterComponent from '@/components/filters/filter/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import FiltersInputSelect from '@/components/filters/inputSelect/index.vue'
import { __utils } from '@/util'

function getCurrentTime () {
    return [`${__utils.index.formatDate(new Date(), 'YYYY-MM')}-01 00:00:00`, `${__utils.index.formatDate(new Date(), 'YYYY-MM-DD hh:mm:ss')}`]
}

interface TableItem {
    department: string
    group: string
    fxs: string
    time: string
    income: string
    expend: string
    surplus: string
}

interface Filter {
    department: number
    group: number
    fxs: number
    time: string[]
}

@Component({
    components: {
        FilterComponent,
        InputDateRangeComponent,
        CheckBoxComponent,
        FilterBusinessSelect,
        SelectComponent,
        FiltersInputSelect
    }
})
export default class IndexPage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 50
    }

    all!: TableItem

    filter: Filter = {
        department: null as any,
        group: null as any,
        fxs: null as any,
        time: getCurrentTime()
    }

    created () {
        this.getTableData()
    }

    searchDep () {}

    searchFxs (text: string, cb: any) {
        this.__utils.index.searchEmployee(text, cb)
    }

    async getTableData () {
        const { data } = await this.__apis.wallet.getSurplusMoneyList({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            start_time: this.filter.time[0],
            end_time: this.filter.time[1],
            employee_id: this.filter.fxs,
            group_id: this.filter.group,
            dep_id: this.filter.department
        })

        this.tableData.total = Math.min(data.total, 10000)
        this.tableData.data = data.list.map(a => {
            return {
                time: this.filter.time.map(a => a.split(' ')[0]).join(' - '),
                income: this.__utils.index.formatMoney(a.income_money),
                expend: this.__utils.index.formatMoney(a.expenditure_money),
                department: a.user_info.department_name,
                group: a.user_info.group_name,
                fxs: a.user_info.user_name,
                surplus: this.__utils.index.formatMoney(a.income_money + a.expenditure_money)
            }
        })

        // 汇总数据存着
        if (this.tableData.page === 1) {
            this.all = {
                time: '-',
                income: this.__utils.index.formatMoney(data.statistic_res.income_money_total),
                expend: this.__utils.index.formatMoney(data.statistic_res.expenditure_money_total),
                department: `汇总${data.total}条`,
                group: '-',
                fxs: '-',
                surplus: this.__utils.index.formatMoney(data.statistic_res.income_money_total + data.statistic_res.expenditure_money_total)
            }
        }

        this.tableData.data.unshift(this.all)
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        if (!this.filter.time) {
            this.filter.time = getCurrentTime()
        } else {
            this.filter.time = this.__utils.index.formatTimeStartTimeEnd(this.filter.time)
        }

        this.getTableData()
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }
}
