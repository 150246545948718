var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index-page"},[_c('header',{staticClass:"header"},[_c('div',{staticClass:"header-container"},[_c('div',{staticClass:"header-left"},[_vm._m(0),_c('div',{staticClass:"menu"},_vm._l((_vm.menus),function(item){return _c('div',{key:item.id,staticClass:"item-box"},[(!item.jump && item.route)?_c('router-link',{staticClass:"item",attrs:{"to":item.route}},[_vm._v(" "+_vm._s(item.text)+" ")]):(item.sub)?_c('div',{staticClass:"item header_sub_btn",class:{active: item.select !== -1}},[_vm._v(" "+_vm._s(item.select === -1 ? '全部' : item.routes[item.select].text)+" "),_c('i',{staticClass:"el-icon-menu icon"}),_c('div',{staticClass:"header_sub"},[_c('div',{staticClass:"box"},_vm._l((_vm.overMenus),function(item,index){return _c('div',{key:item.id,staticClass:"item",class:{active: _vm.$route.path.includes(item.route)},attrs:{"to":item.route},on:{"click":function($event){return _vm.changeItemSelect(item, index)}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)])]):(item.routes)?_c('router-link',{staticClass:"item drop-down",attrs:{"to":item.routes[item.select].route}},[_vm._v(" "+_vm._s(item.routes[item.select].text)+" "),(item.routes)?_c('img',{staticClass:"icon-down",attrs:{"src":require("@/assets/images/pages/index/down.png"),"alt":""}}):_vm._e(),_c('div',{staticClass:"hover-down"},[_c('div',{staticClass:"down-list"},_vm._l((item.routes),function(downItem,downIndex){return (item.select !== downIndex)?_c('div',{key:downItem.id,staticClass:"down-item",attrs:{"to":downItem.route},on:{"click":function($event){return _vm.changeItemSelect(item, downIndex)}}},[_vm._v(" "+_vm._s(downItem.text)+" ")]):_vm._e()}),0)])]):_c('div',{staticClass:"item",on:{"click":function($event){return _vm.toJump(item)}}},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),0)]),_c('div',{class:{
                    'header-right': true,
                    new: _vm.newTz
                }},[_c('div',{staticClass:"news"},[_c('img',{attrs:{"src":_vm.newsSrc,"alt":"news"},on:{"click":_vm.toggleNews}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.newCount > 0),expression:"newCount > 0"}],staticClass:"new_tip"},[_vm._v(" "+_vm._s(_vm.realNewCount)+" ")])]),_c('img',{staticClass:"avatar",attrs:{"src":_vm.avatarSrc,"alt":"avatar"},on:{"click":_vm.gotoUserCenter}}),_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"user-name"},[_vm._v(" "+_vm._s(_vm.userInfo.username)+" ")]),_c('div',{staticClass:"user-job"},[_vm._v(" "+_vm._s(_vm.userInfo.role_name)+" ")])]),_c('div',{class:{
                        'btn-logout': true,
                        active: _vm.logout.show
                    },on:{"click":function($event){_vm.logout.show = !_vm.logout.show}}})]),_c('transition',{attrs:{"name":"logout"}},[(_vm.logout.show)?_c('div',{staticClass:"account-list-box"},[_c('div',{staticClass:"account-list"},[_vm._l((_vm.logout.account),function(item){return _c('div',{key:item.id,staticClass:"item",on:{"click":function($event){return _vm.changeAccount(item)}}},[_c('div',{class:{
                                    status: true,
                                    in: item.id === _vm.userInfo.user_id
                                }}),_c('img',{staticClass:"avatar",attrs:{"src":item.avatar,"alt":""}}),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(item.username)+" ")]),(item.group_name)?_c('div',{staticClass:"team"},[_vm._v(" "+_vm._s(item.group_name)+" ")]):_vm._e(),_c('div',{staticClass:"role"},[_vm._v(" "+_vm._s(item.role_name)+" ")])])}),_c('div',{staticClass:"btn-exit",on:{"click":_vm.exit}},[_vm._v(" 退出登录 "),_c('div',{staticClass:"icon"})])],2)]):_vm._e()])],1)]),_c('warningMsgComponent',{directives:[{name:"auth",rawName:"v-auth",value:(_vm.__role.permissions.WarningMsgComponent),expression:"__role.permissions.WarningMsgComponent"}]}),(_vm.showNewsPopup)?_c('NewsComponent',{on:{"updata-news-count":_vm.updataNewsCount,"popup-close":_vm.toggleNews}}):_vm._e(),(!_vm.isNotFoundPage)?_c('NoticeComponent',{directives:[{name:"auth",rawName:"v-auth",value:(_vm.__role.permissions.ShowNotice),expression:"__role.permissions.ShowNotice"}]}):_vm._e(),_c('div',{staticClass:"main"},[_c('router-view')],1),_c('questionEditComponent'),_c('seeQuestionComponent'),_c('RankComponent'),(!_vm.isNotFoundPage)?_c('ActivityTaskComponent',{directives:[{name:"auth",rawName:"v-auth",value:(_vm.__role.permissions.GetTodayTaskOverview),expression:"__role.permissions.GetTodayTaskOverview"}]}):_vm._e(),(!_vm.isNotFoundPage)?_c('SummaryPopup',{directives:[{name:"auth",rawName:"v-auth",value:(_vm.__role.permissions.GetWeeklyData),expression:"__role.permissions.GetWeeklyData"}]}):_vm._e(),_c('RecommendPopup',{directives:[{name:"auth",rawName:"v-auth",value:(_vm.__role.permissions.RobCustomerAction),expression:"__role.permissions.RobCustomerAction"}]}),_c('div',{staticClass:"footer"},[_vm._v(" ©"+_vm._s(new Date().getFullYear())+" 杭州奇思妙行网络科技有限公司 版权所有 ")]),_c('el-backtop',{attrs:{"target":"#app"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('img',{staticClass:"image",attrs:{"src":require("@/assets/logo.png"),"alt":"logo"}}),_vm._v(" 新房分析师管理系统 ")])}]

export { render, staticRenderFns }