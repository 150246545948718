import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { EventBus, EventNames, filterId } from '@/util/bus'

@Component
export default class FiltersSelect extends Vue {
    options: any[] = []
    label = ''
    value = ''
    visible = false
    isEnter = false
    id = 0

    @Prop({
        type: String,
        default: ''
    })
    title !: string

    @Prop({
        type: String,
        default: ''
    })
    propName !: string

    @Prop({
        type: String,
        default: ''
    })
    componentId !: string

    @Prop({
        type: String,
        default: '请输入'
    })
    placeholder !: string

    @Prop({
        type: Boolean,
        default: false
    })
    startSearch !: boolean

    @Watch('visible')
    watchVisible (n: boolean) {
        if (n && !this.isEnter) this.value = ''

        if (n && this.startSearch) {
            this.remote('')
        }
    }

    @Watch('value')
    valueChange () {
        if (!this.value) return
        this.label = this.options.filter((a: any) => a.value === this.value)[0].label
    }

    created () {
        this.id = filterId.value
        filterId.value++
        EventBus.$on(EventNames.clearFilter, this.clear)
    }

    destroyed () {
        EventBus.$off(EventNames.clearFilter, this.clear)
    }

    // 这条筛选被清空
    clear (id: number) {
        if (this.id === id) {
            this.isEnter = false
        }
    }

    remote (text: string) {
        this.$emit('search', text, (data: any) => {
            this.options = data
        })
    }

    cancel () {
        this.visible = false
    }

    enter () {
        const item = this.options.filter((a: any) => a.value === this.value)[0]
        const label = item ? item.label : this.label

        // 这里需要通知筛选词条组件进行更新
        EventBus.$emit(EventNames.filterChange + this.componentId, {
            title: this.title + '：',
            content: label,
            id: this.id,
            value: {
                [this.propName]: this.value
            }
        })

        this.isEnter = true
        this.cancel()
    }
}
