import { Component, Vue } from 'vue-property-decorator'
import { LabelValueArray, Table } from '@/types'
import { ExitStatus } from '@/util'
import { LevelStatus } from '@/util/const'
import RobotCallDetailComponent from '@/views/phoneRecord/robot/call-detail/index.vue'
import RobotCallEditComponent from '@/views/phoneRecord/robot/edit/index.vue'
import { routerBaseUrl } from '@/router'
import { RobotCallRecordItem } from '@/api/phoneRecord'
import { CallDetailItem } from './call-detail'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'
import { CallEditItem } from '@/views/phoneRecord/robot/edit'

const ResultStatus = [
    {
        label: '已接听',
        value: 'ANSWERED'
    },
    {
        label: '未接',
        value: 'NO_ANSWER'
    },
    {
        label: '占线',
        value: 'BUSY'
    },
    {
        label: '关机',
        value: 'POWER_OFF'
    },
    {
        label: '被叫停机',
        value: 'OUT_OF_SERVICE'
    },
    {
        label: '拒接',
        value: 'REFUSED'
    },
    {
        label: '空号',
        value: 'VACANT_NUMBER'
    },
    {
        label: '无法接通',
        value: 'CAN_NOT_CONNECT'
    },
    {
        label: '主叫号码不可用',
        value: 'FROM_PHONE_ERROR'
    },
    {
        label: '外呼失败',
        value: 'SYSTEM_ERROR'
    }
]

type TableItem = {
    id: number
    time: string
    callId: number
    taskId: number
    customerId: number
    customerInfo: {
        name: string
        phone: string
        phoneSecond: string
        houseId: number,
        houseName: string
        operateLog: string,
        sysTags: number[],
        registerTime: string
    }
    level: string
    callResult: string
    callDuration: number
    chats: RobotCallRecordItem['call_detail_list']
    applyTime: string
    fxs: string
    url: string
    lock: boolean
    distributionUserName: string
}

@Component({
    components: {
        RobotCallDetailComponent,
        inputSelectSearch,
        RobotCallEditComponent
    }
})
export default class FxsPhoneRecordPage extends Vue {
    robotCallDetailComponent: {
        show: boolean
        item: CallDetailItem
    } = {
        show: false,
        item: {} as CallDetailItem
    }

    robotCallEditComponent: {
        show: boolean
        item: CallEditItem
    } = {
        show: false,
        item: {} as CallEditItem
    }

    filter: {
        callDate: string[],
        customer: string,
        assign: string | number,
        level: string,
        result: string,
        userName: string,
        userId: number,
        depId: string | number
        chat_duration: string | number
    } = {
        callDate: [],
        customer: '',
        assign: '',
        level: '',
        result: '',
        userName: '',
        userId: 0,
        depId: '',
        chat_duration: ''
    }

    ExitStatus = ExitStatus

    LevelStatus = LevelStatus

    ResultStatus = ResultStatus

    table: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 20,
        total: 0
    }

    get
    xsDep () {
        return this.__utils.index.getXsDep()
    }

    search () {
        this.table.page = 1
        this.getData()
    }

    tableSizeChange (a: number) {
        this.table.pageSize = a
        this.getData()
    }

    tableCurrentChange (a: number) {
        this.table.page = a
        this.getData()
    }

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    created () {
        this.filter.depId = this.userInfo.dep || ''
        this.filter.assign = this.userInfo.dep ? 0 : ''
        this.getData()
    }

    showCallDetail (item: TableItem) {
        this.robotCallDetailComponent = {
            show: true,
            item: {
                ...item.customerInfo,
                callId: item.callId,
                callDuration: item.callDuration,
                chats: item.chats,
                level: item.level,
                url: item.url
            }
        }
    }

    clear () {
        this.filter = {
            callDate: [],
            customer: '',
            assign: '',
            level: '',
            result: '',
            userName: '',
            userId: 0,
            depId: '',
            chat_duration: ''
        }
        this.getData()
    }

    // 跳转客户详情
    gotoDetail (id: number) {
        window.open(`${routerBaseUrl}/customer/detail/${id}/`, '_blank')
    }

    async dispatch (item: TableItem, dispatch = false) {
        await this.__apis.phoneRecord.thawCustomerRobotCallLock({
            id: item.id,
            dispatch
        })

        this.$message.success('操作成功')

        this.getData()
    }

    async getData () {
        const { data } = await this.__apis.phoneRecord.getRobotCallRecord({
            pagesize: this.table.pageSize,
            page: this.table.page,
            result_status: this.filter.result,
            mobile: this.filter.customer,
            intent_level: this.filter.level,
            start_time: this.filter.callDate && this.filter.callDate[0],
            end_time: this.filter.callDate && this.filter.callDate[1],
            is_distribution: {
                0: false,
                1: true
            }[this.filter.assign as never],
            dep_id: +this.filter.depId || undefined,
            distribution_user_id: this.filter.userId || undefined,
            chat_duration: +this.filter.chat_duration || undefined
        })

        this.table.total = data.total

        this.table.data = data.list.map(a => {
            return {
                id: a.id,
                url: a.full_audio_url,
                chats: a.call_detail_list,
                time: a.start_time,
                callId: a.call_record_id,
                taskId: a.robot_call_job_id,
                customerId: a.customer_id,
                customerInfo: {
                    name: a.customer_info.customer_name,
                    phone: a.customer_info.mobile,
                    phoneSecond: '',
                    houseId: a.customer_info.house_id,
                    houseName: a.customer_info.house_name,
                    operateLog: a.customer_info.operate_log,
                    sysTags: a.customer_info.sys_tags || [],
                    registerTime: a.customer_info.register_time
                },
                level: a.intent_level,
                callResult: this.__utils.index.getLabelByValue(ResultStatus, a.result_status),
                callDuration: a.chat_duration,
                applyTime: a.is_distribution_time ? this.__utils.index.formatDate(new Date(a.is_distribution_time), 'YYYY-MM-DD: HH:mm:ss') : '',
                fxs: a.fxs_user_name,
                lock: a.lock,
                distributionUserName: a.distribution_user_name
            }
        })
    }

    edit (item: TableItem) {
        this.robotCallEditComponent = {
            show: true,
            item: {
                id: item.id,
                name: item.customerInfo.name,
                phone: item.customerInfo.phone,
                houseName: item.customerInfo.houseName,
                houseId: item.customerInfo.houseId,
                registerTime: item.customerInfo.registerTime,
                sysTag: item.customerInfo.sysTags,
                log: item.customerInfo.operateLog
            }
        }
    }

    change (item: LabelValueArray[0]) {
        this.filter.userId = item.value
        this.filter.userName = item.label
    }

    async seePhoneHandle (item: TableItem) {
        if (!item.customerInfo.phone.includes('*')) return
        const { data } = await this.__apis.phoneRecord.getMobileFullNumber(item.id)
        if (data.mobile) {
            item.customerInfo.phone = data.mobile
        }
    }
}
