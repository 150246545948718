import { Component, Vue } from 'vue-property-decorator'
import InputSelectComponent from '@/components/filters/inputSelect/index.vue'
import FilterComponent from '@/components/filters/filter/index.vue'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import addComponent from './add/index.vue'
import { __utils, FriendStatus } from '@/util'
import { routerBaseUrl } from '@/router'
import { duijieUser } from '@/api/channelManage'

interface Table {
    [index: string]: any
    data: {
        id: number
        friendSimpleName: string
        friendName: string
        friendIntro: string
        dock: duijieUser
        startTime: string | null
        allMoney: string
        allTransaction: string
        getMoney: string
        notMoney: string
        getMoneyHouse: string
        partMoneyHouse: string
        notMoneyHouse: string
        getMoneyDay: string
        friendHouse: string
        friendStatus: string
        mustMoney: string
        promiseMoney: string
        promiseMoney2: string
        warning: boolean
    }[]
    loading: boolean
    name: string
    full_name: string
    status: string
    time_start: string
    time_end: string
    cj_money_min: string
    cj_money_max: string
}

@Component({
    components: {
        InputSelectComponent,
        FilterComponent,
        CheckBoxComponent,
        InputDateRangeComponent,
        addComponent
    }
})
export default class IndexPage extends Vue {
    table: Table = {
        data: [],
        loading: false,
        name: '',
        full_name: '',
        status: '',
        time_start: '',
        time_end: '',
        cj_money_min: '',
        cj_money_max: ''
    }

    pagination = {
        total: 20,
        pagesize: 20,
        current: 1
    }

    statusFilter = {
        data: __utils.index.copyObj(FriendStatus)
    }

    addFriendComponentOptions = {
        show: false
    }

    get
    fullName () {
        return this.__store.state.success_detail.all_channel.map(a => {
            return {
                label: a.full_name,
                value: a.id
            }
        })
    }
    get
    simpleName () {
        return this.__store.state.success_detail.all_channel.map(a => {
            return {
                label: a.name,
                value: a.id
            }
        })
    }

    created () {
        this.getData()
    }

    // 搜索渠道商全称
    searchFullName (text: string, cb: any) {
        cb(this.fullName.filter(a => a.label.includes(text)).map(a => {
            return {
                value: a.label
            }
        }))
    }

    // 搜索渠道商全称
    searchSimpleName (text: string, cb: any) {
        cb(this.simpleName.filter(a => a.label.includes(text)).map(a => {
            return {
                value: a.label
            }
        }))
    }

    // 筛选发送变化
    filterChange (data: any) {
        this.table.cj_money_max = ''
        this.table.cj_money_min = ''
        this.table.name = ''
        this.table.full_name = ''
        this.table.status = ''
        this.table.time_start = ''
        this.table.time_end = ''

        for (let k in data) {
            if (k === 'startDate') {
                this.table.time_start = data[k][0]
                this.table.time_end = data[k][1]
            } else if (k === 'full_name') {
                this.table.full_name = data[k].replace(/<[^>]+>$/, '')
            } else {
                this.table[k] = data[k]
            }
        }

        this.pagination.current = 1
        this.getData()
    }

    async getData () {
        try {
            if (this.table.loading) return
            this.table.loading = true
            this.table.data = []

            const { data } = await this.__apis.channelManage.getSalePartnerList({
                page: this.pagination.current,
                pagesize: this.pagination.pagesize,
                name: this.table.name,
                full_name: this.table.full_name,
                status: this.table.status,
                time_start: this.table.time_start,
                time_end: this.table.time_end,
                cj_money_min: this.table.cj_money_min,
                cj_money_max: this.table.cj_money_max
            })

            this.table.loading = false

            this.pagination.total = data.total
            data.list.forEach((a) => {
                this.table.data.push({
                    id: a.Id,
                    friendSimpleName: a.Name,
                    friendName: a.FullName,
                    friendIntro: a.Introduction,
                    dock: a.DuijieUser || [],
                    startTime: a.StartTime && a.StartTime.split(' ')[0],
                    allMoney: a.SumCjMoney + '元',
                    allTransaction: a.SumCjCount + '套',
                    getMoney: a.JieyongMoney + '元',
                    notMoney: a.UnJieyongMoney + '元',
                    getMoneyHouse: a.Jy2Count + '套',
                    partMoneyHouse: a.Jy1Count + '套',
                    notMoneyHouse: a.Jy0Count + '套',
                    getMoneyDay: a.JyAvgDays + '天',
                    friendHouse: a.HouseCount + '个',
                    friendStatus: FriendStatus.filter(f => f.value === a.Status)[0].label,
                    mustMoney: a.SumMustDzMoney + '元',
                    promiseMoney: a.DepositMoney + '元',
                    promiseMoney2: a.RemainMoney + '元',
                    warning: a.Warning
                })
            })
        } catch (e) {
            this.table.loading = false
            throw e
        }
    }

    // 过滤每一列的类容
    formatColumn (r: any, c: any, cellValue: any) {
        if (typeof cellValue === 'number') {
            return cellValue
        } else {
            return cellValue || ''
        }
    }

    gotoDetail (item: any) {
        open(`${routerBaseUrl}/channelManage/hezuoshang/${item.id}`, '_blank')
    }

    // 当前分页改变时候触发
    currentChange (page: number) {
        if (this.table.loading) return

        this.pagination.current = page
        this.getData()
    }

    // 新建合作商
    createFriend () {
        this.addFriendComponentOptions.show = true
    }

    // 设置特殊行样式
    tableRowClassName ({ rowIndex }: any) {
        return this.processPromiseMoney(rowIndex)
    }

    // 合作商 保证金剩余 不足 30%
    processPromiseMoney (index: number) {
        const data = this.table.data[index]

        if (!data) return ''

        return data.warning ? 'promiseMoney' : ''
    }
}
