import { Component, Vue, Prop } from 'vue-property-decorator'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'
import { LabelValueArray } from '@/types'
import { ShareItem } from '..'

interface Item {
    customer_id: number
    shareList: ShareItem[]
}

@Component({
    components: {
        InputSelectSearch
    }
})
export default class ShareManageDialogComponent extends Vue {
    @Prop({
        default: false
    })
    visible!: boolean

    @Prop({
        default () {
            return {
                customer_id: 0,
                shareList: []
            }
        }
    })
    dataSource!: Item

    userInfo = {
        id: 0,
        name: ''
    }

    get
    source () {
        return this.dataSource.shareList.map(a => {
            return {
                label: a.shareName,
                value: a.shareId
            }
        })
    }

    created () {
    }

    selectSearchFxs (item: LabelValueArray[0]) {
        this.userInfo.id = item.value
        this.userInfo.name = item.label
    }

    close () {
        this.$emit('update:visible', false)
    }

    async save () {
        if (this.__utils.index.limitRepeatClick()) return
        try {
            await this.__apis.customer.zhuanjiao({
                customer_id: this.dataSource.customer_id,
                to_user_id: this.userInfo.id
            })
            this.$notify({
                title: '成功',
                message: '保存成功！',
                type: 'success'
            })
            this.close()
            this.$emit('close')
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }
}
