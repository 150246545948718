import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { QuestionType, QuestionTypeEnum, SubjectType } from '@/util/const'
import inputSelectSearchComponent from '@/components/inputSelectSearch/index.vue'
import { LabelValueArray } from '@/types'
import areaOptions, { PlaceItem } from '@/util/places'
import { TableItem } from '@/views/questionBank/manage/classification/index.ts'

interface AnswerItem {
    value: boolean
    content: string
}

interface Form {
    questionType?: number
    subjectType?: number,
    content: string
    answer: string[]
    date: string[]
    scope: string | number
    scopeDep?: number
    scopeHouse: {
        house_id: number
        house_name: string
    },
    scopeArea: string[]
}

const PreData = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']

const QuestionScope = [
    {
        label: '指定楼盘',
        value: 2
    },
    {
        label: '指定区域',
        value: 3
    }
]

@Component({
    components: {
        inputSelectSearchComponent
    }
})
export default class QuestionBankAddQuestionDialogComponent extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    visible!: boolean

    @Prop({
        default () {
            return {}
        }
    })
    item!: TableItem

    form: Form = {
        questionType: undefined,
        subjectType: undefined,
        content: '',
        answer: [],
        date: [],
        scope: '',
        scopeDep: undefined,
        scopeHouse: {
            house_id: 0,
            house_name: ''
        },
        scopeArea: []
    }

    answerList: AnswerItem[] = [
        {
            value: false,
            content: ''
        },
        {
            value: false,
            content: ''
        },
        {
            value: false,
            content: ''
        },
        {
            value: false,
            content: ''
        }
    ]

    preData = PreData

    QuestionType = QuestionType

    SubjectType = SubjectType

    QuestionScope = QuestionScope

    areaOptions: readonly PlaceItem[] = Object.freeze(areaOptions)

    saveTips = false

    editAnswerList: AnswerItem[] = []

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    get
    depArr () {
        return this.__utils.index.getXsDep()
    }

    get
    hasQuestionRes () {
        return this.answerList.some(a => a.value)
    }

    get
    hasQuestionContent () {
        return this.answerList.every(a => a.content)
    }

    get
    isEdit () {
        return !!this.item.id
    }

    get
    title () {
        return this.isEdit ? '编辑题目' : '题目录入'
    }

    @Watch('visible')
    visibleChange (n: boolean) {
        if (n) {
            if (this.isEdit) {
                this.getData()
            } else {
                this.clearData()
                this.form.scopeDep = this.userInfo.dep || +this.$route.params.dep || undefined
                this.form.subjectType = +this.$route.params.type
            }
        }
    }

    async getData () {
        try {
            const { data: { question_base_info, house_name } } = await this.__apis.activity.questionDetail(this.item.id)

            const isManySel = question_base_info.question_type === QuestionTypeEnum.manySelect

            const cor = isManySel ? question_base_info.correct_array : [question_base_info.correct]
            this.answerList = question_base_info.items.map((a, i) => {
                return {
                    value: cor.includes(a.prefix),
                    content: a.content
                }
            })

            this.editAnswerList = this.answerList

            this.form = {
                questionType: question_base_info.question_type,
                subjectType: question_base_info.subject_id,
                content: question_base_info.title,
                answer: [],
                date: question_base_info.invalid_time
                    ? [question_base_info.effective_time, question_base_info.invalid_time].map(a => this.__utils.index.formatDate(new Date(a), 'YYYY-MM-DD'))
                    : [],
                scope: question_base_info.areas.length ? 3 : (question_base_info.subject_id === 1 ? 2 : 3),
                scopeDep: question_base_info.dept_id,
                scopeHouse: {
                    house_id: question_base_info.house_id || 0,
                    house_name: house_name || ''
                },
                scopeArea: question_base_info.areas.map(a => String(a.areaId))
            }
        } catch (e) {
            throw e
        }
    }

    async check (isNextAdd: boolean) {
        this.saveTips = true
        const form = this.form
        if (
            !form.subjectType ||
            !form.questionType ||
            !form.content ||
            !form.scopeDep ||
            !this.hasQuestionRes ||
            !this.hasQuestionContent
        ) {
            return
        }
        await this.saveSubmit()
        await this.createQuestion(isNextAdd)
    }

    async saveSubmit () {
        try {
            await this.$confirm(`是否保存录入内容？新设置内容将在明天生效！`, '设置保存提示', {
                dangerouslyUseHTMLString: false,
                confirmButtonText: '保存',
                cancelButtonText: '取消',
                type: 'warning',
                showClose: false,
                customClass: 'my_confirm_question_bank_set',
                center: true
            })
        } catch (e) {
            console.log(e)
        }
    }

    async createQuestion (isNextAdd: boolean) {
        try {
            const form = this.form
            const isManySel = form.questionType === QuestionTypeEnum.manySelect
            const correct = (() => {
                let res: string[] = []
                this.answerList.forEach((a, i) => {
                    if (a.value) {
                        const item = this.preData[i]
                        if (isManySel) {
                            res.push(item)
                        } else {
                            res = [item]
                        }
                    }
                })
                return res
            })()
            const nextDay = this.__utils.index.formatDate(new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000)), 'YYYY-MM-DD')
            const data = {
                question_type: form.questionType,
                subject_id: form.subjectType,
                title: form.content,
                items: this.answerList.map((a, i) => {
                    return {
                        prefix: this.preData[i],
                        content: a.content
                    }
                }),
                correct_array: isManySel ? correct : undefined,
                correct: !isManySel ? correct.join(',') : undefined,
                dept_id: form.scopeDep || undefined,
                house_id: form.scope === 2 ? form.scopeHouse.house_id : undefined,
                areas: form.scope === 3 ? this.getAreaDataByIds(form.scopeArea).map(a => {
                    return {
                        areaId: a.id,
                        areaName: a.name
                    }
                }) : undefined,
                effective_time: form.date && form.date[0] ? form.date[0] + ' 00:00:00' : nextDay + ' 00:00:00',
                invalid_time: form.date && form.date[1] ? form.date[1] + ' 23:59:59' : undefined
            }
            if (this.isEdit) {
                await this.__apis.activity.updateQuestion({
                    id: this.item.id,
                    ...data
                })
            } else {
                await this.__apis.activity.createQuestion(data)
            }
            this.clearData()
            if (!isNextAdd) {
                this.$emit('update:visible', false)
            }
            if (!this.isEdit) {
                this.$message({
                    message: '题目录入成功，次日生效！',
                    type: 'success'
                })
            } else {
                this.$message({
                    message: '编辑成功，次日生效！',
                    type: 'success'
                })
            }
            this.$emit('change')
        } catch (e) {
            throw e
        }
    }

    close () {
        this.$confirm(`你确定要关闭${this.title}吗？`, '关闭提示！', {
            dangerouslyUseHTMLString: false,
            confirmButtonText: '确 定',
            cancelButtonText: '取 消',
            type: 'warning',
            showClose: false,
            customClass: 'my_confirm_question_bank_set',
            center: true
        }).then(async () => {
            this.$emit('update:visible', false)
        }).catch((e) => {
            console.log(e)
        })
    }

    clearData () {
        this.saveTips = false
        this.form = {
            questionType: undefined,
            subjectType: +this.$route.params.type || undefined,
            content: '',
            answer: [],
            date: [],
            scope: '',
            scopeDep: this.userInfo.dep || +this.$route.params.dep || undefined,
            scopeHouse: {
                house_id: 0,
                house_name: ''
            },
            scopeArea: []
        }
        this.answerList = [
            {
                value: false,
                content: ''
            },
            {
                value: false,
                content: ''
            },
            {
                value: false,
                content: ''
            },
            {
                value: false,
                content: ''
            }
        ]
        this.editAnswerList = this.answerList
    }

    addAnswerItem () {
        if (this.answerList.length > this.preData.length - 1) return
        this.answerList.push({
            value: false,
            content: ''
        })
    }

    delAnswerItem (index: number) {
        this.answerList.splice(index, 1)
    }

    questionTypeChange (value: number) {
        const answerList = this.editAnswerList
        if (value === QuestionTypeEnum.judgeSelect) {
            this.answerList = PreData.filter((a, i) => i < 2).map((a, i) => {
                return {
                    value: false,
                    content: answerList[i] ? answerList[i].content : ''
                }
            })
        } else {
            this.answerList = PreData.filter((a, i) => i < 4).map((a, i) => {
                return {
                    value: false,
                    content: answerList[i] ? answerList[i].content : ''
                }
            })
        }
    }

    subjectTypeChange (value: number) {
        this.form.scope = value === 1 ? 2 : 3
    }

    questionResChange (index: number) {
        if (this.form.questionType !== QuestionTypeEnum.manySelect) {
            this.answerList.forEach((a, i) => {
                if (index !== i) {
                    a.value = false
                }
            })
        }
    }

    async selectHouse (item: LabelValueArray[0] & {id: number}) {
        this.form.scopeHouse.house_id = item.id
    }

    getAreaDataByIds (data: string[]) {
        let res: {
            id: number,
            name: string
        }[] = []
        data.forEach(v => {
            this.getValueObj(v, this.areaOptions, res)
        })
        return res
    }

    getValueObj (v: string, arr: readonly PlaceItem[], res: {id: number, name: string}[]) {
        arr.forEach(a => {
            if (a.value === v) {
                res.push({
                    id: +a.value,
                    name: a.label
                })
            }
            if (a.children && a.children.length) {
                return this.getValueObj(v, a.children, res)
            }
        })
        return res
    }

    // 类型不为楼盘基础禁用楼盘选择
    scopeDisabled (type: number) {
        return type === 2 && this.form.subjectType !== 1
    }
}
