import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SeeAudioDialogComponent extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    show !: boolean

    @Prop({
        type: String,
        default: ''
    })
    url !: string

    close () {
        this.$emit('update:show', false)
    }
}
