import { Component, Vue, Prop } from 'vue-property-decorator'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'
import inputSearch from '@/components/inputSelect/index.vue'

const idValidator = (rule: { message: string }, value: number, callback: Function) => {
    if (!value) {
        return callback(new Error(rule.message))
    }
    if (!Number.isInteger(value)) {
        callback(new Error('请输入数字值'))
    } else {
        if (value > 0) {
            callback()
        }
    }
}

interface houseBindChannelInfoData{
    channel_id: number
    channel_name: string,
    duijie_user_info: {
        id: number
        status: number
        tel: string
        username: string
    }[],
    user_name: string,
    mobile: string,
    id: number
}

interface searchData {
    id: number
    label: string
}

@Component({
    components: {
        inputSelectSearch,
        inputSearch
    }
})
export default class JiXiaoAddScoreComponent extends Vue {
    @Prop({
        default: false
    })
    visible!:boolean

    @Prop({
        default: false
    })
    edit!: boolean

    @Prop({
        default: () => {
            return {
                id: 0,
                userId: 0,
                userName: '',
                houseId: 0,
                houseName: '',
                channelId: 0
            }
        }
    })
    data!: {
        id: number
        userId: number
        userName: string
        houseId: number
        houseName: string
        channelId: number
    }

    form: {
        houseId: number
        userId: number
        houseName: string
        userName: string
    } = {
        houseId: 0,
        houseName: '',
        userName: '',
        userId: 0
    }

    rules = {
        houseId: [
            { type: 'number', validator: idValidator, message: '请输入选择楼盘', trigger: 'change' }
        ],
        userId: [
            { type: 'number', validator: idValidator, message: '请输入选择负责人', trigger: 'change' }
        ]
    }

    houseBindChannelInfo: houseBindChannelInfoData = {
        channel_id: 0,
        channel_name: '',
        duijie_user_info: [],
        user_name: '',
        mobile: '',
        id: 0
    }

    get
    duiJieInfo () {
        const info = this.houseBindChannelInfo
        return info.user_name && info.mobile ? `${info.user_name} ${info.mobile}` : '暂无对接人'
    }

    get
    title () {
        return this.edit ? '编辑绑定' : '添加绑定'
    }

    async created () {
        this.form.userId = this.data.userId
        this.form.userName = this.data.userName
        this.form.houseId = this.data.houseId
        this.form.houseName = this.data.houseName
        if (this.edit) {
            await this.getHouseChannelBindDetail(this.data.houseId)
        }
    }

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    async searchChannelHandleName (text: string, cb:Function) {
        const { data } = await this.__apis.utils.getChannelMemberList()

        const res = data.filter(a => {
            // 只能设置渠道经理自己和渠道专员的楼盘
            return (a.department === this.userInfo.dep && a.role !== this.userInfo.role_id) || (a.user_id === this.userInfo.user_id)
        }).map(a => {
            return {
                label: a.name,
                value: a.user_id
            }
        })

        cb(res.filter(a => a.label.includes(text)))
    }

    selectChannelHandleName (item: {value: number, label: string}) {
        this.form.userId = item.value
        this.form.userName = item.label
    }

    async selectHouse (item:searchData) {
        this.form.houseId = item.id
        this.form.houseName = item.label
        await this.getHouseChannelBindDetail(item.id)
    }

    async getHouseChannelBindDetail (houseId: number) {
        const { data } = await this.__apis.channelManage.getHouseChannelBindDetail(houseId)

        const djUser = data.duijie_user ? data.duijie_user[0] : {
            username: '',
            tel: ''
        }

        this.houseBindChannelInfo = {
            id: data.id || 0,
            channel_id: data.channel_id,
            channel_name: data.channel_name,
            duijie_user_info: data.duijie_user,
            user_name: djUser.username,
            mobile: djUser.tel
        }
    }

    close () {
        const form = this.$refs['form'] as any
        form.resetFields()
        this.form.houseId = 0
        this.form.userId = 0
        this.$emit('update:visible', false)
    }

    checkValid () {
        const form = this.$refs['form'] as any
        form.validate((valid: any) => {
            if (valid) {
                this.save()
            } else {
                return false
            }
        })
    }

    async save () {
        let loading:any

        if (this.__utils.index.limitRepeatClick()) return

        try {
            loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            })

            if (this.edit) {
                await this.__apis.channelManage.updateHouseBindUser({
                    id: this.data.id,
                    user_id: this.form.userId
                })
            } else {
                await this.__apis.channelManage.saveHouseBindUser({
                    house_id: this.form.houseId,
                    house_name: this.form.houseName,
                    user_id: this.form.userId,
                    channel_id: this.houseBindChannelInfo.channel_id,
                    channel_name: this.houseBindChannelInfo.channel_name,
                    duijie_user_info: JSON.stringify(
                        (this.houseBindChannelInfo.duijie_user_info || []).map(a => {
                            return {
                                userName: a.username,
                                tel: a.tel
                            }
                        })
                    )
                })
            }

            loading.close()
            this.close()
            this.$emit('save')
            this.$notify({
                title: '成功',
                message: '保存成功！',
                type: 'success'
            })
        } catch (e) {
            loading && loading.close()
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }
}
