import { Component, Vue } from 'vue-property-decorator'
import fxsListComponent from './fxs/index.vue'
import recordsComponent from './records/index.vue'
import tabsComponent from '@/components/system/tabs/index.vue'
import { __role } from '@/role'
import qmPermission from 'qm-plugin-admin-permission'

@Component({
    components: {
        fxsListComponent,
        recordsComponent,
        tabsComponent
    }
})
export default class AccountUserManage extends Vue {
    tabs = {
        current: 0,
        data: [
            {
                text: '分析师列表',
                url: ['/system/account/fxs']
            }, {
                text: '用户变更记录',
                url: ['/system/account/records'],
                authKey: __role.permissions.UserChangeHistoryPage
            }, {
                text: '员工列表',
                url: ['/system/account/user'],
                authKey: __role.permissions.UserListPage
            }
        ]
    }

    created () {
        qmPermission.filter(this.tabs.data)
    }
}
