import { Component, Vue } from 'vue-property-decorator'
import { routerBaseUrl } from '@/router'

interface TableItem {
    name: string
    phone: string
    fxs: string
    time: string
    id: number
}

@Component
export default class ActivityTask extends Vue {
    tab = ['退客驳回记录']
    tabIndex = 0
    tableData: {
        data: TableItem[]
        page: number
        total_page: number
        loading: boolean
    } = {
        data: [],
        page: 1,
        total_page: 1,
        loading: false
    }
    show = false

    showPanel () {
        this.show = true
        this.getTableData()
    }

    closePanel () {
        this.tableData.page = 1
        this.tableData.total_page = 1
        this.tableData.data = []
        this.show = false
    }

    changeTab (type: number) {
        if (type === this.tabIndex) {
            return
        }
        this.tabIndex = type
        this.tableData.page = 1
        this.getTableData()
    }

    open (id: number) {
        window.open(`${routerBaseUrl}/customer/detail/${id}/`, '_blank')
    }

    scrollList (e: any) {
        if ((this.tableData.page >= this.tableData.total_page)) return

        let toScrollHeight = e.target.scrollHeight - e.target.clientHeight
        if (toScrollHeight - e.target.scrollTop < 20) {
            this.tableData.page++
            this.getTableData()
        }
    }

    async getTableData () {
        if (this.tableData.loading) return

        try {
            const { data } = await this.__apis.customer.groupTurnDownRecord({
                page: this.tableData.page
            })

            this.tableData.loading = true
            this.tableData.total_page = data.total_page

            this.tableData.data = this.tableData.data.concat(data.list.map(a => {
                return {
                    name: a.customer_name,
                    phone: a.customer_phone,
                    fxs: a.fxs_name,
                    time: this.__utils.index.formatDate(new Date(a.mtime), 'YYYY-MM-DD HH:mm'),
                    id: a.customer_id
                }
            }))
        } catch (e) {
            throw e
        } finally {
            this.tableData.loading = false
        }
    }
}
