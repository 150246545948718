import { Component, Vue } from 'vue-property-decorator'
import { LevelStatus } from '@/util/const'
import editCustomerLayer from '@/components/customer/editCustomerLayer/index.vue'

enum Mark {
    zero = 0,
    one = 1,
    two,
    three
}

const MarkData = [
    {
        value: Mark.zero,
        label: '未评分'
    },
    {
        value: Mark.one,
        label: '1星'
    },
    {
        value: Mark.two,
        label: '2星'
    },
    {
        value: Mark.three,
        label: '3星'
    }
]

const RenLingType = {
    1: '预约',
    2: '认领',
    3: '转交',
    4: '创建'
}

const DkCountList = {
    gte: '大于等于',
    lt: '小于',
    eq: '等于'
}

@Component({
    components: {
        editCustomerLayer
    }
})
export default class CustomerMixin extends Vue {
    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    get
    allowShowRepeatCustomer () {
        return !(this.userInfo.dep === 2 && this.isFxs)
    }

    get
    isFxs () {
        return this.__role.has([this.__role.ROLE.FENXISHI], false)
    }

    get
    repeatCustomerBtnShow () {
        return this.__role.check(this.__role.permissions.GetRepeatPhoneCustomerList)
    }

    get
    repeatCustomerBqShow () {
        return this.__role.check(this.__role.permissions.ShowRepeatPhoneCustomerTag)
    }

    get
    impt () {
        return this.__store.state.user.impt
    }

    get
    customerTags () {
        return this.__store.state.user.customerTags
    }

    get
    sourceType () {
        return this.__store.state.user.sourceType
    }

    get
    sysTags () {
        return this.__store.state.user.sysTags
    }

    level = {
        data: LevelStatus.slice(0, 4),
        active: ''
    }

    markData = MarkData

    RenLingType = RenLingType

    DkCountList = DkCountList

    editFastData: any = {}

    async seePhone (item: any) {
        if (item.mobile_fixed.indexOf('*') === -1) {
            return
        }
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })

        try {
            const res = await this.__apis.customer.showPubCustomerMobile(item.id)
            if (res.data.mobile) {
                item.mobile_fixed = res.data.mobile
            }
        } catch (e) {
            throw e
        } finally {
            loading.close()
        }
    }

    async getRepeatCustomer (id: number, item: any) {
        const res = await this.__apis.customer.getRepeatPhoneCustomerList(id)
        this.$set(item, 'repeatCustomerList', res.data)
    }

    toDetail (obj: any, bool: boolean) {
        obj = { name: '/customer/detail', params: { id: obj.id } }
        if (bool) {
            let { href } = this.$router.resolve(obj)
            window.open(href, '_blank')
        } else {
            this.$router.push(obj)
        }
    }

    sortType !: any

    sortMode !: any

    // 排序
    sortChange (obj: any) {
        if (!obj.order) {
            obj.prop = ''
            obj.sort_mode = ''
        } else {
            if (obj.prop === 'tag') {
                obj.sort_mode = obj.order === 'ascending' ? 'desc' : 'asc'
            } else {
                obj.sort_mode = obj.order === 'ascending' ? 'asc' : 'desc'
            }
        }
        this.sortType = obj.prop
        this.sortMode = obj.sort_mode
        this.getData()
    }

    getData () {}

    sysTags3 !: any

    editForm !: any

    dataObj !: any

    editIndex !: any

    dialogEdit !: any

    editCustomerLayer = {
        visible: false,
        customerId: 0
    }

    editCustomerInfo (customerId: number) {
        this.editCustomerLayer = {
            visible: true,
            customerId
        }
    }

    // 快捷操作
    toEdit (index: number) {
        for (let key in this.sysTags3) {
            this.$set(this.sysTags3[key], 'active', false)
        }

        this.editForm.tag = this.dataObj.res.customers[index].tag
        this.editForm.impt_group = this.dataObj.res.customers[index].impt_group && this.dataObj.res.customers[index].impt_group.value

        this.dataObj.res.customers[index].sys_tags && this.dataObj.res.customers[index].sys_tags.map((v: any) => {
            for (let key in this.sysTags3) {
                if (v.value === this.sysTags3[key].value) {
                    this.$set(this.sysTags3[key], 'active', true)
                }
            }
        })

        this.editIndex = index
        this.editFastData = this.dataObj.res.customers[index]
        this.dialogEdit = true
    }

    // 快捷操作保存
    async saveEditForm () {
        if (Number(this.editIndex) < 0) return

        let sys = []
        if (this.sysTags3) {
            for (let key in this.sysTags3) {
                if (this.sysTags3[key].active) {
                    sys.push(this.sysTags3[key].value)
                }
            }
        }

        const item = this.editFastData
        await this.__apis.customer.customerSave({
            id: item.id,
            name: item.name,
            mobile: item.mobile_fixed,
            customer_house: item.house_id,
            sys_tags: sys.join(','),
            impt_group: this.editForm.impt_group,
            tag: this.editForm.tag,
            log: this.editForm.log,
            next_follow_time: this.editForm.gjTime,
            __ignore_filter_value: true
        })

        this.$notify({
            title: '成功',
            message: '保存成功！',
            type: 'success'
        })

        this.dialogEdit = false
        for (let key in this.sysTags3) {
            this.$set(this.sysTags3[key], 'active', false)
        }
        this.editForm.log = ''
        this.editForm.gjTime = ''
        this.getData()
    }

    page !: any

    pageChange (page: number) {
        this.page = page
        this.getData()
    }

    loading = false
    // 标签单选
    async selectHandle (index: number, type: string) {
        if (this.loading) return
        try {
            this.loading = true
            // @ts-ignore
            this[type] = index
            this.page = 1
            await this.getData()
        } finally {
            this.loading = false
        }
    }

    // 系统标签多选
    selectManyHandle (index: string, obj: any) {
        // @ts-ignore
        this.$set(this[obj][index], 'active', !this[obj][index].active)
    }

    // 是否有外呼标签
    hasRobotTag (item: any) {
        return item.type_array.includes('七鱼机器人外呼')
    }

    async addCancelFollow (item:any) {
        if (this.__utils.index.limitRepeatClick()) return
        try {
            if (item.follow_id) {
                await this.__apis.customer.deleteFollowCustomer(item.follow_id)
            } else {
                await this.__apis.customer.saveFollowCustomer(item.id)
            }
            this.getData()
            this.$notify({
                title: '成功',
                message: `${item.follow_id ? '取消' : '添加'}成功！`,
                type: 'success'
            })
        } finally {
            await this.__utils.index.restoreRepeatClick()
        }
    }

    async beginFollow (item: any) {
        await this.__apis.customer.beginFollow(item.id)
        this.$notify({
            title: '成功',
            message: '提交成功！',
            type: 'success'
        })
        const { data } = await this.__apis.customer.customerDetail(String(item.id))
        item.mobile_fixed = data.mobile_fixed
        item.can_show_begin_follow_button = data.can_show_begin_follow_button
    }
}
