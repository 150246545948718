import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class AddHotArticleComponent extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    visible!: boolean

    @Prop({
        type: Object,
        default: () => {}
    })
    data!: {
        value: string
    }

    async submit () {
        const item = this.data
        await this.__apis.wxFriends.addPoolHotArticle({
            url: item.value
        })
        this.$emit('save')
        this.$notify({
            title: '成功',
            message: '添加成功',
            type: 'success'
        })
    }

    async save () {
        if (!this.data.value) {
            this.$message.warning('请输入链接地址！')
            return
        }
        if (this.__utils.index.limitRepeatClick()) return
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)'
        })
        try {
            await this.submit()
            this.close()
        } finally {
            loading.close()
            this.__utils.index.restoreRepeatClick()
        }
    }

    close () {
        this.$emit('update:visible', false)
    }
}
