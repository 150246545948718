import { Component, Vue } from 'vue-property-decorator'
import setComponent from '@/components/wallet/set/index.vue'

@Component({
    components: {
        setComponent
    }
})
export default class IndexPage extends Vue {

}
