import { BaseRes, java, ListReq, ListRes, pp } from '@/api/index'

interface getPhoneRecordListReq {
    [key:string]:any
    start_date?: any
    end_date?: any
    page?: number
    pagesize?: number
    sort?: any
    filters?:any
    aggregate?: boolean
}

interface getPhoneRecordListRes {
    total: number
    total_found: number
    rows: [
        {
            id: number, // 记录id
            type: number, // 1 呼出  2 呼入
            caller_id: string, // 主叫号码
            callee_id: string, // 被叫号码
            middle_id: string, // 外线号码
            duration: number,
            billsec: number, // 通话时长（秒）
            status: string, // NORMAL_CLEARING表示接通，其他都可以当成未接通
            uuid: string,
            ctime: string,
            created_time: string, // 通话时间
            answered_time: string,
            hangup_time: string,
            voicemail_audio_id: number,
            record_audio_id: number, // 录音文件id，0表示没有录音
            user_id: number, // 用户id
            username: string, // 用户名
            department: number, // 部门
            departmentStr: string,
            teamStr: string,
            team: number, // 战队
            role: number // 角色
        }
    ]

}

interface getPhoneRecordInfoRes {
    // 通话详情
    cdr: {
        id: number, // ID
        type: number, // 1 呼出 2 呼入
        caller_id: string, // 呼叫账号
        callee_id: string, // 被叫号码
        middle_id: string, // 外线号码
        duration: number,
        billsec: number, // 通话时长（秒）
        status: string,
        uuid: string,
        ctime: string,
        created_time: string,
        answered_time: string,
        hangup_time: string,
        voicemail_audio_id: number,
        record_audio_id: number // 通话录音文件id
    },
    // 通话录音信息
    record_audio: {
        duration: number, // 录音时长
        id: number,
        url: string
    },
    // 通话相关的用户
    user: {
        id: number,
        username: string,
        email: string,
        ctime: string,
        mtime: string,
        deactive: number,
        headimg_url: string,
        roles: string[],
        department: number,
        team: number
    }
}

type GetRobotCallRecordReq = ListReq & Partial<{
    start_time: string
    end_time: string
    mobile: string
    intent_level: string
    result_status: string
    is_distribution: boolean
    dep_id: number
    distribution_user_id: number
    chat_duration: number
}>

export enum GetRobotCallRecordChatType {
    ROBOT = 'ROBOT',
    PERSON = 'PERSON'
}

export type RobotCallRecordItem = {
    id: number
    lock: boolean
    call_record_id: number
    chat_duration: number
    customer_id: number
    customer_name: string
    customer_info: {
        customer_name: string
        mobile: string
        house_id: number
        house_name: string
        operate_log: string,
        sys_tags: number[],
        register_time: ''
    }
    full_audio_url: string
    fxs_user_id: number
    fxs_user_name: string
    intent_level: string
    is_distribution_time: number
    mobile: string
    result_status: string
    robot_call_job_id: number
    start_time: string
    call_detail_list: {
        callDetailId: number
        text: string
        type: GetRobotCallRecordChatType
    }[],
    distribution_user_name: string
}

type GetRobotCallRecordRes = ListRes<RobotCallRecordItem>

interface customerRobotCallInfo extends RobotCallRecordItem{
    level: string
}

type ThawCustomerRobotCallLockReq = {
    id: number
    dispatch: boolean
}

interface saveQiYuCustomerInfoReq {
    'id': number,
    'customer_name': string,
    'house_id': number,
    house_name: string
    'sys_tags': number[]
    'operate_log': string
}

export default class APIPhone {
    // 客户机器人通话记录查看全号
    getMobileFullNumber (id: number): BaseRes<customerRobotCallInfo> {
        return java.post('/admin/qiyu/robot/call/getMobileFullNumber', { id })
    }

    // 客户机器人通话记录编辑客户信息
    saveQiYuCustomerInfo (data: saveQiYuCustomerInfoReq) {
        return java.post('/admin/qiyu/robot/call/saveQiYuCustomerInfo', data)
    }

    // 根据用户id获取机器人通话记录
    customerRobotCallInfo (customerId: number): BaseRes<customerRobotCallInfo> {
        return java.post('/admin/qiyu/robot/call/customerRobotCallInfo', { customerId })
    }

    // 机器人外呼客户解除锁定
    thawCustomerRobotCallLock (data: ThawCustomerRobotCallLockReq) {
        return java.post('/admin/qiyu/robot/call/thawCustomerRobotCallLock', data)
    }

    // 机器人外呼记录
    getRobotCallRecord (data: GetRobotCallRecordReq): BaseRes<GetRobotCallRecordRes> {
        return java.post('/admin/qiyu/robot/call/getRobotCallRecord', data)
    }

    // 通话记录查询
    getPhoneRecordList (data:getPhoneRecordListReq): Promise<getPhoneRecordListRes> {
        return pp.post('/voip/cdr/query', {
            ...data
        })
    }

    // 通话详情
    getPhoneRecordInfo (id:number): Promise<getPhoneRecordInfoRes> {
        return pp.post('/voip/cdr/info', {
            id: id
        })
    }
}
