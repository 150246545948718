import { Component, Vue } from 'vue-property-decorator'

import FilterComponent from '@/components/filters/filter/index.vue'
import inputDateRange from '@/components/filters/inputDateRange/index.vue'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'
import InputSelectComponent from '@/components/filters/inputSelect/index.vue'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'

@Component({
    components: {
        FilterComponent,
        inputDateRange,
        FilterBusSelectComponent,
        FilterBusInputSelectComponent,
        InputSelectComponent,
        CheckBoxComponent
    }
})
export default class InvalidCustomerAuditPage extends Vue {
    // 显示驳回理由 和 底部按钮
    // 待审核或者争议单才可以显示
    get
    isThenOrder () {
        return this.recordDetail.saleInfo.Status === 0 || this.recordDetail.saleInfo.Status === 3
    }

    // 驳回按钮禁用
    get
    btnRejectDisable () {
        return !this.recordDetail.saleReason.trim()
    }

    // 争议单按钮显示
    get
    showBtnDispute () {
        return this.recordDetail.saleInfo.Status === 0 && this.__role.check(this.__role.permissions.MarkDispute)
    }

    dataObj: any = {
        res: []
    }
    page = 1
    pageSize = 50
    total = 0
    sortType = ''
    sortMode = ''
    formLabelWidth = '90px'
    filter = {}
    rejectCustomer:{
        [key:string]:any
    } = {
        visible: false,
        remark: '',
        data: {},
        status: 0
    }
    recordDetail:any = {
        guideVisible: false,
        saleVisible: false,
        status: false,
        guideReason: '',
        saleReason: '',
        guideInfo: {},
        saleInfo: {}
    }
    status = [
        {
            value: 0,
            label: '待审核'
        }, {
            value: 1,
            label: '已通过'
        }, {
            value: 2,
            label: '已驳回'
        }, {
            value: 3,
            label: '争议单'
        }
    ]

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    mounted () {
        this.getData()
    }

    seeDetail (item:any) {
        console.log(111, item)
        if (item.Type === 1) {
            this.recordDetail.guideInfo = item
            this.recordDetail.guideReason = ''
            this.recordDetail.guideVisible = true
        } else {
            this.recordDetail.saleInfo = item
            this.recordDetail.status = item.Data && Boolean(+item.Data.is_controversial)
            this.recordDetail.saleReason = ''
            this.recordDetail.saleVisible = true
        }
    }

    getAuditStatus (status: 1 | 2 | 3 | 0) {
        return {
            0: '待审核',
            1: '通过',
            2: '驳回',
            3: '争议单'
        }[status]
    }

    async getData () {
        const res = await this.__apis.customer.getDailyWorkExamineList({
            type: 2,
            ...this.filter,
            page: this.page,
            pagesize: this.pageSize
        })

        res.data.list.map((v:any) => {
            v.Data = v.Data ? JSON.parse(v.Data) : {}
            if (v.DepId !== 0 && v.GroupId !== 0) {
                v.group_name = this.__utils.index.getGroupInfoById(v.GroupId).name
            }

            // 处理转介绍标签
            if (v.CustomerAllInfo) {
                v.CustomerAllInfo.type_array_intro = +v.Data.bind_customer_id === 0
            }

            if (v.Data) {
                if (v.Data.ext_data === '[object Object]') {
                    v.Data.ext_data = ''
                }
                v.Data.ext_data = v.Data.ext_data ? JSON.parse(v.Data.ext_data) : []
                v.Data.house_list = v.Data.house_list ? JSON.parse(v.Data.house_list) : []
                v.Data.user_info = v.Data.user_info ? JSON.parse(v.Data.user_info) : []
                v.Data.imgs = v.Data.imgs ? JSON.parse(v.Data.imgs) : []
                v.Data.user_info.map((a:any) => {
                    a.rate = Number(a.money) === 0 ? 0 + '%' : ((a.money / v.Data.sum_money) * 100).toFixed(2) + '%'
                })

                if (v.Data.pz_imgs) {
                    let zm = [
                        { index: 0, type: 'bb', text: '报备截图', url: '' },
                        { index: 1, type: 'qd', text: '签到单', url: '' },
                        { index: 2, type: 'sk', text: '刷卡单', url: '' },
                        { index: 3, type: 'rg', text: '认购书', url: '' },
                        { index: 4, type: 'sf', text: '客户身份证', url: '' }
                    ]
                    v.Data.pz_imgs = v.Data.pz_imgs ? JSON.parse(v.Data.pz_imgs) : {}
                    Object.keys(v.Data.pz_imgs).map((b:any) => {
                        zm.map((a:any) => {
                            if (a.type === b) {
                                a.url = v.Data.pz_imgs[b]
                            }
                        })
                    })
                    v.Data.zm = zm
                    console.log(v.Data.zm)
                }
            }
        })
        this.dataObj = res.data
        this.total = res.data.total
    }
    // 筛选
    filterChange (data: any) {
        if (data.action_time) {
            data.time_start = data.action_time && data.action_time[0]
            data.time_end = data.action_time && data.action_time[1]
        }
        this.page = 1
        this.filter = data
        this.getData()
    }
    pageChange (page:number) {
        this.page = page
        this.getData()
    }
    toDetail (obj: any, bool: boolean) {
        obj = { name: '/customer/detail', params: { id: obj.CustomerId } }
        if (bool) {
            let { href } = this.$router.resolve(obj)
            window.open(href, '_blank')
        } else {
            this.$router.push(obj)
        }
    }

    async checkRecord (obj:any, status:number) {
        if (this.__utils.index.limitRepeatClick()) return

        try {
            await this.__apis.customer.examineDailyWorkExamine({
                id: obj.Type === 1 ? this.recordDetail.guideInfo.Id : this.recordDetail.saleInfo.Id,
                status: status,
                reject_reason: obj.Type === 1 ? this.recordDetail.guideReason : this.recordDetail.saleReason
            })

            if (obj.Type === 1) {
                this.recordDetail.guideVisible = false
            } else {
                this.recordDetail.saleVisible = false
            }

            this.$notify({
                title: '成功',
                message: '提交成功！',
                type: 'success'
            })
            this.getData()
        } catch (e) {
            this.$notify({
                title: '错误',
                message: '请稍后再试！',
                type: 'error'
            })
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    cancelReject () {
        this.rejectCustomer.visible = false
    }

    async saveReject () {
        await this.__apis.customer.examineDailyWorkExamine({
            'id': this.rejectCustomer.data.Id,
            'status': this.rejectCustomer.status,
            'reject_reason': this.rejectCustomer.remark
        })
        this.cancelReject()
        this.$notify({
            title: '成功',
            message: '提交成功！',
            type: 'success'
        })
        this.getData()
    }
}
