import { Component, Vue } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import inputDateRange from '@/components/filters/inputDateRange/index.vue'
import FilterBusInputSelectComponent from '@/components/filters/filter-business/inputSelect/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'
import InputSelectComponent from '@/components/filters/inputSelect/index.vue'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'

@Component({
    components: {
        FilterComponent,
        inputDateRange,
        FilterBusSelectComponent,
        FilterBusInputSelectComponent,
        InputSelectComponent,
        CheckBoxComponent,
        InputSelectSearch
    }
})
export default class SalemanGuideRecordAuditPage extends Vue {
    dataObj: any = {
        res: []
    }
    page = 1
    pageSize = 50
    total = 0
    sortType = ''
    sortMode = ''
    formLabelWidth = '90px'
    filter = {
        action_time: '',
        status: undefined,
        group_id: undefined,
        user_id: undefined
    }
    recordDetail:any = {
        guideVisible: false,
        saleVisible: false,
        guideReason: '',
        saleReason: '',
        guideInfo: {},
        saleInfo: {}
    }
    status = [
        {
            value: 0,
            label: '待审核'
        }, {
            value: 1,
            label: '已通过'
        }, {
            value: 2,
            label: '已驳回'
        }
    ]
    loading = false

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    mounted () {
        this.getData()
    }

    seeDetail (item:any) {
        console.log('seeDetail', item)
        if (item.Type === 1) {
            this.recordDetail.guideInfo = item
            this.recordDetail.guideReason = ''
            this.recordDetail.guideVisible = true
        } else {
            this.recordDetail.saleInfo = item
            this.recordDetail.saleReason = ''
            this.recordDetail.saleVisible = true
        }
    }

    // 搜索
    clickSearch () {
        this.pageChange(1)
    }

    // 清空筛选
    resetFrom (formName: string) {
        const form = this.$refs[formName] as any
        form.resetFields()
        this.pageChange(1)
    }

    async getData () {
        try {
            this.loading = true
            const filter = this.filter
            const res = await this.__apis.customer.getDailyWorkExamineList({
                type: 1,
                status: filter.status,
                group_id: filter.group_id,
                user_id: filter.user_id,
                time_start: filter.action_time && filter.action_time[0],
                time_end: filter.action_time && filter.action_time[1],
                page: this.page,
                pagesize: this.pageSize
            })
            res.data.list.forEach((v:any) => {
                v.Data = v.Data ? JSON.parse(v.Data) : {}
                v.group_name = this.__utils.index.getGroupInfoById(v.GroupId).name
                if (v.Data) {
                    if (v.Data.ext_data === '[object Object]') {
                        v.Data.ext_data = ''
                    }
                    v.Data.ext_data = v.Data.ext_data ? JSON.parse(v.Data.ext_data) : []
                    v.Data.house_list = v.Data.house_list ? JSON.parse(v.Data.house_list) : []
                    v.Data.user_info = v.Data.user_info ? JSON.parse(v.Data.user_info) : []
                    v.Data.imgs = v.Data.imgs ? JSON.parse(v.Data.imgs) : []

                    if (v.Data.pz_imgs) {
                        let zm = [
                            { index: 0, type: 'qd', text: '签到单', url: '' },
                            { index: 3, type: 'sk', text: '刷卡单', url: '' },
                            { index: 1, type: 'rg', text: '认购书', url: '' },
                            { index: 2, type: 'sf', text: '客户身份证', url: '' }
                        ]
                        v.Data.pz_imgs = v.Data.pz_imgs ? JSON.parse(v.Data.pz_imgs) : {}
                        Object.keys(v.Data.pz_imgs).map((b:any) => {
                            zm.map((a:any) => {
                                if (a.type === b) {
                                    a.url = v.Data.pz_imgs[b]
                                }
                            })
                        })
                        v.Data.zm = zm
                        console.log(v.Data.zm)
                    }
                }
            })
            this.dataObj = res.data
            this.total = res.data.total
        } finally {
            this.loading = false
        }
    }
    // 筛选
    filterChange (data: any) {
        if (data.action_time) {
            data.time_start = data.action_time && data.action_time[0]
            data.time_end = data.action_time && data.action_time[1]
        }
        this.page = 1
        this.filter = data
        this.getData()
    }
    pageChange (page:number) {
        this.page = page
        this.getData()
    }
    toDetail (obj: any, bool: boolean) {
        obj = { name: '/customer/detail', params: { id: obj.CustomerId } }
        if (bool) {
            let { href } = this.$router.resolve(obj)
            window.open(href, '_blank')
        } else {
            this.$router.push(obj)
        }
    }
    async checkRecord (obj:any, status:number) {
        if (this.__utils.index.limitRepeatClick()) return

        const loading = this.$loading({
            lock: true,
            text: '提交中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)'
        })

        try {
            await this.__apis.customer.examineDailyWorkExamine({
                id: obj.Type === 1 ? this.recordDetail.guideInfo.Id : this.recordDetail.saleInfo.Id,
                status: status,
                reject_reason: status === 2 ? (obj.Type === 1 ? this.recordDetail.guideReason : this.recordDetail.saleReason) : undefined,
                remark: status === 1 ? this.recordDetail.guideReason : undefined
            })

            if (obj.Type === 1) {
                this.recordDetail.guideVisible = false
            } else {
                this.recordDetail.saleVisible = false
            }

            this.$notify({
                title: '成功',
                message: '提交成功！',
                type: 'success'
            })

            this.getData()
        } catch (e) {
            this.$notify({
                title: '错误',
                message: '请稍后再试！',
                type: 'error'
            })
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
            loading.close()
        }
    }
}
