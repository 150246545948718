enum Permissions {
    ShowHomeSaleManDataPage = 'showHomeSaleManDataPage',
    ShowHomeWorkOverviewPage = 'showHomeWorkOverviewPage',
    ChangeFyStatus = 'changeFyStatus',
    OnlineSignApply = '/admin/onlineSign/apply',
    CreateShangWuTongCustomer = '/admin/house/detail/weChat/customer/createShangWuTongCustomer',
    ChangeLogTag = 'changeLogTag',
    DepositSave = '/admin/sale_partner/deposit/save',
    AddSuccessData = 'addSuccessData',
    GetHouseSaleServiceRemarkAllList = '/admin/houseSale/remark/getHouseSaleServiceRemarkAllList',
    GetPersonalRankList = '/admin/user/house/sale/getPersonalRankList',
    GetInvalidCustomerApplyList = 'getInvalidCustomerApplyList',
    GetTodayTaskOverview = 'getTodayTaskOverview',
    ShowFinancialWalletTeamPage = 'showFinancialWalletTeamPage',
    ShowFinancialWalletReviewPage = 'showFinancialWalletReviewPage',
    ShowFinancialWalletPersonPage = 'showFinancialWalletPersonPage',
    ShowCommissionManagePage = 'showCommissionManagePage',
    ShowSalaryManagePage = 'showSalaryManagePage',
    ShowSalarySettingPage = 'showSalarySettingPage',
    ShowSalaryOverviewPage = 'showSalaryOverviewPage',
    GroupTurnDownRecord = 'groupTurnDownRecord',
    GetInvalidCustomerApplyRecordList = 'getInvalidCustomerApplyRecordList',
    InvalidCustomerStatistic = 'invalidCustomerStatistic',
    JoinMyContacts = 'joinMyContacts',
    ShowMoneyChangeRecordPage = 'showMoneyChangeRecordPage',
    ShowSuccessManagePage = 'showSuccessManagePage',
    ShowBillingManagePage = 'showBillingManagePage',
    ShowGiveMoneyManagePage = 'showGiveMoneyManagePage',
    ShowSignManagePage = 'showSignManagePage',
    ShowExitManagePage = 'showExitManagePage',
    ShowBackMoneyManagePage = 'showBackMoneyManagePage',
    ShowRepeatPhoneCustomerTag = 'showRepeatPhoneCustomerTag',
    GetRepeatPhoneCustomerList = 'getRepeatPhoneCustomerList',
    EditCommissionRecord = 'editCommissionRecord',
    GetSaleCyRecord = 'getSaleCyRecord',
    SuccessSearchSaleMan = 'successSearchSaleMan',
    NumberActiveCustomers = 'numberActiveCustomers',
    TotalWorkingDays = 'totalWorkingDays',
    ForecastForNextMonth = 'forecastForNextMonth',
    ForecastThisMonth = 'forecastThisMonth',
    SearchUser = 'searchUser',
    SearchSaleManDepartment = 'searchSaleManDepartment',
    SearchTeams = 'searchTeams',
    ShowMySalaryPage = 'showMySalaryPage',
    RoleAuthPage = 'roleAuthPage',
    UserAuthPage = 'userAuthPage',
    AuthManagePage = 'authManagePage',
    TeamManagePage = 'teamManagePage',
    UserChangeHistoryPage = 'userChangeHistoryPage',
    UserListPage = 'userListPage',
    ReportManagePage = 'reportManagePage',
    QmWalletPage = 'qmWalletPage',
    PersonWalletPage = 'personWalletPage',
    WithdrawRecordPage = 'withdrawRecordPage',
    PhoneRecodePage = 'phoneRecodePage',
    ShowRobotPhoneRecordPage = 'showRobotPhoneRecordPage',
    HouseAdminPage = 'houseAdminPage',
    UrgeManagePage = 'urgeManagePage',
    PartnerManagePage = 'partnerManagePage',
    RetirementReviewPage = 'retirementReviewPage',
    HomePage = 'homePage',
    TransactionReviewPage = 'transactionReviewPage',
    MarkDispute = 'markDispute',
    ChannelReportManagePage = 'channelReportManagePage',
    SignReviewPage = 'signReviewPage',
    HouseManagePage = 'houseManagePage',
    PersonManagePage = 'personManagePage',
    FinancialManagePage = 'financialManagePage',
    DaysAndCommission = 'daysAndCommission',
    IsControversial = 'isControversial',
    WageManagePage = 'wageManagePage',
    WalletManagePage = 'walletManagePage',
    GuideRecordReviewPage = 'guideRecordReviewPage',
    GuideDealReviewPage = 'guideDealReviewPage',
    CustomerQuickOperate = 'customerQuickOperate',
    ForwardHistoryChannel = 'zhuanJiaoHistoryChannel',
    ForwardHistoryPage = 'zhuanJiaoHistoryPage',
    BaoBeiHistoryPage = 'baoBeiHistoryPage',
    AllCustomerPage = 'allCustomerPage',
    DepartmentCustomerPage = 'departmentCustomerPage',
    TeamCustomerPage = 'teamCustomerPage',
    ClinchCustomerPage = 'clinchCustomerPage',
    PhoneCustomerPage = 'phoneCustomerPage',
    WaitBuyPage = 'waitBuyPage',
    PublicCustomerPage = 'publicCustomerPage',
    ImportantCustomerPage = 'importantCustomerPage',
    InvalidCustomerPage = 'invalidCustomerPage',
    ChangeInvalidCustomerReviewStatus = 'changeInvalidCustomerReviewStatus',
    InvalidCustomerReviewList = 'invalidCustomerReviewList',
    CustomerAnalysisPage = 'customerAnalysisPage',
    SaleManCustomerAreaSet = 'saleManCustomerAreaSet',
    ShowReservationCustomerStatisticsPage = 'showReservationCustomerStatisticsPage',
    showFollowUpAnalysis = 'showFollowUpAnalysis',
    ShowNewCustomerPage = 'showNewCustomerPage',
    ShowSystemPage = 'showSystemPage',
    ShowChannelPage = 'showChannelPage',
    ShowCustomerPage = 'showCustomerPage',
    ShowSaleManPage = 'showSalemanPage',
    MarkCustomerInvalid = 'markCustomerInvalid',
    FillInFollowUp = 'fillInFollowUp',
    EditReviewPassGuideTotal = 'editReviewPassGuideTotal',
    MyCustomerPage = 'myCustomerPage',
    AddressBookPage = 'addressBookPage',
    MySuccessHistoryPage = 'mySuccessHistoryPage',
    CustomerSave = 'customerSave',
    CreateCustomer = 'createCustomer',
    CustomerDetail = 'customerDetail',
    RenLin = 'renlin',
    CancelRenLin = 'cancelRenlin',
    ForwardCustomer = 'zhuanjiao',
    SaveDkPre = 'saveDkPre',
    SaveDkRes = 'saveDkRes',
    SaveCjRes = 'saveCjRes',
    SaveSale = 'saveSale',
    DoApplyCustomerInvalid = 'doApplyCustomerInvalid',
    ReviewCustomerInvalidApply = 'reviewCustomerInvalidApply',
    ShowPubCustomerMobile = 'showPubCustomerMobile',
    AddDkResForExamine = 'addDkResForExamine',
    AddSaleRecordForExamine = 'addSaleRecordForExamine',
    SaveOrderCustomer = 'saveOrderCustomer',
    CancelOrderCustomer = 'cancelOrderCustomer',
    SaveFxsArea = 'saveFxsArea',
    ApplyInvalidCustomer = 'applyInvalidCustomer',
    PhoneReDispatchCustomer = 'phoneReDispatchCustomer',
    DeleteDkCj = 'deleteDkCj',
    SaveDkCjComment = 'saveDkCjComment',
    ExportWorkList = 'exportWorkList',
    ExamineDailyWorkExamine = 'examineDailyWorkExamine',
    ExportSaleList = 'exportSaleList',
    SaveSaleKpRecord = 'saveSaleKpRecord',
    SaveSaleDzRecord = 'saveSaleDzRecord',
    SaveSaleFyRecord = 'saveSaleFyRecord',
    SaveSaleTfRecord = 'saveSaleTfRecord',
    SaveSaleWqRecord = 'saveSaleWqRecord',
    FastSaveWqRecord = 'fastSaveWqRecord', // 一键设置网签，仅仅前端权限，后端saveSaleWqRecord控制
    SaveSaleTroubleRecord = 'saveSaleTroubleRecord',
    DeleteWqKpDzFyTfRecords = 'deleteWqKpDzFyTfRecords',
    DeleteSaleRecord = 'deleteSaleRecord',
    ExportCjListData = 'exportCjListData',
    SaveSaleCyRecord = 'saveSaleCyRecord',
    SaveBasicSalaryRecord = 'saveBasicSalaryRecord',
    ExportSalaryManageOverViewList = 'exportSalaryManageOverViewList',
    ExportSalaryList = 'exportSalaryList',
    ImportSalaryList = 'importSalaryList',
    ImportSalaryNew = 'importSalaryNew',
    SaveFinanceSendRecord = 'saveFinanceSendRecord',
    DoSendMoney = 'doSendMoney',
    ExamineCashOut = 'examineCashOut',
    SaveCashOut = 'saveCashOut',
    ExportCashOut = 'exportCashOut',
    ImportCashOut = 'importCashOut',
    ExportFinanceSendRecord = 'exportFinanceSendRecord',
    ImportFinanceSendRecord = 'importFinanceSendRecord',
    DeleteSendRecord = 'deleteSendRecord',
    PushToWallet = 'pushToWallet',
    SaveSalePartner = 'saveSalePartner',
    SaveSalePartnerBizRecord = 'saveSalePartnerBizRecord',
    ExamineInvalidCustomer = 'examineInvalidCustomer',
    SaveHouse = 'saveHouse',
    ChangeNewHouseStatus = 'changeNewHouseStatus',
    SaveHouseComment = 'saveHouseComment',
    SaveMrt = 'saveMrt',
    SaveCompanyAlbum = 'saveCompanyAlbum',
    SaveCompanyAlbumPhotos = 'saveCompanyAlbumPhotos',
    DelAlbumPhoto = 'delAlbumPhoto',
    SaveForum = 'saveForum',
    SaveForumComment = 'saveForumComment',
    CollectForum = 'collectForum',
    CancelCollectForum = 'cancelCollectForum',
    SaveKnowledgeLib = 'saveKnowledgeLib',
    SaveKnowledgeLibDir = 'saveKnowledgeLibDir',
    DeleteKnowledgeLib = 'deleteKnowledgeLib',
    DeleteKnowledgeLibDir = 'deleteKnowledgeLibDir',
    DeleteKnowledgeLibDoc = 'deleteKnowledgeLibDoc',
    SaveKnowledgeLibDoc = 'saveKnowledgeLibDoc',
    SaveNotice = 'saveNotice',
    DeleteNotice = 'deleteNotice',
    DoSendGroupWalletMoney = '/admin/wallet/manage/doSendGroupWalletMoney',
    SaveHouseSaleServiceRemark = '/admin/houseSale/remark/saveHouseSaleServiceRemark',
    CreateAndSaveFxs = '/admin/user/createAndSaveFxs',
    UserActiveChange = '/admin/user/userActiveChange',
    RolePermissionsSave = '/admin/permissions/module/role/permissions/save',
    UserPermissionsSave = '/admin/permissions/module/user/permissions/save',
    PermissionsSave = '/admin/permissions/module/permissions/save',
    SetPermissionsStatus = '/admin/permissions/module/set/permissions/status',
    BaoBeiSave = '/admin/channel/baobei/save',
    BaoBeiSpecialSave = '/admin/channel/baobei/specialSave',
    WalletCashOutStatistics = 'walletCashOutStatistics',
    GetWalletCashOutRecordListEs = 'getWalletCashOutRecordListEs',
    GetUnSendBonusListGroupByUser = 'getUnSendBonusListGroupByUser',
    GetUnSendBonusRecordList = 'getUnSendBonusRecordList',
    GetUnSendBonusInterestRecordList = 'getUnSendBonusInterestRecordList',
    GetWalletUnSettlementRecordList = 'getWalletUnSettlementRecordList',
    GetGroupWalletRecordList = 'getGroupWalletRecordList',
    FxsWalletRecord = 'fxsWalletRecord',
    FxsWalletMonthRecord = 'fxsWalletMonthRecord',
    FxsWalletStatistics = 'fxsWalletStatistics',
    GroupWalletMonthRecordES = 'groupWalletMonthRecordES',
    GroupWalletStatistics = 'groupWalletStatistics',
    GetFinanceSendRecordList = 'getFinanceSendRecordList',
    FxsWalletMonthRecordExport = 'fxsWalletMonthRecordExport',
    DeleteSpecialBaoBei = '/admin/channel/baobei/deleteSpecialBaobei',
    SaveBaoBeiTemplate = '/admin/channel/houseBind/saveBaobeiTemplate',
    DeleteBaoBeiTemplate = '/admin/channel/houseBind/deleteBaobeiTemplate',
    HouseBindSave = '/admin/channel/houseBind/save',
    HouseBindStatusSave = '/admin/channel/houseBind/houseBindStatusSave',
    DuiJieUserAction = '/admin/channel/duijie/duiJieUserAction',
    CreateGroup = '/admin/fxs/group/action/createGroup',
    EditGroup = '/admin/fxs/group/action/editGroup',
    DisableGroup = '/admin/fxs/group/action/disableGroup',
    EditGroupLeader = '/admin/fxs/group/action/editGroupLeader',
    CreateEmployee = '/admin/employee/action/createEmployee',
    EditEmployeeInfo = '/admin/employee/action/editEmployeeInfo',
    ShowZhuanjiaoCustomerToDetailBtn = 'showZhuanjiaoCustomerToDetailBtn',
    ShowControversialExaminePage = 'showControversialExaminePage',
    ShowChannelAssessmentPage = 'showChannelAssessmentPage',
    ChannelSaveHouseBindUser = '/admin/channel/achievements/manage/house/user/bind/saveHouseBindUser',
    ChannelSaveScoreRecord = '/admin/channel/achievements/manage/score/record/saveScoreRecord',
    ChannelSaveProblemDealSlipRecord = '/admin/channel/achievements/manage/problem/dealslip/record/saveProblemDealSlipRecord',
    EditExpectWangQianTime = '/admin/user/house/sale/saveExpectWangQianTime',
    RobotCallCustomerFilter = 'robotCallCustomerFilter',
    ShowFxsPhoneRecordPage = 'showFxsPhoneRecordPage',
    ShowPermissionMenu = 'showPermissionMenu',
    GetWeeklyData = '/admin/data/analysis/userWeekDataAnalysis',
    ShowQuestionAnswerRecordPage = '/admin/task/question/answerPage',
    AddCancelFollow = '/admin/follow/customer/saveFollowCustomer',
    FollowCustomerList = '/admin/follow/customer/followCustomerListDtoListQuery',
    ShowShareCustomerRecordPage = 'showShareListPage',
    ShareToSomeOne = '/admin/customer/share/shareToSomeOne',
    RobCustomer = '/admin/rob/customer/robCustomerTransfer',
    RobCustomerAction = '/admin/rob/customer/robCustomerAction',
    ShowQuestionBankPage = 'showQuestionBankPage',
    ShowQuestionBankStatisticPage = '/admin/questionBank/questions/statistic/company',
    ShowQuestionBankMangePage = '/admin/questionBank/questions/statistic/dep',
    ShowRankGoldFall = 'showRankGoldFall',
    ShowHomeProfitRecordPage = '/admin/profit/getProfitRecord',
    ShowNotice = 'showNotice',
    ShowMarketPage = 'showMarketPage',
    ShowMarketPlatformLoginPage = '/admin/customerChannelAccount/getAccountList',
    ShowOcpcListPage = 'showOcpcListPage',
    ShowChongZhiWalletPage = 'showChongZhiWalletPage',
    ShowGroupCommissionRecordPage = '/admin/commision/getCommisionRecord',
    EditTeamProfitUserMoney = '/admin/profit/modifyPersonCost',
    ActionDispatchCustomerStatus = 'actionDispatchCustomerStatus',
    CustomerInvalidApplyRecordsPage = '/admin/customer/invalid/getCustomerInvalidApplyRecords',
    WarningMsgComponent = '/admin/warningMsg/list',
    AddHotArticleBtn = '/admin/pool/hot/article/addPoolHotArticle',
    OldHouseAdmin = 'oldHouseAdmin',
    CustomerDownload = '/admin/customer/download',
    TelCustomerToDispatchUser = 'telCustomerToDispatchUser'
}

export default Permissions
