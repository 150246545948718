import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import saleDialog from '../saleDialog/index.vue'

@Component({
    components: {
        saleDialog
    }
})
export default class ChengjiaoDealRecordAuditPage extends Vue {
    @Prop({ default () { return {} } })
    form!:any

    dataObj: any = {}
    page = 1
    pageSize = 20

    editItemObj = {
        visible: false,
        data: {}
    }

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    @Watch('form', { deep: true })
    formChange () {
        this.pageChange(1)
    }

    mounted () {
        this.getData()
    }

    editRecord (item:any) {
        let data = JSON.parse(JSON.stringify(item.Data))
        data = objectToUppercase(data)
        data.ExtData = {}
        data.ExtData.user_info = data.user_info
        console.log(44, data)
        data.Id = item.Id
        data.isZjs = item.CustomerAllInfo && item.CustomerAllInfo.type_array.includes('转介绍')
        this.editItemObj.data = data
        this.editItemObj.visible = true

        function objectToUppercase (obj:any) {
            if (Object.prototype.toString.call(obj).includes('Object')) {
                Object.keys(obj).map((v:any) => {
                    let old = v
                    v = v.split('')
                    for (let i = 0; i < v.length; i++) {
                        if (i === 0) {
                            v[i] = v[i].toString().toUpperCase()
                        }
                        if (v[i] === '_') {
                            v[i + 1] = v[i + 1].toString().toUpperCase()
                            v.splice(i, 1)
                        }
                    }
                    obj[v.join('')] = obj[old]
                })
                return obj
            }
        }
    }

    // 分页改变
    pageChange (page: number) {
        this.page = page
        this.getData()
    }

    // fetch
    async getData () {
        let form = this.__utils.index.copyObj(this.form)
        form.ctime = ''
        let data = Object.assign(form, {
            type: 2,
            page: this.page,
            pagesize: this.pageSize,

            time_start: this.form.ctime ? this.form.ctime[0] : '',
            time_end: this.form.ctime ? this.form.ctime[1] : ''
        })
        if (this.infoPos.dep) data.dep_id = ''
        if (this.infoPos.group) data.group_id = ''

        const res = await this.__apis.customer.getDailyWorkExamineList(data)

        res.data.list.forEach((v: any) => {
            v.Data = v.Data ? JSON.parse(v.Data) : []
            if (v.Data.ext_data === '[object Object],[object Object]') {
                v.Data.ext_data = ''
            }
            v.Data.ext_data = v.Data.ext_data ? JSON.parse(v.Data.ext_data) : []
            v.Data.pz_imgs = v.Data.pz_imgs ? JSON.parse(v.Data.pz_imgs) : []
            v.Data.imgs = Object.keys(v.Data.pz_imgs).map((a) => v.Data.pz_imgs[a]).filter(a => a)
            v.Data.user_info = v.Data.user_info ? JSON.parse(v.Data.user_info) : []
            v.Data.user_info.map((a:any) => {
                a.rate = Number(a.money) === 0 ? 0 + '%' : ((a.money / v.Data.sum_money) * 100).toFixed(2) + '%'
            })
            v.DuijieUserList.map((a:any) => {
                a.type = '渠道读取'
            })
            v.isEdit = (new Date().getTime() - new Date(v.Ctime.split(' ')[0]).getTime()) < 10 * (24 * 60 * 60 * 1000)
        })
        this.dataObj = res.data
        this.dataObj.show = true
    }
}
