import { Component, Prop, Vue } from 'vue-property-decorator'
import { __utils } from '@/util'

interface list {
    text: string
    time: string[]
}

@Component
export default class DateRangeSelectComponent extends Vue {
    @Prop({
        default () {
            return []
        },
        type: Array
    })
    value !: string[]
    @Prop({
        default: 'yyyy-MM-dd HH:mm:ss',
        type: String
    })
    valueFormat !: string
    @Prop({
        default () {
            return ['00:00:00', '23:59:59']
        },
        type: Array
    })
    defaultTime !: string[]

    list: list[] = [
        {
            text: '7天',
            time: __utils.index.formatTimeStartTimeEnd(__utils.index.getDaysAgo(6))
        }, {
            text: '30天',
            time: __utils.index.formatTimeStartTimeEnd(__utils.index.getDaysAgo(29))
        }, {
            text: '90天',
            time: __utils.index.formatTimeStartTimeEnd(__utils.index.getDaysAgo(89))
        }, {
            text: '半年',
            time: __utils.index.formatTimeStartTimeEnd(__utils.index.getDaysAgo(179))
        }, {
            text: '一年',
            time: __utils.index.formatTimeStartTimeEnd(__utils.index.getDaysAgo(364))
        }, {
            text: '全部',
            time: __utils.index.formatTimeStartTimeEnd(['2020-03-01', __utils.index.formatDate(new Date(), 'YYYY-MM-DD')])
        }
    ]

    dateValue: string[] = []

    pickDateValue = ''

    created () {
        this.dateValue = this.__utils.index.copyObj(this.value)
    }

    datePickerChange () {
        this.$emit('update:value', this.dateValue)
    }

    changeDate (item: list) {
        this.dateValue = item.time

        this.datePickerChange()
    }

    cmtItemActive (item: list) {
        if (this.dateValue) {
            return item.time[0] === this.dateValue[0] && item.time[1] === this.dateValue[1]
        } else {
            return false
        }
    }

    // 设置禁止选择的日期 最大时间跨度为1年
    disabledDate (c: string) {
        // 当前选中了一个时间 则判断跨度是否相隔一年以上
        if (this.pickDateValue) {
            const now = new Date(this.pickDateValue)
            const time = new Date(c).getTime()
            const prevYear = new Date(`${now.getFullYear() - 1}-${now.getMonth() + 1}-${now.getDate()}`).getTime()
            const nextYear = new Date(`${now.getFullYear() + 1}-${now.getMonth() + 1}-${now.getDate()}`).getTime()

            return time - prevYear < 0 || time - nextYear > 0
        }
    }

    onPick ({ maxDate, minDate }: {maxDate: string, minDate : string}) {
        // 选择完毕
        if (maxDate) {
            this.pickDateValue = ''
        } else {
            // 只选择第一个 限制第二个只能选择一年以内的时间
            this.pickDateValue = minDate
        }
    }
}
