import { Component, Prop, Vue } from 'vue-property-decorator'
import { __utils } from '@/util'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'
import { basicUserInfo } from '@/api/user'

export interface editItem {
    id?: number
}

type leader = {
    id: number
    leader_id: number
    name: string // 只做展示用
    start_time: string
    end_time: string
    status: 0 | 1
}

function get100YearsAgo () {
    return new Date(`${new Date().getFullYear() + 100}/01/01`)
}

@Component({
    components: {
        inputSelectSearch
    }
})
export default class EditComponent extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    show !: boolean

    @Prop({
        type: Object,
        default: {}
    })
    item !: editItem

    get
    isEdit () {
        return !!this.item.id
    }

    get
    deps () {
        return this.__store.state.user.infoPos.dep_arr
    }

    get
    validLeader () {
        return this.leader.filter(a => a.status === 1)
    }

    get
    cmtBtnSaveDisabled () {
        if (this.title.active === 0) {
            return !(this.group.dep && this.group.name && this.group.leader && this.group.time)
        } else {
            // 只能有一个空的结束时间
            let hasEndEmptyTime = false
            return !this.validLeader.every(a => {
                const res = a.start_time && (a.end_time || !hasEndEmptyTime) && a.name
                if (!a.end_time) hasEndEmptyTime = true

                return res
            }) || !this.validLeader.length
        }
    }

    title = {
        active: 0,
        data: ['战队信息']
    }

    group: {
        name: string
        dep: number
        leader: number
        time: string
        isStudy: boolean
    } = {
        name: '',
        dep: null as any,
        leader: null as any, // 搜索之后是 number
        time: __utils.index.formatDate(new Date(), 'YYYY-MM-DD'),
        isStudy: false
    }

    leader: leader[] = []

    async created () {
        if (this.isEdit) {
            this.title.data.push('队长信息')
        }

        await this.getData()

        if (!this.leader.length) {
            this.addLeader()
        }
    }

    async getData () {
        // 编辑时候 会获取数据
        if (!this.item.id) return

        const { data } = await this.__apis.system.getGroupAndLeaderList(this.item.id)

        const leader = data.leader_list.filter(a => a.leader_user_id === data.leader_user_id)[0]
        this.group.name = data.group_name
        this.group.dep = data.dep
        this.group.time = this.__utils.index.formatDate(new Date(data.start_time), 'YYYY-MM-DD')
        this.group.isStudy = !!data.is_pxb

        if (leader) {
            this.group.leader = leader.user_info.user_name as any // 这里做显示用，修改的时候 不会用到这个
        }

        // 需要排序 结束时间大的 排在上面
        data.leader_list.sort((a, b) => {
            return b.end_time - a.end_time
        })

        this.leader = data.leader_list.map(a => {
            return {
                id: a.id,
                name: a.user_info.user_name,
                leader_id: a.leader_user_id,
                start_time: this.__utils.index.formatDate(new Date(a.start_time), 'YYYY-MM-DD'),
                end_time: this.__utils.index.formatDate(new Date(a.end_time), 'YYYY-MM-DD'),
                status: 1
            }
        })
    }

    validLeaderDate () {
        const len = this.validLeader.length
        for (let i = 0; i < len; i++) {
            const l = this.validLeader[i]

            for (let j = i + 1; j < len; j++) {
                const l1 = this.validLeader[j]
                // 获取开始结束时间 结束时间有可能没有 设置为100年后
                const ls = new Date(l.start_time).getTime()
                const le = new Date(l.end_time).getTime() || get100YearsAgo().getTime()
                const l1s = new Date(l1.start_time).getTime()
                const l1e = new Date(l1.end_time).getTime() || get100YearsAgo().getTime()

                if (
                    // 不能存在两个相等的时间
                    (ls === l1s && le === l1e) ||
                    // 不能重合
                    (ls >= l1s && ls <= l1e) || (le >= l1s && le <= l1e)) {
                    this.$message.error('队长在任时间有误，请核查')
                    return false
                }
            }
        }

        return true
    }

    selectLeader (data: basicUserInfo, item: leader) {
        item.leader_id = data.Id
    }

    // 结束时间没有的为现任队长
    // cmtBtnSaveDisabled 保证了只会有一个队长结束时间没有
    getNowLeader () {
        for (let i = 0; i < this.validLeader.length; i++) {
            const a = this.validLeader[i]

            if (!a.end_time) return a
        }

        return undefined
    }

    async enter () {
        if (this.__utils.index.limitRepeatClick()) return

        try {
            // 新建战队
            if (!this.isEdit) {
                await this.__apis.system.createGroup({
                    group_name: this.group.name,
                    dep_id: this.group.dep,
                    is_pxb: this.group.isStudy ? 1 : 0,
                    start_time: new Date(this.group.time.replace(/-/g, '/')).getTime(),
                    leader_user_id: this.group.leader
                })
            } else {
                if (!this.item.id) return

                // 修改战队信息
                if (this.title.active === 0) {
                    await this.__apis.system.editGroup({
                        group_id: this.item.id,
                        group_name: this.group.name,
                        start_time: new Date(this.group.time.replace(/-/g, '/')).getTime(),
                        is_pxb: this.group.isStudy ? 1 : 0
                    })
                }

                // 修改队长信息
                if (this.title.active === 1) {
                    // 判断时间是否合理
                    if (!this.validLeaderDate()) return

                    // 设置现任队长时间为100年后
                    const leader = this.getNowLeader()
                    if (leader) {
                        leader.end_time = __utils.index.formatDate(get100YearsAgo(), 'YYYY-MM-DD')
                    }

                    await this.__apis.system.editGroupLeader({
                        group_id: this.item.id,
                        leader_list: this.leader.map(a => {
                            return {
                                id: a.id,
                                start_time: new Date(a.start_time.replace(/-/g, '/')).getTime(),
                                end_time: new Date(a.end_time.replace(/-/g, '/')).getTime(),
                                leader_user_id: a.leader_id,
                                status: a.status
                            }
                        })
                    })
                }
            }

            this.$message.success('保存成功')
            this.close()
            this.$emit('enter')
        } catch (e) {
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    // 关闭删除弹窗
    // 因为content点击需要stop 这会阻止弹窗关闭，所以需要模拟一下点击body
    stop1 (e: Event) {
        document.body.click()
        e.stopPropagation()
    }

    addLeader () {
        this.leader.unshift({
            id: 0,
            leader_id: 0,
            name: '',
            start_time: '',
            end_time: '',
            status: 1
        })
    }

    removeLeader (index: number) {
        const leader = this.leader[index]

        // id为0的 删除
        if (leader.id === 0) {
            this.leader.splice(index, 1)
            return
        }

        leader.status = 0
    }

    close () {
        this.$emit('update:show', false)
    }
}
