import { Component, Mixins } from 'vue-property-decorator'
import InputHouseSearch from '@/components/InputHouseSearch/index.vue'
import CustomerMixin from '../mixin'

@Component({
    components: {
        InputHouseSearch
    }
})
export default class CustomerMyPage extends Mixins(CustomerMixin) {
    customerSelectCurrent = 0
    searchVisible = true
    form: any = {
        ctime: '',
        last_follow_time: '',
        name: '',
        mobile: '',
        house: '',
        tag: '',
        impt_group: '',
        dep: '',
        group: '',
        user_id: '',
        intention_area: '',
        keywords: '',
        mark: '',
        renling_type: '',
        source_type: '',
        dai_kan_count: '',
        es_comparison_type: ''
    }
    dataObj = {}
    page = 1
    pageSize = 50
    total = 0
    sysTags2: {
        [index: number]: any
    } = {}
    sysTags3: {
        [index: number]: any
    } = {}
    dialogEdit = false
    editForm = {
        tag: '',
        impt_group: ''
    }
    formLabelWidth = '110px'
    sortType = ''
    sortMode = ''
    addCustomerDialog = false
    addCustomerForm = {
        customer_name: '',
        customer_tel: '',
        house_name: '',
        house_id: '',
        quyu: [],
        link_id: ''
    }
    loading=false

    customerTypeList = [
        {
            label: '转介绍',
            value: '0'
        }
        // {
        //     label: '高德来电',
        //     value: '20977019'
        // }
    ]

    get
    infoPos () {
        let infoPos = this.__store.state.user.infoPos
        this.form.dep = infoPos.dep
        this.form.group = infoPos.group
        this.form.user_id = infoPos.fxs
        return infoPos
    }

    mounted () {
        this.sysTags2 = this.sysTagsIn()
        this.sysTags3 = this.sysTagsIn()
        this.getData()
    }

    // 客户来源筛选
    changeFromType (value: string) {
        if (this.form.renling_type === value) {
            this.form.renling_type = ''
        } else {
            this.form.renling_type = value
        }
    }

    // 客户级别筛选
    changeLevel (value: string) {
        if (this.form.tag === value) {
            this.form.tag = ''
        } else {
            this.form.tag = value
        }
    }

    // 拷贝系统标签
    sysTagsIn () {
        return this.__store.state.user.sysTags && this.__utils.index.copyObj(this.__store.state.user.sysTags)
    }

    // 系统标签多选
    selectHandle2 (index: number, obj: any) {
        // @ts-ignore
        this.$set(this[obj][index], 'active', !this[obj][index].active)
    }

    // 单选
    // @ts-ignore
    selectHandle (index: number, type: string) {
        if (this.customerSelectCurrent !== index) {
            // @ts-ignore
            this[type] = index
            this.page = 1
            this.getData()
        }
    }

    // fetch
    async getData () {
        this.loading = true
        let sys = []
        if (this.sysTags2) {
            for (let key in this.sysTags2) {
                if (this.sysTags2[key].active) {
                    sys.push(this.sysTags2[key].value)
                }
            }
        }
        let data:any = {
            type: [0, 3].includes(this.customerSelectCurrent) ? 'my' : (this.customerSelectCurrent === 1 ? 'abc_overtime' : 'follow_overtime'),
            sys_tag: sys.join(','),
            ctime_begin: this.form.ctime ? this.form.ctime[0] : '',
            ctime_stop: this.form.ctime ? this.form.ctime[1] : '',

            last_follow_time_begin: this.form.last_follow_time ? this.form.last_follow_time[0] : '',
            last_follow_time_stop: this.form.last_follow_time ? this.form.last_follow_time[1] : '',

            sort_field: this.sortType,
            sort_mode: this.sortMode,

            dai_kan_count: +this.form.dai_kan_count || undefined,
            es_comparison_type: this.form.es_comparison_type || undefined,

            page: this.page,
            pagesize: this.pageSize
        }
        let form = this.__utils.index.copyObj(this.form)
        data = Object.assign(form, {
            dep: '',
            group: '',
            ctime: '',
            last_follow_time: '',
            ...data
        })

        if (this.customerSelectCurrent === 3) {
            data.impt_group = 2
        }

        const res = await this.__apis.customer.getCustomerList(data)

        this.dataObj = res.data
        this.total = res.data.res.total
        this.loading = false
    }

    // 搜索
    clickSearch () {
        this.page = 1
        this.getData()
    }

    // 搜索面板
    clickSearchVisible () {
        this.searchVisible = !this.searchVisible
    }

    // 清空筛选
    resetFrom (formName: string) {
        const form = this.$refs[formName] as any
        form.resetFields()

        for (let key in this.sysTags2) {
            this.$set(this.sysTags2[key], 'active', false)
        }
        this.form.renling_type = ''
        this.form.es_comparison_type = ''
        this.page = 1
        this.getData()
    }

    // 添加新客户
    addCustomerVisible () {
        this.addCustomerDialog = true
    }

    selectHouse (obj: any) {
        this.addCustomerForm.house_id = obj.id
    }

    addCustomerHide () {
        const forms = this.$refs['addCustomerForm'] as any
        forms && forms.resetFields()
        this.addCustomerDialog = false
    }

    // 保存添加
    async saveNewCustomer () {
        if (this.__utils.index.limitRepeatClick()) return
        try {
            const addCustomerForm = this.addCustomerForm
            let data = {
                name: addCustomerForm.customer_name,
                mobile: addCustomerForm.customer_tel,
                customer_house: addCustomerForm.house_id
            } as any

            let isValidRes = this.__utils.index.isObjAttrValid(data)
            if (isValidRes) {
                this.$notify({
                    title: '警告',
                    message: isValidRes + '不能为空！',
                    type: 'warning'
                })
                return
            }

            if (addCustomerForm.link_id && addCustomerForm.link_id !== '0') {
                data.link_id = addCustomerForm.link_id
            }

            await this.__apis.customer.createCustomer(data)

            this.$notify({
                title: '成功',
                message: '保存成功！',
                type: 'success'
            })
            this.addCustomerHide()
            this.getData()
        } catch (e) {
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }
}
