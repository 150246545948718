import { BaseRes, ListReq, ListRes, php, java, ListRes2 } from '@/api/index'
import { userInfo6 } from '@/api/customer'
import { userInfo7 } from './system'

interface tableFilterReq extends ListReq {
    common_dep_id?: string
    group_id: string
    employee_id?: string
    dep_id?: string
}

interface fxsWalletStatisticsReq {
    time_start: string
    time_end: string
}

interface fxsWalletStatisticsRes {
    money_info: {
        money: number
        interest_money: number
        interest_rate: number
    }
    char_list: {
        save_money: {
            date: string
            value: number
        }[]
        cash_out_money: fxsWalletStatisticsRes['char_list']['save_money']
    }
}

interface fxsWalletMonthRecordReq extends tableFilterReq {
    time_start: string
    time_end: string
}

interface fxsWalletMonthRecordRes extends ListRes<{
    employee_id: number
    income_money: number
    pay_money: number
    interest_money: number
    wallet_money: number
    month: string
    user_info: userInfo6 & {
        GroupName: string
    }
}>{}

interface getWalletUnSettlementRecordListReq extends tableFilterReq {
    time_start: string
    time_end: string
    type: string
}

interface getWalletUnSettlementRecordListRes extends ListRes<{
    Id: number
    EmployeeId: number
    DepId: number
    GroupId: number
    Money: number
    Type: number
    Remark: string
    Ctime: string
    Mtime: string
    Tax: number
    ExpenseMoney: number
    ExpenseDeductMoney: number
    RealMoney: number
    SalaryMoney: number
    UserInfo: userInfo6 & {
        GroupName: string
    }
}>{
    statistic_res: {
        total_money: string
    }
}

interface fxsWalletRecordReq extends ListReq {
    common_dep_id?: string
    employee_id: string
    group_id?: string
    time_start: string
    time_end: string
    type: string
    remark?: string
}

interface fxsWalletRecordRes extends ListRes<{
    employee_id: number
    ctime: string
    money: number
    after_money: number
    before_money: number
    remark: string
    type: number
    wallet_id: number
    dep_id: number
    group_id: number
    id: number
    user_info: userInfo6 & {
        GroupName: string
    }
}>{
    statistic_res: {
        money: number
    }
}

interface groupWalletStatisticsReq {
    dep_id?: number | string
    group_id?: number
    time_start: string
    time_end: string
}

interface groupWalletStatisticsRes {
    money_info: {
        money_list: {
            money: number
            group_id: number
            group_name: string
        }[]
        total_money: number
    }
    char_list: {
        [index: string]: {
            date: string
            value: number
        }[]
    }
}

interface groupWalletMonthRecordESReq extends tableFilterReq {
    time_start: string
    time_end: string
}

interface groupWalletMonthRecordESRes extends ListRes<{
    dep_id: number
    dep_name: string
    group_id: number
    group_name: string
    money: number
    month: string
}>{}

interface getGroupWalletRecordListReq extends tableFilterReq {
    type: string
    time_start: string
    time_end: string
}

interface getGroupWalletRecordListRes extends ListRes<{
    Id: number
    DepId: number
    GroupId: number
    Money: number
    Type: number
    Remark: string
    Ctime: string
    Mtime: string
    DepName: string
    GroupName: string
}>{}

interface walletCashOutStatisticsReq {
    common_dep_id: number | string
    time_start: string
    time_end: string
}

interface walletCashOutStatisticsRes {
    money_info: {
        money_list: {
            money: number
            group_id: number
            group_name: string
        }[]
        last_money: number
        last_time_start: number
        last_time_end: number
        compare_last_rate: string | number
    }
    char_list: {
        [index: string]: {
            date: string
            value: number
        }[]
    }
}

interface getWalletCashOutRecordListEsReq extends tableFilterReq {
    time_start: string
    time_end: string
    status: string
}

interface getWalletCashOutRecordListEsRes extends ListRes<{
    ctime: string
    money: number
    remark: string
    employee_id: number
    dep_id: number
    group_id: number
    status: number
    reject_reason: string
    send_time: null | string
    wallet_money: number
    id: number
    user_info: fxsWalletMonthRecordRes['list'][0]['user_info']
}>{}

interface getWalletCashOutRecordReq extends tableFilterReq {
    send_time_start: string
    send_time_end: string
    time_start: string
    time_end: string
    status: string
}

interface getWalletCashOutRecordRes extends ListRes<getWalletUnSettlementRecordListRes['list'][0] & {
    RejectReason: string
    SendTime: null | string
    Status: number
}> {
    statistic: {
        ExpenseDeductMoney: string
        ExpenseMoney: string
        Money: string
        RealMoney: string
        Tax: string
        SalaryMoney: string
    }
}

interface examineCashOutReq {
    id: number
    status: number
    reject_reason?: string
}

interface getFinanceSendRecordListReq extends tableFilterReq {
    action_time: string
    status: string
    type: string
}

interface getFinanceSendRecordListRes extends ListRes<{
    Id: number
    EmployeeId: number
    DepId: number
    GroupId: number
    MustMoney: number
    DeductMoney: number
    FinalMoney: number
    Status: number
    Type: number
    Remark: string
    ActionTime: string
    Ctime: string
    Mtime: string
    UserInfo: userInfo6 & {
        UserId: number
        GroupName: string
    }
}>{
    statistic_res: {
        MustMoney: string
        DeductMoney: string
        FinalMoney: string
    }
}

interface saveFinanceSendRecordReq {
    employee_id?: string
    action_time?: string
    type: string
    must_money: string
    deduct_money: string
    remark: string
    id?: string
}

interface saveCashOutReq {
    id: number
    tax: number
    expense_deduct_money: number
}

interface getUnSendBonusListGroupByUserReq extends tableFilterReq {
    time_start: string
    time_end: string
}

interface getUnSendBonusListGroupByUserRes extends ListRes<{
    'doc_count': number
    'unsend_bonus_money': number
    'unsend_bonus_interest_money': number
    'dep_id': number
    'group_id': number
    'employee_id': number
    'user_info': userInfo6 & {
        GroupName: string
    }
}>{
    statistic_res?: {
        'res_count': number,
        'unsend_bonus_money': number,
        'unsend_bonus_interest_money': number
    }
}

interface getUnSendBonusRecordListReq extends ListReq {
    employee_id: number
    time_start: string
    time_end: string
}

interface getUnSendBonusRecordListRes extends ListRes<{
    ActionTime: string
    Money: number
    UserInfo: userInfo6 & {
        GroupName: string
    }
}>{
    statistic_res: string
}

interface getUnSendBonusInterestRecordListRes extends ListRes<{
    ActionTime: string
    InterestMoney: number
    UserInfo: userInfo6 & {
        GroupName: string
    }
}>{
    statistic_res: string
}

interface groupWalletAllListRes {
    dep_name: string
    group_name: string
    sum_money: number
    send_money: number
    group_id: number
}

interface doSendGroupWalletMoneyReq {
    group_id: number
    remark: string
    money: number
}

interface groupWalletDetailListReq extends ListReq {
    group_id: number
    time_start: string
    time_end: string
}

interface groupWalletDetailListRes extends ListRes<{
    group_name: string
    dep_name: string
    ctime: string
    remark: string
    money: number
}>{}

interface getSurplusMoneyListReq extends ListReq {
    start_time: string
    end_time: string
    dep_id: number
    group_id: number
    employee_id: number
}

interface getSurplusMoneyListRes extends ListRes<{
    user_info: userInfo7
    user_id: number
    income_money: number
    expenditure_money: number
}>{
    statistic_res: {
        expenditure_money_total: number
        income_money_total: number
    }
}

interface getSaleManNotArriveListRes {
    dep: number
    group: number
    money: string
    remarks: string
    username: string
    date: number
    type: number
}

interface rechargeFinanceRecordQueryReq extends ListReq{
    dep_id?: number;
    group_id?: number;
    employee_name?: string;
}

interface rechargeFinanceRecordQueryRes{
    dep_id: number
    employee_id: number
    employee_name: string
    group_id: number
    in_finance_money: number
    out_finance_money: number
}

interface addFinanceOutRecordReq{
    month: string;
    employee_id: number;
    money: number;
    remark: string;
}

interface financeOutRecordQueryReq extends ListReq{
    employee_id: number;
}

interface financeOutRecordQueryRes {
    action_user_name: string
    ctime: number
    dep_id: number
    employee_name: string
    group_id: number
    money: number
    month: string
    remark: string
}

export default class ApiWallet {
    // 查询个人未到账明细
    getSaleManNotArriveList (employee_id: number): BaseRes<getSaleManNotArriveListRes[]> {
        return java.post('/admin/sale/man/not/arrive/statistics/getSaleManNotArriveList', {
            employee_id
        })
    }

    // 收支盈余列表
    getSurplusMoneyList (data: getSurplusMoneyListReq): BaseRes<getSurplusMoneyListRes> {
        return java.post('/admin/wallet/record/getSurplusMoneyList', data)
    }

    // 获取财务钱包管理战队钱包详情
    groupWalletDetailList (data: groupWalletDetailListReq): BaseRes<groupWalletDetailListRes> {
        return java.post('/admin/wallet/manage/groupWalletDetailList', data)
    }

    // 财务钱包管理战队钱包发放
    doSendGroupWalletMoney (data: doSendGroupWalletMoneyReq) {
        return java.post('/admin/wallet/manage/doSendGroupWalletMoney', data)
    }

    // 获取财务钱包管理战队钱包列表
    groupWalletAllList (): BaseRes<groupWalletAllListRes[]> {
        return java.post('/admin/wallet/manage/groupWalletAllList', {})
    }

    // 获取未发放提成明细
    getUnSendBonusInterestRecordList (data: getUnSendBonusRecordListReq): BaseRes<getUnSendBonusInterestRecordListRes> {
        return php.post('', {
            action_name: 'getUnSendBonusInterestRecordList',
            ...data
        })
    }

    // 获取未发放提成明细
    getUnSendBonusRecordList (data: getUnSendBonusRecordListReq): BaseRes<getUnSendBonusRecordListRes> {
        return php.post('', {
            action_name: 'getUnSendBonusRecordList',
            ...data
        })
    }

    // 获取未发提成列表
    getUnSendBonusListGroupByUser (data: getUnSendBonusListGroupByUserReq): BaseRes<getUnSendBonusListGroupByUserRes> {
        return php.post('', {
            action_name: 'getUnSendBonusListGroupByUser',
            ...data
        })
    }

    // 删除金额发放记录
    deleteSendRecord (id: number) {
        return php.post('', {
            action_name: 'deleteSendRecord',
            id
        })
    }

    // 修改个税
    saveCashOut (data: saveCashOutReq) {
        return php.post('', {
            action_name: 'saveCashOut',
            ...data
        })
    }

    // 个人已到账_图表
    fxsWalletStatistics (data: fxsWalletStatisticsReq): BaseRes<fxsWalletStatisticsRes> {
        return php.post('', {
            action_name: 'fxsWalletStatistics',
            ...data
        })
    }

    // 个人已到账_记录
    fxsWalletMonthRecord (data: fxsWalletMonthRecordReq): BaseRes<fxsWalletMonthRecordRes> {
        return php.post('', {
            action_name: 'fxsWalletMonthRecord',
            ...data
        })
    }

    // 个人未到账记录
    getWalletUnSettlementRecordList (data: getWalletUnSettlementRecordListReq): BaseRes<getWalletUnSettlementRecordListRes> {
        return php.post('', {
            action_name: 'getWalletUnSettlementRecordList',
            ...data
        })
    }

    // 个人钱包详情
    fxsWalletRecord (data: fxsWalletRecordReq): BaseRes<fxsWalletRecordRes> {
        return php.post('', {
            action_name: 'fxsWalletRecord',
            ...data
        })
    }

    // 战队钱包_图表
    groupWalletStatistics (data: groupWalletStatisticsReq): BaseRes<groupWalletStatisticsRes> {
        return php.post('', {
            action_name: 'groupWalletStatistics',
            ...data
        })
    }

    // 战队钱包表格
    groupWalletMonthRecordES (data: groupWalletMonthRecordESReq): BaseRes<groupWalletMonthRecordESRes> {
        return php.post('', {
            action_name: 'groupWalletMonthRecordES',
            ...data
        })
    }

    // 战队钱包详情
    getGroupWalletRecordList (data: getGroupWalletRecordListReq): BaseRes<getGroupWalletRecordListRes> {
        return php.post('', {
            action_name: 'getGroupWalletRecordList',
            ...data
        })
    }

    // 提现图表
    walletCashOutStatistics (data: walletCashOutStatisticsReq): BaseRes<walletCashOutStatisticsRes> {
        return php.post('', {
            action_name: 'walletCashOutStatistics',
            ...data
        })
    }

    // 提现记录表格
    getWalletCashOutRecordListEs (data: getWalletCashOutRecordListEsReq): BaseRes<getWalletCashOutRecordListEsRes> {
        return php.post('', {
            action_name: 'getWalletCashOutRecordListEs',
            ...data
        })
    }

    // 获取提现审核记录
    getWalletCashOutRecord (data: getWalletCashOutRecordReq): BaseRes<getWalletCashOutRecordRes> {
        return php.post('', {
            action_name: 'getWalletCashOutRecord',
            ...data
        })
    }

    // 审核提现记录
    examineCashOut (data: examineCashOutReq) {
        return php.post('', {
            action_name: 'examineCashOut',
            ...data
        })
    }

    // 获取金额发放列表
    getFinanceSendRecordList (data: getFinanceSendRecordListReq): BaseRes<getFinanceSendRecordListRes> {
        return php.post('', {
            action_name: 'getFinanceSendRecordList',
            ...data
        })
    }

    // 金额发放
    doSendMoney (id: number) {
        return php.post('', {
            action_name: 'doSendMoney',
            id
        })
    }

    // 新增或修改金额发放记录
    saveFinanceSendRecord (data: saveFinanceSendRecordReq) {
        return php.post('', {
            action_name: 'saveFinanceSendRecord',
            ...data
        })
    }

    // 充值余额记录
    rechargeFinanceRecordQuery (data: rechargeFinanceRecordQueryReq):BaseRes<ListRes2<rechargeFinanceRecordQueryRes>> {
        return java.post('/admin/finance/manage/rechargeFinanceRecordQuery', data)
    }

    // 添加充值卡发放薪资记录
    addFinanceOutRecord (data: addFinanceOutRecordReq) {
        return java.post('/admin/finance/out/addFinanceOutRecord', data)
    }

    // 查询充值卡发放薪资记录
    financeOutRecordQuery (data: financeOutRecordQueryReq):BaseRes<ListRes2<financeOutRecordQueryRes>> {
        return java.post('/admin/finance/out/financeOutRecordQuery', data)
    }
}
