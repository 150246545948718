import ApiUser from './user'
import ApiActivity from './activity'
import ApiBusiness from './business'
import ApiFinancial from './financial'
import ApiGzManage from './gzManage'
import ApiUtils from './utils'
import ApiHome from './home'
import ApiCustomer from './customer'
import ApiCommunity from '@/api/community'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { __localStorage, storageName } from '@/localStorage'
import element from 'element-ui'
import ApiSaleMan from '@/api/saleman'
import ApiKnowLedge from './knowlegde'
import ApiChannelManage from '@/api/channelManage'
import ApiPhoneRecord from '@/api/phoneRecord'
import { installPlugin } from '@/util/install'
import ApiWallet from './wallet'
import ApiReportManage from '@/api/reportManage'
import ApiSystem from '@/api/system'
import APIGrow from '@/api/grow'
import ApiMarket from '@/api/market'
import ApiChongZhi from '@/api/chongzhi'
import ApiWxFriends from '@/api/wxFriends'
import { apiConfig } from '@/api/config'
import login from 'qm-plugin-admin-login'
import { EventBus, EventNames } from '@/util/bus'

declare module 'vue/types/vue' {

    interface Vue {
        __apis: {
            user: ApiUser
            activity: ApiActivity
            business: ApiBusiness
            financial: ApiFinancial
            gzManage: ApiGzManage
            utils: ApiUtils
            home: ApiHome
            customer: ApiCustomer
            community: ApiCommunity
            saleman: ApiSaleMan
            knowledge: ApiKnowLedge
            channelManage: ApiChannelManage
            phoneRecord: ApiPhoneRecord
            wallet: ApiWallet
            reportManage: ApiReportManage
            system: ApiSystem
            grow: APIGrow
            market: ApiMarket
            chongzhiWallet: ApiChongZhi
            wxFriends: ApiWxFriends
        }
    }
}

export const __apis = {
    user: new ApiUser(),
    activity: new ApiActivity(),
    business: new ApiBusiness(),
    financial: new ApiFinancial(),
    gzManage: new ApiGzManage(),
    utils: new ApiUtils(),
    home: new ApiHome(),
    customer: new ApiCustomer(),
    community: new ApiCommunity(),
    saleman: new ApiSaleMan(),
    knowledge: new ApiKnowLedge(),
    channelManage: new ApiChannelManage(),
    phoneRecord: new ApiPhoneRecord(),
    wallet: new ApiWallet(),
    reportManage: new ApiReportManage(),
    system: new ApiSystem(),
    grow: new APIGrow(),
    market: new ApiMarket(),
    chongzhiWallet: new ApiChongZhi(),
    wxFriends: new ApiWxFriends()
}

export type ListRes <T> = {
    list: T[]
    page: number
    total: number
    total_page: number
}

export type ListRes2 <T> = {
    rows: T[]
    page: number
    total: number
    total_page: number
}

export type ListReq = {
    page: number
    pagesize?: number
}

type Res<T> = {
    data: T
    errCode: number
}

export type BaseRes <T> = Promise<Res<T>>

export type PubbyListRes <T> = Promise<{
    rows: T[]
    total: number
    total_found: number
}>

export type BaseSubmitRes = Promise<{
    errCode: number,
    errMsg: string
}>

export default {
    install: installPlugin('__apis', __apis)
}

function logout () {
    EventBus.$emit(EventNames.LOGOUT)
    login.logout()

    // 返回一个不决策的promise，这样登录错误之后的其他代码就不会执行
    return new Promise(() => {})
}

function setHeader (config: AxiosRequestConfig) {
    config.headers['ac-token'] = __localStorage.getLocalStorage(storageName.TOKEN)
    return config
}

function sendErrorMessage (response: AxiosResponse, { status, code, msg }: { status?: number; code?: number; msg?: string } = {}) {
    const httpCode = status ? `httpCode: ${status}` : ''
    const errCode = code ? `errCode: ${code}` : ''
    const errMsg = msg ? `errMsg: ${msg}` : ''

    element.Notification.error(`发生错误：${httpCode} ${errCode} ${errMsg}`)
    return Promise.reject(response)
}

// 生成axios实例
function genAxios (config: AxiosRequestConfig) {
    const ins = axios.create({
        timeout: 60000,
        ...config
    })

    ins.interceptors.request.use((config) => {
        config = setHeader(config)

        return config
    }, (error) => {
        return sendErrorMessage(error, { status: error.status })
    })

    ins.interceptors.response.use((response) => {
        // 提示异常信息
        if (![200, 304].includes(response.status)) {
            return sendErrorMessage(response, {
                status: response.status
            })
        }

        return response
    }, (response) => {
        return sendErrorMessage(response, {
            msg: response.message
        })
    })

    return ins
}

export const php = genAxios({
    baseURL: apiConfig.apiUrl
})

php.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    config.data && filterData(config.data)

    config.data = Object.keys(config.data).map((name: string) => {
        return `${name}=${encodeURIComponent(config.data[name])}`
    }).join('&')

    return config
})

export const java = genAxios({
    baseURL: apiConfig.jUrl
});

[java, php].forEach(a => {
    a.interceptors.response.use((response) => {
        const { data, config } = response
        const errCode = data.errCode

        if (response.request.responseType === 'blob') {
            return response
        }

        if (errCode !== 0) {
            if ([403, -10403].includes(errCode)) {
                return logout()
            }

            if (config.data.indexOf('ignore') === -1) {
                return sendErrorMessage(response, { msg: data.errMsg, code: data.errCode })
            }
        }

        return data
    })
})

export const ppUpload = genAxios({
    baseURL: apiConfig.uploadAction
})

export const pp = genAxios({
    baseURL: apiConfig.ppApiUrl
});

[pp, java, ppUpload].forEach(a => {
    a.interceptors.request.use((config) => {
        config.data && filterData(config.data)

        return config
    })
});

[pp, ppUpload].forEach(a => {
    a.interceptors.response.use((response) => {
        const { data, config } = response

        const ppUploadUrl = `${config.baseURL}${config.url}`
        if (ppUploadUrl && ppUploadUrl.includes('upload')) {
            return data
        }

        const ignore = config.data.indexOf('ignore') !== -1

        if (
            data.errcode &&
            typeof data.errcode === 'number' &&
            data.errcode > 0 &&
            !ignore
        ) {
            if (data.errcode >= 1000 && data.errcode < 2000) {
                return logout()
            }

            return sendErrorMessage(response, { msg: data.errmsg })
        }

        return data
    })
})

function filterData (data: any) {
    if (!data['__ignore_filter_value']) {
        for (let k in data) {
            if (['', undefined, null, NaN].includes(data[k])) {
                delete data[k]
            }
        }
    }

    delete data['__ignore_filter_value']
}
