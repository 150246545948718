import { Component, Prop, Vue } from 'vue-property-decorator'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'
import { searchHouseRes } from '@/api/utils'

@Component({
    components: {
        InputSelectSearch
    }
})
export default class OcpcSaveDialogComponent extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    visible!: boolean

    @Prop({
        type: Object,
        default: () => {}
    })
    data!: {
        id: number
        ocpcName: string
        houseId: number
        houseName: string
        link: string
    }

    rules = {
        ocpcName: [
            { type: 'string', required: true, message: '请输入名称', trigger: 'change' }
        ],
        houseName: [
            { type: 'string', required: true, message: '请输入选择楼盘', trigger: 'change' }
        ],
        link: [
            { type: 'string', required: true, message: '请输入链接', trigger: 'change' }
        ]
    }

    get
    title () {
        return this.data.id ? '编辑广告' : '添加广告'
    }

    selectHouse (a: searchHouseRes) {
        this.data.houseId = a.id
        this.data.houseName = a.label
    }

    check () {
        const form = this.$refs['form'] as any
        form.validate(async (valid: boolean) => {
            if (valid) {
                this.save()
            }
        })
    }

    async save () {
        if (this.__utils.index.limitRepeatClick()) return
        try {
            const data = this.data
            await this.__apis.market.saveOcpcBindHouseRecord({
                id: data.id || undefined,
                house_id: data.houseId,
                ocpc_name: data.ocpcName,
                link: data.link
            })
            this.$message.success('提交成功！')
            this.$emit('save')
            this.close()
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    close () {
        this.$emit('update:visible', false)
    }
}
