import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'

export interface TableItem {
    userId: number
    name: string
    title: string
    nickName: string
    avatar: string
    seeTime: string
}

@Component({
    components: {}
})
export default class WxFriendsCircleSeeUserPage extends Vue {
    tableData: Table<TableItem> = {
        loading: false,
        data: [],
        page: 1,
        pageSize: 50,
        total: 0
    }

    created () {
        this.getData()
    }

    async getData () {
        try {
            this.tableData.loading = true
            const query = this.$route.query
            const { data } = await this.__apis.wxFriends.getWeChatMomentsPreviewList({
                pagesize: this.tableData.pageSize,
                page: this.tableData.page,

                article_id: query.article_id ? +query.article_id : undefined
            })

            this.tableData.data = data.list.map(a => {
                return {
                    userId: a.user_id,
                    name: a.article_user_name,
                    title: a.article_title,
                    nickName: a.user_nickname,
                    avatar: a.user_head_img_url,
                    seeTime: this.__utils.index.formatDate(new Date(a.preview_time), 'YYYY-MM-DD HH:mm:ss')
                }
            })
            this.tableData.total = data.total
        } finally {
            this.tableData.loading = false
        }
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getData()
    }
}
