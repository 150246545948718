import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SuccessDetailSaleChangeRemark extends Vue {
    @Prop({
        default () {
            return false
        },
        type: Boolean
    })
    show !: boolean

    get
    cmtBtnSaveDisabled () {
        return this.remark.trim() === ''
    }

    remark = ''

    save () {
        this.$emit('save', this.remark)
        this.remark = ''
        this.closeSelf()
    }

    closeSelf () {
        this.$emit('update:show', false)
    }
}
