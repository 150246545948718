import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import dateRangeSelectComponent from '@/components/wallet/date-range-select/index.vue'
import FilterComponent from '@/components/filters/filter/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'
import InputSelectComponent from '@/components/filters/inputSelect/index.vue'

interface TableItem {
    customer_type: number
    transfer_user_name: string
    transferred_user_name: string
    transfer_users_id: number[]
    customer_phone: string
    transfer_groups_id: number[]
    transferred_group_id: number
    transfer_group_id: number
    transfer_user_id: number
    ctime: string
    id: number
    customer_name: string
    customer_id: number
    transferred_user_id: number
    transfer_group_name?: string
    transferred_group_name?: string
}

interface Filter {
    'customer_name'?: string
    'customer_id'?: number
    'customer_phone'?: string
    'transfer_user_id'?: number
    'transferred_user_id'?: number
    'start_time'?: string
    'end_time'?: string
    'customer_type'?: string
    'ctime'?: string[]
}

@Component({
    components: {
        FilterComponent,
        dateRangeSelectComponent,
        SelectComponent,
        InputDateRangeComponent,
        FilterBusinessSelect,
        InputSelectComponent
    }
})
export default class ZhuanJiaoRecorePage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        total: 0
    }
    filter:Filter = {}

    created () {
        this.getData()
    }

    async getData () {
        let { data } = await this.__apis.customer.getTransferLogList({
            ...this.filter,
            page: this.tableData.page,
            pagesize: this.tableData.pageSize
        })
        this.tableData.total = data.total
        this.tableData.data = data.list.map(v => {
            return {
                ...v,
                ctime: v.ctime !== '0' ? this.__utils.index.formatDate(this.__utils.index.offsetDate(new Date(v.ctime)), 'YYYY-MM-DD HH:mm:ss') : '',
                transfer_group_name: this.__utils.index.getGroupInfoById(v.transfer_group_id).name,
                transferred_group_name: this.__utils.index.getGroupInfoById(v.transferred_group_id).name
            }
        })
    }

    // 搜索渠道
    searchQuDao (text: string, cb: Function) {
        let list = [
            { label: '搜索客户', value: 1 },
            { label: '信息流', value: 2 },
            { label: '电话客户', value: 3 }
        ]
        cb(list.filter((v) => v.label.includes(text)))
    }

    // 分页size修改
    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getData()
    }

    // 分页修改
    tableCurrentChange (page: number) {
        this.tableData.page = page
        this.getData()
    }

    // 筛选变化
    filterChange (item: Filter) {
        console.log('filter', item)
        Object.keys(item as Filter).map((v) => {
            if (v === 'ctime') {
                item.start_time = item['ctime'] && item['ctime'][0]
                item.end_time = item['ctime'] && item['ctime'][1]
                delete item[v]
            }
        })
        this.filter = item
        this.tableData.page = 1
        this.getData()
    }

    toDetail (customerId: string) {
        let { href } = this.$router.resolve({ name: '/customer/detail', params: { id: customerId } })
        window.open(href, '_blank')
    }
}
