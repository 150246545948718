import { Component, Vue, Prop } from 'vue-property-decorator'
import { EventBus, EventNames, filterId } from '@/util/bus'

@Component
export default class FiltersCheck extends Vue {
    value = ''
    visible = false
    isEnter = false
    id = 0

    @Prop({
        type: String,
        default: ''
    })
    title !: string

    @Prop({
        type: String,
        default: ''
    })
    propName !: string

    @Prop({
        type: Array,
        default () {
            return []
        }
    })
    dataSource !: []

    @Prop({
        type: String,
        default: ''
    })
    componentId !: string

    get
    cmtData () {
        return [{
            label: '全部',
            value: ''
        }].concat(this.dataSource)
    }

    created () {
        this.id = filterId.value
        filterId.value++
        EventBus.$on(EventNames.clearFilter, this.clear)
    }

    destroyed () {
        EventBus.$off(EventNames.clearFilter, this.clear)
    }

    // 这条筛选被清空
    clear (id: number) {
        if (this.id === id) {
            this.value = ''
            this.isEnter = false
        }
    }

    cancel () {
        this.visible = false
    }

    enter () {
        const label = this.cmtData.filter(a => a.value === this.value)[0].label

        // 这里需要通知筛选词条组件进行更新
        EventBus.$emit(EventNames.filterChange + this.componentId, {
            title: this.title + '：',
            content: label,
            id: this.id,
            value: {
                [this.propName]: this.value
            }
        })

        this.cancel()
        this.isEnter = true
    }
}
