import { Component, Vue } from 'vue-property-decorator'
import mixin from '../mixin'

@Component({
    mixins: [mixin]
})
export default class CustomerContactPage extends Vue {
    searchVisible = true
    classCurrent = -1
    bqUserCurrent = -1
    form = {
        ctime: '',
        last_follow_time: '',
        name: '',
        mobile: '',
        house: '',
        tag: '',
        search_key: ''
    }
    dataObj: any = {}
    page = 1
    pageSize = 50
    total = 0
    sysTags3 = {}
    dialogEdit = false
    editForm = {
        tag: '',
        impt_group: ''
    }
    formLabelWidth = '110px'
    // txl
    editTxlForm: any = {
        id: '',
        remark_name: '',
        note: '',
        group_id: '',
        user_tags: ''
    }
    dialogTxlEdit = false
    editTxlIndex = -1
    sortType = ''
    SysTags = ''

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    mounted () {
        this.sysTags3 = this.sysTagsIn()
        this.getData()
    }

    // 拷贝系统标签
    sysTagsIn () {
        return this.__store.state.user.sysTags && this.__utils.index.copyObj(this.__store.state.user.sysTags)
    }

    selectHandle (index: number, type: string) {
        // @ts-ignore
        this[type] = this[type] === index ? null : index
    }

    async getData () {
        let sys = []
        if (this.dataObj.init_data) {
            for (let key in this.dataObj.init_data.filter.SysTags) {
                if (this.dataObj.init_data.filter.SysTags[key].active) {
                    sys.push(this.dataObj.init_data.filter.SysTags[key].text)
                }
            }
        }

        let data = {
            type: 'contacts',

            system_tags: this.SysTags ? this.dataObj.init_data.filter.SysTags[this.SysTags].text : '',
            user_tags: this.bqUserCurrent === -1 ? '' : this.dataObj.init_data.filter.UserTagsAll[this.bqUserCurrent].TagName,
            group_id: this.classCurrent === -1 ? '' : this.classCurrent,

            phone: this.form.mobile,
            remark_username: this.form.name,

            page: this.page,
            pagesize: this.pageSize,

            ctime_begin: this.form.ctime ? this.form.ctime[0] : '',
            ctime_stop: this.form.ctime ? this.form.ctime[1] : '',

            last_follow_time_begin: this.form.last_follow_time ? this.form.last_follow_time[0] : '',
            last_follow_time_stop: this.form.last_follow_time ? this.form.last_follow_time[1] : '',
            sort_field: this.sortType
        }

        let form = this.__utils.index.copyObj(this.form)
        data = Object.assign(form, {
            ctime: '',
            last_follow_time: '',
            ...data
        })

        const res = await this.__apis.customer.customerList(data)

        this.dataObj = res.data
        this.dataObj.show = true
        this.total = res.data.res.total
    }

    toDetail (obj: any, bool: boolean) {
        obj = { name: '/customer/detail', params: { id: obj.CustomerId } }
        if (bool) {
            let { href } = this.$router.resolve(obj)
            window.open(href, '_blank')
        } else {
            this.$router.push(obj)
        }
    }

    // 搜索
    clickSearch () {
        this.page = 1
        this.getData()
    }

    // 搜索面板
    clickSearchVisible () {
        this.searchVisible = !this.searchVisible
    }

    // 清空筛选
    resetFrom (formName: string) {
        const form = this.$refs[formName] as any
        form.resetFields()

        for (let key in this.dataObj.init_data.filter.SysTags) {
            this.$set(this.dataObj.init_data.filter.SysTags[key], 'active', false)
        }
        this.SysTags = ''

        this.classCurrent = -1
        this.bqUserCurrent = -1
        this.page = 1
        this.getData()
    }

    // txl tag
    txlUserTagEdit (i: number) {
        this.$set(this.dataObj.init_data.filter.UserTagsAll[i], 'active', !this.dataObj.init_data.filter.UserTagsAll[i].active)
    }

    // txl edit
    toTxlEdit (index: number) {
        this.dataObj.init_data.filter.UserTagsAll.map((v: any) => {
            this.$set(v, 'active', false)
        })

        this.editTxlForm.remark_name = this.dataObj.res.list[index].RemarkUsername
        this.editTxlForm.note = this.dataObj.res.list[index].Note
        this.editTxlForm.group_id = this.dataObj.res.list[index].GroupId || ''
        this.editTxlForm.user_tags = this.dataObj.res.list[index].UserTags && this.dataObj.res.list[index].UserTags.split(',')

        this.dataObj.init_data.filter.UserTagsAll.map((v: any) => {
            this.editTxlForm.user_tags &&
            this.editTxlForm.user_tags.map((vv: any) => {
                if (v.TagName === vv) {
                    this.$set(v, 'active', true)
                }
            })
        })
        this.editTxlIndex = index
        this.dialogTxlEdit = true
    }

    // txl edit save
    async saveEditTxlForm () {
        if (this.editTxlIndex < 0) return

        let sys: any[] = []
        this.dataObj.init_data.filter.UserTagsAll.map((v: any) => {
            if (v.active) {
                sys.push(v.TagName)
            }
        })
        let data = this.__utils.index.copyObj(this.editTxlForm)
        data = Object.assign(data, {
            id: this.dataObj.res.list[this.editTxlIndex].Id,
            user_tags: sys.join(',')
        })

        await this.__apis.customer.saveCustomer(data)

        this.$notify({
            title: '成功',
            message: '保存成功！',
            type: 'success'
        })
        this.dialogTxlEdit = false
        this.getData()
    }

    // 排序
    sortChange (obj: any) {
        if (obj.order === 'ascending') {
            obj.prop = ''
        }
        this.sortType = obj.prop
        this.getData()
    }
}
