import { Component, Prop, Vue } from 'vue-property-decorator'
import { TableItem } from '../index'

interface bindUserItem {
    id?: number
    name: string
    dep?: number
    group?: number
    role?: number
    count: number
    active: boolean
    open_id: string
    head_img: string
    nick_name: string
    un_bind_openid: boolean
    open?: number
    project_roles: number[][]
}

@Component({
    components: {
    }
})
export default class EditUserComponent extends Vue {
    @Prop({
        default: false
    })
    visible!:boolean

    @Prop({
        default () {
            return {}
        }
    })
    data!:TableItem

    form: {
        id: number
        name: string
        mobile: string
        company: string
        commonDep?: number
        exit_time?: Date
    } = {
        id: 0,
        name: '',
        mobile: '',
        company: '',
        commonDep: undefined,
        exit_time: undefined
    }

    roleList: {
        label: string
        value: number
        status: number
    }[] = []

    get
    comanyList () {
        return this.__store.state.user.company
    }

    bindUserList: bindUserItem[] = []

    projectRolesProps = {
        multiple: true,
        label: 'name',
        value: 'id',
        children: 'roles'
    }

    get
    projectRoles () {
        return this.__utils.index.getProjectsRoles()
    }

    get
    showExitTime () {
        if (!this.bindUserList.length) return false

        return !this.bindUserList.filter(a => !a.open).length
    }

    get
    showExitTimeNone () {
        return !this.form.exit_time
    }

    get
    depList () {
        return [{ label: '请选择', value: 0 }].concat(this.__utils.index.getXsDep())
    }

    get
    common_dep_arr () {
        return this.__store.state.user.common_dep_arr
    }

    async created () {
        this.roleList = await this.__utils.index.getAdminRoleList()
        this.getEmployeeBindUserList()
    }

    async getEmployeeBindUserList () {
        const { data } = await this.__apis.system.getEmployeeBindUserList(this.data.id)
        this.form.id = data.id
        this.form.name = data.name
        this.form.mobile = data.mobile
        this.form.exit_time = data.leave_time ? new Date(data.leave_time) : new Date()
        this.form.company = data.company || ''
        const commonDep = this.__utils.index.getComDepInfoById(data.dep)
        this.form.commonDep = commonDep ? commonDep.value : undefined
        this.bindUserList = data.user_list.map(v => {
            return {
                id: v.user_id,
                name: v.user_name,
                dep: v.dep_id === 0 ? undefined : v.dep_id,
                group: v.group_id === 0 ? undefined : v.group_id,
                role: v.role_id === 0 ? undefined : v.role_id,
                count: v.max_take_count,
                active: v.employee_bind,
                open_id: v.open_id,
                head_img: v.head_img,
                nick_name: v.nick_name,
                un_bind_openid: false,
                open: v.de_active,
                project_roles: (v.project_roles || []).reduce((r:number[][], a) => {
                    a.role_ids.map(v => {
                        r.push([a.project_id, v])
                    })
                    return r
                }, [])
            }
        })
    }

    groupList (dep: number) {
        return [{ name: '请选择', value: 0 }].concat(this.__utils.index.getGroupsByDepId(dep))
    }

    addBindUser () {
        this.bindUserList.unshift({
            name: '',
            dep: undefined,
            group: undefined,
            role: undefined,
            count: 0,
            active: false,
            open_id: '',
            head_img: '',
            nick_name: '',
            un_bind_openid: false,
            open: 1,
            project_roles: []
        })
    }

    changeDep (item:bindUserItem) {
        item.group = undefined
    }

    unBind (item:bindUserItem) {
        item.un_bind_openid = true
    }

    openIdInfo (item: bindUserItem) {
        return item.open_id && !item.un_bind_openid ? [
            {
                id: item.open_id,
                avator: item.head_img,
                username: item.nick_name
            }
        ] : []
    }

    changeActive (item:bindUserItem) {
        this.bindUserList.map(v => {
            v.active = false
        })
        item.active = true
    }

    close () {
        this.$emit('update:visible', false)
    }

    saveProjectRoles (projectRole: number[][]) {
        const roleObj:{[key:string]: number[]} = projectRole.reduce((r:{[key:number]: number[]}, a) => {
            if (!r[a[0]]) {
                r[a[0]] = []
            }
            r[a[0]].push(a[1])
            return r
        }, {})
        console.log(roleObj)
        const roleList = this.projectRoles.map(a => {
            return {
                project_id: a.id,
                role_ids: roleObj[a.id] || []
            }
        })
        console.log(roleList)
        return roleList
    }

    checkValid () {
        if (!this.form.name.trim()) {
            this.$notify({
                title: '警告',
                message: '请输入姓名，姓名不能为空！',
                type: 'warning'
            })
            return
        }
        if (!this.form.mobile.trim()) {
            this.$notify({
                title: '警告',
                message: '请输入手机号，手机号不能为空！',
                type: 'warning'
            })
            return
        }
        // 全部关闭 true 否则 false  // 0开 1关
        const isAllNoActive = this.bindUserList.every(a => a.open)
        // 账号开启列表中，不存在主账号
        const isOpenNoActive2 = this.bindUserList.filter(a => !a.open).every(a => !a.active)
        if (!isAllNoActive && isOpenNoActive2) {
            this.$notify({
                title: '警告',
                message: '请选择一个主账号！',
                type: 'warning'
            })
            return
        }
        this.save()
    }

    async save () {
        let loading:any

        try {
            loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.5)'
            })

            await this.__apis.system.editEmployeeInfo({
                user_name: this.form.name,
                mobile: this.form.mobile,
                company: this.form.company,
                dep: this.form.commonDep !== undefined ? +this.form.commonDep : 0,
                employee_id: this.data.id,
                leave_time: this.form.exit_time ? this.form.exit_time.getTime() : undefined,
                user_list: this.bindUserList.filter(a => a.name && a.role).map(a => {
                    if (a.id) {
                        const closeText = '-离职'
                        if (a.open) {
                        // 1关
                            a.name = a.name.includes(closeText) ? a.name : `${a.name}${closeText}`
                        } else {
                        // 0开
                            a.name = a.name.includes(closeText) ? a.name.replace(closeText, '') : a.name
                        }
                    }
                    return {
                        fxs_dep: a.dep || 0,
                        group_id: a.group || 0,
                        role_id: a.role || 0,
                        user_id: a.id || 0,
                        user_name: a.name,
                        max_take_count: a.count,

                        open_id: a.open_id,
                        head_img: a.head_img,
                        nick_name: a.nick_name,

                        employee_bind: a.active,
                        un_bind_open_id: a.un_bind_openid, // 解除绑定 true

                        de_active: a.open || 0, // 0开 1关
                        project_roles: this.saveProjectRoles(a.project_roles)
                    }
                })
            })
            await this.__utils.index.sleep(1000)
            loading.close()
            this.close()
            this.$emit('save')
            this.$notify({
                title: '成功',
                message: '保存成功！',
                type: 'success'
            })
        } catch (e) {
            loading && loading.close()
            console.log(e)
        }
    }
}
