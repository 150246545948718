import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import inputDateRangeComponent from '@/components/filters/inputDateRange/index'
import FilterComponent from '@/components/filters/filter/index.vue'
import { routerBaseUrl } from '@/router'

@Component({
    components: {
        InputDateRangeComponent,
        FilterComponent
    }
})
export default class UserCenterSalaryDetailPage extends Vue {
    @Prop({
        default: 0,
        type: Number
    })
    monthTime !: number

    @Watch('monthTime')
    watchMonthTime (n: number) {
        if (n) {
            // 增加筛选条件
            const start = new Date(n * 1000)
            const end = start.getTime() + 31 * 86400000
            const end1 = new Date(end - new Date(end).getDate() * 86400000)

            const input = this.$refs.startDate as inputDateRangeComponent

            input.value = [start.format('yyyy-MM-dd'), end1.format('yyyy-MM-dd')]
            input.enter()
        }
    }

    table: any = {
        data: [],
        loading: false,
        time_start: '',
        time_end: ''
    }

    pagination = {
        total: 20,
        pagesize: 20,
        current: 1
    }

    mounted () {
        this.getData()
    }
    // 跳转成交订单
    gotoSuccess (id: number) {
        open(`${routerBaseUrl}/financial/success_detail/success/${id}`, '_blank')
    }

    filterChange (data: any) {
        this.table.time_start = ''
        this.table.time_end = ''

        for (let k in data) {
            if (k === 'startDate') {
                this.table.time_start = data[k][0]
                this.table.time_end = data[k][1]
            } else {
                this.table[k] = data[k]
            }
        }

        if (this.table.time_start === '') {
            this.$emit('update:monthTime', 0)
        }

        this.pagination.current = 1
        this.getData()
    }

    // 当前分页改变时候触发
    currentChange (page: number) {
        if (this.table.loading) return

        this.pagination.current = page
        this.getData()
    }

    async getData () {
        const { data } = await this.__apis.user.getMyBonusList({
            page: this.pagination.current,
            pagesize: this.pagination.pagesize,
            time_start: this.table.time_start,
            time_end: this.table.time_end
        })

        this.table.data = []
        this.pagination.total = data.total

        if (data.list.length === 0) return

        // 总计
        if (data.statistic_res) {
            this.table.all = data.statistic_res
        }
        this.table.data.push(this.table.all, ...data.list)

        // 处理一些信息
        this.table.data.forEach((a: any) => {
            a.jy_time2 = new Date(a.jy_time * 1000).format('yyyy-MM-dd')
            a.sale_time2 = new Date(a.sale_time * 1000).format('yyyy-MM-dd')
            a.bonus_rate2 = a.bonus_rate * 100 + '%'
        })
        this.table.data[0].jy_time2 = '总计'
        this.table.data[0].sale_time2 = ''
        this.table.data[0].bonus_rate2 = ''
    }
}
