import Vue, { VueConstructor } from 'vue'
import Router from 'vue-router'
import ElementUI from 'element-ui'
import NoPage from '@/views/NoPage/index.vue'
import Home from '@/views/home/index.vue'
import Customer from '@/views/customer/index/index.vue'
import SaleMan from '@/views/saleman/index/index.vue'
import Financial from '@/views/financial/index/index.vue'
import noticeHistory from '@/views/notice/index.vue'
import userCenter from '@/views/usercenter/index/index.vue'
import Index from '@/views/index/index.vue'
import { __role } from '@/role'
import Permissions from '@/role/permissions'

type routeConfig = {
    children?: routeConfig[],
    path: string,
    component?: VueConstructor | {
        (): Promise<typeof import('*.vue')>
    },
    name?: string,
    redirect?: string | Object,
    meta?: any
    authKey?: Permissions
    key?: string
    all?: boolean
}

// 题库
const questionBankRoute = [
    {
        path: '/questionBank',
        name: '/questionBank',
        authKey: __role.permissions.ShowQuestionBankPage,
        component: () => import('@/views/questionBank/index/index.vue'),
        children: [
            {
                path: 'answerRecord',
                name: '/questionBank/answerRecord',
                meta: {
                    title: '答题记录'
                },
                authKey: __role.permissions.ShowQuestionAnswerRecordPage,
                component: () => import('@/views/questionBank/answerRecord/index.vue')
            },
            {
                path: 'statistics',
                name: '/questionBank/statistics',
                meta: {
                    title: '题库统计'
                },
                authKey: __role.permissions.ShowQuestionBankStatisticPage,
                component: () => import('@/views/questionBank/statistics/index.vue')
            },
            {
                path: 'manage',
                name: '/questionBank/manage',
                meta: {
                    title: '题库管理'
                },
                authKey: __role.permissions.ShowQuestionBankMangePage,
                component: () => import('@/views/questionBank/manage/index.vue')
            },
            {
                path: 'manage/classification/:type/:dep',
                name: '/questionBank/manage/classification',
                meta: {
                    title: '题库-题目管理'
                },
                authKey: __role.permissions.ShowQuestionBankMangePage,
                component: () => import('@/views/questionBank/manage/classification/index.vue')
            }
        ]
    }
]

// 市场
const marketManageRoute = [
    {
        path: '/market',
        name: '/market',
        authKey: __role.permissions.ShowMarketPage,
        component: () => import('@/views/market/index/index.vue'),
        children: [
            {
                path: 'loginList',
                name: '/market/loginList',
                authKey: __role.permissions.ShowMarketPlatformLoginPage,
                component: () => import('@/views/market/loginList/index.vue')
            },
            {
                path: 'ocpc',
                name: '/market/ocpc',
                authKey: __role.permissions.ShowOcpcListPage,
                component: () => import('@/views/market/ocpc/index.vue')
            }
        ]
    }
]

// 充值钱包
const chongZhiWalletRoute = [
    {
        path: 'chongzhi-wallet',
        name: '/chongzhi-wallet',
        meta: {
            title: '充值钱包'
        },
        component: () => import('@/views/financial/chongzhiWallet/index.vue'),
        authKey: __role.permissions.ShowChongZhiWalletPage,
        children: [
            {
                path: '',
                name: '/chongzhi-wallet/record',
                meta: {
                    title: '充值钱包'
                },
                component: () => import('@/views/financial/chongzhiWallet/record/index.vue')
            },
            {
                path: 'record-detail',
                name: '/chongzhi-wallet/record-detail',
                meta: {
                    title: '充值钱包-明细'
                },
                component: () => import('@/views/financial/chongzhiWallet/record-detail/index.vue')
            },
            {
                path: 'recharge',
                name: '/chongzhi-wallet/recharge',
                meta: {
                    title: '充值钱包-充值'
                },
                component: () => import('@/views/financial/chongzhiWallet/recharge/index.vue')
            },
            {
                path: 'review',
                name: '/chongzhi-wallet/review',
                meta: {
                    title: '充值钱包-提现审核'
                },
                component: () => import('@/views/financial/chongzhiWallet/review/index.vue')
            }
        ]
    }
]

// 战队提成
const groupCommissionRoute = [
    {
        path: 'group-commission',
        name: '/group-commission',
        meta: {
            title: '战队提成'
        },
        authKey: __role.permissions.ShowGroupCommissionRecordPage,
        component: () => import('@/views/financial/groupCommission/index.vue'),
        children: [
            {
                path: '',
                name: '/group-commission',
                meta: {
                    title: '战队提成-队长提成发放'
                },
                component: () => import('@/views/financial/groupCommission/record/index.vue')
            },
            {
                path: 'profit',
                name: '/group-commission/profit',
                meta: {
                    title: '战队提成-队长利润提成明细'
                },
                component: () => import('@/views/financial/groupCommission/profit/index.vue')
            },
            {
                path: 'profit-detail',
                name: '/group-commission/profit-detail',
                meta: {
                    title: '战队提成-队长利润提成明细'
                },
                component: () => import('@/views/financial/groupCommission/profit-detail/index.vue')
            }
        ]
    }
]

// 朋友圈
const wxFriendsCircle = [
    {
        path: '/friendsCircle',
        name: '/friendsCircle',
        component: () => import('@/views/wxFriendsCircle/index.vue'),
        children: [
            {
                path: '',
                name: '/friendsCircle',
                component: () => import('@/views/wxFriendsCircle/article/index.vue')
            },
            {
                path: 'seeUser',
                name: '/friendsCircle/seeUser',
                component: () => import('@/views/wxFriendsCircle/seeUser/index.vue')
            }
        ]
    }
]

export const routes: routeConfig[] = [
    // 项目页面
    {
        path: '/',
        component: Index,
        children: [
            // 概况
            {
                path: '/',
                redirect: '/home'
            },
            {
                path: '/home',
                name: '/home',
                meta: {
                    title: '新房分析师管理系统'
                },
                component: Home,
                authKey: __role.permissions.HomePage
            },

            // 知识库
            {
                path: '/knowledge',
                meta: { title: '知识库' },
                component: () => import('@/views/knowledge/space/index.vue'),
                children: [
                    {
                        path: '',
                        meta: { title: '知识库' },
                        name: '/knowledge',
                        component: () => import('@/views/knowledge/index/index.vue')
                    },
                    {
                        path: 'searchDoc',
                        meta: { title: '搜索文档' },
                        name: '/knowledge/searchDoc',
                        component: () => import('@/views/knowledge/searchDoc/index.vue')
                    },
                    {
                        path: 'addEditKu',
                        meta: { title: '新增/编辑知识库' },
                        name: '/knowledge/addEditKu',
                        component: () => import('@/views/knowledge/addEditKu/index.vue')
                    },
                    {
                        path: 'addCate',
                        meta: { title: '创建目录' },
                        name: '/knowledge/addCate',
                        component: () => import('@/views/knowledge/addCate/index.vue')
                    },
                    {
                        path: 'addCateOk',
                        meta: { title: '创建完成' },
                        name: '/knowledge/addCateOk',
                        component: () => import('@/views/knowledge/addCateOk/index.vue')
                    },
                    {
                        path: 'doc',
                        meta: { title: '文档' },
                        name: '/knowledge/doc',
                        component: () => import('@/views/knowledge/doc/index.vue')
                    },
                    {
                        path: 'editor',
                        meta: { title: '新建文档' },
                        name: '/knowledge/editor',
                        component: () => import('@/views/knowledge/editorWang/index.vue')
                    }
                ]
            },

            // 客户管理
            {
                path: '/customer',
                component: Customer,
                authKey: __role.permissions.ShowCustomerPage,
                children: [
                    {
                        path: 'new',
                        meta: {
                            title: '新客户'
                        },
                        name: '/customer/newcustomer',
                        authKey: __role.permissions.ShowNewCustomerPage,
                        component: () => import('@/views/customer/new/index.vue')
                    },
                    {
                        path: 'zdcustomer',
                        meta: {
                            title: '重点客户'
                        },
                        authKey: __role.permissions.ImportantCustomerPage,
                        name: '/customer/zdcustomer',
                        component: () => import('@/views/customer/important/index.vue')
                    },
                    {
                        path: 'departcustomer',
                        meta: {
                            title: '部门客户'
                        },
                        authKey: __role.permissions.DepartmentCustomerPage,
                        name: '/customer/departcustomer',
                        component: () => import('@/views/customer/dep/index.vue')
                    },
                    {
                        path: 'teamcustomer',
                        meta: {
                            title: '战队客户'
                        },
                        authKey: __role.permissions.TeamCustomerPage,
                        name: '/customer/teamcustomer',
                        component: () => import('@/views/customer/dep/index.vue')
                    },
                    {
                        path: 'allcustomer',
                        meta: {
                            title: '全部客户'
                        },
                        authKey: __role.permissions.AllCustomerPage,
                        name: '/customer/allcustomer',
                        component: () => import('@/views/customer/all/index.vue')
                    },
                    {
                        path: 'txlcustomer',
                        meta: {
                            title: '通讯录'
                        },
                        authKey: __role.permissions.AddressBookPage,
                        name: '/customer/txlcustomer',
                        component: () => import('@/views/customer/contact/index.vue')
                    },
                    {
                        path: 'mycustomer',
                        meta: {
                            title: '我的客户'
                        },
                        authKey: __role.permissions.MyCustomerPage,
                        name: '/customer/mycustomer',
                        component: () => import('@/views/customer/my/index.vue')
                    },
                    {
                        path: 'gccustomer',
                        meta: {
                            title: '公池客户'
                        },
                        name: '/customer/gccustomer',
                        authKey: __role.permissions.PublicCustomerPage,
                        component: () => import('@/views/customer/public/index.vue')
                    },
                    {
                        path: 'invalidcustomer',
                        meta: {
                            title: '无效客户'
                        },
                        name: '/customer/invalidcustomer',
                        authKey: __role.permissions.CustomerInvalidApplyRecordsPage,
                        // component: () => import('@/views/customer/invalid/index.vue')
                        component: () => import('@/views/customer/invalidExamine/index.vue')
                    },
                    {
                        path: 'phonecustomer',
                        component: () => import('@/views/customer/phone/index.vue'),
                        authKey: __role.permissions.PhoneCustomerPage,
                        children: [
                            {
                                path: '',
                                name: '/customer/phonecustomer',
                                meta: {
                                    title: '400客户'
                                },
                                component: () => import('@/views/customer/phone/400/index.vue')
                            },
                            {
                                path: 'toutiao',
                                meta: {
                                    title: '头条客户'
                                },
                                name: '/customer/phonecustomer/toutiao',
                                component: () => import('@/views/customer/phone/toutiao/index.vue')
                            },
                            {
                                path: 'record',
                                meta: {
                                    title: '分配记录'
                                },
                                name: '/customer/phonecustomer/record',
                                component: () => import('@/views/customer/phone/record/index.vue')
                            }
                        ]
                    },
                    {
                        path: 'fenxicustomer',
                        meta: {
                            title: '客户分析'
                        },
                        authKey: __role.permissions.CustomerAnalysisPage,
                        name: '/customer/fenxicustomer',
                        component: () => import('@/views/customer/analysis/index.vue')
                    },
                    {
                        path: 'cjcustomer',
                        meta: {
                            title: '已成交客户'
                        },
                        authKey: __role.permissions.ClinchCustomerPage,
                        name: '/customer/cjcustomer',
                        component: () => import('@/views/customer/clinch/index.vue')
                    },
                    {
                        path: 'buy',
                        meta: {
                            title: '待分配客户'
                        },
                        authKey: __role.permissions.WaitBuyPage,
                        name: '/customer/buy',
                        component: () => import('@/views/customer/buy/index.vue')
                    },
                    {
                        path: 'zjRecord',
                        meta: {
                            title: '转交记录'
                        },
                        name: '/customer/zjRecord',
                        authKey: __role.permissions.ForwardHistoryPage,
                        component: () => import('@/views/customer/zjRecord/index.vue')
                    },
                    {
                        path: 'share',
                        meta: {
                            title: '共享客户'
                        },
                        name: '/customer/share',
                        authKey: __role.permissions.ForwardHistoryPage,
                        component: () => import('@/views/customer/share/index.vue')
                    }
                ]
            },
            {
                path: '/customer/detail/:id',
                authKey: __role.permissions.CustomerDetail,
                component: () => import('@/views/customer/layout/detail/index.vue'),
                children: [
                    {
                        path: '',
                        meta: {
                            title: '客户详情'
                        },
                        name: '/customer/detail',
                        component: () => import('@/views/customer/layout/customerInfo/index.vue')
                    },
                    {
                        path: 'customerInfo',
                        meta: {
                            title: '客户详情'
                        },
                        name: '/customer/detail/customerInfo',
                        component: () => import('@/views/customer/layout/customerInfo/index.vue')
                    },
                    {
                        path: 'comeguide',
                        meta: {
                            title: '即将带看'
                        },
                        name: '/customer/detail/comeguide',
                        component: () => import('@/views/customer/layout/comeguide/index.vue')
                    },
                    {
                        path: 'guidetotal',
                        meta: {
                            title: '带看总结'
                        },
                        name: '/customer/detail/guidetotal',
                        component: () => import('@/views/customer/layout/guidetotal/index.vue')
                    },
                    {
                        path: 'chengjiaototal',
                        meta: {
                            title: '成交总结'
                        },
                        name: '/customer/detail/chengjiaototal',
                        component: () => import('@/views/customer/layout/chengjiaototal/index.vue')
                    }
                ]
            },

            // 销售
            {
                path: '/saleman',
                component: SaleMan,
                authKey: __role.permissions.ShowSaleManPage,
                children: [
                    {
                        path: '',
                        meta: {
                            title: '客户分配情况',
                            keepAlive: true
                        },
                        name: '/saleman/condition/renling',
                        component: () => import('@/views/saleman/layout/renling/index.vue')
                    },
                    {
                        path: 'dongtai',
                        meta: {
                            keepAlive: true,
                            title: '发布动态情况'
                        },
                        name: '/saleman/condition/dongtai',
                        component: () => import('@/views/saleman/layout/dongtai/index.vue')
                    },
                    {
                        path: 'guide',
                        meta: {
                            title: '即将带看'
                        },
                        name: '/saleman/guide',
                        component: () => import('@/views/saleman/guide/index.vue')
                    },
                    {
                        path: 'chengjiao',
                        meta: {
                            title: '成交总结'
                        },
                        name: '/saleman/chengjiao',
                        component: () => import('@/views/saleman/chengjiao/index.vue')
                    },
                    {
                        path: 'record',
                        meta: {
                            title: '带看成交审核记录'
                        },
                        name: '/saleman/record',
                        authKey: __role.permissions.GuideDealReviewPage,
                        component: () => import('@/views/saleman/record/index.vue')
                    },
                    {
                        path: 'guideRecord',
                        name: 'guideRecord',
                        meta: {
                            title: '带看审核'
                        },
                        authKey: __role.permissions.GuideRecordReviewPage,
                        component: () => import('@/views/saleman/guideRecord/index.vue')
                    }
                ]
            },
            {
                path: '/saleman/condition',
                authKey: __role.permissions.ShowSaleManPage,
                component: () => import('@/views/saleman/layout/qkIndex/index.vue'),
                children: [{
                    path: '',
                    meta: {
                        title: '客户认领情况'
                    },
                    name: '/saleman/condition',
                    redirect: '/saleman/condition/renling'
                },
                {
                    path: 'renling',
                    meta: {
                        title: '客户认领情况',
                        keepAlive: true
                    },
                    name: '/saleman/condition/renling',
                    component: () => import('@/views/saleman/layout/renling/index.vue')
                },
                {
                    path: 'dongtai',
                    meta: {
                        keepAlive: true,
                        title: '发布动态情况'
                    },
                    name: '/saleman/condition/dongtai',
                    component: () => import('@/views/saleman/layout/dongtai/index.vue')
                },
                {
                    path: 'genjin',
                    meta: {
                        keepAlive: true,
                        title: '跟进客户情况'
                    },
                    name: '/saleman/condition/genjin',
                    component: () => import('@/views/saleman/layout/genjin/index.vue')
                }
                ]
            },

            // 财务
            {
                authKey: __role.permissions.FinancialManagePage,
                path: '/financial',
                component: Financial,
                children: [
                    {
                        path: 'success',
                        meta: {
                            title: '成交管理'
                        },
                        component: () => import('@/views/financial/success/index.vue')
                    },
                    {
                        path: 'huiyong',
                        meta: {
                            title: '回佣管理'
                        },
                        component: () => import('@/views/financial/manage/index.vue')
                    },
                    {
                        path: 'kaipiao',
                        meta: {
                            title: '开票管理'
                        },
                        component: () => import('@/views/financial/manage/index.vue')
                    },
                    {
                        path: 'fanyong',
                        meta: {
                            title: '返佣管理'
                        },
                        component: () => import('@/views/financial/manage/index.vue')
                    },
                    {
                        path: 'tuifang',
                        meta: {
                            title: '退房管理'
                        },
                        component: () => import('@/views/financial/manage/index.vue')
                    },
                    {
                        path: 'trouble',
                        meta: {
                            title: '问题单'
                        },
                        component: () => import('@/views/financial/manage/index.vue')
                    },
                    {
                        path: 'wangqian',
                        meta: {
                            title: '网签管理'
                        },
                        component: () => import('@/views/financial/manage/index.vue')
                    },
                    {
                        path: 'saleMoneyChangeList',
                        meta: {
                            title: '佣金变更记录'
                        },
                        component: () => import('@/views/financial/saleMoneyChangeList/index.vue')
                    },
                    {
                        authKey: __role.permissions.WageManagePage,
                        path: 'gongziManage',
                        meta: {
                            title: '财务管理'
                        },
                        component: () => import('@/views/financial/gongziManage/index.vue'),
                        children: [
                            {
                                path: 'gailan',
                                name: '/financial/gongziManage/gailan',
                                authKey: __role.permissions.ShowSalaryOverviewPage,
                                meta: {
                                    title: '概览'
                                },
                                component: () => import('@/views/financial/gongziManage/layout/gailan/index.vue')
                            },
                            {
                                path: 'ticheng',
                                name: '/financial/gongziManage/ticheng',
                                authKey: __role.permissions.ShowCommissionManagePage,
                                meta: {
                                    title: '提成管理'
                                },
                                component: () => import('@/views/financial/gongziManage/layout/ticheng/index.vue')
                            },
                            {
                                path: 'tcDetail/:id',
                                name: '/financial/gongziManage/tcDetail',
                                authKey: __role.permissions.ShowCommissionManagePage,
                                meta: {
                                    title: '提成详情'
                                },
                                component: () => import('@/views/financial/gongziManage/layout/components/tcDetail/index.vue')
                            },
                            {
                                path: 'gongzi',
                                name: '/financial/gongziManage/gongzi',
                                authKey: __role.permissions.ShowSalaryManagePage,
                                meta: {
                                    title: '工资管理'
                                },
                                component: () => import('@/views/financial/gongziManage/layout/gongzi/index.vue')
                            },
                            {
                                path: 'gzUser',
                                authKey: __role.permissions.ShowSalarySettingPage,
                                meta: {
                                    title: '员工工资设置'
                                },
                                name: '/financial/gzUser',
                                component: () => import('@/views/financial/gongziManage/layout/gzUser/index.vue')
                            }
                        ]
                    },
                    {
                        path: 'wallet',
                        meta: {
                            title: '钱包管理'
                        },
                        authKey: __role.permissions.WalletManagePage,
                        component: () => import('@/views/financial/wallet/index.vue'),
                        children: [
                            {
                                path: '',
                                meta: {
                                    title: '钱包管理'
                                },
                                authKey: __role.permissions.ShowFinancialWalletPersonPage,
                                component: () => import('@/views/financial/wallet/set/index.vue'),
                                children: [
                                    {
                                        path: '',
                                        meta: {
                                            title: '钱包管理'
                                        },
                                        component: () => import('@/views/financial/wallet/set/gongzi/index.vue')
                                    }, {
                                        path: 'ticheng',
                                        meta: {
                                            title: '钱包管理'
                                        },
                                        component: () => import('@/views/financial/wallet/set/ticheng/index.vue')
                                    }, {
                                        path: 'jiangjin',
                                        meta: {
                                            title: '钱包管理'
                                        },
                                        component: () => import('@/views/financial/wallet/set/jiangjin/index.vue')
                                    }, {
                                        path: 'chongzhi',
                                        meta: {
                                            title: '余额充值'
                                        },
                                        component: () => import('@/views/financial/wallet/set/chongzhi/index.vue')
                                    }, {
                                        path: 'chongzhiCard',
                                        meta: {
                                            title: '充值卡发放薪资'
                                        },
                                        component: () => import('@/views/financial/wallet/set/chongzhiCard/index.vue')
                                    }, {
                                        path: 'chongzhiCard/detail/:id',
                                        meta: {
                                            title: '充值卡发放薪资明细'
                                        },
                                        component: () => import('@/views/financial/wallet/set/chongzhiCard/detail/index.vue')
                                    }
                                ]
                            },
                            {
                                authKey: __role.permissions.ShowFinancialWalletReviewPage,
                                path: 'review',
                                meta: {
                                    title: '钱包管理'
                                },
                                component: () => import('@/views/financial/wallet/review/index.vue')
                            },
                            {
                                authKey: __role.permissions.ShowFinancialWalletTeamPage,
                                path: 'group',
                                meta: {
                                    title: '钱包管理'
                                },
                                component: () => import('@/views/financial/wallet/group/index.vue')
                            },
                            {
                                authKey: __role.permissions.ShowFinancialWalletTeamPage,
                                path: 'group-detail',
                                meta: {
                                    title: '钱包管理'
                                },
                                component: () => import('@/views/financial/wallet/group/detail/index.vue')
                            }
                        ]
                    },
                    ...chongZhiWalletRoute,
                    ...groupCommissionRoute
                ]
            },
            {
                authKey: __role.permissions.FinancialManagePage,
                path: '/financial/success_detail',
                meta: {
                    title: ''
                },
                name: 'financial-success_detail',
                component: () => import('@/views/financial/success-detail/index.vue'),
                children: [
                    {
                        path: 'trouble/:id',
                        meta: {
                            title: '成交管理详情'
                        },
                        name: 'success_detail-trouble',
                        component: () => import('@/views/financial/success-detail/trouble/index.vue')
                    },
                    {
                        path: 'remark/:id',
                        meta: {
                            title: '成交管理详情'
                        },
                        name: 'success_detail-remark',
                        component: () => import('@/views/financial/success-detail/remark/index.vue')
                    },
                    {
                        path: 'commission/:id',
                        meta: {
                            title: '成交管理详情'
                        },
                        name: 'success_detail-commission',
                        component: () => import('@/views/financial/success-detail/commission/index.vue')
                    },
                    {
                        path: 'success/:id',
                        meta: {
                            title: '成交管理详情'
                        },
                        name: 'success_detail-success',
                        component: () => import('@/views/financial/success-detail/success/index.vue')
                    },
                    {
                        path: 'web-sign/:id',
                        meta: {
                            title: '成交管理详情'
                        },
                        name: 'success_detail-webSign',
                        component: () => import('@/views/financial/success-detail/webSign/index.vue')
                    },
                    {
                        path: 'billing/:id',
                        meta: {
                            title: '成交管理详情'
                        },
                        name: 'success_detail-billing',
                        component: () => import('@/views/financial/success-detail/billing/index.vue')
                    },
                    {
                        path: 'end/:id',
                        meta: {
                            title: '成交管理详情'
                        },
                        name: 'success_detail-end',
                        component: () => import('@/views/financial/success-detail/end/index.vue')
                    },
                    {
                        path: 'back/:id',
                        meta: {
                            title: '成交管理详情'
                        },
                        name: 'success_detail-back',
                        component: () => import('@/views/financial/success-detail/back/index.vue')
                    },
                    {
                        path: 'exit/:id',
                        meta: {
                            title: '成交管理详情'
                        },
                        name: 'success_detail-exit',
                        component: () => import('@/views/financial/success-detail/exit/index.vue')
                    }
                ]
            },

            // 渠道
            {
                path: '/channelManage',
                name: '/channelManage',
                authKey: __role.permissions.ShowChannelPage,
                component: () => import('@/views/channelManage/index/index.vue'),
                children: [
                    {
                        authKey: __role.permissions.UrgeManagePage,
                        path: 'cuiyong',
                        name: 'cuiyong',
                        meta: {
                            title: '催佣管理'
                        },
                        component: () => import('@/views/channelManage/layout/cuiyong/index.vue')
                    },
                    {
                        authKey: __role.permissions.PartnerManagePage,
                        path: 'hezuoshang',
                        component: () => import('@/views/channelManage/layout/friend/index.vue'),
                        children: [
                            {
                                path: '',
                                name: 'hezuoshang',
                                meta: {
                                    title: '合作商列表'
                                },
                                component: () => import('@/views/channelManage/layout/friend/friend/index.vue')
                            }, {
                                path: 'duijie',
                                meta: {
                                    title: '对接人列表'
                                },
                                component: () => import('@/views/channelManage/layout/friend/duijie/index.vue')
                            }
                        ]
                    },
                    {
                        authKey: __role.permissions.PartnerManagePage,
                        path: 'hezuoshang',
                        meta: {
                            title: '合作商管理详情'
                        },
                        component: () => import('@/views/channelManage/layout/friend/friend/detail/index.vue'),
                        children: [
                            {
                                path: ':id',
                                name: 'friend-detail',
                                meta: {
                                    title: '合作商管理详情'
                                },
                                component: () => import('@/views/channelManage/layout/friend/friend/detail/info/index.vue')
                            }, {
                                path: 'business/:id',
                                name: 'friend-detail-business',
                                meta: {
                                    title: '合作商管理详情'
                                },
                                component: () => import('@/views/channelManage/layout/friend/friend/detail/business/index.vue')
                            },
                            {
                                path: 'promise/:id',
                                meta: {
                                    title: '合作商管理详情'
                                },
                                name: 'friend-detail-promise',
                                component: () => import('@/views/channelManage/layout/friend/friend/detail/promise/index.vue')
                            },
                            {
                                path: 'change/:id',
                                meta: {
                                    title: '合作商管理详情'
                                },
                                name: 'friend-detail-change',
                                component: () => import('@/views/channelManage/layout/friend/friend/detail/change/index.vue')
                            },
                            {
                                path: 'use/:id',
                                meta: {
                                    title: '合作商管理详情'
                                },
                                name: 'friend-detail-use',
                                component: () => import('@/views/channelManage/layout/friend/friend/detail/use/index.vue')
                            }
                        ]
                    },
                    {
                        authKey: __role.permissions.SignReviewPage,
                        path: 'signReview',
                        meta: {
                            title: '网签审核'
                        },
                        component: () => import('@/views/channelManage/layout/signReview/index.vue')
                    },
                    {
                        authKey: __role.permissions.HouseManagePage,
                        path: 'house-manage',
                        meta: {
                            title: '楼盘管理'
                        },
                        component: () => import('@/views/channelManage/layout/house-manage/index.vue')
                    },
                    {
                        authKey: __role.permissions.HouseBindSave,
                        path: 'house-manage/detail',
                        meta: {
                            title: '楼盘编辑'
                        },
                        component: () => import('@/views/channelManage/layout/house-manage/detail/index.vue')
                    },
                    {
                        authKey: __role.permissions.RetirementReviewPage,
                        path: 'invalidCustomer',
                        name: 'invalidCustomer',
                        meta: {
                            title: '退客审核'
                        },
                        component: () => import('@/views/channelManage/layout/invalidCustomer/index.vue')
                    },
                    {
                        authKey: __role.permissions.TransactionReviewPage,
                        path: 'recordAudit',
                        name: 'recordAudit',
                        meta: {
                            title: '成交审核'
                        },
                        component: () => import('@/views/channelManage/layout/recordAudit/index.vue')
                    },
                    {
                        authKey: __role.permissions.ShowControversialExaminePage,
                        path: 'controversialExamine',
                        name: 'controversialExamine',
                        meta: {
                            title: '申诉单审核'
                        },
                        component: () => import('@/views/channelManage/layout/controversialExamine/index.vue')
                    },
                    {
                        authKey: __role.permissions.ShowChannelAssessmentPage,
                        path: 'performanceAppraisal',
                        meta: {
                            title: '绩效考核'
                        },
                        component: () => import('@/views/channelManage/layout/performanceAppraisal/index.vue'),
                        children: [
                            {
                                path: '',
                                name: '/performanceAppraisal/score',
                                meta: {
                                    title: '考核得分'
                                },
                                component: () => import('@/views/channelManage/layout/performanceAppraisal/score/index.vue')
                            },
                            {
                                path: 'score/detail/:id',
                                name: '/performanceAppraisal/score/detail',
                                meta: {
                                    title: '考核得分详情'
                                },
                                component: () => import('@/views/channelManage/layout/performanceAppraisal/score/scoreDetail/index.vue')
                            },
                            {
                                path: 'houseBind',
                                name: '/performanceAppraisal/houseBind',
                                meta: {
                                    title: '楼盘绑定'
                                },
                                component: () => import('@/views/channelManage/layout/performanceAppraisal/houseBind/index.vue')
                            }
                        ]
                    }
                ]
            },

            // 电话记录
            {
                authKey: __role.permissions.PhoneRecodePage,
                path: '/phone-record',
                name: '/phone-record',
                component: () => import('@/views/phoneRecord/index.vue'),
                children: [
                    {
                        authKey: __role.permissions.ShowFxsPhoneRecordPage,
                        path: '',
                        meta: {
                            title: '销售电话记录'
                        },
                        component: () => import('@/views/phoneRecord/fxs/index.vue')
                    },
                    {
                        authKey: __role.permissions.ShowRobotPhoneRecordPage,
                        path: 'robot',
                        meta: {
                            title: '机器人外呼记录'
                        },
                        component: () => import('@/views/phoneRecord/robot/index.vue')
                    }
                ]
            },

            // 奇妙钱包
            {
                authKey: __role.permissions.QmWalletPage,
                path: '/qm-wallet',
                meta: {
                    title: '奇妙钱包'
                },
                component: () => import('@/views/qmWallet/index.vue'),
                children: [
                    {
                        path: '',
                        meta: {
                            title: '奇妙钱包'
                        },
                        authKey: __role.permissions.PersonWalletPage,
                        component: () => import('@/views/qmWallet/fxs/index.vue')
                    },
                    {
                        path: 'group',
                        meta: {
                            title: '奇妙钱包'
                        },
                        component: () => import('@/views/qmWallet/group/index.vue')
                    },
                    {
                        path: 'record',
                        meta: {
                            title: '奇妙钱包'
                        },
                        authKey: __role.permissions.WithdrawRecordPage,
                        component: () => import('@/views/qmWallet/record/index.vue')
                    },
                    {
                        path: 'group/detail',
                        meta: {
                            title: '奇妙钱包'
                        },
                        component: () => import('@/views/qmWallet/group/detail/index.vue')
                    },
                    {
                        path: 'fxs/detail',
                        meta: {
                            title: '奇妙钱包'
                        },
                        authKey: __role.permissions.PersonWalletPage,
                        component: () => import('@/views/qmWallet/fxs/detail/index.vue')
                    },
                    {
                        path: 'fxs/commission-detail',
                        meta: {
                            title: '奇妙钱包'
                        },
                        authKey: __role.permissions.PersonWalletPage,
                        component: () => import('@/views/qmWallet/fxs/wait-commission-detail/index.vue')
                    }
                ]
            },

            // 历史公告
            {
                path: '/notice',
                name: '/notice',
                meta: {
                    title: '历史公告'
                },
                component: noticeHistory
            },

            // 个人中心
            {
                path: '/usercenter',
                name: '/usercenter',
                meta: {
                    title: '个人中心'
                },
                component: userCenter
            },

            // 报告管理
            {
                path: '/report-manage',
                name: '/report-manage',
                meta: {
                    title: '报告管理'
                },
                authKey: __role.permissions.ReportManagePage,
                component: () => import('@/views/reportManage/index.vue')
            },

            // 系统
            {
                path: '/system',
                redirect: '/system/account',
                authKey: __role.permissions.ShowSystemPage,
                component: () => import('@/views/system/index.vue'),
                children: [
                    {
                        path: 'account',
                        meta: {
                            title: '账号管理'
                        },
                        redirect: '/system/account/fxs',
                        component: () => import('@/views/system/account/index.vue'),
                        children: [
                            {
                                path: 'fxs',
                                meta: {
                                    title: '分析师列表'
                                },
                                component: () => import('@/views/system/account/fxs/index.vue')
                            },
                            {
                                path: 'records',
                                meta: {
                                    title: '用户变更记录'
                                },
                                authKey: __role.permissions.UserChangeHistoryPage,
                                component: () => import('@/views/system/account/records/index.vue')
                            },
                            {
                                path: 'user',
                                meta: {
                                    title: '员工列表'
                                },
                                authKey: __role.permissions.UserListPage,
                                component: () => import('@/views/system/account/user/index.vue')
                            }
                        ]
                    },
                    {
                        path: 'role',
                        meta: {
                            title: '角色权限'
                        },
                        authKey: __role.permissions.RoleAuthPage,
                        component: () => import('@/views/system/role/index.vue')
                    },
                    {
                        path: 'user',
                        meta: {
                            title: '用户权限'
                        },
                        authKey: __role.permissions.UserAuthPage,
                        component: () => import('@/views/system/user/index.vue')
                    },
                    {
                        path: 'role-manage',
                        meta: {
                            title: '权限管理'
                        },
                        authKey: __role.permissions.AuthManagePage,
                        component: () => import('@/views/system/role-manage/index.vue')
                    },
                    {
                        path: 'group-manage',
                        meta: {
                            title: '战队管理'
                        },
                        authKey: __role.permissions.TeamManagePage,
                        component: () => import('@/views/system/group-manage/index.vue')
                    }
                ]
            },

            // 成长管理
            {
                path: '/grow-manage',
                name: '/grow-manage',
                meta: {
                    title: '成长管理'
                },
                key: __role.KEYS.grow_file,
                all: false,
                component: () => import('@/views/growManage/index.vue'),
                redirect: '/grow-manage/file',
                children: [
                    {
                        path: 'file',
                        meta: {
                            title: '成长档案'
                        },
                        key: __role.KEYS.grow_file,
                        component: () => import('@/views/growManage/file/index.vue')
                    }, {
                        path: 'xxb',
                        meta: {
                            title: '新训班'
                        },
                        key: __role.KEYS.grow_xxb,
                        component: () => import('@/views/growManage/xxb/index.vue')
                    }
                ]
            },
            // 成长档案详情
            {
                path: '/grow-manage/file-detail/:id',
                meta: {
                    title: '成长档案详情'
                },
                key: __role.KEYS.grow_file,
                component: () => import('@/views/growManage/file/detail/index.vue')
            },
            // 新训班详情
            {
                path: '/grow-manage/xxb-detail/:id',
                meta: {
                    title: '新训班详情'
                },
                key: __role.KEYS.grow_file,
                component: () => import('@/views/growManage/xxb/detail/index.vue')
            },

            // 题库
            ...questionBankRoute,

            // 市场
            ...marketManageRoute,

            ...wxFriendsCircle,

            // 404
            {
                path: '*',
                name: '/404',
                meta: {
                    title: '404 not defined'
                },
                component: NoPage
            }
        ]
    }
]

Vue.use(Router)

export const routerBaseUrl = process.env.NODE_ENV === 'production' ? '/newadmin' : '/newadmin-dev'

const router = new Router({
    mode: 'history',
    base: routerBaseUrl,
    routes: [],

    // 路由切换回到最顶部
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

let loadingInstance: any

router.beforeEach((to, from, next) => {
    loadingInstance = ElementUI.Loading.service({
        fullscreen: true,
        text: '加载中'
    })

    if (to.meta && to.meta.title) {
        document.title = to.meta.title
    }

    next()
})

router.afterEach(() => {
    loadingInstance.close()
})

export default router
