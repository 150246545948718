import { Component, Vue, Watch } from 'vue-property-decorator'
import InputHouseSearch from '@/components/InputHouseSearch/index.vue'
import PhotosComponent from '@/components/success-detail/photos/index.vue'
import ChannelDetailComponent from '@/components/success-detail/channel-detail/index.vue'
import SaleChangeRemarkComponent from '@/components/success-detail/saleChangeRemark/index.vue'
import { Notification } from 'element-ui'
import { SuccessDetailDispatchType } from '@/store/successDetail'
import { FriendStatus } from '@/util'
import { routerBaseUrl } from '@/router'
import ReasonDialog from './reason/index.vue'

@Component({
    components: {
        ReasonDialog,
        InputHouseSearch,
        PhotosComponent,
        ChannelDetailComponent,
        SaleChangeRemarkComponent
    }
})
export default class IndexPage extends Vue {
    showReasonDialog () {
        this.reasonDialog.show = true
    }

    reasonDialog = {
        show: false
    }

    ID = -1

    successDate = '' // 成交时间

    commission = 0 // 成交总佣金

    commission1 = 0

    shouldMoney = 0 // 应到账金额

    shouldMoney1 = 0

    houseid = -1 // 楼盘id

    house = '' // 成交楼盘

    price = 0 // 成交总价

    houseno = '' // 成交房号

    username = '' // 客户姓名

    IsControversial = false // 争议单

    userphone = {
        value: '',
        error: false
    } // 客户电话

    userid = -1 // 客户id

    channel: {
        value?: number
    } = {
        value: undefined
    }

    channel_people:{
        username: string
        name: string
        phone: string
        office: string
        remark: string
        type: string
    }[] = []

    consultant = {
        name: '',
        phone: ''
    }

    certificate = [{
        image: '',
        label: '报备截图'
    }, {
        image: '',
        label: '签到单'
    }, {
        image: '',
        label: '刷卡单'
    }, {
        image: '',
        label: '认购书'
    }, {
        image: '',
        label: '客户身份证'
    }]

    days = '0' // 成交周期

    dealerAndCommission: {
        dealer: string
        commission: number
        rate: string
    }[] = []

    moneyChangeList: {
        Ctime: string
        Id: number
        Remark: string
        SaleId:number
        OldMoney: number
        Money: number
        Operator: string
    }[] = []

    remark = '' // 客户备注

    channelDetailComponent: any = {
        show: false,
        fullName: '',
        simpleName: '',
        dep: '',
        intro: '',
        people: [],
        startTime: '',
        status: ''
    }

    saleChangeRemarkComponent = {
        show: false,
        remark: ''
    }

    // 计算出隐含的返佣金额
    get computeFanyong (): number {
        return this.shouldMoney - this.commission
    }

    // 返佣记录里的返佣值
    get realFanyong (): number {
        let r: number = 0
        this.allData.FanYongList.forEach(v => {
            r += v.Money
        })
        return r
    }

    // 金额有误差
    get isWrongMoney (): boolean {
        const dlt = this.computeFanyong - this.realFanyong
        return dlt > 0.01 || dlt < -0.01
    }

    @Watch('allData')
    watchAllData () {
        this.loadSuccessDetail()
    }

    get
    allChannel () {
        return this.__store.state.success_detail.all_channel
    }
    get
    allData () {
        return this.__store.state.success_detail.all_data
    }
    get
    infoPos () {
        return this.__store.state.user.infoPos
    }
    get
    roleEdit () {
        const channel_name = this.allChannel.filter(a => a.id === this.channel.value)[0]

        return {
            authKey: this.__role.permissions.SaveSale,
            values: [this.successDate, this.commission, this.shouldMoney, this.house, this.price, this.houseno, this.username, this.userphone.value, channel_name ? channel_name.full_name : '', this.consultant.name, this.consultant.phone, this.days + '天', this.remark],
            selector: '.input'
        }
    }

    created () {
        if (this.allData.Id) {
            this.loadSuccessDetail()
        }
    }

    showBtnZYD = false
    async canShowBtnZYD () {
        if (this.__role.check(this.__role.permissions.ChannelSaveProblemDealSlipRecord)) {
            const { data } = await this.__apis.channelManage.problemDealSlipIsAppeal({
                sale_id: this.ID
            })

            this.showBtnZYD = data.is_appeal
        }
    }

    // 监听手机号输入
    watchUserPhone () {
        this.userphone.error = !this.__utils.index.checkPhone(this.userphone.value)
    }

    // 过滤每一列的类容
    formatColumn (r: any, c: any, cellValue: any) {
        if (typeof cellValue === 'number') {
            return cellValue
        } else {
            return cellValue || ''
        }
    }

    // 选择楼盘
    selectHouse (data: any) {
        this.houseid = data.id
        this.house = data.label
    }

    async applyZyExamine (remark: string) {
        const channel_name = this.allChannel.filter(a => a.id === this.channel.value)[0]

        await this.__apis.channelManage.saveProblemDealSlipRecord({
            sale_id: this.ID,
            apply_user_id: this.__store.state.user.userInfo.user_id,
            remarks: remark,
            house_name: this.house,
            channel_name: channel_name ? channel_name.full_name : '',
            duijie_user_info: JSON.stringify(
                this.channel_people
                    .filter(a => ['渠道读取'].includes(a.type))
                    .map(a => {
                        return {
                            username: a.username,
                            phone: a.phone
                        }
                    })
            )
        })

        this.$notify({
            title: '成功',
            message: '申请成功，等待审核！',
            type: 'success'
        })
    }

    async delDetail (id: number) {
        try {
            await this.$confirm('确认删除？')

            await this.__apis.channelManage.deleteSaleRecord(id)

            this.$notify({
                title: '成功',
                message: '删除成功！',
                type: 'success'
            })

            this.$router.replace('/financial')
        } catch (e) {
            console.log(e)
        }
    }

    gotoCustomerDetail () {
        open(`${routerBaseUrl}/customer/detail/${this.allData.CustomerId}`, '_blank')
    }

    // 跳转渠道详情
    async gotoChannelDetail () {
        // 需要判断用户权限 如果有权限 则跳转地址
        if (this.__role.check(this.__role.permissions.PartnerManagePage)) {
            open(`${routerBaseUrl}/channelManage/hezuoshang/${this.channel.value}`, '_blank')
        } else {
            // 查看详情即可
            // 获取数据
            const { data } = await this.__apis.channelManage.getPartnerDetail(Number(this.channel.value))

            this.channelDetailComponent = {
                show: true,
                fullName: data.FullName,
                simpleName: data.Name,
                dep: this.__utils.index.getDepInfoById(data.Dep).v,
                intro: data.Introduction,
                people: data.DuijieUser || [],
                startTime: data.StartTime ? data.StartTime.split(' ')[0] : '',
                status: FriendStatus.filter(a => a.value === data.Status)[0].label
            }
        }
    }

    // 佣金变更说明保存
    saleChangeRemarkSave (remark: string) {
        this.saleChangeRemarkComponent.remark = remark
        this.save()
    }

    // 保存编辑
    async save () {
        if (
            (this.commission !== this.commission1 && !this.saleChangeRemarkComponent.remark.trim()) ||
            (this.shouldMoney !== this.shouldMoney1 && !this.saleChangeRemarkComponent.remark.trim())
        ) {
            this.saleChangeRemarkComponent.show = true
            return
        }

        // 签到单和刷卡单必填
        if (
            !this.certificate[1].image || !this.certificate[2].image
        ) {
            this.$notify({
                title: '警告',
                message: '签到单和刷卡单必填！',
                type: 'warning'
            })
            return
        }

        const pz_imgs = JSON.stringify({
            bb: this.certificate[0].image,
            qd: this.certificate[1].image,
            sk: this.certificate[2].image,
            rg: this.certificate[3].image,
            sf: this.certificate[4].image
        })
        const channel_name = this.allChannel.filter(a => a.id === this.channel.value)[0]

        await this.__apis.financial.saveSale({
            id: this.ID,
            customer_tel: this.userphone.value,
            customer_name: this.username,
            pz_imgs,
            zygw_name: this.consultant.name,
            zygw_tel: this.consultant.phone,
            house_id: this.houseid,
            house_name: this.house,
            house_money: this.price,
            house_no: this.houseno,
            remark: this.remark,
            sale_time: this.successDate,
            channel_partner_id: this.channel.value,
            channel_name: channel_name ? channel_name.full_name_new : '',
            sum_must_dz_money: this.shouldMoney,
            sum_money: this.commission,
            change_money_remark: this.saleChangeRemarkComponent.remark,
            is_controversial: Number(this.IsControversial)
        })

        // 保存成功
        Notification.success('保存成功')

        this.saleChangeRemarkComponent.remark = ''
        this.__store.dispatch(SuccessDetailDispatchType.setAllData, +this.$route.params.id)
    }

    // 加载成交详情记录
    async loadSuccessDetail () {
        this.ID = this.allData.Id
        this.successDate = this.allData.SaleTime
        this.commission = this.allData.SumMoney
        this.commission1 = this.allData.SumMoney
        this.shouldMoney = this.allData.SumMustDzMoney
        this.shouldMoney1 = this.allData.SumMustDzMoney
        this.house = this.allData.HouseName
        this.price = this.allData.HouseMoney
        this.houseno = this.allData.HouseNo
        this.userid = this.allData.CustomerId
        this.username = this.allData.CustomerName
        this.userphone.value = this.allData.CustomerTel
        this.consultant.name = this.allData.ZygwName
        this.consultant.phone = this.allData.ZygwTel
        this.houseid = this.allData.HouseId
        this.channel.value = this.allData.ChannelPartnerId || undefined
        this.days = this.allData.CjDays.toString() || '未知'
        this.IsControversial = Boolean(this.allData.IsControversial)

        // 成交凭证
        if (this.allData.PzImgs) {
            this.certificate[0].image = this.allData.PzImgs.bb
            this.certificate[1].image = this.allData.PzImgs.qd
            this.certificate[2].image = this.allData.PzImgs.sk
            this.certificate[3].image = this.allData.PzImgs.rg
            this.certificate[4].image = this.allData.PzImgs.sf
        }

        // 成交人和佣金
        this.dealerAndCommission = []
        if (this.allData.ExtData.user_info) {
            this.allData.ExtData.user_info.forEach(a => {
                this.dealerAndCommission.push({
                    dealer: a.user_name,
                    commission: a.money,
                    rate: Number(a.money) === 0 ? 0 + '%' : ((a.money / this.allData.SumMoney) * 100).toFixed(4) + '%'
                })
            })
        }
        // 金额修改记录
        this.moneyChangeList = []
        if (this.allData.MoneyChangeList) {
            this.allData.MoneyChangeList.forEach(a => {
                this.moneyChangeList.push(a)
            })
        }

        this.remark = this.allData.Remark

        // 渠道对接人
        this.channel_people = []
        this.channel_people.push({
            username: this.allData.ChannelUsername,
            name: this.allData.ChannelName,
            phone: this.allData.ChannelTel,
            office: this.allData.ChannelUserJob,
            remark: this.allData.ChannelUserNote,
            type: '订单默认'
        })

        this.allData.DuijieUserList.forEach(a => {
            this.channel_people.push({
                username: a.username,
                name: a.name,
                phone: a.tel,
                office: a.job,
                remark: a.note,
                type: '渠道读取'
            })
        })

        this.canShowBtnZYD()
    }
}
