import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { apiConfig } from '@/api/config'
import { storageName } from '@/localStorage'

@Component
export default class UploadImg extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    readonly !: boolean

    @Prop({
        type: Number,
        default: 100
    })
    limit !: number

    @Prop({
        type: Boolean,
        default: false
    })
    multiple !: boolean

    @Prop({
        type: String,
        default: 'list'
    })
    type !: string

    @Prop({
        type: String,
        default: '上传图片'
    })
    tips !: string

    @Prop({
        type: Array,
        default () {
            return []
        }
    })
    url !: any[]

    // 上传地址
    uploadUrl = apiConfig.uploadAction
    // headers
    headers = {
        'Access-Token': ''
    }

    // 预览弹窗
    dialogVisible = false
    dialogImageUrl = ''

    fileList: any[] = []

    get
    token () {
        return this.__localStorage.getLocalStorage(storageName.TOKEN)
    }

    get
    mode () {
        return this.type
    }

    get
    tipText () {
        return this.tips
    }

    skipUrlWatch = false
    @Watch('url', {
        immediate: true
    })
    watchUrl () {
        if (this.skipUrlWatch) {
            this.skipUrlWatch = false
            return
        }

        this.fileList = this.url.map(url => {
            return {
                url
            }
        })
    }

    exceed () {
        this.$message.error(`超出${this.limit}张限制，请重新上传`)
    }

    // 上传之前
    async beforeUpload () {
        const res = await this.__apis.reportManage.uploadFileAccessToken()

        this.headers['Access-Token'] = res.access_token

        return !!res.access_token
    }

    // list
    handleSuccess (res: any) {
        if (!res.url) {
            return this.$notify({
                title: '错误',
                message: '图片上传出现问题！',
                type: 'error'
            })
        }

        this.url.push(res.url)
        this.skipUrlWatch = true
    }

    handleRemove (file: any) {
        const url = file.response ? file.response.url : file.url
        const i = this.url.indexOf(url)

        if (i === -1) {
            return
        }

        this.url.splice(i, 1)
    }

    handlePreview (file: any) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
        const images = (this.$refs['images'] as any)
        images && typeof images.clickHandler === 'function' && images.clickHandler()
    }

    avatarSuccess (res: any) {
        this.url.pop()
        this.url.push(res.url)
    }

    avatarRemove () {
        this.url.pop()
    }

    avatarPreview () {
        this.dialogImageUrl = this.url[0]
        this.dialogVisible = true
        const images = (this.$refs['images'] as any)
        images && typeof images.clickHandler === 'function' && images.clickHandler()
    }
}
