import { Component, Vue } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'
import inputDateRange from '@/components/filters/inputDateRange/index.vue'
import addScore from '../add/index.vue'
import { Table } from '@/types'
import { routerBaseUrl } from '@/router'
import { singleScoreMoney } from '..'

interface TableItem {
    id: number,
    userName: string
    depName: string,
    houseName: string,
    backDay: string
    dateTimeText: string
    score: string
    scoreMoney: string
    remark: string
    saleId: number
}

interface filterData {
    house_id?: number
    user_id?: number
    date?: string
    start_date?: string
    end_date?: string
}

@Component({
    components: {
        FilterComponent,
        FilterBusSelectComponent,
        inputDateRange,
        addScore
    }
})
export default class JiXiaoScoreDetailPage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0
    }

    filter: filterData = {}

    addScoreData = {
        visible: false,
        data: {}
    }

    params = {
        user_name: this.$route.query.name,
        user_id: +this.$route.params.id
    }

    created () {
        this.getData()
    }

    showAddScore () {
        this.addScoreData.data = {
            user_name: this.params.user_name,
            user_id: this.params.user_id
        }
        this.addScoreData.visible = true
    }

    async getData () {
        const { data } = await this.__apis.channelManage.getScoreRecordDetail({
            user_id: this.params.user_id,
            ...this.filter,
            page: this.tableData.page,
            pagesize: this.tableData.pageSize
        })
        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.user_id,
                userName: a.user_info.user_name,
                dep: a.user_info.department,
                depName: a.user_info.department_name,
                houseName: a.house_name,
                backDay: a.hui_yong_day === -1 ? '/' : a.hui_yong_day + '天',
                dateTimeText: this.__utils.index.formatDate(new Date(a.ctime), 'YYYY-MM-DD HH:mm'),
                score: a.score.toString(),
                scoreMoney: '¥' + (a.score * singleScoreMoney[a.user_info.department as number]).toFixed(2),
                remark: a.remarks,
                saleId: a.sale_id
            }
        })
    }

    filterChange (filter: filterData) {
        filter.start_date = filter.date ? new Date(filter.date[0]).format('yyyy-MM-dd') : ''
        filter.end_date = filter.date ? new Date(filter.date[1]).format('yyyy-MM-dd') : ''
        delete filter.date
        this.filter = filter
        this.getData()
    }

    currentChange (page: number) {
        this.tableData.page = page
        this.getData()
    }

    gotoDetail (item: TableItem) {
        open(`${routerBaseUrl}/financial/success_detail/success/${item.saleId}`, '_blank')
    }
}
