import { BaseRes, java, ListReq, ListRes } from '@/api/index'

interface getWeChatMomentsPreviewListReq extends ListReq{
    article_id?: number
}
interface getWeChatMomentsPreviewListRes{
    user_id: number,
    article_title:string,
    article_user_name:string,
    preview_time:string,
    user_head_img_url:string,
    user_nickname:string
}

interface getWeChatMomentsArticleListReq extends ListReq{

}

interface getWeChatMomentsArticleListRes {
    count: number
    ctime: string
    dep_id: number
    gro_id: number
    id: number
    name: string
    title: string
    type: number
}

interface addPoolHotArticleReq{
    url: string;
}

export default class ApiWxFriends {
    // 获取朋友圈访问记录
    getWeChatMomentsPreviewList (data: getWeChatMomentsPreviewListReq): BaseRes<ListRes<getWeChatMomentsPreviewListRes>> {
        return java.post('/admin/we/chat/moments/getWeChatMomentsPreviewList', data)
    }

    // 获取文章列表
    getWeChatMomentsArticleList (data: getWeChatMomentsArticleListReq): BaseRes<ListRes<getWeChatMomentsArticleListRes>> {
        return java.post('/admin/we/chat/moments/getWeChatMomentsArticleList', data)
    }

    // 添加房产热文
    addPoolHotArticle (data: addPoolHotArticleReq) {
        return java.post('/admin/pool/hot/article/addPoolHotArticle', data)
    }
}
