import { Component, Vue } from 'vue-property-decorator'
import TabsComponent from '@/components/wallet/tabs/index.vue'

@Component({
    components: {
        TabsComponent
    }
})
export default class IndexPage extends Vue {
    tabsComponent = {
        active: 0,
        items: [
            {
                text: '合作商列表',
                url: ['/channelManage/hezuoshang']
            }, {
                text: '对接人列表',
                url: ['/channelManage/hezuoshang/duijie']
            }
        ]
    }
}
