import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {
    }
})
export default class ImportDialogComponent extends Vue {
    @Prop({ default: false })
    visible!:boolean

    importObj = {
        action_time: '',
        type: -1
    }
    importDialog = {
        type: -1,
        typeData: [
            { label: '工资导入', value: 1 },
            { label: '提成奖金导入', value: 2 }
        ],
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    }

    get
    isSaveDisabled () {
        return this.importDialog.type > -1 && this.importDialog.month > -1
    }

    async saveImportDialog () {
        try {
            // 参数追加
            this.importObj.type = this.importDialog.typeData[this.importDialog.type].value
            this.importObj.action_time = `${this.importDialog.year}-${this.importDialog.month < 10 ? '0' + this.importDialog.month : this.importDialog.month}-01`
            await this.$confirm(
                `请再次确认是否导入“${this.importDialog.typeData[this.importDialog.type].label.replace('导入', '')}，${this.importDialog.year}年${this.importDialog.month}月”的数据`,
                '确认数据导入',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    customClass: 'my_confirm',
                    center: true
                }
            )
            this.cancel()
            this.$emit('submit', this.importObj)
        } catch (e) {
            console.log(e)
        }
    }

    radioClick (i:number) {
        this.importDialog.type = i
    }

    yearComputed (count:number) {
        this.importDialog.year += count
        this.importDialog.month = -1
    }

    dateMonthClick (v:number) {
        this.importDialog.month = v
    }

    cancel () {
        this.importDialog.type = -1
        this.importDialog.year = new Date().getFullYear()
        this.importDialog.month = new Date().getMonth() + 1
        this.$emit('update:visible', false)
    }
}
