import { Component, Vue, Prop } from 'vue-property-decorator'
import InputSelectSearch from '@/components/inputSelectSearch/index.vue'
import { LabelValueArray } from '@/types'
import { ShareManage } from '@/views/customer/share'

type Item = ShareManage['data']

@Component({
    components: {
        InputSelectSearch
    }
})
export default class ShareManageDialogComponent extends Vue {
    @Prop({
        default: false
    })
    visible!: boolean

    @Prop({
        default () {
            return {
                id: 0,
                userInfo: []
            }
        }
    })
    dataSource!: Item

    userInfo = {
        id: 0,
        name: '',
        shareId: 0
    }

    oldUserInfo: Item['userInfo'] = []

    get
    currentUser () {
        return this.__store.state.user.userInfo
    }

    created () {
        this.oldUserInfo = this.__utils.index.copyObj(this.dataSource.userInfo)
    }

    selectSearchFxs (item: LabelValueArray[0]) {
        this.userInfo.id = item.value
        this.userInfo.name = item.label
        this.add()
    }

    add () {
        if (this.dataSource.userInfo.length >= 10 || this.userInfo.id === 0) return
        if (this.userInfo.id === this.currentUser.user_id) return
        const t = this.dataSource.userInfo.filter(a => a.id === this.userInfo.id)
        if (t.length) {
            return
        } else {
            this.dataSource.userInfo.push(this.userInfo)
        }
        this.userInfo = {
            id: 0,
            name: '',
            shareId: 0
        }
    }

    remove (index: number) {
        this.dataSource.userInfo.splice(index, 1)
    }

    removeAll () {
        this.dataSource.userInfo = []
        this.userInfo = {
            id: 0,
            name: '',
            shareId: 0
        }
    }

    close () {
        this.$emit('update:visible', false)
    }

    async save () {
        try {
            await this.$confirm(`请确认是否提交本次操作变动的全部内容`, '客户共享管理变动提醒', {
                dangerouslyUseHTMLString: false,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                showClose: false,
                closeOnClickModal: false,
                customClass: 'my_confirm_share',
                center: true
            })
            if (this.__utils.index.limitRepeatClick()) return
            try {
                const data = this.dataSource
                // console.log(data)
                const ids = data.userInfo.map(a => a.id)

                const delInfo = this.oldUserInfo.length ? this.oldUserInfo.filter(a => !ids.includes(a.id)) : []
                console.log('oldUserInfo', this.oldUserInfo)
                console.log('oldIds', ids)
                console.log('delInfo', delInfo)
                const d = delInfo.map(a => a.shareId)
                if (d.length) {
                    await this.__apis.customer.cancelShare(d)
                }

                const oldIds = this.oldUserInfo.map(a => a.id)
                const p = data.userInfo.filter(a => !oldIds.includes(a.id)).map(a => {
                    return this.__apis.customer.shareToSomeOne({
                        customer_id: data.id,
                        to_fxs_user_id: a.id
                    })
                })
                await Promise.all(p)

                this.$notify({
                    title: '成功',
                    message: '保存成功！',
                    type: 'success'
                })
                this.close()
                this.$emit('close')
            } finally {
                this.__utils.index.restoreRepeatClick()
            }
        } catch (e) {
            console.log(e)
        }
    }
}
