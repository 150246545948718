import { Component, Vue, Watch } from 'vue-property-decorator'
import addComponent from '@/components/success-detail/end/index.vue'
import { Notification } from 'element-ui'
import { SuccessDetailDispatchType } from '@/store/successDetail'
import { getSuccessDetailRes } from '@/api/financial'
import { GetMoneyStatus } from '@/util'

@Component({
    components: {
        addComponent
    }
})
export default class IndexPage extends Vue {
    addComponentOptions = {
        show: false,
        remark: '',
        money: '',
        date: '',
        id: undefined
    }

    add: any = {
        status: {
            value: '未结佣',
            data: GetMoneyStatus
        }
    }

    table: getSuccessDetailRes['DaoZhangList'] = []

    serverTimestamp: number = 0

    @Watch('allData')
    async watchAllData () {
        await this.loadEndData()
    }

    get
    allData () {
        return this.__store.state.success_detail.all_data
    }

    created () {
        if (this.allData.Id !== undefined) {
            this.loadEndData()
        }
    }

    // 过滤每一列的类容
    formatColumn (r: any, c: any, cellValue: any) {
        if (typeof cellValue === 'number') {
            return cellValue
        } else {
            return cellValue || ''
        }
    }

    // 修改记录
    editRecord (item: any) {
        this.addComponentOptions.date = item.row.DaozhangTime
        this.addComponentOptions.remark = item.row.Remark
        this.addComponentOptions.money = item.row.Money
        this.addComponentOptions.id = item.row.Id
        this.addComponentOptions.show = true
    }

    // 1小时内可以删除
    canDeleteDzRecord (ctime: string): boolean {
        const ct = Date.parse(ctime)
        const nt = this.serverTimestamp
        return ct + 3600 * 1000 > nt
    }

    // 删除记录
    async deleteRecord (item: any) {
        await this.__apis.financial.deleteUserSaleDaoZhangRecord({
            id: item.row.Id
        })

        // 保存成功
        Notification.success('删除结佣记录成功')

        this.__store.dispatch(SuccessDetailDispatchType.setAllData, +this.$route.params.id)
    }

    // 修改结佣状态
    async changeStatus (data: any) {
        await this.__apis.financial.saveSale({
            id: this.$route.params.id,
            jieyong_status: data
        })

        // 保存成功
        Notification.success('结佣状态修改成功')

        this.__store.dispatch(SuccessDetailDispatchType.setAllData, +this.$route.params.id)
    }

    showAddComponent () {
        this.addComponentOptions.date = ''
        this.addComponentOptions.remark = ''
        this.addComponentOptions.money = ''
        this.addComponentOptions.id = undefined
        this.addComponentOptions.show = true
    } // 显示添加弹窗

    async loadEndData () {
        const st = await this.__apis.system.getServerTime()
        this.serverTimestamp = st.timestamp

        // 处理状态
        this.add.status.value = this.allData.JieyongStatus

        this.table = this.allData.DaoZhangList
    }
}
