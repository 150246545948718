import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import mixin from '../../mixin'
import EditComponent from '@/components/customer/phone/edit/index.vue'
import InvalidComponent from '@/components/customer/phone/invaild/index.vue'
import marketCustomerComponent from '@/components/customer/phone/marketCustomer/index.vue'
import { EditData } from '@/components/customer/phone/edit'
import { User } from '@/store/user'
import { routerBaseUrl } from '@/router'
import addCustomer from './addCustomer/index.vue'
import seeAudioComponent from '@/components/media/audio/index.vue'
import zjCustomerComponent from './zjCustomer/index.vue'

interface TableItem {
    id: string
    customer_id: number
    house_id: string
    name: string
    mobile_fixed: string
    house_name: string
    demand_region: string
    type_array: string[]
    register_time: string
    has_dispatch: boolean
    tags: User['sysTags']
    remark: {
        log: string
    }[]
    call_url: string
}

@Component({
    mixins: [mixin],
    components: {
        addCustomer,
        EditComponent,
        InvalidComponent,
        marketCustomerComponent,
        seeAudioComponent,
        zjCustomerComponent
    }
})
export default class IndexPage extends Vue {
    invalidComponent = {
        show: false,
        id: ''
    }

    editComponent: {
        show: boolean
        item: EditData
    } = {
        show: false,
        item: {
            id: '',
            name: '',
            mobile: '',
            ctime: '',
            house_id: '',
            house_name: '',
            tags: [],
            remark: ''
        }
    }

    addMarketingCustomerComponent = {
        show: false
    }

    table: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        total: 0
    }

    // 排序需要用到的
    sortType = ''

    // 排序需要用到的
    sortMode = ''

    form: {
        ctime: string[],
        name: string,
        mobile: string,
        type: string,
        mark_list: string | string[]
    } = {
        ctime: [],
        name: '',
        mobile: '',
        type: '',
        mark_list: ''
    }

    markListData = [
        {
            label: '请选择',
            value: ''
        },
        {
            label: '无效客户',
            value: ['X', 'Y', 'Z']
        }
    ]

    seeAudio = {
        show: false,
        url: ''
    }

    customerCallType = [
        {
            label: '400客户',
            value: 'ftt'
        },
        {
            label: '头条客户',
            value: 'toutiao'
        },
        {
            label: '百度OCPC',
            value: 'bd_ocpc'
        },
        {
            label: '尚通',
            value: 'shangTong400'
        }
    ]

    zjCustomerInfo = {
        show: false,
        item: {
            id: ''
        }
    }

    get
    sysTags () {
        return this.__store.state.user.sysTags
    }

    mounted () {
        this.getData()
    }

    addSWTCustomerComponent = {
        show: false
    }

    addSWTCustomer () {
        this.addSWTCustomerComponent.show = true
    }

    addMarketCustomer () {
        this.addMarketingCustomerComponent.show = true
    }

    gotoDetail (item: TableItem) {
        open(`${routerBaseUrl}/customer/detail/${item.customer_id}`)
    }

    // 分配客户
    async setItem (item: TableItem) {
        if (this.__utils.index.limitRepeatClick()) return
        try {
            await this.__apis.customer.customerCallDispatch(+item.id)
            this.getData()
            this.$message.success('分配成功')
        } catch (e) {
            this.$message.error('分配失败')
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    // 分配客户， 随机伪装类型
    async setItemWithType (item: TableItem) {
        if (this.__utils.index.limitRepeatClick()) return
        try {
            const types = [
                1, // 预约看房
                2, // 接收通知
                // 3, // 领取红包
                4, // 领取优惠
                13 // 询底价
                // 43 // 领取户型资料
            ]
            const type = types[Math.floor(Math.random() * types.length)]

            await this.__apis.customer.customerCallDispatch(+item.id, 0, type)
            this.getData()
            this.$message.success('分配成功')
        } catch (e) {
            this.$message.error('分配失败')
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    // 标记无效
    setInvalid (item: TableItem) {
        this.invalidComponent.id = item.id
        this.invalidComponent.show = true
    }

    cmtBtnEditDisabled (item: TableItem) {
        return item.has_dispatch
    }

    // 客户姓名 意向楼盘
    cmtBtnSetDisabled (row: TableItem) {
        return !(row.house_id !== '0' && row.name) || this.cmtBtnEditDisabled(row)
    }

    // 操作客户成功
    editSuccess () {
        this.getData()
    }

    editItem (item: TableItem) {
        this.editComponent.item = {
            house_name: item.house_name,
            ctime: item.register_time,
            house_id: item.house_id,
            mobile: item.mobile_fixed,
            name: item.name,
            tags: item.tags,
            id: item.id,
            remark: item.remark[0] ? item.remark[0].log : ''
        }

        this.editComponent.show = true
    }

    async getData () {
        this.table.data = []
        const { data } = await this.__apis.customer.customerCall({
            origin: this.form.type,
            sort_field: this.sortType,
            sort_mode: this.sortMode,
            customer_name: this.form.name,
            customer_tel: this.form.mobile,
            registerDate: this.form.ctime && this.form.ctime.length ? {
                query_start_time: `${this.form.ctime[0]} 00:00:00`,
                query_end_time: `${this.form.ctime[1]} 23:59:59`
            } : undefined,
            mark_list: this.form.mark_list === '' ? undefined : this.form.mark_list as string[],
            page: this.table.page,
            pagesize: this.table.pageSize
        })

        this.table.data = data.list.map(a => {
            return {
                name: a.customer_name,
                house_name: a.house_name,
                mobile_fixed: a.phone,
                demand_region: a.demand_region,
                type_array: a.type_array,
                register_time: this.__utils.index.formatDate(new Date(a.register_time), 'YYYY-MM-DD HH:mm:ss'),
                has_dispatch: a.has_dispatch,
                house_id: (a.house_id || 0).toString(),
                tags: a.sys_tags.length ? a.sys_tags.split(',').reduce((s, a) => {
                    s.push(this.sysTags[a as any])
                    return s
                }, [] as User['sysTags']) : [],
                id: a.id.toString(),
                customer_id: a.customer_id,
                remark: a.follower_logs,
                call_url: a.call_video_url,
                origin: this.__utils.index.getLabelByValue(this.customerCallType, a.origin) || a.origin,
                depName: this.__utils.index.getDepInfoById(a.dep).v
            }
        })
        this.table.total = data.total
    }

    tableSizeChange (a: number) {
        this.table.pageSize = a
        this.getData()
    }

    tableCurrentChange (a: number) {
        this.table.page = a
        this.getData()
    }

    clickSearch () {
        this.table.page = 1
        this.getData()
    }

    resetFrom () {
        const form = this.$refs['form'] as any
        form.resetFields()
        this.form.mark_list = ''
        this.clickSearch()
    }

    async toPubPool (id: number) {
        await this.__apis.customer.toPubPool(id)
        this.$notify({
            title: '成功',
            message: '提交成功！',
            type: 'success'
        })
        await this.getData()
    }

    sortChange (column: any) {
        this.sortType = column.prop
        this.sortMode = column.order === 'ascending' ? 'asc' : 'desc'
        this.getData()
    }

    showAudio (url: string) {
        if (!url) return
        this.seeAudio = {
            show: true,
            url
        }
    }

    dispatchUser (item: TableItem) {
        this.zjCustomerInfo = {
            show: true,
            item: {
                id: item.id,
                ...item
            }
        }
    }
}
