// import { request, FormatError } from './request'
// import { getTracker } from './ga'
// import { HitBuilders } from 'wxapp-ga'
import { FormatError, RespError } from './errors'
import { apiConfig } from '@/api/config'
import axios from 'axios'
import { __localStorage, storageName } from '@/localStorage'

// 事件接口 onConnecting onConnect onClose onMessage

export type OnCallback = () => void
export type OnMessageCallback = (data: any) => void
export type OnConnectingCallback = OnCallback
export type OnConnectCallback = OnCallback
export type OnCloseCallback = OnCallback

function closeWebSocket (socket: WebSocket|null) {
    if (socket && socket.close) {
        socket.close(1000)
    }
}

export class MessageService {
    public onMessage: OnMessageCallback | undefined
    public onConnecting: OnConnectingCallback | undefined
    public onConnect: OnConnectCallback | undefined
    public onClose: OnCloseCallback | undefined

    private connectCount: number
    private connectInterval: number
    private maxConnectInterval: number
    private connectTimeout: number
    private connectTimeoutInterval: number
    private maxConnectTimeout: number
    private socket: WebSocket | null
    private appHidden: boolean
    private retryConnectTimer: null | number | undefined
    private delayCloseTimer: null | number | undefined

    private lastToken: {access_token: string, wss_url: string, timestamp: number}

    private reConnectOnClose: boolean | undefined
    private connectId: number | undefined

    constructor () {
        this.connectCount = 0
        this.connectInterval = 500 // 0.5s
        this.maxConnectInterval = 10 * 1000 // 10s
        this.connectTimeout = 1000 // 1s
        this.connectTimeoutInterval = 1000
        this.maxConnectTimeout = 8 * 1000
        this.socket = null
        this.appHidden = false
        this.lastToken = { access_token: '', wss_url: '', timestamp: 0 }
        this.reConnectOnClose = true
    }

    private _close () {
        if (this.retryConnectTimer) {
            clearTimeout(this.retryConnectTimer)
        }
        this.retryConnectTimer = null
        if (this.socket && this.socket.close) {
            // 清空事件
            this.socket.onerror = () => {}
            this.socket.onclose = () => {}
            this.socket.onmessage = () => {}
            closeWebSocket(this.socket)
        }

        this.socket = null
        this.retryConnectTimer = null
    }
    public async close () {
        this.reConnectOnClose = false
        this.lastToken = { access_token: '', wss_url: '', timestamp: 0 }
        this._close()
        this.connectCount = 0
    }

    public async connect () {
        // 关闭旧连接
        this._close()

        if (this.onConnecting) {
            try {
                this.onConnecting()
            } catch (e) {
            }
        }

        let socket: WebSocket
        let stopConnect = false // 是否已经超时（因为存在异步，可能超时事件提前触发，需要判断是否需要取消已经在进行中的连接流程）
        const timeStart = +new Date()
        const onMessageCallback = this.onMessage
        const connectId: number = this.connectId = timeStart

        const closeSocket = (socket: WebSocket) => {
            return new Promise((resolve, reject) => {
                closeWebSocket(socket)
                resolve()
                // socket.close({success: resolve, fail: reject})
            })
        }

        const _onClose = (res: any) => {
            if (!res) { res = {} }
            // console.log(res)
            // {code: 1000, reason: "normal closure"}
            console.log('websocket closed [' + res.code + ']' + (res.reason || ''))

            if (this.onClose && connectId === this.connectId) {
                try {
                    this.onClose()
                } catch (e) {
                }
            }

            if (this.reConnectOnClose) {
                setTimeout(() => this.connect(), this.connectInterval * 2)
            }
        }

        const _onError = (res: any) => {
            closeSocket(socket)
            console.log('socket error', res)
        }

        try {
            if (this.appHidden) {
                throw new Error('app hidden')
            }
            // 获取推送token
            const getAccessToken = async (): Promise<string> => {
                // token有效期5分钟
                if ((+new Date()) - this.lastToken.timestamp < 60 * 2 * 1000) {
                    console.log('use valid cached push access_token')
                    return this.lastToken.access_token
                }
                // 超过2min，重新获取
                const ret = await axios.post(apiConfig.ppApiUrl + '/push/access_token',
                    {
                        // 连接的是通用推送服务，需要告诉服务器自己是哪款app
                        scope: 'xiangju/admin'
                    }, {
                        headers: {
                            'ac-token': __localStorage.getLocalStorage(storageName.TOKEN)
                        }
                    }
                )
                this.lastToken = { access_token: ret.data.access_token, wss_url: ret.data.wss_url, timestamp: +new Date() }
                return ret.data.access_token
            }
            // 建立websocket连接
            const connect = async () => {
                await new Promise((resolve, reject) => {
                    socket = new WebSocket(apiConfig.MESSAGE_SERVER, [])
                    socket.onclose = () => {}
                    socket.onerror = () => {}
                    resolve()
                })
                // 等待连接open完成
                await new Promise((resolve) => {
                    socket.onopen = resolve
                })
            }
            // 用token注册该设备到消息push服务
            const pRegister = async () => {
                const [token] = await Promise.all([getAccessToken(), connect()])

                // 处理提前终止
                if (stopConnect) {
                    console.log('cancel before wss connect')
                    return
                }

                // 发送验证token
                await new Promise((resolve, reject) => {
                    socket.send(JSON.stringify({ type: 0x0001, token }))
                    resolve()
                })
                // 等待验证结果
                // 如果服务器不接受会断开连接
                await new Promise((resolve, reject) => {
                    let regDone = false
                    let regSuccess = false
                    const onMessage = (res: any) => {
                        if (res.data) {
                            try {
                                const ret = JSON.parse(res.data)
                                // keepalive ack
                                if (ret.type === 0x00000011) {
                                    // socket.send({data: JSON.stringify({...ret, type: 0x00000012})})
                                    socket.send(JSON.stringify({ ...ret, type: 0x00000012 }))
                                    return
                                }

                                if (!regDone) {
                                    regDone = true
                                    if (ret.type === 0x00000002) {
                                        if (ret.errcode === 0) {
                                            regSuccess = true
                                            resolve()
                                        } else {
                                            reject(new Error(`[${ret.errcode}] ${ret.errmsg}`))
                                        }
                                    } else {
                                        reject(new Error('unknown response in register'))
                                    }
                                    return
                                }
                            } catch (e) {
                            }
                        }
                        if (!regDone || !regSuccess) { return }

                        try {
                            const data = JSON.parse(res.data)
                            // console.log('socket receive message', data)
                            if (typeof onMessageCallback === 'function') {
                                try {
                                    onMessageCallback(data)
                                } catch (e) {
                                }
                            }
                        } catch (e) {
                            // console.log('error', e)

                        }
                    }
                    socket.onmessage = onMessage
                })
            }
            const timeout = Math.min(this.maxConnectTimeout, this.connectTimeout + this.connectTimeoutInterval * this.connectCount)
            const pTimeout = new Promise((resolve, reject) => setTimeout(() => {
                reject(new Error(`timeout in ${timeout} ms`))
            }, timeout))

            await Promise.race([pRegister(), pTimeout])
        } catch (e) {
            stopConnect = true // 发生错误或超时，需要停止正在进行的异步流程

            closeWebSocket(socket!)

            if (!(e instanceof Error && e.message === 'app hidden')) {
                // 主动关闭连接的情况，不记录错误信息

                console.log('websocket connect error', FormatError(e))

                let logError = true
                if (e instanceof RespError) { // 不记录业务错误
                    logError = false
                } else if (typeof e === 'string' && e.indexOf('timeout in ') === 0) { // 不记录存粹的timeout错误
                    logError = false
                }

                // if (logError) {
                //     // ga跟踪
                //     const screenName = 'init'
                //     const t = getTracker()
                //     const emsg = FormatError(e)
                //     t.setScreenName(screenName)
                //     t.send(new HitBuilders.ExceptionBuilder()
                //         .setDescription(`wx.connectSocket error:\n${emsg}\nurl: ${config.MESSAGE_SERVER}`)
                //         .setFatal(false).build())
                // }
            }

            this.connectCount++
            const wait = Math.min(this.connectInterval * this.connectCount * this.connectCount, this.maxConnectInterval)
            this.retryConnectTimer = setTimeout(() => this.connect(), wait)
            return
        }

        this.socket = socket!

        const timeCost = (+new Date() - timeStart)
        console.log('websocket connected in ' + timeCost + ' ms.')
        this.connectCount = 0
        this.reConnectOnClose = true

        if (this.onConnect) {
            try {
                this.onConnect()
            } catch (e) {
            }
        }

        socket!.onclose = _onClose

        socket!.onerror = _onError

        // 刷新最新通知
        //  store.dispatch(ACTION_REFRESH_LATEST_NOTIFICATIONS)

        // ga跟踪
        // getTracker().send(new HitBuilders.TimingBuilder()
        //     .setCategory('计时器')
        //     .setValue(timeCost)
        //     .setVariable('push连接')
        //     .setLabel('网络连接').build())
        //
    }

    // 发送数据给服务器
    public send (data: string | ArrayBufferLike | Blob | ArrayBufferView) {
        if (this.socket) {
            try {
                this.socket.send(data)
            } catch (e) {}
        }
    }

    private onAppHide () {
        this.appHidden = true

        // 延迟关闭
        this.delayCloseTimer = setTimeout(() => {
            // 已经打开
            if (!this.appHidden) {
                return
            }
            closeWebSocket(this.socket)
        }, 60 * 1000) // 1min之后断开连接
    }

    private onAppShow () {
        this.appHidden = false
        this.connectCount = 0
        // 取消关闭任务
        if (this.delayCloseTimer) {
            clearTimeout(this.delayCloseTimer)
            this.delayCloseTimer = null
        }
        // 优化：如果下次连接任务还在定时器延时中，立即执行连接
        if (this.retryConnectTimer) {
            clearTimeout(this.retryConnectTimer)
            this.retryConnectTimer = null
            this.connect()
        }
    }
}

export const messageService = (() => {
    let instance: any = null
    return (): MessageService => {
        if (!instance) {
            instance = new MessageService()
        }
        return instance
    }
})()
