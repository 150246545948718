import { Component, Vue } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import FilterBusSelectComponent from '@/components/filters/filter-business/select/index.vue'
import inputDateRange from '@/components/filters/inputDateRange/index.vue'
import { Table } from '@/types'
import dataRange from '@/util/date.ts'

// 提成分数单价
export const singleScoreMoney: {
    [index: number]: number
} = {
    2: 8, // 杭州东
    4: 10, // 广东
    6: 8 // 杭州西
}

interface TableItem {
    user_id: number
    userName: string
    depName: string,
    roleName: string
    dateTimeText: string
    score: number
    scoreMoney: string
}

interface filterData {
    user_id?: number
    start_date: string
    end_date: string
    date?: string
}

@Component({
    components: {
        FilterComponent,
        FilterBusSelectComponent,
        inputDateRange
    }
})
export default class JiXiaoScorePage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0
    }

    filter: filterData = {
        start_date: dataRange.getCurrentMonth()[0].format('yyyy-MM-dd'),
        end_date: dataRange.getCurrentMonth()[1].format('yyyy-MM-dd')
    }

    created () {
        this.getData()
    }

    getStartEndDateByYearMonth (year:number, month:number) {
        let startDay = new Date(year, month, 1)
        if (month === 11) {
            year++
            month = 0
        } else {
            month++
        }
        let millisecond = 1000 * 60 * 60 * 24
        let nextMonthDayOne = new Date(year, month, 1)
        let lastDay = new Date(nextMonthDayOne.getTime() - millisecond)
        return [startDay, lastDay]
    }

    async getData () {
        const { data } = await this.__apis.channelManage.getGroupByUserIdScoreList({
            ...this.filter
        })
        this.tableData.data = data.map(a => {
            return {
                user_id: a.user_id,
                userName: a.user_info.user_name,
                depName: a.user_info.department_name,
                roleName: a.user_info.role_name,
                dateTimeText: this.__utils.index.formatDate(new Date(this.filter.start_date), 'YYYY-MM'),
                score: a.total_score,
                scoreMoney: '¥' + (a.total_score * singleScoreMoney[a.user_info.department as number]).toFixed(2)
            }
        })
    }

    objectSpanMethod ({ column, rowIndex }: any) {
        let data:{name: any}[] = []
        if (column.property === 'depName') {
            data = this.__utils.index.merageTableArr(this.tableData.data.map((v) => {
                return {
                    name: v.depName
                }
            }))
            return this.__utils.index.returnTableSpan(data, rowIndex)
        }
    }

    currentChange (page: number) {
        this.tableData.page = page
        this.getData()
    }

    filterChange (filter: filterData) {
        console.log(filter)
        const d = filter.date ? new Date(filter.date) : new Date()
        const dArr = this.getStartEndDateByYearMonth(d.getFullYear(), d.getMonth())
        filter.start_date = dArr ? new Date(dArr[0]).format('yyyy-MM-dd') : ''
        filter.end_date = dArr ? new Date(dArr[1]).format('yyyy-MM-dd') : ''
        this.filter = filter
        this.getData()
    }

    toDetail (item:TableItem) {
        this.$router.push(`/channelManage/performanceAppraisal/score/detail/${item.user_id}?name=${item.userName}`)
    }
}
