import { Component, Vue } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'
import InputSelectComponent from '@/components/filters/inputSelect/index.vue'
import { Table } from '@/types'
import importComponent from './import/index.vue'

interface TableItem {
    id: number
    employeeId: string
    department: string,
    fxs: string,
    money: string,
}

interface Filter {
    [index: string]: string
    fxs: string
}

@Component({
    components: {
        FilterComponent,
        SelectComponent,
        FilterBusinessSelect,
        InputSelectComponent,
        importComponent
    }
})
export default class IndexPage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 50
    }

    filter: Filter = {
        fxs: ''
    }

    type = '6'

    importComponent = {
        show: false,
        data: {
            department: '',
            group: '',
            userId: '',
            userName: '',
            allMoney: ''
        }
    }

    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        this.getTableData()
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }

    searchFxs (text: string, cb: any) {
        this.__utils.index.searchEmployee(text, cb)
    }

    toMoneyDetail (item: TableItem) {
        const { href } = this.$router.resolve({
            path: `/financial/chongzhi-wallet/record-detail?employee_id=${item.id}`
        })
        window.open(href, '_blank')
    }

    importSuccess () {
        this.$message({
            type: 'success',
            message: '添加成功'
        })

        this.tableData.page = 1
        this.getTableData()
    }

    showImportComponent () {
        this.importComponent.show = true
        this.importComponent.data = {
            department: '',
            group: '',
            userId: '',
            userName: '',
            allMoney: ''
        }
    }

    showImportItemComponent (item: TableItem) {
        this.importComponent.show = true
        this.importComponent.data.department = item.department
        // this.importComponent.data.group = item.group
        this.importComponent.data.userId = item.employeeId
        this.importComponent.data.userName = item.fxs
        this.importComponent.data.allMoney = ''
    }

    async getTableData () {
        const { data } = await this.__apis.chongzhiWallet.walletList({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            employee_id: this.filter.fxs
        })

        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.employee_id,
                department: this.__utils.index.getComDepInfoById(a.employee_common_dep).name,
                fxs: a.employee_user_name,
                money: this.__utils.index.formatMoney(a.money),
                employeeId: a.employee_id.toString()
            }
        })
    }

    created () {
        this.getTableData()
    }
}
