import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import questionBankSetComponent from '@/components/questionBank/set/index.vue'

interface TableItem {
    id: number
    depId: number
    depName: string
    questionTotal: number
    questionUseTotal: number
    updateTime: string
}

@Component({
    components: {
        questionBankSetComponent
    }
})
export default class QuestionBankStatisticsPage extends Vue {
    form: {
        dep?: number
    } = {
        dep: undefined
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0
    }

    questionBankSet = {
        visible: false,
        item: {}
    }

    get
    depArr () {
        return this.__utils.index.getXsDep()
    }

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    created () {
        this.form.dep = this.userInfo.dep || undefined
        this.getData()
    }

    async getData () {
        const filter = this.form
        const { data } = await this.__apis.activity.questionsCompanyStatistic()
        this.tableData.data = data.map(a => {
            return {
                id: 0,
                depId: a.dep_id,
                depName: a.dep_name || (a.dep_id === 0 ? '公司' : ''),
                questionTotal: a.question_count,
                questionUseTotal: a.use_question_count,
                updateTime: this.__utils.index.formatDate(new Date(a.last_update_time), 'YYYY-MM-DD HH:mm:ss')
            }
        }).filter(a => filter.dep ? a.depId === filter.dep : true)
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getData()
    }

    resetFilter () {
        const form = this.$refs['form'] as any
        form.resetFields()
        this.tableCurrentChange(1)
    }

    async seeDetail (item: TableItem) {
        await this.$router.push({
            path: `/questionBank/manage?dep=${item.depId}`
        })
    }

    showQuestionSet () {
        this.questionBankSet.visible = true
    }
}
