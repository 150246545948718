import { Module } from 'vuex'

export const UPDATE_CONNECTION_STATE = 'UPDATE_CONNECTION_STATE'

export enum ConnectionState {
    CONNECTING = 1,
    CONNECTED = 2,
    CLOSED = 3
}

const initConnectionState: ConnectionState = ConnectionState.CLOSED

export interface StoreConnectionState {
    connectionState: ConnectionState
}

export default <Module<StoreConnectionState, any>>{
    namespaced: true,

    state: () => ({
        connectionState: initConnectionState
    }),
    mutations: {
        [UPDATE_CONNECTION_STATE] (state:StoreConnectionState, payload: ConnectionState) {
            state.connectionState = payload
        }
    }
}
