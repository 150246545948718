import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import FilterComponent from '@/components/filters/filter/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import checkBoxComponent from '@/components/filters/checkBox/index.vue'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'
import { ExitStatus } from '@/util'

interface TableItem {
    time: string
    fxs: {
        username: string
        rate: string
    }[]
    customer_name: string
    customer_phone: string
    income: number
    should: number
    exit: string
}

interface Filter {
    exit?: number
    fxs?: number
    date: string[]
}

@Component({
    components: {
        FilterComponent,
        InputDateRangeComponent,
        checkBoxComponent,
        FilterBusinessSelect
    }
})
export default class SuccessRecordComponent extends Vue {
    created () {
        this.getTableData()
    }

    exitStatus = ExitStatus

    getExitText (exit: number) {
        let label = ''

        for (let i = 0; i < this.exitStatus.length; i++) {
            if (this.exitStatus[i].value === exit) {
                label = this.exitStatus[i].label
                break
            }
        }

        return label
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 50
    }

    getFxs (index: number) {
        return this.tableData.data[index].fxs
    }

    async getTableData () {
        const { data } = await this.__apis.user.getMyGroupUserSaleRecord({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            start_time: this.filter.date[0],
            end_time: this.filter.date[1],
            has_tuifan: this.filter.exit,
            fxs_id: this.filter.fxs
        })

        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            const userList = a.proportion.user_info.map(b => {
                return {
                    username: b.name,
                    rate: `${(Number(b.money) / a.sum_money * 100).toFixed(2)}%`
                }
            })

            return {
                time: this.__utils.index.formatDate(new Date(a.sale_time), 'YYYY-MM-DD'),
                fxs: userList,
                customer_name: a.customer_name,
                customer_phone: a.customer_tel,
                income: a.sum_money,
                should: a.sum_must_dz_money,
                exit: this.getExitText(a.has_tuifan)
            }
        })
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }

    filter: Filter = {
        exit: undefined,
        fxs: undefined,
        date: []
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        if (!this.filter.date) {
            this.filter.date = []
        } else {
            this.filter.date = this.__utils.index.formatTimeStartTimeEnd(this.filter.date)
        }

        this.getTableData()
    }
}
