import { Vue, Component, Prop } from 'vue-property-decorator'
import { getPxbTrainDetailRes } from '@/api/grow'
import { peiXunStatusList } from '@/util/const'
import { __utils } from '@/util'

interface form {
    peiXunQiShu: getPxbTrainDetailRes['number_periods']
    kaibanDate: string
    jieXunDate: string
    riChangGuanLi: getPxbTrainDetailRes['daily_manage_lecture']
    liLunZhiShi: getPxbTrainDetailRes['knowledge_lecture']
    moNiJiangPan: getPxbTrainDetailRes['analog_lecture']
    peiXunXinDe: getPxbTrainDetailRes['training_experience']
    jieYePingYu: getPxbTrainDetailRes['comment']
    peiXunStatus: getPxbTrainDetailRes['is_done']
    xiaDuiDate: string
    xiaDuiName: getPxbTrainDetailRes['team_leader']
    peiXunStatusLabel: string
}

@Component
export default class XinXunPeiXunComponent extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    edit !: boolean

    @Prop({
        type: Object,
        default: () => {
            return {
                analog_lecture: null,
                comment: '',
                ctime: 0,
                daily_manage_lecture: null,
                down_team: null,
                employee_id: 0,
                end_time: null,
                is_done: 0,
                knowledge_lecture: null,
                mtime: 0,
                number_periods: 0,
                start_time: 0,
                team_leader: '',
                training_experience: ''
            }
        }
    })
    source !: getPxbTrainDetailRes

    peiXunStatusList = peiXunStatusList

    isEdit = false

    editStatueHandle (bl: boolean) {
        this.isEdit = bl
    }

    form: form = {} as form

    created () {
        this.form = {
            peiXunQiShu: this.source.number_periods,
            kaibanDate: this.source.start_time ? this.__utils.index.formatDate(new Date(this.source.start_time), 'YYYY-MM-DD') : '',
            jieXunDate: this.source.end_time ? this.__utils.index.formatDate(new Date(this.source.end_time), 'YYYY-MM-DD') : '',
            riChangGuanLi: this.source.daily_manage_lecture,
            liLunZhiShi: this.source.knowledge_lecture,
            moNiJiangPan: this.source.analog_lecture,
            peiXunXinDe: this.source.training_experience,
            jieYePingYu: this.source.comment,
            peiXunStatus: this.source.is_done,
            peiXunStatusLabel: __utils.index.getLabelByValue(peiXunStatusList, this.source.is_done),
            xiaDuiDate: this.source.down_team ? this.__utils.index.formatDate(new Date(this.source.down_team), 'YYYY-MM-DD') : '',
            xiaDuiName: this.source.team_leader
        }
    }

    async editSave () {
        this.editStatueHandle(false)

        const form = this.form
        await this.__apis.grow.saveTrainingManage({
            employee_id: this.source.employee_id,
            analog_lecture: form.moNiJiangPan,
            comment: form.jieYePingYu,
            daily_manage_lecture: form.riChangGuanLi,
            down_team: form.xiaDuiDate ? new Date(form.xiaDuiDate).getTime() : 0,
            end_time: form.jieXunDate ? new Date(form.jieXunDate).getTime() : 0,
            is_done: form.peiXunStatus,
            knowledge_lecture: form.liLunZhiShi,
            number_periods: form.peiXunQiShu,
            start_time: form.kaibanDate ? new Date(form.kaibanDate).getTime() : 0,
            team_leader: form.xiaDuiName,
            training_experience: form.peiXunXinDe
        })

        this.$message.success('保存成功！')
        this.$emit('save')
    }
}
