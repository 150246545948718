import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'
import FilterComponent from '@/components/filters/filter/index.vue'
import dateRangeSelectComponent from '@/components/wallet/date-range-select/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'
import userEditComponent from '@/components/system/createUser/index.vue'
import closeComponent from './close/index.vue'
import { FxsUserType } from '@/util/const'

export interface TableItem {
    Id: number
    deactive: number
    depName: string
    groupName: string
    userName: string
    roleName: string
    ctime: string
    dep: number
    group: number
    type: string
}

@Component({
    components: {
        FilterComponent,
        dateRangeSelectComponent,
        SelectComponent,
        InputDateRangeComponent,
        FilterBusinessSelect,
        userEditComponent,
        closeComponent,
        CheckBoxComponent
    }
})
export default class AccountUserList extends Vue {
    closeComponent = {
        show: false,
        id: 0,
        name: ''
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0
    }
    filter:{
        dep_id?: number
        group_id?: number
        user_id?: number
        time_start?: string
        time_end?: string
    } = {}
    userEdit = {
        visible: false,
        data: {}
    }

    FxsUserType = FxsUserType

    created () {
        this.getData()
    }

    async getData () {
        let { data } = await this.__apis.system.getFxsList({
            ...this.filter,
            page: this.tableData.page,
            pagesize: this.tableData.pageSize
        })
        this.tableData.total = data.total
        this.tableData.data = data.list.map(v => {
            return {
                Id: v.id,
                deactive: v.deactive,
                depName: this.__utils.index.getDepInfoById(v.department).v,
                groupName: v.group_name,
                userName: v.username,
                roleName: v.role_name,
                ctime: this.__utils.index.formatDate(new Date(v.ctime), 'YYYY-MM-DD'),
                dep: v.department,
                group: v.group,
                type: this.__utils.index.getLabelByValue(FxsUserType, v.regular)
            }
        })
    }

    // 筛选确定
    filterChange (item: any) {
        // console.log('filter', item)
        Object.keys(item).map((v:any) => {
            if (v === 'ctime') {
                item.time_start = item[v][0] + ' 00:00:00'
                item.time_end = item[v][1] + ' 23:59:59'
                delete item[v]
            }
            if (v === 'action_time') {
                item.action_time = item[v][0]
            }
        })
        this.filter = item
        this.tableData.page = 1
        this.getData()
    }

    // 是否是关闭账号
    isDeActive (active: number) {
        return active === 1
    }

    // 账号关闭了的处理显示样式
    tableRowClassFun (item: {row:any, rowIndex:number}) {
        return this.isDeActive(item.row.deactive) ? 'opacity_tr_text' : ''
    }

    // 编辑用户
    editUser (obj: any) {
        let item = this.__utils.index.copyObj(obj)
        // console.log('editUser', item)
        this.userEdit.data = {
            Id: item.Id
        }
        this.userEdit.visible = true
    }

    // 显示关闭弹窗
    showClose (item: TableItem) {
        this.closeComponent.show = true
        this.closeComponent.id = item.Id
        this.closeComponent.name = item.userName
    }

    // 激活用户
    async activeAccount (item: TableItem) {
        try {
            await this.$confirm(`请确认是否激活 <span class="name">${item.userName}</span> 的账号`, '激活账号', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                customClass: 'my_confirm my_confirm_fxs',
                center: true
            })

            await this.__apis.system.userActiveChange({
                deactive: 0, // 0 开启  1关闭
                id: item.Id
            })

            this.$message({
                type: 'success',
                message: '激活成功!'
            })

            this.getData()
        } catch (e) {
            console.log(e)
        }
    }

    // page变化
    tableCurrentChange (page: number) {
        this.tableData.page = page
        this.getData()
    }
}
