import { Component, Vue } from 'vue-property-decorator'
import InputHouseSearch from '@/components/InputHouseSearch/index.vue'
import { customerListRes } from '@/api/customer'
import mixin from '../mixin'

@Component({
    components: {
        InputHouseSearch
    },
    mixins: [mixin]
})
export default class CustomerClinchPage extends Vue {
    customerSelectCurrent = 0
    searchVisible = true
    form: any = {
        ctime: '',
        last_follow_time: '',
        name: '',
        mobile: '',
        house: '',
        mark: '',
        tag: '',
        impt_group: '',
        intention_area: '',
        keywords: '',
        source_type: '',
        dep: '',
        group: '',
        user_id: '',
        renling_type: ''
    }
    dataObj: customerListRes = {} as any
    page = 1
    pageSize = 50
    total = 0
    sysTags2: {
        [index: string]: any
    } = {}

    sysTags3: {
        [index: string]: any
    } = {}
    dialogEdit = false
    editForm = {
        tag: '',
        impt_group: -1,
        log: ''
    }
    formLabelWidth = '120px'
    sortType = ''
    sortMode = ''

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    created () {
        this.sysTags2 = this.sysTagsIn()
        this.sysTags3 = this.sysTagsIn()

        this.form.dep = this.infoPos.dep
        this.form.group = this.infoPos.group
        this.form.user_id = this.infoPos.fxs
        this.getData()
    }

    // 是否有外呼标签
    hasRobotTag (item: any) {
        return item.type_array.includes('七鱼机器人外呼')
    }

    // 拷贝系统标签
    sysTagsIn () {
        return this.__store.state.user.sysTags && this.__utils.index.copyObj(this.__store.state.user.sysTags)
    }

    async getData () {
        let sys = []
        if (this.sysTags2) {
            for (let key in this.sysTags2) {
                if (this.sysTags2[key].active) {
                    sys.push(this.sysTags2[key].value)
                }
            }
        }
        let data: any = {
            type: 'cj_customer', 		// this.$route.params.type
            has_cj: 1,
            page: this.page,
            pagesize: this.pageSize,
            sys_tag: sys.join(','),
            ctime_begin: this.form.ctime ? this.form.ctime[0] : '',
            ctime_stop: this.form.ctime ? this.form.ctime[1] : '',
            last_follow_time_begin: this.form.last_follow_time ? this.form.last_follow_time[0] : '',
            last_follow_time_stop: this.form.last_follow_time ? this.form.last_follow_time[1] : '',
            sort_field: this.sortType,
            sort_mode: this.sortMode
        }
        let form = this.__utils.index.copyObj(this.form)
        data = Object.assign(data, {
            ...form,
            ctime: '',
            last_follow_time: '',
            cj_dep: this.form.dep,
            cj_group: this.form.group,
            cj_fxs: this.form.user_id
        })

        delete data.dep
        delete data.group
        delete data.user_id
        if (data.cj_fxs) {
            delete data.cj_dep
            delete data.cj_group
        }
        const res = await this.__apis.customer.getCustomerList(data)
        this.dataObj = res.data
        this.total = res.data.res.total
    }

    // 客户来源筛选
    changeFromType (value: string) {
        if (this.form.renling_type === value) {
            this.form.renling_type = ''
        } else {
            this.form.renling_type = value
        }
    }

    // 搜索
    clickSearch () {
        this.page = 1
        this.getData()
    }

    // 清空筛选
    resetFrom (formName: string) {
        const form = this.$refs[formName] as any
        form.resetFields()
        for (let key in this.sysTags2) {
            this.$set(this.sysTags2[key], 'active', false)
        }
        this.form.renling_type = ''
        this.page = 1
        this.getData()
    }

    // 筛选展开收起
    clickSearchVisible () {
        this.searchVisible = !this.searchVisible
    }
}
