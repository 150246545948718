export enum MessageType {
    TIME_REQUEST = 0x00001001, // 时间查询
    TIME_RESPONSE = 0x00001002, // 时间应答

    // 其他业务相关消息类型
    TASK_COMPLETED = 0x00002001, // 离线任务完成
    ORDER_STATUS_CHANGED = 0x00002002, // 订单状态变化
    TRADE_STATUS_CHANGED = 0x00002003, // 交易状态变化

    // xiangju/admin 相关 0x00003XXX
    MSG_CENTER_NEW_MESSAGE = 0x00003001, // 消息中心新消息 {id:<new msg id>}
}
