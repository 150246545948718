import { Component, Prop, Vue } from 'vue-property-decorator'

interface options {
    fullName: string
    simpleName: string
    dep: string
    intro: string
    people: any[]
    startTime: string
    status: string
}

@Component
export default class SuccessDetailChannelDetail extends Vue {
    @Prop({
        type: Boolean,
        default: false
    })
    show !: boolean

    @Prop({
        type: Object,
        default: {
            fullName: '',
            simpleName: '',
            dep: '',
            intro: '',
            people: [],
            startTime: '',
            status: ''
        }
    })
    options !: options

    closeSelf () {
        this.$emit('update:show', false)
    }
}
