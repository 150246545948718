import { Component, Vue } from 'vue-property-decorator'
import { Table } from '@/types'

const controversialExamineStatus = [
    {
        label: '申请中',
        value: 1
    },
    {
        label: '通过',
        value: 2
    },
    {
        label: '驳回',
        value: 3
    }
]

interface TableItem {
    id: number,
    userId: number
    saleId: number
    userName: string
    depName: string,
    houseName: string
    channelName: string
    duiJieUserInfo: {
        userName: string
        mobile: string
    }[]
    remark: string
    statusText: string
    status: number
}

@Component({
    components: {}
})
export default class ControversialExaminePage extends Vue {
    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSize: 20,
        total: 0
    }

    created () {
        this.getData()
    }

    async getData () {
        const { data } = await this.__apis.channelManage.getChannelProblemDealSlipRecord({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize
        })
        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.id,
                userId: a.apply_user_id,
                saleId: a.sale_id,
                userName: a.user_info.user_name,
                depName: a.user_info.department_name,
                houseName: a.house_name,
                channelName: a.channel_name,
                duiJieUserInfo: a.duijie_user_info.map(a => {
                    return {
                        userName: a.username,
                        mobile: a.phone
                    }
                }),
                remark: a.remarks,
                statusText: this.__utils.index.getLabelByValue(controversialExamineStatus, a.status),
                status: a.status
            }
        })
    }

    currentChange (page: number) {
        this.tableData.page = page
        this.getData()
    }

    toDetail (item:TableItem, newWin: boolean) {
        const url = `/financial/success_detail/success/${item.saleId}`
        if (newWin) {
            let { href } = this.$router.resolve(url)
            window.open(href, '_blank')
        } else {
            this.$router.push(url)
        }
    }

    async examineHandle (item:TableItem, type: number) {
        await this.__apis.channelManage.updateProblemDealSlipStatus({
            id: item.id,
            status: type
        })
        this.$notify({
            title: '成功',
            message: `${type === 2 ? '通过' : '驳回'}成功！`,
            type: 'success'
        })
        await this.getData()
    }

    btnStatusDisabled (status:number) {
        return status > 1
    }
}
