import { Component, Vue } from 'vue-property-decorator'
import dataRange from '@/util/date.ts'

@Component
export default class SaleManGenJinPage extends Vue {
    dataObj: any = {}
    page = 1
    pageSize = 50
    total = 0
    dateFilter = ['不限', '昨天', '本周', '上周']
    dateFilterCur = 0
    form: any = {
        ctime: '',
        username: '',
        phone: '',
        house: '',
        dep: '',
        group: '',
        user_id: ''
    }
    customerSelectCurrent = 0

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }
    get
    impt () {
        return this.__store.state.user.impt
    }
    get
    customerTags () {
        return this.__store.state.user.customerTags
    }

    mounted () {
        this.form.dep = this.infoPos.dep
        this.form.group = this.infoPos.group
        this.form.user_id = this.infoPos.fxs

        this.getData()
    }

    async getData () {
        let form = this.__utils.index.copyObj(this.form)
        form.ctime = ''
        let data = Object.assign(form, {
            action_name: 'getFollowCustomerList',
            page: this.page,
            pagesize: this.pageSize,

            time_start: this.form.ctime ? this.form.ctime[0] : '',
            time_end: this.form.ctime ? this.form.ctime[1] : ''
        })

        const res = await this.__apis.saleman.getFollowCustomerList(data)

        res.data.list.map((v: any) => {
            v.is_valid *= 1
        })
        this.dataObj = res.data
        this.dataObj.show = true
        this.total = res.data.total
    }

    pageChange (page: number) {
        this.page = page
        this.getData()
    }

    selectHandle (index: number, type: string) {
        // @ts-ignore
        this[type] = index
        this.getData()
    }

    // 部门改变
    departSelect () {
        this.form.group = ''

        if (!this.form.dep) {
            this.form.user_id = ''
        }
    }

    // 组改变
    groupSelect () {
        this.form.user_id = ''
    }

    // 日期按钮
    dateFilterHandle (index: number, key: string) {
        // @ts-ignore
        this[key] = index
        let dateTime
        if (index === 1) {
            dateTime = {
                time_start: dataRange.getCurrentYesterday().format('yyyy-MM-dd'),
                time_end: dataRange.getCurrentYesterday().format('yyyy-MM-dd')
            }
        } else if (index === 2) {
            dateTime = {
                time_start: dataRange.getCurrentWeek()[0].format('yyyy-MM-dd'),
                time_end: dataRange.getCurrentWeek()[1].format('yyyy-MM-dd')
            }
        } else if (index === 3) {
            dateTime = {
                time_start: dataRange.getPreviousWeek()[0].format('yyyy-MM-dd'),
                time_end: dataRange.getPreviousWeek()[1].format('yyyy-MM-dd')
            }
        } else {
            dateTime = {
                time_start: '',
                time_end: ''
            }
        }
        this.form.ctime = [dateTime.time_start, dateTime.time_end]
    }

    // 搜索
    clickSearch () {
        this.page = 1
        this.getData()
    }

    // 清空筛选
    resetFrom (formName: string) {
        const form = this.$refs[formName] as any
        form.resetFields()
        this.getData()
    }

    routerPush (obj: any) {
        this.$router.push(obj)
    }
}
