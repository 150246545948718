import { Component, Vue } from 'vue-property-decorator'
import noData from '@/components/NoData/index.vue'

@Component({
    components: {
        noData
    }
})
export default class NoPage extends Vue {
    text = '页面不存在'
    msg = '404 Not found 路由!! '
}
