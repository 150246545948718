import { getAllPermissionsRes, getAllRoleRes, saveRolePermissionReq, saveUserPermissionsReq } from '@/api/system'
import { Vue } from 'vue-property-decorator'
import { __apis } from '@/api'

// 保存权限
export async function saveRole (options: saveRolePermissionReq) {
    try {
        await __apis.system.saveRolePermission({
            permissions_ids: options.permissions_ids,
            role_id: options.role_id
        })
    } catch (e) {
        throw e
    }
}
export async function saveUser (options: saveUserPermissionsReq) {
    try {
        await __apis.system.saveUserPermissions({
            permissions_ids: options.permissions_ids,
            user_id: options.user_id
        })
    } catch (e) {
        throw e
    }
}
export let tree_refresh = {
    value: false
}

// 权限树
let tree_data: {
    pc: mergeTreeRes[],
    xcx: mergeTreeRes[]
} = {} as any
let pc_data1 !: getAllPermissionsRes[]
let pc_data2 !: getAllPermissionsRes[]
let xcx_data1 !: getAllPermissionsRes[]
let xcx_data2! : getAllPermissionsRes[]
export async function Tree () {
    // 缓存数据，这里目前只用获取一次
    if (tree_refresh.value || !pc_data1 || !pc_data2 || !xcx_data1 || !xcx_data2) {
        tree_refresh.value = false
        tree_data = Vue.observable({
            pc: [],
            xcx: []
        })

        const [{ data: modules }, { data: all }] = await getTree()
        // pc
        pc_data1 = modules.filter(a => a.client_id === 1)
        pc_data2 = all.filter(a => a.client_id === 1)
        // xcx
        xcx_data1 = modules.filter(a => a.client_id === 2)
        xcx_data2 = all.filter(a => a.client_id === 2)
    }

    // 每次赋值前需要删除之前的值
    tree_data.pc.splice(0, tree_data.pc.length)
    tree_data.xcx.splice(0, tree_data.xcx.length)
    // observe 不可以直接覆盖
    // 每次调用需要返回新的一组数据，tree在外部会被 el-tree 污染
    tree_data.pc.push(...mergeTree(pc_data1, pc_data2))
    tree_data.xcx.push(...mergeTree(xcx_data1, xcx_data2))

    return {
        ...tree_data,
        setDisableNodeByIds,
        restoreTreeStatus,
        getModules
    }
}

// The module name can be repeated, so a prefix is required
function getModules (tree: mergeTreeRes[], prefix?: string) {
    const res: roleType = {}

    for (let i = 0; i < tree.length; i++) {
        res[Number(tree[i].id.slice(1))] = prefix + tree[i].name
    }
    return res
}

// restore disabled status
// fix el-tree
function restoreTreeStatus (tree: mergeTreeRes[]) {
    tree.forEach(a => {
        a.disabled = false
        a.children.map(b => {
            b.disabled = false
        })
    })
}

// setDisableNode
function setDisableNodeByIds (tree: mergeTreeRes[], ids: number[]) {
    tree.forEach(a => {
        const len = a.children.filter(b => ids.includes(b.id)).map(b => {
            b.disabled = true
        }).length

        if (a.children.length && len === a.children.length) {
            a.disabled = true
        }
    })
}

// 暂时只支持2级
function mergeTree (parent: getAllPermissionsRes[], children: getAllPermissionsRes[]): mergeTreeRes[] {
    const res: {
        [index: number]: mergeTreeRes
    } = {}

    parent.forEach(a => {
        // 这里需要注意 修改了parent id这个操作基本是不可逆的，目前是不需要用到这个id干什么，传输入到后端也只需要children的id
        const id = `_${a.id}`
        res[a.id] = {
            ...a,
            id,
            disabled: false,
            children: []
        }
    })

    children.forEach(a => {
        const parent = res[a.pid]
        if (!parent) return

        // 禁用权限不会显示
        a.status === 1 && parent.children.push({
            ...a,
            disabled: false
        })
    })

    return Object.values(res)
}

async function getTree () {
    return Promise.all([__apis.system.getPermissionsModules(), __apis.system.getAllPermissions()])
}

// 角色
let role_data !: {
    role: getAllRoleRes[]
}
export async function Role () {
    role_data = Vue.observable({
        role: []
    })

    await getRole()

    return {
        ...role_data,
        getRole,
        getRoleByRoleId
    }
}

function getRoleByRoleId (role_id: number) {
    const role = role_data.role
    for (let i = 0; i < role.length; i++) {
        if (role[i].id === role_id) return role[i]
    }
}

async function getRole () {
    const { data: roles } = await __apis.system.getAllRole()
    // 每次请求需要删除之前的值
    role_data.role.splice(0, role_data.role.length)
    // observe 不可以直接覆盖
    role_data.role.push(...roles)
}

export interface mergeTreeRes extends Omit<getAllPermissionsRes, 'id'> {
    id: string
    disabled: boolean
    children: Array<getAllPermissionsRes & {
        disabled: boolean
    }>
}

async function getType () {
    const data = await __apis.system.permissionsType()
    return data.data
}

export type roleType = {
    [index: number]: string
}

let CLIENT: roleType = null as any

let SERVICE: roleType = null as any

let STATUS: roleType = null as any

let MODULES: roleType = null as any

export function getKeyByValue <T extends Object> (obj: T, value: any) {
    let o !: keyof T
    for (o in obj) {
        if (obj[o] === value) {
            return o
        }
    }
}

// 权限管理相关数据 模块 客户端 服务端 状态
export async function RoleManage () {
    if (!CLIENT || !SERVICE || !STATUS || !MODULES) {
        const [data, { pc, xcx, getModules }] = await Promise.all([getType(), Tree()])
        CLIENT = data.client
        SERVICE = data.service
        STATUS = data.status
        MODULES = Object.assign(getModules(pc, 'PC-'), getModules(xcx, 'XCX-'))
    }

    return {
        CLIENT,
        SERVICE,
        STATUS,
        MODULES
    }
}
