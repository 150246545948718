import { BaseRes, ListReq, ListRes, php } from '@/api/index'
import { basicUserInfo } from '@/api/user'

interface saveMrtReq {
    name: string
    brief_intro: string
    intro: string
    img: string
    honour: string
}

interface getMrtListRes extends ListRes<{
    Id: number
    UserId: null | number
    Name: string
    BriefIntro: string
    Img: string
    Intro: string
    Honour: string
    Ctime: string
}> {}

interface getCompanyJobListRes {
    [index: string]: {
        name: string
        value: number
    }
}

interface getCompanyUserListReq {
    pagesize: number
    dep_id: number
}

export interface getCompanyAlbumListRes extends ListRes<{
    Id: number
    Name: string
    Img: string
    Ctime: string
}>{}

interface getAlbumPhotosListReq extends ListReq {
    album_id: number
}

interface getAlbumPhotosListRes extends ListRes<{
    Id: number
    AlbumId: number
    Img: string
    Ctime: string
}>{}

interface saveCompanyAlbumPhotosReq {
    img: string
    album_id: number
}

interface saveCompanyAlbumReq {
    id?: number
    name: string
    img: string
}

interface getCompanyUserListRes extends ListRes<basicUserInfo & {
    DepInfo: {
        name: string
        value: number
        intro: string
        img: string
    }
}>{}

export default class ApiBusiness {
    getCompanyUserList (data: getCompanyUserListReq): BaseRes<getCompanyUserListRes> {
        return php.post('', {
            action_name: 'getCompanyUserList',
            ...data
        })
    }

    saveCompanyAlbum (data: saveCompanyAlbumReq) {
        return php.post('', {
            action_name: 'saveCompanyAlbum',
            ...data
        })
    }

    saveCompanyAlbumPhotos (data: saveCompanyAlbumPhotosReq) {
        return php.post('', {
            action_name: 'saveCompanyAlbumPhotos',
            ...data
        })
    }

    delAlbumPhoto (id: number) {
        return php.post('', {
            action_name: 'delAlbumPhoto',
            id
        })
    }

    getAlbumPhotosList (data: getAlbumPhotosListReq): BaseRes<getAlbumPhotosListRes> {
        return php.post('', {
            action_name: 'getAlbumPhotosList',
            ...data
        })
    }

    getCompanyAlbumList (data: ListReq): BaseRes<getCompanyAlbumListRes> {
        return php.post('', {
            action_name: 'getCompanyAlbumList',
            ...data
        })
    }

    // 获取名人堂数据
    getMrtList (data: ListReq): BaseRes<getMrtListRes> {
        return php.post('', {
            action_name: 'getMrtList',
            ...data
        })
    }

    // 保存名人堂
    saveMrt (data: saveMrtReq) {
        return php.post('', {
            action_name: 'saveMrt',
            ...data
        })
    }

    // 获取公司职位列表
    getCompanyJobList (): BaseRes<getCompanyJobListRes> {
        return php.post('', {
            action_name: 'getCompanyJobList'
        })
    }
}
