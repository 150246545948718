import { Component, Vue } from 'vue-property-decorator'
import buttonsComponent from '@/components/wallet/buttons/index.vue'

@Component({
    components: {
        buttonsComponent
    }
})
export default class IndexPage extends Vue {
    tabs = {
        active: 0,
        tab: ['工资发放', '提成发放', '奖金发放', '余额充值', '充值卡发放薪资'],
        urls: ['/financial/wallet', '/financial/wallet/ticheng', '/financial/wallet/jiangjin', '/financial/wallet/chongzhi', '/financial/wallet/chongzhiCard']
    }

    created () {
        this.tabs.active = this.tabs.urls.indexOf(this.$route.path)
    }

    tabsChange (a: number) {
        if (this.tabs.active === a) return

        this.tabs.active = a
        this.$router.push(this.tabs.urls[a])
    }
}
