import { Component, Vue } from 'vue-property-decorator'
import { UserDispatchType } from '@/store/user'
import login from 'qm-plugin-admin-login'
import qmPermission from 'qm-plugin-admin-permission'
import { AppState, LOAD_APP_STATE, StoreAppState, UPDATE_APP_STATE } from '@/store/modules/app_state'
import store, { Module } from '@/store'
import { createMessageService } from '@/service/message'
import { EventBus } from '@/util/bus'
import { __localStorage, storageName } from '@/localStorage'
import { apiConfig } from '@/api/config'

const appStore = store.module<StoreAppState>(Module.APP_STATE)

@Component
export default class App extends Vue {
    success = false

    async created () {
        try {
            login.init({
                env: process.env.NODE_ENV
            })

            // 获取本地保存的数据
            appStore.commit(LOAD_APP_STATE)
            appStore.commit(UPDATE_APP_STATE, { time_diff_server: 0 } as AppState) // reset

            // 处理消息事件
            const ws = createMessageService()
            let wsConnect = false
            // 如果是登录用户，立即连接服务器
            if (__localStorage.getLocalStorage(storageName.TOKEN)) {
                wsConnect = true
                ws.connect().catch((e: any) => e)
            }
            // 否则等待login成功后再连接
            EventBus.$on('login', () => {
                if (!wsConnect) {
                    wsConnect = true
                    ws.connect().catch((e: any) => e)
                }
            })
            EventBus.$on('logout', () => {
                if (wsConnect) {
                    wsConnect = false
                    ws.close()
                }
            })

            await Promise.all([this.getData(), qmPermission.init({ id: 15, apiBaseUrl: apiConfig.jUrl })])

            this.__role.init()
        } catch (e) {
            this.$message({
                message: '应用程序初始化失败，请稍后再试',
                type: 'error'
            })
            throw e
        } finally {
            this.success = true
        }
    }

    async getData () {
        // 同步获取基础信息
        await this.__store.dispatch(UserDispatchType.getUserInfo)

        try {
            await this.__store.dispatch(UserDispatchType.getBaseInfo)
        } catch (e) {
            this.$message.error({
                message: `发生未知原因错误，请拍照发在公司群里 ${e.message}`,
                duration: 10000
            })
        }
    }
    mounted () {
        // chrome 109 是最后一个支持windows 7的版本，但是userAgent比较特殊，是 Windows NT 10.0 的系统标记
        // 这个版本在win7上有字体显示问题，默认的textarea的monospace字体显示模糊
        const isWin7Chrome109: boolean = navigator.userAgent.indexOf('Windows NT 10.0') > -1 && navigator.userAgent.indexOf('Chrome/109.0') > -1
        const fixClassName = 'win7-chrome'
        if (isWin7Chrome109) {
            if (document.body) {
                if (document.body.classList) {
                    document.body.classList.add(fixClassName)
                } else {
                    document.body.className += ` ${fixClassName}`
                }
            }
        }
    }
}
