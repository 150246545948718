
export class MockLocalStorage implements Storage {
    [name: string]: any
    setItem (key: string, value: string): void {
        this[key] = value || ''
    }
    getItem (key: string): string|null {
        return key in this ? this[key] : null
    }
    removeItem (key: string): void {
        delete this[key]
    }
    get length (): number {
        return Object.keys(this).length
    }
    key (i: number): string|null {
        const keys = Object.keys(this)
        return keys[i] || null
    }
    clear (): void {
        Object.keys(this).forEach((key) => {
            delete this[key]
        })
    }
}
