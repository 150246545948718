import { Component, Vue } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import { Table, _Filter } from '@/types'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'
import InputDate from '@/components/filters/inputDateRange/index.vue'
import { routerBaseUrl } from '@/router'
import { duijieUser } from '@/api/channelManage'

interface TableItem {
    id: number
    baobeiHouse: string
    expectDay: string
    friendName: string
    channelPeople: duijieUser
    date: string
    people: string
    status: number
}

type Filter = _Filter<{
    baobeiHouse: number
    friendName: number
    date: string[]
}>

@Component({
    components: {
        FilterComponent,
        FilterBusinessSelect,
        InputDate
    }
})
export default class IndexPage extends Vue {
    filter: Filter = {}

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0
    }

    created () {
        this.getTableData()
    }

    async getTableData () {
        const { data } = await this.__apis.channelManage.houseBindList({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            time_start: this.filter.date && this.filter.date[0],
            time_end: this.filter.date && this.filter.date[1],
            channel_id: this.filter.friendName,
            house_id: this.filter.baobeiHouse
        })
        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.id,
                baobeiHouse: a.house_name,
                expectDay: a.return_time,
                friendName: a.channel_name,
                channelPeople: a.duijie_user,
                date: this.__utils.index.formatDate(new Date(a.ctime), 'YYYY-MM-DD'),
                people: a.operator,
                status: a.status
            }
        })
    }

    async toggleStatus (item: TableItem) {
        if (this.__utils.index.limitRepeatClick()) return

        const status = (item.status + 1) % 2

        try {
            await this.__apis.channelManage.houseBindStatusSave({
                id: item.id,
                status
            })

            this.$message.success(`${status === 0 ? '禁用' : '启用'}成功`)
            this.getTableData()
        } catch (e) {
            this.$message.error(`${status === 0 ? '禁用' : '启用'}失败`)
            throw e
        } finally {
            this.__utils.index.restoreRepeatClick()
        }
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        if (this.filter.date) {
            this.filter.date = this.__utils.index.formatTimeStartTimeEnd(this.filter.date)
        }

        this.getTableData()
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }

    gotoEdit (index?: number) {
        open(`${routerBaseUrl}/channelManage/house-manage/detail${index !== undefined ? `?id=${this.tableData.data[index].id}` : ''}`)
    }

    tableRowClassFun ({ row: item }: {row: TableItem}) {
        if (item.status === 0) {
            return 'disabled'
        }
    }
}
