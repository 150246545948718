import { Component, Vue, Watch } from 'vue-property-decorator'
import { Notification } from 'element-ui'
import addComponent from '@/components/success-detail/remark/index.vue'
import { SuccessDetailDispatchType } from '@/store/successDetail'
import { getSuccessDetailRes } from '@/api/financial'

@Component({
    components: {
        addComponent
    }
})
export default class IndexPage extends Vue {
    addComponentOptions = {
        show: false,
        remark: '',
        id: undefined
    }

    table: getSuccessDetailRes['RemarkList'] = []

    @Watch('allData')
    watchAllData () {
        this.loadEndData()
    }

    get
    allData () {
        return this.__store.state.success_detail.all_data
    }

    created () {
        if (this.allData.Id !== undefined) {
            this.loadEndData()
        }
    }

    // 过滤每一列的类容
    formatColumn (r: any, c: any, cellValue: any) {
        if (typeof cellValue === 'number') {
            return cellValue
        } else {
            return cellValue || ''
        }
    }

    showAddComponent () {
        this.addComponentOptions.remark = ''
        this.addComponentOptions.id = undefined
        this.addComponentOptions.show = true
    } // 显示添加弹窗

    loadEndData () {
        this.table = this.allData.RemarkList
    }
}
