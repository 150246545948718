import { Module } from 'vuex'
import { __localStorage, storageName } from '@/localStorage'

export const UPDATE_APP_STATE = 'UPDATE_APP_STATE'
export const LOAD_APP_STATE = 'LOAD_APP_STATE'
export const CLEAR_APP_STATE = 'CLEAR_APP_STATE'

export interface AppState {
    time_diff_server: number // 和服务器之间的时差ms +表示比服务器快 -表示比服务器慢
    time_ping: number // 和服务器之间的网络延迟ms (指单程，非往返)
}

const initAppState: AppState = {
    time_diff_server: 0,
    time_ping: 0
}

export interface StoreAppState {
    appState: AppState
}
const storeKey = '__NA__appState' as storageName
export default <Module<StoreAppState, any>>{
    namespaced: true,

    state: () => ({
        appState: initAppState
    }),
    mutations: {
        [UPDATE_APP_STATE] (state: StoreAppState, payload: AppState) {
            state.appState = {
                ...state.appState,
                ...payload
            }
            __localStorage.setLocalStorage(storeKey, state.appState)
        },
        [CLEAR_APP_STATE] (state: StoreAppState) {
            __localStorage.setLocalStorage(storeKey as any, initAppState)

            state.appState = {
                ...initAppState
            }
        },
        [LOAD_APP_STATE] (state: StoreAppState) {
            const payload = __localStorage.getLocalStorage(storeKey) || {}
            state.appState = {
                ...initAppState,
                ...payload
            }
        }
    }
}
