import { Component, Vue, Watch } from 'vue-property-decorator'
import { __role } from '@/role'
import router from '@/router'
import qmPermission from 'qm-plugin-admin-permission'

const menus = [{
    route: { path: '/financial/success', query: { type: 'getHouseSaleList' } },
    text: '成交管理',
    authKey: __role.permissions.ShowSuccessManagePage
}, {
    route: { path: '/financial/huiyong', query: { type: 'daoZhangList' } },
    text: '回佣管理',
    authKey: __role.permissions.ShowBackMoneyManagePage
}, {
    route: { path: '/financial/kaipiao', query: { type: 'kaiPiaoList' } },
    text: '开票管理',
    authKey: __role.permissions.ShowBillingManagePage
}, {
    route: { path: '/financial/fanyong', query: { type: 'fanYongList' } },
    text: '返佣管理',
    authKey: __role.permissions.ShowGiveMoneyManagePage
}, {
    route: { path: '/financial/tuifang', query: { type: 'tuifangList' } },
    text: '退房管理',
    authKey: __role.permissions.ShowExitManagePage
}, {
    route: { path: '/financial/trouble', query: { type: 'troubleList' } },
    text: '问题单',
    authKey: __role.permissions.ShowExitManagePage
}, {
    route: { path: '/financial/wangqian', query: { type: 'wangqianList' } },
    text: '网签管理',
    authKey: __role.permissions.ShowSignManagePage
}, {
    route: { path: '/financial/saleMoneyChangeList' },
    text: '佣金更改记录',
    authKey: __role.permissions.ShowMoneyChangeRecordPage
}, {
    route: { path: '/financial/gongziManage' },
    text: '工资管理',
    authKey: __role.permissions.WageManagePage
}, {
    route: { path: '/financial/wallet' },
    text: '钱包管理',
    authKey: __role.permissions.WalletManagePage
}, {
    route: { path: '/financial/chongzhi-wallet' },
    text: '充值钱包管理',
    authKey: __role.permissions.ShowChongZhiWalletPage
}, {
    route: { path: '/financial/group-commission' },
    text: '战队提成',
    authKey: __role.permissions.ShowGroupCommissionRecordPage
}]

@Component({
    // fix: 子页面跳转父页面失效
    beforeRouteUpdate (to, from, next) {
        const This = this as any
        This.showRouterView = false
        setTimeout(() => {
            This.showRouterView = true
        }, 100)
        next()
    }
})
export default class IndexPage extends Vue {
    menus = menus

    showRouterView = true

    get
    userInfo () {
        return this.__store.state.user.userInfo
    }

    @Watch('$route')
    watchRoute (to: any) {
        if (to.fullPath === '/financial') {
            router.replace(this.menus[0].route)
        }
    }

    created () {
        qmPermission.filter(this.menus)

        this.watchRoute({
            fullPath: this.$route.path
        })
    }
}
