import { Component, Vue, Watch } from 'vue-property-decorator'
import FilterComponent from '@/components/filters/filter/index.vue'
import FilterVue from '@/components/filters/filter/index.ts'
import SelectComponent from '@/components/filters/select/index.vue'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import { LabelValueArray, Table } from '@/types'

interface TableItem {
    id: number,
    department: string,
    group: string,
    fxs: string,
    project: string,
    money: string,
    submitTime: string,
    remark: string
}

interface Filter {
    [index: string]: string | string[]
    department: string
    group: string
    fxs: string
    project: string
    time: string[]
}

@Component({
    components: {
        FilterComponent,
        SelectComponent,
        CheckBoxComponent,
        InputDateRangeComponent
    }
})
export default class IndexPage extends Vue {
    filter: Filter = {
        department: '',
        group: '',
        fxs: '',
        project: '',
        time: []
    }

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 50
    }

    project = [
        {
            value: 1,
            label: '带看'
        }, {
            value: 2,
            label: '成交'
        }
    ]

    // 搜索未到账记录
    searchDetail: {
        value: string
        list: LabelValueArray<string>
    } = {
        value: '',
        list: []
    }

    get
    cmtShowFilter () {
        return this.searchDetail.value === ''
    }

    @Watch('searchDetail.value')
    async watchSearchDetail () {
        if (this.cmtShowFilter) {
            // 恢复pageSize
            this.tableData.pageSize = 10
            this.getTableData()
            return
        }

        // 清空筛选条件
        const filter = this.$refs.filterComponent as FilterVue
        filter.clearAllTag()

        const { data } = await this.__apis.wallet.getSaleManNotArriveList(Number(this.searchDetail.value))

        this.tableData.total = data.length
        this.tableData.pageSize = data.length
        this.tableData.data = data.map((a, i) => {
            return {
                id: i,
                department: this.__utils.index.getDepInfoById(a.dep).v,
                group: this.__utils.index.getGroupInfoById(a.group).name,
                fxs: a.username,
                project: this.__utils.index.getLabelByValue(this.project, a.type),
                money: a.money,
                submitTime: this.__utils.index.formatDate(new Date(a.date), 'YYYY-MM-DD HH:mm'),
                remark: a.remarks
            }
        })
    }

    searchWaitFxs (text: string) {
        this.__utils.index.searchEmployee(text, (data) => {
            this.searchDetail.list = data
        })
    }

    created () {
        this.getTableData()
    }

    async getTableData () {
        const { data } = await this.__apis.wallet.getWalletUnSettlementRecordList({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            dep_id: this.filter.department,
            group_id: this.filter.group,
            employee_id: this.filter.fxs,
            time_start: this.filter.time[0],
            time_end: this.filter.time[1],
            type: this.filter.project
        })

        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.Id,
                department: a.UserInfo.DepartmentName,
                group: a.UserInfo.GroupName,
                fxs: a.UserInfo.UserName,
                project: this.project.filter(p => p.value === a.Type)[0].label,
                money: this.__utils.index.formatMoney(a.Money),
                submitTime: a.Ctime,
                remark: a.Remark
            }
        })

        this.tableData.data.unshift({
            id: -1,
            department: '总计',
            group: '',
            fxs: '',
            project: '',
            money: this.__utils.index.formatMoney(+data.statistic_res.total_money),
            submitTime: '',
            remark: ''
        })
    }

    searchDep (text: string, cb: any) {
        this.__utils.index.searchDep(text, cb)
    }

    searchGroup (text: string, cb: any) {
        this.__utils.index.searchGroup(text, cb, { dep: this.filter.department })
    }

    searchFxs (text: string, cb: any) {
        this.__utils.index.searchEmployee(text, cb)
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        if (!this.cmtShowFilter) {
            return
        }

        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        if (!this.filter.time) {
            this.filter.time = []
        } else {
            this.filter.time = this.__utils.index.formatTimeStartTimeEnd(this.filter.time)
        }

        this.getTableData()
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }
}
