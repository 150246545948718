import { Vue, Component } from 'vue-property-decorator'
import TabsComponent from '../../components/wallet/tabs/index.vue'
import { __role } from '@/role'

@Component({
    components: {
        TabsComponent
    }
})
export default class IndexPage extends Vue {
    tabsComponent = {
        active: 0,
        items: [
            {
                text: '成长档案',
                key: __role.KEYS.grow_file,
                url: ['/grow-manage/file']
            }, {
                text: '新训班',
                key: __role.KEYS.grow_xxb,
                url: ['/grow-manage/xxb']
            }
        ]
    }

    created () {
        this.__role.filter(this.tabsComponent.items)
    }
}
