import { Component, Vue } from 'vue-property-decorator'
import { mergeTreeRes, saveUser, Tree } from '../utils'
import inputSelectSearch from '@/components/inputSelectSearch/index.vue'
import { searchUserRes } from '@/api/utils'

@Component({
    components: {
        inputSelectSearch
    }
})
export default class IndexPage extends Vue {
    user: number = null as any
    role_name: string = ''

    tree: {
        defaultProps: any
        pc: mergeTreeRes[],
        xcx: mergeTreeRes[]
    } = {
        pc: [],
        xcx: [],
        defaultProps: {
            label: 'name'
        }
    }

    // fix
    save (): any {}
    change (a: searchUserRes['list'][0]): any {}

    async created () {
        const { pc: pc_tree, xcx: xcx_tree, setDisableNodeByIds, restoreTreeStatus } = await Tree()
        this.tree.pc = pc_tree
        this.tree.xcx = xcx_tree
        let roles: number[] = []

        this.change = (user) => {
            this.$nextTick(async () => {
                this.role_name = user.roleName

                const pc_ref = this.$refs.pc as any
                const xcx_ref = this.$refs.xcx as any

                restoreTreeStatus(this.tree.pc)
                restoreTreeStatus(this.tree.xcx)

                const { data } = await this.__apis.system.getUserPermissions(this.user)

                const per = data.user_permissions.concat(data.role_permissions)
                pc_ref.setCheckedKeys(per)
                xcx_ref.setCheckedKeys(per)

                // 保存角色权限，在save的时候使用
                roles = data.role_permissions

                setDisableNodeByIds(pc_tree, roles)
                setDisableNodeByIds(xcx_tree, roles)
            })
        }
        this.save = async () => {
            const pc_ref = this.$refs.pc as any
            const xcx_ref = this.$refs.xcx as any

            // 保存时候不能有role权限 只保存user
            const userKeys = pc_ref.getCheckedKeys(true).concat(xcx_ref.getCheckedKeys(true)).filter((a: any) => Number.isInteger(a) && !roles.includes(a))

            await saveUser({
                user_id: this.user,
                permissions_ids: userKeys
            })

            this.$message.success('保存成功')
        }
    }
}
