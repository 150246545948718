import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CustomerPhoneInvalidComponent extends Vue {
    @Prop({
        default: false,
        type: Boolean
    })
    show !: boolean

    @Prop({
        default: '',
        type: String
    })
    customerId !: string

    radio = ''

    get
    customerTags () {
        const tags = this.__store.state.user.customerTags
        return [tags.X, tags.Y, tags.Z]
    }

    async enter () {
        try {
            await this.__apis.customer.customerCallInvalid({
                call_id: +this.customerId,
                mark: this.radio
            })
            this.$message.success('标记成功')
            this.$emit('success')
            this.close()
        } catch (e) {
            this.$message.error('标记失败')
            throw e
        }
    }

    close () {
        this.$emit('update:show', false)
    }
}
