import { Component, Prop, Vue } from 'vue-property-decorator'
import InputSelectComponent from '@/components/filters/inputSelect/index.vue'

@Component({
    components: {
        InputSelectComponent
    }
})
export default class FilterBusinessInputSelect extends Vue {
    value = ''

    @Prop({
        type: String,
        default: '请输入筛选条件'
    })
    title !: string

    @Prop({
        type: String,
        default: ''
    })
    propName !: string

    @Prop({
        type: String,
        default: ''
    })
    componentId !: string

    @Prop({
        type: String,
        default: '请输入'
    })
    placeholder !: string

    @Prop({
        type: String,
        default: 'house'
    })
    type !: string

    @Prop({
        type: [String, Number],
        default: 1
    })
    fxs !: string | number

    get
    infoPos () {
        return this.__store.state.user.infoPos
    }

    // 搜索数据
    async search (text: string, cb: any) {
        let data: any

        if (this.type === 'house') {
            let { data: res } = await this.__apis.utils.searchHouse({
                term: text
            })
            data = res
        } else if (this.type === 'user') {
            const { data: res } = await this.__apis.utils.searchUser({
                key: text,
                fxs: this.fxs
            })

            data = res.list.map(a => {
                return {
                    value: a.Username,
                    label: a.Username
                }
            })
        } else if (this.type === 'group') {
            this.__utils.index.searchGroup(text, (res) => {
                data = res
            })
        } else if (this.type === 'commonDep') {
            this.__utils.index.searchComDep(text, (res) => {
                data = res
            })
        } else if (this.type === 'employee') {
            const obj = await this.__apis.gzManage.getEmployeeRecord({
                page: 1,
                pagesize: 1000
            })
            let list = obj.data.list.map((a: any) => {
                return {
                    value: a.Username,
                    label: a.Username
                }
            })
            data = list.filter((v: any) => {
                return v.label.includes(text)
            })
        }

        cb(data)
    }
}
