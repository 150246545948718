import { Component, Vue, Watch } from 'vue-property-decorator'
import dateRangeSelectComponent from '@/components/wallet/date-range-select/index.vue'
import echarts from 'echarts'
import { __utils, groupColors } from '@/util'
import { Table } from '@/types'
import FilterComponent from '@/components/filters/filter/index.vue'
import SelectComponent from '@/components/filters/select/index.vue'
import InputDateRangeComponent from '@/components/filters/inputDateRange/index.vue'
import CheckBoxComponent from '@/components/filters/checkBox/index.vue'
import FilterBusinessSelect from '@/components/filters/filter-business/select/index.vue'
import EChartOption = echarts.EChartOption;

interface TableItem {
    id: number,
    department: string,
    group: string,
    fxs: string,
    time: string,
    money: string,
    status: string
    balance: string
}

interface Filter {
    [index: string]: string | string[]
    department: string
    group: string
    fxs: string
    time: string[]
    status: string
}

interface Chart {
    department: number
    money: string
    last_money: string
    rate: string | number
    legends: {
        money: string
        name: string
        color: string
    }[]
    lastTime: string
    loading: boolean
}

@Component({
    components: {
        dateRangeSelectComponent,
        FilterComponent,
        SelectComponent,
        InputDateRangeComponent,
        CheckBoxComponent,
        FilterBusinessSelect
    }
})
export default class IndexPage extends Vue {
    // 时间发生变更
    @Watch('dateRangeSelectComponent.time')
    watchDateRange () {
        this.drawCharts()
    }

    get
    deps () {
        const dep_arr = this.__store.state.user.common_dep_arr
        return [{ value: 0, name: '全部' }].concat(dep_arr)
    }

    filter: Filter = {
        status: '',
        department: '',
        group: '',
        fxs: '',
        time: []
    }

    status = [
        {
            value: 0,
            label: '提现中'
        }, {
            value: 1,
            label: '已提现'
        }, {
            value: 2,
            label: '已驳回'
        }
    ]

    tableData: Table<TableItem> = {
        data: [],
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 50
    }

    chart: Chart = {
        department: 0,
        money: '',
        rate: '',
        last_money: '',
        legends: [],
        lastTime: '',
        loading: false
    }

    dateRangeSelectComponent = {
        time: __utils.index.formatTimeStartTimeEnd(['2020-03-01', __utils.index.formatDate(new Date(), 'YYYY-MM-DD')])
    }

    mounted () {
        this.drawCharts()
        this.getTableData()
    }

    async getTableData () {
        const { data } = await this.__apis.wallet.getWalletCashOutRecordListEs({
            page: this.tableData.page,
            pagesize: this.tableData.pageSize,
            common_dep_id: this.filter.department,
            group_id: this.filter.group,
            employee_id: this.filter.fxs,
            status: this.filter.status,
            time_start: this.filter.time[0],
            time_end: this.filter.time[1]
        })

        this.tableData.total = data.total
        this.tableData.data = data.list.map(a => {
            return {
                id: a.employee_id,
                department: a.user_info.CommonDepartmentName,
                group: a.user_info.GroupName,
                fxs: a.user_info.UserName,
                time: a.ctime,
                money: this.__utils.index.formatMoney(a.money),
                status: this.status.filter(s => s.value === a.status)[0].label,
                balance: this.__utils.index.formatMoney(a.wallet_money)
            }
        })
    }

    searchDep () {}

    searchFxs (text: string, cb: any) {
        this.__utils.index.searchEmployee(text, cb)
    }

    // 筛选发送变化
    filterChange (data: Filter) {
        this.filter = this.__utils.index.copyObj(data)
        this.tableData.page = 1

        if (!this.filter.time) {
            this.filter.time = []
        } else {
            this.filter.time = this.__utils.index.formatTimeStartTimeEnd(this.filter.time)
        }

        this.getTableData()
    }

    tableSizeChange (a: number) {
        this.tableData.pageSize = a
        this.getTableData()
    }

    tableCurrentChange (a: number) {
        this.tableData.page = a
        this.getTableData()
    }

    async drawCharts () {
        // 表格高度重新设置
        this.chart.loading = true

        const { data } = await this.__apis.wallet.walletCashOutStatistics({
            common_dep_id: this.chart.department || '',
            time_start: this.dateRangeSelectComponent.time[0],
            time_end: this.dateRangeSelectComponent.time[1]
        })
        const colors = groupColors

        if (typeof data.money_info.compare_last_rate === 'string') {
            this.chart.rate = data.money_info.compare_last_rate
        } else {
            if (data.money_info.compare_last_rate === 0) {
                this.chart.rate = '持平'
            } else {
                this.chart.rate = (data.money_info.compare_last_rate * 100).toFixed(2)
            }
        }
        // 有可能部门下面没有战队
        let allMoney = 0
        if (data.money_info.money_list.length) {
            allMoney = data.money_info.money_list.map(a => a.money).reduce((a, b) => a + b)
        }
        this.chart.money = this.__utils.index.formatMoney(allMoney)

        this.chart.lastTime = `${this.__utils.index.formatDate(new Date(data.money_info.last_time_start * 1000), 'YYYY-MM-DD')} ~ ${this.__utils.index.formatDate(new Date(data.money_info.last_time_end * 1000), 'YYYY-MM-DD')}`
        this.chart.last_money = this.__utils.index.formatMoney(data.money_info.last_money)
        this.chart.legends = data.money_info.money_list.map((a) => {
            return {
                name: a.group_name || '未知队',
                money: this.__utils.index.formatMoney(a.money),
                color: colors[a.group_id]
            }
        })

        // 表格高度重新设置
        await this.__utils.index.sleep(0)
        this.chart.loading = false
        await this.__utils.index.sleep(0)

        // 提现金额文字大小动态设置
        const echartsBoxHeight = $('.charts .money-chart .echars-box').height() as number
        $('.charts .money-chart .echars-box .center-label .bottom').css({
            fontSize: this.__utils.index.cmtDynamicNumber(echartsBoxHeight, 199, 300, 22, 34),
            marginTop: this.__utils.index.cmtDynamicNumber(echartsBoxHeight, 199, 300, 1, 16)
        })

        // 饼图
        if (this.__role.has(this.__role.TYPE.SZCWKJ)) {
            const chart = echarts.init(document.querySelector('.echarts-bing-instance') as HTMLDivElement)
            const option: EChartOption = {
                tooltip: {
                    trigger: 'item'
                },
                color: this.chart.legends.map(a => a.color),
                series: [ {
                    type: 'pie',
                    radius: ['72%', '90%'],
                    avoidLabelOverlap: true,
                    label: {
                        normal: {
                            show: false
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data: data.money_info.money_list.map((a) => {
                        return {
                            value: this.__utils.index.formatMoney(a.money),
                            name: a.group_name,
                            itemStyle: {
                                color: colors[a.group_id]
                            }
                        }
                    }) as any[]
                }],
                grid: {
                    width: 428,
                    height: '100%',
                    top: 0,
                    left: 0,
                    containLabel: true
                }
            }
            chart.setOption(option)
        }

        // 折线图
        const chart = echarts.init(document.querySelector('.echarts-instance') as HTMLDivElement)
        let series: any[] = []

        // 日期
        let dates: string[] = []
        const charListValues = Object.values(data.char_list)
        if (charListValues.length) {
            dates = charListValues[0].map(a => this.__utils.index.formatChartDate(a.date))
        }

        data.money_info.money_list.forEach((a) => {
            const b = data.char_list[a.group_id]
            series.push({
                data: b.map(c => this.__utils.index.formatMoney(c.value)),
                lineStyle: {
                    color: colors[a.group_id]
                },
                type: 'line',
                smooth: true,
                symbol: 'none'
            })
        })

        const option: EChartOption = {
            xAxis: {
                type: 'category',
                data: dates,
                splitLine: {
                    show: false
                },
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    formatter (val: string) {
                        return val.replace(' ', '\n')
                    },
                    color: 'rgba(0,0,0,0.45)'
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(0, 0, 0, .1)'
                    }
                }
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    color: 'rgba(0,0,0,0.45)',
                    verticalAlign: 'middle'
                }
            },
            tooltip: {
                trigger: 'axis',
                formatter (a) {
                    let res = ''

                    if (Array.isArray(a)) {
                        res = `<div>${a[0].name}</div>`

                        // 根据最大的金额设置width
                        let moneyLabelWidth = 0
                        a.forEach(b => {
                            moneyLabelWidth = Math.max(moneyLabelWidth, b.data.length)
                        })

                        a.forEach((b, i) => {
                            res += `<div>
${b.marker}
<span style="display: inline-block;margin-right: 8px;width: ${moneyLabelWidth * 8}px">${b.data}</span>
<span>${data.money_info.money_list[i].group_name || '未知队'}</span>
</div>`
                        })
                    } else {
                        res = `<div>${a.name}</div>`

                        res += `<div>${a.marker}
<span style="margin-right: 8px;">${a.data}</span>
<span>${data.money_info.money_list[0].group_name}</span>
</div>`
                    }

                    return res
                }
            },
            color: this.chart.legends.map(a => a.color),
            series: series,
            grid: {
                width: '100%',
                height: this.__role.has(this.__role.TYPE.SZCWKJ) ? '95%' : 148,
                top: 12,
                left: 0,
                containLabel: true
            }
        }
        chart.setOption(option)
    }
}
