import $ from 'axios'
import { BaseRes, java, ListReq, ListRes, php, pp } from '@/api/index'
import { forumDetail } from '@/api/community'

export interface basicUserInfo {
    Id: number
    UserId: number
    NickName: string
    Avatar: string
    JobId: number
    DepId: number
    Intro: string
    Ctime: string
    JobInfo: {
        name: string
        value: number
    }
    UserName: string
    Deactive: number
    role_id: number
    role_name: string
}

export interface getMyAllAccountRes {
    id: number
    username: string
    avatar: string
    role: number
    role_name: string
    dep: number
    dep_name: boolean
    group: number
    group_name: string
    ac_token: string
    session_key: string
}

interface userCenterHomeRes {
    basic_info: basicUserInfo
    ip_tel_account: string
}

interface saveUserInfoReq {
    avatar?: string
    nick_name?: string
    job_id?: string
    dep_id?: string
    intro?: string
    filter_set?: boolean
}

interface getAdminNoticeListRes extends ListRes <{
    Id: number
    UserId: number
    Type: number
    Content: string
    BizData: string
    Ctime: string
}> {}

interface getCollectForumListReq extends ListReq {
    user_id: number
}

interface getCollectForumListRes extends ListRes <forumDetail> {}

interface getMyBonusListReq extends ListReq {
    time_start: string
    time_end: string
}

export interface getMyBonusListRes extends ListRes <{
    user_id: number
    username: string
    dep: number
    group: number
    role: number
    jy_time: number
    jy_id: number
    jy_money: number
    my_jy_money: number
    must_money: number
    has_send_money: number
    not_send_money: number
    bonus_rate: number
    sale_time: number
    sale_id: number
    sale_sid: number
    sale_house_name: string
    sale_house_id: number
    sale_customer_name: string
    sale_customer_tel: string
    sale_total_money: number
    sale_my_money: number
}> {
    statistic_res: {
        my_jy_money: number
        must_money: number
        jy_money: number
    }
}

export interface getMySalaryBonusOverViewResStatisticRes {
    real_salary_money: number
    bonus_must_money: number
    bonus_tax: number
    gjj_money: number
    bonus_has_send_money_by_year: number
    bonus_not_send_money_by_year: number
    bonus_not_send_money: number
    expense_money: number
    subsidy_money: number
    bonus_has_send_money: number
    salary_tax: number
    expense_not_send_money: number
    shebao_money: number
    expense_send_money: number
    must_money: number
    bonus_real_send_money: number
    reward_money: number
    bonus_must_money_by_year: number
    total_jy_money: number
}

export interface getMySalaryBonusOverViewRes extends ListRes <{
    salary_remark: string
    subsidy_remark: string
    expense_remark: string
    reward_remark: string
    user_id: number
    uids: number[]
    username: string
    dep: number
    dep_name: string
    group: number
    group_name: string
    role: number
    role_name: string
    common_dep: number
    common_dep_name: string
    month_time: number
} & getMySalaryBonusOverViewResStatisticRes> {
    statistic_res: getMySalaryBonusOverViewResStatisticRes
}

interface getMyIpInfoRes {
    user: {
        id: number
        password: string
        user_id: number
        ctime: string
        mtime: string
    }
}

interface getMyWalletRes {
    'money':number
    'interest_money':number
}

interface getMyGroupUserSaleRecordReq extends ListReq {
    start_time?: string
    end_time?: string
    has_tuifan?: number
    fxs_id?: number
}

interface getMyGroupUserSaleRecordRes extends ListRes<{
    customer_name: string
    customer_tel: string
    has_tuifan: number
    sale_time: number
    sum_money: number
    sum_must_dz_money: number
    proportion: {
        user_info: {
            dep: number
            group: number
            money: string
            name: string
            user_id: number
        }[]
    }
}>{}

interface getPersonalRankListReq {
    dep_id: number
    start_time: string
    end_time: string
}

type getPersonalRankListRes = ListRes<{
    total_money: string
    user_name: string
}> & {
    is_show: boolean
    total_money: string
}

type getGroupRankListRes = {
    total_money: string
    group: number
}[]

type getDepRankListRes = {
    total_money: string
    dep: number
}[]

interface userBaseInfo {
    duty: number
}

export default class ApiUser {
    // 退出登录
    logout (): Promise<void> {
        return $.get('https://user.91xiangju.com/logout?api=1', {
            withCredentials: true
        })
    }

    // 用户通用数据
    userBaseInfo (): BaseRes<userBaseInfo> {
        return java.post('/admin/user/userBaseInfo', {})
    }

    // 个人排行榜
    getPersonalRankList (data: getPersonalRankListReq): BaseRes<getPersonalRankListRes> {
        return java.post('/admin/user/house/sale/getPersonalRankList', data)
    }

    // 战队排行榜
    getGroupRankList (data: getPersonalRankListReq): BaseRes<getGroupRankListRes> {
        return java.post('/admin/user/house/sale/getGroupRankList', data)
    }

    // 战区排行榜
    getDepRankList (data: getPersonalRankListReq): BaseRes<getDepRankListRes> {
        return java.post('/admin/user/house/sale/getDepRankList', data)
    }

    // 获取我的成交记录
    getMyGroupUserSaleRecord (data: getMyGroupUserSaleRecordReq): BaseRes<getMyGroupUserSaleRecordRes> {
        return java.post('/admin/user/house/sale/getMyGroupUserSaleRecord', data)
    }

    // 获取所有账号
    getMyAllAccount (): BaseRes<getMyAllAccountRes[]> {
        return php.post('', {
            action_name: 'getMyAllAccount'
        })
    }

    // 我的收入概览
    getMySalaryBonusOverView (data: getMyBonusListReq): BaseRes<getMySalaryBonusOverViewRes> {
        return php.post('', {
            action_name: 'getMySalaryBonusOverView',
            ...data
        })
    }

    // 我的提成详情列表
    getMyBonusList (data: getMyBonusListReq): BaseRes<getMyBonusListRes> {
        return php.post('', {
            action_name: 'getMyBonusList',
            ...data
        })
    }

    // 获取个人信息
    userCenterHome (): BaseRes<userCenterHomeRes> {
        return php.post('', {
            action_name: 'userCenterHome'
        })
    }

    // 保存个人信息
    saveUserInfo (data: saveUserInfoReq) {
        return php.post('', {
            action_name: 'saveUserInfo',
            ...data
        })
    }

    // 获取管理员通知列表
    getAdminNoticeList (): BaseRes<getAdminNoticeListRes> {
        return php.post('', {
            action_name: 'getAdminNoticeList'
        })
    }

    // 我的收藏数据
    getCollectForumList (data: getCollectForumListReq): BaseRes<getCollectForumListRes> {
        return php.post('', {
            action_name: 'getCollectForumList',
            ...data
        })
    }

    // Voip用户账号注册
    getMyIpRegister () {
        return pp.post('/voip/user/register', {
            ignore: true
        })
    }

    // Voip用户账号查询
    getMyIpInfo (): Promise<getMyIpInfoRes> {
        return pp.post('/voip/user/info', {})
    }

    // 我的money
    getMyWallet (): BaseRes<getMyWalletRes> {
        return php.post('', {
            action_name: 'getMyWallet'
        })
    }
}
