var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invalid_record"},[(_vm.dataObj.total)?_c('div',{staticClass:"total"},[_c('div',{staticClass:"text"},[_vm._v(" 退客"+_vm._s(_vm.dataObj.total)+"条"),_c('span',[_vm._v("/")]),_vm._v("通过"+_vm._s(_vm.statusTotal[0])+"条"),_c('span',[_vm._v("/")]),_vm._v("驳回"+_vm._s(_vm.statusTotal[1])+"条"),_c('span',[_vm._v("/")]),_vm._v("申请中"+_vm._s(_vm.statusTotal[2])+"条 ")])]):_vm._e(),_c('FilterComponent',{staticClass:"filter",on:{"filterChange":_vm.filterChange}}),_c('el-table',{directives:[{name:"drag",rawName:"v-drag"}],attrs:{"data":_vm.dataObj.list,"header-row-class-name":"customer_table_th","cell-style":{'text-align': 'left', 'vertical-align': 'top', padding: '12px 6px'},"header-cell-style":{'text-align': 'left', padding: '1px 10px', 'background-color': '#fafafa',
                             color: 'rgba(0, 0, 0, 0.85)', 'font-weight': 'bold'}}},[_c('el-table-column',{attrs:{"prop":"house","label":"楼盘"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"filter_title"},[_c('span',[_vm._v("楼盘")]),_c('FilterBusSelectComponent',{staticClass:"filter_icon",attrs:{"type":"house","prop-name":"house_id","title":"楼盘"}})],1)])],2),_c('el-table-column',{attrs:{"prop":"dep_name","label":"部门"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"filter_title"},[_c('span',[_vm._v("部门")]),_c('FilterBusSelectComponent',{staticClass:"filter_icon",attrs:{"type":"dep","prop-name":"dep_id","title":"部门","start-search":true}})],1)])],2),_c('el-table-column',{attrs:{"prop":"group_name","label":"申请战队"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"filter_title"},[_c('span',[_vm._v("申请战队")]),_c('FilterBusSelectComponent',{staticClass:"filter_icon",attrs:{"type":"group","prop-name":"group_id","title":"申请战队","start-search":true}})],1)])],2),_c('el-table-column',{attrs:{"prop":"","label":"申请人"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                             var row = ref.row;
return [_vm._v(" "+_vm._s(row.username)+" ")]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"filter_title"},[_c('span',[_vm._v("申请人")]),_c('FilterBusSelectComponent',{staticClass:"filter_icon",attrs:{"place-holder":"请输入姓名","prop-name":"user_id","title":"申请人"}})],1)])],2),_c('el-table-column',{attrs:{"prop":"customerName","label":"客户姓名"}}),_c('el-table-column',{attrs:{"prop":"customerTel","label":"客户电话"}}),_c('el-table-column',{attrs:{"prop":"joinTime","label":"进客时间"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"filter_title"},[_c('span',[_vm._v("进客时间")]),_c('inputDateRange',{staticClass:"filter_icon",attrs:{"prop-name":"ctime","title":"进客时间"}})],1)])],2),_c('el-table-column',{attrs:{"prop":"ctime","label":"申请时间"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"filter_title"},[_c('span',[_vm._v("申请时间")]),_c('inputDateRange',{staticClass:"filter_icon",attrs:{"prop-name":"date","title":"申请时间"}})],1)])],2),_c('el-table-column',{attrs:{"prop":"","label":"申请状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:{
                        fxs_color: scope.row.Status === 0,
                        green: scope.row.Status === 1,
                        red: scope.row.Status === 2
                    }},[_vm._v(" "+_vm._s(scope.row.Status_str)+" ")])]}}])}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"caozuo_btn"},[_c('el-button',{staticClass:"to_detail_btn",on:{"click":function($event){return _vm.toDetail(scope.row,true)}}},[_vm._v(" 客户详情 ")])],1)]}}])})],1),_c('el-pagination',{attrs:{"hide-on-single-page":"","background":"","align":"right","layout":"prev, pager, next","total":_vm.total,"page-size":_vm.pageSize},on:{"current-change":_vm.pageChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }